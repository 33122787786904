import { DesignElement, IEdge, IPoint, Zone } from '@shared-types';
import { useState } from 'react';
import { Button, Checkbox, Modal, Select } from 'semantic-ui-react';
import { compact, contains } from 'underscore';
import { PanelButton } from '../../components/PanelButton';
import { algoVersions } from '../../components/PlotMap';
import { defaultFitting } from '../../helpers';
import { getZoneEdges } from '../../helpers/directedGraph';
import { drawPolygon, isInsidePoly } from '../../helpers/geometry.helpers';
import { createDefaultEdge } from '../../paper-helpers/edges';
import { isPlant } from '../../plants/plants';
import { pipingAlgo, postMinotaurSingle } from '../../services/design.service';
import { getMidPoint } from '../../shared/geometry';
import {
  deleteOrphanedFittings,
  setPipeAlgoVersion,
  useDesignStore,
} from '../../state';
import { addElementsEdges } from '../../state/addElementsEdges';
import { deleteEdge } from '../../state/deleteEdge';

export const ZonePiping = () => {
  const currentZone = useDesignStore((state) =>
    state.zones.find((zone) => zone.uuid === state.currentZone),
  );
  const elements = useDesignStore((state) => state.elements);
  const elementCache = useDesignStore((state) => state.elementCache);
  const valveBoxes = useDesignStore((state) => state.valveBoxes);
  // TODO: need to replace yards/beds w/polylines to run piping algo
  const yards = useDesignStore((state) => state.yards);
  // TODO: need to replace yards/beds w/polylines to run piping algo
  const beds = useDesignStore((state) => state.beds);
  const zones = useDesignStore((state) => state.zones);
  const lateralPipe = useDesignStore((state) => state.lateralPipe);
  const pipeAlgoVersion = useDesignStore((state) => state.pipeAlgoVersion);
  const plants = useDesignStore((state) => state.items.filter(isPlant));
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  const edges = useDesignStore((state) => state.edges);
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const [includeTrees, setIncludeTrees] = useState(true);
  const [pruneRoot, setPruneRoot] = useState(false);

  const [running, setRunning] = useState(false);

  const [open, setOpen] = useState(false);

  const runPipingAlgo = async () => {
    if (currentZone) {
      setRunning(true);
      try {
        const obstacles: {
          type: 'valvebox' | 'plant';
          points: IPoint[];
        }[] = [];
        const valveBoxObstacles: {
          type: 'valvebox' | 'plant';
          points: IPoint[];
        }[] = valveBoxes.map((vb) => {
          const zoneOutputPoints = compact(vb.zoneIDs).map((id) => {
            const vbZone = zones.find((z) => z.uuid === id);
            if (vbZone) {
              const valveOutput = elementCache[vbZone.valveOutputFitting];
              const valve = elementCache[vbZone.valve];
              const midPoint = getMidPoint(
                valveOutput.position,
                valve.position,
              );
              return midPoint;
            }
            return elementCache[vbZone!.valveOutputFitting].position;
          });
          const points = [
            {
              x: elementCache[vb.inputFitting].position.x,
              y: elementCache[vb.inputFitting].position.y,
            },
            ...zoneOutputPoints,
            {
              x: elementCache[vb.outputFitting].position.x,
              y: elementCache[vb.outputFitting].position.y,
            },
          ];

          return {
            type: 'valvebox',
            points,
          };
        });
        obstacles.push(...valveBoxObstacles);
        if (includeTrees) {
          const plantObstacles: {
            type: 'valvebox' | 'plant';
            points: IPoint[];
          }[] = plants.map((p) => ({
            type: 'plant',
            points: drawPolygon(
              8,
              { x: p.position.x, y: p.position.y },
              (p.width / 2) * 0.6,
            ),
          }));
          obstacles.push(...plantObstacles);
        }
        const payload = {
          nodes: [
            ...(currentZone.headIds.map((id) =>
              elements.find((el) => el.uuid === id),
            ) as DesignElement[]),
            elements.find(
              (el) => el.uuid === currentZone.valveOutputFitting,
            ) as DesignElement,
          ],
          // TODO: convert to using polylines instead of yards
          yards: yards.map((y) => y.points),
          obstacles,
          edges: edges.map((edge) => {
            const sourceEl = elements.find((el) => el.uuid === edge.source);
            const targetEl = elements.find((el) => el.uuid === edge.target);
            return {
              start: {
                x: sourceEl?.position.x || -1,
                y: sourceEl?.position.y || -1,
              },
              end: {
                x: targetEl?.position.x || -1,
                y: targetEl?.position.y || -1,
              },
            };
          }),
          beds: beds.map((y) => y.points),
          root: currentZone.valveOutputFitting,
          isPVC: false,
        };
        let elementsInPlants = false;
        for (let el of payload.nodes) {
          for (let obstruction of obstacles) {
            if (isInsidePoly(el.position, obstruction.points)) {
              elementsInPlants = true;
              // break
            }
          }
        }
        if (elementsInPlants) {
          console.error(
            'some elements are inside plant canopies. trying anyway',
          );
        }
        doClearPipes();
        deleteOrphanedFittings();
        const solution = await pipingAlgo(payload, pipeAlgoVersion, pruneRoot);
        setRunning(false);
        const newNodes = solution.newNodes.map((n): DesignElement => {
          return {
            ...defaultFitting({ x: n.x, y: n.y }),
            uuid: n.id,
          };
        });
        const lateralPipes = pipeProducts.filter(
          (p) => p.series === lateralPipe,
        );
        const newEdges = solution.edges.map((e) =>
          createDefaultEdge({
            source: e.start,
            target: e.end,
            isLateral: true,
            pipe:
              lateralPipes.find((p) => p.size === e.diameter)?.uuid ||
              lateralPipes[0].uuid,
          }),
        );
        addElementsEdges(newNodes, newEdges);
      } catch (err) {
        console.log(err);
        setRunning(false);
      }
    }
  };
  const doGeneratePipes = (
    zone: Zone,
    elements: DesignElement[],
    type: string,
  ) => {
    try {
      const mappedHeads: DesignElement[] = elements.filter((el) =>
        contains(zone.headIds, el.uuid),
      );
      doClearPipes();
      // let slopeAngle = valveBox ? valveBox.rotation * -1 : 0
      postMinotaurSingle(yards, mappedHeads, type, 0).then((res: any) => {
        const edges: IEdge[] = res.edges;
        addElementsEdges([], edges);
        // setPipes(treePipe, zone.uuid, lateralPipe)
      });
    } catch (err) {
      console.error(err);
    }
  };
  const doClearPipes = () => {
    if (currentZone) {
      const edgesToDelete = getZoneEdges(edges, currentZone, pocGraphs);
      edgesToDelete.forEach((edge) => {
        if (
          edge.source !== currentZone.valve &&
          edge.target !== currentZone.valve
        ) {
          deleteEdge(edge.uuid);
        }
      });
    }
  };

  return (
    <Modal
      size="mini"
      open={open}
      trigger={
        <PanelButton onClick={() => setOpen(true)}>
          Zone piping algorithms
        </PanelButton>
      }
    >
      <Modal.Header>Static Loss Items</Modal.Header>
      <Modal.Content>
        {open && currentZone && (
          <div>
            <h4>Minimum Spanning Tree</h4>
            <Button
              onClick={doGeneratePipes.bind(
                null,
                currentZone,
                elements,
                'gmst',
              )}
            >
              Run MST Algorithm
            </Button>
            <hr />
            {/* <Button onClick={exportForCost}>Export for cost</Button> */}
            <Checkbox
              checked={includeTrees}
              onChange={() => setIncludeTrees(!includeTrees)}
              label="Pipe around trees?"
            />
            <br />
            <Checkbox
              checked={pruneRoot}
              onChange={() => setPruneRoot(!pruneRoot)}
              label="Omit pipe to valve?"
            />
            <br />
            <Select
              value={pipeAlgoVersion}
              onChange={(_, d) => setPipeAlgoVersion(d.value as string)}
              options={algoVersions.map((a) => ({
                key: a.version,
                value: a.version,
                text: a.name,
              }))}
            ></Select>
            <br />
            {!running && (
              <Button onClick={runPipingAlgo}>Run piping algo</Button>
            )}
            {running && (
              <Button loading disabled>
                Loading
              </Button>
            )}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => setOpen(false)}>
          Done
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
