import {
  DesignElement,
  IPipeSection,
  IPoint,
  LateralProperties,
  LineSegment,
  Sprinkler,
  VertexAngle,
  Zone,
} from '@shared-types';
import * as math from 'mathjs';
import * as classifyPoint from 'robust-point-in-polygon';
import { newUUID } from 'src/pages/Workbench/crypto-uuid';
import {
  distSquared,
  getEuclideanDistance,
  getPointAlongLine,
  getTheta,
  rotateAroundPoint,
  toDegrees,
} from 'src/pages/Workbench/shared/geometry';
import _ from 'underscore';
import { PipeSectionType, StripCorner } from '../shared/workbench-enums';

export const linesIntersect = (
  a: LineSegment,
  b: LineSegment,
): boolean | IPoint => {
  const x1 = a.start.x;
  const y1 = a.start.y;
  const x2 = a.end.x;
  const y2 = a.end.y;
  const x3 = b.start.x;
  const y3 = b.start.y;
  const x4 = b.end.x;
  const y4 = b.end.y;

  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false;
  }

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  // Lines are parallel
  if (denominator === 0) {
    return false;
  }

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }

  // Return a object with the x and y coordinates of the intersection
  const x = x1 + ua * (x2 - x1);
  const y = y1 + ua * (y2 - y1);

  return { x, y };
};

export const getRotation = (
  a: IPoint,
  b: IPoint,
  clockwise?: boolean,
): number => {
  const aDeg = toDegrees(getTheta(b, a));
  return clockwise ? aDeg : 360 - aDeg;
};

export const onRight = (lineA: IPoint, lineB: IPoint, c: IPoint): boolean =>
  lineSide(lineA, lineB, c) > 0;

export const lineSide = (a: IPoint, b: IPoint, c: IPoint): number =>
  // 0: colinear, -1: left, 1: right
  (c.x - a.x) * (b.y - a.y) - (c.y - a.y) * (b.x - a.x);

export const sortZones = (zones: Zone[]): Zone[] =>
  _(zones)
    .chain()
    .sortBy((z) => z.orderNumber)
    .value();

export const generateSections = (
  pipe: LineSegment,
  intersections: IPoint[],
  scale: number,
): IPipeSection[] => {
  const sections: IPipeSection[] = [
    {
      type: PipeSectionType.LATERAL,
      values: { start: pipe.start, end: pipe.end },
    },
  ];
  intersections.forEach((intersection: IPoint) => {
    const prevPipe = sections.pop() as IPipeSection;
    const { start, end } = prevPipe.values as LateralProperties;
    const hopRadius = 3 / scale;
    const { pre, post, through } = getPipePoints(
      { start: pipe.start, end: pipe.end },
      intersection,
      hopRadius,
    );

    // TODO: round out edge cases where hops are at ends of array or the hops are too close together
    if (getEuclideanDistance(end, pre) < hopRadius) {
      console.warn(`invalid distance between hop points on line ${pipe.start}`);
    }

    sections.push({
      type: PipeSectionType.LATERAL,
      values: { start: start, end: pre },
    });
    sections.push({
      type: PipeSectionType.HOP,
      values: { pre, post, through },
      sectionId: newUUID(),
    });
    sections.push({
      type: PipeSectionType.LATERAL,
      values: { start: post, end: pipe.end },
    });
  });
  return sections;
};

export const getPipePoints = (
  p1: LineSegment,
  intersect: IPoint,
  hopRadius: number,
): { pre: IPoint; post: IPoint; through: IPoint } => {
  const distance = getEuclideanDistance(p1.start, intersect);
  const pre: IPoint = getPointAlongLine(distance - hopRadius, p1);
  const post: IPoint = getPointAlongLine(distance + hopRadius, p1);
  const angle = -90;
  const through: IPoint = rotateAroundPoint(intersect, post, angle);
  return { pre, post, through };
};

export const getClosestPoint = (
  lines: LineSegment[],
  somePoint: IPoint,
): IPoint => {
  let least = 10000000;
  let newPoint = somePoint;
  lines.forEach((line: LineSegment) => {
    const { distance, point } = distToSegmentSquared(
      somePoint,
      line.start,
      line.end,
    );
    if (distance < least) {
      least = distance;
      newPoint = point;
    }
  });
  return newPoint;
};

export const distToSegmentSquared = (
  point: IPoint,
  segA: IPoint,
  segB: IPoint,
): { distance: number; point: IPoint } => {
  // https://stackoverflow.com/a/1501725/1285185
  const segmentLength = distSquared(segA, segB);
  if (segmentLength === 0) {
    throw new Error('the segement being checked against has no length!');
  }
  let projection =
    ((point.x - segA.x) * (segB.x - segA.x) +
      (point.y - segA.y) * (segB.y - segA.y)) /
    segmentLength;
  projection = Math.max(0, Math.min(1, projection));
  const newPoint = {
    x: segA.x + projection * (segB.x - segA.x),
    y: segA.y + projection * (segB.y - segA.y),
  };
  return { distance: Math.sqrt(distSquared(point, newPoint)), point: newPoint };
};

export const movePointAway = (
  point: IPoint,
  angle: number,
  rotation: number,
  scale: number,
  headSize: number,
): IPoint => {
  // move a point away in the distance of its angle's midpoint and rotation
  const newPosition = {
    x:
      point.x +
      (Math.cos((Math.PI / 180) * (angle / 2)) * headSize) / 2 / scale,
    y:
      point.y +
      (Math.sin((Math.PI / 180) * (angle / 2)) * headSize) / 2 / scale,
  };
  const newPoint = rotateAroundPoint(point, newPosition, -rotation);
  return {
    x: newPoint.x,
    y: newPoint.y,
  };
};
export const invertCartesianSVG = (
  points: IPoint[],
  height: number,
): IPoint[] =>
  points.map((p: IPoint) => ({
    x: p.x,
    y: height - p.y,
  }));

export const invertHead = (
  head: VertexAngle | Sprinkler,
  height: number,
): VertexAngle | Sprinkler => ({
  // TODO: can this be shared w/server?
  ...head,
  ...{
    x: head.x,
    y: height - head.y,
  },
  rotation: 360 - (head.rotation + head.angle),
});

export const getAngle = (
  a: IPoint,
  b: IPoint,
  c: IPoint,
  clockwise?: boolean,
): number => {
  const aDeg = toDegrees(getTheta(a, b));
  const cDeg = toDegrees(getTheta(c, b));
  if (clockwise) {
    return aDeg >= cDeg ? aDeg - cDeg : 360 - (cDeg - aDeg);
  } else {
    return cDeg >= aDeg ? cDeg - aDeg : 360 - (aDeg - cDeg);
  }
};

export const getPointTriplets = (arr: IPoint[]): IPoint[][] => {
  const groups: IPoint[][] = [];
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      groups.push([arr[arr.length - 1], arr[0], arr[1]]);
    } else {
      groups.push([arr[i - 1], arr[i], arr[(i + 1) % arr.length]]);
    }
  }
  return groups;
};

export const getEdges = (
  vertices: IPoint[],
  closed?: boolean,
): LineSegment[] => {
  const edges: LineSegment[] = [];
  vertices.forEach((vertex: IPoint, i: number) => {
    if (i < vertices.length - 1) {
      if (vertex.x !== vertices[i + 1].x || vertex.y !== vertices[i + 1].y) {
        edges.push({ start: vertex, end: vertices[i + 1] });
      }
    } else {
      if (closed) {
        if (vertex.x !== vertices[0].x || vertex.y !== vertices[0].y) {
          edges.push({ start: vertex, end: vertices[0] });
        }
      }
    }
  });
  return edges;
};

export const calculateVelocity = (insideDiameter: number, gpm: number) =>
  (0.408 * gpm) / Math.pow(insideDiameter, 2);

export const calcFrictionLoss = (
  coefficient: number,
  insideDiameter: number,
  length: number,
  flow: number,
) =>
  flow > 0
    ? ((0.433 *
        (0.2083 * Math.pow(100 / coefficient, 1.852) * Math.pow(flow, 1.852))) /
        Math.pow(insideDiameter, 4.8655)) *
      (length / 100)
    : 99;

export const pointsEqual = (a: IPoint, b: IPoint) =>
  math.equal(a.x, b.x) && math.equal(a.y, b.y);

export const generateKeyPoints = (
  points: IPoint[],
  minAngle: number,
  maxAngle: number,
  height: number,
) => {
  const invertedPoints = invertCartesianSVG(points, height);
  const pointTriplets = getPointTriplets(invertedPoints);
  const vertexAngles: VertexAngle[] = [];
  pointTriplets.forEach((g: IPoint[]) => {
    const a = getAngle(g[0], g[1], g[2]);
    const r = getRotation(g[1], g[2]);
    if (Math.round(a) <= 40) {
      console.error('there is a vertex smaller than 40˚');
    }
    vertexAngles.push({
      angle: Math.round(a),
      x: g[1].x,
      y: g[1].y,
      rotation: r === 360 ? 0 : Math.round(r),
    });
  });
  return vertexAngles
    .map((head: VertexAngle) => invertHead(head, height))
    .filter(
      (head: VertexAngle) => head.angle <= maxAngle || head.angle >= minAngle,
    );
};

export const getDistanceFromEdge = (points: IPoint[], p: IPoint) => {
  const edges = getEdges(points, false);
  return getDistanceFromKnownEdges(edges, p);
};
export const getDistanceFromKnownEdges = (edges: LineSegment[], p: IPoint) => {
  // sometimes we know the edges and dont' have to calc
  let d = 100000;
  edges.forEach((segment: LineSegment) => {
    if (
      segment.start.x !== segment.end.x ||
      segment.start.y !== segment.end.y
    ) {
      const { distance } = distToSegmentSquared(p, segment.start, segment.end);
      d = distance < d ? distance : d;
    }
  });
  return d;
};

export const isInsidePoly = (p: IPoint, poly: IPoint[]): boolean => {
  const pos = classifyPoint(
    poly.map((p: IPoint) => [p.x, p.y]),
    [p.x, p.y],
  );
  // return true if point is inside or on boundary of polygon
  return pos < 1;
};

export const getMissingCoordinateY = (
  point: IPoint,
  x: number,
  slope: number,
): number => -(slope * (point.x - x) - point.y);

export const closest = (arr, num) => {
  for (const n of arr) {
    if (num <= n) {
      return n;
    }
  }
};

export const drawPolygon = (
  sides: number,
  center: IPoint,
  radius: number,
): IPoint[] => {
  const angle = 360 / sides;
  let points: IPoint[] = [];
  for (let i = 1; i <= sides; i++) {
    const newPosition = {
      x: math.round(
        center.x + Math.cos((Math.PI / 180) * angle * i) * radius,
        3,
      ),
      y: math.round(
        center.y + Math.sin((Math.PI / 180) * angle * i) * radius,
        3,
      ),
    };
    if (newPosition.x === -0) {
      newPosition.x = 0;
    }
    if (newPosition.y === -0) {
      newPosition.y = 0;
    }
    points.push(newPosition);
  }
  return points;
};
export const averagePoints = (points: IPoint[]): IPoint => {
  return {
    x: points.reduce((acc, p) => acc + p.x, 0) / points.length,
    y: points.reduce((acc, p) => acc + p.y, 0) / points.length,
  };
};
export const sideOfLine = (
  a: IPoint,
  b: IPoint,
  pointToCheck: IPoint,
): number =>
  // returns 0 if colinear, -1 if left, 1 if right
  (pointToCheck.x - a.x) * (b.y - a.y) - (pointToCheck.y - a.y) * (b.x - a.x);

export const distanceToLine = (point1, point2, point3) =>
  Math.abs(
    (point2.y - point1.y) * point3.x -
      (point2.x - point1.x) * point3.y +
      point2.x * point1.y -
      point2.y * point1.x,
  ) /
  Math.pow(
    Math.pow(point2.y - point1.y, 2) + Math.pow(point2.x - point1.x, 2),
    0.5,
  );

export const binarySearchIndex = (points: IPoint[], x: number) => {
  let start = 0;
  let end = points.length - 1;
  let mid = Math.floor((start + end) / 2);
  while (start < end) {
    if (points[mid].x < x) {
      start = mid + 1;
    } else {
      end = mid;
    }
    mid = Math.floor((start + end) / 2);
  }
  return mid;
};

export const getDotSearchIndices = (
  points: IPoint[],
  x: number,
  radius: number,
) => {
  const leftIndex = binarySearchIndex(points, x - radius);
  const rightIndex = binarySearchIndex(points, x + radius);
  const oneExtraLeftIndex = leftIndex > 0 ? leftIndex - 1 : 0;
  const oneExtraRightIndex =
    rightIndex === points.length - 1 ? rightIndex : rightIndex + 1;
  return { leftIndex: oneExtraLeftIndex, rightIndex: oneExtraRightIndex };
};

export const pointIsInsideArc = (
  point: IPoint,
  arc: { center: IPoint; radius: number; from: IPoint; to: IPoint },
) => {
  const d = getEuclideanDistance(point, arc.center);
  if (d > arc.radius) {
    return false;
  }
  const angle = getAngleBetweenPoints(arc.center, point);
  const E = getAngleBetweenPoints(arc.center, arc.from);
  const S = getAngleBetweenPoints(arc.center, arc.to);
  if (S < E) {
    return angle >= S && angle <= E;
  } else {
    return angle >= S || angle <= E;
  }
};
const getAngleBetweenPoints = (p1: IPoint, p2: IPoint) => {
  return Math.atan2(p2.y - p1.y, p2.x - p1.x);
};
export const rectangleTopLeftPoint = (
  origin: IPoint,
  width: number,
  height: number,
  rotation: number,
  corner: StripCorner,
) => {
  const topLeft = { x: origin.x, y: origin.y };
  if (corner === StripCorner.BOTTOM_LEFT) {
    topLeft.x = origin.x;
    topLeft.y = origin.y - height;
  } else if (corner === StripCorner.BOTTOM_RIGHT) {
    topLeft.x = origin.x - width;
    topLeft.y = origin.y - height;
  } else if (corner === StripCorner.BOTTOM_CENTER) {
    topLeft.x = origin.x - width / 2;
    topLeft.y = origin.y - height;
  }
  return rotateAroundPoint(origin, topLeft, -rotation);
};
export const pointIsInsideRotatedRectangle = (
  origin: IPoint,
  corner: string,
  width: number,
  height: number,
  rotation: number,
  point: IPoint,
): boolean => {
  const rotatedPoint = rotateAroundPoint(origin, point, rotation);
  if (corner === StripCorner.BOTTOM_LEFT) {
    return (
      rotatedPoint.x >= origin.x &&
      rotatedPoint.x <= origin.x + width &&
      rotatedPoint.y >= origin.y - height &&
      rotatedPoint.y <= origin.y
    );
  } else if (corner === StripCorner.BOTTOM_RIGHT) {
    return (
      rotatedPoint.x >= origin.x - width &&
      rotatedPoint.x <= origin.x &&
      rotatedPoint.y >= origin.y - height &&
      rotatedPoint.y <= origin.y
    );
  } else if (corner === StripCorner.BOTTOM_CENTER) {
    return (
      rotatedPoint.x >= origin.x - width / 2 &&
      rotatedPoint.x <= origin.x + width / 2 &&
      rotatedPoint.y >= origin.y - height &&
      rotatedPoint.y <= origin.y
    );
  }
  return false;
};
export const bestFitLine = (
  points: IPoint[],
): { m: number; b: number } | null => {
  const xPoints = points.map((p) => p.x);
  const yPoints = points.map((p) => p.y);
  if (xPoints.length && yPoints.length) {
    const avgX = math.mean(xPoints);
    const avgY = math.mean(yPoints);
    const n = points.length;
    const numer = _.range(n).reduce(
      (a, i) => a + (xPoints[i] - avgX) * (yPoints[i] - avgY),
      0,
    );
    const denum = _.range(n).reduce(
      (a, i) => a + Math.pow(xPoints[i] - avgX, 2),
      0,
    );
    const m = numer / denum;
    const b = avgY - m * avgX;
    return { m, b };
  }
  return null;
};

export const findGroupings = (heads: DesignElement[]) => {
  const groups: DesignElement[][] = [];
  const headsCopy = [...heads];
  if (headsCopy.length > 2) {
    for (let i = 0; i < 1000; i++) {
      const p1 = headsCopy[math.randomInt(0, headsCopy.length)];
      const p2 = headsCopy[math.randomInt(0, headsCopy.length)];
      if (p1.uuid !== p2.uuid) {
        const colinearHeads = heads.filter(
          (d) =>
            getDistanceFromEdge([p1.position, p2.position], d.position) < 10,
        );
        if (colinearHeads.length > 2) {
          groups.push(colinearHeads);
        }
      }
    }
  }
  return _.sortBy(groups, (g) => g.length).reverse();
};

export const getIntersection = (
  lineFunction: LineSegment,
  targetSegment: LineSegment,
): IPoint | null => {
  // Avoid division by zero for vertical lines
  const denom1 = lineFunction.end.x - lineFunction.start.x;
  const denom2 = targetSegment.end.x - targetSegment.start.x;

  const epsilon = 1e-10; // Adjust this tolerance based on your typical data ranges
  const delta1 = denom1 * (targetSegment.end.y - targetSegment.start.y);
  const delta2 = denom2 * (lineFunction.end.y - lineFunction.start.y);
  const parallelOrIdentical = Math.abs(delta1 - delta2) < epsilon;

  if (parallelOrIdentical) return null; // Lines are parallel or identical

  // Calculate slopes, handle vertical lines by setting slope to Infinity
  const m1 =
    denom1 !== 0
      ? (lineFunction.end.y - lineFunction.start.y) / denom1
      : Infinity;
  const m2 =
    denom2 !== 0
      ? (targetSegment.end.y - targetSegment.start.y) / denom2
      : Infinity;

  // Calculate y-intercepts, b = y - mx
  const b1 = lineFunction.start.y - m1 * lineFunction.start.x;
  const b2 = targetSegment.start.y - m2 * targetSegment.start.x;

  // Calculate intersection point
  let x, y;
  if (Math.abs(denom1) < epsilon) {
    // Practically vertical
    x = lineFunction.start.x;
    y = m2 * x + b2;
  } else if (Math.abs(denom2) < epsilon) {
    // Practically vertical
    x = targetSegment.start.x;
    y = m1 * x + b1;
  } else {
    x = (b2 - b1) / (m1 - m2);
    y = m1 * x + b1;
  }
  // Check if intersection point is within the bounds of the line segment
  const withinSegment2 =
    x >= Math.min(targetSegment.start.x, targetSegment.end.x) &&
    x <= Math.max(targetSegment.start.x, targetSegment.end.x) &&
    y >= Math.min(targetSegment.start.y, targetSegment.end.y) &&
    y <= Math.max(targetSegment.start.y, targetSegment.end.y);

  if (withinSegment2) {
    return { x, y };
  } else {
    return null;
  }
};
