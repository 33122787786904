import { ReactNode, useState } from 'react';
import styled from 'styled-components';

interface Props {
  onConfirm: () => void;
  loading?: boolean;
  disabled?: boolean;
  children?: string | ReactNode[];
}

export const LinkWithConfirm = (props: Props) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const confirm = () => {
    props.onConfirm();
    setShowConfirm(false);
  };
  const clickOriginal = () => {
    if (!props.disabled) {
      setShowConfirm(!showConfirm);
    }
  };
  return (
    <Wrap>
      {!props.loading && (
        <>
          <div
            className={`original ${props.disabled ? 'disabled' : ''}`}
            onClick={clickOriginal}
          >
            {props.children}
          </div>
          {showConfirm && (
            <div className="confirmation">
              Are you sure? <span onClick={confirm}>Yes</span>{' '}
              <span onClick={() => setShowConfirm(false)}>No</span>
            </div>
          )}
        </>
      )}
      {props.loading && <div>loading....</div>}
    </Wrap>
  );
};
const Wrap = styled.div`
  display: inline;
  .original {
    display: inline;
    color: #4183c4;
    cursor: help;
    &:hover {
      text-decoration: underline;
    }
    &.disabled {
      cursor: not-allowed;
      color: #999;
      user-select: none;
      text-decoration: line-through;
    }
  }
  .confirmation {
    span {
      color: #4183c4;
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
      margin-left: 8px;
    }
  }
`;
