import { Confirm } from 'semantic-ui-react';
import { deleteAllEdges } from '../../paper-helpers/edges';
import { confirmDefaults } from '../../state/modal.service';

export const DeleteAllPipesType = 'deleteAllPipes';
export const DeleteAllPipes = ({ closeModal }) => (
  <Confirm
    {...confirmDefaults}
    header="Delete All Pipes"
    content="This will delete all pipes"
    confirmButton="Delete"
    onConfirm={() => {
      deleteAllEdges();
      closeModal();
    }}
  />
);
