import { Zone } from '@shared-types';
import styled from 'styled-components';
import { setCurrentZone, useDesignStore } from '../../state';
import { ZoneDetails } from './ZoneDetails';

export const ZoneList = () => {
  const currentZone = useDesignStore((state) => state.currentZone);
  const zones = useDesignStore((state) => state.zones);

  const setZone = (id: string) => {
    setCurrentZone(id);
  };

  const toggleCurrentZone = (id: string, currentZone: string) => {
    setZone(id === currentZone ? '' : id);
  };

  return (
    <Wrap>
      {zones
        .sort((z1, z2) => z1.orderNumber - z2.orderNumber)
        .map((zone: Zone) => (
          <div key={zone.uuid} className="zone">
            <StyledZoneSwatch
              $active={zone.uuid === currentZone}
              $color={zone.orderNumber > -1 ? zone.color : '#999'}
              onClick={toggleCurrentZone.bind(null, zone.uuid, currentZone)}
            >
              <div className="zone-color" />
              <div className="zone-text">
                <div className="zone-id">
                  Zone {zone.isDrip || zone.plantIds.length > 0 ? '(Drip)' : ''}{' '}
                  {zone.orderNumber + 1}
                </div>
              </div>
            </StyledZoneSwatch>
            {zone.uuid === currentZone && <ZoneDetails zone={zone} />}
          </div>
        ))}
    </Wrap>
  );
};
const Wrap = styled.div`
  /* line-height: 1.5rem; */
  .loss-item {
    margin: 8px 0;
    padding: 8px;
    border: 1px solid #ccc;
  }
  .ui.selection.dropdown .menu > .item.bad {
    color: #666;
  }
`;
export const StyledZoneSwatch = styled.div<{
  $color: string;
  $active: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #4e4e4e;
  padding-left: 8px;
  background-color: ${(props) => (props.$active ? '#4e4e4e' : '')};
  &:hover {
    background: #333;
  }
  .zone-color {
    background: ${(props) => props.$color};
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 10px;
  }

  .zone-text {
    display: flex;
    flex: 1;
    padding: 4px 4px 4px 12px;
    align-items: center;
  }
  .zone-secondary {
    display: flex;
    > div {
      margin-left: 8px;
      padding-left: 8px;
    }
  }
`;
