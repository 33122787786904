import { DesignElement, IEdge, IPoint, WaterGroup, Zone } from '@shared-types';
import { zoneColors } from 'src/vars';
import { compact, contains, uniq } from 'underscore';
import { mapEls } from '../caches/element.cache';
import { addUndo } from '../contexts/design/undo';
import { newUUID } from '../crypto-uuid';
import { generateGraphs } from '../helpers/directedGraph';
import { hopPipes } from '../helpers/hopping';
import { updateAllHeadColors } from '../paper-helpers/plot.helpers';
import { getState, setState } from './state-basics';

export const addGroup = () => {
  const zColors = getState().zones.map((z) => z.color);
  const groupColors = getState().groups.map((g) => g.color);
  const totalColors = [...zColors, ...groupColors];
  const usedColors = compact(uniq(totalColors));
  const unusedColors = zoneColors.filter(
    (color) => !usedColors.includes(color),
  );
  const group: WaterGroup = {
    headIds: [],
    plantIds: [],
    color: unusedColors[0] || 'black',
    pipes: [],
    hoppedPipes: [],
    uuid: newUUID(),
    orderNumber: getState().groups.length,
  };
  const groups = [...getState().groups, group];
  setState({
    groups,
  });
};
export const addHeadsToGroup = (groupID: string, headIDs: string[]) => {
  const groups = getState().groups.map((z) => ({
    // first, if we're in a group that is not the passed group,
    // remove any heads from this group that are in payload.headIDs
    // next, if we're in the group that is passed, add all headIDs to that
    // group, but check that they are unique
    ...z,
    headIds:
      z.uuid === groupID
        ? uniq([...z.headIds, ...headIDs])
        : z.headIds.filter((h) => !contains(headIDs, h)),
  }));
  const zones = getState().zones.map((z) => ({
    // remove head from any zone it is in
    ...z,
    headIds: z.headIds.filter((h) => !contains(headIDs, h)),
  }));
  setState({
    groups,
    zones,
  });
};
export const deleteGroup = (payload: string) => {
  const newGroups = getState().groups.filter((z) => z.uuid !== payload);
  setState({ groups: newGroups });
};
export const addStaticLossItem = (payload: string) => {
  const zones = getState().zones.map((zone) =>
    zone.uuid === payload
      ? {
          ...zone,
          staticLossItems: zone.staticLossItems
            ? [...zone.staticLossItems, { description: '', loss: 0 }]
            : [{ description: '', loss: 0 }],
        }
      : { ...zone },
  );
  setState({
    zones,
  });
};
export const deleteStaticLossItem = (zoneID: string, index: number) => {
  const zones = getState().zones.map((zone) =>
    zone.uuid === zoneID
      ? {
          ...zone,
          staticLossItems: (zone.staticLossItems || []).filter(
            (_, i) => i !== index,
          ),
        }
      : { ...zone },
  );
  setState({
    zones,
  });
};
export const editStaticLossItemValue = (
  zoneID: string,
  i: number,
  value: number,
) => {
  const zones = getState().zones.map((zone) =>
    zone.uuid === zoneID
      ? {
          ...zone,
          staticLossItems: (zone.staticLossItems || []).map((item, j) =>
            j === i ? { ...item, loss: value } : item,
          ),
        }
      : { ...zone },
  );
  setState({
    zones,
  });
};
export const editStaticLossItemDesc = (
  zoneID: string,
  i: number,
  value: string,
) => {
  const zones = getState().zones.map((zone) =>
    zone.uuid === zoneID
      ? {
          ...zone,
          staticLossItems: (zone.staticLossItems || []).map((item, j) =>
            j === i ? { ...item, description: value } : item,
          ),
        }
      : { ...zone },
  );
  setState({
    zones,
  });
};

export const setZoneDrip = (isDrip: boolean, zoneID: string) => {
  const zones = getState().zones.map((zone) => ({
    ...zone,
    isDrip: zone.uuid === zoneID ? isDrip : zone.isDrip || false,
  }));
  setState({
    zones,
  });
};

export const overrideInfoBox = (
  vbID: string,
  point: IPoint,
  vertical: boolean,
  reversed: boolean,
  undoable = true,
) => {
  if (undoable) {
    const oldVB = getState().valveBoxes.find((vb) => vb.uuid === vbID);
    if (oldVB) {
      addUndo(() => {
        overrideInfoBox(
          vbID,
          oldVB.infoBoxOverride?.point || { x: 0, y: 0 },
          oldVB.infoBoxOverride?.vertical || false,
          oldVB.infoBoxOverride?.reversed || false,
          false,
        );
      }, 'override info box');
    }
  }
  const valveBoxes = getState().valveBoxes.map((v) => ({
    ...v,
    infoBoxOverride:
      v.uuid === vbID ? { vertical, point, reversed } : v.infoBoxOverride,
  }));
  setState({
    valveBoxes,
  });
};

export const addHeadsToZone = (
  zoneID: string,
  headIDs: string[],
  undoable = true,
) => {
  if (undoable) {
    const zones = getState().zones;
    const groups = getState().groups;
    addUndo(() => {
      updateAllHeadColors(zones, groups);
      setState({ zones, groups });
    }, 'Add Heads to Zone');
  }
  const zones = getState().zones.map((z) => ({
    // first, if we're in a zone that is not the passed zone,
    // remove any heads from this zone that are in payload.headIDs
    // next, if we're in the zone that is passed, add all headIDs to that
    // zone, but check that they are unique
    ...z,
    headIds:
      z.uuid === zoneID
        ? uniq([...z.headIds, ...headIDs])
        : z.headIds.filter((h) => !contains(headIDs, h)),
  }));
  // Remove head from any group it is in
  const groups = getState().groups.map((g) => ({
    ...g,
    headIds: g.headIds.filter((h) => !contains(headIDs, h)),
  }));
  updateAllHeadColors(zones, groups);
  setState({
    zones,
    groups,
  });
};

export const setZoneElevation = (zoneID: string, elevation: number) => {
  const zones = getState().zones.map((z) => ({
    ...z,
    elevation: zoneID === z.uuid ? elevation : z.elevation,
  }));
  setState({
    zones,
  });
};
export const finalizeSteps = (
  els: DesignElement[],
  edges: IEdge[],
  zones: Zone[],
  scale: number,
) => {
  const elementCache = mapEls(els);
  const { masterGraph, pocGraphs } = generateGraphs(edges, els, zones);
  const newEdges = hopPipes(zones, edges, els, elementCache, scale, pocGraphs);
  return { elementCache, finalizedEdges: newEdges, masterGraph, pocGraphs };
};
