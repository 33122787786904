import { Query } from '../../../shared-types/portal-types';

export const BASE_API_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:5000';
export const WEB_URL = process.env.REACT_APP_WEB_URL || 'http://localhost:3000';
// TODO: find way to share URLs across server and UI

export const getJWTToken = (): string =>
  localStorage.getItem('is.access_token') || '';

export const headers = (): Headers => {
  return new Headers({
    Authorization: `Bearer ${getJWTToken()}`,
    'Content-Type': 'application/json',
  });
};

export const interceptor = (response: Response): Response => {
  if (response.status === 401) {
    // Redirect users to home page on any 401 response
    window.location.href = WEB_URL;
  } else if (response.status === 403) {
    window.location.href = `${WEB_URL}/app`;
    throw new Error('Forbidden access - redirecting');
  } else {
    if (!response.ok) {
      // Throw an error for other non-ok responses
      throw new Error(response.statusText);
    }
  }
  return response;
};

export const wrapFetch = async <T>(
  url: string,
  method?: string,
  body?: any,
): Promise<T> => {
  try {
    const response = await fetch(`${BASE_API_URL}${url}`, {
      method,
      headers: headers(),
      body: body ? JSON.stringify(body) : undefined,
    });
    return interceptor(response).json();
  } catch (error) {
    // Handle or re-throw the error based on your error handling strategy
    throw error;
  }
};

export const wrapFetchWithQuery = async <T>(
  url: string,
  method?: string,
  rawQuery?: Query,
): Promise<T> => {
  // console.log('raw query: ', rawQuery);
  const query = JSON.stringify(rawQuery || {});
  const fullUrl = rawQuery
    ? `${BASE_API_URL}${url}?q=${query}`
    : `${BASE_API_URL}${url}`;
  return fetch(fullUrl, {
    method,
    headers: headers(),
  })
    .then(interceptor)
    .then((res: Response) => res.json());
};
