import { ConfirmProps, ModalProps } from 'semantic-ui-react';

import { MoveItemsModal, MoveItemsModalType } from '../components/MoveItems';
import { SettingsModal, SettingsModalType } from '../components/SettingsModal';
import { AlertsModal, AlertsModalType } from '../features/alerts/AlertsModal';
import {
  DeleteHeadsConfirm,
  DeleteHeadsConfirmType,
} from '../features/heads/DeleteAllHeads';
import {
  EdgeAlgoModal,
  EdgeAlgoModalType,
} from '../features/heads/EdgeAlgoModal';
import {
  HeadEquipmentModal,
  HeadEquipmentModalType,
} from '../features/heads/HeadEquipment';
import {
  HeadSizeModal,
  HeadSizeModalType,
} from '../features/heads/HeadSizeTools';
import {
  DeleteAllPipes,
  DeleteAllPipesType,
} from '../features/pipes/DeleteAllPipes';
import {
  DeleteOrphans,
  DeleteOrphansType,
} from '../features/pipes/DeleteOrphans';
import {
  PipeEquipmentModal,
  PipeEquipmentModalType,
} from '../features/pipes/PipeEquipment';
import {
  HydraulicsModal,
  HydraulicsModalType,
} from '../features/pressure/HydraulicsModal';
import { StatsModal, StatsModalType } from '../features/stats/StatsModal';
import {
  ValveEquipmentModal,
  ValveEquipmentModalType,
} from '../features/valves/ValveEquipment';
import { getModalState, useModalStore } from './modal.store';

const ModalService = () => {
  const { modal, closeModal } = useModalStore((state) => ({
    modal: state.modal,
    closeModal: state.closeModal,
  }));

  const modals = {
    [DeleteHeadsConfirmType]: () => DeleteHeadsConfirm({ closeModal }),
    [DeleteAllPipesType]: () => DeleteAllPipes({ closeModal }),
    [DeleteOrphansType]: () => DeleteOrphans({ closeModal }),
    [HeadSizeModalType]: () => HeadSizeModal({ closeModal }),
    [HeadEquipmentModalType]: () => HeadEquipmentModal({ closeModal }),
    [SettingsModalType]: () => SettingsModal({ closeModal }),
    [ValveEquipmentModalType]: () => ValveEquipmentModal({ closeModal }),
    [PipeEquipmentModalType]: () => PipeEquipmentModal({ closeModal }),
    [MoveItemsModalType]: () => MoveItemsModal({ closeModal }),
    [EdgeAlgoModalType]: () => EdgeAlgoModal({ closeModal }),
    [StatsModalType]: () => StatsModal({ closeModal }),
    [AlertsModalType]: () => AlertsModal({ closeModal }),
    [HydraulicsModalType]: () => HydraulicsModal({ closeModal }),
  };

  const ModalComponent = modals[modal];

  return ModalComponent ? <ModalComponent /> : null;
};

export default ModalService;

export const confirmDefaults: ConfirmProps = {
  closeOnDimmerClick: false,
  closeOnEscape: true,
  open: true,
  size: 'mini',
  onCancel: getModalState().closeModal,
};
export const modalDefaults: ModalProps = {
  closeOnDimmerClick: false,
  closeOnEscape: true,
  dimmer: true,
  open: true,
  size: 'mini',
  onClose: getModalState().closeModal,
};
