import { PortalSortCols } from '../../../shared-types/portal-types';

export const convertSort = (
  sortDir: 'asc' | 'desc',
): 'ascending' | 'descending' =>
  sortDir === 'asc' ? 'ascending' : 'descending';

export const isSorted = (
  sortDir: 'asc' | 'desc',
  currSortCol: PortalSortCols,
  targetSortCol: PortalSortCols,
): 'ascending' | 'descending' | undefined =>
  currSortCol === targetSortCol ? convertSort(sortDir) : undefined;
