import { designDefault } from 'src/shared/constants';
import { zoneColors } from 'src/vars';
import { IPOCDirectedGraph } from '../../../../../shared-types/pocDirectedGraph.helper';
import {
  CustomSymbolType,
  DesignModeType,
  IDesignState,
  IMiscItemBase,
  LassoMode,
  SprinklerBase,
  Tools,
} from '../../../../../shared-types/workbench-types';
import { mapEls } from '../caches/element.cache';
import { setPaperZoom } from '../components/setPaperZoom';
import { toolChangeEvent } from '../custom-events';
import { paperItemStore } from '../helpers';
import { generateGraphs } from '../helpers/directedGraph';
import { hopPipes } from '../helpers/hopping';
import { getGeneratePerfData } from '../helpers/nozzleTransformer';
import { localPaper } from '../localPaper';
import {
  centerOnPaperItem,
  hideNamedLayer,
  removeNamedItems,
  showNamedLayer,
} from '../paper-helpers/plot.helpers';
import { renderPlantOutlines } from '../plants/plants';
import { updateActivePoly } from '../polyline/polyline.service';
import { ITEMNAMES, LAYER_NAMES } from '../shared/workbench-enums';
import { renderSheetPreviews } from '../sheets/sheets';
import { newDraggableItems } from '../tools/arrow-new';
import { isPaperPipe } from '../tools/paper-items/paper-pipe';
import { isPaperSprinkler } from '../tools/paper-items/paper-sprinkler';
import { showAllYardDots, showYardDots, updateResolution } from './coverage';
import { getLocalIsMouse, setLocalIsMouse } from './mouseMode';
import { getState, setState } from './state-basics';

export const setScale = (payload: number) => {
  setState({ scale: payload });
};
export const setZoomLevel = (payload: number) => {
  setState({ zoomLevel: payload });
  setPaperZoom(payload);
};
export const setActiveYardIndex = (payload: number) => {
  if (getState().showCoverage) {
    showYardDots(false, getState().activeYardIndex);
    showYardDots(true, payload);
  }
  setState({
    activeYardIndex: payload,
  });
  updateActivePoly(payload);
};
export const setActiveMiscItem = (payload: IMiscItemBase) => {
  setState({
    activeMiscItem: payload,
  });
};
export const setCurrentZone = (payload: string) => {
  setState({ currentZone: payload });
};
export const addSprinklerBase = (payload: SprinklerBase) => {
  setState({
    sprinklerBases: [...getState().sprinklerBases, payload],
    activeBaseKey: payload.uuid,
    activeBaseRadius: getGeneratePerfData(payload)[0].radius,
  });
};
export const deleteSprinklerBase = (payload: string) => {
  setState({
    sprinklerBases: getState().sprinklerBases.filter((s) => s.uuid !== payload),
  });
};
export const setPipeAlgoVersion = (payload: string) => {
  setState({ pipeAlgoVersion: payload });
};
export const setShowHeadArcs = (show: boolean) => {
  setState({ showArcs: show });
  paperItemStore.forEach((i) => {
    if (isPaperSprinkler(i)) {
      i.toggleArc(show);
    }
  });
};
export const toggleArcsOff = () => {
  setState({ showArcs: false });
  paperItemStore.forEach((i) => {
    if (isPaperSprinkler(i)) {
      i.toggleArc(false);
    }
  });
};
export const toggleArcsOn = () => {
  setState({ showArcs: true });
  paperItemStore.forEach((i) => {
    if (isPaperSprinkler(i)) {
      i.toggleArc(true);
    }
  });
};
export const toggleCoverage = () => {
  const show = !getState().showCoverage;
  showAllYardDots(false);
  showYardDots(show, getState().activeYardIndex);
  setState({ showCoverage: show, showAllCoverage: false });
  if (show) {
    showNamedLayer(LAYER_NAMES.COVERAGE);
  } else {
    hideNamedLayer(LAYER_NAMES.COVERAGE);
  }
};
export const toggleAllCoverage = () => {
  const show = !getState().showAllCoverage;
  showAllYardDots(show);
  showYardDots(true, getState().activeYardIndex);
  setState({ showAllCoverage: show });
  if (show) {
    showNamedLayer(LAYER_NAMES.COVERAGE);
  } else {
    hideNamedLayer(LAYER_NAMES.COVERAGE);
  }
};
export const setScore = (score: number) => {
  setState({ score });
};
export const setShowGPM = (show: boolean) => {
  getState().elements.forEach((el) => {
    const paperItem = paperItemStore.get(el.uuid);
    if (paperItem && isPaperSprinkler(paperItem)) {
      paperItem.toggleGPM(show);
      paperItem.togglePrecip(show);
    }
  });
  setState({ showGPM: show });
};
export const toggleGPM = () => {
  const shouldShow = !getState().showGPM;
  getState().elements.forEach((el) => {
    const paperItem = paperItemStore.get(el.uuid);
    if (paperItem && isPaperSprinkler(paperItem)) {
      paperItem.toggleGPM(shouldShow);
      paperItem.togglePrecip(shouldShow);
    }
  });
  setState({ showGPM: shouldShow });
};
export const setShowPipeLabels = (show: boolean) => {
  getState().edges.forEach((e) => {
    const paperItem = paperItemStore.get(e.uuid);
    if (paperItem && isPaperPipe(paperItem)) {
      paperItem.toggleLabel(show);
    }
  });
  setState({ showPipeLabels: show });
};
export const togglePipeLabels = () => {
  const show = !getState().showPipeLabels;
  getState().edges.forEach((e) => {
    const paperItem = paperItemStore.get(e.uuid);
    if (paperItem && isPaperPipe(paperItem)) {
      paperItem.toggleLabel(show);
    }
  });
  setState({ showPipeLabels: show });
};
export const setLassoMode = (mode: LassoMode) => {
  setState({ lassoMode: mode });
};
export const setResolution = (payload: number) => {
  setState({
    resolution: payload,
    showCoverage: true,
    showAllCoverage: false,
  });
  updateResolution(payload);
  showYardDots(true, getState().activeYardIndex);
};

export const selectItems = (_items: paper.Item[]) => {
  // until arrow tool is removed, we need to prevent new items from being selected
  const filteredItems = _items.filter(
    (item) => !(newDraggableItems as string[]).includes(item.name),
  );
  setState({ selectedItems: filteredItems });
  if (
    filteredItems.length === 1 &&
    filteredItems[0].name === ITEMNAMES.ELEMENT
  ) {
    const el = (filteredItems[0] as paper.Group).data;
    const headZones = getState().zones.filter((z) =>
      z.headIds.includes(el.uuid),
    );
    if (headZones.length === 1) {
      setState({ currentZone: headZones[0].uuid });
    }
  }
};

export const clearSelectedItems = () => {
  setState({ selectedItems: [] });
};
export const setDesign = (payload: IDesignState) => {
  const elements = payload.elements;
  const edges = payload.edges.filter(
    (edge) =>
      !!payload.elements.find((e) => e.uuid === edge.source) &&
      !!payload.elements.find((e) => e.uuid === edge.target),
  );
  const zones = payload.zones.map((zone, i) => {
    if (!zone.color) {
      return {
        ...zone,
        color: zoneColors[i],
      };
    } else {
      return zone;
    }
  });
  const elementCache = mapEls(elements);
  const { masterGraph, pocGraphs } = generateGraphs(edges, elements, zones);
  const hoppedEdges = hopPipes(
    zones,
    edges,
    elements,
    elementCache,
    payload.scale,
    pocGraphs,
  );
  setState({
    ...designDefault,
    ...payload,
    printPreview: false,
    edges: hoppedEdges,
    elementCache,
    elements,
    zones,
    masterGraph,
    pocGraphs,
    showCoverage: false,
    showAllCoverage: false,
    selectedIDs: [],
    isMouse: getLocalIsMouse(),
    selectedItems: [],
  });
};

export const setActiveBaseKey = (payload: string) => {
  setState({
    activeBaseKey: payload,
  });
};
export const setActiveBaseRadius = (payload: number) => {
  setState({
    activeBaseRadius: payload,
  });
};
export const setOrtho = (ortho: boolean) => {
  setState({ ortho });
  removeNamedItems([ITEMNAMES.ORTHO_DOT]);
};
export const setPipeSnap = (pipeSnap: boolean) => {
  setState({ pipeSnap });
};
export const pocGraphByNode = (
  node: string,
  pocGraphs: IPOCDirectedGraph[],
): IPOCDirectedGraph | undefined => {
  return pocGraphs.find((p) => p.hasNode(node));
};

export const pocGraphByIndex = (
  index: number,
  pocGraphs: IPOCDirectedGraph[],
): IPOCDirectedGraph => {
  return pocGraphs[index];
};
export const setLoading = (payload: boolean) => {
  setState({ loading: payload });
};
export const selectAndCenterSingleID = (uuid: string) => {
  getState().selectedIDs.forEach((id) => {
    paperItemStore.get(id)?.toggleHighlight(false);
  });
  setState({ selectedIDs: [uuid] });
  const paperItem = paperItemStore.get(uuid);
  if (paperItem) {
    paperItem.toggleHighlight(true);
    centerOnPaperItem(paperItem);
  }
};
export const clearSelectedIDs = () => {
  if (getState().selectedIDs.length) {
    localPaper.project.deselectAll();
  }
  getState().selectedIDs.forEach((id) => {
    paperItemStore.get(id)?.toggleHighlight(false);
  });
  setState({ selectedIDs: [] });
};
export const refreshSelectedIDs = () => {
  const selectedIDs = [...getState().selectedIDs];
  setState({ selectedIDs });
};
export const hasSelectedID = (uuid: string) => {
  return getState().selectedIDs.includes(uuid);
};
export const addToSelectedIDs = (uuids: string[]) => {
  setState({ selectedIDs: [...getState().selectedIDs, ...uuids] });
  uuids.forEach((uuid) => {
    paperItemStore.get(uuid)?.toggleHighlight(true);
  });
};
export const removeFromSelectedIDs = (uuid: string) => {
  setState({ selectedIDs: getState().selectedIDs.filter((id) => id !== uuid) });
  paperItemStore.get(uuid)?.toggleHighlight(false);
};
export const toggleSelection = (id: string) => {
  if (hasSelectedID(id)) {
    removeFromSelectedIDs(id);
  } else {
    addToSelectedIDs([id]);
  }
};
export const setActiveTool = (activeTool: Tools) => {
  setState({ activeTool });
  toolChangeEvent(activeTool);
};
export const setLastSave = (timestamp: string) => {
  setState({ lastSave: timestamp });
};
export const setDesignMode = (mode: DesignModeType) => {
  setState({ designMode: mode });
};
export const setIsMouse = (isMouse: boolean) => {
  setState({ isMouse });
  setLocalIsMouse(isMouse);
};
export const setStrokeWidth = (strokeWidth: number) => {
  setState({ strokeWidth });
};
export const setShowPlantOutlines = (show: boolean) => {
  setState({ showPlantOutlines: show });
  renderPlantOutlines(show);
};
export const setActiveCustomSymbol = (activeCustomSymbol: CustomSymbolType) => {
  setState({ activeCustomSymbol });
};
export const setVisibleLayers = (visibleLayers: string[]) => {
  setState({ visibleLayers });
};
export const setLockedLayers = (lockedLayers: string[]) => {
  setState({ lockedLayers });
};
export const setShowSheetPreviews = (show: boolean) => {
  setState({ showSheetPreviews: show });
  renderSheetPreviews(show);
};
export const setShowValveInfoBoxNumbers = (show: boolean) => {
  setState({ showValveInfoBoxNumbers: show });
};
export const setShowHeadPreviews = (show: boolean) => {
  setState({ showHeadPreviews: show });
  paperItemStore.forEach((i) => {
    if (isPaperSprinkler(i)) {
      i.toggleHeadPreview(show);
    }
  });
};
export const setShowValveBoxPreviews = (show: boolean) => {
  setState({ showValveBoxPreviews: show });
};
