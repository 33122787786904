import { Checkbox } from 'semantic-ui-react';
import { getState, setZoneDrip, useDesignStore } from '../../state';
import { changeElements } from '../../state/changeElements';

export const ZoneDrip = () => {
  const currentZone = useDesignStore((state) =>
    state.zones.find((zone) => zone.uuid === state.currentZone),
  );
  const toggleDrip = () => {
    if (currentZone) {
      const zoneValveID = currentZone.valve;
      const el = getState().elementCache[zoneValveID];
      if (currentZone.isDrip) {
        // set lateral valve default
        const lateralValve = getState().lateralValve;
        if (lateralValve) {
          changeElements([{ ...el, props: lateralValve }]);
        }
      } else {
        // set drip valve default
        const dripValve = getState().dripValve;
        if (dripValve) {
          changeElements([{ ...el, props: dripValve }]);
        }
      }
      setZoneDrip(!currentZone.isDrip, currentZone.uuid);
    }
  };
  return (
    <Checkbox
      onClick={toggleDrip}
      checked={currentZone?.isDrip}
      label="Set Drip"
    />
  );
};
