import {
  DesignElement,
  DesignElementMap,
  GraphStructure,
  IBackflowProduct,
  IEdge,
  IMeter,
  IMiscItem,
  IPRV,
  IPipeProduct,
  IPoC,
  IPump,
  IValveProduct,
  WaterGroup,
  Zone,
} from '@shared-types';
import { getEuclideanDistance } from 'src/shared';
import { compact } from 'underscore';
import { IPOCDirectedGraph } from '../../../../../shared-types/pocDirectedGraph.helper';
import {
  DesignSprinklerElement,
  GroupLossTable,
  ZoneLossTable,
} from '../../../../../shared-types/workbench-types';
import { getState } from '../state';
import { isSprinkler } from '../tools/paper-items/paper-sprinkler';
import {
  findEdge,
  getLossFromFlow,
  getRequiredGPM,
  pocMainsLoss,
} from './directedGraph';
import { calcFrictionLoss } from './geometry.helpers';
import { meterGPM, pocMeterLoss } from './meter.helper';
import { UndirectedGraph } from './undirectedGraph';

export class POCDirectedGraph
  extends UndirectedGraph
  implements IPOCDirectedGraph
{
  graph: GraphStructure = {};
  pocRoot: string = '';
  lateralNodes = new Set<string>();
  dripNodes = new Set<string>();
  // caching locally so we don't refer to state
  localElements: DesignElementMap = {};
  localZones: Zone[] = [];
  dripValveIDs = new Set<string>();
  turfValveIDs = new Set<string>();

  constructor(
    root: string,
    graph: GraphStructure,
    elements: DesignElement[],
    zones: Zone[],
  ) {
    super();
    // we are setting so many local things here because these graphs are set along with other
    // things in state, like zones, elements, etc.
    this.localZones = zones;
    this.localElements = elements.reduce((acc, el) => {
      acc[el.uuid] = el;
      return acc;
    }, {});

    zones.forEach((zone) => {
      if (zone.isDrip) {
        this.dripValveIDs.add(zone.valve);
      } else {
        this.turfValveIDs.add(zone.valve);
      }
    });

    this.graph = graph;
    this.pocRoot = root;
    this.addGraphNode(root);
    // force direct the graph just in case an undirected graph is passed in
    this.graph = this.directGraph(root);
    this.setBelowValveNodes();
  }

  get nodes() {
    return Object.keys(this.graph);
  }

  setBelowValveNodes = () => {
    // TODO: This one takes a while...find a way to speed it up
    this.nodes.forEach((node) => {
      this.graph[node].forEach((child) => {
        if (this.lateralNodes.has(node) || this.dripNodes.has(node)) {
          return;
        } else {
          if (!this.lateralNodes.has(node) && this.edgeIsLateral(node, child)) {
            this.lateralNodes.add(node);
          } else if (
            !this.dripNodes.has(node) &&
            this.edgeIsDrip(node, child)
          ) {
            this.dripNodes.add(node);
          }
        }
      });
    });
  };

  addGraphEdge = (a: string, b: string) => {
    // we know which one is already in the dag, so that is the parent
    if (this.graph[a] && !this.graph[b]) {
      this.graph[a].add(b);
      this.graph[b] = new Set();
    } else if (!this.graph[a] && this.graph[b]) {
      this.graph[b].add(a);
      this.graph[a] = new Set();
    }
  };

  descendantsOfNode = (
    node: string,
    descendants = new Set<string>(),
  ): Set<string> => {
    const nodes = this.graph[node] || new Set<string>();
    nodes.forEach((n: string) => {
      descendants.add(n);
      this.descendantsOfNode(n, descendants);
    });
    return descendants;
  };
  leafs = (): string[] => {
    return this.nodes.filter((key) => !this.nodeHasChildren(key));
  };
  leafDescendantsOfNode = (node: string): string[] => {
    const leafs = this.leafs();
    const descendantSet = this.descendantsOfNode(node);
    return leafs.filter((leaf) => descendantSet.has(leaf));
  };
  nodeHasChildren = (node: string): boolean => {
    return this.graph[node] && this.graph[node].size > 0;
  };
  nodeIsLeaf = (node: string) => {
    return !!node && this.hasNode(node) && !this.nodeHasChildren(node);
  };
  nodeIsLeafCap = (node: string): boolean => {
    const el = this.localElements[node];
    return (
      el &&
      this.nodeIsLeaf(node) &&
      !(
        isSprinkler(el) ||
        (el.type === 'miscItem' &&
          (el.props as IMiscItem).name === 'Drip Transition') ||
        (el.type === 'miscItem' && !!(el.props as IMiscItem).gpmEffect)
      )
    );
  };
  rootGPM = (pipeProducts: IPipeProduct[]): number => {
    let gpm = 0;
    const pocEl = this.localElements[this.pocRoot];
    if (pocEl) {
      const poc = pocEl.props as IPoC;
      if (poc.measuredGPM) {
        gpm = poc.measuredGPM;
      } else if (poc.sourceID) {
        const sourceEl = this.localElements[poc.sourceID];
        if (sourceEl && sourceEl.type === 'pump') {
          gpm = (sourceEl.props as IPump).outputGPM;
        } else if (sourceEl && sourceEl.type === 'booster pump') {
          gpm = (sourceEl.props as IPump).outputGPM;
        }
        if (sourceEl && sourceEl.type === 'meter') {
          gpm = meterGPM(sourceEl.props as IMeter, pipeProducts, poc);
        } else {
          console.error(
            'no element of the right type found when getting POC GPM',
          );
        }
      } else {
        console.error('no measured GPM or source ID');
      }
    }
    return gpm;
  };
  _flip = (a: string, b: string) => {
    if (
      !(this.graph[b] && this.graph[b].has(a)) &&
      !(this.graph[a] && this.graph[a].has(b))
    ) {
      console.error('this edge does not exist');
    }
    // flip src and target correctly so we're always pointing downstream
    let src = a;
    let trg = b;
    if (this.graph[b] && this.graph[b].has(a)) {
      src = b;
      trg = a;
    }
    return { src, trg };
  };
  getEdgeMaxGPM = (
    a: string,
    b: string,
    pipeProducts: IPipeProduct[],
    groups: WaterGroup[],
  ): number => {
    // this returns the max GPM that would ever flow through this pipe
    let { src, trg } = this._flip(a, b);
    // descendants is a list of potential things contributing to the GPM running through this pipe
    const descendants = new Set([trg, ...this.descendantsOfNode(trg)]);
    // if any descendant is a cap, then this pipe needs to handle the max GPM
    if (
      this.leafs().some(
        (leaf) => descendants.has(leaf) && this.nodeIsLeafCap(leaf),
      )
    ) {
      return this.rootGPM(pipeProducts);
    }
    // get sum of gpm used by heads of any descendant valve (if main)
    const descendantValveSums = [...descendants]
      .filter((id) => {
        const el = this.localElements[id];
        return el && el.type === 'valve';
      })
      .map((id) =>
        getRequiredGPM([...this.descendantsOfNode(id)], this.localElements),
      );

    // get sum of gpm used by all descendant VIH rotor groups (if main)
    const descendantHeadsByGroupSums: number[] = groups
      .map((group) => group.headIds.filter((id) => descendants.has(id)))
      .map((headIDs) => getRequiredGPM(headIDs, this.localElements));

    // if path endpoint has any valve ancestor, then sum all the descendants' GPMs
    let descendantGPMsum = 0;
    if (this.nodeIsLateral(src)) {
      descendantGPMsum = getRequiredGPM([...descendants], this.localElements);
    }
    return Math.max(
      ...descendantValveSums,
      ...descendantHeadsByGroupSums,
      descendantGPMsum,
      -1,
    );
  };
  nodeIsLateral = (node: string) => {
    return this.lateralNodes.has(node);
  };
  edgeIsLateral = (a: string, b: string): boolean => {
    // TODO: this is probably returning before the chance to check if it's a drip vs turf valve
    return !!this.edgeUpstreamValve(a, b);
  };
  nodeIsDrip = (node: string) => {
    return this.dripNodes.has(node);
  };
  edgeIsDrip = (a: string, b: string): boolean => {
    const valve = this.edgeUpstreamValve(a, b);
    return !!valve && this.dripValveIDs.has(valve);
  };
  edgeUpstreamValve = (a: string, b: string): string | undefined => {
    const { trg } = this._flip(a, b);
    const ancestorPath = this.pathToDest(this.pocRoot, trg);

    for (let i = ancestorPath.length - 2; i >= 0; i--) {
      const id = ancestorPath[i];
      const el = this.localElements[id];
      if (el && el.type === 'valve') {
        return id;
      }
    }

    return undefined;
  };
  frictionLossAtPipe = (
    a: string,
    b: string,
    pipeProducts: IPipeProduct[],
    edges: IEdge[],
    gpm: number,
  ): number => {
    const edge = findEdge(edges, a, b);
    if (edge) {
      const pipe = pipeProducts.find((p) => p.uuid === edge.pipe);
      if (pipe) {
        const distance = getEuclideanDistance(
          this.localElements[a].position,
          this.localElements[b].position,
        );
        return calcFrictionLoss(
          pipe.coefficient,
          pipe.insideDiameter,
          distance,
          gpm,
        );
      }
    }
    return -1;
  };
  psiRoot = (node: string): string => {
    // get the element that is providing the "available" PSI to this node
    const pathToRoot = this.pathToDest(this.pocRoot, node);
    pathToRoot.reverse();
    const psiProviders = ['prv', 'booster pump', 'valve'];
    for (let n of pathToRoot) {
      // in case we are looking for the PSI provider of a PSI provider
      const foundPSIProviders = psiProviders.find(
        (e) =>
          node !== this.localElements[n].uuid &&
          e === this.localElements[n].type,
      );
      if (
        foundPSIProviders &&
        (this.localElements[n].props as IValveProduct | IPump | IPRV)
          .outputPSI > 0
      ) {
        return n;
      }
      if (this.localElements[n].type === 'poc') {
        return n;
      }
    }
    return this.pocRoot;
  };
  pathFromPSIProvider = (node: string) => {
    return this.pathToDest(this.psiRoot(node), node);
  };
  psiOfPSIProvider = (
    node: string,
    pipeProducts: IPipeProduct[],
    edges: IEdge[],
    gpm: number,
    backflowProducts: IBackflowProduct[],
  ) => {
    const psiNode = this.psiRoot(node);
    const el = this.localElements[psiNode];
    // returns the PSI this node is providing
    if (el.type === 'prv') {
      return (el.props as IPRV).outputPSI;
    } else if (el.type === 'booster pump') {
      return this.boosterPumpOutput(
        el,
        edges,
        gpm,
        backflowProducts,
        pipeProducts,
      );
    } else if (el.type === 'poc') {
      return this.pocPSI(pipeProducts);
    } else if (el.type === 'valve' && (el.props as IValveProduct).outputPSI) {
      return (el.props as IValveProduct).outputPSI;
    }
    return -1;
  };
  boosterPumpOutput = (
    el: DesignElement,
    edges: IEdge[],
    gpm: number,
    backflowProducts: IBackflowProduct[],
    pipeProducts: IPipeProduct[],
  ): number => {
    /*
      a booster pump adds its psi its source. however,
      its source may have other losses (friction, elevation, etc)
    */
    const psiProviderID = this.psiRoot(el.uuid);
    const psiProvider = this.localElements[psiProviderID];
    const psi = this.psiOfPSIProvider(
      psiProviderID,
      pipeProducts,
      edges,
      gpm,
      backflowProducts,
    );
    const path = this.pathFromPSIProvider(el.uuid);
    const frictionLoss = path.reduce((acc, cur, i, arr) => {
      if (i === 0) {
        return acc;
      }
      const prev = arr[i - 1];
      const fl = this.frictionLossAtPipe(
        prev,
        cur,
        pipeProducts,
        edges,
        this.rootGPM(pipeProducts),
      );
      return acc + fl;
    }, 0);
    let elevationLoss = 0;
    if (
      psiProvider.type === 'poc' &&
      (el.props as IPump).elevation &&
      (psiProvider.props as IPoC).elevation
    ) {
      elevationLoss =
        (((el.props as IPump).elevation as number) -
          ((psiProvider.props as IPoC).elevation as number)) *
        0.433;
    }
    const backflowLoss = this._backflowLoss(el, backflowProducts, gpm);
    return (
      psi -
      elevationLoss -
      frictionLoss -
      backflowLoss +
      (el.props as IPump).outputPSI
    );
  };
  pocPSI = (pipeProducts: IPipeProduct[]) => {
    let psi = 0;
    const gpm = this.rootGPM(pipeProducts);
    const rootEl = this.localElements[this.pocRoot];
    if (rootEl && rootEl.type === 'poc') {
      let pocEl = rootEl;
      const poc = pocEl.props as IPoC;
      if (poc.measuredPSI) {
        psi = poc.measuredPSI;
      } else if (poc.staticPSI) {
        if (!poc.sourceID || (poc.sourceID && !poc.mainsToSource.length)) {
          psi = -1;
          console.error(
            'this POC does not have enough information to calculate the PSI...',
          );
        } else {
          psi = poc.staticPSI;
          const sourceNode = this.localElements[poc.sourceID];
          if (sourceNode && sourceNode.type === 'meter') {
            const meterLosses = pocMeterLoss(
              pocEl,
              pipeProducts,
              gpm,
              this.localElements,
            );
            // remove meter losses from static PSI
            psi -=
              meterLosses.meterLoss +
              meterLosses.serviceLineLoss +
              meterLosses.elevationLoss;
          } else if (sourceNode && sourceNode.type === 'pump') {
            const pump = sourceNode.props as IPump;
            // override static with pump's PSI
            psi = pump.outputPSI;
          }
          // subtract loss from mains between source and POC
          const mainsLoss = pocMainsLoss(poc.mainsToSource, gpm);
          psi -= mainsLoss;
        }
      } else {
        psi = -1;
        console.log(
          'the POC does not have enough information to calculate the PSI.',
        );
      }
    }
    return psi;
  };
  _backflowLoss = (
    el: DesignElement,
    backflowProducts: IBackflowProduct[],
    gpm: number,
  ): number => {
    const backflow = el.props as IBackflowProduct;
    if (backflow) {
      const perfData = backflowProducts.filter(
        (v) =>
          `${v.brand} ${v.name} ${v.size}` ===
          `${backflow.brand} ${backflow.name} ${backflow.size}`,
      );
      return getLossFromFlow(perfData, gpm);
    }
    return 0;
  };
  _valveLoss = (
    el: DesignElement,
    valveProducts: IValveProduct[],
    gpm: number,
  ): number => {
    const valve = valveProducts.find(
      (v) => v.uuid === (el.props as IValveProduct).uuid,
    );
    if (valve) {
      const name = `${valve.brand} ${valve.name}`;
      const perfData = valveProducts.filter(
        (v) => `${v.brand} ${v.name}` === name,
      );
      return getLossFromFlow(perfData, gpm);
    }
    return 0;
  };
  candidatesForZoneLoss = (zone: Zone): string[] => {
    const leafDescendants = this.leafDescendantsOfNode(zone.valve);
    const leafEls = leafDescendants.map((leaf) => this.localElements[leaf]);
    const isCandidate = (el: DesignElement) =>
      isSprinkler(el) ||
      (el.type === 'miscItem' && !!(el.props as IMiscItem).gpmEffect);
    const candidates = leafEls.filter(isCandidate).map((el) => el.uuid);
    const otherCaps = leafEls.filter((el) => !isCandidate(el));
    otherCaps.forEach((el) => {
      const path = this.pathToDest(zone.valve, el.uuid);
      path.reverse();
      const firstCandidate = path.find((id) =>
        isCandidate(this.localElements[id]),
      );
      if (firstCandidate) {
        candidates.push(firstCandidate);
      }
    });
    return candidates;
  };
  candidatesForGroupLoss = (group: WaterGroup): string[] => {
    const leafDescendants = this.leafDescendantsOfNode(this.pocRoot);
    const leafEls = leafDescendants.map((leaf) => this.localElements[leaf]);
    const isCandidate = (el: DesignElement) =>
      isSprinkler(el) && group.headIds.includes(el.uuid);
    const candidates = leafEls.filter(isCandidate).map((el) => el.uuid);
    const otherCapsAndHeadsFromOtherGroups = leafEls.filter(
      (el) => !isCandidate(el),
    );
    otherCapsAndHeadsFromOtherGroups.forEach((el) => {
      const path = this.pathToDest(this.pocRoot, el.uuid);
      path.reverse();
      const firstCandidate = path.find((id) =>
        isCandidate(this.localElements[id]),
      );
      if (firstCandidate) {
        candidates.push(firstCandidate);
      }
    });
    return candidates;
  };
  lossesAtZone = (
    zone: Zone,
    pipeProducts: IPipeProduct[],
    valveProducts: IValveProduct[],
    backflowProducts: IBackflowProduct[],
    edges: IEdge[],
  ): ZoneLossTable[] => {
    let staticLoss = (zone.staticLossItems || []).reduce(
      (acc, item) => acc + item.loss,
      0,
    );
    let zoneNumber = zone.orderNumber + 1;
    let poc = this.pocRoot;
    let psiAtPOC = this.pocPSI(pipeProducts);
    let psiAtRegulator = psiAtPOC;
    let psiRegulator = this.psiRoot(zone.valve);
    let elevationLoss = 0;
    const psiRegulatorEl = this.localElements[psiRegulator];

    if (zone.elevation) {
      const elevationAtSource = zone.elevationAtValve
        ? zone.elevationAtValve
        : (psiRegulatorEl.props as IPoC | IPRV | IPump).elevation;
      elevationLoss = (zone.elevation - (elevationAtSource || 0)) * 0.433;
    }
    const candidates = this.candidatesForZoneLoss(zone);
    const valveDescendants = [...this.descendantsOfNode(zone.valve)];
    const fullZoneGPM = getRequiredGPM(valveDescendants, this.localElements);

    return candidates.map((node) => {
      let lateralLoss = 0;
      let backflowLoss = 0;
      let valveLoss = 0;
      let mainLoss = 0;
      let headLoss = 0;
      psiRegulator = this.psiRoot(node);
      psiAtRegulator = this.psiOfPSIProvider(
        node,
        pipeProducts,
        edges,
        fullZoneGPM,
        backflowProducts,
      );
      const path = this.pathFromPSIProvider(node);

      path.forEach((pathNode, i) => {
        if (i < path.length - 1) {
          const nextNode = path[i + 1];
          const nn = this.localElements[nextNode];
          if (isSprinkler(nn)) {
            headLoss = Math.max(headLoss, nn.props.base.outputPSI);
          }
          if (nn.type === 'valve') {
            valveLoss = this._valveLoss(nn, valveProducts, fullZoneGPM);
          }
          if (nn.type === 'backflow') {
            backflowLoss = this._backflowLoss(
              nn,
              backflowProducts,
              fullZoneGPM,
            );
          }
          if (this.nodeIsLateral(pathNode)) {
            const desc = [nextNode, ...this.descendantsOfNode(nextNode)];
            const frictionLossInPipe = this.frictionLossAtPipe(
              pathNode,
              nextNode,
              pipeProducts,
              edges,
              getRequiredGPM(desc, this.localElements),
            );
            lateralLoss += frictionLossInPipe;
          } else {
            const frictionLossInPipe = this.frictionLossAtPipe(
              pathNode,
              nextNode,
              pipeProducts,
              edges,
              fullZoneGPM,
            );
            mainLoss += frictionLossInPipe;
          }
        }
      });
      const mainFittingsLoss = mainLoss * 0.1;
      const lateralFittingsLoss = lateralLoss * 0.1;
      const totalLoss =
        lateralLoss +
        mainLoss +
        headLoss +
        elevationLoss +
        valveLoss +
        backflowLoss +
        lateralFittingsLoss +
        mainFittingsLoss +
        staticLoss;
      const residualPSI = psiAtRegulator - totalLoss;
      return {
        backflowLoss,
        valveLoss,
        residualPSI,
        staticLoss,
        zoneNumber,
        poc,
        psiRegulator,
        candidateID: node,
        psiAtRegulator,
        mainLoss,
        mainFittingsLoss,
        lateralLoss,
        lateralFittingsLoss,
        elevationLoss,
        totalLoss,
        headLoss,
      };
    });
  };
  lossesAtGroup = (
    group: WaterGroup,
    pipeProducts: IPipeProduct[],
    backflowProducts: IBackflowProduct[],
    edges: IEdge[],
  ): GroupLossTable[] => {
    let staticLoss = (group.staticLossItems || []).reduce(
      (acc, item) => acc + item.loss,
      0,
    );
    let groupNumber = group.orderNumber + 1;
    let poc = this.pocRoot;
    let psiAtPOC = this.pocPSI(pipeProducts);
    let psiAtRegulator = psiAtPOC;
    let psiRegulator = this.pocRoot;
    let elevationLoss = 0;
    const pocEl = this.localElements[poc];
    if (group.elevation) {
      elevationLoss =
        (group.elevation - ((pocEl.props as IPoC).elevation || 0)) * 0.433;
    }
    const candidates = this.candidatesForGroupLoss(group);
    return candidates.map((node) => {
      let backflowLoss = 0;
      let mainLoss = 0;
      let headLoss = 0;
      psiRegulator = this.psiRoot(node);
      const gpm = getRequiredGPM(group.headIds, this.localElements);

      psiAtRegulator = this.psiOfPSIProvider(
        node,
        pipeProducts,
        edges,
        gpm,
        [],
      );
      const path = this.pathFromPSIProvider(node);
      path.forEach((pathNode, i) => {
        if (i < path.length - 1) {
          const nextNode = path[i + 1];
          const headDescendants = [...this.descendantsOfNode(pathNode)].filter(
            (id) => group.headIds.includes(id),
          );
          const gpm = getRequiredGPM(headDescendants, this.localElements);
          const nn = this.localElements[nextNode];
          if (isSprinkler(nn)) {
            headLoss = Math.max(headLoss, nn.props.base.outputPSI);
          }
          if (nn.type === 'backflow') {
            // backflow should use full GPM of group
            backflowLoss = this._backflowLoss(nn, backflowProducts, gpm);
          }

          // main loss for group should be using all descendants of the node that are in the group
          const frictionLossInPipe = this.frictionLossAtPipe(
            pathNode,
            nextNode,
            pipeProducts,
            edges,
            gpm,
          );
          mainLoss += frictionLossInPipe;
        }
      });
      const mainFittingsLoss = mainLoss * 0.1;
      const totalLoss =
        mainLoss +
        headLoss +
        elevationLoss +
        backflowLoss +
        mainFittingsLoss +
        staticLoss;
      const residualPSI = psiAtRegulator - totalLoss;
      return {
        backflowLoss,
        residualPSI,
        staticLoss,
        groupNumber,
        poc,
        psiAtPOC,
        psiRegulator,
        candidateID: node,
        psiAtRegulator,
        mainLoss,
        mainFittingsLoss,
        elevationLoss,
        totalLoss,
        headLoss,
      };
    });
  };
  hasSplitHeads = (): boolean => {
    const graphedEls = Object.keys(this.graph).map(
      (id) => this.localElements[id],
    );
    const heads = graphedEls.filter(isSprinkler);
    return heads.some((head) => this.graph[head.uuid].size > 2);
  };
  headsWhoseUpstreamValveIsNotTheirs = (): DesignSprinklerElement[] => {
    const graphedEls = Object.keys(this.graph).map(
      (id) => this.localElements[id],
    );
    const heads = graphedEls.filter(isSprinkler);
    const zones = getState().zones;
    return heads.filter((head) => {
      const zone = zones.find((z) => z.headIds.includes(head.uuid));
      const zoneValve = zone?.valve;
      const path = this.pathToDest(this.pocRoot, head.uuid);
      return !zoneValve || (zoneValve && !path.includes(zoneValve));
    });
  };
  has4Way = (): boolean => {
    const graphedEls = Object.keys(this.graph).map(
      (id) => this.localElements[id],
    );
    return graphedEls.some((el) => this.graph[el.uuid].size > 2);
  };
  hasUnvalvedHeads = (): boolean => {
    const valveIDs = new Set();
    Object.keys(this.localElements).forEach((n) => {
      const el = this.localElements[n];
      if (el.type === 'valve') {
        valveIDs.add(el.uuid);
      }
    });
    const graphedEls = compact(
      Object.keys(this.graph).map((id) => this.localElements[id]),
    );
    const heads = graphedEls.filter(isSprinkler);
    return !heads.every((head) =>
      this.pathToDest(this.pocRoot, head.uuid).some((id) => valveIDs.has(id)),
    );
  };
  getMains = (): string[][] => {
    // return back an array of [src, trg] mainlines
    const mains = new Set<string>();
    const leafs = this.leafs();
    leafs.forEach((leaf) => {
      const path = this.pathToDest(this.pocRoot, leaf);
      for (let i = 0; i < path.length; i++) {
        if (i < path.length - 1) {
          const src = path[i];
          const trg = path[i + 1];
          mains.add(`${src}:${trg}`);
          if (this.localElements[trg].type === 'valve') {
            break;
          }
        }
      }
    });
    return [...mains].map((m) => m.split(':'));
  };
  getLaterals = (valveID: string): string[][] => {
    if (!this.hasNode(valveID)) {
      console.error('this valve is not in this graph');
      return [];
    }
    const valveDescendants = this.descendantsOfNode(valveID);
    // return back an array of [src, trg] mainlines
    const laterals = new Set<string>();
    const leafs = this.leafs();
    leafs.forEach((leaf) => {
      if (valveDescendants.has(leaf)) {
        const path = this.pathToDest(valveID, leaf);
        for (let i = 0; i < path.length; i++) {
          if (i < path.length - 1) {
            const src = path[i];
            const trg = path[i + 1];
            laterals.add(`${src}:${trg}`);
          }
        }
      }
    });
    return [...laterals].map((l) => l.split(':'));
  };
  getDownstreamEdges = (node: string): [string, string][] => {
    let edges: [string, string][] = [];
    const downstreamNodes: string[] = [node, ...this.descendantsOfNode(node)];
    for (let n of downstreamNodes) {
      this.graph[n].forEach((child) => {
        edges.push([n, child]);
      });
    }
    return edges;
  };
}
