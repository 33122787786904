import { SleeveToolProperties } from '../features/sleeves/SleeveToolProperties';
import { PlantToolProperties } from '../plants/PlantToolProperties';
import { useDesignStore } from '../state';
import { TextToolProperties } from '../texts/TextToolProperties';
import { DrawCircleProperties } from './DrawCircleProperties';
import { DrawCustomSymbolProperties } from './DrawCustomSymbolProperties';
import { DrawPolylineProperties } from './DrawPolylineProperties';
import { DrawRectangleProperties } from './DrawRectangleProperties';
import { ExportTrigger } from './ExportTrigger';
import { GridToolProperties } from './GridToolProperties';
import { HeadToolProperties } from './HeadToolProperties';
import { PanelDivider, PanelWrap } from './PanelButton';
import { PipeToolProperties } from './PipeToolProperties';
import { SelectionToolProperties } from './SelectionToolProperties';
import { TooltipMenu } from './TooltipMenu';
import { ValveBoxToolProperties } from './ValveBoxToolProperties';

export const PropertiesPanel = () => {
  const selectedIDs = useDesignStore((state) => state.selectedIDs);
  const selectedItems = useDesignStore((state) => state.selectedItems);
  const activeTool = useDesignStore((state) => state.activeTool);

  return (
    <>
      {activeTool === 'Head Placement' && <HeadToolProperties />}
      {activeTool === 'grid' && <GridToolProperties />}
      {activeTool === 'Plant placement' && <PlantToolProperties />}
      {activeTool === 'Sleeve' && <SleeveToolProperties />}
      {activeTool === 'Pipes' && <PipeToolProperties />}
      {activeTool === 'Valve Location' && <ValveBoxToolProperties />}
      {activeTool === 'text-tool' && <TextToolProperties />}
      {activeTool === 'polyline' && <DrawPolylineProperties />}
      {activeTool === 'circle' && <DrawCircleProperties />}
      {activeTool === 'rectangle' && <DrawRectangleProperties />}
      {activeTool === 'custom-symbol' && <DrawCustomSymbolProperties />}
      {(activeTool === 'Lasso' || activeTool === 'Marquee') && (
        <SelectionToolProperties />
      )}

      {selectedIDs.length > 0 ||
      selectedItems.length > 0 ||
      activeTool !== 'Arrow' ? (
        <TooltipMenu />
      ) : (
        <PanelWrap>
          <div className="properties">
            {/* <Basemap /> */}
            <ExportTrigger />
            <PanelDivider />
            {/* <BasemapUploader /> */}
            {/* <LayerPanel /> */}
          </div>
        </PanelWrap>
      )}
    </>
  );
};
