import { ISleeve } from '@shared-types';
import { getState, setState } from '../../../state';
import { sleeveService } from '../sleeve.service';

export const bulkAddSleeves = (sleeves: ISleeve[]) => {
  const updatedItems = getState().items.concat(sleeves);
  setState({
    items: updatedItems,
    selectedIDs: [],
  });
  sleeves.forEach((sleeve) => {
    sleeveService.renderItemToPaper(sleeve);
  });
};
