import { IDesignState } from '@shared-types';
import { format } from 'date-fns';
import { Column, Content, ContentStack } from 'pdfmake/interfaces';
import { TitleBarConfig } from '../../../../../shared-types/workbench-types';
import {
  createNorthArrow,
  createScaleBar,
  designFooterText,
} from './pdf/pdf.helpers';

export const masterSidebarNew = (
  pdfTitle: string,
  state: IDesignState,
  base64Logo: string,
  scale: number,
  config: TitleBarConfig,
  dateOverride?: string,
  index?: number,
): Content[] => {
  const { contractorName, contractorAddress, clientAddress, northRotation } =
    state;
  const date = dateOverride || format(new Date(), 'MMM d, yyyy');
  const footer: Content = {
    text: designFooterText(contractorName),
    fontSize: 8,
    alignment: 'left',
    margin: [0, 100],
  };
  let sheetNum: Content = '';
  if (index !== undefined && index > -1) {
    sheetNum = {
      text: `Sheet ${index + 1}`,
      fontSize: 26,
      alignment: 'center',
      margin: [0, 0, 0, 0],
      bold: true,
    };
  }
  const lines = (n: number) => {
    const l: any[] = [];
    const lineHeight = 36;
    for (let i = 0; i < n; i++) {
      l.push({
        type: 'line',
        x1: 0,
        y1: i * lineHeight,
        x2: (config.titleBarWidth - config.pageMargin) * 72,
        y2: i * lineHeight,
        lineWidth: 1,
        lineColor: '#6B6B6B',
      });
    }
    return l;
  };
  const separatorLine: Column = {
    width: 1,
    margin: [0, 0, 0, 0],
    canvas: [
      {
        type: 'line',
        x1: 0,
        y1: config.pageMargin * 72,
        x2: 0,
        y2: (config.printHeight - config.pageMargin) * 72,
        lineWidth: 1,
        lineColor: '#cccccc',
      },
    ],
  };
  const stack: Column = {
    margin: [config.pageMargin * 72, 50, 0, 0],
    stack: [
      {
        text: pdfTitle,
        alignment: 'center',
        bold: true,
        fontSize: 26,
      },
      {
        text: clientAddress,
        alignment: 'center',
        fontSize: 18,
        margin: [0, 20, 0, 40],
      },
      {
        text: 'Date: ' + date,
        alignment: 'center',
        fontSize: 14,
        margin: [0, 0, 0, 50],
      },
      createScaleBar(),
      {
        text: `Scale: 1" = ${72 / scale}'`,
        alignment: 'center',
        fontSize: 14,
        margin: [0, 0, 0, 50],
      },
      createNorthArrow(northRotation),
      {
        stack: [
          {
            text: 'Revision information and notes',
            alignment: 'center',
            margin: [0, 50, 0, 0],
          },
          {
            canvas: lines(7),
            margin: [0, 30, 0, 100],
          },
        ],
      },
      {
        stack: [
          base64Logo
            ? {
                image: base64Logo,
                fit: [170, 150],
                alignment: 'center',
              }
            : {},
          {
            text: contractorName,
            bold: true,
            fontSize: 20,
            alignment: 'center',
            margin: [0, 0, 0, 10],
          },
          {
            text: contractorAddress,
            alignment: 'center',
            fontSize: 14,
          },
        ],
        margin: [0, 30],
      } as ContentStack,
      sheetNum,
      footer,
    ],
  };
  return [separatorLine, stack];
};
