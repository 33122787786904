import paper from 'src/paper';
import { localPaper, localZoom } from '../localPaper';
import { findNamedItems, getColor } from '../paper-helpers/plot.helpers';

import { BaseItem, DesignElement } from '@shared-types';
import { takeUntil } from 'rxjs';
import { ITEMNAMES } from '../shared/workbench-enums';
import {
  addToSelectedIDs,
  clearSelectedIDs,
  getState,
  selectItems,
} from '../state';
import { MouseDownPayload, ToolService } from '../tool.service';

export const setupLassoTool = (toolService: ToolService) => {
  let lassoPath: paper.Path;
  let closePath: paper.Path;

  const finalizeLasso = (paperItems: paper.Item[], items?: BaseItem[]) => {
    selectItems(paperItems);
    clearSelectedIDs();
    if (items) {
      addToSelectedIDs(items.map((i) => i.uuid));
    }
    paperItems.forEach((i) => (i.selected = true));
    if (lassoPath) {
      lassoPath.remove();
    }
    if (closePath) {
      closePath.remove();
    }
    if (!paperItems.length) {
      localPaper.project.deselectAll();
    }
    if (!items?.length) {
      clearSelectedIDs();
    }
  };
  const finalizePlantLasso = (items: paper.Item[]) => {
    clearSelectedIDs();
    addToSelectedIDs(items.map((i) => i.data.uuid));
    if (lassoPath) {
      lassoPath.remove();
    }
    if (closePath) {
      closePath.remove();
    }
    if (!items.length) {
      clearSelectedIDs();
      localPaper.project.deselectAll();
    }
  };

  const onMouseDown = ({ e }: MouseDownPayload) => {
    if (lassoPath) {
      finalizeLasso([]);
    }
    lassoPath = new paper.Path({
      segments: [e.point],
      strokeWidth: 1 / localZoom(),
      strokeColor: getColor('red'),
    });
  };

  const onKeyUp = (e: paper.KeyEvent) => {
    if (e.key === 'escape') {
      finalizeLasso([]);
    }
  };
  const onMouseDrag = (e) => {
    if (e.modifiers.space) {
      // pan(e);
    } else {
      if (lassoPath) {
        lassoPath.add(e.point);
        if (closePath) {
          closePath.remove();
        }
        closePath = new paper.Path({
          segments: [lassoPath.firstSegment, e.point],
          strokeColor: '#999',
          strokeWidth: 1 / localZoom(),
        });
      }
    }
  };

  const onMouseUp = () => {
    const { lassoMode } = getState();
    lassoPath.closePath();
    if (closePath) {
      closePath.remove();
    }
    if (lassoMode === 'Heads') {
      const elements = findNamedItems(ITEMNAMES.ELEMENT);
      const filteredEls = elements.filter(
        (el) =>
          el.visible &&
          (el.data as DesignElement).type === 'sprinkler' &&
          lassoPath.contains(el.position),
      );
      finalizeLasso(filteredEls);
    } else if (lassoMode === 'All') {
      const elements = findNamedItems(ITEMNAMES.ELEMENT);
      const items = getState().items;
      const filteredEls = elements.filter(
        (el) => el.visible && lassoPath.contains(el.position),
      );
      const filteredItems = items.filter((item) =>
        lassoPath.contains(item.position),
      );
      finalizeLasso(filteredEls, filteredItems);
    } else if (lassoMode === 'Plants') {
      const plants = findNamedItems('plant');
      const filteredPlants = plants.filter(
        (plant) => plant.visible && lassoPath.contains(plant.position),
      );
      finalizePlantLasso(filteredPlants);
    }
  };

  toolService
    .keyUp$('Lasso')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onKeyUp);
  toolService
    .mouseDown$('Lasso')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseDown);
  toolService
    .mouseDrag$('Lasso')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseDrag);
  toolService
    .mouseUp$('Lasso')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseUp);
};
