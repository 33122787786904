import {
  DesignElement,
  IMiscItem,
  IPoint,
  ValveBox,
  Zone,
} from '@shared-types';
import { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  DropdownProps,
  Form,
  Modal,
} from 'semantic-ui-react';
import paper from 'src/paper';
import styled from 'styled-components';
import _, { compact, uniq } from 'underscore';
import { PanelButton } from '../../components/PanelButton';
import { defaultItem } from '../../helpers';
import { makeHull } from '../../helpers/convex-hull';
import { createDefaultEdge } from '../../paper-helpers/edges';
import { getColor } from '../../paper-helpers/plot.helpers';
import { getEuclideanDistance, rotateAroundPoint } from '../../shared/geometry';
import { getState, overrideInfoBox } from '../../state';
import { addElementsEdges } from '../../state/addElementsEdges';
import { addZoneToValveBox } from '../../state/valvebox/addZoneToValveBox';
import { changeValveBox } from '../../state/valvebox/changeValveBox';
import { removeZoneFromValveBox } from '../../state/valvebox/removeZoneFromValveBox';
import { moveItemInArray } from './ValveBoxList';

export const ValveBoxLocationEditor = ({ item }: { item: paper.Group }) => {
  const {
    valveBoxes,
    pipeProducts,
    edges,
    scale,
    mainPipe,
    mainPipeSizes,
    elementCache,
    zones,
  } = getState();
  const [valveBox, setValveBox] = useState<ValveBox | null>(null);
  const [changeSize, setChangeSize] = useState(false);

  useEffect(
    () => {
      const vb = valveBoxes.find((v) => v.uuid === item.data.uuid);
      setValveBox(vb || null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item, valveBoxes],
  );

  const flipValveBoxDirection = (vb: ValveBox) => {
    const newVB = { ...vb, left: !vb.left };
    changeValveBox(newVB);
    setValveBox(newVB);
  };
  const reverseInfoBoxes = (vb: ValveBox) => {
    if (vb.infoBoxOverride) {
      overrideInfoBox(
        vb.uuid,
        vb.infoBoxOverride.point
          ? vb.infoBoxOverride.point
          : item.data.position,
        vb.infoBoxOverride.vertical,
        !vb.infoBoxOverride.reversed,
      );
    }
  };

  const setSize = (_, data: DropdownProps) => {
    if (valveBox) {
      let zoneIDs = [...Array(data.value as number)].map(() => '');
      if (uniq(compact(valveBox.zoneIDs)).length <= (data.value as number)) {
        zoneIDs = [...Array(data.value as number)].map((_, i) => {
          if (i < valveBox.zoneIDs.length) {
            return valveBox.zoneIDs[i];
          }
          return '';
        });
      }
      const newVB = {
        ...valveBox,
        maxSlots: data.value as number,
        zoneIDs,
      };
      changeValveBox(newVB);
      setValveBox(newVB);
      setChangeSize(false);
    }
  };
  const addPointAway = (point: IPoint, distance: number, rotation: number) => {
    const newPosition = {
      x: point.x + Math.cos((Math.PI / 180) * rotation) * distance,
      y: point.y + Math.sin((Math.PI / 180) * rotation) * distance,
    };
    return rotateAroundPoint(point, newPosition, rotation);
  };

  const addQuickCoupler = (valveBox: ValveBox) => {
    const inputConnections = edges.filter(
      (edge) =>
        edge.source === valveBox.inputFitting ||
        edge.target === valveBox.inputFitting,
    );
    const outputConnections = edges.filter(
      (edge) =>
        edge.source === valveBox.outputFitting ||
        edge.target === valveBox.outputFitting,
    );
    const element: DesignElement = {
      ...defaultItem({ x: 0, y: 0 }),
      type: 'miscItem',
      props: {
        name: 'Quick Coupler',
        displayName: 'Quick Coupler',
      } as IMiscItem,
      itemType: 'design-element',
    };
    const minMainSize = Math.min(...mainPipeSizes);
    const pipe = pipeProducts.find(
      (p) => p.series === mainPipe && p.size === minMainSize,
    );
    if (outputConnections.length < 2 && pipe) {
      const outputEl = elementCache[valveBox.outputFitting];
      if (outputEl) {
        const newPoint = addPointAway(
          outputEl.position,
          10 / scale,
          valveBox.rotation,
        );
        element.position.x = newPoint.x;
        element.position.y = newPoint.y;
      }
      const rotatedPoint = rotateAroundPoint(
        outputEl.position,
        element.position,
        valveBox.rotation,
      );
      element.position.x = rotatedPoint.x;
      element.position.y = rotatedPoint.y;
      const edge = createDefaultEdge({
        source: valveBox.outputFitting,
        target: element.uuid,
        pipe: pipe.uuid,
      });
      addElementsEdges([element], [edge]);
    } else if (inputConnections.length < 2 && pipe) {
      const inputEl = elementCache[valveBox.inputFitting];
      if (inputEl) {
        const newPoint = addPointAway(
          inputEl.position,
          -10 / scale,
          valveBox.rotation,
        );
        element.position.x = newPoint.x;
        element.position.y = newPoint.y;
      }
      const rotatedPoint = rotateAroundPoint(
        inputEl.position,
        element.position,
        valveBox.rotation,
      );
      element.position.x = rotatedPoint.x;
      element.position.y = rotatedPoint.y;
      const edge = createDefaultEdge({
        source: valveBox.inputFitting,
        target: element.uuid,
        pipe: pipe.uuid,
      });

      addElementsEdges([element], [edge]);
    } else {
      console.log('no connections', inputConnections, outputConnections);
    }
  };

  const reOrient = (valveBox: ValveBox) => {
    const isOverridden = !!valveBox.infoBoxOverride;
    const vertical = isOverridden ? !valveBox.infoBoxOverride?.vertical : true;
    const reversed = valveBox.infoBoxOverride
      ? valveBox.infoBoxOverride.reversed
      : false;
    overrideInfoBox(
      valveBox.uuid,
      valveBox.infoBoxOverride?.point
        ? valveBox.infoBoxOverride.point
        : item.data.position,
      vertical,
      reversed,
    );
  };

  const closestZones = (p: IPoint) => {
    const zones = getState().zones;
    return _.sortBy(zones, (zone) => {
      const heads = zone.headIds.map((headID) => elementCache[headID]);
      const distances = heads.map((head) =>
        getEuclideanDistance(head.position, p),
      );
      return Math.min(...distances);
    });
  };

  const highlightZone = (zone: Zone, vb: ValveBox) => {
    const heads = zone.headIds.map((headID) => elementCache[headID].position);
    const points = makeHull(heads);
    const sorted = _.sortBy(heads, (head) =>
      getEuclideanDistance(head, vb.position),
    );
    const hullShape = new paper.Path(points);
    hullShape.strokeWidth = 2;
    hullShape.fillColor = getColor(zone.color);
    hullShape.opacity = 0.2;
    hullShape.name = 'hull';
    hullShape.scale(2);
    const arrow = new paper.Path([vb.position, sorted[0]]);
    arrow.strokeWidth = 2;
    arrow.strokeColor = getColor('black');
    setTimeout(() => {
      arrow.remove();
      hullShape.remove();
    }, 5000);
  };
  const getFt = (zone: Zone, vb: ValveBox) => {
    if (!zone.headIds.length) return '-';
    const heads = zone.headIds.map((headID) => elementCache[headID]);
    const distances = heads.map((head) =>
      getEuclideanDistance(head.position, vb.position),
    );
    return Math.round(Math.min(...distances));
  };
  const toggleZone = (
    enable: boolean | undefined,
    zone: Zone,
    vb: ValveBox,
  ) => {
    if (enable) {
      addZoneToValveBox(zone.uuid, vb.uuid);
    } else {
      removeZoneFromValveBox(zone.uuid, vb.uuid);
    }
  };
  const moveUp = (currentIndex: number) => {
    if (valveBox && currentIndex !== 0) {
      const newArr = moveItemInArray(
        [...valveBox.zoneIDs],
        currentIndex,
        currentIndex - 1,
      );
      changeValveBox({
        ...valveBox,
        zoneIDs: newArr,
      });
    }
  };

  const moveDown = (currentIndex: number) => {
    if (valveBox && currentIndex !== valveBox.zoneIDs.length - 1) {
      const newArr = moveItemInArray(
        [...valveBox.zoneIDs],
        currentIndex,
        currentIndex + 1,
      );
      changeValveBox({
        ...valveBox,
        zoneIDs: newArr,
      });
    }
  };
  return (
    <Wrap>
      {!!valveBox && (
        <div>
          <h3>
            Valve Box{' '}
            {valveBoxes.findIndex((vb) => vb.uuid === valveBox.uuid) + 1}{' '}
          </h3>
          <p>
            Size: <strong>{valveBox.maxSlots}</strong>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={() => setChangeSize(true)} size="mini">
              Change Size
            </Button>
          </p>
          <h5>Valve Box Membership</h5>
          <div className="zones">
            {closestZones(valveBox.position).map((zone) => (
              <div key={zone.uuid} className="zone">
                <Checkbox
                  onChange={(_, data) =>
                    toggleZone(data.checked, zone, valveBox)
                  }
                  disabled={
                    valveBox.zoneIDs.filter((z) => !!z).length >=
                      valveBox.maxSlots && !valveBox.zoneIDs.includes(zone.uuid)
                  }
                  checked={valveBox.zoneIDs.includes(zone.uuid)}
                  label={` ${zone.isDrip ? '(Drip) ' : ''}Zone ${
                    zone.orderNumber + 1
                  } (${getFt(zone, valveBox)}ft)`}
                />
                <div>
                  <span
                    className={`zone-color ${
                      !valveBoxes.find((vb) => vb.zoneIDs.includes(zone.uuid))
                        ? 'unzoned'
                        : ''
                    }`}
                    style={{
                      background: zone.color,
                    }}
                  ></span>
                  <span
                    className="highlight-zone"
                    onClick={() => highlightZone(zone, valveBox)}
                  >
                    Show
                  </span>
                </div>
              </div>
            ))}
          </div>
          <small>Zones are sorted by distance to closest head</small>
          <br />
          <br />
          <div className="buttons">
            <h5>Valve Box Positioning</h5>
            <PanelButton onClick={() => flipValveBoxDirection(valveBox)}>
              Flip Valve Side
            </PanelButton>
            <PanelButton onClick={() => addQuickCoupler(valveBox)}>
              Add Quick Coupler
            </PanelButton>
            <h5>Zone Info Box Positioning</h5>
            <PanelButton onClick={() => reOrient(valveBox)}>
              Horizontal / Vertical
            </PanelButton>
            <PanelButton onClick={() => reverseInfoBoxes(valveBox)}>
              Reverse Order
            </PanelButton>
          </div>
          <h5>Change Valve Order</h5>
          {valveBox.zoneIDs.map((va, j) => {
            const zone = zones.find((z) => z.uuid === va);
            const orderNum = zone ? `zone ${zone.orderNumber + 1}` : 'empty';
            return (
              <VBOrderer key={`${va}-${j}`} $color={zone?.color || 'black'}>
                <div>
                  {orderNum}
                  <div className="zone-color" />
                  &nbsp;
                  <Button
                    size="mini"
                    disabled={j === valveBox.zoneIDs.length - 1}
                    icon="arrow down"
                    onClick={() => moveDown(j)}
                  ></Button>
                  <Button
                    size="mini"
                    icon="arrow up"
                    disabled={j === 0}
                    onClick={() => moveUp(j)}
                  ></Button>
                </div>
              </VBOrderer>
            );
          })}
        </div>
      )}
      <Modal size="mini" open={changeSize}>
        <Modal.Header>Change Valve Box Details</Modal.Header>
        <Modal.Content>
          {valveBox && (
            <>
              Note: Reducing the count of valves will eject current valves from
              the valve box. They will need to be put back into the valve box.
              You will not be able to downsize a valve box if there are valves
              in it.
              <br />
              <br />
              <Form.Select
                value={valveBox.maxSlots}
                onChange={setSize}
                options={[1, 3, 5].map((m) => ({
                  text: `${m} valves`,
                  value: m,
                  disabled: valveBox.zoneIDs.filter((id) => !!id).length > m,
                }))}
              />
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setChangeSize(false)}>
            Done
          </Button>
        </Modal.Actions>
      </Modal>
    </Wrap>
  );
};

const Wrap = styled.div`
  flex: 1;
  width: 100%;
  padding: 8px;
  .zones {
    height: 200px;
    overflow: auto;
    border: 1px solid #333;
    background: #fefefe;
    .zone {
      display: flex;
      justify-content: space-between;
      padding: 4px;
    }
  }
  .highlight-zone {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  .buttons {
    margin-top: 8px;
    button {
      display: inline-block;
    }
  }
  .zone-color {
    height: 12px;
    width: 24px;
    margin-right: 16px;
    display: inline-block;
    position: relative;
    &.unzoned {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 120%;
        height: 3px;
        background: red;
        transform: translate(-50%, -50%);
      }
    }
  }
`;
export const VBOrderer = styled.div<{ $color: string }>`
  margin-top: 8px;
  .zone-color {
    background: ${(props) => props.$color};
    width: 15px;
    height: 15px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-left: 3px;
    font-size: 10px;
  }
`;
