import { addYears, format } from 'date-fns';
import { useContext, useState } from 'react';
import { Button, Form, Header, InputOnChangeData } from 'semantic-ui-react';
import { PortalContext } from 'src/contexts/portal/portal';
import { base } from 'src/styles/common';
import styled from 'styled-components';
import { BulkTermAgreement } from './BulkTerms';

export const formatDate = (date: Date): string =>
  format(date, 'yyyy-MM-dd hh:mm:ss');
export const addYearToDate = (date: Date): string =>
  formatDate(addYears(date, 1));

const planOptions = (count = 50) => [
  {
    value: 2,
    text: `2 payments of $${Math.round((count * 350) / 2).toLocaleString()}`,
  },
  {
    value: 12,
    text: `12 payments (monthly installments of $${Math.round(
      (count * 400) / 12,
    ).toLocaleString()})`,
  },
];

const blocks = [
  { text: '1 Block of 50 (50 designs)', value: 50 },
  { text: '2 Blocks of 50 (100 designs)', value: 100 },
  { text: '3 Blocks of 50 (150 designs)', value: 150 },
  { text: '4 Blocks of 50 (200 designs)', value: 200 },
  { text: '5 Blocks of 50 (250 designs)', value: 250 },
];

interface Props {
  onSave: () => void;
}

// TODO: examine component use/duplication with bulk contract component (which is a single page detail view vs modal on dashboard)
export const BulkContractForm = ({ onSave }: Props) => {
  const { createBulkContract } = useContext(PortalContext);
  const [payments, setPayments] = useState<number>(planOptions(50)[0].value);
  const [agreed, setAgreed] = useState(false);
  const [count, setCount] = useState(50);
  const [title, setTitle] = useState('Bulk Contract');

  const submitBulkContract = () => {
    // TODO: set current date here
    onSave();
    createBulkContract(payments, title, count);
  };

  const changeTitle = (_, data: InputOnChangeData) => {
    setTitle(data.value);
  };

  return (
    <Wrap>
      <Header as="h2" className="header">
        New Bulk Contract Form
      </Header>
      <div className="contract-form">
        <Form onSubmit={submitBulkContract} widths="equal">
          <Form.Input
            value={title}
            onChange={changeTitle}
            label="Bulk Contract Title"
          />
          <Form.Select
            label="How many blocks"
            value={count}
            onChange={(_, data) => setCount(data.value as number)}
            placeholder="Select Blocks"
            options={blocks}
          />
          <Form.Select
            label="Select payment plan"
            value={payments}
            onChange={(_, data) => setPayments(data.value as number)}
            placeholder="Select Plan"
            options={planOptions(count)}
          />
          <BulkTermAgreement onAgree={setAgreed} />
          <Button
            disabled={!agreed}
            type="submit"
            className="save-button"
            color="blue"
          >
            Submit
          </Button>
          <small>
            You must read through the entire agreement of terms and agree before
            being able to create a bulk contract.
          </small>
        </Form>
      </div>
    </Wrap>
  );
};
const Wrap = styled.div`
  padding: ${base * 2}px;
  max-width: 600px;
  margin: 0 auto;

  .header {
    margin: 20px auto !important;
    text-align: center;
  }

  .contract-form {
    padding: ${base * 3}px;
    border: 1px solid #ccc;
    margin: 0 auto;
    margin-bottom: ${base * 3}px;
  }

  .save-button {
    display: flex;
    margin: ${base * 2}px auto;
  }
`;
