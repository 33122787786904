import {
  OperatorFunction,
  filter,
  map,
  merge,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { compact } from 'underscore';
import { BaseItemType, Polyline, Vertex } from '../../../../../shared-types';

import {
  defaultItem,
  getItemByUUID,
  isBasemap,
  isPolyline,
  paperItemStore,
} from '../helpers';
import { changeItemPosition } from '../polyline/item.model';
import { isPaperPolyLine } from '../polyline/paper-polyline';
import { moveVertices } from '../polyline/polyline-helpers';
import { getPolyYardIndex } from '../polyline/polyline.service';
import { subtractPoints } from '../shared/geometry';
import {
  clearSelectedIDs,
  getState,
  hasSelectedID,
  setActiveYardIndex,
  toggleSelection,
} from '../state';
import { updateItem } from '../state/item-state';
import { MouseDownPayload, toolService } from '../tool.service';
import { scaleBasemap } from '../upload/basemap.service';
import {
  cropBoxHandleName,
  knownPointHandleName,
  scaleHandleName,
} from '../upload/paper-basemap';
import { modeService } from './mode.service';
import { arrowStamp$ } from './stamp';

const {
  mouseDown$,
  mouseUp$,
  mouseDrag$,
  keyUp$,
  rightClickDown$,
  rightClickUp$,
} = toolService.getEvents('Arrow');

export const newDraggableItems: BaseItemType[] = [
  'circle',
  'polyline',
  'sheet',
  'rectangle',
  'basemap',
  'plant',
  'text-item',
  'sleeve',
  'legend',
  'custom-symbol',
];

const isArrowMode = () => getState().activeTool === 'Arrow';
const isArrowRegularMode =
  <T>(): OperatorFunction<T, T> =>
  (source) => {
    return source.pipe(
      filter(isArrowMode),
      withLatestFrom(modeService.subMode$),
      filter(([_, subMode]) => subMode === 'move'),
      map(([e]) => e),
    );
  };
const arrowRegularMouseDown$ = mouseDown$.pipe(isArrowRegularMode());
export const noModifiers = (e: paper.ToolEvent) =>
  Object.keys(e.modifiers).every((m) => !e.modifiers[m]);

const namedItemDown$ = (name: string) =>
  arrowRegularMouseDown$.pipe(
    filter(({ e, paperItem }) => paperItem?.name === name),
  );
// const arrowDown$ = mouseDown$.pipe(filter(isArrowMode));
// const arrowMoveDown$ = mouseDown$.pipe(isArrowRegularMode());

// const panWithNothingSelected = arrowDown$.pipe(
//   filter(
//     (e) =>
//       (!e.item || e.item?.name === ITEMNAMES.OUTLINE) &&
//       !getState().selectedIDs.length,
//   ),
// );
// const panWithSomethingSelected = arrowDown$.pipe(
//   filter((e) => e.modifiers.space && !!getState().selectedIDs.length),
// );
export const arrowMoveItem$ = arrowRegularMouseDown$.pipe(
  filter(({ e, paperItem: item }) => {
    const selectedIDs = getState().selectedIDs;
    return (
      noModifiers(e) &&
      !!selectedIDs.length &&
      item &&
      item.data?.uuid &&
      selectedIDs.includes(item.data.uuid)
    );
  }),
  map(({ e }) => {
    const paperItems = compact(
      getState().selectedIDs.map((id) => paperItemStore.get(id)),
    );
    paperItems.forEach((paperItem) => {
      paperItem.setPivot(e.point);
    });
    return { paperItems, downPoint: e.point };
  }),
  switchMap(({ paperItems, downPoint }) =>
    mouseDrag$.pipe(
      tap((e) => {
        paperItems.forEach((p) => {
          p.setPosition(e.point);
        });
      }),
      takeUntil(
        merge(
          mouseUp$.pipe(
            tap((e) => {
              const diff = subtractPoints(e.point, downPoint);
              paperItems.forEach((p) => {
                const item = getItemByUUID(p.getItem().data.uuid);
                if (item) {
                  const updatedItem = changeItemPosition(item, diff);
                  updateItem(updatedItem);
                }
              });
            }),
          ),
        ),
      ),
    ),
  ),
);
const hasItem = ({ e, paperItem: item }: MouseDownPayload) => !!item;
const itemDown$ = arrowRegularMouseDown$.pipe(filter(hasItem));
const selectableItemDown$ = itemDown$.pipe(
  filter(({ e, paperItem }) => {
    const item = getState().items.find((i) => i.uuid === paperItem?.data.uuid);
    if (item) {
      return newDraggableItems.includes(item.itemType);
      // return item.selectable;
    }
    return false;
  }),
);
const selectableItemDownWithShift$ = selectableItemDown$.pipe(
  // shift + click to toggle selection
  filter(({ e }) => e.modifiers.shift),
  tap(({ e, paperItem }) => {
    toggleSelection(paperItem?.data.uuid);
  }),
);
const unselectedItemDownWithNoModifiers$ = selectableItemDown$.pipe(
  // click to select this item and deselect all others
  filter(
    ({ e, paperItem }) =>
      Object.keys(e.modifiers).every((m) => !e.modifiers[m]) &&
      !hasSelectedID(paperItem?.data.uuid),
  ),
  map(({ e, paperItem }) => {
    if (getState().selectedIDs.length) {
      clearSelectedIDs();
    }
    toggleSelection(paperItem?.data.uuid);
  }),
);
const noItemOrMods = ({ e, paperItem }: MouseDownPayload) =>
  !paperItem && noModifiers(e);
const deselectRegular$ = merge(
  arrowRegularMouseDown$.pipe(
    filter(noItemOrMods),
    switchMap(() => mouseUp$.pipe(take(1), takeUntil(mouseDrag$))),
  ),
  toolService.escapeKey$,
).pipe(
  tap(() => {
    if (getState().selectedIDs.length) {
      clearSelectedIDs();
    }
  }),
);
const bisectPolyline$ = namedItemDown$('handle.bisect.polyline').pipe(
  switchMap(({ paperItem }) =>
    mouseUp$.pipe(
      take(1),
      tap((upEvent) => {
        if (!paperItem) return;
        if (paperItem.data.spliceIndex > -1) {
          const polyline = getItemByUUID(paperItem.data.polyID);
          if (polyline && isPolyline(polyline)) {
            const updatedPolyline: Polyline = {
              ...polyline,
              // need this because of splice later
              vertices: [...polyline.vertices],
            };
            const newVertex: Vertex = {
              ...defaultItem({
                x: paperItem.position.x,
                y: paperItem.position.y,
              }),
              itemType: 'vertex',
              through: null,
            };
            updatedPolyline.vertices.splice(
              paperItem.data.spliceIndex,
              0,
              newVertex,
            );
            updateItem(updatedPolyline);
          }
        }
      }),
    ),
  ),
);
const ctrlClickVertex$ = namedItemDown$('handle.vertex').pipe(
  filter(({ e }) => !!e.modifiers.meta),
  map(({ paperItem }) => {
    const handle = paperItem as paper.Path;
    const polyline = getItemByUUID(handle.data.polyID, isPolyline);
    const vertexIndex = handle.data.i;
    const isThrough = handle.data.type === 'through';
    let newPolyline = { ...polyline };
    if (isThrough) {
      // remove the through property of the current vertex, and insert a new vertex
      newPolyline.vertices[vertexIndex].through = null;
      newPolyline.vertices.splice(vertexIndex, 0, {
        ...defaultItem({
          x: handle.position.x,
          y: handle.position.y,
        }),
        itemType: 'vertex',
        through: null,
      });
      updateItem(newPolyline);
    } else {
      // delete this vertex and add the "through" to the next vertex
      const v = newPolyline.vertices[vertexIndex + 1];
      if (vertexIndex > 0 && v && !v.through) {
        v.through = {
          x: handle.position.x,
          y: handle.position.y,
        };
        newPolyline.vertices.splice(vertexIndex, 1);
        updateItem(newPolyline);
      }
    }
  }),
);
const dragPolyVertex$ = namedItemDown$('handle.vertex').pipe(
  filter(({ e }) => !e.modifiers.meta),
  map(({ e, paperItem }) => {
    const handle = paperItem as paper.Path;
    handle.pivot = e.point;
    const polyline = getItemByUUID(handle.data.polyID, isPolyline);
    const vertexIndex = handle.data.i;
    const isThrough = handle.data.type === 'through';
    const paperPoly = paperItemStore.get(polyline.uuid);
    return { handle, polyline, vertexIndex, isThrough, paperPoly };
  }),
  switchMap(({ handle, polyline, vertexIndex, isThrough, paperPoly }) => {
    return mouseDrag$.pipe(
      map((e) => {
        handle.position = e.point;
        const newPoint = handle.bounds.center;
        polyline = {
          ...polyline,
          vertices: moveVertices(
            polyline.vertices[vertexIndex],
            newPoint,
            polyline.vertices,
            vertexIndex,
            isThrough,
          ),
        };
        if (paperPoly) {
          paperPoly.update(polyline);
        }
        return polyline;
      }),
      takeUntil(
        mouseUp$.pipe(
          tap(() => {
            updateItem(polyline);
          }),
        ),
      ),
    );
  }),
);

const dragCropBoxHandle$ = namedItemDown$(cropBoxHandleName).pipe(
  map(({ e, paperItem }) => {
    const handle = paperItem as paper.Path;
    // handle.pivot = e.point;
    const basemap = getItemByUUID(handle.data.basemapID, isBasemap);
    const isTopLeft = handle.data.type === 'topLeft';
    const paperBasemap = paperItemStore.get(basemap.uuid);
    return { handle, basemap, isTopLeft, paperBasemap };
  }),
  switchMap(({ handle, basemap, isTopLeft, paperBasemap }) => {
    return mouseDrag$.pipe(
      map((e) => {
        handle.position = e.point;
        // const newPoint = handle.bounds.center;
        // if it's top left, then the min x and y should be the position of the basemap
        // if it's bottom right, then the max x and y should be the position of the basemap + width and height
        const newPoint = handle.bounds.center;
        if (isTopLeft) {
          newPoint.x = Math.max(newPoint.x, basemap.position.x);
          newPoint.y = Math.max(newPoint.y, basemap.position.y);
        } else {
          newPoint.x = Math.min(
            newPoint.x,
            basemap.position.x + basemap.width / basemap.scale,
          );
          newPoint.y = Math.min(
            newPoint.y,
            basemap.position.y + basemap.height / basemap.scale,
          );
        }
        handle.position = newPoint;

        basemap = {
          ...basemap,
          cropBox: {
            topLeft: isTopLeft
              ? { x: newPoint.x, y: newPoint.y }
              : basemap.cropBox.topLeft,
            bottomRight: isTopLeft
              ? basemap.cropBox.bottomRight
              : { x: newPoint.x, y: newPoint.y },
          },
        };
        if (paperBasemap) {
          paperBasemap.update(basemap);
        }
        return basemap;
      }),
      takeUntil(
        mouseUp$.pipe(
          tap(() => {
            updateItem(basemap);
          }),
        ),
      ),
    );
  }),
);

const dragKnownPoint$ = namedItemDown$(knownPointHandleName).pipe(
  map(({ e, paperItem }) => {
    const handle = paperItem as paper.Path;
    handle.pivot = e.point;
    const basemap = getItemByUUID(handle.data.basemapID, isBasemap);
    const paperBasemap = paperItemStore.get(basemap.uuid);
    return { handle, basemap, paperBasemap };
  }),
  switchMap(({ handle, basemap, paperBasemap }) => {
    return mouseDrag$.pipe(
      map((e) => {
        handle.position = e.point;
        const newPoint = handle.bounds.center;
        basemap = {
          ...basemap,
          knownPoints: {
            a: handle.data.type === 'a' ? newPoint : basemap.knownPoints.a,
            b: handle.data.type === 'b' ? newPoint : basemap.knownPoints.b,
          },
        };
        if (paperBasemap) {
          paperBasemap.update(basemap);
        }
        return basemap;
      }),
      takeUntil(
        mouseUp$.pipe(
          tap(() => {
            handle.selected = false;
            updateItem(basemap);
          }),
        ),
      ),
    );
  }),
);
const dragScaleHandle$ = namedItemDown$(scaleHandleName).pipe(
  map(({ e, paperItem }) => {
    const handle = paperItem as paper.Path;
    // handle.pivot = e.point;
    const basemap = getItemByUUID(handle.data.basemapID, isBasemap);
    const isTopLeft = handle.data.type === 'topLeft';
    const paperBasemap = paperItemStore.get(basemap.uuid);
    return { handle, basemap, isTopLeft, paperBasemap };
  }),
  switchMap(({ handle, basemap, isTopLeft, paperBasemap }) => {
    return mouseDrag$.pipe(
      map((e) => {
        handle.position = e.point;
        const newPoint = handle.bounds.center;
        if (isTopLeft) {
          const newWidth = basemap.cropBox.bottomRight.x - newPoint.x;
          // flip these because the handles are opposites of pivots
          basemap = scaleBasemap(basemap, 'bottomRight', newWidth);
        } else {
          const newWidth = newPoint.x - basemap.position.x;
          // flip these because the handles are opposites of pivots
          basemap = scaleBasemap(basemap, 'topLeft', newWidth);
        }
        handle.position = newPoint;
        if (paperBasemap) {
          paperBasemap.update(basemap);
        }
        return basemap;
      }),
      takeUntil(
        mouseUp$.pipe(
          tap(() => {
            updateItem(basemap);
          }),
        ),
      ),
    );
  }),
);
// const deleteSelectedItems$ = keyUp$.pipe(
//   filter((e) => e.key === 'backspace' || e.key === 'delete'),
//   tap(() => {
//     const currentFocus = document.activeElement;
//     if (
//       currentFocus &&
//       (currentFocus.tagName === 'INPUT' || currentFocus.tagName === 'TEXTAREA')
//     ) {
//       return;
//     }
//     const selectedIDs = getState().selectedIDs;
//     selectedIDs.forEach((id) => {
//       const item = getItemByUUID(id);
//       if (item) {
//         deleteItem(item.uuid);
//       }
//     });
//   }),
// );

const rightClickItem$ = rightClickDown$.pipe(
  isArrowRegularMode(),
  filter((e) => !!e.item),
  switchMap((e) =>
    rightClickUp$.pipe(
      takeUntil(mouseDrag$),
      tap(() => {
        if (e.item.name === 'polyline') {
          // make polyline yard the active yard
          const polyline = getItemByUUID(e.item.data.uuid, isPolyline);
          if (polyline.polyType === 'yard') {
            setActiveYardIndex(getPolyYardIndex(e.item.data.uuid));
          }
        } else if (e.item.name === 'handle.vertex') {
          // remove vertex from polyline
          const polyline = getItemByUUID(e.item.data.polyID, isPolyline);
          const updatedPolyline: Polyline = {
            ...polyline,
            vertices: polyline.vertices.filter(
              (v) => v.uuid !== e.item.data.id,
            ),
          };
          updateItem(updatedPolyline);
        }
      }),
    ),
  ),
);

const showBisects$ = keyUp$.pipe(
  filter((e) => e.key === 'b'),
  tap(() => {
    const currentFocus = document.activeElement;
    if (
      currentFocus &&
      (currentFocus.tagName === 'INPUT' || currentFocus.tagName === 'TEXTAREA')
    ) {
      return;
    }
    const selectedIDs = getState().selectedIDs;
    if (selectedIDs.length > 1) return;
    const paperItem = paperItemStore.get(selectedIDs[0]);
    if (paperItem && isPaperPolyLine(paperItem)) {
      paperItem.toggleBisects();
    }
  }),
);

export const arrow$ = merge(
  selectableItemDownWithShift$,
  unselectedItemDownWithNoModifiers$,
  deselectRegular$,
  arrowMoveItem$,
  dragPolyVertex$,
  // deleteSelectedItems$,
  rightClickItem$,
  dragCropBoxHandle$,
  arrowStamp$,
  dragKnownPoint$,
  dragScaleHandle$,
  bisectPolyline$,
  showBisects$,
  ctrlClickVertex$,
);
