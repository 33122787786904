import { isSheet, paperItemStore } from '../helpers';
import { getState } from '../state';
import { isPaperSheet } from './paper-sheet';

export const renderSheetPreviews = (show: boolean) => {
  const sheets = getState().items.filter(isSheet);
  sheets.forEach((sheet) => {
    const sheetItem = paperItemStore.get(sheet.uuid);
    if (sheetItem && isPaperSheet(sheetItem)) {
      sheetItem.setPrintPreview(show);
    }
  });
};
