import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

interface CheckboxActionProps {
  onClick: () => void;
  checked: boolean;
  title: string;
  tooltip?: string;
}
export const CheckboxAction = ({
  onClick,
  checked,
  title,
  tooltip,
}: CheckboxActionProps) => {
  return (
    <CheckboxWrap onClick={onClick}>
      <Icon name={checked ? 'checkmark box' : 'square outline'} />
      {title}
      {!!tooltip && (
        <Popup
          content={tooltip}
          position="top center"
          trigger={<Icon className="popup-icon" name="question circle" />}
        />
      )}
    </CheckboxWrap>
  );
};
const CheckboxWrap = styled.div`
  cursor: pointer;
  user-select: none;
  .popup-icon {
    margin-left: 4px;
    color: #888;
  }
`;
