import { compact } from 'underscore';
import { getState } from '../state-basics';
import { changeValveBox } from './changeValveBox';
import { removeZoneFromValveBox } from './removeZoneFromValveBox';

export const addZoneToValveBox = (zoneID: string, valveBoxUUID: string) => {
  const zone = getState().zones.find((z) => z.uuid === zoneID);
  const vb = getState().valveBoxes.find((vb) => vb.uuid === valveBoxUUID);
  const currentVB = getState().valveBoxes.find((vb) =>
    vb.zoneIDs.includes(zoneID),
  );
  if (currentVB) {
    removeZoneFromValveBox(zoneID, currentVB.uuid);
  }
  if (zone && vb) {
    if (compact(vb.zoneIDs).length >= vb.maxSlots) {
      console.error('Valve box is full');
      return;
    }
    const newVB = {
      ...vb,
      zoneIDs: [...compact(vb.zoneIDs), zone.uuid],
    };
    changeValveBox(newVB);
  }
};
