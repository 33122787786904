import { PDFDocument, PDFPage } from 'pdf-lib';
import { createAllZonesPagePDF } from '../helpers/pdf/allZonesPage';
import { createFullTreePDF } from '../helpers/pdf/fullTreePages';
import { createGroupsPagesPDF } from '../helpers/pdf/groupsPage';
import { createTakeoffPagePDF } from '../helpers/pdf/takeoffPage';
import { createValveboxesPagePDF } from '../helpers/pdf/valveboxesPage';
import { createZonesPagesPDF } from '../helpers/pdf/zonesPages';
import { getState } from '../state';
import { createTitlePagePDF } from './packet-title.page';
import { saveAndDownloadPDF } from './saveAndDownloadPDF';

export const createPacketPDF = async (extraPages: PDFDocument[]) => {
  const packet = await PDFDocument.create();
  const title = getState().title;
  let pageNumber = 1;
  const documents = [
    await createTitlePagePDF(),
    await createTakeoffPagePDF(),
    await createAllZonesPagePDF(),
    await createZonesPagesPDF(),
    await createGroupsPagesPDF(),
    await createValveboxesPagePDF(),
    await createFullTreePDF(),
    ...extraPages,
  ];
  for (const doc of documents) {
    await appendToDocument(packet, doc);
  }
  for (const page of packet.getPages()) {
    addFooter(page, pageNumber);
    pageNumber++;
  }
  await saveAndDownloadPDF(packet, `${title} - System Details.pdf`);
};

export const createTakeoffPDF = async () => {
  const packet = await PDFDocument.create();
  const title = getState().title;
  let pageNumber = 1;
  const documents = [await createTakeoffPagePDF()];
  for (const doc of documents) {
    await appendToDocument(packet, doc);
  }
  for (const page of packet.getPages()) {
    addFooter(page, pageNumber);
    pageNumber++;
  }
  await saveAndDownloadPDF(packet, `${title} - Material Takeoff.pdf`);
};

const appendToDocument = async (
  finalDoc: PDFDocument,
  doc: PDFDocument | void,
) => {
  if (doc) {
    const pages = doc.getPages();
    for (let i = 0; i < pages.length; i++) {
      const [copied] = await finalDoc.copyPages(doc, [i]);
      finalDoc.addPage(copied);
    }
  }
};
const addFooter = (page: PDFPage, pageNumber: number) => {
  page.drawText(`${getState().title} - Page ${pageNumber}`, {
    x: 16,
    y: 16,
    size: 8,
  });
};
