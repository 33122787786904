import {
  filter,
  map,
  merge,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import paper from 'src/paper';
import { compact } from 'underscore';

import { defaultItem, getItemByUUID, paperItemStore } from '../helpers';
import { changeItemPosition } from '../polyline/item.model';
import { subtractPoints } from '../shared/geometry';
import { getState } from '../state';
import { addItem } from '../state/item-state';
import { toolService } from '../tool.service';
import { noModifiers } from './arrow-new';
import { modeService } from './mode.service';

const mouseDown$ = toolService.paperMouseDown$;
const mouseMove$ = toolService.paperMouseMove$;
const mouseUp$ = toolService.paperMouseUp$;
const escapeKey$ = toolService.escapeKey$;

const stampMove$ = modeService.subMode$.pipe(
  filter((subMode) => {
    const { activeTool, selectedIDs } = getState();
    return (
      subMode === 'stamp' && activeTool === 'Arrow' && selectedIDs.length > 0
    );
  }),
  map(() => {
    const items = compact(
      getState().selectedIDs.map((id) => paperItemStore.get(id)),
    );
    const g = new paper.Group();
    const referenceItem = items[0].getItem();
    g.pivot = referenceItem.position;
    items.forEach((item) => {
      const cloned = item.getItem().clone();
      g.addChild(cloned);
    });
    g.name = 'stamped';
    return { g };
  }),
  switchMap(({ g }) =>
    mouseMove$.pipe(
      tap((e) => {
        g.position = e.point;
      }),
      takeUntil(
        merge(
          modeService.subMode$.pipe(filter((subMode) => subMode !== 'stamp')),
          escapeKey$.pipe(tap(() => modeService.setSubMode('move'))),
        ).pipe(
          tap(() => {
            g.remove();
          }),
        ),
      ),
    ),
  ),
);
const stampClick$ = mouseDown$.pipe(
  withLatestFrom(modeService.subMode$),
  filter(([{ e }, subMode]) => {
    const { activeTool, selectedIDs } = getState();
    return (
      noModifiers(e) &&
      activeTool === 'Arrow' &&
      subMode === 'stamp' &&
      selectedIDs.length > 0
    );
  }),
  switchMap(() =>
    mouseUp$.pipe(
      take(1),
      tap((e) => {
        const selectedIDs = getState().selectedIDs;
        const diff = subtractPoints(
          e.point,
          getItemByUUID(selectedIDs[0]).position,
        );
        selectedIDs.forEach((id) => {
          const item = getItemByUUID(id);
          if (item) {
            const newItem = changeItemPosition({ ...item }, diff);
            newItem.uuid = defaultItem().uuid;
            addItem(newItem);
          }
        });
      }),
    ),
  ),
);

export const arrowStamp$ = merge(stampMove$, stampClick$);
