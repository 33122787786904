import {
  OperatorFunction,
  Subject,
  filter,
  map,
  share,
  takeUntil,
  tap,
} from 'rxjs';
import paper from 'src/paper';
import { Tools } from '../../../../shared-types';
import { getState } from './state';

export interface MouseDownPayload {
  e: paper.ToolEvent;
  paperItem: paper.Item | null;
}
export const fromTool =
  <T>(tool: Tools): OperatorFunction<T, T> =>
  (source) =>
    source.pipe(
      filter(() => getState().activeTool === tool),
      map((e) => e),
    );
export class ToolService {
  destroyTools$ = new Subject<boolean>();
  private _paperMouseUp$ = new Subject<paper.ToolEvent>();
  private _paperMouseDown$ = new Subject<paper.ToolEvent>();
  private _paperMouseMove$ = new Subject<paper.ToolEvent>();
  private _paperMouseDrag$ = new Subject<paper.ToolEvent>();
  private _paperKeyDown$ = new Subject<paper.KeyEvent>();
  private _paperKeyUp$ = new Subject<paper.KeyEvent>();
  private _rightClickUp$ = new Subject<paper.ToolEvent>();
  private _rightClickDown$ = new Subject<paper.ToolEvent>();

  private _mouseIsDown = false;

  get mouseIsDown() {
    return this._mouseIsDown;
  }

  paperMouseUp$ = this._paperMouseUp$.asObservable().pipe(
    tap(() => (this._mouseIsDown = false)),
    share(),
    takeUntil(this.destroyTools$),
  );
  paperMouseDown$ = this._paperMouseDown$.asObservable().pipe(
    tap(() => (this._mouseIsDown = true)),
    map((e): MouseDownPayload => ({ e, paperItem: e.item })),
    share(),
    takeUntil(this.destroyTools$),
  );
  paperMouseMove$ = this._paperMouseMove$
    .asObservable()
    .pipe(share(), takeUntil(this.destroyTools$));
  paperMouseDrag$ = this._paperMouseDrag$
    .asObservable()
    .pipe(share(), takeUntil(this.destroyTools$));
  paperKeyDown$ = this._paperKeyDown$
    .asObservable()
    .pipe(share(), takeUntil(this.destroyTools$));
  paperKeyUp$ = this._paperKeyUp$
    .asObservable()
    .pipe(share(), takeUntil(this.destroyTools$));
  paperRightClickUp$ = this._rightClickUp$
    .asObservable()
    .pipe(share(), takeUntil(this.destroyTools$));
  paperRightClickDown$ = this._rightClickDown$
    .asObservable()
    .pipe(share(), takeUntil(this.destroyTools$));
  escapeKey$ = this.paperKeyDown$.pipe(
    filter((e) => e.key === 'escape'),
    takeUntil(this.destroyTools$),
  );

  activeTool$ = new Subject<Tools>();

  init() {}

  setupTool = (): paper.Tool => {
    const tool = new paper.Tool();
    tool.onMouseDown = (e: paper.ToolEvent) => {
      if ((e as any).event?.button === 2) {
        this._rightClickDown$.next(e);
      } else {
        // console.log(e.item?.name, 'item');
        this._paperMouseDown$.next(e);
      }
    };
    tool.onMouseMove = (e: paper.ToolEvent) => {
      this._paperMouseMove$.next(e);
    };
    tool.onMouseDrag = (e) => {
      this._paperMouseDrag$.next(e);
    };
    tool.onMouseUp = (e: paper.ToolEvent) => {
      if ((e as any).event?.button === 2) {
        this._rightClickUp$.next(e);
      } else {
        this._paperMouseUp$.next(e);
      }
    };
    tool.onKeyDown = (e: paper.KeyEvent) => {
      this._paperKeyDown$.next(e);
    };
    tool.onKeyUp = (e) => {
      this._paperKeyUp$.next(e);
    };
    return tool;
  };

  mouseDown$ = (tool: Tools) => this.paperMouseDown$.pipe(fromTool(tool));
  mouseUp$ = (tool: Tools) => this.paperMouseUp$.pipe(fromTool(tool));
  mouseMove$ = (tool: Tools) => this.paperMouseMove$.pipe(fromTool(tool));
  mouseDrag$ = (tool: Tools) => this.paperMouseDrag$.pipe(fromTool(tool));
  keyDown$ = (tool: Tools) => this.paperKeyDown$.pipe(fromTool(tool));
  keyUp$ = (tool: Tools) => this.paperKeyUp$.pipe(fromTool(tool));
  rightClickDown$ = (tool: Tools) =>
    this.paperRightClickDown$.pipe(fromTool(tool));
  rightClickUp$ = (tool: Tools) => this.paperRightClickUp$.pipe(fromTool(tool));

  getEvents = (tool: Tools) => ({
    mouseDown$: this.mouseDown$(tool),
    mouseUp$: this.mouseUp$(tool),
    mouseMove$: this.mouseMove$(tool),
    mouseDrag$: this.mouseDrag$(tool),
    keyDown$: this.keyDown$(tool),
    keyUp$: this.keyUp$(tool),
    rightClickDown$: this.rightClickDown$(tool),
    rightClickUp$: this.rightClickUp$(tool),
  });

  destroy() {
    this.destroyTools$.next(true);
    this.destroyTools$.complete();
  }
}

export const toolService = new ToolService();

export const namedItemDown$ = (name: string) =>
  filter(({ e, paperItem }) => paperItem?.name === name);
