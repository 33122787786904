import { DesignElementMap, IEdge } from '@shared-types';
import { useEffect, useState } from 'react';
import { Button, Divider, Modal, Select } from 'semantic-ui-react';

import styled from 'styled-components';
import { contains } from 'underscore';
import { ChangeGroup } from '../../components/ChangeGroup';
import { ChangeZone } from '../../components/ChangeZone';

import { PanelButton, PanelWrap } from '../../components/PanelButton';
import { useEventListener } from '../../components/useEventListener';
import { newUUID } from '../../crypto-uuid';
import { averagePoints } from '../../helpers/geometry.helpers';
import { cloneEdge, createDefaultEdge } from '../../paper-helpers/edges';
import { headsGPM } from '../../paper-helpers/heads';
import { postMST } from '../../services/design.service';
import {
  addHeadsToGroup,
  addHeadsToZone,
  setActiveTool,
  useDesignStore,
} from '../../state';
import { addElementsEdges } from '../../state/addElementsEdges';
import { deleteEdge } from '../../state/deleteEdge';
import { addValveBoxLocation } from '../../state/valvebox/addValveBoxLocation';
import { addZoneToValveBox } from '../../state/valvebox/addZoneToValveBox';
import { getLateralPipeProduct } from '../pipes/Pipes';

export const MultiHeadTooltip = ({ heads }: { heads: paper.Group[] }) => {
  const zones = useDesignStore((state) => state.zones);
  const groups = useDesignStore((state) => state.groups);
  const elementCache = useDesignStore((state) => state.elementCache);
  const edges = useDesignStore((state) => state.edges);
  const [loading, setLoading] = useState(false);
  const [valveBoxSize, setValveBoxSize] = useState(3);
  const [showChangeZone, setShowChangeZone] = useState(false);
  const [genVB, setGenVB] = useState(false);
  const [cloning, setCloning] = useState(false);

  const paperClick = (d) => {
    if (cloning) {
      cloneEdge(heads, d.detail.point, edges);
      setCloning(false);
    }
  };

  useEventListener('keyup', (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      setCloning(false);
      window.removeEventListener('paperClick', paperClick);
    }
  });

  useEffect(() => {
    if (cloning) {
      setActiveTool('Arrow');
      window.addEventListener('paperClick', paperClick, { once: true });
    }
    return () => window.removeEventListener('paperClick', paperClick);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloning, setActiveTool]);

  const handleAddToZone = (zoneID: string, headIds: string[]) => {
    addHeadsToZone(zoneID, headIds);
  };

  const handleAddToGroup = (groupID: string, headIds: string[]) => {
    addHeadsToGroup(groupID, headIds);
  };

  const pipeHeads = () => {
    const pipe = getLateralPipeProduct();
    const headsHaveCapacity = heads.every(
      (head) =>
        edges.filter(
          (edge) =>
            edge.source === head.data.uuid || edge.target === head.data.uuid,
        ).length < 2,
    );
    if (heads.length === 2 && headsHaveCapacity) {
      const edge: IEdge = createDefaultEdge({
        source: heads[0].data.uuid,
        target: heads[1].data.uuid,
        pipe: pipe ? pipe.uuid : '',
        isLateral: true,
      });
      addElementsEdges([], [edge]);
    } else {
      console.error(
        'either not enough heads, or some heads have too many pipes already',
      );
    }
  };
  const mstHeads = async (heads: paper.Group[]) => {
    setLoading(true);
    handleDeleteConnectedPipes(heads);
    const res = await postMST(heads.map((h) => h.data));
    const pipe = getLateralPipeProduct();
    res.edges.forEach((edge) => {
      const e: IEdge = {
        ...edge,
        preExisting: false,
        pipe: pipe ? pipe.uuid : '',
        hoppedSections: [],
        isLateral: true,
        isDrip: false,
        showLabel: false,
      };
      addElementsEdges([], [e]);
    });
    setLoading(false);
  };

  const handleDeleteConnectedPipes = (heads: paper.Group[]) => {
    const headUUIDs = heads.map((i) => i.data.uuid);
    edges.forEach((edge) => {
      if (
        contains(headUUIDs, edge.source) ||
        contains(headUUIDs, edge.target)
      ) {
        deleteEdge(edge.uuid);
      }
    });
  };

  const generateVB = (elementCache: DesignElementMap) => {
    setGenVB(false);
    const vbPoint = averagePoints(
      heads.map((item) => elementCache[item.data.uuid].position),
    );
    const headIDs = heads.map((item) => item.data.uuid);
    const usedZones = zones.filter((z) =>
      z.headIds.some((h) => headIDs.includes(h)),
    );
    const count = Math.ceil(usedZones.length / valveBoxSize);
    for (let i = 0; i < count; i++) {
      console.log('adding vb', i);
      const vbID = newUUID();
      const point = {
        x: vbPoint.x + i * valveBoxSize * 2,
        y: vbPoint.y + i * valveBoxSize * 2,
      };
      addValveBoxLocation(vbID, valveBoxSize, point, 0);
      usedZones
        .slice(i * valveBoxSize, i * valveBoxSize + valveBoxSize)
        .forEach((z) => {
          addZoneToValveBox(z.uuid, vbID);
        });
    }
  };

  const minValveBoxes = (): number => {
    const headIDs = heads.map((item) => item.data.uuid);
    const zoneCount = zones.filter((z) =>
      z.headIds.some((h) => headIDs.includes(h)),
    ).length;
    return Math.ceil(zoneCount / valveBoxSize);
  };

  return (
    <Wrap>
      <h5>Multiple Head Details</h5>
      {heads.length} heads selected, {headsGPM(heads).toFixed(1)} GPM
      <br />
      <br />
      <PanelButton onClick={() => setShowChangeZone(!showChangeZone)}>
        Change Zone
      </PanelButton>
      {heads.length === 2 && (
        <PanelButton disabled={loading} onClick={pipeHeads}>
          Pipe together
        </PanelButton>
      )}
      {heads.length > 1 && (
        <PanelButton disabled={loading} onClick={() => mstHeads(heads)}>
          MST together
        </PanelButton>
      )}
      <PanelButton
        disabled={loading}
        onClick={() => handleDeleteConnectedPipes(heads)}
      >
        Delete Connected Pipes
      </PanelButton>
      {heads.length === 2 && (
        <PanelButton onClick={() => setCloning(true)}>Clone edge</PanelButton>
      )}
      <PanelButton onClick={() => setGenVB(true)}>
        Generate Valvebox
      </PanelButton>
      <Modal open={genVB} size="mini">
        <Modal.Header>Generate ValveBox(es)</Modal.Header>
        <Modal.Content>
          Generating valve boxes from these heads. It will average the position
          of all the heads and place it in the middle of the selected heads.
          <br />
          <Select
            value={valveBoxSize}
            onChange={(_, d) => setValveBoxSize(d.value as number)}
            options={[
              { text: 'Standard VB (3)', value: 3 },
              { text: 'Jumbo VB (5)', value: 5 },
            ]}
          ></Select>
          <br />
          <br />
          <small>
            Selecting {valveBoxSize} valves will generate {minValveBoxes()}{' '}
            boxes
          </small>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setGenVB(false)}>Cancel</Button>
          <Button primary onClick={() => generateVB(elementCache)}>
            Generate
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={showChangeZone} size="mini">
        <Modal.Header>Change Group/Zone</Modal.Header>
        <Modal.Content>
          <>
            <ChangeZone
              zones={zones}
              headIds={heads.map((i) => i.data.uuid)}
              onAdd={handleAddToZone}
            />
            <Divider />
            <ChangeGroup
              groups={groups}
              headIds={heads.map((i) => i.data.uuid)}
              onAdd={handleAddToGroup}
            />
          </>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setShowChangeZone(false)}>
            Done
          </Button>
        </Modal.Actions>
      </Modal>
    </Wrap>
  );
};
const Wrap = styled(PanelWrap)``;
