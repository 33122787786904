import {
  DesignElement,
  DesignElementMap,
  IEdge,
  IPipeProduct,
  IPoint,
  ValveBox,
  Zone,
} from '@shared-types';
import { IPOCDirectedGraph } from '../../../../../shared-types/pocDirectedGraph.helper';
import { lineOffset } from '../components/offset';
import { newUUID } from '../crypto-uuid';
import { getLateralPipeProduct } from '../features/pipes/Pipes';
import { defaultFitting, defaultItem, paperItemStore } from '../helpers';
import { getZoneEdges } from '../helpers/directedGraph';
import { getRotation, onRight } from '../helpers/geometry.helpers';
import { UndirectedGraph } from '../helpers/undirectedGraph';
import { localPaper } from '../localPaper';
import { getEuclideanDistance } from '../shared/geometry';
import { getPipeSizes } from '../shared/getPipeSizes';
import {
  deleteOrphanedFittings,
  getState,
  setDripPipe,
  setDripSizes,
  setLateralPipe,
  setLateralSizes,
  setMainPipe,
  setMainSizes,
} from '../state';
import { addElementsEdges } from '../state/addElementsEdges';
import { deleteEdge } from '../state/deleteEdge';
import { deleteEdges } from '../state/deleteEdges';
import { PaperPipe, isPaperPipe } from '../tools/paper-items/paper-pipe';
import { getProtectedEdgeNodes } from '../tools/tool.helpers';
import { updateLegend } from './legend';

const edgeColors: { [edge: string]: string } = {};

export const createDefaultEdge = (edge: Partial<IEdge>): IEdge => {
  // TODO: come back to this and make sure it's not breaking anything because
  // ...edge may or may not have a position prop
  return {
    ...defaultItem(),
    source: '',
    target: '',
    isDrip: false,
    isLateral: false,
    pipe: edge.pipe || '',
    preExisting: false,
    hoppedSections: [],
    showLabel: false,
    itemType: 'edge',
    ...edge,
  };
};

const resetColors = () => {
  Object.keys(edgeColors).forEach((k) => {
    delete edgeColors[k];
  });
};
export const renderEdges = (edges: IEdge[]): void => {
  console.log(`plotting ${edges.length} edges`);
  edges.forEach((edge) => {
    const edgeItem = new PaperPipe(edge);
    paperItemStore.set(edge.uuid, edgeItem);
  });
};
export const updateZoneColors = (
  edges: IEdge[],
  zones: Zone[],
  pocGraphs: IPOCDirectedGraph[],
) => {
  // console.time('updateZoneColors')
  // reset the colors so that disconnected pipes can turn black again
  resetColors();
  zones.forEach((zone) => {
    const zoneEdges = getZoneEdges(edges, zone, pocGraphs);
    zoneEdges.forEach((edge) => {
      edgeColors[edge.uuid] = zone.color;
    });
  });
  edges.forEach((edge) => {
    const paperItem = paperItemStore.get(edge.uuid);
    if (paperItem && isPaperPipe(paperItem)) {
      paperItem.updateColor(edgeColors[edge.uuid] || '#000');
    }
  });
  // console.timeEnd('updateZoneColors')
};
export const bisectPipe = (data: IEdge, newEndPoint: IPoint): string => {
  try {
    const protectedEdges = getProtectedEdgeNodes();
    if (
      protectedEdges.includes(data.source) ||
      protectedEdges.includes(data.target)
    ) {
      throw 'this is protected';
    }
    deleteEdge(data.uuid);
    const newFitting: DesignElement = {
      ...defaultFitting({ x: newEndPoint.x, y: newEndPoint.y }),
    };
    addElementsEdges(
      [newFitting],
      [
        { ...data, target: newFitting.uuid, uuid: newUUID() },
        { ...data, source: newFitting.uuid, uuid: newUUID() },
      ],
    );
    return newFitting.uuid;
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const addItemToPipe = (
  data: IEdge,
  el: DesignElement,
  elementCache: DesignElementMap,
): string => {
  deleteEdge(data.uuid);
  let rotation = 0;
  if (el.type === 'miscItem') {
    const sourceEl = elementCache[data.source];
    const targetEl = elementCache[data.target];
    if (sourceEl && targetEl) {
      rotation = getRotation(sourceEl.position, targetEl.position);
      rotation = rotation + 90;
    }
  }
  addElementsEdges(
    [{ ...el, props: { ...el.props, rotation } }],
    [
      { ...data, target: el.uuid, uuid: newUUID() },
      { ...data, source: el.uuid, uuid: newUUID() },
    ],
  );
  return el.uuid;
};

export const cloneEdge = (
  selectedItems: paper.Item[],
  point: IPoint,
  edges: IEdge[],
) => {
  const root = selectedItems[0].data.uuid;
  const dest = selectedItems[1].data.uuid;
  const g = new UndirectedGraph();
  edges.forEach((edge) => {
    g.addGraphEdge(edge.source, edge.target);
  });
  const path = g.pathToDest(root, dest);
  if (path.length > 1) {
    const a = localPaper.project.getItem({ data: { uuid: path[0] } });
    const b = localPaper.project.getItem({ data: { uuid: path[1] } });
    const onRightSide = onRight(a.position, b.position, point);
    let original = path.map((id) => {
      const el = localPaper.project.getItem({ data: { uuid: id } });
      const coord = [el.position.x, el.position.y];
      return coord;
    });
    let newElements: DesignElement[] = [];
    let newEdges: IEdge[] = [];
    const l = lineOffset(
      original,
      (10 / getState().scale) * (onRightSide ? -1 : 1),
    );
    l.forEach((coord) => {
      const newFitting: DesignElement = {
        ...defaultFitting({ x: coord[0], y: coord[1] }),
      };
      newElements.push(newFitting);
    });
    newElements.forEach((el, i) => {
      if (i < newElements.length - 1) {
        const edge = createDefaultEdge({
          source: el.uuid,
          target: newElements[i + 1].uuid,
        });
        newEdges.push(edge);
      }
    });
    addElementsEdges(newElements, newEdges);
  }
};

export const createEdge = (
  _edge: IEdge,
  pipeProducts: IPipeProduct[],
  mainPipe: string,
  mainPipeSizes: number[],
): IEdge => {
  const minMainSize = Math.min(...mainPipeSizes);
  const lateral = getLateralPipeProduct();
  const main = pipeProducts.find(
    (p) => p.series === mainPipe && p.size >= minMainSize,
  );
  const edge = { ..._edge, uuid: _edge.uuid || newUUID() };
  if (!edge.pipe) {
    if (lateral) {
      edge.pipe = lateral.uuid;
      edge.isDrip = false;
      edge.isLateral = true;
    } else if (main) {
      edge.isLateral = false;
      edge.isDrip = false;
      edge.pipe = main.uuid;
    } else {
      console.error('pipe is not main or lateral');
      edge.pipe = pipeProducts.length ? pipeProducts[0].uuid : '';
      edge.isLateral = false;
      edge.isDrip = false;
    }
  }
  return edge;
};

export const deleteAllEdges = () => {
  const { edges, zones, valveBoxes } = getState();
  const valveBoxFittings = valveBoxes.reduce(
    (acc: string[], vb: ValveBox) => [
      ...acc,
      vb.inputFitting,
      vb.outputFitting,
    ],
    [],
  );
  const valveFittings = zones.reduce(
    (acc: string[], zone: Zone) => [
      ...acc,
      zone.valveInputFitting,
      zone.valveOutputFitting,
      zone.valve,
    ],
    [],
  );
  const fittings = [...valveBoxFittings, ...valveFittings];
  const edgesToDelete = edges.filter(
    (edge) =>
      !(fittings.includes(edge.source) && fittings.includes(edge.target)),
  );
  deleteEdges(edgesToDelete);
  deleteOrphanedFittings();
};

export const changeMainPipe = (pipe: string) => {
  const pipeProducts = getState().pipeProducts;
  setMainPipe(pipe);
  setMainSizes(getPipeSizes(pipe, pipeProducts));
  updateLegend();
};
export const changeLateralPipe = (pipe: string) => {
  const pipeProducts = getState().pipeProducts;
  setLateralPipe(pipe);
  setLateralSizes(getPipeSizes(pipe, pipeProducts));
  updateLegend();
};
export const changeDripPipe = (pipe: string) => {
  const pipeProducts = getState().pipeProducts;
  setDripPipe(pipe);
  setDripSizes(getPipeSizes(pipe, pipeProducts));
  updateLegend();
};
export const getZoneEdgeLength = (
  edges: IEdge[],
  zone: Zone,
  elementCache: DesignElementMap,
  pocGraphs: IPOCDirectedGraph[],
): number =>
  getZoneEdges(edges, zone, pocGraphs).reduce((acc: number, edge: IEdge) => {
    const el1 = elementCache[edge.source];
    const el2 = elementCache[edge.target];
    return el1 && el2
      ? acc + getEuclideanDistance(el1.position, el2.position)
      : acc;
  }, 0);
export const totalPipeLength = (
  edges: IEdge[],
  name: string,
  elements: DesignElement[],
  pipeProducts: IPipeProduct[],
): number =>
  edges
    .filter((edge) => {
      const pipe = pipeProducts.find((pipe) => pipe.uuid === edge.pipe);
      return pipe && pipe.name === name && !edge.preExisting;
    })
    .reduce(
      (acc: number, edge: IEdge) => acc + getEdgeDistance(edge, elements),
      0,
    );
export const getEdgeDistance = (
  edge: IEdge,
  elements: DesignElement[],
): number => {
  const source = elements.find((el) => el.uuid === edge.source);
  const target = elements.find((el) => el.uuid === edge.target);
  return source && target
    ? getEuclideanDistance(source.position, target.position)
    : 0;
};
