import { DesignElement, NozzleData, Tools } from '@shared-types';
import { takeUntil } from 'rxjs';
import paper from 'src/paper';
import { PaperEvType } from '../custom-events';
import { defaultItem } from '../helpers';
import {
  LocalNozzleData,
  getGeneratePerfData,
} from '../helpers/nozzleTransformer';
import { localPaper, localZoom } from '../localPaper';
import {
  createArc,
  getArcPoints,
  getColor,
} from '../paper-helpers/plot.helpers';
import { ITEMNAMES, StripCorner } from '../shared/workbench-enums';
import { addHeadsToZone, getState, selectItems } from '../state';
import { addElementsEdges } from '../state/addElementsEdges';
import { MouseDownPayload, ToolService } from '../tool.service';
import { createSprinkler } from './sprinkler';

export const setLocalNozzles = (nozzles: NozzleData[]) => {
  const localData = localStorage.getItem('is.nozzleData');
  if (localData) {
    // TODO: clear local cache after a few days?
    const data = JSON.parse(localData) as LocalNozzleData;
    data.nozzles = nozzles;
    localStorage.setItem('is.nozzleData', JSON.stringify(data));
  } else {
    localStorage.setItem(
      'is.nozzleData',
      JSON.stringify({
        heads: [],
        headNozzles: [],
        updated: new Date().getTime(),
        nozzles,
      }),
    );
  }
};

export const setupHeadTool = (toolService: ToolService) => {
  let dragging = false;
  let panning = false;
  let rotation = 0;
  let radius = 2;
  let angle = 2;
  let grid = false;
  let isStrip = false;
  let currentUUID = '';
  const c: paper.Path = new paper.Path();
  c.name = ITEMNAMES.TMP_HEAD_GROUP;
  let startPoint = new paper.Point(0, 0);
  let hint = new paper.Path();
  let gridHint = new paper.Path();
  gridHint.dashArray = [2, 1];
  gridHint.strokeColor = getColor('red');
  c.opacity = 0.3;
  c.fillColor = getColor('#333');
  window.addEventListener(PaperEvType.CHANGE_TOOL, (d: any) => {
    c.pathData = '';
    clearHeadHints();
    if (d.detail === ('Head Placement' as Tools)) {
      selectItems([]);
      localPaper.project.deselectAll();
    }
  });
  let hasFirst: paper.Point | undefined = undefined;

  let currentKey = '';
  let headHints: paper.Item[] = [];

  const clearHeadHints = () => {
    headHints.forEach((n) => n.remove());
    headHints = [];
    gridHint.remove();
  };

  const updateGridHint = (point: paper.Point) => {
    clearHeadHints();
    gridHint = new paper.Path([hasFirst, point]);
    gridHint.dashArray = [2, 1];
    gridHint.strokeColor = getColor('red');
    const l = gridHint.length;
    gridHint.locked = true;
    c.visible = false;
    const { sprinklerBases, activeBaseKey, activeBaseRadius } = getState();
    const base = sprinklerBases.find((n) => n.uuid === activeBaseKey) || null;
    let dist = radius;
    if (base) {
      // so we can cache radius
      const data = getGeneratePerfData(base);
      isStrip = data[0].width !== 0;
      dist = activeBaseRadius;
      if (isStrip) {
        dist = data[0].width;
      }
      const count = Math.floor(l / dist);
      for (let i = 1; i < count; i++) {
        const p = gridHint.getPointAt(i * dist);
        const c = new paper.Shape.Circle(p, isStrip ? 2 : dist);
        c.fillColor = getColor('black');
        c.opacity = 0.3;
        c.name = 'headHint';
        c.locked = true;
        headHints.push(c);
      }
    }
  };

  const updateArcHint = (point: paper.Point) => {
    c.visible = true;
    const { sprinklerBases, activeBaseKey, activeBaseRadius } = getState();
    const base = sprinklerBases.find((n) => n.uuid === activeBaseKey) || null;
    if (base) {
      if (base.uuid !== currentUUID || radius !== activeBaseRadius) {
        // so we can cache radius
        currentUUID = base.uuid;
        const data = getGeneratePerfData(base);
        isStrip = data[0].width !== 0;
        radius = activeBaseRadius;
        angle = Math.max(...data.map((n) => n.angle));
      }
      if (isStrip) {
        const data = getGeneratePerfData(base);
        if (data[0].origin === StripCorner.BOTTOM_LEFT) {
          const tmp = new paper.Path.Rectangle({
            from: [point.x, point.y],
            to: [point.x + data[0].width, point.y - data[0].height],
          });
          c.pivot = point;
          c.pathData = tmp.pathData;
          tmp.remove();
        } else if (data[0].origin === StripCorner.BOTTOM_RIGHT) {
          const tmp = new paper.Path.Rectangle({
            from: [point.x, point.y],
            to: [point.x - data[0].width, point.y - data[0].height],
          });
          c.pivot = point;
          c.pathData = tmp.pathData;
          c.fillColor = getColor('blue');
          tmp.remove();
        } else if (data[0].origin === StripCorner.BOTTOM_CENTER) {
          const tmp = new paper.Path.Rectangle({
            from: [point.x - data[0].width / 2, point.y],
            to: [point.x + data[0].width / 2, point.y - data[0].height],
          });
          c.pivot = point;
          c.pathData = tmp.pathData;
          c.fillColor = getColor('green');
          tmp.remove();
        } else {
          console.warn('there is no origin for this strip!!!');
        }
        c.rotate(rotation + 90, point);
      } else if (angle === 360) {
        const tmp = new paper.Shape.Circle(point, radius);
        const x = tmp.toPath();
        c.pivot = point;
        c.pathData = x.pathData;
        tmp.remove();
        x.remove();
      } else {
        const arcValues = getArcPoints(angle, point, radius);
        const arc = createArc(arcValues, 0, point);
        c.pivot = point;
        c.pathData = arc.pathData;
        arc.remove();
        c.rotate(rotation + 90, point);
      }
    }
    c.locked = true;
  };

  const onKeyDown = (e: paper.KeyEvent) => {
    if (e.key === 'space') {
      e.preventDefault();
      return;
    }
    if (e.key === 'g') {
      e.preventDefault();
      grid = !grid;
      if (!grid) {
        clearHeadHints();
        hasFirst = undefined;
      }
    }
    if (e.key === 'escape') {
      grid = false;
      clearHeadHints();
      hasFirst = undefined;
    }
  };

  const onMouseUp = (e: paper.ToolEvent) => {
    hint.remove();
    if (dragging) {
      dragging = false;
    }
    if (panning) {
      panning = false;
    } else {
      const {
        currentZone,
        zones,
        activeBaseKey,
        activeBaseRadius,
        sprinklerBases,
      } = getState();
      if (
        (currentZone || (!currentZone && !zones.length)) &&
        (!e.item ||
          e.item.name === ITEMNAMES.TMP_HEAD_GROUP ||
          e.item.name === 'plant' ||
          e.item.name === ITEMNAMES.OUTLINE ||
          e.item.name === 'polyline')
      ) {
        const base =
          sprinklerBases.find((b) => b.uuid === activeBaseKey) || null;
        if (base) {
          const radius = activeBaseRadius;
          const sprinkler = createSprinkler(
            {
              ...startPoint,
              angle,
              radius,
              rotation: rotation + 90,
            },
            base,
          );
          if (grid) {
            const elements = headHints.map((n) => {
              const element: DesignElement = {
                ...defaultItem({ x: n.position.x, y: n.position.y }),
                type: 'sprinkler',
                props: {
                  ...sprinkler,
                  x: n.position.x,
                  y: n.position.y,
                },
                itemType: 'design-element',
              };
              return element;
            });
            addElementsEdges(elements, []);
            addHeadsToZone(
              currentZone,
              elements.map((el) => el.uuid),
            );
            hasFirst = headHints[headHints.length - 1].position;
            startPoint = hasFirst;
            headHints.forEach((n) => n.remove());
            headHints = [];
            gridHint.remove();
          } else {
            const element: DesignElement = {
              ...defaultItem({ x: startPoint.x, y: startPoint.y }),
              type: 'sprinkler',
              props: sprinkler,
              itemType: 'design-element',
            };
            addElementsEdges([element], []);
            hasFirst = startPoint;
            addHeadsToZone(currentZone, [element.uuid]);
          }
        }
      } else {
        console.warn(
          'either no zone selected, or something else clicked, so not adding a head',
        );
      }
    }
  };

  const onMouseDown = ({ e }: MouseDownPayload) => {
    startPoint = e.point;
  };

  const onMouseMove = (e: paper.ToolEvent) => {
    if (dragging) {
      dragging = false;
    } else {
      const stateKey = getState().activeBaseKey;
      const stateRadius = getState().activeBaseRadius;
      if (grid && hasFirst) {
        updateGridHint(e.point);
      } else if (
        c.pathData &&
        currentKey === stateKey &&
        radius === stateRadius
      ) {
        c.visible = true;
        c.position = e.point;
      } else {
        updateArcHint(e.point);
        currentKey = stateKey;
      }
    }
  };

  const onMouseDrag = (e: paper.ToolEvent) => {
    if ((e as any).event?.buttons === 2) {
      panning = true;
    } else {
      dragging = true;
      hint.remove();
      hint = new paper.Path([startPoint, e.point]);
      hint.strokeWidth = 2 / localZoom();
      hint.dashArray = [2, 1];
      hint.strokeColor = getColor('green');
      hint.locked = true;
      rotation = e.point.subtract(startPoint).angle;
      updateArcHint(startPoint);
    }
  };

  toolService
    .mouseDrag$('Head Placement')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseDrag);
  toolService
    .mouseMove$('Head Placement')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseMove);
  toolService
    .mouseDown$('Head Placement')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseDown);
  toolService
    .keyDown$('Head Placement')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onKeyDown);
  toolService
    .mouseUp$('Head Placement')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseUp);
};
