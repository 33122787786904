import { IValveProduct } from '@shared-types';
import { getState, setState } from './state-basics';

export const setLateralPipe = (payload: string) => {
  setState({ lateralPipe: payload });
};
export const setMainPipe = (payload: string) => {
  setState({ mainPipe: payload });
};
export const setDripPipe = (payload: string) => {
  setState({ dripPipe: payload });
};
export const setLateralValve = (payload: IValveProduct | null) => {
  setState({ lateralValve: payload });
};
export const setDripValve = (payload: IValveProduct | null) => {
  setState({ dripValve: payload });
};
// export const setValveProducts = (valveProducts: IValveProduct[]) => {
//   setState({ valveProducts });
// };
// export const setPipeProducts = (pipeProducts: IPipeProduct[]) => {
//   setState({ pipeProducts });
// };
// export const setBackflowProducts = (backflowProducts: IBackflowProduct[]) => {
//   setState({ backflowProducts });
// };
// export const setPRVProducts = (prvProducts: IPRV[]) => {
//   setState({ prvProducts });
// };
// export const setFlowKitProducts = (flowKits: IFlowKitProduct[]) => {
//   setState({ flowKits });
// };
export const clearLateralSizes = () => {
  setState({ lateralPipeSizes: [] });
};
export const clearMainSizes = () => {
  setState({ mainPipeSizes: [] });
};
export const clearDripSizes = () => {
  setState({ dripPipeSizes: [] });
};
export const setLateralSizes = (sizes: number[]) => {
  setState({ lateralPipeSizes: sizes });
};
export const setMainSizes = (sizes: number[]) => {
  setState({ mainPipeSizes: sizes });
};
export const setDripSizes = (sizes: number[]) => {
  setState({ dripPipeSizes: sizes });
};
export const toggleLateralSize = (size: number) => {
  const currentSizes = getState().lateralPipeSizes;
  const newSizes = currentSizes.includes(size)
    ? currentSizes.filter((s) => s !== size)
    : [...currentSizes, size];
  setState({
    lateralPipeSizes: newSizes,
  });
};
export const toggleMainSize = (size: number) => {
  const currentSizes = getState().mainPipeSizes;
  const newSizes = currentSizes.includes(size)
    ? currentSizes.filter((s) => s !== size)
    : [...currentSizes, size];
  setState({
    mainPipeSizes: newSizes,
  });
};
export const toggleDripSize = (size: number) => {
  const currentSizes = getState().dripPipeSizes;
  const newSizes = currentSizes.includes(size)
    ? currentSizes.filter((s) => s !== size)
    : [...currentSizes, size];
  setState({
    dripPipeSizes: newSizes,
  });
};
