import { useState } from 'react';
import { Button, Divider, Label, List, Modal } from 'semantic-ui-react';
import { contains } from 'underscore';
import { PanelButton } from '../../components/PanelButton';
import { localPaper } from '../../localPaper';
import { ITEMNAMES } from '../../shared/workbench-enums';
import { selectItems, useDesignStore } from '../../state';
import { changeValveBoxOrder } from '../../state/valvebox/changeValveBoxOrder';
import { deleteValveBox } from '../../state/valvebox/deleteValveBox';

export const moveItemInArray = (arr, oldIndex, newIndex) => {
  // https://stackoverflow.com/a/5306832/1285185
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const ValveBoxList = (): JSX.Element => {
  const zones = useDesignStore((state) => state.zones);
  const valveBoxes = useDesignStore((state) => state.valveBoxes);
  const [open, setOpen] = useState(false);

  const moveVBDown = (currentValveboxIndex: number) => {
    if (currentValveboxIndex !== valveBoxes.length - 1) {
      const newArr = moveItemInArray(
        [...valveBoxes],
        currentValveboxIndex,
        currentValveboxIndex + 1,
      );
      changeValveBoxOrder(newArr);
    }
  };
  const moveVBUp = (currentValveboxIndex: number) => {
    if (currentValveboxIndex !== 0) {
      const newArr = moveItemInArray(
        [...valveBoxes],
        currentValveboxIndex,
        currentValveboxIndex - 1,
      );
      changeValveBoxOrder(newArr);
    }
  };

  const selectVB = (uuid: string) => {
    const item = localPaper.project.getItem({
      name: ITEMNAMES.VALVE_BOX_LOCATION,
      data: { uuid },
    });
    if (item) {
      selectItems([item]);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      trigger={
        <PanelButton onClick={() => setOpen(true)}>
          Reorder Valve Boxes
        </PanelButton>
      }
    >
      <Modal.Header>Reorder Valve Boxes</Modal.Header>
      <Modal.Content scrolling>
        {open && (
          <List>
            {valveBoxes.map((vb, i) => (
              <div key={i}>
                <List.Item key={i} className="vb-item">
                  <List.Content>
                    <List.Header>
                      Valve Box {i + 1}{' '}
                      <Label as="a" onClick={() => selectVB(vb.uuid)}>
                        select
                      </Label>
                      <Label as="a" onClick={() => deleteValveBox(vb.uuid)}>
                        delete
                      </Label>
                      {valveBoxes.length > 1 && (
                        <>
                          &nbsp;
                          <Button
                            size="mini"
                            disabled={i === valveBoxes.length - 1}
                            onClick={() => moveVBDown(i)}
                          >
                            Move Down
                          </Button>
                          <Button
                            size="mini"
                            disabled={i === 0}
                            onClick={() => moveVBUp(i)}
                          >
                            Move Up
                          </Button>
                        </>
                      )}
                    </List.Header>
                  </List.Content>
                </List.Item>
                <Divider />
              </div>
            ))}
            <List.Item>
              <List.Content>
                <List.Header>Rogue valve assemblies</List.Header>
                <List.Description>
                  {zones
                    .filter(
                      (zone) =>
                        !valveBoxes.some((v) => contains(v.zoneIDs, zone.uuid)),
                    )
                    .map((zone) => (
                      <div key={zone.uuid}>zone: {zone.uuid}</div>
                    ))}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button primary onClick={() => setOpen(false)}>
          Done
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
