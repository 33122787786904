import { BaseStyledItem, IStyle } from '@shared-types';
import { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { styled } from 'styled-components';
import { compact, uniq } from 'underscore';
import { PanelInputWrap, PanelSelect } from './PanelButton';

interface Props {
  items: BaseStyledItem[];
  onChange: (items: BaseStyledItem[]) => void;
}
export const EditStroke = ({ items, onChange }: Props) => {
  const [localStyle, setLocalStyle] = useState<IStyle>({});

  useEffect(() => {
    const currentStyle: IStyle = {};
    const style = items.map((item) => item.style);
    const strokeWidths = compact(uniq(style.map((s) => s.strokeWidth)));
    if (strokeWidths.length === 1) {
      currentStyle.strokeWidth = strokeWidths[0];
    } else {
      delete currentStyle.strokeWidth;
    }
    const dashArrays = compact(
      uniq(
        style.map((s) => (s.dashArray ? JSON.stringify(s.dashArray) : null)),
      ),
    );
    if (dashArrays.length === 1) {
      currentStyle.dashArray = JSON.parse(dashArrays[0]) as number[];
    } else {
      delete currentStyle.dashArray;
    }
    const dashOffsets = compact(uniq(style.map((s) => s.dashOffset)));
    if (dashOffsets.length === 1) {
      currentStyle.dashOffset = dashOffsets[0];
    } else {
      delete currentStyle.dashOffset;
    }
    const strokeCaps = compact(uniq(style.map((s) => s.strokeCap)));
    if (strokeCaps.length === 1) {
      currentStyle.strokeCap = strokeCaps[0];
    } else {
      delete currentStyle.strokeCap;
    }
    const strokeJoins = compact(uniq(style.map((s) => s.strokeJoin)));
    if (strokeJoins.length === 1) {
      currentStyle.strokeJoin = strokeJoins[0];
    } else {
      delete currentStyle.strokeJoin;
    }
    const strokeColors = compact(uniq(style.map((s) => s.strokeColor)));
    if (strokeColors.length === 1) {
      currentStyle.strokeColor = strokeColors[0];
    } else {
      delete currentStyle.strokeColor;
    }
    const fillColors = uniq(style.map((s) => s.fillColor));
    if (fillColors.length === 1) {
      currentStyle.fillColor = fillColors[0];
    } else {
      delete currentStyle.fillColor;
    }
    const fillAlphas = compact(uniq(style.map((s) => s.fillAlpha)));
    if (fillAlphas.length === 1) {
      currentStyle.fillAlpha = fillAlphas[0];
    } else {
      delete currentStyle.fillAlpha;
    }
    setLocalStyle(currentStyle);
  }, [items]);

  const updateValue = (value: Partial<IStyle>) => {
    const updatedStyle = { ...localStyle, ...value };
    const updatedLocals = items.map((item) => ({
      ...item,
      style: updatedStyle,
    }));
    setLocalStyle(updatedStyle);
    onChange(updatedLocals);
  };
  const updateDashArray = (index: number, value: number) => {
    const updatedDashArray = localStyle.dashArray || [0, 0, 0, 0];
    updatedDashArray[index] = value;
    updateValue({ dashArray: updatedDashArray });
  };
  return (
    <Wrap>
      <PanelInputWrap
        label={'Stroke Width'}
        onChange={(e) => {
          updateValue({ strokeWidth: parseFloat(e.target.value) || 0 });
        }}
        type="number"
        min={0}
        step={0.1}
        value={localStyle.strokeWidth || '0'}
      />
      <div className="col-2">
        <PanelSelect
          value={localStyle.strokeJoin}
          label="Stroke Join"
          options={['', 'miter', 'round', 'bevel'].map((s) => ({
            text: s,
            value: s,
          }))}
          onChange={(e, data) => {
            if (data.value === '') {
              return;
            }
            updateValue({ strokeJoin: data.value as IStyle['strokeJoin'] });
          }}
        />
        <PanelSelect
          value={localStyle.strokeCap}
          label="Stroke Cap"
          options={['', 'butt', 'round', 'square'].map((s) => ({
            text: s,
            value: s,
          }))}
          onChange={(e, data) => {
            if (data.value === '') {
              return;
            }
            updateValue({ strokeCap: data.value as IStyle['strokeCap'] });
          }}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th colSpan={2} align="left">
              Dash Array
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Dash 1: </td>
            <td>
              <Input
                type="number"
                min={0}
                step={0.1}
                value={
                  localStyle.dashArray?.length ? localStyle.dashArray[0] : ''
                }
                onChange={(e) => {
                  updateDashArray(0, parseFloat(e.target.value));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Gap 1: </td>
            <td>
              <Input
                type="number"
                min={0}
                step={0.1}
                value={
                  localStyle.dashArray?.length ? localStyle.dashArray[1] : ''
                }
                onChange={(e) => {
                  updateDashArray(1, parseFloat(e.target.value));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Dash 2: </td>
            <td>
              <Input
                type="number"
                min={0}
                step={0.1}
                value={
                  localStyle.dashArray?.length ? localStyle.dashArray[2] : ''
                }
                onChange={(e) => {
                  updateDashArray(2, parseFloat(e.target.value));
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Gap 2: </td>
            <td>
              <Input
                type="number"
                min={0}
                step={0.1}
                value={
                  localStyle.dashArray?.length ? localStyle.dashArray[3] : ''
                }
                onChange={(e) => {
                  updateDashArray(3, parseFloat(e.target.value));
                }}
              />
            </td>
          </tr>

          <tr>
            <td>Offset</td>
            <td>
              <Input
                type="number"
                min={0}
                step={0.1}
                value={localStyle.dashOffset || 0}
                onChange={(e) => {
                  updateValue({ dashOffset: parseFloat(e.target.value) });
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <PanelInputWrap
        label={'Stroke Color'}
        type="color"
        value={localStyle.strokeColor || ''}
        onChange={(e) => {
          updateValue({ strokeColor: e });
        }}
      />
      <>
        <PanelInputWrap
          label={'Fill Color'}
          type="color"
          value={localStyle.fillColor || ''}
          onChange={(e) => {
            updateValue({ fillColor: e || null });
          }}
        />
        {localStyle.fillColor && (
          <PanelInputWrap
            label={'Opacity'}
            onChange={(e) => {
              const newValue = e.target.value ? parseFloat(e.target.value) : 0;
              updateValue({ fillAlpha: newValue / 100 });
            }}
            type="number"
            min={0}
            step={1}
            max={100}
            isAllowed={(values) => {
              const { floatValue } = values;
              return floatValue >= 0 && floatValue <= 100;
            }}
            value={Math.round(
              (localStyle.fillAlpha !== undefined ? localStyle.fillAlpha : 1) *
                100,
            )}
          />
        )}
      </>
    </Wrap>
  );
};
const Wrap = styled.div`
  .col-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
    > div {
      flex: 1;
    }
  }
`;
