import { IPlant } from '@shared-types';
import { filter, map, merge, switchMap, takeUntil, tap } from 'rxjs';
import paper from 'src/paper';
import { getEuclideanDistance, polyHintPurple } from 'src/shared';

import { localPaper, localZoom } from '../localPaper';
import { activateNamedLayer, getColor } from '../paper-helpers/plot.helpers';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { addPlant, getState } from '../state';
import { toolService } from '../tool.service';
import { plantService } from './plant.service';

const createTmpPlant = (point: paper.Point, radius: number) => {
  const plant = new paper.Path.Circle({
    center: point,
    radius,
    strokeColor: getColor(polyHintPurple),
    strokeWidth: 1 / localZoom(),
  });
  return plant;
};

const { mouseDown$, mouseUp$, mouseDrag$ } =
  toolService.getEvents('Plant placement');

const dragDrawPlant$ = mouseDown$.pipe(
  filter(({ e }) => !e.modifiers.space),
  map(({ e }) => {
    activateNamedLayer(LAYER_NAMES.PLANTS);

    const radius = 0.01;
    const tmpPlant = createTmpPlant(e.point, radius);
    return { tmpPlant, radius, downPoint: e.downPoint };
  }),
  switchMap(({ tmpPlant, radius, downPoint }) =>
    mouseDrag$.pipe(
      tap((e) => {
        const newRadius = getEuclideanDistance(downPoint, e.point);
        if (newRadius !== 0 && radius !== 0) {
          tmpPlant.scale(newRadius / radius);
          radius = newRadius;
        }
      }),
      takeUntil(
        merge(
          mouseUp$.pipe(
            tap(() => {
              if (radius > 0.1) {
                const p = tmpPlant.position;
                const activePlant = getState().activePlant;
                if (activePlant) {
                  const plant: IPlant = {
                    ...plantService.createItem({ x: p.x, y: p.y }),
                    name: activePlant.name,
                    width: radius * 2,
                    gph: activePlant.gph,
                    gpm: activePlant.gpm,
                  };
                  addPlant(plant);
                }
              }
            }),
          ),
          // escapeKey$
        ).pipe(
          tap(() => {
            tmpPlant.remove();
            activateNamedLayer(LAYER_NAMES.DEFAULT);
          }),
        ),
      ),
    ),
  ),
);

// color plants purple when switching to plant placement tool
// otherwise if previous tool was plant placement, color plants back to black
const colorPlants$ = toolService.activeTool$.pipe(
  map((tool) => {
    if (tool === 'Plant placement') {
      plantService.colorPlants(true);
      localPaper.project.layers.forEach((layer) => {
        if (layer.name === LAYER_NAMES.PLANTS) {
          layer.opacity = 1;
        } else {
          layer.opacity = 0.3;
        }
      });
    } else {
      plantService.colorPlants(false);
      localPaper.project.layers.forEach((layer) => {
        layer.opacity = 1;
      });
    }
  }),
);

export const drawPlant$ = merge(colorPlants$, dragDrawPlant$);
