import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const RouteWithAuth = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        if (!token) {
          throw new Error('No token');
        }
      } catch (error) {
        console.error(error);
        navigate('/', { replace: true, state: { from: location } });
      }
    })();
  }, [isAuthenticated, navigate, location, getAccessTokenSilently]);

  return <>{!!isAuthenticated && <Outlet />}</>;
};
