import { List } from 'semantic-ui-react';
import { createTakeoffPDF } from './createPacket';

export const TakeoffExport = (): JSX.Element => {
  return (
    <List.Item>
      <List.Icon name="file pdf" />
      <List.Content as="a" onClick={createTakeoffPDF}>
        Export Takeoff PDF
      </List.Content>
    </List.Item>
  );
};
