import { Zone } from '@shared-types';
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { contains } from 'underscore';
import { renderTree } from '../../components/TreeDiagram';
import { isPlant } from '../../plants/plants';
import { getState, pocGraphByNode, useDesignStore } from '../../state';

interface Props {
  zone: Zone;
  classTitle: string;
}

export interface GraphType {
  id: string;
  type: string;
  children: GraphType[] | null;
}

export const ZoneTreeDiagram = ({ zone, classTitle }: Props) => {
  const treeRef = useRef<any>();
  const zones = useDesignStore((state) => state.zones);
  const plants = useDesignStore((state) => state.items.filter(isPlant));
  const edges = useDesignStore((state) => state.edges);
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  const elementCache = useDesignStore((state) => state.elementCache);
  const selectedItems = useDesignStore((state) => state.selectedItems);
  useEffect(() => {
    const { pipeProducts, valveProducts, backflowProducts } = getState();
    if (!selectedItems.length) {
      d3.select(treeRef.current).select('*').remove();
      const dg = pocGraphByNode(zone.valve, pocGraphs);
      if (dg) {
        const losses = dg.lossesAtZone(
          zone,
          pipeProducts,
          valveProducts,
          backflowProducts,
          edges,
        );
        const width = treeRef.current?.clientWidth || 50;
        const height = treeRef.current?.clientHeight || 50;
        const padding = 60;
        const paddedWidth = width - padding;
        const paddedHeight = height - padding;
        const hierarchicalTree = renderTree(dg, elementCache, zone.valve);
        if (hierarchicalTree) {
          const hierarchy = d3.hierarchy<GraphType>(hierarchicalTree);
          const d3tree = d3
            .tree<GraphType>()
            .size([paddedHeight, paddedWidth])
            .separation((a, b) => (a.parent === b.parent ? 1 : 2));
          const root = d3tree(hierarchy);
          // const d3Cluster = d3
          //   .cluster<GraphType>()
          //   .size([paddedHeight, paddedWidth])
          // const root = d3Cluster(hierarchy)
          const svg = d3.select(treeRef.current).append('svg');
          svg.attr('width', width);
          svg.attr('height', height);
          svg.attr('class', classTitle);
          const g = svg.append('g').attr('transform', 'translate(20,20)');

          g.selectAll('.link')
            .data(root.links())
            .enter()
            .append('line')
            .attr('class', 'link')
            .attr('x1', (d) => d.source.y)
            .attr('y1', (d) => d.source.x)
            .attr('x2', (d) => d.target.y)
            .attr('y2', (d) => d.target.x)
            .attr('stroke', '#999')
            .attr('stroke-width', 0.5);

          g.selectAll('.link-size')
            .data(root.links())
            .enter()
            .append('text')
            .attr('class', 'link-size')
            .attr('x', (d) => d.source.y + (d.target.y - d.source.y) / 2)
            .attr('y', (d) => d.source.x + (d.target.x - d.source.x) / 2 - 6)
            .attr('font-size', '8px')
            .attr('font-weight', 'normal')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'central')
            .attr('font-family', 'sans-serif')
            .text((d) => {
              const edge = edges.find(
                (edge) =>
                  edge.source === d.source.data.id &&
                  edge.target === d.target.data.id,
              );
              const parentEdge = edges.find(
                (e) => e.target === d.source.data.id,
              );
              if (edge && edge.pipe) {
                const pipe = pipeProducts.find((p) => p.uuid === edge.pipe);
                const parentPipe = pipeProducts.find(
                  (p) => p.uuid === (parentEdge ? parentEdge.pipe : ''),
                );
                if (!d.source.parent && pipe) {
                  return `${pipe.size}"`;
                } else if (
                  parentEdge &&
                  parentPipe &&
                  pipe &&
                  parentPipe.size > pipe.size
                ) {
                  return `${pipe.size}"`;
                } else {
                  return '';
                }
              }
              return 'n/a';
            });

          // general nodes
          g.selectAll('circle.node')
            .data(root.descendants().filter((d) => d.data.type !== 'valve'))
            .enter()
            .append('circle')
            .classed('node', true)
            .attr('cx', (d) => d.y)
            .attr('cy', (d) => d.x)
            .attr('r', (d) => (d.data.type === 'fitting' ? 1 : 2))
            .attr('fill', (d) =>
              d.data.type === 'fitting' ? 'white' : 'steelblue',
            )
            .attr('stroke', (d) => (d.data.type === 'fitting' ? '#999' : ''));

          // valves
          g.selectAll('circle.valve')
            .data(root.descendants().filter((d) => d.data.type === 'valve'))
            .enter()
            .append('circle')
            .classed('valve', true)
            .attr('cx', (d) => d.y - 6)
            .attr('cy', (d) => d.x)
            .attr('r', 6)
            .attr('fill', (d) => {
              const zone = zones.find((z) => z.valve === d.data.id);
              return zone ? zone.color : '#000';
            });

          // valve zone number
          g.selectAll('.zonenum')
            .data(root.descendants().filter((d) => d.data.type === 'valve'))
            .enter()
            .append('text')
            .attr('class', 'zonenum')
            .attr('x', (d) => d.y - 6)
            .attr('y', (d) => d.x + 12)
            .text((d) => {
              const zone = zones.find((z) => z.valve === d.data.id);
              return zone
                ? `${zone.isDrip || zone.plantIds.length > 0 ? 'D' : ''}${
                    zone.orderNumber + 1
                  }`
                : 'z';
            })
            .attr('font-family', 'sans-serif')
            .attr('font-weight', 'bold')
            .attr('font-size', '10px')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'central');

          let worstResidual = 10000;
          g.selectAll('.loss')
            .data(
              root
                .descendants()
                .filter((d) => !d.children)
                .map((d) => {
                  const zone = zones.find((z) =>
                    contains(z.headIds, d.data.id),
                  );
                  let residual = 0;
                  if (zone) {
                    const thisLoss = losses.find(
                      (loss) => loss.candidateID === d.data.id,
                    );
                    if (thisLoss) {
                      residual = thisLoss.residualPSI;
                      if (thisLoss.residualPSI < worstResidual) {
                        worstResidual = thisLoss.residualPSI;
                      }
                    }
                  }
                  return {
                    ...d,
                    residual,
                  };
                }),
            )
            .enter()
            .append('text')
            .attr('class', 'loss')
            .attr('x', (d) => d.y + 5)
            .attr('y', (d) => d.x)
            .text((d) => (d.residual > 0 ? d.residual.toFixed(2) : ''))
            .attr('font-family', 'sans-serif')
            .attr('font-weight', (d) =>
              d.residual === worstResidual ? 'bold' : 'normal',
            )
            .attr('fill', (d) =>
              d.residual < 0
                ? 'red'
                : d.residual === worstResidual
                  ? 'steelblue'
                  : '#333333',
            )
            .attr('font-size', '12px')
            .attr('text-anchor', () => 'left')
            .attr('alignment-baseline', 'central');
        }
      }
    }
  }, [
    zone,
    selectedItems,
    classTitle,
    edges,
    plants,
    zones,
    elementCache,
    pocGraphs,
  ]);
  return <Diagram ref={treeRef} />;
};

const Diagram = styled.div`
  width: 500px;
  height: 200px;
  border: 1px solid black;
  margin: 10px auto;
  .link {
    stroke: #999;
    stroke-width: 1px;
  }
`;
