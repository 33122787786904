import * as math from 'mathjs';
import { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  Basemap,
  ImageUpload,
  PDFUpload,
} from '../../../../../shared-types/workbench-types';
import { ButtonAction } from '../components/layers/ButtonAction';
import { getItemsByType, isBasemap } from '../helpers';
import { setScale } from '../state';
import { addItem } from '../state/item-state';
import { PDFUploader } from './PDFUploader';
import { basemapService } from './basemap.service';

export const scaleRasterImage = (originalScaleFactor: number): number => {
  return 1 / originalScaleFactor;
};
export const BasemapUploader = () => {
  const [uploadBasemap, setUploadBasemap] = useState(false);

  const convertJPGtoBasemap = (
    file: PDFUpload | ImageUpload,
    xValue: number,
  ): Basemap => {
    const scale = file.scale;
    const basemap: Basemap = {
      ...basemapService.createItem(),
      scale,
      width: file.width,
      height: file.height,
      position: { x: xValue, y: 0 },
      cropBox: {
        topLeft: { x: xValue, y: 0 },
        bottomRight: {
          x: xValue + file.width * scaleRasterImage(scale),
          y: file.height * scaleRasterImage(scale),
        },
      },
      jpgUUID: file.uuid,
      original: {
        type: file.type,
        filename: file.originalFilename,
        width: file.width,
        height: file.height,
        scale: file.scale,
        rotation: file.rotation,
      },
    };
    if (file.type === 'pdf') {
      basemap.original.pageNumber = (file as PDFUpload).pageNumber;
    }
    return basemap;
  };
  const handleUploadedFiles = async (files: (ImageUpload | PDFUpload)[]) => {
    let nextX = 0;
    const hasBasemaps = getItemsByType(isBasemap).length > 0;
    const scales: number[] = [];
    for (let i = 0; i < files.length; i++) {
      const originalFile = files[i];
      const basemap = convertJPGtoBasemap(originalFile, nextX);
      addItem(basemap);
      scales.push(basemap.scale);
      nextX += originalFile.width / originalFile.scale;
    }
    if (!hasBasemaps && scales.length) {
      const mode = math.mode(scales);
      setScale(mode);
    }
    setUploadBasemap(false);
  };
  return (
    <Wrap>
      <ButtonAction title="Upload" onClick={() => setUploadBasemap(true)} />
      <Modal
        size="large"
        dimmer="blurring"
        open={uploadBasemap}
        closeOnDimmerClick={false}
        closeIcon={true}
        onClose={() => setUploadBasemap(false)}
        content={
          <PDFUploader
            limit={5}
            onFinished={handleUploadedFiles}
            onCancel={() => setUploadBasemap(false)}
          />
        }
      />
    </Wrap>
  );
};

const Wrap = styled.div``;
