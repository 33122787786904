import { CustomSymbolType } from '@shared-types';
import { paverSVG, rockSVG } from '../../../shared';

export const customSymbols: {
  [key in CustomSymbolType]: {
    name: string;
    svgString: string;
  };
} = {
  rock: {
    name: 'Rock',
    svgString: rockSVG,
  },
  paver: {
    name: 'Paver',
    svgString: paverSVG,
  },
};
