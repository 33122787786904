import { Basemap } from '../../../../../shared-types';
import { PaperSheet } from '../sheets/paper-sheet';
import { PaperBasemap } from '../upload/paper-basemap';

const intersectingPaperBasemaps = (
  paperBasemaps: PaperBasemap[],
  paperSheet: PaperSheet,
): PaperBasemap[] => {
  // any basemaps that have a cropbox that intersects the sheet
  return paperBasemaps.filter((basemap) => {
    if (!basemap.clipMask) return false;
    const cropBox = basemap.clipMask.bounds;
    const sheetBounds = paperSheet.group.bounds;
    return (
      sheetBounds.intersects(cropBox) ||
      sheetBounds.contains(cropBox.topLeft) ||
      sheetBounds.contains(cropBox.topRight) ||
      sheetBounds.contains(cropBox.bottomRight) ||
      sheetBounds.contains(cropBox.bottomLeft) ||
      sheetBounds.contains(cropBox.center)
    );
  });
};

export const filterIntersectingBasemaps = (
  paperBasemaps: PaperBasemap[],
  paperSheet: PaperSheet,
  basemaps: Basemap[],
): Basemap[] =>
  intersectingPaperBasemaps(paperBasemaps, paperSheet).map(
    (paperBasemap) =>
      basemaps.filter((basemap) => basemap.uuid === paperBasemap.id)[0],
  );
