import ReactSlider from 'react-slider';
import styled from 'styled-components';

export const Slider = ({
  value,
  onChange,
  step = 1,
  min = 0,
  max = 100,
}: any) => {
  return (
    <Wrap>
      <ReactSlider
        value={value}
        min={min}
        step={step}
        max={max}
        className="horizontal-slider"
        thumbClassName="thumb"
        trackClassName="track"
        onChange={onChange}
      />
    </Wrap>
  );
};
export const Wrap = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  .horizontal-slider {
    flex: 1;
    margin: auto;
    height: 100%;
  }
  .thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    height: 24px;
    width: 24px;
    background: white;
    border-radius: 4px;
    top: 50%;
    transform: translateY(-50%);
    &.active {
      background: #83a9ff;
    }
  }
  .track {
    position: relative;
    background: #ddd;
    height: 8px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;
  }
  .track.track-0 {
    background: #83a9ff;
    height: 8px;
  }
`;
