import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Image, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { IPlant, PlantBase } from '../../../../../shared-types';
import { plantOptions, PlantType } from '../../../shared';
import { PanelButton, PanelLabel, PanelWrap } from '../components/PanelButton';
import { paperItemStore } from '../helpers';
import { reducePlantsGPHbyId } from '../helpers/directedGraph';
import { addPlantsToZone, useDesignStore } from '../state';
import { bulkUpdateItems } from '../state/item-state';
import { ChangeDripZone } from './ChangeDripZone';
import { isPaperPlant } from './paper-plant';
import { isPlant } from './plants';

export const MultiPlantTooltip = ({ items }: { items: IPlant[] }) => {
  const [showChangeZone, setShowChangeZone] = useState(false);
  const [plants, setPlants] = useState(items);
  const statePlants = useDesignStore((state) => state.items.filter(isPlant));
  const [currentWidth, setCurrentWidth] = useState(0);

  useEffect(() => {
    setPlants(items);
    if (items.length) {
      setCurrentWidth(items[0].width);
    }
  }, [items]);

  const handleAddToZone = (zoneID: string, plantIDs: string[]) => {
    addPlantsToZone(zoneID, plantIDs);
  };
  const doChangePlant = (plant: IPlant, newPlantBase: PlantBase) => {
    const paperPlant = paperItemStore.get(plant.uuid);
    if (paperPlant && isPaperPlant(paperPlant)) {
      paperPlant.toggleOutline(true);
    }
    let width = plant.width;
    if (
      plant.name === PlantType.DECIDUOUS_TREE ||
      plant.name === PlantType.EVERGREEN_TREE
    ) {
      if (
        newPlantBase.name !== PlantType.DECIDUOUS_TREE &&
        newPlantBase.name !== PlantType.EVERGREEN_TREE
      ) {
        width = newPlantBase.defaultWidth;
      }
    } else {
      if (
        newPlantBase.name === PlantType.DECIDUOUS_TREE ||
        newPlantBase.name === PlantType.EVERGREEN_TREE
      ) {
        width = newPlantBase.defaultWidth;
      }
    }
    const newPlant = {
      ...plant,
      gpm: newPlantBase.gpm,
      gph: newPlantBase.gph,
      name: newPlantBase.name,
      width,
    };
    return newPlant;
  };
  const doChangeWidth = (newWidth: number, save = false) => {
    let updatedPlants = plants.map((plant) => {
      const paperPlant = paperItemStore.get(plant.uuid);
      if (paperPlant && isPaperPlant(paperPlant)) {
        paperPlant?.toggleOutline(true);

        const updatedPlant: IPlant = {
          ...plant,
          width: newWidth,
        };
        setCurrentWidth(newWidth);
        if (paperPlant) {
          paperPlant.update(updatedPlant);
        }
        return updatedPlant;
      }
      return plant;
    });
    if (save) {
      setPlants(updatedPlants);
      bulkUpdateItems(updatedPlants);
    }
  };
  const changePlants = (newPlantBase: PlantBase) => {
    const newPlants = plants.map((plant) => {
      const newPlant = doChangePlant(plant, newPlantBase);
      return newPlant;
    });
    setPlants(newPlants);
    bulkUpdateItems(newPlants);
  };
  const setPreExisting = (preExisting: boolean) => {
    const updatedPlants = plants.map((plant) => ({ ...plant, preExisting }));
    setPlants(updatedPlants);
    bulkUpdateItems(updatedPlants);
  };
  return (
    <Wrap>
      You have selected {items.length} plants with{' '}
      {reducePlantsGPHbyId(
        statePlants,
        items.map((p) => p.uuid),
      )}{' '}
      GPH
      <PanelLabel>Change Plant Type</PanelLabel>
      {plantOptions.map((p, i) => (
        <div
          className={`plant-item`}
          key={i}
          onClick={changePlants.bind(null, p)}
        >
          <div>{p.name}</div>
          <Image src={`data:image/svg+xml;utf8,${p.iconSVG}`} />
        </div>
      ))}
      <PanelWrap>
        <Checkbox
          onClick={(e, data) => setPreExisting(!!data.checked)}
          checked={plants.every((p) => p.preExisting)}
          label="Pre-existing? (will not show in takeoff)"
        />
      </PanelWrap>
      <PanelLabel>Change Diameter</PanelLabel>
      <Slider
        defaultValue={currentWidth}
        value={currentWidth}
        onChange={(w) => doChangeWidth(w as number)}
        step={1}
        min={1}
        max={100}
        onChangeComplete={(w) => doChangeWidth(w as number, true)}
      />{' '}
      <PanelButton onClick={() => setShowChangeZone(true)}>
        Change Zone
      </PanelButton>
      <Modal size="mini" open={showChangeZone}>
        <Modal.Header>Change Plant Zone</Modal.Header>
        <Modal.Content>
          <ChangeDripZone
            plantIDs={items.map((i) => i.uuid)}
            onAdd={handleAddToZone}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setShowChangeZone(false)}>
            Done
          </Button>
        </Modal.Actions>
      </Modal>
    </Wrap>
  );
};
const Wrap = styled(PanelWrap)`
  .plant-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #3e3e3e;
    cursor: pointer;
    margin-bottom: 8px;
    background: #ccc;
    padding: 8px;
    &.active {
      background: #2185d0;
    }
    img.ui.image {
      height: 30px;
    }
  }
`;
