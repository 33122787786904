import { takeUntil } from 'rxjs';
import paper from 'src/paper';
import {
  BaseItem,
  DesignElement,
} from '../../../../../shared-types/workbench-types';
import { localPaper } from '../localPaper';
import { findNamedItems, getColor } from '../paper-helpers/plot.helpers';
import { ITEMNAMES } from '../shared/workbench-enums';
import {
  addToSelectedIDs,
  clearSelectedIDs,
  getState,
  selectItems,
} from '../state';
import { MouseDownPayload, ToolService } from '../tool.service';

export const setupMarqueeTool = (toolService: ToolService) => {
  let marqueePath: paper.Path;
  let startPoint = new paper.Point(0, 0);

  const finalizeLasso = (paperItems: paper.Item[], items?: BaseItem[]) => {
    selectItems(paperItems);
    clearSelectedIDs();
    paperItems.forEach((i) => (i.selected = true));
    if (items) {
      addToSelectedIDs(items.map((i) => i.uuid));
    }
    if (marqueePath) {
      marqueePath.remove();
    }
    if (!paperItems.length) {
      localPaper.project.deselectAll();
    }
    if (!items?.length) {
      clearSelectedIDs();
    }
  };
  const finalizePlantLasso = (items: paper.Item[]) => {
    clearSelectedIDs();
    addToSelectedIDs(items.map((i) => i.data.uuid));
    if (marqueePath) {
      marqueePath.remove();
    }
    if (!items.length) {
      clearSelectedIDs();
      localPaper.project.deselectAll();
    }
  };

  const onMouseDown = ({ e }: MouseDownPayload) => {
    if (marqueePath) {
      finalizeLasso([]);
    }
    startPoint = e.point;
    marqueePath = new paper.Path({
      segments: [e.point],
    });
    marqueePath.fillColor = getColor('red');
    marqueePath.opacity = 0.2;
  };

  const onKeyUp = (e: paper.KeyEvent) => {
    if (e.key === 'escape') {
      finalizeLasso([]);
    }
  };
  const onMouseDrag = (e) => {
    if (e.modifiers.space) {
      // pan(e);
    } else {
      const rect = new paper.Rectangle(startPoint, e.point);
      const tmpRect = new paper.Path.Rectangle(rect);
      marqueePath.pathData = tmpRect.pathData;
      tmpRect.remove();
    }
  };

  const onMouseUp = () => {
    const { lassoMode } = getState();
    if (lassoMode === 'Heads') {
      const elements = findNamedItems(ITEMNAMES.ELEMENT);
      const filteredEls = elements.filter(
        (el) =>
          el.visible &&
          (el.data as DesignElement).type === 'sprinkler' &&
          el.position.isInside(marqueePath.bounds),
      );
      finalizeLasso(filteredEls);
    } else if (lassoMode === 'All') {
      const elements = findNamedItems(ITEMNAMES.ELEMENT);
      const items = getState().items;
      const filteredEls = elements.filter(
        (el) => el.visible && el.position.isInside(marqueePath.bounds),
      );
      const filteredItems = items.filter((item) =>
        marqueePath.contains(item.position),
      );
      finalizeLasso(filteredEls, filteredItems);
    } else if (lassoMode === 'Plants') {
      const plants = findNamedItems('plant');
      const filteredPlants = plants.filter(
        (plant) => plant.visible && plant.position.isInside(marqueePath.bounds),
      );
      finalizePlantLasso(filteredPlants);
    }
  };

  toolService
    .keyUp$('Marquee')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onKeyUp);
  toolService
    .mouseUp$('Marquee')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseUp);
  toolService
    .mouseDown$('Marquee')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseDown);
  toolService
    .mouseDrag$('Marquee')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseDrag);
};
