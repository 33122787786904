import { Icon, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { PanelWrap } from '../../components/PanelButton';
import { useDesignStore } from '../../state';
import { openModal } from '../../state/modal.store';
import { PipeEquipmentModalType } from '../pipes/PipeEquipment';
import { DeleteGeneratedSleevesButton } from './DeleteGeneratedSleevesButton';
import { DeleteUserSleevesButton } from './DeleteUserSleevesButton';
import { GenerateSleeves } from './GenerateSleeves';
export const SleeveToolProperties = () => {
  const lateralPipe = useDesignStore((state) => state.lateralPipe);
  const mainPipe = useDesignStore((state) => state.mainPipe);
  return (
    <Wrap>
      <h5>Sleeve Tool</h5>
      {(!lateralPipe || !mainPipe) && (
        <Message color="red">
          <Icon name="warning" />
          Pipes equipment is incomplete.
          <span
            className="linkLike"
            onClick={() => openModal(PipeEquipmentModalType)}
          >
            Set Pipes
          </span>
        </Message>
      )}

      <GenerateSleeves />
      <DeleteGeneratedSleevesButton />
      <DeleteUserSleevesButton />
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)`
  .linkLike {
    margin-left: 8px;
    cursor: pointer;
    text-decoration: underline;
  }
`;
