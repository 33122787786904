import { getState, setState } from '../../../state';
import { deletePaperItem } from '../../../state/renderers';

export const deleteSleeve = (sleeveID: string) => {
  const updatedItems = getState().items.filter(
    (item) => item.uuid !== sleeveID,
  );
  setState({
    items: updatedItems,
    selectedIDs: [],
  });
  deletePaperItem(sleeveID);
};
