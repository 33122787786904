import { PDFDocument } from 'pdf-lib';
import { Content } from 'pdfmake/interfaces';
import { createPDFMakePage } from './createPDFMakePage';
import { standardDefinition } from './pdf.helpers';

export async function createFullTreePDF(): Promise<PDFDocument | void> {
  const definitionFunc = async () => standardDefinition();
  const getContent = async () => fullTreePages();
  return createPDFMakePage(definitionFunc, getContent);
}

const generateFullTrees = () => {
  const fullDiagrams = Array.from(document.querySelectorAll('.full-tree'));
  return fullDiagrams.length ? fullDiagrams.map((tree) => tree.outerHTML) : [];
};
const fullTreePages = (): Content[] => {
  const fullCharts = generateFullTrees();
  return fullCharts.length
    ? fullCharts.map((fullChart) => {
        return [
          {
            text: 'System Flow Chart',
            style: 'header',
          },
          {
            text: 'Pipe sizes are shown when a reduction in pipe size occurs. Each blue dot is a head. Zones are marked with a large colored dot. The residual pressure for each zone is shown at the end of each branch. The lowest residual pressure is marked in blue. Not drawn to scale. On-site substitution of pipes should always use a pipe with a diameter equal to or greater than the one shown on the chart.',
            margin: [0, 0, 0, 18],
          },
          {
            svg: fullChart,
            fit: [540, 600],
            margin: [6, 6, 0, 0],
          },
        ];
      })
    : [];
};
