import { IPoint } from '@shared-types';
import paper from 'src/paper';
import { itemSizes } from 'src/shared';
import { getColor } from './plot.helpers';

export const createTextShape = (
  point: IPoint,
  sides: number,
  scale: number,
  letters: string,
  rotation = 0,
): paper.Item => {
  const g = new paper.Group();
  const p = new paper.Path.RegularPolygon({
    center: [0, 0],
    sides: sides,
    radius: (itemSizes.font / scale) * 1.2,
  });
  p.strokeColor = getColor('black');
  p.strokeWidth = 1 / scale;
  p.fillColor = getColor('white');
  p.rotate(rotation);
  const text = new paper.PointText({
    point: [0, 0],
    content: letters,
    fontSize: itemSizes.font / scale,
  });
  text.position = new paper.Point(p.position.x, p.position.y + 0.1);
  g.addChildren([p, text]);
  g.position = new paper.Point(point);
  return g;
};
