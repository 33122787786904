import { useCallback, useEffect, useRef } from 'react';

export const useUserInteraction = (callback, delay = 500) => {
  const timer = useRef<number>();

  const handleUserInteraction = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    (timer as any).current = window.setTimeout(callback, delay);
  }, [callback, delay]);

  useEffect(() => {
    const events = ['keyup', 'mouseup'];

    events.forEach((event) => {
      window.addEventListener(event, handleUserInteraction);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleUserInteraction);
      });
      if (timer.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timer.current);
      }
    };
  }, [handleUserInteraction]);

  // Ensure timer.current is cleaned up on unmount
  useEffect(() => {
    return () => {
      if (timer.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timer.current);
      }
    };
  }, []);
};
