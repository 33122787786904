import { DesignElement, IPRV } from '@shared-types';
import { useState } from 'react';
import { Button, Divider, DropdownProps, Select } from 'semantic-ui-react';
import { styled } from 'styled-components';
import { useDesignStore } from '../state';
import { changeElements } from '../state/changeElements';
import { PanelInputWrap, PanelWrap } from './PanelButton';

interface Props {
  element: DesignElement;
}
export const PRVTooltip = ({ element }: Props) => {
  const prvProducts = useDesignStore((state) => state.prvProducts);
  const [prv, setPRV] = useState<IPRV>(element.props as IPRV);

  const doSetPRV = (_, data: DropdownProps) => {
    const p = prvProducts.find(
      (p) => convertedName(p) === (data.value as string),
    );
    if (p) {
      setPRV(p);
    }
  };

  const changeElevation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const elevation = parseInt(e.currentTarget.value, 10);
    setPRV({
      ...prv,
      elevation,
    });
  };

  const handleSave = (): void => {
    changeElements([
      {
        ...element,
        props: prv,
      },
    ]);
  };

  const cancelSave = () => {
    setPRV(element.props as IPRV);
  };

  const convertedName = (p: IPRV) => {
    return `${p.name} (${p.outputPSI} PSI)`;
  };

  return (
    <Wrap>
      <div>
        <p>Name: {(element.props as IPRV).name}</p>
        <p>
          Output PSI:
          {(element.props as IPRV).outputPSI}
        </p>
        <Divider />
        <p>Max Input PSI: {(element.props as IPRV).maxInputPSI}</p>
        <p>
          Output PSI: {(element.props as IPRV).minOutputPSI} -{' '}
          {(element.props as IPRV).maxOutputPSI}
        </p>
        <Select
          value={convertedName(prv)}
          fluid={true}
          onChange={doSetPRV}
          options={prvProducts.map((p) => ({
            text: convertedName(p),
            value: convertedName(p),
          }))}
        />
        <PanelInputWrap
          label={'Elevation'}
          type="number"
          onChange={changeElevation}
          value={prv.elevation || '0'}
        />
        <br />
        <Button onClick={cancelSave}>Cancel</Button>
        <Button primary onClick={handleSave}>
          Save
        </Button>
      </div>
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)``;
