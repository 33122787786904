import { ISleeve } from '@shared-types';
import { getState, setState } from '../../../state';
import { updatePaperItem } from '../../../state/renderers';

export const bulkChangeSleeves = (payload: ISleeve[]) => {
  const updatedItems = getState().items.map(
    (item) => payload.find((sleeve) => sleeve.uuid === item.uuid) || item,
  );
  setState({
    items: updatedItems,
  });
  payload.forEach((sleeve) => updatePaperItem(sleeve));
};
