import { DesignElement, IEdge, Zone } from '@shared-types';
import { mapEls } from '../caches/element.cache';
import { addUndo } from '../contexts/design/undo';
import { generateGraphs } from '../helpers/directedGraph';
import { hopPipes } from '../helpers/hopping';
import { addElementsEdges } from './addElementsEdges';
import { deleteCoverageCaches } from './coverage';
import { paperState } from './paper.state';
import { getState, setState } from './state-basics';

export const deleteElements = (payload: string[], undoable = true) => {
  const { elementCache, edges, elements, zones } = getState();
  if (undoable) {
    const oldEls = payload.map((id) => elementCache[id]);
    const oldEdges = edges.filter(
      (edge) => payload.includes(edge.source) || payload.includes(edge.target),
    );
    addUndo(() => {
      addElementsEdges(oldEls, oldEdges, false);
    }, 'Delete Elements');
  }

  let newElements = elements.filter(
    (element: DesignElement) => payload.indexOf(element.uuid) === -1,
  );
  const matchingEdges = edges.filter(
    (edge: IEdge) =>
      payload.includes(edge.source) || payload.includes(edge.target),
  );
  paperState.deleteItems([...payload, ...matchingEdges.map((e) => e.uuid)]);
  const updatedElementCache = mapEls(newElements);
  deleteCoverageCaches(payload, updatedElementCache);
  let newEdges = edges.filter(
    (edge: IEdge) =>
      !payload.includes(edge.source) && !payload.includes(edge.target),
  );
  const updatedZones = zones.map((zone: Zone) => ({
    ...zone,
    headIds: zone.headIds.filter((v: string) => !payload.includes(v)),
  }));
  const { masterGraph, pocGraphs } = generateGraphs(
    newEdges,
    newElements,
    updatedZones,
  );
  newEdges = hopPipes(
    zones,
    newEdges,
    newElements,
    elementCache,
    getState().scale,
    pocGraphs,
  );
  setState({
    elements: newElements,
    edges: newEdges,
    zones: updatedZones,
    masterGraph,
    pocGraphs,
    elementCache: updatedElementCache,
  });
  // deleteOrphanedFittings(); deleted because of colinear pipe cleanup
};
