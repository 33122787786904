import paper from 'src/paper';
import { IPoint, ISleeve, LineSegment } from '../../../../../../shared-types';
import { itemSizes } from '../../../../shared';
import { paperColors } from '../../../../vars';
import { getColor } from '../../paper-helpers/plot.helpers';
import { getState } from '../../state';
import { PaperItem } from '../../tools/paper-items/paper-item';
import { sleeveService } from './sleeve.service';

export const isPaperSleeve = (item: PaperItem<any>): item is PaperSleeve => {
  return item instanceof PaperSleeve;
};
export class PaperSleeve implements PaperItem<ISleeve> {
  private _group = new paper.Group();
  _item = sleeveService.createItem({});

  get highlight() {
    return this._group.getItem({ name: 'highlight' });
  }
  get group() {
    return this._group;
  }
  constructor(item: ISleeve) {
    this.group.name = item.itemType;
    this.group.data = { uuid: item.uuid };
    this.create(item);
    this._item = item;
  }
  create(item: ISleeve) {
    this.group.removeChildren();
    const lines = this.createSleeveLines(item);
    this.group.addChildren(lines);
    this._item = item;
  }
  createSleeveLines(item: ISleeve) {
    const pipeColor = getColor(paperColors.pipe);
    const scale = getState().scale;
    const subPaths: paper.Path[] = [];
    if (item.lines) {
      item.lines.forEach((line: LineSegment, i: number) => {
        const path = new paper.Path.Line(
          new paper.Point(line.start),
          new paper.Point(line.end),
        );
        path.dashArray = itemSizes.sleeveDashArray(scale);
        path.strokeWidth = itemSizes.sleeveDiameter / scale;
        path.strokeColor = pipeColor;
        path.name = `sleeve-line-${i}`;
        subPaths.push(path);
      });
    }
    return subPaths;
  }
  update(item: ISleeve) {
    const isSelected = !!this.highlight;
    if (this._item.width !== item.width) {
      this.group.removeChildren();
      this.create(item);
      if (isSelected) {
        this.toggleHighlight(true);
      }
    }
    this._item = item;
  }
  setSelectedStatus(selected: boolean) {
    if (this.highlight) {
      this.highlight.remove();
    }
    if (selected) {
      const highlight = this.group.clone();
      highlight.strokeWidth = 0.2;
      highlight.strokeColor = getColor('#94baf7');
      highlight.fillColor = null;
      highlight.name = 'highlight';
      highlight.locked = true;
      this.group.addChild(highlight);
    }
  }
  getItem() {
    return this.group;
  }
  setPosition(point: IPoint): void {
    this.group.position = new paper.Point(point);
  }
  setPivot(point: IPoint): void {
    this.group.pivot = new paper.Point(point);
  }
  toggleHighlight(show: boolean): void {
    this.setSelectedStatus(show);
  }
  destroy() {
    this.group.remove();
    this.highlight?.remove();
  }
}
