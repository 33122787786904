import { useAuth0 } from '@auth0/auth0-react';
import { UserUI } from '@shared-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  fetchProfile,
  getCards,
  getCustomer,
} from 'src/services/account.service';
import { getInvoices } from 'src/services/invoice.service';

export const ProfileContext = React.createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  user: null as UserUI | null,
  stripeInfo: {} as { customer?: any; cards?: any; invoices?: any },
  role: 'contractor',
  // loadCards: (): any => null,
  loadInvoices: (): any => null,
  // addCard: (customerId: string, tokenId: string): any => null,
  // deleteCard: (cardId: string): any => null,
  // updateCard: (cardId: string): any => null,
  /* eslint-enable @typescript-eslint/no-unused-vars */
});

export const ProfileConsumer = ProfileContext.Consumer;

export const ProfileProvider = (props: any) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [profileUser, setUser] = useState<UserUI | null>(null);
  const [role, setRole] = useState('');
  const [stripeInfo, setStripeInfo] = useState<any>({});

  const loadStripeInfo = async (): Promise<any> => {
    try {
      const token = await getAccessTokenSilently();
      localStorage.setItem('is.access_token', token || '');
      const { user } = await fetchProfile();
      setUser(user);
      if (user) {
        setRole(user.role);
        if (user.role === 'contractor') {
          const { customer } = await getCustomer(user.stripe_id);
          const { cards } = await getCards(user.stripe_id);
          const { invoices } = await getInvoices(user.stripe_id);
          setStripeInfo({ customer, cards, invoices });
        }
      }
    } catch (err) {
      console.log('error: ', err);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      loadStripeInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // const loadCards = async (): Promise<any> => {
  //   try {
  //     if (profileUser) {
  //       const { cards } = await getCards(profileUser.stripe_id);
  //       setStripeInfo({ ...stripeInfo, cards });
  //     }
  //   } catch (err) {
  //     console.log('error: ', err);
  //   }
  // };
  // const addCard = async (stripeId: string, tokenId: string) => {
  //   try {
  //     await attachCard(stripeId, tokenId);
  //     await loadCards();
  //   } catch (err) {
  //     console.log('error: ', err);
  //   }
  // };

  const loadInvoices = async (): Promise<any> => {
    try {
      if (profileUser) {
        const { invoices } = await getInvoices(profileUser.stripe_id);
        setStripeInfo({ ...stripeInfo, invoices });
      }
    } catch (err) {
      console.log('error: ', err);
    }
  };

  // const deleteCard = async (cardId: string): Promise<any> => {
  //   try {
  //     if (profileUser) {
  //       await removeCard(cardId, profileUser.stripe_id);
  //       loadCards();
  //     }
  //   } catch (err) {
  //     console.log('error: ', err);
  //   }
  // };

  // const updateCard = async (cardId: string): Promise<any> => {
  //   try {
  //     if (profileUser) {
  //       await defaultCard(cardId, profileUser.stripe_id);
  //       const { customer } = await getCustomer(profileUser.stripe_id);
  //       setStripeInfo({ ...stripeInfo, customer });
  //     }
  //   } catch (err) {
  //     console.log('error: ', err);
  //   }
  // };

  return (
    <ProfileContext.Provider
      value={{
        user: profileUser,
        // addCard,
        // updateCard,
        // deleteCard,
        // loadCards,
        loadInvoices,
        stripeInfo,
        role,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
};

export const withProfile = (Component: any): any => {
  return class X extends React.Component {
    render(): JSX.Element {
      return (
        <ProfileConsumer>
          {(profile: any): any => (
            <Component {...this.props} profile={profile} />
          )}
        </ProfileConsumer>
      );
    }
  };
};
