import styled from 'styled-components';
import { ValveBoxList } from '../features/valves/ValveBoxList';
import { ValveBoxPredictions } from '../features/valves/ValveBoxPredictions';
import { PanelWrap } from './PanelButton';

export const ValveBoxToolProperties = () => {
  return (
    <Wrap>
      <h5>Valve Box Tool</h5>
      <ValveBoxPredictions />
      <ValveBoxList />
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)``;
