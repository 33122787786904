import { HANDLE_TYPE, IPoint, Polyline, Vertex } from '@shared-types';
import * as math from 'mathjs';
import paper from 'src/paper';
import { getMidPoint, greens } from '../../../shared';
import { applyAlpha } from '../helpers';
import { localZoom } from '../localPaper';
import { getColor } from '../paper-helpers/plot.helpers';
import { getState } from '../state';
import { PaperItem } from '../tools/paper-items/paper-item';
import { polylineService } from './polyline.service';

export const isPaperPolyLine = (
  item: PaperItem<any>,
): item is PaperPolyline => {
  return item instanceof PaperPolyline;
};
export class PaperPolyline extends PaperItem<Polyline> {
  private _group = new paper.Group();
  private _item = polylineService.createItem();
  private _vertices: paper.Path.Circle[] = [];
  private vertexName: HANDLE_TYPE = 'handle.vertex';
  private _green = greens[math.randomInt(0, 121)];
  private _bisects: paper.Path.Circle[] = [];
  showBisects = false;

  get group() {
    return this._group;
  }
  get paperItem(): paper.Path {
    return this._group.getItem({ name: 'polyline' }) as paper.Path;
  }
  get highlight() {
    return this._group.getItem({ name: 'highlight' });
  }
  get vertices() {
    return this._vertices;
  }

  constructor(item: Polyline) {
    super();
    this.group.name = item.itemType;
    this.group.data = { uuid: item.uuid };
    this.create(item);
  }
  getRealItem(): Polyline {
    return this._item;
  }
  create(item: Polyline) {
    this.group.removeChildren();
    const p = this.createPolyPath(item);
    this.group.addChild(p);
    if (item.locked) {
      this.group.locked = true;
    }
    if (!item.visible) {
      this.group.visible = false;
    }
    this._item = item;
  }
  createPolyPath(item: Polyline) {
    const scale = getState().scale;
    const p = new paper.Path();
    item.vertices.forEach((v, i) => {
      if (v.through) {
        p.arcTo(new paper.Point(v.through), new paper.Point(v.position));
      } else {
        p.add(v.position);
      }
      if (i === item.vertices.length - 1 && item.closed) {
        p.closePath();
      }
    });
    const newColor = applyAlpha(item.style.strokeColor, item.style.strokeAlpha);
    p.strokeColor = newColor;
    p.strokeWidth = (item.style.strokeWidth || 1) / scale;
    p.opacity = item.style.opacity !== undefined ? item.style.opacity : 1;

    p.dashArray = (item.style.dashArray || []).map((d) => d / scale);
    p.dashOffset = (item.style.dashOffset || 0) / scale;
    p.strokeCap = item.style.strokeCap || 'butt';
    p.strokeJoin = item.style.strokeJoin || 'miter';
    const newStrokeColor = applyAlpha(
      item.style.strokeColor,
      item.style.strokeAlpha,
    );
    p.strokeColor = newStrokeColor;
    if (item.polyType === 'yard') {
      p.fillColor = getColor(this._green);
      p.opacity = 0.2;
      p.strokeWidth = 0;
    } else if (item.polyType === 'bed') {
      p.fillColor = getColor('brown');
      p.opacity = 0.2;
      p.closePath();
      p.strokeWidth = 0;
    }

    p.name = 'polyline';
    return p;
  }
  update(item: Polyline) {
    const selected = !!this.highlight;
    const p = this.createPolyPath(item);
    this.paperItem.replaceWith(p);
    if (item.locked !== this._item.locked) {
      this.group.locked = item.locked;
    }
    if (item.visible !== this._item.visible) {
      this.group.visible = item.visible;
    }
    this._item = item;
    if (item.vertices.length !== this._vertices.length) {
      this.createVertices();
    } else {
      this.updateVertexPositions(item);
    }
    this.updateBisects(item);
    this.toggleHighlight(selected);
  }
  updateBisects(item: Polyline) {
    this._bisects.forEach((b) => {
      b.remove();
    });
    this._bisects = [];
    // for all vertices that don't have a "through" point, get the bisect point
    // between itself and the previous vertex
    const bisectPoints: { point: IPoint; spliceIndex: number }[] = [];
    item.vertices.forEach((v, i) => {
      if (!v.through) {
        let prev = item.vertices[i - 1];
        if (i === 0 && item.closed) {
          prev = item.vertices[item.vertices.length - 1];
        }
        if (prev) {
          bisectPoints.push({
            point: getMidPoint(prev.position, v.position),
            spliceIndex: i,
          });
        }
      }
    });
    bisectPoints.forEach((b) => {
      const bp = new paper.Path.Circle({
        center: new paper.Point(b.point),
        radius: 8 / localZoom(),
        fillColor: 'purple',
      });
      bp.name = 'handle.bisect.polyline';
      bp.data = {
        polyID: this._item.uuid,
        spliceIndex: b.spliceIndex,
      };
      bp.visible = false;
      this._bisects.push(bp);
    });
  }
  updateVertexPositions(item: Polyline) {
    if (this._vertices.length !== item.vertices.length) {
      this._vertices.forEach((v) => {
        v.remove();
      });
      this._vertices = [];
    }
    item.vertices.forEach((v, i) => {
      if (v.through) {
        const tv = this._vertices.find(
          (vert) => vert.data.id === v.uuid && vert.data.type === 'through',
        );
        if (tv) {
          this.updateVertex(tv, v.through);
        }
        const tv2 = this._vertices.find(
          (vert) => vert.data.id === v.uuid && vert.data.type === 'vertex',
        );
        if (tv2) {
          this.updateVertex(tv2, v.position);
        }
      } else {
        const tv = this._vertices.find(
          (vert) => vert.data.id === v.uuid && vert.data.type === 'vertex',
        );
        if (tv) {
          this.updateVertex(tv, v.position);
        }
      }
    });
  }
  updateVertex(tv: paper.Path, newCenter: IPoint) {
    tv.pivot = tv.bounds.center;
    tv.position = new paper.Point(newCenter);
    tv.visible = true;
    tv.selected = false;
  }
  setSelectedStatus(selected: boolean) {
    if (this.highlight) {
      this.highlight.remove();
    }
    if (selected) {
      const highlight = this.paperItem.clone();
      highlight.strokeWidth = 0.2;
      highlight.dashArray = [];
      highlight.strokeColor = getColor('#94baf7');
      highlight.name = 'highlight';
      highlight.fillColor = null;
      highlight.locked = true;
      this.group.addChild(highlight);
    }
  }
  destroy() {
    this.group.remove();
    this._vertices.forEach((v) => {
      v.remove();
    });
  }
  getItem() {
    return this.group;
  }
  setPosition(point: IPoint): void {
    if (!this._item.locked) {
      this.group.position = new paper.Point(point);
      this._vertices.forEach((v) => {
        v.visible = false;
      });
    }
  }
  setPivot(point: IPoint): void {
    this.group.pivot = new paper.Point(point);
  }
  // getRotation(): number {
  //   return this._item.rotation;
  // }
  // scaleItem(pivot: IPoint, scale: number): void {
  //   this.group.scale(scale, pivot);
  // }
  destroyVertices() {
    this._vertices.forEach((v) => {
      v.remove();
    });
    this._vertices = [];
  }
  createVertexHandle = (v: Vertex, i: number) => {
    const b = new paper.Path.Circle({
      center: new paper.Point(v.position),
      radius: 8 / localZoom(),
      fillColor: 'blue',
      opacity: 0.6,
      data: {
        type: 'vertex',
        id: v.uuid,
        i,
        polyID: this._item.uuid,
      },
    });
    b.name = this.vertexName;
    return b;
  };
  createThroughHandle = (center: IPoint, v: Vertex, i: number) => {
    const b = new paper.Path.Circle({
      center: new paper.Point(center),
      radius: 8 / localZoom(),
      fillColor: 'red',
      opacity: 0.6,
      data: {
        type: 'through',
        id: v.uuid,
        i,
        polyID: this._item.uuid,
      },
    });
    b.name = this.vertexName;
    return b;
  };
  createVertices() {
    this.destroyVertices();
    this._item.vertices.forEach((v, i) => {
      if (v.through) {
        const a = this.createThroughHandle(v.through, v, i);
        const b = this.createVertexHandle(v, i);
        this._vertices.push(a, b);
      } else {
        const b = this.createVertexHandle(v, i);
        this._vertices.push(b);
      }
    });
  }
  toggleHighlight(show: boolean): void {
    this.setSelectedStatus(show);
    if (show) {
      this.createVertices();
    } else {
      this.destroyVertices();
    }
  }
  activateYard(activate: boolean) {
    if (activate) {
      this.paperItem.fillColor = getColor('#aaa');
      this.paperItem.opacity = 0.5;
    } else {
      this.paperItem.fillColor = getColor(this._green);
      this.paperItem.opacity = 0.1;
    }
  }
  showAllBisects(show = false) {
    this._bisects.forEach((b) => {
      b.visible = show;
    });
  }
  toggleBisects() {
    this.showAllBisects(!this.showBisects);
    this.showBisects = !this.showBisects;
  }
}
