import { DesignElement, IValveProduct } from '@shared-types';
import { useEffect, useState } from 'react';
import { Button, Divider, Form, Input, Modal, Radio } from 'semantic-ui-react';
import { uniq } from 'underscore';
import { PanelButton } from '../../components/PanelButton';
import {
  getLossFromFlow,
  reduceHeadsGPMbyId,
  reducePlantsGPHbyId,
  valveGPM,
} from '../../helpers/directedGraph';
import { isPlant } from '../../plants/plants';
import { useDesignStore } from '../../state';
import { changeElements } from '../../state/changeElements';

export const ZoneValveInfo = () => {
  const currentZone = useDesignStore((state) =>
    state.zones.find((zone) => zone.uuid === state.currentZone),
  );
  const groups = useDesignStore((state) => state.groups);
  const plants = useDesignStore((state) => state.items.filter(isPlant));
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const valveProducts = useDesignStore((state) => state.valveProducts);
  const elementCache = useDesignStore((state) => state.elementCache);
  const [valveElement, setValve] = useState<DesignElement | null>(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [hideZeroLoss, setHideZeroLoss] = useState(true);

  useEffect(() => {
    if (currentZone) {
      const valve = elementCache[currentZone.valve];
      if (valve) {
        setValve(valve);
      }
    }
  }, [currentZone, elementCache]);

  const valvePossibilities = (): {
    loss1;
    loss2;
    plantGPM;
    headGPM;
    pipedGPM;
    name;
  }[] => {
    if (currentZone) {
      let valves = uniq(
        valveProducts
          .filter((v) =>
            `${v.brand} ${v.name}`.toLowerCase().includes(filter.toLowerCase()),
          )
          .map((v) => `${v.brand} ${v.name}`),
      );
      valves.sort((a, b) => a.localeCompare(b));
      let newValves = valves
        .map((name) => {
          const pipedGPM = valveGPM(
            currentZone,
            pipeProducts,
            groups,
            pocGraphs,
          );
          const headGPM =
            currentZone.headIds.length > 0
              ? reduceHeadsGPMbyId(currentZone.headIds, elementCache)
              : 0;
          const plantGPM =
            currentZone.isDrip || currentZone.plantIds.length
              ? reducePlantsGPHbyId(plants, currentZone.plantIds) / 60
              : 0;
          const perfData = valveProducts.filter(
            (v) => `${v.brand} ${v.name}` === name,
          );
          const loss1 = getLossFromFlow(perfData, headGPM || plantGPM);
          const loss2 = getLossFromFlow(perfData, pipedGPM);
          return { loss1, loss2, plantGPM, headGPM, pipedGPM, name };
        })
        .filter((v) => (hideZeroLoss ? v.loss1 > 0 || v.loss2 > 0 : true));
      return newValves;
    } else {
      return [];
    }
  };

  const doValveChange = (_, data) => {
    const newValve = valveProducts.find(
      (v) => `${v.brand} ${v.name}` === data.value,
    );
    if (valveElement && newValve) {
      const newEl = { ...valveElement, props: newValve };
      changeElements([newEl]);
      setValve(newEl);
    }
  };
  return (
    <Modal
      size="small"
      open={open}
      trigger={
        <PanelButton onClick={() => setOpen(true)}>Change Valve</PanelButton>
      }
    >
      <Modal.Header>Change Valve for Zone</Modal.Header>
      <Modal.Content>
        {currentZone && open && (
          <Form>
            <label>Filter Valves</label>
            <Input
              value={filter}
              onChange={(_, d) => setFilter(d.value)}
            />{' '}
            <Button onClick={() => setFilter('')}>clear</Button>
            <Divider />
            Current:
            <strong>
              {valveElement &&
                `${(valveElement.props as IValveProduct).brand} ${
                  (valveElement.props as IValveProduct).name
                }`}
            </strong>
            <Divider />
            Showing {valvePossibilities().length} options{' '}
            <Button size="mini" onClick={() => setHideZeroLoss(!hideZeroLoss)}>
              {hideZeroLoss ? 'Show' : 'Hide'} Zero Loss Valves
            </Button>
            <Divider />
            {valveElement !== null && (
              <div style={{ height: '200px', overflow: 'auto' }}>
                {valvePossibilities().map(
                  ({ loss1, loss2, headGPM, name, plantGPM, pipedGPM }, i) => {
                    return (
                      <Radio
                        key={i}
                        name="valve-model"
                        checked={
                          `${(valveElement.props as IValveProduct).brand} ${
                            (valveElement.props as IValveProduct).name
                          }` === name
                        }
                        label={`${name} : (${loss1.toFixed(2)} loss at ${(
                          headGPM || plantGPM
                        ).toFixed(2)} GPM / ${loss2.toFixed(
                          2,
                        )} loss at ${pipedGPM.toFixed(2)} GPM piped)`}
                        value={name}
                        onChange={doValveChange}
                      />
                    );
                  },
                )}
              </div>
            )}
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => setOpen(false)}>
          Done
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
