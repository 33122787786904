import {
  IDesignState,
  IMiscItemBase,
  PlantBase,
} from '../../../shared-types/workbench-types';
import { LAYER_NAMES } from '../pages/Workbench/shared/workbench-enums';

// If this cannot be reached, pipes will be sized up as much as possible
export const MININUM_RESIDUAL_PRESSURE = 2.5;

export const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const NOTIFICATION_FADE_MS = 500;
export const NOTIFICATION_DURATION_MS = 6000;
export enum PlantType {
  PERENNIAL = 'Perennial (1 gph)',
  EVERGREEN_SHRUB = 'Evergreen Shrub (2 gph)',
  DECIDUOUS_SHRUB = 'Deciduous Shrub (2 gph)',
  EVERGREEN_TREE = 'Evergreen Tree (3 gph)',
  DECIDUOUS_TREE = 'Deciduous Tree (5 gph)',
}
export const rockSVG =
  '<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" width="12.641" height="15.487" viewBox="0 0 12.641 15.487"> <path d="M5.386.295c-1.662.554-3.167.167-3.583,1.917s-.5,3.167-1.083,4.417-.333,3-.333,4.083-.5,1.667.417,2.417,1.75,1.667,2.417,1.583,1.5.5,2.5.5,2.667.25,3.5-.833,1.583-1.167,1.75-2.167-.083-3.417.333-4.5,1.083-1.5,1.083-2.917.083-2.25-.417-2.833-2.417-1.167-3.25-1.333-2.833-.5-3.333-.333Z" style="fill: none; stroke: currentColor; stroke-miterlimit: 10; stroke-width: .5px;" /> <path d="M4.469,2.295c-.411.575-1.417.583-1.5,2s.25,1.333.083,2.583-.417,1.417-.333,2.5-.667,1.333.5,2.167,1.667.917,2.25,1.25,1.667.417,2.25-.083,1.583-1.583,1.75-2.75-.417-1.167.25-2.333,1.083-1.75,1.167-2.833.083-.917-.667-1.583-1.333-.917-2.167-.917-3.167-.583-3.583,0Z" style="fill: none; stroke: currentColor; stroke-miterlimit: 10; stroke-width: .25px;" /> <path d="M5.719,3.462c-.854,1.067-1.083.667-1.167,2.083s-.75.833-.083,1.917.75,1.5,1.833,1.333,1.5-.167,1.917-.917,1.25-.75,1-2.417.25-1.667-.75-1.917-2.417-.5-2.75-.083Z" style="fill: none; stroke: currentColor; stroke-miterlimit: 10; stroke-width: .25px;" /> </svg>';

export const paverSVG =
  '<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" width="6.277" height="6.292" viewBox="0 0 6.277 6.292"> <polygon points=".152 3.949 1.152 1.222 3.652 .131 6.152 .676 6.152 3.403 3.652 6.131 .152 3.949" style="fill: none; stroke: #000; stroke-miterlimit: 10; stroke-width: .25px;" /> </svg>';

export const plantSVGS = {
  [PlantType.DECIDUOUS_TREE]:
    '<svg xmlns="http://www.w3.org/2000/svg" stroke="black" fill="none" width="100" height="99.2779" viewBox="0 0 100 99.2779"><title>Asset 13</title><g id="Layer_2" data-name="Layer 2"><g id="Elements"><path d="M26.9841,7.7731,23.9225,8.44,22.51,7.5767l-3.2843,2.708v1.4468l-4.99,1.4674v2.0545l-2.788,1.6148-1.0532,6.11L7.337,24.6464,6.31,26.9947,4.1089,28.0214l-.294,7.0448H1.7612L.9507,38.819H5.2155L6.434,43.6933,5.2155,47.6559,2.4941,49.0084.4651,46.9147,0,49.0084l1.2553,2.5472L0,54.509l2.2145,5.9056.8688,1.2515S1.8645,64.56,2.3214,64.7122s1.2669,3.7492,1.2669,3.7492L3.54,71.4137,8.1812,73.29l1.2229,5.2063,1.7517,2.1317h2.3363l1.0144-2.5126,2.7415-1.3711,4.084.9819,2.0846,2.5214.6855,5.1785L21.6853,88.718l2.1122.8973.0464,1.4968s3.3366,2.5127,3.14,2.7678,1.2566-.3732,1.2566-.3732l4.01.4346V95.86h4.0363l1.1422,1.6751h8.3771s1.5344.7484,1.5234.99,1.98.5164,1.98.5164l1.7515-1.8869,5.1026,2.123,4.6981-.2361V97.1549l2.709-.8607,4.4734-.13,1.3705-1.9035h1.5994L75.19,90.68l4.3538-.1511.9138-3.1224,2.3091-1.4761-2.8271-1.61-1.2164-3.14,2.0019-4.1616,3.3879-.96,2.3828,3.7471,2.3389-.6247,1.351-4.3213,5.81-5.5351-.5494-4.279L96.8992,63.32l.3886-3.5574,1.6757-1.6756-.5329-8.5653L100,46.3586l-2.1591-3.727V38.9808l-2-1.6849.0763-4.1125-1.4139-2.7993.5-2.9121-2.8549-4.076-3.1008,2.2772-3.376.3535L83.6694,23.71l1.4133-3.9254,2.5513-1.06L84.9652,14.25l-7.0659-2.3549L75.3551,7.5767l-2.52-.3536L69.7339,4.24,62.04,3.18,58.8292,0H52.3833l.43,6.148L48.4187,8.99l-7.7146-.9381L38.3432,1.1978l-2.801,1.3546H30.1251Z"/></g></g></svg>',
  [PlantType.EVERGREEN_TREE]:
    '<svg xmlns="http://www.w3.org/2000/svg" stroke="black" width="100" height="95.4624" viewBox="0 0 100 95.4624" fill="none"><title>Asset 10</title><g id="Layer_2" data-name="Layer 2"><g id="Elements"><path d="M10.0813,17.565C21.4785,38.66,0,46.8313,0,46.8313,22.0285,57.34,9.0724,76.417,9.0724,76.417,32.9285,72.2377,33.8261,94.84,33.8261,94.84c16.6908-17.4046,30.9371.6227,30.9371.6227,3.3429-24.0633,25.5716-17.8649,25.5716-17.8649C79.3807,55.9815,100,48.3105,100,48.3105c-21.3488-9.2684-9.2121-29.5464-9.2121-29.5464C67.1784,23.7085,65.9737.1218,65.9737.1218,58.5663,7.3006,49.0186,13.8766,35.4869,0,35.4869,0,34.3647,22.3392,10.0813,17.565Z"/></g></g></svg>',
  [PlantType.DECIDUOUS_SHRUB]:
    '<svg xmlns="http://www.w3.org/2000/svg" stroke="black" fill="none" width="100" height="99.2779" viewBox="0 0 100 99.2779"><title>Asset 13</title><g id="Layer_2" data-name="Layer 2"><g id="Elements"><path d="M26.9841,7.7731,23.9225,8.44,22.51,7.5767l-3.2843,2.708v1.4468l-4.99,1.4674v2.0545l-2.788,1.6148-1.0532,6.11L7.337,24.6464,6.31,26.9947,4.1089,28.0214l-.294,7.0448H1.7612L.9507,38.819H5.2155L6.434,43.6933,5.2155,47.6559,2.4941,49.0084.4651,46.9147,0,49.0084l1.2553,2.5472L0,54.509l2.2145,5.9056.8688,1.2515S1.8645,64.56,2.3214,64.7122s1.2669,3.7492,1.2669,3.7492L3.54,71.4137,8.1812,73.29l1.2229,5.2063,1.7517,2.1317h2.3363l1.0144-2.5126,2.7415-1.3711,4.084.9819,2.0846,2.5214.6855,5.1785L21.6853,88.718l2.1122.8973.0464,1.4968s3.3366,2.5127,3.14,2.7678,1.2566-.3732,1.2566-.3732l4.01.4346V95.86h4.0363l1.1422,1.6751h8.3771s1.5344.7484,1.5234.99,1.98.5164,1.98.5164l1.7515-1.8869,5.1026,2.123,4.6981-.2361V97.1549l2.709-.8607,4.4734-.13,1.3705-1.9035h1.5994L75.19,90.68l4.3538-.1511.9138-3.1224,2.3091-1.4761-2.8271-1.61-1.2164-3.14,2.0019-4.1616,3.3879-.96,2.3828,3.7471,2.3389-.6247,1.351-4.3213,5.81-5.5351-.5494-4.279L96.8992,63.32l.3886-3.5574,1.6757-1.6756-.5329-8.5653L100,46.3586l-2.1591-3.727V38.9808l-2-1.6849.0763-4.1125-1.4139-2.7993.5-2.9121-2.8549-4.076-3.1008,2.2772-3.376.3535L83.6694,23.71l1.4133-3.9254,2.5513-1.06L84.9652,14.25l-7.0659-2.3549L75.3551,7.5767l-2.52-.3536L69.7339,4.24,62.04,3.18,58.8292,0H52.3833l.43,6.148L48.4187,8.99l-7.7146-.9381L38.3432,1.1978l-2.801,1.3546H30.1251Z"/></g></g></svg>',
  [PlantType.EVERGREEN_SHRUB]:
    '<svg xmlns="http://www.w3.org/2000/svg" stroke="black" width="100" height="95.4624" viewBox="0 0 100 95.4624" fill="none"><title>Asset 10</title><g id="Layer_2" data-name="Layer 2"><g id="Elements"><path d="M10.0813,17.565C21.4785,38.66,0,46.8313,0,46.8313,22.0285,57.34,9.0724,76.417,9.0724,76.417,32.9285,72.2377,33.8261,94.84,33.8261,94.84c16.6908-17.4046,30.9371.6227,30.9371.6227,3.3429-24.0633,25.5716-17.8649,25.5716-17.8649C79.3807,55.9815,100,48.3105,100,48.3105c-21.3488-9.2684-9.2121-29.5464-9.2121-29.5464C67.1784,23.7085,65.9737.1218,65.9737.1218,58.5663,7.3006,49.0186,13.8766,35.4869,0,35.4869,0,34.3647,22.3392,10.0813,17.565Z"/></g></g></svg>',
  [PlantType.PERENNIAL]:
    '<svg xmlns="http://www.w3.org/2000/svg" stroke="black" fill="none" width="100" height="100.003" viewBox="0 0 100 100.003"><title>Asset 14</title><g id="Layer_2" data-name="Layer 2"><g id="Elements"><path d="M100,50.003A50,50,0,1,1,50,0,49.9939,49.9939,0,0,1,100,50.003Z"/></g></g></svg>',
};

export const plantOptions: PlantBase[] = [
  {
    name: PlantType.DECIDUOUS_TREE,
    iconSVG: plantSVGS[PlantType.DECIDUOUS_TREE],
    gpm: 0.083333,
    gph: 5,
    defaultWidth: 15,
  },
  {
    name: PlantType.EVERGREEN_TREE,
    iconSVG: plantSVGS[PlantType.EVERGREEN_TREE],
    gpm: 0.05,
    gph: 3,
    defaultWidth: 15,
  },
  {
    name: PlantType.DECIDUOUS_SHRUB,
    iconSVG: plantSVGS[PlantType.DECIDUOUS_SHRUB],
    gpm: 0.033333,
    gph: 2,
    defaultWidth: 3,
  },
  {
    name: PlantType.EVERGREEN_SHRUB,
    iconSVG: plantSVGS[PlantType.EVERGREEN_SHRUB],
    gpm: 0.033333,
    gph: 2,
    defaultWidth: 3,
  },
  {
    name: PlantType.PERENNIAL,
    iconSVG: plantSVGS[PlantType.PERENNIAL],
    gpm: 0.01666666667,
    gph: 1,
    defaultWidth: 2.5,
  },
];
export const sleeveDiameters = [2, 4, 6, 8, 10, 12, 16];

export const itemSizes = {
  valveDiameter: 10,
  valveTextFont: 8,
  genericOutline: 1,
  dripDot: 0.9,
  font: 11,
  treeText: 7,
  treeDot: 2,
  legendFont: 10,
  headSize: 7,
  headClue: 9,
  zoneDot: 8,
  edgeFont: 7,
  pipeSize: 2,
  sleeveDiameter: 1,
  quickCouplerSize: 6,
  smallArcStroke: {
    color: '#333333',
    width: 1,
  },
  mainDashArray: (scale: number) => [8 / scale, 8 / scale],
  dripDashArray: (scale: number) => [1 / scale, 3 / scale],
  sleeveDashArray: (scale: number) => [4 / scale, 4 / scale],
};

export const meterSizes = [
  {
    size: 0.625,
    display: '5/8"',
    safeFlow: 15,
    losses: [
      { flow: 1, loss: 0.2 },
      { flow: 2, loss: 0.3 },
      { flow: 3, loss: 0.4 },
      { flow: 4, loss: 0.6 },
      { flow: 5, loss: 0.9 },
      { flow: 6, loss: 1.3 },
      { flow: 7, loss: 1.8 },
      { flow: 8, loss: 2.3 },
      { flow: 9, loss: 3 },
      { flow: 10, loss: 3.7 },
      { flow: 11, loss: 4.4 },
      { flow: 12, loss: 5.1 },
      { flow: 13, loss: 6.1 },
      { flow: 14, loss: 7.2 },
      { flow: 15, loss: 8.3 },
      { flow: 16, loss: 9.4 },
      { flow: 17, loss: 10.7 },
      { flow: 18, loss: 12 },
      { flow: 19, loss: 13.4 },
      { flow: 20, loss: 15 },
    ],
  },
  {
    size: 0.75,
    display: '3/4"',
    safeFlow: 22,
    losses: [
      { flow: 1, loss: 0.1 },
      { flow: 2, loss: 0.2 },
      { flow: 3, loss: 0.3 },
      { flow: 4, loss: 0.5 },
      { flow: 5, loss: 0.6 },
      { flow: 6, loss: 0.7 },
      { flow: 7, loss: 0.8 },
      { flow: 8, loss: 1 },
      { flow: 9, loss: 1.3 },
      { flow: 10, loss: 1.6 },
      { flow: 11, loss: 1.9 },
      { flow: 12, loss: 2.2 },
      { flow: 13, loss: 2.6 },
      { flow: 14, loss: 3.1 },
      { flow: 15, loss: 3.6 },
      { flow: 16, loss: 4.1 },
      { flow: 17, loss: 4.6 },
      { flow: 18, loss: 5.2 },
      { flow: 19, loss: 5.8 },
      { flow: 20, loss: 6.5 },
      { flow: 22, loss: 7.9 },
      { flow: 24, loss: 9.5 },
      { flow: 26, loss: 11.2 },
      { flow: 28, loss: 13 },
      { flow: 30, loss: 15 },
    ],
  },
  {
    size: 1,
    display: '1"',
    safeFlow: 36,
    losses: [
      { flow: 4, loss: 0.1 },
      { flow: 5, loss: 0.2 },
      { flow: 6, loss: 0.3 },
      { flow: 7, loss: 0.4 },
      { flow: 8, loss: 0.5 },
      { flow: 9, loss: 0.6 },
      { flow: 10, loss: 0.7 },
      { flow: 11, loss: 0.8 },
      { flow: 12, loss: 0.9 },
      { flow: 13, loss: 1 },
      { flow: 14, loss: 1.1 },
      { flow: 15, loss: 1.2 },
      { flow: 16, loss: 1.4 },
      { flow: 17, loss: 1.6 },
      { flow: 18, loss: 1.8 },
      { flow: 19, loss: 2 },
      { flow: 20, loss: 2.2 },
      { flow: 22, loss: 2.8 },
      { flow: 24, loss: 3.4 },
      { flow: 26, loss: 4 },
      { flow: 28, loss: 4.6 },
      { flow: 30, loss: 5.3 },
      { flow: 32, loss: 6 },
      { flow: 34, loss: 6.9 },
      { flow: 36, loss: 7.8 },
      { flow: 38, loss: 8.7 },
      { flow: 40, loss: 9.6 },
      { flow: 42, loss: 10.6 },
      { flow: 44, loss: 11.7 },
      { flow: 46, loss: 12.8 },
      { flow: 48, loss: 13.9 },
      { flow: 50, loss: 15 },
    ],
  },
  {
    size: 1.5,
    display: '1 1/2"',
    safeFlow: 75,
    losses: [
      { flow: 16, loss: 0.4 },
      { flow: 17, loss: 0.5 },
      { flow: 18, loss: 0.6 },
      { flow: 19, loss: 0.7 },
      { flow: 20, loss: 0.8 },
      { flow: 22, loss: 1 },
      { flow: 24, loss: 1.2 },
      { flow: 26, loss: 1.4 },
      { flow: 28, loss: 1.6 },
      { flow: 30, loss: 1.8 },
      { flow: 32, loss: 2.1 },
      { flow: 34, loss: 2.4 },
      { flow: 36, loss: 2.7 },
      { flow: 38, loss: 3 },
      { flow: 40, loss: 3.3 },
      { flow: 42, loss: 3.6 },
      { flow: 44, loss: 3.9 },
      { flow: 46, loss: 4.2 },
      { flow: 48, loss: 4.5 },
      { flow: 50, loss: 4.9 },
      { flow: 52, loss: 5.3 },
      { flow: 54, loss: 5.7 },
      { flow: 56, loss: 6.2 },
      { flow: 58, loss: 6.7 },
      { flow: 60, loss: 7.2 },
      { flow: 65, loss: 8.3 },
      { flow: 70, loss: 9.8 },
      { flow: 75, loss: 11.2 },
      { flow: 80, loss: 12.8 },
      { flow: 90, loss: 16.1 },
      { flow: 100, loss: 20 },
    ],
  },
  {
    size: 2,
    display: '2"',
    safeFlow: 120,
    losses: [
      { flow: 32, loss: 0.8 },
      { flow: 34, loss: 0.9 },
      { flow: 36, loss: 1 },
      { flow: 38, loss: 1.2 },
      { flow: 40, loss: 1.3 },
      { flow: 42, loss: 1.4 },
      { flow: 44, loss: 1.5 },
      { flow: 46, loss: 1.6 },
      { flow: 48, loss: 1.7 },
      { flow: 50, loss: 1.9 },
      { flow: 52, loss: 2.1 },
      { flow: 54, loss: 2.2 },
      { flow: 56, loss: 2.3 },
      { flow: 58, loss: 2.5 },
      { flow: 60, loss: 2.7 },
      { flow: 65, loss: 3.2 },
      { flow: 70, loss: 3.7 },
      { flow: 75, loss: 4.3 },
      { flow: 80, loss: 4.9 },
      { flow: 90, loss: 6.2 },
      { flow: 100, loss: 7.8 },
      { flow: 110, loss: 9.5 },
      { flow: 120, loss: 11.3 },
      { flow: 130, loss: 13 },
      { flow: 140, loss: 15.1 },
      { flow: 150, loss: 17.3 },
      { flow: 160, loss: 20 },
    ],
  },
];
export const itemOptions: IMiscItemBase[] = [
  {
    name: 'Booster Pump',
  },
  {
    name: 'Controller',
  },
  {
    name: 'Flow Sensor',
  },
  {
    name: 'Dosatron',
  },
  {
    name: 'Rain Sensor',
  },
  {
    name: 'Reverse Osmosis',
  },
  {
    name: 'Backflow',
  },
  {
    name: 'Filter',
  },
  {
    name: 'Drip Transition',
  },
  {
    name: 'Gate Valve',
  },
  {
    name: 'Quick Coupler',
  },
  {
    name: 'Master Valve',
  },
  {
    name: 'Meter',
  },
  {
    name: 'Pump',
  },
  {
    name: 'Pressure Regulating Valve',
  },
  {
    name: 'Point of Connection',
  },
  {
    name: 'Water tank',
  },
  {
    name: 'Winterization Location',
  },
  {
    name: 'Backflow Enclosure',
  },
  {
    name: 'Drain Valve',
  },
  {
    name: 'Air Relief Valve',
  },
  {
    name: 'Hose Bib',
  },
];

export const greens = [
  '#78AB46',
  '#4A7023',
  '#629632',
  '#458B00',
  '#7F9A65',
  '#9CBA7F',
  '#659D32',
  '#7F8778',
  '#586949',
  '#608341',
  '#BCED91',
  '#488214',
  '#6A8455',
  '#577A3A',
  '#397D02',
  '#748269',
  '#567E3A',
  '#9DB68C',
  '#C0D9AF',
  '#A6D785',
  '#61B329',
  '#77896C',
  '#687E5A',
  '#3F602B',
  '#3F6826',
  '#8AA37B',
  '#8FA880',
  '#646F5E',
  '#476A34',
  '#435D36',
  '#93DB70',
  '#3B5E2B',
  '#84BE6A',
  '#3A6629',
  '#308014',
  '#9CA998',
  '#55AE3A',
  '#7BBF6A',
  '#C5E3BF',
  '#395D33',
  '#596C56',
  '#A9C9A4',
  '#86C67C',
  '#7BCC70',
  '#8CDD81',
  '#699864',
  '#3D8B37',
  '#5F755E',
  '#B7C8B6',
  '#63AB62',
  '#838B83',
  '#698B69',
  '#2F4F2F',
  '#426F42',
  '#548B54',
  '#8FBC8F',
  '#9BCD9B',
  '#71C671',
  '#7CCD7C',
  '#215E21',
  '#228B22',
  '#B4EEB4',
  '#90EE90',
  '#008B00',
  '#009900',
  '#C1FFC1',
  '#3D9140',
  '#B2D0B4',
  '#4D6B50',
  '#5B9C64',
  '#3F9E4D',
  '#517B58',
  '#BDFCC9',
  '#3D5B43',
  '#31B94D',
  '#96C8A2',
  '#3EA055',
  '#487153',
  '#00611C',
  '#688571',
  '#91B49C',
  '#79A888',
  '#B4D7BF',
  '#337147',
  '#37BC61',
  '#70DB93',
  '#40664D',
  '#92CCA6',
  '#329555',
  '#0E8C3A',
  '#78A489',
  '#3E6B4F',
  '#2C5D3F',
  '#759B84',
  '#2E6444',
  '#2E8B57',
  '#00C957',
  '#34925E',
  '#3CB371',
  '#43CD80',
  '#426352',
  '#607C6E',
  '#006633',
  '#008B45',
  '#00CD66',
];

export const LATEST_VERSION = 1;

export const designDefault: IDesignState = {
  activeBaseKey: '',
  elements: [],
  waterSource: '',
  backflow: '',
  beds: [],
  defaultDripValve: '',
  defaultTurfValve: '',
  dripPipeType: '',
  lateralPipeMinSize: 0.75,
  lateralPipeSizes: [],
  mainPipeType: '',
  mainPipeMinSize: 0.75,
  mainPipeSizes: [],
  activeBaseRadius: 0,
  activeNozzles: [],
  activePlant: plantOptions[0],
  activeMiscItem: itemOptions[0],
  mainPipe: '',
  lateralPipe: '',
  dripPipe: '',
  dripPipeMinSize: 0.75,
  dripPipeSizes: [],
  activeTool: 'Arrow',
  lateralValve: null,
  dripValve: null,
  activeYardIndex: 0,
  currentZone: '',
  edges: [],
  GPM: 999,
  pipes: [],
  legendPosition: { x: 50, y: 0 },
  resolution: 5,
  scale: 3.6,
  score: 0,
  selectedItems: [],
  printPreview: false,
  pumps: [],
  sprinklerBases: [],
  svg: '',
  svgURL: '',
  svgScale: 1,
  title: '',
  clientAddress: '',
  contractorName: '',
  contractorAddress: '',
  contractorLogoURL: '',
  usableGPMPercentage: 80,
  valveBoxes: [],
  yards: [],
  zones: [],
  zoomLevel: 1,
  center: { x: 0, y: 0 },

  pipeProducts: [],
  valveProducts: [],
  flowKits: [],
  backflowProducts: [],
  prvProducts: [],

  zoneNumberOffset: 0,

  northRotation: 0,
  headSize: 5,
  headZoneDotSize: 6,
  headClueSize: 7,

  lastSave: '',
  lassoMode: 'Heads',
  pipeAlgoVersion: '1',
  groups: [],
  activity: [],
  notification: null,
  measure: 0,
  ortho: true,
  pipeSnap: true,
  elementCache: {},
  masterGraph: undefined,
  pocGraphs: [],
  loading: false,
  currentElevation: 0,
  version: LATEST_VERSION,
  selectedIDs: [],

  items: [],
  plants: [],
  sleeves: [],
  texts: [],
  basemaps: [],
  sheets: [],

  designMode: 'basemap',
  isMouse: true,
  strokeWidth: 1,
  activeCustomSymbol: 'rock',
  visibleLayers: [
    LAYER_NAMES.DEFAULT,
    LAYER_NAMES.ELEMENTS,
    LAYER_NAMES.EDGES,
    LAYER_NAMES.PLANTS,
    LAYER_NAMES.VALVE_BOXES,
    LAYER_NAMES.HEADS,
    LAYER_NAMES.TRACING,
    LAYER_NAMES.BASEMAPS,
  ],
  lockedLayers: [],
  showArcs: false,
  showCoverage: false,
  showAllCoverage: false,
  showGPM: false,
  showPipeLabels: false,
  showAngles: false,
  showPlantOutlines: true,
  showSheetPreviews: false,
  showValveInfoBoxNumbers: false,
  showHeadPreviews: false,
  showValveBoxPreviews: false,
};

export const polyHintPurple = '#c321cf';
