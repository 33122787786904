import { ISleeve } from '@shared-types';
import { takeUntil } from 'rxjs';
import { defaultItem, paperItemStore } from '../../helpers';
import { activateNamedLayer } from '../../paper-helpers/plot.helpers';
import { ItemService } from '../../polyline/ItemService';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { toolService } from '../../tool.service';
import { drawSleeve$ } from './draw-sleeve';
import { PaperSleeve } from './paper-sleeve';

export class SleeveService implements ItemService<ISleeve> {
  init() {
    drawSleeve$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
  }
  createItem = (sleeve: Partial<ISleeve>): ISleeve => {
    const point = sleeve.position || { x: 0, y: 0 };
    return {
      ...defaultItem(point),
      itemType: 'sleeve',
      pipe: {
        start: point,
        end: point,
        pipe: 'test',
      },
      width: 0,
      diameter: 0,
      lines: [],
      totalPipeDiameter: 0,
      user: false,
      ...sleeve,
    };
  };
  renderItemToPaper = (item: ISleeve, layer = LAYER_NAMES.SLEEVES) => {
    activateNamedLayer(layer);
    paperItemStore.set(item.uuid, new PaperSleeve(item));
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  };
  registerWithState = () => {};
}

export const sleeveService = new SleeveService();
