import {
  DesignElement,
  DesignElementMap,
  IEdge,
  IPipeProduct,
} from '@shared-types';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  Button,
  Checkbox,
  DropdownProps,
  Icon,
  Modal,
  Table,
} from 'semantic-ui-react';
import { getEuclideanDistance, getMidPoint, traversePoly } from 'src/shared';
import styled from 'styled-components';
import { newUUID } from '../crypto-uuid';
import { NozzleOptions } from '../features/heads/NozzleOptions';
import { defaultItem } from '../helpers';
import {
  calcFrictionLoss,
  calculateVelocity,
} from '../helpers/geometry.helpers';
import { bisectPipe } from '../paper-helpers/edges';
import {
  clearSelectedItems,
  getState,
  pocGraphByNode,
  setActiveBaseKey,
  setActiveBaseRadius,
  useDesignStore,
} from '../state';
import { addElementsEdges } from '../state/addElementsEdges';
import { changeEdges } from '../state/changeEdges';
import { deleteEdge } from '../state/deleteEdge';
import { createSprinkler } from '../tools/sprinkler';
import { PanelButton, PanelSelect, PanelWrap } from './PanelButton';
interface Props {
  uuid: string;
}

export const PipeProperties = ({ uuid }: Props) => {
  const edge = useDesignStore((state) =>
    state.edges.find((e) => e.uuid === uuid),
  );
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const activeBaseKey = useDesignStore((state) => state.activeBaseKey);
  const activeBaseRadius = useDesignStore((state) => state.activeBaseRadius);
  const elementCache = useDesignStore((state) => state.elementCache);
  const groups = useDesignStore((state) => state.groups);
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  const [currentEdge, setCurrentEdge] = useState<IEdge | undefined>();
  const [currentPipe, setCurrentPipe] = useState<IPipeProduct | undefined>();
  const [start, setStart] = useState<DesignElement | undefined>();
  const [end, setEnd] = useState<DesignElement | undefined>();
  const [showFill, setShowFill] = useState(false);
  const [fillOffset, setFillOffset] = useState(0);

  useEffect(
    () => {
      if (edge && pipeProducts) {
        setCurrentEdge({ ...edge });
        setCurrentPipe(pipeProducts.find((p) => p.uuid === edge.pipe));
        const start = elementCache[edge.source];
        const end = elementCache[edge.target];
        setStart(start);
        setEnd(end);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [edge, pipeProducts, elementCache],
  );

  const doChangeEdgePipe = (_, data: DropdownProps) => {
    const id = data.value as string;
    const pipe = pipeProducts.find((p) => p.uuid === id);
    if (pipe && edge) {
      changeEdges([{ ...edge, pipe: pipe.uuid }]);
      setCurrentEdge({ ...edge, pipe: pipe.uuid });
      setCurrentPipe(pipe);
    }
  };

  const doActiveBaseChange = (uuid: string, radius: number) => {
    setActiveBaseKey(uuid);
    setActiveBaseRadius(radius);
  };

  // const doReverse = (edge: IEdge) => {
  //   const newEdge = {
  //     ...edge,
  //     source: edge.target,
  //     target: edge.source,
  //   }
  //   changeEdges([newEdge])
  // }

  const doBisect = (edge: IEdge) => {
    if (start && end) {
      const midPoint = getMidPoint(start.position, end.position);
      const newFitting = bisectPipe(edge, midPoint);
      if (newFitting) {
        clearSelectedItems();
      }
    }
  };

  const setPreExisting = (preExisting: boolean) => {
    if (!currentEdge) return;
    changeEdges([{ ...currentEdge, preExisting }]);
    setCurrentEdge({ ...currentEdge, preExisting });
  };
  const setVertical = (vertical: boolean) => {
    if (!currentEdge) return;
    changeEdges([{ ...currentEdge, vertical }]);
    setCurrentEdge({ ...currentEdge, vertical });
  };
  const setShowLabel = (showLabel: boolean) => {
    if (!currentEdge) return;
    changeEdges([{ ...currentEdge, showLabel }]);
    setCurrentEdge({ ...currentEdge, showLabel });
  };

  const getGPM = (edge: IEdge): number => {
    const dg = pocGraphByNode(edge.source, pocGraphs);
    return dg
      ? dg.getEdgeMaxGPM(edge.source, edge.target, pipeProducts, groups)
      : -1;
  };

  const fillHeads = (edge: IEdge, elementCache: DesignElementMap) => {
    setShowFill(false);
    const sprinklerBases = getState().sprinklerBases;
    const e1 = elementCache[edge.source];
    const e2 = elementCache[edge.target];
    const p1 = { x: e1.position.x, y: e1.position.y };
    const p2 = { x: e2.position.x, y: e2.position.y };
    const lineLength = getEuclideanDistance(p1, p2);
    const numHeads = Math.ceil((lineLength - fillOffset) / activeBaseRadius);
    let lastSrc = edge.source;
    let finalTarget = edge.target;
    deleteEdge(edge.uuid);
    let newEls: DesignElement[] = [];
    let newEdges: IEdge[] = [];
    for (let i = 0; i < numHeads; i++) {
      const newPoint = traversePoly(
        [p1, p2],
        fillOffset + activeBaseRadius * i,
        true,
        0,
      );
      if (newPoint) {
        const base =
          sprinklerBases.find((b) => b.uuid === activeBaseKey) || null;
        if (base) {
          const radius = activeBaseRadius;
          const sprinkler = createSprinkler(
            {
              ...newPoint,
              angle: 360,
              radius,
              rotation: 0,
            },
            base,
          );
          let elID = newUUID();
          const element: DesignElement = {
            ...defaultItem({ x: newPoint.x, y: newPoint.y }),
            uuid: elID,
            type: 'sprinkler',
            props: sprinkler,
            itemType: 'design-element',
          };
          newEls.push(element);
          if (i > 0 || fillOffset > 0) {
            newEdges.push({
              ...edge,
              uuid: newUUID(),
              source: lastSrc,
              target: elID,
            });
          }
          lastSrc = elID;
        }
      }
    }
    newEdges.push({
      ...edge,
      uuid: newUUID(),
      source: lastSrc,
      target: finalTarget,
    });
    addElementsEdges(newEls, newEdges);
  };

  return (
    <Wrap>
      {!!currentPipe && !!start && !!end && !!currentEdge && !!edge && (
        <>
          <h5>Pipe Details</h5>
          <PanelSelect
            compact={true}
            label="Pipe Type"
            value={currentEdge.pipe}
            onChange={doChangeEdgePipe}
            options={pipeProducts.map((p: IPipeProduct) => ({
              text: `${p.name}`,
              value: p.uuid,
            }))}
          />
          <Table inverted size="small" singleLine>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Roughness coefficient</Table.Cell>
                <Table.Cell>{currentPipe.coefficient}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Safe Flow</Table.Cell>
                <Table.Cell>{currentPipe.safeFlow}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Inside Diameter</Table.Cell>
                <Table.Cell>{currentPipe.insideDiameter} in</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Downstream GPM</Table.Cell>
                <Table.Cell>{getGPM(currentEdge).toFixed(2)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Velocity</Table.Cell>
                <Table.Cell>
                  {calculateVelocity(
                    currentPipe.insideDiameter,
                    getGPM(currentEdge),
                  ) > currentPipe.dangerFPS ? (
                    <Icon name="exclamation circle" color="red" />
                  ) : (
                    ''
                  )}
                  {calculateVelocity(
                    currentPipe.insideDiameter,
                    getGPM(currentEdge),
                  ).toFixed(2)}{' '}
                  ft/s
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Pipe Segment Length</Table.Cell>
                <Table.Cell>
                  {getEuclideanDistance(start.position, end.position).toFixed(
                    2,
                  )}
                  ft
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>PSI Loss</Table.Cell>
                <Table.Cell>
                  {calcFrictionLoss(
                    currentPipe.coefficient,
                    currentPipe.insideDiameter,
                    getEuclideanDistance(start.position, end.position),
                    getGPM(edge),
                  ).toFixed(4)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <PanelButton onClick={() => doBisect(edge)}>Bisect Pipe</PanelButton>
          <PanelButton onClick={() => setShowFill(true)}>
            Fill with Heads
          </PanelButton>
          <Checkbox
            onClick={() => setPreExisting(!currentEdge.preExisting)}
            checked={currentEdge.preExisting}
            label="Pre-existing?"
          />
          <Checkbox
            onClick={() => setVertical(!currentEdge.vertical)}
            checked={currentEdge.vertical}
            label="Vertical Pipe?"
          />
          <Checkbox
            onClick={() => setShowLabel(!currentEdge.showLabel)}
            checked={currentEdge.showLabel}
            label="Always Show Label"
          />
          {/* <Button onClick={() => doReverse(edge)}>
            Reverse Pipe Direction
          </Button> */}
          <Modal size="mini" open={showFill}>
            <Modal.Header>Fill with Heads</Modal.Header>
            <Modal.Content>
              <NumericFormat
                value={fillOffset}
                onChange={(d) =>
                  setFillOffset(parseFloat(d.currentTarget.value))
                }
              />
              <NozzleOptions
                activeKey={activeBaseKey}
                activeRadius={activeBaseRadius}
                onSet={doActiveBaseChange}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setShowFill(false)}>Cancel</Button>
              <Button primary onClick={() => fillHeads(edge, elementCache)}>
                Done
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )}
    </Wrap>
  );
};
const Wrap = styled(PanelWrap)``;
