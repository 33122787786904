import Fuse, { FuseResult } from 'fuse.js';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { smartSearchCommand$ } from '../custom-events';
import { DeleteHeadsConfirmType } from '../features/heads/DeleteAllHeads';
import { EdgeAlgoModalType } from '../features/heads/EdgeAlgoModal';
import { HeadEquipmentModalType } from '../features/heads/HeadEquipment';
import { HeadSizeModalType } from '../features/heads/HeadSizeTools';
import { DeleteAllPipesType } from '../features/pipes/DeleteAllPipes';
import { DeleteOrphansType } from '../features/pipes/DeleteOrphans';
import { PipeEquipmentModalType } from '../features/pipes/PipeEquipment';
import {
  cleanupEdges,
  connectEdgeHeads,
  doAutoSize,
  doFixPipeDirections,
  findLines,
} from '../features/pipes/Pipes';
import { cleanColinearPipes } from '../features/pipes/cleanColinearPipes';
import { deleteGeneratedSleeves } from '../features/sleeves/state/deleteGeneratedSleeves';
import { deleteUserSleeves } from '../features/sleeves/state/deleteUserSleeves';
import { ValveEquipmentModalType } from '../features/valves/ValveEquipment';
import { selectYardHeads } from '../paper-helpers/heads';
import { blurElements } from '../paper-helpers/plot.helpers';
import { sizeDripTransitions } from '../plants/plants';
import {
  getState,
  toggleAllCoverage,
  toggleArcsOff,
  toggleArcsOn,
  toggleCoverage,
  toggleGPM,
  togglePipeLabels,
} from '../state';
import { addZone } from '../state/addZone';
import { openModal, useModalStore } from '../state/modal.store';
import { MoveItemsModalType } from './MoveItems';
import { SettingsModalType } from './SettingsModal';

interface SearchItem {
  term: string;
  command: Function;
}
const list: SearchItem[] = [
  {
    term: 'Delete All Heads',
    command: () => openModal(DeleteHeadsConfirmType),
  },
  { term: 'Delete All Pipes', command: () => openModal(DeleteAllPipesType) },
  { term: 'Add a New Zone', command: () => addZone([], false) },
  { term: 'Show Head Arcs', command: toggleArcsOn },
  { term: 'Hide Head Arcs', command: toggleArcsOff },
  { term: 'Toggle (Show/Hide) Coverage', command: toggleCoverage },
  {
    term: 'Toggle (Show/Hide) Coverage for All Yards',
    command: toggleAllCoverage,
  },
  { term: 'Toggle (Show/Hide) GPM', command: toggleGPM },
  { term: 'Toggle (Show/Hide) Pipe Labels', command: togglePipeLabels },
  { term: 'Delete Generated Sleeves', command: deleteGeneratedSleeves },
  { term: 'Delete User-Created Sleeves', command: deleteUserSleeves },
  {
    term: 'Delete Orphaned Fittings',
    command: () => openModal(DeleteOrphansType),
  },
  { term: 'Edit Settings', command: () => openModal(SettingsModalType) },
  {
    term: 'Edit Valve Equipment',
    command: () => openModal(ValveEquipmentModalType),
  },
  {
    term: 'Edit Pipe Equipment',
    command: () => openModal(PipeEquipmentModalType),
  },
  {
    term: 'Size Drip Transitions',
    command: sizeDripTransitions,
  },
  {
    term: 'Fix Pipe Directions',
    command: doFixPipeDirections,
  },
  {
    term: 'Autosize Pipes',
    command: doAutoSize,
  },
  {
    term: 'Connect Edge Heads',
    command: connectEdgeHeads,
  },
  {
    term: 'Clean up Edges',
    command: cleanupEdges,
  },
  {
    term: 'Clean up Colinear Pipes',
    command: () => cleanColinearPipes(getState().zones),
  },
  {
    term: 'Colinear(ish) Pipes',
    command: findLines,
  },
  {
    term: 'Select heads in active yard',
    command: selectYardHeads,
  },
  {
    term: 'Change Head Sizes',
    command: () => openModal(HeadSizeModalType),
  },
  {
    term: 'Edit Head Equipment',
    command: () => openModal(HeadEquipmentModalType),
  },
  {
    term: 'Bulk Move Items',
    command: () => openModal(MoveItemsModalType),
  },
  {
    term: 'Edge Algorithm',
    command: () => openModal(EdgeAlgoModalType),
  },
];

export const SmartSearch = () => {
  const inp = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<FuseResult<SearchItem>[]>([]);
  const [resultIndex, setResultIindex] = useState(0);
  const modal = useModalStore((state) => state.modal);
  const [blurTimeout, setBlurTimeout] = useState<any>(null);

  useEffect(() => {
    const sub = smartSearchCommand$.subscribe((command) => {
      if (command === 'open-search') {
        inp.current?.focus();
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (modal) {
      inp.current?.blur();
    }
  }, [modal]);

  const doSearch = (e) => {
    setSearchTerm(e.currentTarget.value);
    const options = {
      includeScore: true,
      keys: ['term'],
      threshold: 0.4,
    };

    const fuse = new Fuse(list, options);

    const result = fuse.search(e.currentTarget.value);
    setResults(result);
    setResultIindex(0);
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (results[resultIndex]) {
        results[resultIndex].item.command();
        setSearchTerm('');
        setResultIindex(0);
        setResults([]);
        blurElements();
      }
    } else if (e.key === 'ArrowDown') {
      const nextIndex = resultIndex + 1;
      if (nextIndex < results.length) {
        setResultIindex(nextIndex);
      } else {
        setResultIindex(0);
      }
    } else if (e.key === 'ArrowUp') {
      const prevIndex = resultIndex - 1;
      if (prevIndex >= 0) {
        setResultIindex(prevIndex);
      } else {
        setResultIindex(results.length - 1);
      }
    }
  };
  return (
    <Wrap>
      <Icon name="search" />
      <input
        ref={inp}
        type="text"
        placeholder="Type / to open search and start typing"
        value={searchTerm}
        onChange={doSearch}
        onKeyDown={handleKeyDown}
        onBlur={() => {
          setBlurTimeout(
            setTimeout(() => {
              setSearchTerm('');
              setResults([]);
              setResultIindex(0);
            }, 0),
          );
        }}
        onFocus={() => {
          if (blurTimeout) clearTimeout(blurTimeout);
        }}
      />
      {results.length > 0 && (
        <div
          className="results"
          tabIndex={0} // make it focusable
          onFocus={() => {
            if (blurTimeout) clearTimeout(blurTimeout);
          }}
          onBlur={() => {
            setBlurTimeout(
              setTimeout(() => {
                setSearchTerm('');
                setResults([]);
                setResultIindex(0);
              }, 0),
            );
          }}
        >
          {results.map((r, i) => (
            <div
              key={i}
              className={`result ${i === resultIndex ? 'active' : ''}`}
              onClick={() => {
                r.item.command();
                setSearchTerm('');
                setResults([]);
                setResultIindex(0);
              }}
            >
              {r.item.term}
            </div>
          ))}
        </div>
      )}
    </Wrap>
  );
};

export const Wrap = styled.div`
  padding-left: 16px;
  color: rgba(255, 255, 255, 0.5);
  input {
    background: rgba(255, 255, 255, 0.1);
    border: 0;
    outline: none;
    margin-left: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 8px;
    width: 190px;
    transition: width 0.3s ease-in-out;
    color: rgba(255, 255, 255, 0.2);
    &:focus {
      width: 250px;
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.8);
      border-bottom-color: rgba(255, 255, 255, 0.5);
    }
  }
  .results {
    color: black;
    position: absolute;
    top: 50px;
    left: 50px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 100;
    max-height: 50vh;
    overflow-y: auto;
  }
  .result {
    cursor: pointer;
    font-size: 12px;
    padding: 8px 16px;
    &:hover {
      background: #efefef;
    }
    &.active {
      /* font-weight: bold; */
      background: #ccc;
    }
  }
`;
