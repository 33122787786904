import { DesignElement, IMeter } from '@shared-types';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  Button,
  DropdownItemProps,
  DropdownProps,
  Form,
  Modal,
} from 'semantic-ui-react';
import { meterSizes } from 'src/shared';
import { styled } from 'styled-components';
import { uniq } from 'underscore';
import { useDesignStore } from '../state';
import { changeElements } from '../state/changeElements';
import { PanelButton, PanelWrap } from './PanelButton';

interface Props {
  element: DesignElement;
}
export const MeterTooltip = ({ element }: Props) => {
  const [changeMeter, setChangeMeter] = useState(false);

  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const [meter, setMeter] = useState<IMeter>(element.props as IMeter);
  const [isFirstRun, setFirstRun] = useState(true);

  useEffect(() => {
    if (isFirstRun) {
      setFirstRun(false);
    }
  }, [isFirstRun]);

  const doSizeChange = (_, data: DropdownProps) => {
    setMeter({
      ...meter,
      meterSize: data.value as number,
    });
  };

  const doPipeChange = (_, data: DropdownProps) => {
    setMeter({
      ...meter,
      serviceLine: data.value as string,
    });
  };

  const doPipeSizeChange = (_, data: DropdownProps) => {
    setMeter({
      ...meter,
      serviceLineSize: data.value as number,
    });
  };

  const changeLength = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMeter({
      ...meter,
      serviceLineLength: parseFloat(e.currentTarget.value),
    });
  };
  const changeElevation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMeter({
      ...meter,
      serviceElevationChange: parseFloat(e.currentTarget.value),
    });
  };
  const handleSave = (): void => {
    setChangeMeter(false);
    changeElements([
      {
        ...element,
        props: meter,
      },
    ]);
  };

  const cancelSave = () => {
    setChangeMeter(false);
    setMeter(element.props as IMeter);
  };
  return (
    <Wrap>
      <div>
        <p>Name: {(element.props as IMeter).name}</p>
        <p>Size: {(element.props as IMeter).meterSize}"</p>
        <p>Service Line: {(element.props as IMeter).serviceLine}</p>
        <p>
          Service Line Length: {(element.props as IMeter).serviceLineLength}
        </p>
        <p>
          Service Line Elevation:{' '}
          {(element.props as IMeter).serviceElevationChange}
        </p>
        <p>Service Line Size: {(element.props as IMeter).serviceLineSize}"</p>
        <p>GPM Percentage: {(element.props as IMeter).gpmPercentage}%</p>
      </div>
      <PanelButton onClick={() => setChangeMeter(true)}>
        Change Meter
      </PanelButton>
      <Modal open={changeMeter} size="mini">
        <Modal.Header>Change Meter Details</Modal.Header>
        <Modal.Content>
          <Form.Select
            label="Meter Size"
            value={meter.meterSize}
            required={true}
            onChange={doSizeChange}
            options={meterSizes.map((size) => ({
              value: size.size,
              text: size.display,
            }))}
          />

          <label>Elevation Change to Meter (ft) - (eg, 7 or -8)</label>
          <NumericFormat
            name="serviceElevationChange"
            value={meter.serviceElevationChange}
            onBlur={changeElevation}
          />
          {/* <label>% of flow usage</label>
      <NumberFormat name="" value={meter.gpmPercentage} onBlur={changePercentage} /> */}
          <p>
            The <strong>Service Line</strong> is the pipe running from the city
            to the meter
          </p>
          <div className="service-line">
            <Form.Select
              label="Service Line Type"
              value={meter.serviceLine}
              required={true}
              onChange={doPipeChange}
              options={uniq(pipeProducts.map((p) => p.series)).map(
                (pipeName: string): DropdownItemProps => ({
                  text: pipeName,
                  value: pipeName,
                }),
              )}
            />
            {!!meter.serviceLine && (
              <>
                <Form.Select
                  label="Service Line Size"
                  value={meter.serviceLineSize}
                  onChange={doPipeSizeChange}
                  required={true}
                  options={uniq(
                    pipeProducts
                      .filter((p) => p.series === meter.serviceLine)
                      .map((p) => p.size),
                  ).map(
                    (pipeName: number): DropdownItemProps => ({
                      text: pipeName,
                      value: pipeName,
                    }),
                  )}
                />
                <div>
                  <label>Service Line Length (ft)</label>
                  <NumericFormat
                    name="serviceLineLength"
                    value={meter.serviceLineLength}
                    onBlur={changeLength}
                  />
                </div>
              </>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={cancelSave}>Cancel</Button>
          <Button primary onClick={handleSave}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)``;
