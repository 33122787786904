import { BaseItem } from '@shared-types';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { uniq } from 'underscore';
import { PanelInputWrap } from './PanelButton';

interface Props {
  items: BaseItem[];
  onChange: (items: BaseItem[]) => void;
}
export const EditRotation = ({ items, onChange }: Props) => {
  const [localRotation, setLocalRotation] = useState<number>(0);

  useEffect(() => {
    const r = uniq(items.map((item) => item.rotation));
    if (r.length === 1) {
      setLocalRotation(r[0]);
    }
  }, [items]);

  const updateRotation = (value: number) => {
    const updated = items.map((item) => ({
      ...item,
      rotation: value,
    }));
    setLocalRotation(value);
    onChange(updated);
  };
  return (
    <Wrap>
      <PanelInputWrap
        label={'Rotation'}
        onChange={(e) => {
          updateRotation(parseFloat(e.target.value || '0'));
        }}
        type="number"
        min={-360}
        max={360}
        step={0.1}
        value={localRotation || '0'}
      />
    </Wrap>
  );
};
const Wrap = styled.div`
  .col-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
    > div {
      flex: 1;
    }
  }
`;
