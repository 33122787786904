import { Modal } from 'semantic-ui-react';
import { modalDefaults } from '../../state/modal.service';
import { PressureTables } from './PressureTables';

export const HydraulicsModalType = 'hydraulicsModal';
export const HydraulicsModal = ({ closeModal }) => {
  return (
    <Modal
      {...modalDefaults}
      style={{ maxHeight: '70vh' }}
      header="Hydraulics"
      actions={[
        {
          key: 'done',
          content: 'Done',
          primary: true,
        },
      ]}
      onActionClick={closeModal}
      content={
        <Modal.Content scrolling>
          <PressureTables />
        </Modal.Content>
      }
    />
  );
};
