import { Rectangle } from '@shared-types';
import { filter, map, merge, switchMap, takeUntil, tap } from 'rxjs';
import paper from 'src/paper';
import { polyHintPurple } from '../../../shared';
import { localZoom } from '../localPaper';
import { getColor } from '../paper-helpers/plot.helpers';
import { addItem } from '../state/item-state';
import { toolService } from '../tool.service';
import { rectangleService } from './rectangle.service';

const { mouseDown$, mouseUp$, mouseDrag$ } = toolService.getEvents('rectangle');

const createTmpRectangle = (rect: paper.Rectangle) => {
  const r = new paper.Path.Rectangle(rect);
  r.strokeColor = getColor(polyHintPurple);
  r.strokeWidth = 1 / localZoom();
  return r;
};

const dragDrawRectangle$ = mouseDown$.pipe(
  filter(({ e }) => !e.modifiers.space),
  map(({ e }) => {
    const rect = new paper.Rectangle(e.point, e.point);
    const paperRect = createTmpRectangle(rect);
    return { rect, paperRect };
  }),
  switchMap(({ rect, paperRect }) =>
    mouseDrag$.pipe(
      tap((e) => {
        paperRect.remove();
        const xDiff = e.point.x - e.downPoint.x;
        const yDiff = e.point.y - e.downPoint.y;
        const maxDiff = Math.max(Math.abs(xDiff), Math.abs(yDiff));

        const xDirection = xDiff < 0 ? -1 : 1;
        const yDirection = yDiff < 0 ? -1 : 1;

        if (e.modifiers.shift) {
          rect = new paper.Rectangle(
            e.downPoint,
            new paper.Point({
              x: e.downPoint.x + maxDiff * xDirection,
              y: e.downPoint.y + maxDiff * yDirection,
            }),
          );
          paperRect = createTmpRectangle(rect);
        } else {
          rect = new paper.Rectangle(e.downPoint, e.point);
          paperRect = createTmpRectangle(rect);
        }
      }),
      takeUntil(
        merge(
          mouseUp$.pipe(
            tap(() => {
              if (rect.width * rect.height > 1) {
                const newRect: Rectangle = {
                  ...rectangleService.createItem(),
                  width: rect.width,
                  height: rect.height,
                  position: { x: rect.topLeft.x, y: rect.topLeft.y },
                };
                addItem(newRect);
              }
            }),
          ),
        ).pipe(
          tap(() => {
            paperRect.remove();
          }),
        ),
      ),
    ),
  ),
);

export const drawRectangle$ = merge(dragDrawRectangle$);
