import { BulkContractUI } from '@shared-types';
import { addYearToDate, formatDate } from 'src/components/BulkContractForm';
import { Query } from '../../../shared-types/portal-types';
import { wrapFetch, wrapFetchWithQuery } from './fetchHelpers';

export const getBulkContract = (
  id: string,
): Promise<{ bulkContract: BulkContractUI }> =>
  wrapFetch(`/api/bulk-contracts/${id}`);

export const getBulkContracts = (
  query?: Query,
): Promise<{ total: number; bulkContracts: BulkContractUI[] }> =>
  wrapFetchWithQuery(`/api/bulk-contracts`, 'GET', query);

export const newBulkContract = (
  payments: number,
  title: string,
  count: number,
): Promise<{ bulkContract: BulkContractUI }> =>
  wrapFetch(`/api/bulk-contracts`, 'POST', {
    payments,
    title,
    count,
    startDate: formatDate(new Date()),
    endDate: addYearToDate(new Date()),
  });

export const saveBulkContract = (
  id: number,
  title: string,
  count: number,
  startDate: string,
  endDate: string,
): Promise<{ bulkContract: BulkContractUI }> =>
  wrapFetch(`/api/bulk-contracts/${id}`, 'PUT', {
    title,
    count,
    startDate,
    endDate,
  });

export const deleteBulkContract = (bulkContractID: string): Promise<any> =>
  wrapFetch(`/api/bulk-contracts/${bulkContractID}`, 'DELETE');

export const getRemainingProjects = (
  email: string,
): Promise<{ count: number }> =>
  // this is an open route, no need for login
  wrapFetch(`/credits/${email}`, 'GET');

export const getNextCredit = (email: string): Promise<{ code: string }> =>
  // this is an open route, no need for login
  wrapFetch(`/api/bulk-contracts/next-credit/${email}`, 'GET');

export const getCreditsByContract = (email: string): Promise<any> =>
  // this is an open route, no need for login
  wrapFetch(`/api/bulk-contracts/credits/${email}`, 'GET');

export const releaseCredits = (bulkContractId: number, email: string) =>
  wrapFetch(`/api/bulk-contracts/${bulkContractId}/release/${email}`, 'POST');

export const assignBulkCredits = (
  bulkContractId: number,
  email: string,
  count: number,
) =>
  wrapFetch(`/api/bulk-contracts/${bulkContractId}/assign`, 'POST', {
    count,
    email,
  });
