import { PDFDocument } from 'pdf-lib';
import { Content, ContentTable } from 'pdfmake/interfaces';
import { compact } from 'underscore';
import {
  DesignElement,
  DesignElementMap,
  IBackflowProduct,
  IEdge,
  IMeter,
  IPipeProduct,
  IPoC,
  IPump,
  IValveProduct,
  Sprinkler,
  Zone,
  ZoneLossTable,
} from '../../../../../../shared-types';
import { IPOCDirectedGraph } from '../../../../../../shared-types/pocDirectedGraph.helper';
import { toRealisticFixed } from '../../helpers';
import { getAvgPrecip } from '../../paper-helpers/heads';
import { getState, pocGraphByIndex, pocGraphByNode } from '../../state';
import { getPOCData, valveGPM } from '../directedGraph';
import { createPDFMakePage } from './createPDFMakePage';
import { defaultTableLayouts } from './defaultTableLayouts';
import { mapHeadIdsToHeads } from './mapHeadIdsToHeads';
import { pocLines, standardDefinition } from './pdf.helpers';

export async function createAllZonesPagePDF(): Promise<PDFDocument | void> {
  const definitionFunc = async () => standardDefinition();
  const getContent = async () => allZonesPage();
  return createPDFMakePage(definitionFunc, getContent);
}
export const allZonesPage = (): Content[] => {
  const {
    zones,
    elements,
    edges,
    groups,
    valveProducts,
    pipeProducts,
    backflowProducts,
    pocGraphs,
    elementCache,
  } = getState();

  const staticPSI = [
    ...new Set(
      elements
        .filter((el) => el.type === 'poc')
        .map((el) => (el.props as IPoC).staticPSI),
    ),
  ];
  if (staticPSI.length > 1) {
    console.error('should not have more than 1 static PSI measurement');
  }
  return [
    { text: 'Zone Breakdown', style: 'header' },
    { text: '', margin: [0, 0, 0, 18] },
    ...pocLines(pipeProducts, elementCache, pocGraphs),
    { text: '', margin: [0, 0, 0, 18] },
    {
      stack: [
        {
          layout: defaultTableLayouts.simpleTable as any,
          table: {
            headerRows: 1,
            body: [
              [
                { text: 'Zone' },
                { text: 'Average Precipitation' },
                { text: 'GPM' },
              ],
              ...zones
                .sort((z1, z2) => z1.orderNumber - z2.orderNumber)
                .map((zone: Zone) => [
                  { text: `Zone ${zone.orderNumber + 1}` },
                  {
                    text:
                      zone.headIds.length > 0
                        ? `${getAvgPrecip(elements, zone.headIds).toFixed(
                            2,
                          )} in/hr`
                        : 'n/a',
                  },
                  {
                    text: `${
                      zone.plantIds.length
                        ? `${valveGPM(
                            zone,
                            pipeProducts,
                            groups,
                            pocGraphs,
                          ).toFixed(1)}`
                        : mapHeadIdsToHeads(elements, zone.headIds)
                            .reduce(
                              (acc: number, h: Sprinkler) => acc + h.gpm,
                              0,
                            )
                            .toFixed(1)
                    }`,
                  },
                ]),
            ],
          },
          margin: [0, 0, 0, 30],
        },
        ...pocGraphs.map((_, treeIndex) =>
          pocTables(treeIndex, elements, pipeProducts, elementCache, pocGraphs),
        ),

        pressureTable(
          zones,
          elements,
          edges,
          valveProducts,
          backflowProducts,
          pipeProducts,
          pocGraphs,
        ),
      ],
    },
  ];
};
export const pressureTable = (
  zones: Zone[],
  elements: DesignElement[],
  edges: IEdge[],
  valveProducts: IValveProduct[],
  backflowProducts: IBackflowProduct[],
  pipeProducts: IPipeProduct[],
  pocGraphs: IPOCDirectedGraph[],
): ContentTable => {
  const zoneLosses: { [n: number]: ZoneLossTable } = {};
  const staticLossSet = new Set<string>();
  zones.forEach((zone) => {
    (zone.staticLossItems || []).forEach((lossItem) => {
      staticLossSet.add(lossItem.description);
    });
    const dg = pocGraphByNode(zone.valve, pocGraphs);
    if (dg) {
      const losses = dg.lossesAtZone(
        zone,
        pipeProducts,
        valveProducts,
        backflowProducts,
        edges,
      );
      const worstLoss = losses.reduce(
        (acc, loss) => (loss.totalLoss > acc.totalLoss ? loss : acc),
        losses[0],
      );
      zoneLosses[zone.orderNumber] = worstLoss;
    }
  });

  const actualStaticLosses: string[] = compact([...staticLossSet]);
  const table: Content = {
    margin: [0, 30, 0, 0],
    table: {
      headerRows: 2,
      body: [
        [
          {
            colSpan: 7 + staticLossSet.size,
            width: 'auto',
            text: 'Pressure Loss from POC through Zones',
            fontSize: 12,
            bold: true,
          },
          ...[...Array(6 + staticLossSet.size).map(() => ({ text: '' }))],
        ],
        [
          { bold: true, fontSize: 8, width: 'auto', text: 'Zone' },
          { fontSize: 8, text: 'Backflow' },
          { fontSize: 8, text: 'Main' },
          { fontSize: 8, text: 'Valve' },
          { fontSize: 8, text: 'Lateral' },
          { fontSize: 8, text: 'Fittings' },
          { fontSize: 8, text: 'Elevation' },
          ...actualStaticLosses.map((lossItem) => ({
            fontSize: 8,
            text: lossItem,
          })),
        ],
        ...zones.map((zone) => {
          if (zoneLosses[zone.orderNumber]) {
            return [
              {
                fontSize: 8,
                bold: true,
                text: `${zone.isDrip || zone.plantIds.length > 0 ? 'D' : ''}${
                  zone.orderNumber + 1
                }`,
              },
              {
                fontSize: 8,
                text: elements.find((el) => el.type === 'backflow')
                  ? `${toRealisticFixed(
                      zoneLosses[zone.orderNumber].backflowLoss,
                    )} PSI`
                  : 'n/a',
              },
              {
                fontSize: 8,
                text: `${toRealisticFixed(
                  zoneLosses[zone.orderNumber].mainLoss,
                )} PSI`,
              },
              {
                fontSize: 8,
                text: `${toRealisticFixed(
                  zoneLosses[zone.orderNumber].valveLoss,
                )} PSI`,
              },
              {
                fontSize: 8,
                text: `${toRealisticFixed(
                  zoneLosses[zone.orderNumber].lateralLoss,
                )} PSI`,
              },
              {
                fontSize: 8,
                text: `${toRealisticFixed(
                  zoneLosses[zone.orderNumber].lateralFittingsLoss,
                )} PSI`,
              },
              {
                fontSize: 8,
                text: `${toRealisticFixed(
                  zoneLosses[zone.orderNumber].elevationLoss,
                )} PSI`,
              },
              ...actualStaticLosses.map((lossItem) => {
                if (zone.staticLossItems) {
                  const item = zone.staticLossItems.find(
                    (it) => it.description === lossItem,
                  );
                  if (item) {
                    return {
                      fontSize: 8,
                      text: `${item.loss} PSI`,
                    };
                  } else {
                    return {
                      fontSize: 8,
                      text: 'n/a',
                    };
                  }
                } else {
                  return {
                    fontSize: 8,
                    text: 'n/a',
                  };
                }
              }),
            ];
          } else {
            return [...Array(7 + staticLossSet.size)].map(() => ({ text: '' }));
          }
        }),
        [
          {
            colSpan: 7 + actualStaticLosses.length,
            fontSize: 8,
            text: 'All numbers in the table above represent the amount of loss in PSI. Fittings loss based on 10% of lateral loss. A drop in elevation produces a gain in PSI (so elevation loss is shown as negative).',
          },
        ],
      ],
    },
    layout: defaultTableLayouts.simpleTable as any,
  };
  return table;
};
export const pocTables = (
  treeIndex: number,
  elements: DesignElement[],
  pipeProducts: IPipeProduct[],
  elementCache: DesignElementMap,
  pocGraphs: IPOCDirectedGraph[],
): Content => {
  const {
    poc,
    pump,
    calculatedGPM,
    meter,
    root,
    meterLosses,
    meterElevationLoss,
    mainsLoss,
    calculatedPSI,
  } = getPOCData(treeIndex, elements, pipeProducts, elementCache, pocGraphs);
  const dg = pocGraphByIndex(treeIndex, pocGraphs);
  const prvs = elements.filter((el) => el.type === 'prv');
  const boosters = elements.filter((el) => el.type === 'booster pump');
  const hasBooster =
    boosters.length && boosters.some((b) => dg.hasNode(b.uuid));
  const hasPRV = prvs.length && prvs.some((p) => dg.hasNode(p.uuid));
  const table: Content = {
    table: {
      headerRows: 1,
      body: [],
      widths: [150, 200],
    },
    layout: defaultTableLayouts.simpleTable as any,
  };
  if (poc && table.table) {
    table.table.body.push([
      { bold: true, fontSize: 9, text: `POC: ${poc.name}`, colSpan: 2 },
      { bold: true, fontSize: 9, text: '' },
    ] as any);
    table.table.body.push([
      { fontSize: 9, text: 'Measured GPM' },
      {
        fontSize: 9,
        text: poc.measuredGPM ? poc.measuredGPM.toFixed(2) : 'n/a',
      },
    ]);
    if (pump) {
      table.table.body.push([
        { fontSize: 9, text: 'Pump PSI' },
        { fontSize: 9, text: (pump.props as IPump).outputPSI.toFixed(2) },
      ]);
      table.table.body.push([
        { fontSize: 9, text: 'Pump GPM' },
        { fontSize: 9, text: (pump.props as IPump).outputGPM.toFixed(2) },
      ]);
    }
    table.table.body.push([
      { fontSize: 9, text: 'Calculated GPM' },
      { fontSize: 9, text: calculatedGPM.toFixed(2) },
    ]);
    if (meter && root) {
      table.table.body.push(
        [
          {
            fontSize: 9,
            text: `This POC is connected to a meter. GPM is calculated using ${
              poc.measuredGPM
                ? 'the measured GPM at the POC.'
                : `the minimum value when calculating the max safe velocity in either the meter, the service line, or the mains leading to the POC. This is because a GPM measurement at the POC was not provided.`
            }`,
            colSpan: 2,
          },
          { fontSize: 9, text: '' },
        ] as any,
        [
          { fontSize: 9, text: 'Meter Size' },
          { fontSize: 9, text: `${(meter.props as IMeter).meterSize}` },
        ],
        [
          { fontSize: 9, text: 'Meter Loss' },
          {
            fontSize: 9,
            text: `${meterLosses ? meterLosses.meterLoss.toFixed(2) : 0}`,
          },
        ],
        [
          { fontSize: 9, text: 'Meter Service Line Loss' },
          {
            fontSize: 9,
            text: `${meterLosses ? meterLosses.serviceLineLoss.toFixed(2) : 0}`,
          },
        ],
        [
          { fontSize: 9, text: 'Meter Elevation Loss' },
          { fontSize: 9, text: `${meterElevationLoss.toFixed(2)}` },
        ],
        [
          { fontSize: 9, text: 'Meter to POC Mains Loss' },
          { fontSize: 9, text: `${mainsLoss.toFixed(2)}` },
        ],
      );
    }
    table.table.body.push(
      [
        { fontSize: 9, text: 'Static PSI' },
        { fontSize: 9, text: poc.staticPSI ? poc.staticPSI.toFixed(2) : '' },
      ],
      [
        { fontSize: 9, text: 'Calculated PSI' },
        { fontSize: 9, text: calculatedPSI.toFixed(2) },
      ],
      [
        { fontSize: 9, text: 'Measured (Dynamic) PSI' },
        {
          fontSize: 9,
          text: poc.measuredPSI ? poc.measuredPSI.toFixed(2) : 'not measured',
        },
      ],
      [
        { fontSize: 9, text: 'Has Pressure Regulating Valve' },
        {
          fontSize: 9,
          text: hasPRV ? 'Yes' : 'No',
        },
      ],
      [
        { fontSize: 9, text: 'Has Booster Pump' },
        {
          fontSize: 9,
          text: hasBooster ? 'Yes' : 'No',
        },
      ],
      [
        {
          fontSize: 8,
          text: '* If a dynamic PSI reading is measured on-site, that number will be used as the working PSI of the system except in the case of pressure regulating valves or booster pumps. Otherwise the calculated PSI (static PSI minus all losses up to POC) will be used as the working PSI.\n\n* If a dynamic GPM reading is measured on-site (ie, a bucket test) that will be used as the GPM for the design. If that is not provided, a calculated GPM will be used by finding the lowest max-flow across the meter and meter service line (if provided) as well as any mains leading from the meter or pump to the POC.',
          colSpan: 2,
        },
        { fontSize: 8, text: '' },
      ] as any,
    );
  }
  return table;
};
