import { CustomSymbol, IPoint } from '@shared-types';

import { takeUntil } from 'rxjs';
import { defaultStyledItem, paperItemStore } from '../helpers';
import { activateNamedLayer } from '../paper-helpers/plot.helpers';
import { ItemService } from '../polyline/ItemService';
import { registerStateHooks } from '../polyline/item.model';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { getState, refreshSelectedIDs } from '../state';
import { deletePaperItem, updatePaperItem } from '../state/renderers';
import { toolService } from '../tool.service';
import { drawCustomSymbol$ } from './drawCustomSymbol';
import { PaperCustomSymbol } from './paper-custom-symbol';

class CustomSymbolService implements ItemService<CustomSymbol> {
  init() {
    this.registerWithState();
    drawCustomSymbol$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
  }
  createItem = (position?: IPoint): CustomSymbol => {
    const item = defaultStyledItem(position);
    return {
      ...item,
      style: {
        ...item.style,
        fillAlpha: 0.01,
        fillColor: 'white',
        strokeWidth: 0.5,
      },
      itemType: 'custom-symbol',
      scale: 1,
      rotation: 0,
      symbolType: 'rock',
    };
  };
  renderItemToPaper = (item: CustomSymbol, layer = LAYER_NAMES.TRACING) => {
    activateNamedLayer(layer);
    paperItemStore.set(item.uuid, new PaperCustomSymbol(item));
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  };
  registerWithState = () => {
    registerStateHooks<CustomSymbol>('custom-symbol', {
      postAddItem: (item) => {
        this.renderItemToPaper(item);
      },
      postUpdateItem: (item) => {
        updatePaperItem(item);
        if (getState().selectedIDs.includes(item.uuid)) {
          refreshSelectedIDs();
        }
      },
      postDeleteItem: (item) => {
        deletePaperItem(item.uuid);
      },
    });
  };
}

export const customSymbolService = new CustomSymbolService();
