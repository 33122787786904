import { Zone } from '@shared-types';

// HEADS
export const headInZone = (
  zones: Zone[],
  zoneID: string,
  headId: string,
): boolean =>
  zones.length > 0 &&
  zones.filter((zone) => zone.uuid === zoneID)[0].headIds.includes(headId);
