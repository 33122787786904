import {
  DesignElement,
  DesignElementMap,
  GraphNode,
  IBackflowProduct,
  IEdge,
  IMainToSource,
  IMeter,
  IMiscItem,
  IPipeProduct,
  IPlant,
  IPoC,
  IPoint,
  IValveProduct,
  WaterGroup,
  Zone,
} from '@shared-types';
import { MININUM_RESIDUAL_PRESSURE, meterSizes } from 'src/shared/constants';
import { compact, uniq } from 'underscore';
import { IPOCDirectedGraph } from '../../../../../shared-types/pocDirectedGraph.helper';
import { getState, pocGraphByIndex, pocGraphByNode } from '../state';
import { isSprinkler } from '../tools/paper-items/paper-sprinkler';
import { calcFrictionLoss, getMissingCoordinateY } from './geometry.helpers';
import { createMasterGraph, createPOCGraphs } from './graph';
import { oldPOCMeterLoss } from './meter.helper';
import { UndirectedGraph } from './undirectedGraph';

export const reduceHeadsGPMbyId = (
  headIds: string[],
  elMap: DesignElementMap,
): number =>
  headIds.reduce((acc, uuid) => {
    const matchingHead = elMap[uuid];
    if (!matchingHead) {
      console.warn('cannot find head in cache...hmm');
    }
    const gpm = isSprinkler(matchingHead) ? matchingHead.props.gpm : 0;
    return acc + gpm;
  }, 0);

export const getRequiredGPM = (
  descendants: string[],
  elMap: DesignElementMap,
): number =>
  descendants.reduce((acc: number, id: string) => {
    const el = elMap[id];
    if (isSprinkler(el)) {
      return acc + el.props.gpm;
    } else if (el && el.type === 'miscItem') {
      return acc + ((el.props as IMiscItem).gpmEffect || 0);
    }
    return acc;
  }, 0);

// export const hasSplitHeads = (nodes: GraphNode[]): boolean =>
//   nodes.some((node) => node.type === 'sprinkler' && node.children.length > 1);

export const getAncestorIDs = (
  nodes: GraphNode[],
  id: string,
  treeIndex: number,
  elMap: DesignElementMap,
): string[] => {
  // if (ancestorIDCache[id]) {
  //   return ancestorIDCache[id]
  // }
  const ancestors: string[] = [];
  const node = elMap[id];
  if (node) {
    const parents = new Set<string>();
    nodes.forEach((n) => {
      if (n.children.includes(node.uuid)) {
        parents.add(n.uuid);
      }
    });
    // const parents = nodes.filter(n => contains(n.children, node.uuid)).map(n => n.uuid)
    // ancestors.push(...parents)
    ancestors.push(
      ...[...parents]
        .map((v) => getAncestorIDs(nodes, v, treeIndex, elMap))
        .reduce((a: string[], b: string[]) => [...a, ...b], [...parents]),
    );
  }
  // ancestorIDCache[id] = ancestors
  return ancestors;
};
export const edgeIsLateral = (
  nodes: GraphNode[],
  elements: DesignElement[],
  edge: IEdge,
  treeIndex: number,
  elMap: DesignElementMap,
): boolean => {
  // return edge.isLateral || false
  const sourceAncestors = getAncestorIDs(nodes, edge.source, treeIndex, elMap);
  if (
    sourceAncestors.some(
      (uuid: string) =>
        elements.filter((el) => el.uuid === uuid && el.type === 'valve')
          .length > 0,
    )
  ) {
    return true;
  }
  const targetAncestors = getAncestorIDs(nodes, edge.target, treeIndex, elMap);
  if (
    targetAncestors.some(
      (uuid: string) =>
        elements.filter((el) => el.uuid === uuid && el.type === 'valve')
          .length > 0,
    )
  ) {
    return true;
  }
  return false;
};

export const generateGraphs = (
  edges: IEdge[],
  elements: DesignElement[],
  zones: Zone[],
): { masterGraph: UndirectedGraph; pocGraphs: IPOCDirectedGraph[] } => {
  const masterGraph = createMasterGraph(edges, elements);
  const pocGraphs = createPOCGraphs(elements, masterGraph, zones);
  return { masterGraph, pocGraphs };
};
export const findEdge = (
  edges: IEdge[],
  e1: string,
  e2: string,
): IEdge | undefined => {
  const e = edges.find(
    (edge) =>
      (edge.source === e1 && edge.target === e2) ||
      (edge.target === e1 && edge.source === e2),
  );
  return e;
};

export const getZoneEdges = (
  edges: IEdge[],
  zone: Zone,
  pocGraphs: IPOCDirectedGraph[],
): IEdge[] => {
  const zoneEdges: IEdge[] = [];
  const dg = pocGraphByNode(zone.valve, pocGraphs);
  if (dg) {
    const subtreeEdges = compact(
      dg.getDownstreamEdges(zone.valve).map((e) => findEdge(edges, e[0], e[1])),
    );
    subtreeEdges.forEach((edge) => {
      zoneEdges.push(edge);
    });
  }
  return zoneEdges;
};

export const getLossFromFlow = (
  products: (IValveProduct | IBackflowProduct)[],
  gpm: number,
): number => {
  let p1: { gpm: number; loss: number } | undefined;
  let p2: { gpm: number; loss: number } | undefined;
  const flowList = uniq(products.map((v) => v.flow)).sort((a, b) => a - b);
  if (gpm < Math.min(...flowList) || gpm > Math.max(...flowList)) {
    // console.log(
    //   `ERROR: flow ${gpm} is not within the range ${flowList.join(',')}`
    // )
    return 0;
  }
  for (let i = 0; i < flowList.length; i++) {
    if (gpm < flowList[i]) break;
    const thisProduct = products.filter((n) => n.flow === flowList[i]);
    const nextProduct = products.filter((n) => n.flow === flowList[i + 1]);
    if (thisProduct.length === 1 && thisProduct[0].flow === gpm)
      return thisProduct[0].loss || 0;
    if (!thisProduct.length || !nextProduct.length) break;
    p1 = {
      gpm: flowList[i],
      loss: thisProduct[0].loss,
    };
    p2 = {
      gpm: flowList[i + 1],
      loss: nextProduct[0].loss,
    };
  }
  if (!p1 || !p2) return 0;
  const slope = (p2.loss - p1.loss) / (p2.gpm - p1.gpm);
  return getMissingCoordinateY({ x: p2.gpm, y: p2.loss }, gpm, slope);
};

export const getPOCGraphIndex = (nodeUUID: string): number =>
  getState().pocGraphs.findIndex((g) => g.hasNode(nodeUUID));

export const pocMainsLoss = (mainPipes: IMainToSource[], gpm: number) =>
  mainPipes.reduce(
    (acc: number, main: IMainToSource) =>
      acc +
      calcFrictionLoss(
        main.pipe.coefficient,
        main.pipe.insideDiameter,
        main.distance,
        gpm,
      ),
    0,
  );

export const getEdgePoints = (
  edge: IEdge,
  elMap: DesignElementMap,
): IPoint[] => {
  const source = elMap[edge.source];
  const target = elMap[edge.target];
  return source && target
    ? [
        { x: source.position.x, y: source.position.y },
        { x: target.position.x, y: target.position.y },
      ]
    : [];
};

export const getMeterLoss = (meter: IMeter, gpm: number): number => {
  const meterSizeLosses = meterSizes.find((m) => m.size === meter.meterSize);
  if (meterSizeLosses && meterSizeLosses.losses) {
    const knownLoss =
      meterSizeLosses.losses &&
      meterSizeLosses.losses.find((loss) => loss.flow === gpm);
    if (knownLoss) {
      return knownLoss.loss;
    } else {
      const loss1 = meterSizeLosses.losses
        .filter((loss) => loss.flow <= gpm)
        .sort((a, b) => b.flow - a.flow);

      const loss2 = meterSizeLosses.losses
        .filter((loss) => loss.flow >= gpm)
        .sort((a, b) => a.flow - b.flow);

      if (loss1.length && loss2.length) {
        const slope =
          (loss2[0].loss - loss1[0].loss) / (loss2[0].flow - loss1[0].flow);
        return getMissingCoordinateY(
          { x: loss2[0].flow, y: loss2[0].loss },
          gpm,
          slope,
        );
      }
    }
    return 999;
  }
  return 0;
};
export const getElevationLoss = (elevationChange: number): number =>
  // each foot in elevation change is a 0.433 change in PSI
  elevationChange * 0.433;

export const hasSameRadiusDiffHead = (elements: DesignElement[]): boolean => {
  const m = new Map();
  const nonZeroRadiusSprinklers = elements
    .filter(isSprinkler)
    .filter((el) => el.props.radius > 0)
    .map((el) => el.props);
  for (const sprinkler of nonZeroRadiusSprinklers) {
    const existingRadius = m.get(sprinkler.radius);
    const sprinklerVal = `${sprinkler.base.headModel}:${sprinkler.base.headSeries}`;
    if (existingRadius && existingRadius !== sprinklerVal) {
      return true;
    }
    m.set(sprinkler.radius, sprinklerVal);
  }
  return false;
};

export const getPOCData = (
  treeIndex: number,
  elements: DesignElement[],
  pipeProducts: IPipeProduct[],
  elementCache: DesignElementMap,
  pocGraphs: IPOCDirectedGraph[],
) => {
  const dg = pocGraphByIndex(treeIndex, pocGraphs);
  const _root = dg.pocRoot;
  const root = elementCache[_root];
  const poc = root && (root.props as IPoC);
  const meter =
    poc &&
    elements.find((el) => el.uuid === poc.sourceID && el.type === 'meter');
  const pump =
    poc &&
    elements.find((el) => el.uuid === poc.sourceID && el.type === 'pump');
  const mains = (poc && poc.mainsToSource) || [];
  const calculatedGPM = dg.rootGPM(pipeProducts);
  const meterLosses =
    (root &&
      meter &&
      oldPOCMeterLoss(root, pipeProducts, elementCache, pocGraphs)) ||
    undefined;
  const meterElevationLoss = (meterLosses && meterLosses.elevationLoss) || 0;
  const mainsLoss = pocMainsLoss(mains, calculatedGPM);
  const calculatedPSI = dg.pocPSI(pipeProducts);
  return {
    poc,
    pump,
    calculatedGPM,
    meter,
    root,
    meterLosses,
    meterElevationLoss,
    mainsLoss,
    calculatedPSI,
  };
};

// export const movePipeEndPoints = (
//   pipes: IPipeSegment[],
//   oldPoint: IPoint,
//   newPoint: IPoint,
// ): IPipeSegment[] =>
//   pipes.map((pipe: IPipeSegment) => {
//     if (pipe.start.x === oldPoint.x && pipe.start.y === oldPoint.y) {
//       return {
//         ...pipe,
//         start: {
//           x: newPoint.x,
//           y: newPoint.y,
//         },
//       };
//     } else if (pipe.end.x === oldPoint.x && pipe.end.y === oldPoint.y) {
//       return {
//         ...pipe,
//         end: { x: newPoint.x, y: newPoint.y },
//       };
//     }
//     return pipe;
//   });

export const reducePlantsGPHbyId = (
  plants: IPlant[],
  plantIds: string[],
): number =>
  plantIds
    .map((uuid: string) => {
      const matchingPlant = plants.find((plant: IPlant) => plant.uuid === uuid);
      return matchingPlant ? matchingPlant.gph : 0;
    })
    .reduce((acc, zg) => acc + zg, 0);

export const valveGPM = (
  zone: Zone,
  pipeProducts: IPipeProduct[],
  groups: WaterGroup[],
  pocGraphs: IPOCDirectedGraph[],
): number => {
  const dg = pocGraphByNode(zone.valve, pocGraphs);
  return dg
    ? dg.getEdgeMaxGPM(zone.valveInputFitting, zone.valve, pipeProducts, groups)
    : -1;
};

const changePipe = (
  pipeProducts: IPipeProduct[],
  src: string,
  dest: string,
  size: number,
  isMain: boolean,
  mainSeries: string,
  lateralSeries: string,
  edges: IEdge[],
  gpmDict,
) => {
  const edge = findEdge(edges, src, dest);
  const gpm = gpmDict[src][dest];
  if (edge) {
    if (!edge.preExisting) {
      const pipeSizeOptions = pipeProducts.filter(
        (p) =>
          p.series === (isMain ? mainSeries : lateralSeries) &&
          p.size <= size &&
          p.safeFlow >= gpm,
      );
      pipeSizeOptions.sort((a, b) => b.size - a.size);
      const newPipe = pipeSizeOptions.length ? pipeSizeOptions[0] : undefined;
      if (newPipe) {
        // console.log(`changing ${isMain ? 'main' : 'lateral'} to ${size}`)
        // console.log(edge.pipe, newPipe.uuid)
        edge.pipe = newPipe.uuid;
      }
    }
  }
};
const isMain = (mains: string[][], node: string) =>
  mains.some((d) => d[0] === node);

export const fixPipeDirections = (
  edges: IEdge[],
  pocGraphs: IPOCDirectedGraph[],
): IEdge[] => {
  const edgesToChange: IEdge[] = [];
  edges.forEach((edge) => {
    // TODO: solve for multiple trees?
    const dg = pocGraphByNode(edge.target, pocGraphs);
    if (dg) {
      if (dg.graph[edge.target] && dg.graph[edge.target].has(edge.source)) {
        // the target of this edge is not in the list of children,
        // so the edge is reversed/wrong and needs to be flipped
        edgesToChange.push({
          ...edge,
          source: edge.target,
          target: edge.source,
        });
      }
    }
  });
  return edgesToChange;
};
export const autoSizePipes = (
  valveProducts: IValveProduct[],
  backflowProducts: IBackflowProduct[],
  zones: Zone[],
  pipeProducts: IPipeProduct[],
  edges: IEdge[],
  groups: WaterGroup[],
  lateralSeries: string,
  lateralPipeSizes: number[],
  mainSeries: string,
  mainPipeSizes: number[],
): IEdge[] => {
  const pocGraphs = getState().pocGraphs;
  // console.time('autosizepipes')
  const lateralSizes = pipeProducts
    .filter(
      (p) => p.series === lateralSeries && lateralPipeSizes.includes(p.size),
    )
    .map((p) => p.size)
    .sort((a, b) => b - a);

  const mainSizes = pipeProducts
    .filter((p) => p.series === mainSeries && mainPipeSizes.includes(p.size))
    .map((p) => p.size)
    .sort((a, b) => b - a);
  const pipeDict: { [pipeUUID: string]: IPipeProduct } = pipeProducts.reduce(
    (acc, pipe) => {
      acc[pipe.uuid] = pipe;
      return acc;
    },
    {},
  );
  const newEdges: IEdge[] = [];
  pocGraphs.forEach((dg: IPOCDirectedGraph) => {
    // const leafDict = dg.leafDescendantsOfNode(node)
    const leafDict: { [nodeID: string]: string[] } = dg.nodes.reduce(
      (acc, n) => {
        acc[n] = dg.leafDescendantsOfNode(n);
        return acc;
      },
      {},
    );
    const gpmDict: { [nodeID: string]: { [childID: string]: number } } =
      dg.nodes.reduce((acc, n) => {
        acc[n] = acc[n] || {};
        dg.graph[n].forEach((c) => {
          acc[n][c] = dg.getEdgeMaxGPM(n, c, pipeProducts, groups);
        });
        return acc;
      }, {});
    const leafs = dg.leafs();
    const mains = dg.getMains();
    const leafZoneMap: { [leafID: string]: Zone } = {};
    leafs.forEach((leaf) => {
      const zone = zones.find((z) => z.headIds.includes(leaf));
      if (zone) {
        leafZoneMap[leaf] = zone;
      }
    });

    const changeChildPipes = (node: string, size: number) => {
      const children = [...dg.graph[node]];
      if (!children || (children && !children.length)) return;
      children.forEach((child) => {
        changePipe(
          pipeProducts,
          node,
          child,
          size,
          isMain(mains, node),
          mainSeries,
          lateralSeries,
          edges,
          gpmDict,
        );
        changeChildPipes(child, size);
      });
    };
    const residualPressure = (uuid: string) => {
      const psi = Math.min(
        ...dg
          .lossesAtZone(
            leafZoneMap[uuid],
            pipeProducts,
            valveProducts,
            backflowProducts,
            edges,
          )
          .map((x) => x.residualPSI),
      );
      return psi;
    };
    const resize = (node: string, size: number) => {
      let sizes = lateralSizes;
      if (isMain(mains, node)) {
        sizes = mainSizes;
      }
      const currentSizes = sizes.filter((s) => s <= size);
      const bestSize = Math.max(...currentSizes);
      const currentSizeIndex = sizes.findIndex((s) => s === bestSize);
      // sometimes when going from main to laterals, the sizes don't match up
      let newSize = sizes[currentSizeIndex];
      let nextSize: number | undefined = undefined;
      let prevSize: number | undefined = undefined;
      if (sizes[currentSizeIndex + 1]) {
        nextSize = sizes[currentSizeIndex + 1];
      }
      if (sizes[currentSizeIndex - 1]) {
        prevSize = sizes[currentSizeIndex - 1];
      }
      if (!dg.graph[node] || !dg.graph[node].size) {
        // console.log('reached the leaf')
        return;
      }
      if (!sizes.includes(newSize)) {
        console.log(`reached the final size: ${newSize} is not in ${sizes}`);
        return;
      }
      changeChildPipes(node, newSize);
      for (let child of [...dg.graph[node]]) {
        let isSafe = true;
        const edge = findEdge(edges, node, child);
        if (edge) {
          const gpm = gpmDict[node][child];
          const pipe = pipeDict[edge.pipe];
          if (pipe && pipe.safeFlow < gpm) {
            isSafe = false;
          }
        }
        const hasNegativeResidual =
          !isSafe ||
          leafDict[node].some(
            (leaf) =>
              leafZoneMap[leaf] &&
              residualPressure(leaf) < MININUM_RESIDUAL_PRESSURE,
          );
        if (isSafe && !hasNegativeResidual) {
          // if good pressure, then try going one size smaller
          if (nextSize) {
            resize(node, nextSize);
          } else {
            changePipe(
              pipeProducts,
              node,
              child,
              newSize,
              isMain(mains, node),
              mainSeries,
              lateralSeries,
              edges,
              gpmDict,
            );
            resize(child, newSize);
          }
        } else {
          // otherwise, go back and set current pipe back up one size
          if (prevSize) {
            changePipe(
              pipeProducts,
              node,
              child,
              prevSize,
              isMain(mains, node),
              mainSeries,
              lateralSeries,
              edges,
              gpmDict,
            );
            changeChildPipes(child, prevSize);
          }
          resize(child, newSize);
        }
      }
      return;
    };
    const root = dg.pocRoot;
    if (root) {
      resize(root, mainSizes[0]);
    }
    newEdges.push(...edges);
  });
  // console.timeEnd('autosizepipes')
  return newEdges;
};
