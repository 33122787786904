import { InvoiceUI } from '@shared-types';
import { format, fromUnixTime } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Header, Table } from 'semantic-ui-react';
import { isSorted } from 'src/helpers/sortHelpers';
import { emptyStateHeight } from 'src/styles/common';
import styled from 'styled-components';
import { InvoiceSortCols } from '../../../../../shared-types/portal-types';

interface Props {
  invoices: InvoiceUI[];
}
export const InvoiceTable = ({ invoices }: Props) => {
  const [sortCol, setSortCol] = useState<InvoiceSortCols>('due_date');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [filteredInvoices, setFilteredInvoices] = useState<InvoiceUI[]>([]);
  const handleSortClick = (targetSortCol: InvoiceSortCols) => {
    if (targetSortCol === sortCol) {
      sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
    } else {
      setSortCol(targetSortCol);
    }
  };
  useEffect(() => {
    const openInvoices = invoices
      .filter((i) => i.status === 'open' || i.status === 'draft')
      .sort((a, b) =>
        sortOrder === 'desc'
          ? b[sortCol] - a[sortCol]
          : a[sortCol] - b[sortCol],
      );
    setFilteredInvoices(openInvoices);
  }, [invoices, sortCol, sortOrder]);
  const isOlderThan30 = (dueDate: number) =>
    new Date().getTime() - fromUnixTime(dueDate).getTime() >
    30 * 24 * 60 * 60 * 1000;
  return (
    <Wrap>
      <Header>Outstanding Invoices</Header>
      {!filteredInvoices.length && (
        <div className="empty-state">
          There are currently no open invoices on file.
        </div>
      )}
      {!!filteredInvoices.length && (
        <Table size="small" sortable striped={true} celled={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={isSorted(sortOrder, sortCol, 'dueDate')}
                onClick={() => handleSortClick('due_date')}
              >
                Due
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={isSorted(sortOrder, sortCol, 'amount_due')}
                onClick={() => handleSortClick('amount_due')}
              >
                Amount (USD)
              </Table.HeaderCell>
              <Table.HeaderCell className="unsortable">
                Invoice #
              </Table.HeaderCell>
              <Table.HeaderCell className="unsortable">
                Contractor Email
              </Table.HeaderCell>
              <Table.HeaderCell className="unsortable">
                Contractor Info
              </Table.HeaderCell>
              <Table.HeaderCell className="unsortable">
                Project
              </Table.HeaderCell>
              <Table.HeaderCell className="unsortable">Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredInvoices.map((invoice: InvoiceUI, i: number) => (
              <Table.Row
                key={i}
                warning={invoice.status === 'draft'}
                error={isOlderThan30(invoice.due_date)}
              >
                <Table.Cell>
                  {invoice.due_date
                    ? format(fromUnixTime(invoice.due_date), 'MMM d, yyyy')
                    : 'n/a'}
                </Table.Cell>
                <Table.Cell>
                  {(invoice.amount_due / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Table.Cell>
                <Table.Cell>{invoice.number}</Table.Cell>
                <Table.Cell>{`${invoice.customer_email || 'N/A'}`}</Table.Cell>
                <Table.Cell>
                  {`${invoice.customer_name || ''} ${
                    invoice.customer_phone || ''
                  }`}
                </Table.Cell>
                <Table.Cell>
                  {invoice.project && (
                    <Link to={`/app/projects/${invoice.project.id}`}>
                      {invoice.project.data.title}
                    </Link>
                  )}
                </Table.Cell>
                <Table.Cell warning={invoice.status === 'draft'}>
                  {invoice.status}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </Wrap>
  );
};
const Wrap = styled.div`
  flex: 1;
  overflow: auto;

  .empty-state {
    height: ${emptyStateHeight}px;
  }

  .unsortable {
    font-weight: normal !important;
  }
`;
