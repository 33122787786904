import { IPoint } from '@shared-types';
import paper from 'src/paper';
import { itemSizes } from 'src/shared';
import { getColor } from './plot.helpers';

export const createQuickCoupler = (
  point: IPoint,
  scale: number,
): paper.Item => {
  const hexScale = itemSizes.quickCouplerSize / scale;
  const g = new paper.Group();
  const p = new paper.Path(
    hexagonPoints().map((p) => new paper.Point(p.x * hexScale, p.y * hexScale)),
  );
  p.closePath();
  p.fillColor = getColor('white');
  p.strokeWidth = 0.5 / scale;
  p.strokeColor = getColor('black');

  const p2 = new paper.Path(
    hexagonPoints()
      .map((p) => new paper.Point(p.x * hexScale, p.y * hexScale))
      .slice(0, 4),
  );
  p2.fillColor = getColor('black');
  p2.strokeWidth = 0.5 / scale;
  p2.strokeColor = getColor('black');
  g.addChildren([p, p2]);
  g.position = new paper.Point(point);
  return g;
};

const hexagonPoints = (): IPoint[] => {
  const z = Math.sqrt(3) / 2;
  return [
    { x: -0.5, y: z },
    { x: 0.5, y: z },
    { x: 1, y: 0 },
    { x: 0.5, y: -z },
    { x: -0.5, y: -z },
    { x: -1, y: 0 },
  ];
};

/*
(1, 0)
(0.5, √3/2)
(-0.5, √3/2)
(-1, 0)
(-0.5, -√3/2)
(0.5, -√3/2)
*/
