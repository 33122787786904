import styled from 'styled-components';
import { ZonePanel } from '../features/zones/ZonePanel';
import { PropertiesPanel } from './PropertiesPanel';
import { SheetsPanel } from './SheetsPanel';
import { TabSystem } from './TabSystem';
import { LayerFolders } from './layers/LayerFolders';

export const RightMenu = () => {
  // const designMode = useDesignStore((s) => s.designMode);
  return (
    <Wrap>
      {/* {designMode === 'basemap' && <BasemapMenu />}
      {designMode === 'design' && <DesignMenu />}
      {designMode === 'export' && <ExportMenu />} */}
      <TabSystem
        tabs={[
          {
            name: 'Properties',
            content: (
              <>
                <LayerFolders />
                <PropertiesPanel />
              </>
            ),
          },
          {
            name: 'Sheets',
            content: <SheetsPanel />,
          },
          {
            name: 'Zones',
            content: <ZonePanel />,
          },
        ]}
        initialTab={'Properties'}
      />
    </Wrap>
  );
};

export const Wrap = styled.div`
  gap: 2px;
  cursor: default;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
