import {
  DesignElementMap,
  IEdge,
  IPipeProduct,
  IPipeSegment,
  IPoint,
  ISleeve,
  LineSegment,
  Polyline,
} from '@shared-types';
import paper from 'src/paper';
import {
  addPoints,
  getAngleBetweenPoints,
  getEuclideanDistance,
  getMidPoint,
  itemSizes,
  sleeveDiameters,
} from 'src/shared';
import { paperColors } from '../../../../vars';
import { defaultItem, getItemsByType, isPolyline } from '../../helpers';
import {
  closest,
  getDistanceFromEdge,
  isInsidePoly,
  linesIntersect,
} from '../../helpers/geometry.helpers';
import { getColor } from '../../paper-helpers/plot.helpers';
import { getPointsFromPolyline } from '../../polyline/polyline.service';
import { getState } from '../../state';
import { bulkAddSleeves } from './state/bulkAddSleeves';
import { deleteGeneratedSleeves } from './state/deleteGeneratedSleeves';

export const createSleeve = (
  start: IPoint,
  end: IPoint,
  scale: number,
): paper.Path => {
  const sleeveLeft = new paper.Path([
    new paper.Point(start),
    new paper.Point(end),
  ]);
  sleeveLeft.dashArray = itemSizes.sleeveDashArray(scale);
  sleeveLeft.strokeWidth = itemSizes.sleeveDiameter / scale;
  sleeveLeft.strokeColor = getColor(paperColors.pipe);
  return sleeveLeft;
};
export const moveSleeve = (sleeve: ISleeve, diff: IPoint): ISleeve => ({
  ...sleeve,
  lines: sleeve.lines.map((line) => ({
    start: addPoints(line.start, diff),
    end: addPoints(line.end, diff),
  })),
  pipe: {
    start: addPoints(sleeve.pipe.start, diff),
    end: addPoints(sleeve.pipe.end, diff),
    pipe: sleeve.pipe.pipe,
  },
});
export const getSleeveLines = (
  line: LineSegment,
  width: number,
): LineSegment[] => {
  const L = Math.sqrt(
    (line.start.x - line.end.x) * (line.start.x - line.end.x) +
      (line.start.y - line.end.y) * (line.start.y - line.end.y),
  );

  const offsetPixels = width;

  // This is the first line
  const x1p1 = line.start.x - (offsetPixels * (line.end.y - line.start.y)) / L;
  const x2p1 = line.end.x - (offsetPixels * (line.end.y - line.start.y)) / L;
  const y1p1 = line.start.y - (offsetPixels * (line.start.x - line.end.x)) / L;
  const y2p1 = line.end.y - (offsetPixels * (line.start.x - line.end.x)) / L;

  // This is the second line
  const x1p = line.start.x + (offsetPixels * (line.end.y - line.start.y)) / L;
  const x2p = line.end.x + (offsetPixels * (line.end.y - line.start.y)) / L;
  const y1p = line.start.y + (offsetPixels * (line.start.x - line.end.x)) / L;
  const y2p = line.end.y + (offsetPixels * (line.start.x - line.end.x)) / L;
  return [
    { start: { x: x1p1, y: y1p1 }, end: { x: x2p1, y: y2p1 } },
    { start: { x: x1p, y: y1p }, end: { x: x2p, y: y2p } },
  ];
};

const createSleeveRectangle = (sleeve: ISleeve): paper.Path.Rectangle => {
  const rect = new paper.Rectangle({
    topLeft: new paper.Point(sleeve.lines[0].start),
    bottomRight: new paper.Point(sleeve.lines[1].end),
  });
  const path = new paper.Path.Rectangle(rect);
  path.data = { sleeveUUID: sleeve.uuid };
  return path;
};

const linesAreMostlyParallel = (
  a1: IPoint,
  b1: IPoint,
  a2: IPoint,
  b2: IPoint,
) => {
  const angle1 = getAngleBetweenPoints(a1, b1);
  const angle2 = getAngleBetweenPoints(a2, b2);
  const angleDiff = Math.abs(angle1 - angle2);
  return angleDiff < 0.1;
};

export const combineSleeves = (sleeves: ISleeve[]): ISleeve[] => {
  const combine = (sleeves: ISleeve[], i: number): ISleeve[] => {
    // find intersection with another sleeve
    const otherSleeveRectangles = sleeves
      .filter((_, j) => j !== i)
      .map((sleeve) => ({
        sleeve,
        rectangle: createSleeveRectangle(sleeve),
      }));
    const currSleeve = sleeves[i];
    const currSleeveRectangle = createSleeveRectangle(currSleeve);
    const intersections = otherSleeveRectangles
      .filter(
        (path) =>
          linesAreMostlyParallel(
            currSleeve.pipe.start,
            currSleeve.pipe.end,
            path.sleeve.pipe.start,
            path.sleeve.pipe.end,
          ) && path.rectangle.intersects(currSleeveRectangle),
      )
      .map((p) => p.rectangle);
    if (!intersections.length) {
      if (i === sleeves.length - 1) {
        return sleeves;
      }
      return combine(sleeves, i + 1);
    }
    const sortedIntersections: paper.Path.Rectangle[] = intersections.sort(
      (a, b) =>
        getEuclideanDistance(
          a.bounds.center,
          currSleeveRectangle.bounds.center,
        ) -
        getEuclideanDistance(
          b.bounds.center,
          currSleeveRectangle.bounds.center,
        ),
    );

    const sleeveToJoin = sortedIntersections[0];
    const sleeveUUID: string = sleeveToJoin.data.sleeveUUID;
    const sleeveDataToJoin = sleeves.find((s) => s.uuid === sleeveUUID);
    if (!sleeveDataToJoin) {
      return sleeves;
    }

    const lines: LineSegment[] = [];

    // Get which currSleeve line to keep
    const testCurrSegments: LineSegment[] = [
      sleeveDataToJoin.lines[0].start,
      sleeveDataToJoin.lines[0].end,
      sleeveDataToJoin.lines[1].start,
      sleeveDataToJoin.lines[1].end,
    ].map((p) => ({
      start: currSleeve.lines[0].start,
      end: p,
    }));
    if (
      testCurrSegments.some((seg) => linesIntersect(seg, currSleeve.lines[1]))
    ) {
      // we started w/the outside line on the curr sleeve, keep it
      lines.push(currSleeve.lines[0]);
    } else {
      // we started on the inside, ditch it
      lines.push(currSleeve.lines[1]);
    }

    // get which new sleeve to keep
    const testNewSegments: LineSegment[] = [
      currSleeve.lines[0].start,
      currSleeve.lines[0].end,
      currSleeve.lines[1].start,
      currSleeve.lines[1].end,
    ].map((p) => ({
      start: sleeveDataToJoin.lines[0].start,
      end: p,
    }));
    if (
      testNewSegments.some((seg) =>
        linesIntersect(seg, sleeveDataToJoin.lines[1]),
      )
    ) {
      // we started w/the outside line on the curr sleeve, keep it
      lines.push(sleeveDataToJoin.lines[0]);
    } else {
      // we started on the inside, ditch it
      lines.push(sleeveDataToJoin.lines[1]);
    }
    const sleeveUUID2: string = sleeveToJoin.data.sleeveUUID;
    const sleeveDataToJoin2 = sleeves.find((s) => s.uuid === sleeveUUID2);
    if (!sleeveDataToJoin2) {
      return sleeves;
    }
    const newSleeveArr = updateSleeveArr(
      currSleeve,
      sleeveDataToJoin2,
      sleeves,
      lines,
    );
    // // if no intersections with sleeves, then return new sleeves
    // // try to combine sleeve with
    currSleeveRectangle.remove();
    otherSleeveRectangles.forEach((path) => path.rectangle.remove());
    return combine(newSleeveArr, 0);
  };

  const newSleeves = combine(sleeves, 0);
  return newSleeves;
};

const getSleeveableSegments = (
  edges: IEdge[],
  polylines: Polyline[],
  elementCache: DesignElementMap,
) => {
  const sleevableSegments: IPipeSegment[] = [];
  edges.forEach((edge) => {
    const sourceEl = elementCache[edge.source];
    const targetEl = elementCache[edge.target];
    if (sourceEl && targetEl) {
      const polyIntersectionPoints: IPoint[] = [];
      const polylinePoints: IPoint[][] = [];
      polylines.forEach((poly) => {
        const points = getPointsFromPolyline(poly);
        polylinePoints.push(points);
      });
      polylinePoints.forEach((points: IPoint[]) => {
        const polyEdges = generateLinesFromPoints(points);
        polyEdges.forEach((ls) => {
          const polyIntersection = linesIntersect(ls, {
            start: sourceEl.position,
            end: targetEl.position,
          });
          if (!!polyIntersection) {
            polyIntersectionPoints.push(polyIntersection as IPoint);
          }
        });
      });
      const sortedPolyIntersections = [...polyIntersectionPoints].sort(
        (a, b) =>
          getEuclideanDistance(a, sourceEl.position) -
          getEuclideanDistance(b, sourceEl.position),
      );
      sortedPolyIntersections.forEach((intersection, i, arr) => {
        if (i < arr.length - 1) {
          const nextIntersection = arr[i + 1];
          const midPoint = getMidPoint(nextIntersection, intersection);
          /*  
                if not 2 intersections, then do a slow check to see if mid point is
                in the middle of a yard or bed. doing this because we can't depend on
                even/odd numbers of intersections because a sleevable pipe
                could start and end potentially outside a yard/bed
              */
          if (
            edge.pipe &&
            (arr.length === 2 ||
              polylinePoints.every((points) => !isInsidePoly(midPoint, points)))
          ) {
            // another slow check. need to make sure the midpoint is not too close to the edge of the yard/bed
            if (
              polylinePoints.every(
                (points) => getDistanceFromEdge(points, midPoint) > 0.5,
              )
            ) {
              const ls: IPipeSegment = {
                start: intersection,
                end: nextIntersection,
                pipe: edge.pipe,
              };
              sleevableSegments.push(ls);
            }
          }
        }
      });
    }
  });
  return sleevableSegments;
};
const generateLinesFromPoints = (points: IPoint[]): LineSegment[] =>
  points.map((h: IPoint, i: number, arr: IPoint[]) => {
    const next = i === arr.length - 1 ? 0 : i + 1;
    return {
      start: { x: h.x, y: h.y },
      end: { x: arr[next].x, y: arr[next].y },
    };
  });

export const generateSleeves = (threshold: number) => {
  deleteGeneratedSleeves();
  const { edges, pipeProducts, scale, elementCache } = getState();
  const polylineYardsBeds = getItemsByType(isPolyline).filter(
    (p) => p.polyType === 'yard' || p.polyType === 'bed',
  );
  const sleevableSegments = getSleeveableSegments(
    edges,
    polylineYardsBeds,
    elementCache,
  );
  const sleeves = sleevesFromSegments(
    sleevableSegments,
    pipeProducts,
    scale,
    threshold,
  );
  if (sleeves.length) {
    const newSleeves = combineSleeves(sleeves);
    bulkAddSleeves(newSleeves);
  }
};
const sleevesFromSegments = (
  sleevableSegments: IPipeSegment[],
  pipeProducts: IPipeProduct[],
  scale: number,
  sleeveThreshold: number,
): ISleeve[] => {
  return sleevableSegments
    .filter((seg) => getEuclideanDistance(seg.start, seg.end) > sleeveThreshold)
    .map((seg) => {
      const segPipe = pipeProducts.find((p) => p.uuid === seg.pipe);
      const diameter = segPipe ? closest(sleeveDiameters, segPipe.size * 2) : 0;
      const width = (itemSizes.pipeSize / scale) * 2;
      const lines = getSleeveLines(seg, width);
      return {
        ...defaultItem({ x: seg.start.x, y: seg.start.y }),
        totalPipeDiameter: segPipe ? segPipe.size : 0,
        pipe: {
          start: seg.start,
          end: seg.end,
          pipe: segPipe ? segPipe.uuid : '',
        },
        width,
        diameter,
        lines,
        itemType: 'sleeve',
      };
    });
};
// const generateLinesFromPoints = (points: IPoint[]): LineSegment[] =>
//   points.map((h: IPoint, i: number, arr: IPoint[]) => {
//     const next = i === arr.length - 1 ? 0 : i + 1;
//     return {
//       start: { x: h.x, y: h.y },
//       end: { x: arr[next].x, y: arr[next].y },
//     };
//   });

const updateSleeveArr = (
  originalSleeve: ISleeve,
  sleeveToJoin: ISleeve,
  sleeves: ISleeve[],
  lines: LineSegment[],
): ISleeve[] => {
  const totalPipeDiameter =
    originalSleeve.totalPipeDiameter + sleeveToJoin.totalPipeDiameter;
  const diameter = closest(sleeveDiameters, totalPipeDiameter * 2);
  const newSleeve: ISleeve = {
    ...defaultItem({
      x: originalSleeve.pipe.start.x,
      y: originalSleeve.pipe.start.y,
    }),
    uuid: originalSleeve.uuid,
    pipe: {
      ...originalSleeve.pipe,
    },
    width: 0,
    totalPipeDiameter,
    diameter,
    lines,
    itemType: 'sleeve',
  };
  const newSleeveArr = sleeves.filter(
    (sleeve) =>
      sleeve.uuid !== sleeveToJoin.uuid && sleeve.uuid !== originalSleeve.uuid,
  );
  newSleeveArr.push(newSleeve);
  return newSleeveArr;
};
