import paper from 'src/paper';
import { itemSizes } from 'src/shared';
import { IPlant, IPoint } from '../../../../../shared-types/workbench-types';
import { PlantType } from '../../../shared/constants';
import { defaultItem } from '../helpers';
import { getColor } from '../paper-helpers/plot.helpers';
import { ITEMNAMES } from '../shared/workbench-enums';
import { getState } from '../state';
import { PaperItem } from '../tools/paper-items/paper-item';
import { plantSymbolCache } from './plants';

export const isPaperPlant = (item: PaperItem<any>): item is PaperPlant => {
  return item instanceof PaperPlant;
};
export class PaperPlant implements PaperItem<IPlant> {
  private plant: IPlant = {
    ...defaultItem({ x: 0, y: 0 }),
    gpm: 0,
    gph: 0,
    width: 0,
    name: PlantType.DECIDUOUS_TREE,
    itemType: 'plant',
  };
  private _outlineVisible = false;
  // private _gpmVisible = false
  private color: string = '#000';
  private _group = new paper.Group();

  get highlight() {
    return this._group.getItem({ name: 'highlight' });
  }
  get group() {
    return this._group;
  }
  get paperItem() {
    return this._group.getItem({ name: ITEMNAMES.PHOENIX_HEAD });
  }

  constructor(plant: IPlant, color?: string) {
    this.plant = plant;
    this.color = color || '#000';
    this.render(plant);
  }
  getRealItem(): IPlant {
    return this.plant;
  }
  getItem(): paper.Item {
    return this.group;
  }
  setPosition(point: IPoint): void {
    this.group.position = new paper.Point(point);
  }
  setPivot(point: IPoint): void {
    this.group.pivot = new paper.Point(point);
  }
  toggleHighlight(show: boolean): void {
    this.setSelectedStatus(show);
    this.toggleOutline(getState().showPlantOutlines || show);
  }
  setSelectedStatus(selected: boolean) {
    if (this.highlight) {
      this.highlight.remove();
    }
    if (selected) {
      const highlight = this.paperItem.clone();
      highlight.strokeWidth = 1;
      highlight.strokeColor = getColor('#94baf7');
      highlight.fillColor = null;
      highlight.name = 'highlight';
      highlight.locked = true;
      this._group.addChild(highlight);
    }
  }
  get paperOutline() {
    return this._group.getItem({ name: 'plant-outline' });
  }
  get paperZoneDot() {
    return this._group.getItem({ name: ITEMNAMES.PHOENIX_HEAD });
  }
  // get paperGPM() {
  //   return this.group.getItem({ name: ITEMNAMES.GPM_TEXT });
  // }
  destroy = () => {
    this._group.remove();
  };
  update = (plant: IPlant) => {
    if (
      this._outlineVisible &&
      (this.plant.width !== plant.width || this.plant.name !== plant.name)
    ) {
      this.paperOutline?.remove();
      const outline = this.generateOutline(plant);
      this._group.addChild(outline);
    }
    if (plant.visible !== this.plant.visible) {
      this._group.visible = plant.visible;
    }
    if (plant.locked !== this.plant.locked) {
      this._group.locked = plant.locked;
    }
    this.plant = plant;
  };
  updateColor = (color = '#000') => {
    this.color = color;
    this.colorZoneDot(color);
  };
  toggleOutline = (show: boolean) => {
    if (this._outlineVisible === show) return;
    this._outlineVisible = show;
    this.paperOutline?.remove();
    if (show) {
      const outline = this.generateOutline(this.plant);
      this._group.addChild(outline);
    }
  };
  private render = (item: IPlant) => {
    const { groups, zones } = getState();
    const zg = [...groups, ...zones].find((zg) =>
      zg.plantIds.includes(item.uuid),
    );
    if (zg) {
      this.color = zg.color;
    }
    this._group.removeChildren();
    this._group.opacity = 1;
    this._group.name = item.itemType;
    this._group.data = this.plant;
    const dot = this.generateZoneDot(this.plant, this.color);
    this._group.addChild(dot);
  };
  private generateOutline = (plant: IPlant): paper.SymbolItem => {
    const svg = plantSymbolCache[plant.name];
    const { scale } = getState();
    const width = plant.width * scale;
    const outline = svg.place(new paper.Point(plant.position));
    outline.scale(width / outline.bounds.width / scale);
    outline.name = 'plant-outline';
    return outline;
  };
  // private generateGPMText = (plant: IPlant): paper.PointText => {
  //   const roundedGPM = (plant.gpm || 0).toFixed(2)
  //   const gpmText = new paper.PointText(
  //     new paper.Point(plant.x + 1.2, plant.y - 1.2)
  //   )
  //   gpmText.content = `${roundedGPM} GPM`
  //   gpmText.fontWeight = 'bold'
  //   gpmText.fontSize = 1.2
  //   gpmText.name = ITEMNAMES.GPM_TEXT
  //   return gpmText
  // }
  private generateZoneDot = (
    plant: IPlant,
    color: string,
  ): paper.Shape.Circle => {
    const scale = getState().scale;
    const zoneDot = new paper.Shape.Circle(
      new paper.Point(plant.position),
      itemSizes.treeDot / scale,
    );
    zoneDot.name = ITEMNAMES.PHOENIX_HEAD;
    zoneDot.fillColor = getColor(color || '#000');
    zoneDot.opacity = 0.8;
    return zoneDot;
  };
  private colorZoneDot = (color: string) => {
    if (this.paperZoneDot) this.paperZoneDot.fillColor = getColor(color);
  };
}
