import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

interface ButtonActionProps {
  onClick: () => void;
  title: string;
  tooltip?: string;
}
export const ButtonAction = ({
  onClick,
  title,
  tooltip,
}: ButtonActionProps) => {
  return (
    <CheckboxWrap onClick={onClick}>
      <Icon name="plus" />
      {title}
      {!!tooltip && (
        <Popup
          content={tooltip}
          position="top center"
          trigger={<Icon className="popup-icon" name="question circle" />}
        />
      )}
    </CheckboxWrap>
  );
};
const CheckboxWrap = styled.div`
  cursor: pointer;
  user-select: none;
  .popup-icon {
    margin-left: 4px;
    color: #888;
  }
`;
