import { ProjectData, ProjectPOC } from '@shared-types';
import { scales } from '../Workbench/upload/upload.helpers';

export const projectType = [
  {
    id: 'Res-Small',
    text: 'Residential',
  },
  {
    id: 'Comm-Small',
    text: 'Commercial',
  },
  {
    id: 'Custom',
    text: 'Custom (Large residential, Large Commercial, multiple POCs, etc)',
    price: -1,
  },
];

export const basePOC: ProjectPOC = {
  staticPSI: 0,
  dynamicPSI: 0,
  gpm: 0,
  mains: [],
  source: null,
  name: 'Point of Connection',
};

export const baseProjectData: ProjectData = {
  companyName: '',
  firstName: '',
  lastName: '',
  companyAddress: '',
  companyCity: '',
  companyState: '',
  companyZip: '',
  siteAddress: '',
  siteCity: '',
  siteState: '',
  siteZip: '',
  title: '',
  projectNumber: '',
  poNumber: '',
  mainlinePipeType: '',
  mainlinePipeSize: 1.5,
  lateralPipeType: '',
  lateralPipeSize: 1.5,
  lateralValve: '',
  headBrand: '',
  dripValve: '',
  dripValveBrand: '',
  turfValve: '',
  turfValveBrand: '',
  backflow: '',
  controller: '',
  notes: '',
  headsNozzles: '',
  rush: false,
  scale: scales[2].value,
  size: projectType[0].id,
  contractorFiles: [],
  tracedBasemaps: [],
  designPackets: [],
  pocs: [basePOC],
  logos: [],
  acres: 1,
};
