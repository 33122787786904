import styled from 'styled-components';
import { irrigationBlue } from '../../../../vars';
import { DeleteButton, LockButton, VisibilityButton } from './ButtonWraps';

interface LayerChildProps {
  visible: boolean;
  locked: boolean;
  title: string;
  clickToggle: () => void;
  clickLock: () => void;
  layerLocked: boolean;
  layerVisible: boolean;
  clickDelete: () => void;
  clickItem?: () => void;
}
export const LayerChild = ({
  visible,
  locked,
  title,
  clickToggle,
  clickLock,
  layerLocked,
  layerVisible,
  clickItem,
  clickDelete,
}: LayerChildProps) => {
  return (
    <LayerChildWrap $clickable={!!clickItem}>
      <div
        className={`layer-item ${locked ? 'locked' : ''} ${
          !visible ? 'hidden' : ''
        }`}
      >
        <VisibilityButton
          disabled={layerLocked || !layerVisible}
          visible={visible}
          onClick={clickToggle}
        />
        <LockButton
          disabled={layerLocked || !layerVisible}
          locked={locked}
          onClick={clickLock}
        />
        <div className="name" onClick={clickItem}>
          <span>{title}</span>
        </div>
        <DeleteButton
          disabled={layerLocked || !layerVisible}
          onClick={clickDelete}
        />
      </div>
    </LayerChildWrap>
  );
};

const LayerChildWrap = styled.div<{ $clickable: boolean }>`
  .layer-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #555;
    .name {
      padding-left: 24px;
      user-select: none;
      display: block;
      opacity: 0.8;
      flex: 1;
      ${(props) => (props.$clickable ? 'cursor: pointer;' : '')}
      &:hover {
        ${(props) => (props.$clickable ? `color: ${irrigationBlue};` : '')}
      }
    }

    &.locked {
      .name {
        text-decoration: line-through;
      }
    }
  }
`;
