import React from 'react';
import {
  DesignElement,
  DesignSprinklerElement,
} from '../../../../../../shared-types';
import { paperItemStore } from '../../helpers';
import { localPaper } from '../../localPaper';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import {
  clearSelectedIDs,
  clearSelectedItems,
  selectItems,
  setShowHeadPreviews,
  toggleArcsOff,
  toggleArcsOn,
  toggleGPM,
  useDesignStore,
} from '../../state';
import { changeElements } from '../../state/changeElements';
import { deleteElements } from '../../state/deleteElements';
import { hideOutOfBounds } from '../../tools/pan.tool';
import {
  isPaperSprinkler,
  isSprinkler,
} from '../../tools/paper-items/paper-sprinkler';
import { CheckboxAction } from './CheckboxAction';
import { LayerChild } from './LayerChild';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const HeadsLayer = ({ open, onOpen }) => {
  const showArcs = useDesignStore((state) => state.showArcs);
  const showGPM = useDesignStore((state) => state.showGPM);
  const showHeadPreviews = useDesignStore((state) => state.showHeadPreviews);
  const heads = useDesignStore((state) => state.elements.filter(isSprinkler));
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.HEADS,
  );

  const toggleShowArcs = () => {
    if (showArcs) {
      toggleArcsOff();
    } else {
      toggleArcsOn();
    }
  };

  const toggleShowGPM = () => {
    toggleGPM();
  };
  const toggleHeadPreview = () => {
    setShowHeadPreviews(!showHeadPreviews);
  };
  const toggleChildVisibility = (head: DesignSprinklerElement) => {
    changeElements([{ ...head, visible: !head.visible }]);
  };

  const toggleChildLock = (head: DesignSprinklerElement) => {
    changeElements([{ ...head, locked: !head.locked }]);
  };
  const clickEl = (el: DesignElement) => {
    clearSelectedIDs();
    clearSelectedItems();
    localPaper.project.deselectAll();
    const item = paperItemStore.get(el.uuid);
    if (item) {
      if (isPaperSprinkler(item)) {
        selectItems([item.getItem()]);
        item.getItem().selected = true;
        localPaper.project.view.center = item.getItem().position;
        hideOutOfBounds();
      }
    }
  };

  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Heads (${heads.length})`}
      open={open}
      onOpen={onOpen}
      actions={
        <>
          <CheckboxAction
            title="Arcs"
            onClick={toggleShowArcs}
            checked={showArcs}
          />
          <CheckboxAction
            title="GPM"
            onClick={toggleShowGPM}
            checked={showGPM}
          />
          <CheckboxAction
            title="Preview"
            onClick={toggleHeadPreview}
            checked={showHeadPreviews}
          />
        </>
      }
      childLayer={heads.map((head) => (
        <LayerChild
          key={head.uuid}
          visible={head.visible}
          locked={head.locked}
          title={`${head.props.base.headModel} ${head.props.base.nozzleSeries}`}
          clickToggle={() => toggleChildVisibility(head)}
          clickLock={() => toggleChildLock(head)}
          clickDelete={() => deleteElements([head.uuid])}
          clickItem={() => clickEl(head)}
          layerLocked={layerLocked}
          layerVisible={layerVisible}
        />
      ))}
    />
  );
};

export default React.memo(HeadsLayer);
