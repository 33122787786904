import { PDFDocument } from 'pdf-lib';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { getPdfMakeBuffer } from '../../components/saveAndDownloadPDF';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export async function createPDFMakePage(
  definitionFunc: () => Promise<TDocumentDefinitions>,
  getContent: () => Promise<Content[]>,
): Promise<PDFDocument | void> {
  const definition = await definitionFunc();
  definition.content = await getContent();
  if (!definition.content.length) return;
  const newPDF = await getPdfMakeBuffer(definition);
  return await PDFDocument.load(newPDF);
}
