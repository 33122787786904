import styled from 'styled-components';
import { useDesignStore } from '../state';
import { TreeDiagram } from './TreeDiagram';

export const FullTreeDiagrams = () => {
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  return (
    <Wrap>
      <div className="tree">
        {pocGraphs.map((_, i) => (
          <TreeDiagram classTitle={`full-tree`} key={i} graphIndex={i} />
        ))}
      </div>
    </Wrap>
  );
};
const Wrap = styled.div`
  .tree {
    margin-bottom: 16px;
  }
`;
