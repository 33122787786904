import { takeUntil } from 'rxjs';
import paper from 'src/paper';
import { itemSizes } from '../../../shared';
import { newUUID } from '../crypto-uuid';
import { PaperEvType } from '../custom-events';
import { getColor } from '../paper-helpers/plot.helpers';
import { getState } from '../state';
import { addValveBoxLocation } from '../state/valvebox/addValveBoxLocation';
import { MouseDownPayload, ToolService } from '../tool.service';

export const setupValveBoxLocationTool = (toolService: ToolService) => {
  let dragging = false;
  let scale = getState().scale;
  const newMiscTool = new paper.Group();
  newMiscTool.name = 'vb-tool';
  const line = new paper.Path.Line(
    new paper.Point(0, 2),
    new paper.Point(4, 2),
  );
  line.strokeColor = getColor('black');
  line.strokeWidth = itemSizes.pipeSize / scale;
  line.dashArray = itemSizes.mainDashArray(scale);
  const arrowOut = new paper.Path.RegularPolygon(
    new paper.Point(4, 2.1),
    3,
    0.5,
  );
  const arrowIn = new paper.Path.RegularPolygon(
    new paper.Point(0, 2.1),
    3,
    0.5,
  );
  arrowIn.strokeWidth = 0.1;
  arrowIn.strokeColor = getColor('white');
  arrowOut.strokeWidth = 0.1;
  arrowOut.strokeColor = getColor('white');
  // const leftSide = new paper.Group()
  // leftSide.name = 'left-side'
  // const dot = new paper.Path.Circle()
  arrowOut.rotate(90);
  arrowIn.rotate(90);
  arrowOut.fillColor = getColor('black');
  arrowIn.fillColor = getColor('black');

  newMiscTool.addChildren([line, arrowOut, arrowIn]);
  newMiscTool.applyMatrix = false;
  newMiscTool.rotation = 0;
  newMiscTool.visible = false;
  newMiscTool.pivot = new paper.Point(0, 2);
  let location: paper.Point | undefined;
  window.addEventListener(PaperEvType.CHANGE_TOOL, () => {
    newMiscTool.visible = false;
  });

  const onKeyUp = (e) => {
    const currentFocus = document.activeElement;
    if (
      currentFocus &&
      (currentFocus.tagName === 'INPUT' || currentFocus.tagName === 'TEXTAREA')
    ) {
      return;
    }
    if (e.key === 'escape') {
      newMiscTool.visible = false;
      location = undefined;
    }
  };

  const onMouseMove = (e: paper.ToolEvent) => {
    newMiscTool.position = e.point;
    newMiscTool.visible = true;
  };

  const onMouseDown = ({ e }: MouseDownPayload) => {
    location = e.point;
    newMiscTool.position = e.point;
  };

  const onMouseDrag = (e) => {
    if (e.modifiers.space) {
      dragging = true;
      // pan(e);
    } else {
      const vec = e.point.subtract(location);
      newMiscTool.rotation = vec.angle;
    }
  };

  const onMouseUp = (e) => {
    if (dragging) {
      dragging = false;
    } else {
      if (location) {
        addValveBoxLocation(
          newUUID(),
          3,
          { x: location.x, y: location.y },
          -newMiscTool.rotation,
        );
        newMiscTool.position = e.point;
        newMiscTool.visible = false;
        location = e.point;
      }
    }
  };
  toolService
    .mouseUp$('Valve Location')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseUp);
  toolService
    .mouseDrag$('Valve Location')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseDrag);
  toolService
    .mouseMove$('Valve Location')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseMove);
  toolService
    .mouseDown$('Valve Location')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseDown);
  toolService
    .keyUp$('Valve Location')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onKeyUp);
};
