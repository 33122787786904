import { RouterProvider } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { PortalProvider } from 'src/contexts/portal/portal';
import './App.css';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ProfileProvider } from './contexts/profile/profile';
import ModalService from './pages/Workbench/state/modal.service';
import { router } from './router';

// import { BasemapValidation } from './pages/Workbench/components/BasemapValidation'
// import ReactGA from 'react-ga'
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

export const googleTagEvent = (event: string) => {
  (window as any).dataLayer.push({ event });
};

const App = () => {
  return (
    <PortalProvider>
      <ProfileProvider>
        <ModalService />
        <ErrorBoundary>
          {/* <Elements stripe={stripePromise}> */}
          <RouterProvider router={router} />
          {/* </Elements> */}
        </ErrorBoundary>
      </ProfileProvider>
    </PortalProvider>
  );
};

export default App;
