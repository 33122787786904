import { IPoint } from '@shared-types';
import paper from 'src/paper';
import { itemSizes } from 'src/shared';
import { getColor } from './plot.helpers';

export const createDripTransition = (
  point: IPoint,
  scale: number,
): paper.Item => {
  const g = new paper.Group();
  const p = new paper.Path([
    new paper.Point(31.056, 23.629),
    new paper.Point(39.73, 23.629),
    new paper.Point(39.73, 0),
    new paper.Point(31.056, 0),
    new paper.Point(31.056, 7.478),
    new paper.Point(8.673, 7.478),
    new paper.Point(8.673, 0),
    new paper.Point(0, 0),
    new paper.Point(0, 23.629),
    new paper.Point(8.673, 23.629),
    new paper.Point(8.673, 16.151),
    new paper.Point(31.056, 16.151),
    new paper.Point(31.056, 23.629),
  ]);
  p.fillColor = getColor('black');
  p.scale(1 / itemSizes.quickCouplerSize / scale);
  g.addChild(p);
  g.position = new paper.Point(point);
  return g;
};
