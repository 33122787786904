import { hideNamedLayer, showNamedLayer } from '../paper-helpers/plot.helpers';
import { LAYER_NAMES } from '../shared/workbench-enums';
import {
  setShowGPM,
  setShowHeadArcs,
  setShowHeadPreviews,
  setShowPipeLabels,
  setShowPlantOutlines,
  setShowSheetPreviews,
  setShowValveBoxPreviews,
  setShowValveInfoBoxNumbers,
} from '../state';

export interface Preset {
  name: string;
  config: {
    layersToShow: LAYER_NAMES[];
    actions: PRESET_ACTIONS[];
  };
}

export enum PRESET_ACTIONS {
  showPipeLabels = 'showPipeLabels',
  headArcs = 'headArcs',
  headPreview = 'headPreview',
  plantOutlines = 'plantOutlines',
  sheetPreview = 'sheetPreview',
  valveInfoBoxNumbers = 'valveInfoBoxNumbers',
  headGPM = 'headGPM',
  showValveBoxPreviews = 'showValveBoxPreviews',
}

export const LayerList: { layerName: LAYER_NAMES; readable: string }[] = [
  { layerName: LAYER_NAMES.DEFAULT, readable: 'Default' },
  { layerName: LAYER_NAMES.SVG, readable: 'SVG' },
  { layerName: LAYER_NAMES.HEADS, readable: 'Heads' },
  { layerName: LAYER_NAMES.ELEMENTS, readable: 'Elements' },
  { layerName: LAYER_NAMES.EDGES, readable: 'Pipes' },
  { layerName: LAYER_NAMES.COVERAGE, readable: 'Coverage' },
  { layerName: LAYER_NAMES.PLANTS, readable: 'Plants' },
  { layerName: LAYER_NAMES.VALVE_INFO_BOXES, readable: 'Valve Info Boxes' },
  { layerName: LAYER_NAMES.VALVE_BOXES, readable: 'Valve Boxes' },
  { layerName: LAYER_NAMES.LEGEND, readable: 'Legend' },
  { layerName: LAYER_NAMES.SHEETS, readable: 'Sheets' },
  { layerName: LAYER_NAMES.TRACING, readable: 'Tracing' },
  { layerName: LAYER_NAMES.OUTLINES, readable: 'Outlines' },
  { layerName: LAYER_NAMES.ELEVATION, readable: 'Elevation' },
  { layerName: LAYER_NAMES.BASEMAPS, readable: 'Basemaps' },
  { layerName: LAYER_NAMES.SLEEVES, readable: 'Sleeves' },
];

export const actionsHumanReadable: { [key in PRESET_ACTIONS]: string } = {
  showPipeLabels: 'Show Pipe Labels',
  headArcs: 'Head Arcs',
  headPreview: 'Head Previews',
  plantOutlines: 'Plant Outlines',
  sheetPreview: 'Sheet Previews',
  valveInfoBoxNumbers: 'Valve Info Box Numbers',
  headGPM: 'Head GPM',
  showValveBoxPreviews: 'Show Valve Box Previews',
};
export const actionList: PRESET_ACTIONS[] = [
  PRESET_ACTIONS.showPipeLabels,
  PRESET_ACTIONS.headArcs,
  PRESET_ACTIONS.headPreview,
  PRESET_ACTIONS.plantOutlines,
  PRESET_ACTIONS.sheetPreview,
  PRESET_ACTIONS.valveInfoBoxNumbers,
  PRESET_ACTIONS.headGPM,
];

const resetActions = () => {
  for (const action of actionList) {
    toggleActions[action](false);
  }
};
const hideAllLayers = () => {
  Object.values(LAYER_NAMES).forEach((layer) => {
    hideNamedLayer(layer);
  });
};

export const toggleActions: {
  [key in PRESET_ACTIONS]: (activate: boolean) => void;
} = {
  showPipeLabels: (activate: boolean) => setShowPipeLabels(activate),
  headArcs: (activate: boolean) => setShowHeadArcs(activate),
  headPreview: (activate: boolean) => setShowHeadPreviews(activate),
  plantOutlines: (activate: boolean) => setShowPlantOutlines(activate),
  sheetPreview: (activate: boolean) => setShowSheetPreviews(activate),
  valveInfoBoxNumbers: (activate: boolean) =>
    setShowValveInfoBoxNumbers(activate),
  headGPM: (activate: boolean) => setShowGPM(activate),
  showValveBoxPreviews: (activate: boolean) =>
    setShowValveBoxPreviews(activate),
};

export const applyPreset = (preset: Preset) => {
  resetActions();
  hideAllLayers();
  preset.config.layersToShow.forEach((layer) => {
    showNamedLayer(layer);
  });
  preset.config.actions.forEach((action) => toggleActions[action](true));
};
