import { IMeter, IPump } from '@shared-types';
import { Table } from 'semantic-ui-react';
import { getPOCData } from '../../../helpers/directedGraph';
import { getState } from '../../../state';

interface TreeLossTableProps {
  treeIndex: number;
}
export const POCPressureTable = ({ treeIndex }: TreeLossTableProps) => {
  const { elements, pipeProducts, elementCache, pocGraphs } = getState();
  const {
    poc,
    pump,
    calculatedGPM,
    meter,
    root,
    meterLosses,
    meterElevationLoss,
    mainsLoss,
    calculatedPSI,
  } = getPOCData(treeIndex, elements, pipeProducts, elementCache, pocGraphs);
  return (
    <div>
      {poc && (
        <Table size="small" compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">POC: {poc.name}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell positive={!!poc.measuredGPM}>Measured GPM</Table.Cell>
              <Table.Cell positive={!!poc.measuredGPM}>
                {poc.measuredGPM || 'not measured'}
              </Table.Cell>
            </Table.Row>
            {pump && (
              <>
                <Table.Row>
                  <Table.Cell>Pump PSI</Table.Cell>
                  <Table.Cell>{(pump.props as IPump).outputPSI}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Pump GPM</Table.Cell>
                  <Table.Cell>{(pump.props as IPump).outputGPM}</Table.Cell>
                </Table.Row>
              </>
            )}

            <Table.Row>
              <Table.Cell>Calculated GPM</Table.Cell>
              <Table.Cell>{calculatedGPM}</Table.Cell>
            </Table.Row>
            {meter && root && (
              <>
                <Table.Row>
                  <Table.Cell colSpan="2">
                    This POC is connected to a meter. GPM is calculated using{' '}
                    {poc.measuredGPM
                      ? 'the measured GPM at the POC.'
                      : `the minimum value when calculating the max safe velocity in either the meter, the service line, or the mains leading to the POC. This is because a GPM measurement at the POC was not provided.`}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Meter Size</Table.Cell>
                  <Table.Cell>{(meter.props as IMeter).meterSize}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Meter Loss</Table.Cell>
                  <Table.Cell>
                    {meterLosses ? meterLosses.meterLoss.toFixed(2) : 0}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Meter Service Line Loss</Table.Cell>
                  <Table.Cell>
                    {meterLosses ? meterLosses.serviceLineLoss.toFixed(2) : 0}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Meter Elevation Loss</Table.Cell>
                  <Table.Cell>{meterElevationLoss.toFixed(2)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Meter to POC Mains Loss</Table.Cell>
                  <Table.Cell>{mainsLoss.toFixed(2)}</Table.Cell>
                </Table.Row>
              </>
            )}

            <Table.Row>
              <Table.Cell>Static PSI</Table.Cell>
              <Table.Cell>{poc.staticPSI}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell positive={!poc.measuredPSI}>
                Calculated PSI
              </Table.Cell>
              <Table.Cell positive={!poc.measuredPSI}>
                {calculatedPSI.toFixed(2)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell positive={!!poc.measuredPSI}>
                Measured (Dynamic) PSI
              </Table.Cell>
              <Table.Cell positive={!!poc.measuredPSI}>
                {poc.measuredPSI || 'not measured'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="2">
                If a dynamic PSI reading is measured on-site, that number will
                be used as the working PSI of the system. Otherwise the
                calculated PSI (static PSI minus all losses up to POC) will be
                used.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </div>
  );
};
