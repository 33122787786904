import { CustomSymbolType } from '@shared-types';
import paper from 'src/paper';

export const createCustomSymbolItem = (
  symbolType: CustomSymbolType,
): paper.Item => {
  switch (symbolType) {
    case 'rock':
      const rock1 = new paper.Path();
      rock1.pathData =
        'M3.119.226c-.945.315-1.8.095-2.036,1.089s-.284,1.8-.616,2.51-.189,1.705-.189,2.321-.284.947.237,1.373.995.947,1.373.9.852.284,1.421.284,1.516.142,1.989-.474.9-.663.995-1.231-.047-1.942.189-2.557.616-.852.616-1.658.047-1.279-.237-1.61-1.373-.663-1.847-.758-1.61-.284-1.894-.189Z';
      const rock2 = new paper.Path();
      rock2.pathData =
        'M2.598,1.362c-.234.327-.805.332-.852,1.137s.142.758.047,1.468-.237.805-.189,1.421-.379.758.284,1.231.947.521,1.279.71.947.237,1.279-.047.9-.9.995-1.563-.237-.663.142-1.326.616-.995.663-1.61.047-.521-.379-.9-.758-.521-1.231-.521-1.8-.332-2.036,0Z';
      const rock3 = new paper.Path();
      rock3.pathData =
        'M3.308,2.025c-.485.607-.616.379-.663,1.184s-.426.474-.047,1.089.426.852,1.042.758.852-.095,1.089-.521.71-.426.568-1.373.142-.947-.426-1.089-1.373-.284-1.563-.047Z';
      const rock = new paper.Group([rock1, rock2, rock3]);
      rock.strokeWidth = 1;
      return rock;
    case 'paver':
      const paver = new paper.Path([
        { x: 0.152, y: 3.949 },
        { x: 1.152, y: 1.222 },
        { x: 3.652, y: 0.131 },
        { x: 6.152, y: 0.676 },
        { x: 6.152, y: 3.403 },
        { x: 3.652, y: 6.131 },
      ]);
      paver.closed = true;
      paver.strokeWidth = 1;
      return paver;
    default:
      return new paper.Path.Circle(new paper.Point(0, 0), 10);
  }
};
