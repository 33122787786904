import { InvoiceUI } from '@shared-types';
import { Query } from '../../../shared-types/portal-types';
import { wrapFetch, wrapFetchWithQuery } from './fetchHelpers';

export const getAllInvoices = (
  query?: Query,
): Promise<{ invoices: InvoiceUI[]; total: number }> =>
  wrapFetchWithQuery(`/api/invoices/`, 'GET', query);

export const getInvoices = (
  customerId: string,
): Promise<{ invoices: InvoiceUI[] }> =>
  wrapFetch(`/api/invoices/${customerId}`);

export const getProjectInvoices = (
  projectId: number,
): Promise<{ invoices: InvoiceUI[] }> =>
  wrapFetch(`/api/invoices/project/${projectId}`);

export const createProjectInvoice = (
  projectId: number,
  amount: number,
  description: string,
  dueDate: number,
): Promise<{ invoice: InvoiceUI }> =>
  wrapFetch(`/api/invoices/project`, 'POST', {
    projectId,
    amount,
    description,
    dueDate,
  });

export const createBulkContractInvoice = (
  bulkContractId: number,
  amount: number,
  description: string,
  dueDate: number,
): Promise<{ invoice: InvoiceUI }> =>
  wrapFetch(`/api/invoices/bulk-contract`, 'POST', {
    bulkContractId,
    amount,
    description,
    dueDate,
  });

export const payInvoice = (
  stripeInvoiceId: string,
): Promise<{ invoices: InvoiceUI[] }> =>
  wrapFetch(`/api/invoices/pay`, 'PUT', { stripeInvoiceId });

export const markInvoiceAsPaid = (
  stripeInvoiceId: string,
): Promise<{ invoices: InvoiceUI[] }> =>
  wrapFetch(`/api/invoices/pay`, 'PUT', { stripeInvoiceId, outOfBand: true });

export const voidInvoice = (
  stripeInvoiceId: string,
): Promise<{ invoices: InvoiceUI[] }> =>
  wrapFetch(`/api/invoices/void`, 'PUT', { stripeInvoiceId });

export const finalizeInvoice = (
  stripeInvoiceId: string,
): Promise<{ invoices: InvoiceUI[] }> =>
  wrapFetch(`/api/invoices/finalize`, 'POST', { stripeInvoiceId });

export const deleteDraftInvoice = (
  stripeInvoiceId: string,
): Promise<{ deleted: boolean }> =>
  wrapFetch(`/api/invoices/delete-draft`, 'POST', { stripeInvoiceId });
