import { DesignElement, IEdge, IPoint, ValveBox, Zone } from '@shared-types';
import { useState } from 'react';
import { Confirm, Dropdown } from 'semantic-ui-react';
import _ from 'underscore';
import { autoSizePipes, fixPipeDirections } from '../../helpers/directedGraph';
import { findGroupings, isInsidePoly } from '../../helpers/geometry.helpers';
import { bisectPipe, createDefaultEdge } from '../../paper-helpers/edges';
import { getPolylineYards } from '../../polyline/polyline-helpers';
import { getPointsFromPolyline } from '../../polyline/polyline.service';
import { postMST } from '../../services/design.service';
import {
  getEuclideanDistance,
  getMidPoint,
  traversePoly,
} from '../../shared/geometry';
import {
  deleteOrphanedFittings,
  getState,
  reverseEdges,
  useDesignStore,
} from '../../state';
import { addElementsEdges } from '../../state/addElementsEdges';
import { changeEdges } from '../../state/changeEdges';
import { changeElements } from '../../state/changeElements';
import { deleteEdges } from '../../state/deleteEdges';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';

export const getLateralPipeProduct = () => {
  const { pipeProducts, lateralPipe, lateralPipeSizes } = getState();
  return pipeProducts.find(
    (p) => p.series === lateralPipe && p.size >= Math.min(...lateralPipeSizes),
  );
};
export const findLines = async () => {
  // const es: IEdge[] = []
  const pipe = getLateralPipeProduct();
  const zones = getState().zones;
  for (let i = 0; i < zones.length; i++) {
    const zone = zones[i];
    const heads = zone.headIds
      .map((id) => getState().elementCache[id])
      .filter((h) => isSprinkler(h) && h.props.angle === 360);
    const groups = findGroupings(heads);
    const grouped = new Set<string>();
    for (let j = 0; j < groups.length; j++) {
      const group = groups[j];
      if (group.every((g) => !grouped.has(g.uuid))) {
        group.forEach((g) => grouped.add(g.uuid));
        const res = await postMST(group);
        res.edges.forEach((edge) => {
          const e: IEdge = {
            ...edge,
            preExisting: false,
            pipe: pipe ? pipe.uuid : '',
            hoppedSections: [],
            isLateral: true,
            isDrip: false,
            showLabel: false,
          };
          addElementsEdges([], [e]);
        });
      }
    }
  }
};
export const doAutoSize = () => {
  const {
    pipeProducts,
    lateralPipe,
    mainPipe,
    lateralPipeSizes,
    mainPipeSizes,
    valveProducts,
    backflowProducts,
    groups,
    edges,
    zones,
  } = getState();
  const edgesToChange = autoSizePipes(
    valveProducts,
    backflowProducts,
    zones,
    pipeProducts,
    edges,
    groups,
    lateralPipe,
    lateralPipeSizes,
    mainPipe,
    mainPipeSizes,
  );
  console.log('edges to change', edgesToChange.length);

  changeEdges(edgesToChange);
};
export const doFixPipeDirections = () => {
  const edges = getState().edges;
  const pocGraphs = getState().pocGraphs;
  const edgesToChange = fixPipeDirections(edges, pocGraphs);
  console.log(`${edgesToChange.length} edges to change`);
  reverseEdges(edgesToChange);
};
export const connectEdgeHeads = () => {
  const { elements, zones } = getState();
  const polylineYards = getPolylineYards();
  const partialHeads = elements.filter(
    (h) => isSprinkler(h) && h.props.angle < 360,
  );
  let es: IEdge[] = [];
  polylineYards.forEach((polyine) => {
    const polyYardPoints = getPointsFromPolyline(polyine);
    const perimeter = polyYardPoints.reduce((acc, point, i) => {
      const nextPoint = polyYardPoints[(i + 1) % polyYardPoints.length];
      const edge = [point, nextPoint];
      const d = getEuclideanDistance(edge[0], edge[1]);
      return acc + d;
    }, 0);
    let encountered = new Set<string>();
    let lastZone: Zone | undefined = undefined;
    let lastHead: DesignElement | undefined = undefined;
    const pipe = getLateralPipeProduct();
    for (let i = 0; i < perimeter; i++) {
      const p = traversePoly(polyYardPoints, i, true, 0);
      if (p) {
        const closeIsh = partialHeads.filter(
          (h) =>
            isInsidePoly(h.position, polyYardPoints) &&
            getEuclideanDistance(h.position, p) < 3,
        );
        const nearest = _.sortBy(closeIsh, (h) =>
          getEuclideanDistance(h.position, p),
        )[0];
        if (nearest && !encountered.has(nearest.uuid)) {
          const zone = zones.find((z) => z.headIds.includes(nearest.uuid));
          encountered.add(nearest.uuid);
          if (zone) {
            if (lastZone && zone.uuid === lastZone.uuid && lastHead) {
              // still in the current zone
              if (lastHead) {
                const e = createDefaultEdge({
                  source: lastHead.uuid,
                  target: nearest.uuid,
                  pipe: pipe ? pipe.uuid : '',
                  hoppedSections: [],
                  isLateral: true,
                });
                lastHead = nearest;
                lastZone = zone;
                es.push(e);
              } else {
                lastHead = nearest;
                lastZone = zone;
              }
            } else {
              // new zone
              lastHead = nearest;
              lastZone = zone;
            }
          }
        }
      }
    }
  });
  addElementsEdges([], es);
};
export const cleanupEdges = () => {
  const polylineYards = getPolylineYards();
  const edges = getState().edges;

  polylineYards.forEach((polyline) => {
    const polyYardPoints = getPointsFromPolyline(polyline);
    for (let i = 0; i < edges.length; i++) {
      const edge = edges[i];
      const p1 = getState().elementCache[edge.source];
      const p2 = getState().elementCache[edge.target];
      if (p1 && p2) {
        const mid = getMidPoint(p1.position, p2.position);
        if (
          isInsidePoly(p1.position, polyYardPoints) &&
          isInsidePoly(p2.position, polyYardPoints) &&
          !isInsidePoly(mid, polyYardPoints)
        ) {
          const newFitting = bisectPipe(edge, mid);
          // move newFitting perpendicular to the edge until it is inside yard
          const newFittingPoint = getState().elementCache[newFitting];
          if (newFittingPoint) {
            const edgeVector = getVector(p1, p2);
            const normalVector = getNormalVector(edgeVector);
            let amount = 0.01;
            for (let i = 0; i < 30; i++) {
              amount = amount + 0.01;
              const newFittingPoint2 = getPointFromVector(
                newFittingPoint,
                normalVector,
                amount,
              );
              if (isInsidePoly(newFittingPoint2, polyYardPoints)) {
                newFittingPoint.position.x = newFittingPoint2.x;
                newFittingPoint.position.y = newFittingPoint2.y;
                changeElements([
                  {
                    ...newFittingPoint,
                  },
                ]);
                break;
              } else {
                const newFittingPoint3 = getPointFromVector(
                  newFittingPoint,
                  normalVector,
                  -amount,
                );
                if (isInsidePoly(newFittingPoint3, polyYardPoints)) {
                  newFittingPoint.position.x = newFittingPoint3.x;
                  newFittingPoint.position.y = newFittingPoint3.y;
                  changeElements([
                    {
                      ...newFittingPoint,
                    },
                  ]);
                  break;
                }
              }
            }
          }
        }
      }
    }
  });
};

const getVector = (p1: DesignElement, p2: DesignElement) => {
  return {
    x: p2.position.x - p1.position.x,
    y: p2.position.y - p1.position.y,
  };
};
const getNormalVector = (v: IPoint) => {
  return {
    x: -v.y,
    y: v.x,
  };
};
const getPointFromVector = (p: DesignElement, v: IPoint, scale: number) => {
  return {
    x: p.position.x + v.x * scale,
    y: p.position.y + v.y * scale,
  };
};

export const Pipes = () => {
  const edges = useDesignStore((state) => state.edges);
  const zones = useDesignStore((state) => state.zones);
  const valveBoxes = useDesignStore((state) => state.valveBoxes);
  const [deletePipesOpen, setDeletePipesOpen] = useState(false);
  const [deleteOrphansOpen, setDeleteOrphansOpen] = useState(false);

  const deleteAllEdges = () => {
    const valveBoxFittings = valveBoxes.reduce(
      (acc: string[], vb: ValveBox) => [
        ...acc,
        vb.inputFitting,
        vb.outputFitting,
      ],
      [],
    );
    const valveFittings = zones.reduce(
      (acc: string[], zone: Zone) => [
        ...acc,
        zone.valveInputFitting,
        zone.valveOutputFitting,
        zone.valve,
      ],
      [],
    );
    const fittings = [...valveBoxFittings, ...valveFittings];
    const edgesToDelete = edges.filter(
      (edge) =>
        !(fittings.includes(edge.source) && fittings.includes(edge.target)),
    );
    deleteEdges(edgesToDelete);
    deleteOrphanedFittings();
  };
  // const connectColinearHeads = async () => {
  //   for (let i = 0; i < zones.length; i++) {
  //     console.log('zone', i)
  //     const zone = zones[i]
  //     const heads = zone.headIds
  //       .map((id) => elementCache[id])
  //       .filter((h) => (h.props as Sprinkler).angle === 360)
  //     const isMostlyColinear = pointsAreMostlyColinear(heads)
  //     if (isMostlyColinear) {
  //       edges.forEach((edge) => {
  //         if (
  //           contains(zone.headIds, edge.source) ||
  //           contains(zone.headIds, edge.target)
  //         ) {
  //           deleteEdge(edge.source, edge.target)
  //         }
  //       })
  //       const res = await postMST(heads)
  //       const pipe = pipeProducts.find((p) => p.series === lateralPipe)
  //       res.edges.forEach((edge) => {
  //         const e: IEdge = {
  //           ...edge,
  //           preExisting: false,
  //           pipe: pipe ? pipe.uuid : '',
  //           hoppedSections: [],
  //           isLateral: true,
  //           isDrip: false,
  //           showLabel: false,
  //         }
  //                 addElementsEdges([], [e])
  //       })
  //     }
  //   }
  // }

  return (
    <>
      <Dropdown item text="Pipes">
        <Dropdown.Menu direction="left">
          <Dropdown.Divider />

          <Dropdown.Divider />
          <Dropdown.Item
            icon="trash alternate outline"
            size="mini"
            text="Delete All Pipes"
            onClick={() => setDeletePipesOpen(true)}
          />
          <Dropdown.Item
            icon="trash alternate outline"
            size="mini"
            text="Delete All Orphans"
            onClick={() => setDeleteOrphansOpen(true)}
          />
        </Dropdown.Menu>
      </Dropdown>
      <Confirm
        size="mini"
        header="Delete All Pipes"
        content="This will delete all pipes"
        open={deletePipesOpen}
        confirmButton="Delete"
        onCancel={() => setDeletePipesOpen(false)}
        onConfirm={() => {
          deleteAllEdges();
          setDeletePipesOpen(false);
        }}
      />
      <Confirm
        size="mini"
        header="Delete All Orphans"
        content="This will delete all orphans (fittings) left when deleting pipes"
        open={deleteOrphansOpen}
        confirmButton="Delete"
        onCancel={() => setDeleteOrphansOpen(false)}
        onConfirm={() => {
          deleteOrphanedFittings();
          setDeleteOrphansOpen(false);
        }}
      />
    </>
  );
};
