import { useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import styled from 'styled-components';
import { PanelButton } from '../../components/PanelButton';
import { deleteGeneratedSleeves } from './state/deleteGeneratedSleeves';

export const DeleteGeneratedSleevesButton = () => {
  const [generateDeleteOpen, setGenerateDeleteOpen] = useState(false);
  return (
    <Wrap>
      <PanelButton onClick={() => setGenerateDeleteOpen(true)}>
        Delete Generated Sleeves
      </PanelButton>
      <Confirm
        size="mini"
        header="Delete System-Generated Sleeves"
        content="This will delete any sleeves generated by the 'Generate Sleeves' command, but will not delete any sleeves drawn by hand."
        confirmButton="Delete"
        open={generateDeleteOpen}
        onCancel={() => setGenerateDeleteOpen(false)}
        onConfirm={() => {
          deleteGeneratedSleeves();
          setGenerateDeleteOpen(false);
        }}
      />
    </Wrap>
  );
};

const Wrap = styled.div`
  .ui.mini.modal {
    padding: 16px;
  }
`;
