import { ProjectData } from '@shared-types';
import { Card, Form, Icon, TextAreaProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { SectionBody } from './ProjectRequestForm';

interface EquipmentStepProps {
  projectData: ProjectData;
  complete: boolean;
  onUpdate: (form: ProjectData) => void;
}
export const EquipmentStep = ({
  projectData,
  onUpdate,
  complete,
}: EquipmentStepProps) => {
  const handleAreaChange = (_, f: TextAreaProps) => {
    if (f.name) {
      onUpdate({ ...projectData, [f.name]: f.value });
    }
  };
  return (
    <Wrap>
      <Card fluid>
        <Card.Content className={`custom-header ${complete ? 'complete' : ''}`}>
          <Card.Header>
            Equipment Info&nbsp;&nbsp;
            <Icon
              name="check circle"
              color={complete ? 'green' : 'grey'}
              size="small"
            />
          </Card.Header>
        </Card.Content>

        <SectionBody>
          <div className="wrapper">
            <Form>
              <Form.TextArea
                label={'List your required equipment for this project'}
                name="headsNozzles"
                value={projectData.headsNozzles}
                onChange={handleAreaChange}
                rows={10}
              />
              <ul>
                <li>lateral pipe type and min size</li>
                <li>main pipe type and min size</li>
                <li>heads</li>
                <li>nozzles</li>
                <li>valves</li>
                <li>pumps</li>
                <li>controller</li>
                <li>backflow</li>
              </ul>
            </Form>
          </div>
        </SectionBody>
      </Card>
    </Wrap>
  );
};

const Wrap = styled.div``;
