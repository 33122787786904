import React from 'react';
import { Circle, Polyline, Rectangle } from '../../../../../../shared-types';
import {
  isCircle,
  isPolyline,
  isPolylineBed,
  isPolylineYard,
  isRectangle,
} from '../../helpers';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { selectAndCenterSingleID, useDesignStore } from '../../state';
import { deleteItem, updateItem } from '../../state/item-state';
import { LayerChild } from './LayerChild';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const TracingsLayer = ({ open, onOpen }) => {
  const tracings = useDesignStore(
    (state): (Circle | Rectangle | Polyline)[] => {
      return state.items.filter(
        (item): item is Circle | Rectangle | Polyline => {
          return isCircle(item) || isRectangle(item) || isPolyline(item);
        },
      );
    },
  );
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.TRACING,
  );

  const toggleChildVisibility = (item: Circle | Rectangle | Polyline) => {
    updateItem({ ...item, visible: !item.visible });
  };

  const toggleChildLock = (item: Circle | Rectangle | Polyline) => {
    updateItem({ ...item, locked: !item.locked });
  };

  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Tracings (${tracings.length})`}
      open={open}
      onOpen={onOpen}
      childLayer={tracings.map((tracing) => {
        const type = isCircle(tracing)
          ? 'Circle'
          : isRectangle(tracing)
            ? 'Rectangle'
            : 'Polyline';
        let title = `${type} ${tracing.uuid}`;
        if (isPolylineYard(tracing)) {
          title = `Yard: ${tracing.vertices.length} vertices`;
        } else if (isPolylineBed(tracing)) {
          title = `Bed: ${tracing.vertices.length} vertices`;
        } else if (isRectangle(tracing)) {
          title = `${type} ${tracing.width.toFixed(
            2,
          )}' x ${tracing.height.toFixed(2)}'`;
        } else if (isCircle(tracing)) {
          title = `${type} ${tracing.radius.toFixed(2)}' radius`;
        } else if (isPolyline(tracing)) {
          title = `${type} ${tracing.vertices.length} vertices`;
        }
        return (
          <LayerChild
            key={tracing.uuid}
            visible={tracing.visible}
            locked={tracing.locked}
            title={title}
            clickToggle={() => toggleChildVisibility(tracing)}
            clickLock={() => toggleChildLock(tracing)}
            layerLocked={layerLocked}
            layerVisible={layerVisible}
            clickItem={() => selectAndCenterSingleID(tracing.uuid)}
            clickDelete={() => deleteItem(tracing.uuid)}
          />
        );
      })}
    />
  );
};

export default React.memo(TracingsLayer);
