import { useEffect, useState } from 'react';
import { Button, Icon, Modal, Select, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { PDFFile } from '../../../../../shared-types/workbench-types';
import { scaleValue, scales } from './upload.helpers';

interface Props {
  file: PDFFile;
  removeFile: () => void;
  updateFile: (file: PDFFile) => void;
}

export const PDFTable = ({ file, removeFile, updateFile }: Props) => {
  const [localFile, setLocalFile] = useState<PDFFile>(
    JSON.parse(JSON.stringify(file)),
  );
  const [imgPreview, setImgPreview] = useState('');

  useEffect(() => {
    setLocalFile({ ...file });
  }, [file]);

  const convertBytes = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  const removePage = (pageNumber: number) => {
    const newFile = {
      ...file,
      pages: file.pages.filter((p) => p.pageNumber !== pageNumber),
    };
    setLocalFile(newFile);
    updateFile(newFile);
  };
  const setPageScale = (pageNumber: number, value: number) => {
    const newFile = {
      ...file,
      pages: file.pages.map((p) =>
        p.pageNumber === pageNumber ? { ...p, overrideScale: value } : p,
      ),
    };
    setLocalFile(newFile);
    updateFile(newFile);
  };
  const setScale = (scale: number) => {
    const newFile = {
      ...file,
      scale,
      pages: file.pages.map((p) => ({ ...p, overrideScale: scale })),
    };
    setLocalFile(newFile);
    updateFile(newFile);
  };

  return (
    <Wrap>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={7}>Filename</Table.HeaderCell>
            <Table.HeaderCell width={2}>Pages</Table.HeaderCell>
            <Table.HeaderCell width={3}>Size</Table.HeaderCell>
            <Table.HeaderCell width={3}>Scale</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <h4>{localFile.filename}</h4>
            </Table.Cell>
            <Table.Cell>
              {file.pages.length} / {file.pageCount}
            </Table.Cell>
            <Table.Cell>{convertBytes(localFile.file.size)}</Table.Cell>
            <Table.Cell>
              <Select
                options={scales.map((s) => ({
                  key: s.label,
                  text: s.label,
                  value: s.value,
                }))}
                value={localFile.scale}
                onChange={(e, d) => setScale(d.value as number)}
              />
            </Table.Cell>
            <Table.Cell onClick={removeFile}>
              <Icon className="remove-file" name="close" />
            </Table.Cell>
          </Table.Row>
          {localFile.pages.map((page, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                {page.jpgPreviewDataURL && (
                  <div className="preview-wrap">
                    <div
                      onClick={() => setImgPreview(page.jpgPreviewDataURL)}
                      className="icon-preview preview"
                    >
                      <img src={page.jpgPreviewDataURL} alt="Preview" />
                    </div>
                    <Button
                      onClick={() => setImgPreview(page.jpgPreviewDataURL)}
                    >
                      Click for preview
                    </Button>
                  </div>
                )}
                {!page.jpgPreviewDataURL && (
                  <div className="icon-preview">
                    <Icon name="file pdf" />
                  </div>
                )}
              </Table.Cell>
              <Table.Cell>Page {page.pageNumber}</Table.Cell>
              <Table.Cell>
                width: {page.width}px <br /> height: {page.height}px
              </Table.Cell>
              <Table.Cell>
                {!page.overrideScale && (
                  <div>
                    <Button
                      onClick={() =>
                        setPageScale(page.pageNumber, localFile.scale)
                      }
                    >
                      {scaleValue(localFile.scale)}
                    </Button>
                  </div>
                )}
                {!!page.overrideScale && (
                  <Select
                    options={scales.map((s) => ({
                      key: s.label,
                      text: s.label,
                      value: s.value,
                    }))}
                    value={page.overrideScale}
                    onChange={(e, d) =>
                      setPageScale(page.pageNumber, d.value as number)
                    }
                    clearable
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <Icon
                  className="remove-file"
                  onClick={() => removePage(page.pageNumber)}
                  name="close"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Modal
        open={!!imgPreview}
        size="fullscreen"
        closeOnDimmerClick
        closeOnEscape
        closeOnDocumentClick
        closeIcon={true}
        onClose={() => setImgPreview('')}
      >
        <img src={imgPreview} width="100%" />
      </Modal>
    </Wrap>
  );
};
const Wrap = styled.div``;
