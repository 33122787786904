export const defaultTableLayouts = {
  simpleTable: {
    paddingLeft: () => 8,
    paddingRight: () => 8,
    paddingTop: () => 4,
    paddingBottom: () => 4,
    hLineWidth: (i: number, node: any): number =>
      i === 0 || i === node.table.body.length ? 0 : 1,
    vLineWidth: () => 0,
    hLineColor: (i: number, node: any): string =>
      i === 1 || i === node.table.body.length ? '#333' : '#ccc',
  },
};
