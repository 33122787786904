import { IPoint, Legend } from '../../../../../shared-types/workbench-types';
import { defaultItem, paperItemStore } from '../helpers';
import { activateNamedLayer } from '../paper-helpers/plot.helpers';
import { ItemService } from '../polyline/ItemService';
import { registerStateHooks } from '../polyline/item.model';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { deletePaperItem, updatePaperItem } from '../state/renderers';
import { PaperLegend } from '../tools/paper-items/paper-legend';

class LegendService implements ItemService<Legend> {
  init(): void {
    this.registerWithState();
  }
  createItem(position?: IPoint): Legend {
    return {
      ...defaultItem(position),
      itemType: 'legend',
    };
  }
  renderItemToPaper(item: Legend): void {
    activateNamedLayer(LAYER_NAMES.LEGEND);
    paperItemStore.set(item.uuid, new PaperLegend(item));
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  }
  registerWithState = () =>
    registerStateHooks<Legend>('legend', {
      postAddItem: (item: Legend) => {
        this.renderItemToPaper(item);
      },
      postUpdateItem: (item: Legend) => {
        updatePaperItem(item);
      },
      postDeleteItem: (item: Legend) => {
        deletePaperItem(item.uuid);
      },
    });
}

export const legendService = new LegendService();
