import { CustomSymbol } from '@shared-types';
import { filter, map, merge, switchMap, takeUntil, tap } from 'rxjs';
import { getEuclideanDistance, polyHintPurple } from 'src/shared';

import paper from 'src/paper';
import { localZoom } from '../localPaper';
import { activateNamedLayer, getColor } from '../paper-helpers/plot.helpers';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { getState } from '../state';
import { addItem } from '../state/item-state';
import { toolService } from '../tool.service';
import { createCustomSymbolItem } from './createCustomSymbolItem';
import { customSymbolService } from './custom-symbol.service';

const createTmpSymbol = (point: paper.Point, radius: number) => {
  const activeCustomSymbol = getState().activeCustomSymbol;
  const customSymbolItem = createCustomSymbolItem(activeCustomSymbol);
  customSymbolItem.position = point;
  customSymbolItem.strokeWidth = 1 / localZoom();
  customSymbolItem.strokeColor = getColor(polyHintPurple);
  return customSymbolItem;
};

const { mouseDown$, mouseUp$, mouseDrag$ } =
  toolService.getEvents('custom-symbol');

const dragDrawCustomSymbol$ = mouseDown$.pipe(
  filter(({ e }) => !e.modifiers.space),
  map(({ e }) => {
    activateNamedLayer(LAYER_NAMES.TRACING);
    const radius = 1;
    const rotation = 0;
    const tmpPlant = createTmpSymbol(e.point, rotation);
    let hint = new paper.Path();

    return { tmpPlant, rotation, radius, downPoint: e.downPoint, hint };
  }),
  switchMap(({ tmpPlant, rotation, radius, downPoint, hint }) => {
    const scale = getState().scale;
    return mouseDrag$.pipe(
      tap((e) => {
        const newRadius = getEuclideanDistance(downPoint, e.point) / 5;
        if (newRadius !== 0 && radius !== 0) {
          tmpPlant.scale(newRadius / radius);
          radius = newRadius;
        }
        hint.remove();
        hint = new paper.Path([downPoint, e.point]);
        hint.strokeWidth = 1 / scale;
        hint.dashArray = [2 / scale, 1 / scale];
        hint.strokeColor = getColor(polyHintPurple);
        hint.locked = true;
        const newRotation = e.point.subtract(downPoint).angle;
        tmpPlant.rotate(newRotation - rotation);
        rotation = newRotation;
      }),
      takeUntil(
        merge(
          mouseUp$.pipe(
            tap(() => {
              if (tmpPlant && radius > 0.1) {
                const p = tmpPlant.position;
                const customSymbol: CustomSymbol = {
                  ...customSymbolService.createItem({ x: p.x, y: p.y }),
                  symbolType: getState().activeCustomSymbol,
                  rotation,
                  scale: radius,
                };
                addItem(customSymbol);
              }
            }),
          ),
        ).pipe(
          tap(() => {
            hint.remove();
            tmpPlant.remove();
            activateNamedLayer(LAYER_NAMES.DEFAULT);
          }),
        ),
      ),
    );
  }),
);

export const drawCustomSymbol$ = merge(dragDrawCustomSymbol$);
