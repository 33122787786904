import { useEffect, useState } from 'react';
import { BaseStyledItem } from '../../../../../shared-types';
import { bulkUpdateItems } from '../state/item-state';
import { EditStroke } from './EditStroke';
import { PanelWrap } from './PanelButton';

interface Props {
  items: BaseStyledItem[];
}
export const StyledItemActions = ({ items }: Props) => {
  const [localItems, setLocalItems] = useState<BaseStyledItem[]>(items);

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  const updateStroke = (updatedItems: BaseStyledItem[]) => {
    const updated = updatedItems.map((item) => {
      const localItem = localItems.find((i) => i.uuid === item.uuid);
      if (!localItem) return item;
      const style = {
        ...localItem,
        style: {
          ...localItem.style,
          strokeWidth: item.style.strokeWidth,
          dashArray: item.style.dashArray || localItem.style.dashArray,
          dashOffset: item.style.dashOffset || localItem.style.dashOffset,
          strokeCap: item.style.strokeCap || localItem.style.strokeCap,
          strokeJoin: item.style.strokeJoin || localItem.style.strokeJoin,
          strokeColor: item.style.strokeColor || localItem.style.strokeColor,
          fillColor: item.style.fillColor || localItem.style.fillColor,
          fillAlpha: item.style.fillAlpha || localItem.style.fillAlpha,
        },
      };
      if (!item.style.fillColor) {
        delete style.style.fillAlpha;
        delete style.style.fillColor;
      }
      return style;
    });
    setLocalItems(updated);
    bulkUpdateItems(updated);
  };
  return (
    <PanelWrap>
      <EditStroke items={localItems} onChange={updateStroke} />
    </PanelWrap>
  );
};
