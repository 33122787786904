import paper from 'src/paper';
import {
  CustomSymbol,
  IPoint,
} from '../../../../../shared-types/workbench-types';
import { applyAlpha, defaultStyledItem } from '../helpers';
import { getColor } from '../paper-helpers/plot.helpers';
import { getState } from '../state';
import { PaperItem } from '../tools/paper-items/paper-item';
import { createCustomSymbolItem } from './createCustomSymbolItem';

export const isPaperCustomSymbol = (
  item: PaperItem<any>,
): item is PaperCustomSymbol => {
  return item instanceof PaperCustomSymbol;
};
export class PaperCustomSymbol implements PaperItem<CustomSymbol> {
  private customSymbol: CustomSymbol = {
    ...defaultStyledItem({ x: 0, y: 0 }),
    scale: 1,
    symbolType: 'rock',
  };
  private _group = new paper.Group();

  get highlight() {
    return this._group.getItem({ name: 'highlight' });
  }
  get group() {
    return this._group;
  }
  get paperItem() {
    return this._group;
  }

  constructor(customSymbol: CustomSymbol) {
    this.customSymbol = customSymbol;
    this.render(customSymbol);
  }
  getRealItem(): CustomSymbol {
    return this.customSymbol;
  }
  getItem(): paper.Item {
    return this.group;
  }
  setPosition(point: IPoint): void {
    this.group.position = new paper.Point(point);
  }
  setPivot(point: IPoint): void {
    this.group.pivot = new paper.Point(point);
  }
  toggleHighlight(show: boolean): void {
    this.setSelectedStatus(show);
  }
  setSelectedStatus(selected: boolean) {
    if (this.highlight) {
      this.highlight.remove();
    }
    if (selected) {
      const highlight = this.paperItem.clone();
      highlight.strokeWidth =
        (this.customSymbol.style.strokeWidth || 1) / getState().scale / 3;
      highlight.strokeColor = getColor('#94baf7');
      highlight.fillColor = null;
      highlight.name = 'highlight';
      highlight.locked = true;
      this._group.addChild(highlight);
    }
  }
  destroy = () => {
    this._group.remove();
  };
  update = (customSymbol: CustomSymbol) => {
    const selected = !!this.highlight;

    this.render(customSymbol);
    this.customSymbol = customSymbol;
    this.toggleHighlight(selected);
  };
  private render = (item: CustomSymbol) => {
    this._group.removeChildren();
    const outline = this.generateOutline(item);
    this._group.addChild(outline);
    this._group.opacity = 1;
    this._group.name = item.itemType;
    this._group.data = this.customSymbol;
  };
  private generateOutline = (item: CustomSymbol): paper.Item => {
    const scale = getState().scale;
    const svg = createCustomSymbolItem(item.symbolType);
    const newColor = applyAlpha(item.style.strokeColor, item.style.strokeAlpha);
    svg.strokeColor = newColor;
    const newFillColor = applyAlpha(item.style.fillColor, item.style.fillAlpha);
    svg.fillColor = newFillColor;
    svg.strokeWidth = (item.style.strokeWidth || 1) / scale;
    svg.opacity = item.style.opacity !== undefined ? item.style.opacity : 1;
    svg.rotation = item.rotation;
    svg.scale(item.scale);
    svg.position = new paper.Point(item.position);
    return svg;
  };
}
