import { DesignElement, IValveProduct, Zone } from '@shared-types';
import { useState } from 'react';
import {
  Button,
  Checkbox,
  DropdownProps,
  Form,
  Modal,
  Select,
  Table,
} from 'semantic-ui-react';
import { compact, uniq } from 'underscore';
import { getLossFromFlow, valveGPM } from '../../helpers/directedGraph';
import {
  getState,
  pocGraphByNode,
  setDripValve,
  setLateralValve,
  useDesignStore,
} from '../../state';
import { changeElements } from '../../state/changeElements';
import { modalDefaults } from '../../state/modal.service';

export const ValveEquipmentModalType = 'valveEquipmentModal';
export const ValveEquipmentModal = ({ closeModal }): JSX.Element => {
  const zones = useDesignStore((state) => state.zones);
  const valveProducts = useDesignStore((state) => state.valveProducts);
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const groups = useDesignStore((state) => state.groups);
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  const elementCache = useDesignStore((state) => state.elementCache);
  const lateralValve = useDesignStore((state) => state.lateralValve);
  const dripValve = useDesignStore((state) => state.dripValve);
  const [selectedZones, setSelectedZones] = useState<string[]>([]);

  const doLateralValveChange = (_, data: DropdownProps) => {
    const valveProducts = getState().valveProducts;
    const id = data.value as string;
    const valve = valveProducts.find((v) => v.uuid === id);
    if (valve) {
      setLateralValve(valve);
    }
  };
  const doDripValveChange = (_, data: DropdownProps) => {
    const valveProducts = getState().valveProducts;
    const id = data.value as string;
    const valve = valveProducts.find((v) => v.uuid === id);
    if (valve) {
      setDripValve(valve);
    }
  };
  const valveNames = () => {
    const valveProducts = getState().valveProducts;
    return uniq(valveProducts, true, (v) => `${v.brand} ${v.name}`);
  };
  const applyValves = () => {
    const zones = getState().zones;
    let newValvedEls: DesignElement[] = [];
    zones.forEach((z) => {
      if (!selectedZones.includes(z.uuid)) {
        return;
      }
      const el = getState().elementCache[z.valve];
      if (el) {
        if (z.isDrip && !!dripValve) {
          newValvedEls.push({ ...el, props: dripValve });
        } else if (!z.isDrip && !!lateralValve) {
          newValvedEls.push({ ...el, props: lateralValve });
        }
      }
    });
    changeElements(newValvedEls);
    closeModal();
  };
  const getFlow = (p: IValveProduct) => {
    const valveProducts = getState().valveProducts;
    const products = valveProducts.filter(
      (v) => `${p.brand} ${p.name}` === `${v.brand} ${v.name}`,
    );
    const flows = compact(products.map((p) => p.flow));
    if (!flows.length) {
      return 'unknown';
    } else if (flows.length === 1) {
      return flows[0];
    } else {
      return `${Math.min(...flows)}-${Math.max(...flows)}`;
    }
  };

  const getValveLoss = (zone: Zone): string => {
    const { pipeProducts, valveProducts, backflowProducts, edges, pocGraphs } =
      getState();
    const dg = pocGraphByNode(zone.valve, pocGraphs);
    if (dg) {
      const losses = dg.lossesAtZone(
        zone,
        pipeProducts,
        valveProducts,
        backflowProducts,
        edges,
      );
      const worstLoss = losses.reduce(
        (acc, loss) => (loss.totalLoss > acc.totalLoss ? loss : acc),
        losses[0],
      );
      if (worstLoss) {
        return worstLoss.valveLoss.toFixed(2);
      }
    }
    return '-';
  };

  const getCurrentValve = (zone: Zone): string => {
    const valveProduct = elementCache[zone.valve]?.props as IValveProduct;
    return valveProduct ? `${valveProduct.brand} ${valveProduct.name}` : '-';
  };

  const getEstimatedLoss = (zone: Zone): string => {
    const gpm = valveGPM(zone, pipeProducts, groups, pocGraphs);
    if (lateralValve) {
      const valve = valveProducts.find((p) => p.uuid === lateralValve.uuid);
      if (valve) {
        const name = `${valve.brand} ${valve.name}`;
        const perfData = valveProducts.filter(
          (v) => `${v.brand} ${v.name}` === name,
        );
        return getLossFromFlow(perfData, gpm).toFixed(2);
      }
    }
    return '-';
  };

  const getPSI = (p: IValveProduct) => {
    if (!p.minPSI) {
      return 'unknown';
    } else if (p.minPSI === p.maxPSI) {
      return p.minPSI;
    } else {
      return `${p.minPSI}-${p.maxPSI}`;
    }
  };

  return (
    <Modal
      {...modalDefaults}
      style={{ maxHeight: '80vh', overflow: 'auto' }}
      size="large"
    >
      <Modal.Header>Edit Valve Equipment</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field
            label="Default turf valve"
            control={Select}
            value={lateralValve ? lateralValve.uuid : ''}
            options={valveNames()
              .sort((v1, v2) => v1.brand.localeCompare(v2.brand))
              .map((p: IValveProduct) => ({
                text: `${p.brand} ${p.name} ---- ${getPSI(
                  p,
                )} PSI ---- ${getFlow(p)} GPM`,
                value: p.uuid,
              }))}
            onChange={doLateralValveChange}
          />
          <Form.Field
            label="Default drip valve"
            control={Select}
            value={dripValve ? dripValve.uuid : ''}
            options={valveNames()
              .sort((v1, v2) => v1.brand.localeCompare(v2.brand))
              .map((p: IValveProduct) => ({
                text: `${p.brand} ${p.name} ---- ${getPSI(
                  p,
                )} PSI ---- ${getFlow(p)} GPM`,
                value: p.uuid,
              }))}
            onChange={doDripValveChange}
          />
        </Form>
        <h5>Select which (if any) zones to override</h5>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Zone</Table.HeaderCell>
              <Table.HeaderCell>GPM</Table.HeaderCell>
              <Table.HeaderCell>Current Valve</Table.HeaderCell>
              <Table.HeaderCell>Current Valve Loss</Table.HeaderCell>
              <Table.HeaderCell>
                New Valve Loss
                <br />
                <small>If new valve is selected</small>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {zones.map((zone, i) => (
              <Table.Row key={zone.uuid}>
                <Table.Cell>
                  <Checkbox
                    key={i}
                    label={`Zone ${
                      zone.isDrip || zone.plantIds.length > 0 ? 'D' : ''
                    }${zone.orderNumber + 1}`}
                    checked={selectedZones.includes(zone.uuid)}
                    onChange={() => {
                      if (selectedZones.includes(zone.uuid)) {
                        setSelectedZones(
                          selectedZones.filter((s) => s !== zone.uuid),
                        );
                      } else {
                        setSelectedZones([...selectedZones, zone.uuid]);
                      }
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  {valveGPM(zone, pipeProducts, groups, pocGraphs).toFixed(2)}
                </Table.Cell>
                <Table.Cell>{getCurrentValve(zone)}</Table.Cell>

                <Table.Cell>{getValveLoss(zone)}</Table.Cell>
                <Table.Cell>{getEstimatedLoss(zone)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <br />
        If no zones are selected, the default valve will be used for new zones
        only.
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button primary onClick={applyValves}>
          Apply
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
