import { Checkbox, Icon, Popup } from 'semantic-ui-react';
import { blurElements } from '../paper-helpers/plot.helpers';
import { drawPolyService } from '../polyline/draw-poly.service';
import { usePolyService } from '../polyline/usePolyService';
import { DefaultStrokeWidth } from './DefaultStrokeWidth';
import {
  PanelDivider,
  PanelInputWrap,
  PanelLabel,
  PanelWrap,
} from './PanelButton';

export const DrawPolylineProperties = () => {
  const { overrideMode, arcMode, nextLength, closePath, snapMode } =
    usePolyService();

  const setNextLength = (length: number) => {
    drawPolyService.setNextLength(length);
  };
  const setClosePath = () => {
    drawPolyService.setClosePath(!closePath);
  };

  return (
    <PanelWrap>
      <div>
        <PanelLabel>Drawing Polyline</PanelLabel>

        <Checkbox
          toggle
          label="Arc Mode [a]"
          checked={arcMode}
          onClick={() => {
            drawPolyService.toggleArcMode();
            blurElements();
          }}
        />
        <Checkbox
          toggle
          label="Snap Mode [s]"
          checked={snapMode}
          onClick={() => {
            drawPolyService.toggleSnapMode();
            blurElements();
          }}
        />
        <DefaultStrokeWidth />
        {arcMode && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              toggle
              label="Set Next Through Point [2]"
              checked={overrideMode}
              onClick={() => {
                drawPolyService.setOverrideMode(!overrideMode);
                blurElements();
              }}
            />
            <Popup
              trigger={
                <Icon
                  circular
                  name="help"
                  inverted
                  color="orange"
                  size="small"
                />
              }
              content="Click anywhere on the map to set the through point of the next arc"
            />
          </div>
        )}
        {!arcMode && (
          <>
            <PanelDivider />
            <PanelInputWrap
              label="Set Line Length (ft)"
              value={nextLength}
              type="number"
              hint="0 for no limit, only applies to straight lines"
              min={0}
              onChange={(e: any) => {
                if (e.target.value >= 0) {
                  setNextLength(Math.min(Number(e.target.value)));
                }
              }}
            />
          </>
        )}
        <Checkbox
          label="Show Closed Path"
          checked={closePath}
          onClick={() => {
            setClosePath();
            blurElements();
          }}
        />
      </div>
    </PanelWrap>
  );
};
