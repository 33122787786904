import { ProjectData, ProjectPOC } from '@shared-types';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, Card, Form, Icon } from 'semantic-ui-react';
import { LinkWithConfirm } from 'src/components/LinkWithConfirm';
import { base } from 'src/styles/common';
import styled from 'styled-components';
import { SectionBody } from './ProjectRequestForm';
import { basePOC } from './helpers';

interface PointOfConnectionProps {
  projectData: ProjectData;
  complete: boolean;
  onUpdate: (form: ProjectData) => void;
}
export const PointOfConnection = ({
  projectData,
  onUpdate,
  complete,
}: PointOfConnectionProps) => {
  // const { catalog } = useContext(PortalContext)
  const [pocs, setPocs] = useState<ProjectPOC[]>(projectData.pocs);

  useEffect(
    () => {
      onUpdate({ ...projectData, pocs });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pocs],
  );

  const handlePOCChange = (i: number, f: any) => {
    if (f.currentTarget.name) {
      setPocs(
        pocs.map((poc, j) =>
          j === i
            ? {
                ...poc,
                [f.currentTarget.name]: parseFloat(f.currentTarget.value),
              }
            : poc,
        ),
      );
    }
  };

  const addPOC = () => {
    setPocs([...pocs, basePOC]);
  };
  const deletePOC = (pocIndex: number) => {
    setPocs(pocs.filter((_p, i) => i !== pocIndex));
  };

  // const addPOCSource = (name: string, i: number) => {
  //   console.log('in add poc source')
  //   let source: IPump | IMeter
  //   const firstPipe = catalog.pipes[0]
  //   if (name === 'Meter') {
  //     source = {
  //       name: 'Meter',
  //       meterSize: 1,
  //       serviceLine: firstPipe ? firstPipe.series : '',
  //       serviceLineSize: 1,
  //       serviceLineLength: 0,
  //       serviceElevationChange: 0,
  //       gpmPercentage: 100
  //     } as IMeter
  //   } else if (name === 'Pump') {
  //     source = {
  //       name: 'Pump',
  //       outputPSI: 0,
  //       outputGPM: 0
  //     } as IPump
  //   }
  //   setPocs(
  //     pocs.map((poc, j) => ({
  //       ...poc,
  //       source: j === i ? source : poc.source,
  //       mains: [
  //         {
  //           distance: 0,
  //           pipe: { ...firstPipe }
  //         }
  //       ]
  //     }))
  //   )
  // }

  // const removePOCSource = (i: number) => {
  //   setPocs(
  //     pocs.map((poc, j) => ({
  //       ...poc,
  //       source: j === i ? null : poc.source,
  //       mains: j === i ? [] : poc.mains
  //     }))
  //   )
  // }

  // const addMainToPOC = (i: number) => {
  //   setPocs(
  //     pocs.map((poc, j) => ({
  //       ...poc,
  //       mains:
  //         j === i
  //           ? [
  //             ...poc.mains,
  //             {
  //               distance: 0,
  //               pipe: catalog.pipes[0]
  //             }
  //           ]
  //           : poc.mains
  //     }))
  //   )
  // }
  // const removeMainFromPOC = (i: number) => {
  //   setPocs(
  //     pocs.map((poc, j) => ({
  //       ...poc,
  //       mains: poc.mains.filter((_, k) => k !== i)
  //     }))
  //   )
  // }

  // const handleMainChange = (mainIndex: number, pocIndex: number, _, data: DropdownProps) => {
  //   const currentPipe = projectData.pocs[pocIndex].mains[mainIndex]
  //   const newPipe = catalog.pipes.find(p => p.series === data.value)
  //   if (currentPipe && newPipe) {
  //     setPocs(
  //       pocs.map((poc, i) => ({
  //         ...poc,
  //         mains:
  //           i === pocIndex
  //             ? poc.mains.map((m, j) => ({
  //               ...m,
  //               pipe: j === mainIndex ? newPipe : m.pipe
  //             }))
  //             : poc.mains
  //       }))
  //     )
  //   }
  // }

  // const handleMainSizeChange = (mainIndex: number, pocIndex: number, _, data: DropdownProps) => {
  //   const currentPipe = projectData.pocs[pocIndex].mains[mainIndex]
  //   const newPipe = catalog.pipes.find(
  //     p => p.series === currentPipe.pipe.series && p.size === data.value
  //   )
  //   if (currentPipe && newPipe) {
  //     setPocs(
  //       pocs.map((poc, i) => ({
  //         ...poc,
  //         mains:
  //           i === pocIndex
  //             ? poc.mains.map((m, j) => ({
  //               ...m,
  //               pipe: j === mainIndex ? newPipe : m.pipe
  //             }))
  //             : poc.mains
  //       }))
  //     )
  //   }
  // }

  // const handleMainDistanceChange = (
  //   mainIndex: number,
  //   pocIndex: number,
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setPocs(
  //     pocs.map((poc, i) => ({
  //       ...poc,
  //       mains:
  //         i === pocIndex
  //           ? poc.mains.map((m, j) => ({
  //             ...m,
  //             distance: j === mainIndex ? parseFloat(e.currentTarget.value) : m.distance
  //           }))
  //           : poc.mains
  //     }))
  //   )
  // }

  // const saveMeter = (pocIndex: number, meter: IMeter) => {
  //   setPocs(
  //     pocs.map((poc, i) => ({
  //       ...poc,
  //       source: i === pocIndex ? meter : poc.source
  //     }))
  //   )
  // }

  // const savePump = (pocIndex: number, pump: IPump) => {
  //   setPocs(
  //     pocs.map((poc, i) => ({
  //       ...poc,
  //       source: i === pocIndex ? pump : poc.source
  //     }))
  //   )
  // }

  // const savePRV = (pocIndex: number, prv: IPRV) => {
  //   setPocs(
  //     pocs.map((poc, i) => ({
  //       ...poc,
  //       prv: i === pocIndex ? prv : poc.prv
  //     }))
  //   )
  // }

  // const togglePRV = (pocIndex: number) => {
  //   setPocs(
  //     pocs.map((poc, i) => ({
  //       ...poc,
  //       prv: i === pocIndex ? (poc.prv ? undefined : catalog.prvs[0]) : poc.prv
  //     }))
  //   )
  // }
  return (
    <Wrap>
      {pocs.map((poc, i) => (
        <Card fluid key={i}>
          <Card.Content
            className={`custom-header ${complete ? 'complete' : ''}`}
          >
            <Card.Header>
              Point of Connection {i + 1}&nbsp;&nbsp;
              <Icon
                name="check circle"
                color={complete ? 'green' : 'grey'}
                size="small"
              />
            </Card.Header>
          </Card.Content>
          <SectionBody>
            <div className="wrapper">
              {pocs.length > 1 && (
                <>
                  <LinkWithConfirm onConfirm={() => deletePOC(i)}>
                    Delete POC
                  </LinkWithConfirm>
                  <br />
                </>
              )}
              <Form>
                <Form.Field>
                  <label>Gallons per Minute</label>
                  <NumericFormat
                    required
                    name="gpm"
                    value={poc.gpm}
                    onBlur={handlePOCChange.bind(null, i)}
                  />
                </Form.Field>
                <Form.Group widths={'equal'}>
                  {/* <Form.Field>
                    <label>Static PSI</label>
                    <NumericFormat
                      value={poc.staticPSI}
                      name="staticPSI"
                      onBlur={handlePOCChange.bind(null, i)}
                    />
                  </Form.Field> */}
                  <Form.Field>
                    <label>Dynamic PSI</label>
                    <NumericFormat
                      value={poc.dynamicPSI}
                      name="dynamicPSI"
                      onBlur={handlePOCChange.bind(null, i)}
                    />
                  </Form.Field>
                </Form.Group>
                {/* <Form.Checkbox
                  label="POC uses a Pressure Regulating Valve"
                  defaultChecked={false}
                  onChange={togglePRV.bind(null, i)}
                />
                {!!poc.prv && (
                  <>
                    <PRVForm
                      initialPRV={poc.prv}
                      prvProducts={catalog.prvs}
                      onSave={savePRV.bind(null, i)}
                      autoSave={true}
                    />
                  </>
                )} */}
                {/* <h5>Is the POC connected to a known water source?</h5>
                <Form.Field>
                  <Radio
                    label="POC"
                    name="source"
                    checked={!poc.source}
                    onChange={() => removePOCSource(i)}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Water Meter"
                    name="source"
                    checked={!!poc.source && poc.source.name === 'Meter'}
                    onChange={() => addPOCSource('Meter', i)}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Pump"
                    name="source"
                    checked={!!poc.source && poc.source.name === 'Pump'}
                    onChange={() => addPOCSource('Pump', i)}
                  />
                </Form.Field> */}

                {/* {!!poc.source && (
                  <>
                    {poc.source.name === 'Meter' && (
                      <MeterForm
                        initialMeter={poc.source as IMeter}
                        pipeProducts={catalog.pipes}
                        onSave={saveMeter.bind(null, i)}
                        autoSave={true}
                      />
                    )}

                    {poc.source.name === 'Pump' && (
                      <PumpForm
                        initialPump={poc.source as IPump}
                        onSave={savePump.bind(null, i)}
                        autoSave={true}
                      />
                    )}
                    <Header as="h4">
                      Mains from {poc.source && poc.source.name === 'Meter' ? 'Meter' : 'Pump'} to
                      POC
                    </Header>
                    <small>
                      If a {poc.source && poc.source.name === 'Meter' ? 'Meter' : 'Pump'} is added
                      to a POC, you must also include the pipe information from the{' '}
                      {poc.source && poc.source.name === 'Meter' ? 'Meter' : 'Pump'} to the POC so
                      we can calculate accurate friction loss and residual pressure.
                    </small>
                    {poc.mains.map((main, j) => (
                      <>
                        <div key={j} className="poc-mains">
                          <Form.Select
                            label={`Pipe ${j + 1} Type`}
                            options={uniq(catalog.pipes.map(p => p.series)).map(
                              (pipeName: string): DropdownItemProps => ({
                                text: pipeName,
                                value: pipeName
                              })
                            )}
                            value={main.pipe.series}
                            onChange={handleMainChange.bind(null, j, i)}
                          />

                          <Form.Select
                            label={`Pipe ${j + 1} diameter`}
                            options={uniq(
                              catalog.pipes
                                .filter(p => p.series === main.pipe.series)
                                .map(p => p.size)
                            ).map(
                              (pipeName: number): DropdownItemProps => ({
                                text: pipeName,
                                value: pipeName
                              })
                            )}
                            value={main.pipe.size}
                            onChange={handleMainSizeChange.bind(null, j, i)}
                          />

                          <Form.Field width={4}>
                            <label>Pipe {j + 1} length (ft)</label>
                            <NumericFormat
                              value={main.distance}
                              onBlur={handleMainDistanceChange.bind(null, j, i)}
                            />
                          </Form.Field>
                        </div>
                        {j > 0 && (
                          <div className="clickable" onClick={() => removeMainFromPOC(j)}>
                            Remove pipe {j + 1}
                            <br />
                            <br />
                          </div>
                        )}
                      </>
                    ))}
                    <br />
                    <Button type="button" size="mini" onClick={() => addMainToPOC(i)}>
                      Add another main line
                    </Button>
                  </>
                )} */}
                {/* <Message error>All fields must be added before another POC can be started.</Message> */}
              </Form>
            </div>
          </SectionBody>
        </Card>
      ))}
      <Button type="button" size="mini" onClick={addPOC}>
        Add another POC
      </Button>
    </Wrap>
  );
};

const Wrap = styled.div`
  .wrapper {
    padding: ${base * 2}px;
  }
  .clickable {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
  }
`;
