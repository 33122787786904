import { IPoC, Zone } from '@shared-types';
import { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { toRealisticFixed } from 'src/pages/Workbench/helpers';
import { getState, pocGraphByNode } from '../../../state';

interface TurfProps {
  zone: Zone;
}
export const TurfPressureTable = ({ zone }: TurfProps) => {
  const {
    edges,
    groups,
    elementCache,
    pocGraphs,
    valveProducts,
    backflowProducts,
    pipeProducts,
  } = getState();
  const dg = pocGraphByNode(zone.valve, pocGraphs);
  const gpm = dg ? dg.rootGPM(pipeProducts) : 0;
  const [losses, setLosses] = useState({
    mainLoss: -1,
    lateralLoss: -1,
    fittingsLoss: -1,
    valveLoss: -1,
    headLoss: -1,
    backflowLoss: -1,
    elevationLoss: -1,
    totalLoss: -1,
    working: -1,
    residual: -1,
    zoneGPM: -1,
    pocName: '',
  });
  useEffect(() => {
    const dg = pocGraphByNode(zone.valve, pocGraphs);
    if (dg) {
      const losses = dg.lossesAtZone(
        zone,
        pipeProducts,
        valveProducts,
        backflowProducts,
        edges,
      );
      const worstLoss = losses.reduce(
        (acc, loss) => (loss.totalLoss > acc.totalLoss ? loss : acc),
        losses[0],
      );
      if (worstLoss) {
        setLosses({
          mainLoss: worstLoss.mainLoss,
          lateralLoss: worstLoss.lateralLoss,
          fittingsLoss: worstLoss.lateralFittingsLoss,
          valveLoss: worstLoss.valveLoss,
          headLoss: worstLoss.headLoss,
          backflowLoss: worstLoss.backflowLoss,
          elevationLoss: worstLoss.elevationLoss,
          totalLoss: worstLoss.totalLoss,
          working: worstLoss.psiAtRegulator,
          residual: worstLoss.residualPSI,
          zoneGPM: dg.getEdgeMaxGPM(
            zone.valveInputFitting,
            zone.valve,
            pipeProducts,
            groups,
          ),
          pocName: (elementCache[dg.pocRoot].props as IPoC).name,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table size="small" compact={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="8">
            {(zone.isDrip || zone.plantIds.length > 0) && 'Drip'} Zone{' '}
            {zone.orderNumber + 1}
          </Table.HeaderCell>
          {/* <Table.HeaderCell>(PSI loss)</Table.HeaderCell> */}
          <Table.HeaderCell>(PSI loss)</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>POC</Table.Cell>
          <Table.Cell>{losses.pocName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Working GPM</Table.Cell>
          <Table.Cell>{gpm.toFixed(2)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Zone GPM</Table.Cell>
          <Table.Cell>{toRealisticFixed(losses.zoneGPM)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Valve Loss</Table.Cell>
          <Table.Cell>{toRealisticFixed(losses.valveLoss)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Backflow Loss</Table.Cell>
          <Table.Cell>{toRealisticFixed(losses.backflowLoss)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Lateral Loss</Table.Cell>
          <Table.Cell>{toRealisticFixed(losses.lateralLoss)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Fittings Loss (10% of laterals)</Table.Cell>
          <Table.Cell>{toRealisticFixed(losses.fittingsLoss)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Main Loss</Table.Cell>
          <Table.Cell>{toRealisticFixed(losses.mainLoss)}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Elevation Loss</Table.Cell>
          <Table.Cell>{toRealisticFixed(losses.elevationLoss)}</Table.Cell>
        </Table.Row>
        {(zone.staticLossItems || []).map((item, ik) => (
          <Table.Row key={ik}>
            <Table.Cell>{item.description} Loss</Table.Cell>
            <Table.Cell>{item.loss.toFixed(2)}</Table.Cell>
          </Table.Row>
        ))}
        <Table.Row>
          <Table.Cell>Max Required Pressure</Table.Cell>
          <Table.Cell>{toRealisticFixed(losses.headLoss)}</Table.Cell>
        </Table.Row>
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell>Used PSI</Table.HeaderCell>
          <Table.HeaderCell>
            {toRealisticFixed(losses.totalLoss)}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Working PSI</Table.HeaderCell>
          <Table.HeaderCell>
            {toRealisticFixed(losses.working)}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row negative={losses.residual < 0}>
          <Table.Cell>Residual PSI (working - used)</Table.Cell>
          <Table.Cell>{toRealisticFixed(losses.residual)}</Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};
