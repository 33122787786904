import { useState } from 'react';
import { List, Message } from 'semantic-ui-react';
import { TitleBarConfig } from '../../../../../shared-types';
import { getItemsByType, isSheet } from '../helpers';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { getState } from '../state';
import { PresetCustomizer } from './PresetCustomizer';
import { applyPreset, Preset, PRESET_ACTIONS } from './presets';
import {
  createFullDesignSheets,
  saveAndDownloadPDF,
} from './saveAndDownloadPDF';

const ActualExportCall = async () => {
  const titleBarConfig: TitleBarConfig = {
    pageMargin: 0.5,
    titleBarWidth: 3.5,
    sidebarGap: 0.5,
    sheetWidth: 31,
    sheetHeight: 23,
    printWidth: 36,
    printHeight: 24,
    border: 0,
  };
  const finalPDF = await createFullDesignSheets(titleBarConfig);
  await saveAndDownloadPDF(finalPDF, `${getState().title} - Design Sheets.pdf`);
};

const presetWorkflowDesignSheets = async (preset: Preset) => {
  applyPreset(preset);
  await ActualExportCall();
};

const designSheetPreset: Preset = {
  name: 'Design Sheet',
  config: {
    layersToShow: [
      LAYER_NAMES.EDGES,
      LAYER_NAMES.ELEMENTS,
      LAYER_NAMES.TRACING,
      LAYER_NAMES.HEADS,
      LAYER_NAMES.LEGEND,
      LAYER_NAMES.PLANTS,
      LAYER_NAMES.SHEETS,
      LAYER_NAMES.SLEEVES,
      LAYER_NAMES.VALVE_INFO_BOXES,
      LAYER_NAMES.VALVE_BOXES,
    ],
    actions: [
      PRESET_ACTIONS.headPreview,
      PRESET_ACTIONS.plantOutlines,
      PRESET_ACTIONS.sheetPreview,
      PRESET_ACTIONS.showPipeLabels,
      PRESET_ACTIONS.valveInfoBoxNumbers,
    ],
  },
};

export const SheetExport = (): JSX.Element => {
  const sheets = getItemsByType(isSheet);
  const [preset, setPreset] = useState(designSheetPreset);
  const [customize, setCustomize] = useState(false);
  return (
    <List.Item>
      <List.Icon name="file pdf" />
      <List.Content>
        <a onClick={() => presetWorkflowDesignSheets(preset)}>
          Export Large Sheets PDF
        </a>
        &nbsp; (
        <a
          onClick={() => {
            applyPreset(preset);
            setCustomize(!customize);
          }}
        >
          Customize
        </a>
        )
      </List.Content>
      {customize && (
        <PresetCustomizer preset={preset} onSavePreset={setPreset} />
      )}
      <br />
      {!sheets.length && (
        <Message color="red">
          There are no sheets, so nothing will export!
        </Message>
      )}
    </List.Item>
  );
};
