import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, Modal } from 'semantic-ui-react';
import { PanelButton } from '../../components/PanelButton';
import { setZoneElevation, useDesignStore } from '../../state';

export const ZoneElevation = () => {
  const currentZone = useDesignStore((state) =>
    state.zones.find((zone) => zone.uuid === state.currentZone),
  );
  const [elevation, setElevation] = useState(
    currentZone ? currentZone.elevation || 0 : 0,
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentZone) {
      setElevation(currentZone.elevation || 0);
    }
  }, [currentZone]);

  const changeElevation = (e) => {
    setElevation(parseInt(e.currentTarget.value, 10));
  };

  const save = () => {
    setOpen(false);
    if (currentZone) {
      setZoneElevation(currentZone.uuid, elevation);
    }
  };
  const cancelSave = () => {
    setOpen(false);
    setElevation(currentZone ? currentZone.elevation || 0 : 0);
  };
  return (
    <Modal
      size="mini"
      open={open}
      trigger={
        <PanelButton onClick={() => setOpen(true)}>
          Set Elevation (currently {currentZone?.elevation || 0}ft)
        </PanelButton>
      }
    >
      <Modal.Header>Set Elevation</Modal.Header>
      <Modal.Content>
        {open && (
          <>
            <label>Elevation (ft)</label>&nbsp;
            {currentZone && (
              <NumericFormat
                name="elevation"
                value={elevation}
                onChange={changeElevation}
              />
            )}
            {!currentZone && (
              <div>
                There is no current zone...so elevation changing isn't possible
              </div>
            )}
            <br />
            <br />
            Note: Either set elevation as it relates to distance from POC (if
            POC is set to zero). Or use actual elevation lines to determine the
            head furthest from POC in elevation loss/gain.
            <br />
            <br />
            Note 2: Don't forget to set the POC's elevation
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cancelSave}>Cancel</Button>
        <Button primary onClick={save}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
