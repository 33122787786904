import styled from 'styled-components';
import { ValveBoxLocationEditor } from '../features/valves/ValveBoxLocationTooltip';
import { ITEMNAMES } from '../shared/workbench-enums';
import { useDesignStore } from '../state';

export const ValveBoxMenu = () => {
  const selectedItems: paper.Item[] = useDesignStore(
    (state) => state.selectedItems,
  );
  const isValveBoxLocation = () =>
    selectedItems.length === 1 &&
    selectedItems[0].name === ITEMNAMES.VALVE_BOX_LOCATION;
  return (
    <Wrap style={{ display: isValveBoxLocation() ? 'block' : 'none' }}>
      {isValveBoxLocation() && (
        <ValveBoxLocationEditor item={selectedItems[0] as paper.Group} />
      )}
    </Wrap>
  );
};

const Wrap = styled.div``;
