import {
  DesignElement,
  IBackflowProduct,
  IMeter,
  IMiscItem,
  IPRV,
  IPoC,
  IPump,
  IValveProduct,
  Legend,
  Sprinkler,
} from '@shared-types';
import paper from 'src/paper';
import { itemSizes } from 'src/shared/constants';
import { groupBy, uniq } from 'underscore';
import { paperColors } from '../../../vars';
import { createSleeve } from '../features/sleeves/sleeves.paper';
import {
  defaultItem,
  getItemsByType,
  isLegend,
  isSleeve,
  paperItemStore,
} from '../helpers';
import * as nozzleTransformer from '../helpers/nozzleTransformer';
import { getState } from '../state';
import { isPaperLegend } from '../tools/paper-items/paper-legend';
import {
  PaperSprinkler,
  isSprinkler,
} from '../tools/paper-items/paper-sprinkler';
import { createSprinkler } from '../tools/sprinkler';
import { createTextBox } from './createTextBox';
import { createValveIcon } from './createValveIcon';
import { createMiscItemIcon, getColor } from './plot.helpers';

// When should legend change? This is a derived thing...so maybe it should be more aware?

interface LegendItem {
  content: string;
  icon?: paper.Item;
}

// only first item used for now
export const getLegendItemTitle = (
  placeholder: string,
  entities: any[],
): string =>
  entities.length && entities[0].product
    ? entities[0].product.title
    : placeholder;

export const legendMainline = (
  scale: number,
  mainPipe: string,
  minSize: number,
): LegendItem => {
  const mainLineIcon = new paper.Path([
    new paper.Point(0, 0),
    new paper.Point(36 / scale, 0),
  ]);
  mainLineIcon.strokeColor = getColor(paperColors.pipe);
  mainLineIcon.strokeWidth = itemSizes.pipeSize / scale;
  mainLineIcon.dashArray = itemSizes.mainDashArray(scale);
  return {
    content: `Main: ${mainPipe} (${minSize}") unless otherwise labeled`,
    icon: mainLineIcon,
  };
};
export const legendVertical = (scale: number): LegendItem => {
  const verticalLine = new paper.Path([
    new paper.Point(0, 0),
    new paper.Point(36 / scale, 0),
  ]);
  verticalLine.strokeColor = getColor(paperColors.pipe);
  verticalLine.strokeWidth = itemSizes.pipeSize / scale;
  verticalLine.dashArray = itemSizes.dripDashArray(scale);
  return {
    content: `Vertical pipe`,
    icon: verticalLine,
  };
};

export const legendLateral = (
  scale: number,
  lateralPipe: string,
  minSize: number,
): LegendItem => {
  const lateralIcon = new paper.Path([
    new paper.Point(0, 0),
    new paper.Point(36 / scale, 0),
  ]);
  lateralIcon.strokeColor = getColor(paperColors.pipe);
  lateralIcon.strokeWidth = itemSizes.pipeSize / scale;
  return {
    content: `Laterals: ${lateralPipe} (${minSize}") unless otherwise labeled`,
    icon: lateralIcon,
  };
};

export const legendSleeve = (scale: number): LegendItem => ({
  content: 'Sleeve (2x diameter of combined pipe)',
  icon: createSleeve({ x: 0, y: 0 }, { x: 5, y: 0 }, scale),
});

export const legendDripValve = (
  scale: number,
  dripValve: string,
): LegendItem => {
  const dripValveSymbol = createValveIcon(getColor('black'), true, scale);
  const dripValveString = dripValve ? `Drip: ${dripValve}` : 'Drip Valve';
  return {
    content: dripValveString,
    icon: dripValveSymbol,
  };
};

export const legendTurfValve = (
  scale: number,
  turfValve: string,
): LegendItem => {
  const valveSymbol = createValveIcon(getColor('black'), false, scale);
  const lateralValveString = turfValve ? `Turf: ${turfValve}` : 'Turf Valve';
  return {
    content: lateralValveString,
    icon: valveSymbol,
  };
};

export const legendHeads = (
  elements: DesignElement[],
  scale: number,
  headSize: number,
  headClueSize: number,
): LegendItem[] => {
  const sprinklers = elements.filter(isSprinkler).map((el) => el.props);
  const groupedHeads = groupBy(
    sprinklers,
    (sprinkler: Sprinkler) =>
      `${sprinkler.base.headSeries} : ${sprinkler.base.headModel} : ${sprinkler.base.nozzleModel}`,
  );
  const uniqHeads = Object.keys(groupedHeads).sort();
  const h = uniqHeads.map((headString: string) => {
    const headSplit = headString.split(' : ');
    const sprinkler: Sprinkler = { ...groupedHeads[headString][0] };
    const data = nozzleTransformer.getGeneratePerfData(sprinkler.base);
    const maxAngle = Math.max(...data.map((d) => d.angle));
    const radii = uniq(
      sprinklers
        .filter(
          (h) =>
            h.base.headSeries === headSplit[0] &&
            h.base.headModel === headSplit[1] &&
            h.base.nozzleModel === headSplit[2],
        )
        .map((h) => h.radius),
    );
    const sprinklerGroup = new paper.Group();
    sprinklerGroup.position = new paper.Point(0, 0);
    radii.sort((a, b) => a - b);
    radii.forEach((r: number, i: number) => {
      const point = { x: i * ((headSize * 2) / scale + 5 / scale), y: 0 };
      const spri = createSprinkler(
        { radius: r, angle: maxAngle, rotation: 45, ...point },
        sprinkler.base,
      );
      const sprinklerItem = new PaperSprinkler({
        ...defaultItem({ x: point.x, y: point.y }),
        uuid: `legend-head-${i}`,
        type: 'sprinkler',
        props: spri,
        itemType: 'design-element',
      });
      sprinklerItem.updateHeadSize();
      sprinklerItem.deleteArc();
      sprinklerItem.updateSmallArc(headClueSize);
      sprinklerItem.paperZoneDot?.remove();
      sprinklerGroup.addChild(sprinklerItem.paperClue);
      sprinklerGroup.addChild(sprinklerItem.paperHeadCircle);
      sprinklerItem.destroy();
    });
    return {
      content: `${headSplit[1]} head w/${headSplit[2]} nozzle`,
      icon: sprinklerGroup,
    };
  });
  return h;
};
export const legendMiscItems = (
  elements: DesignElement[],
  scale: number,
): LegendItem[] => {
  const miscItems = elements.filter((el) => el.type === 'miscItem');
  return uniq(miscItems, (el) => (el.props as IMiscItem).name)
    .sort((a, b) =>
      (a.props as IMiscItem).displayName.localeCompare(
        (b.props as IMiscItem).displayName,
      ),
    )
    .map((item: DesignElement): LegendItem => {
      const icon = createMiscItemIcon(item, scale);
      return {
        content: (item.props as IMiscItem).displayName,
        icon,
      };
    });
};

export const legendWaterSources = (
  elements: DesignElement[],
  scale: number,
): LegendItem[] =>
  elements
    .filter((el) => el.type === 'meter')
    .map((el) => ({
      content: `Water Source: ${(el.props as IMeter | IPoC | IPump).name}`,
      icon: createTextBox(
        { x: 0, y: 0 },
        (el.props as IMeter | IPump).name
          .split(' ')
          .map((a) => a.slice(0, 1))
          .join(''),
        scale,
      ),
    }));

export const legendPOCs = (
  elements: DesignElement[],
  scale: number,
): LegendItem[] =>
  elements
    .filter((el) => el.type === 'poc')
    .map((el) => ({
      content: (el.props as IPoC).name || 'Point of Connection',
      icon: createTextBox(
        { x: 0, y: 0 },
        (el.props as IPoC).name
          .split(' ')
          .map((a) => a.slice(0, 1))
          .join(''),
        scale,
      ),
    }));
export const createLegend = (legend: Legend, legendGroup: paper.Group) => {
  const {
    items,
    zones,
    elements,
    edges,
    elementCache,
    scale,
    mainPipe,
    mainPipeSizes,
    lateralPipe,
    lateralPipeSizes,
    headSize,
    headClueSize,
  } = getState();
  const sleeves = items.filter(isSleeve);
  const title = new paper.PointText(new paper.Point(legend.position));
  title.content = 'Legend';
  title.fontSize = 24 / scale;
  title.fontWeight = 'bold';
  const legendItems: LegendItem[] = [];

  if (mainPipe) {
    const minSize = Math.min(...mainPipeSizes);
    legendItems.push(legendMainline(scale, mainPipe, minSize));
  }

  if (edges.find((e) => !!e.vertical)) {
    legendItems.push(legendVertical(scale));
  }

  if (lateralPipe) {
    const minSize = Math.min(...lateralPipeSizes);
    legendItems.push(legendLateral(scale, lateralPipe, minSize));
  }

  if (sleeves.length) {
    legendItems.push(legendSleeve(scale));
  }

  if (zones.find((z) => z.isDrip || z.plantIds.length > 0)) {
    const dripValveZone = zones.filter(
      (z) => (z.isDrip || z.plantIds.length > 0) && !!z.valve,
    );
    const dripValveProducts = uniq(
      dripValveZone
        .filter((z) => !!elementCache[z.valve])
        .map((z) => elementCache[z.valve].props as IValveProduct)
        .map((v) => `${v.brand} ${v.name}`),
    );
    dripValveProducts.forEach((product) => {
      legendItems.push(legendDripValve(scale, product));
    });
  }

  if (zones.find((z) => !z.isDrip && z.headIds.length > 0)) {
    const turfValveZones = zones.filter(
      (z) => !z.isDrip && !z.plantIds.length && !!z.valve,
    );
    const turfValveProducts = uniq(
      turfValveZones
        .filter((z) => !!elementCache[z.valve])
        .map((z) => elementCache[z.valve].props as IValveProduct)
        .map((v) => `${v.brand} ${v.name}`),
    );
    turfValveProducts.forEach((product) => {
      legendItems.push(legendTurfValve(scale, product));
    });
  }

  legendItems.push(...legendHeads(elements, scale, headSize, headClueSize));

  legendItems.push(...legendMiscItems(elements, scale));

  const backflow = elements.find((el) => el.type === 'backflow');
  if (backflow) {
    legendItems.push({
      content: `Backflow: ${(backflow.props as IBackflowProduct).brand} ${
        (backflow.props as IBackflowProduct).name
      }`,
      icon: createTextBox({ x: 0, y: 0 }, 'B', scale),
    });
  }

  const prv = elements.find((el) => el.type === 'prv');
  if (prv) {
    legendItems.push({
      content: `PRV: ${(prv.props as IPRV).name}`,
      icon: createTextBox({ x: 0, y: 0 }, 'PRV', scale),
    });
  }

  const pump = elements.find((el) => el.type === 'pump');
  if (pump) {
    legendItems.push({
      content: `Pump: ${(pump.props as IPump).name}`,
      icon: createTextBox({ x: 0, y: 0 }, 'P', scale),
    });
  }

  const boosterPump = elements.find((el) => el.type === 'booster pump');
  if (boosterPump) {
    legendItems.push({
      content: `Booster Pump: ${(boosterPump.props as IPump).name}`,
      icon: createTextBox({ x: 0, y: 0 }, 'BP', scale),
    });
  }

  legendItems.push(...legendWaterSources(elements, scale));
  legendItems.push(...legendPOCs(elements, scale));

  const fontSize = itemSizes.legendFont / scale;
  let nextOffset = 0;
  const unadded: any[] = [];
  legendItems.forEach(({ content, icon }) => {
    const lineLimit = 75;
    const lineHeight = 2.2;
    const textPosition = lineHeight * (nextOffset * fontSize) + 24 / scale;
    if (content.length > lineLimit) {
      const lines = content.match(/\b.{1,50}/g);
      if (lines) {
        nextOffset += lines.length;
        lines.forEach((line, j) => {
          const t = new paper.PointText(
            new paper.Point(
              legend.position.x,
              legend.position.y +
                (textPosition + lineHeight * 0.8 * (j * fontSize)),
            ),
          );
          t.content = line.trim();
          t.fontSize = fontSize;
          legendGroup.addChild(t);
          if (j === 0 && icon) {
            icon.position = new paper.Point(
              t.bounds.leftCenter.x - fontSize / 2 - icon.bounds.width / 2,
              t.bounds.leftCenter.y,
            );
            legendGroup.addChild(icon);
          }
        });
        // nextOffset += 1
      } else {
        unadded.push(content, icon);
      }
    } else {
      const t = new paper.PointText(
        new paper.Point(legend.position.x, legend.position.y + textPosition),
      );
      t.content = content;
      t.fontSize = fontSize;
      nextOffset += 1;
      legendGroup.addChild(t);
      if (icon) {
        icon.position = new paper.Point(
          t.bounds.leftCenter.x - fontSize / 2 - icon.bounds.width / 2,
          t.bounds.leftCenter.y,
        );
        legendGroup.addChild(icon);
      }
    }
  });
  legendGroup.addChildren([title]);
};

export const updateLegend = () => {
  const legend = getItemsByType(isLegend);
  if (legend.length) {
    const paperLegend = paperItemStore.get(legend[0].uuid);
    if (paperLegend && isPaperLegend(paperLegend)) {
      paperLegend.update(legend[0]);
    }
  }
};
