import {
  DesignSprinklerElement,
  NozzleData,
  Polyline,
  Sprinkler,
  SprinklerBase,
  Yard,
} from '@shared-types';
import { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Icon,
  List,
  Modal,
} from 'semantic-ui-react';
import paper from 'src/paper';
import { newUUID } from '../../crypto-uuid';
import { defaultItem, paperItemStore } from '../../helpers';
import {
  generateKeyPoints,
  isInsidePoly,
  movePointAway,
} from '../../helpers/geometry.helpers';
import { getGeneratePerfData } from '../../helpers/nozzleTransformer';
import { PaperPolyline } from '../../polyline/paper-polyline';
import { getPolylineYards } from '../../polyline/polyline-helpers';
import { getPointsFromPolyline } from '../../polyline/polyline.service';
import { postWholeEdge } from '../../services/design.service';
import { getState, useDesignStore } from '../../state';
import { addElementsEdges } from '../../state/addElementsEdges';
import { modalDefaults } from '../../state/modal.service';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';
import { createSprinklerFromRaw } from '../../tools/sprinkler';

const getYardHeads = (polylineYard: Polyline<'yard'>) => {
  const elements = getState().elements;
  const polylinePoints = getPointsFromPolyline(polylineYard);
  return elements.filter(
    (e) =>
      isSprinkler(e) &&
      e.props.angle < 360 &&
      isInsidePoly(e.position, polylinePoints),
  ).length;
};

export const EdgeAlgoModalType = 'edgeAlgoModal';
export const EdgeAlgoModal = ({ closeModal }) => {
  const activeYardIndex = useDesignStore((state) => state.activeYardIndex);
  const sprinklerBases = useDesignStore((state) => state.sprinklerBases);
  const polylineYards = getPolylineYards();
  const scale = useDesignStore((state) => state.scale);
  const headSize = useDesignStore((state) => state.headSize);
  const [running, setRunning] = useState(false);
  const [selectedBases, setSelectedBases] = useState<Set<string>>(new Set());
  const [yardIndices, setYardIndices] = useState<number[]>(
    polylineYards.filter((y) => getYardHeads(y) === 0).map((_, i) => i),
  );

  useEffect(() => {
    setYardIndices(setEmptyYardIndices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedBases(new Set(sprinklerBases.map((base) => base.uuid)));
  }, [sprinklerBases]);

  const setEmptyYardIndices = () => {
    const indices: number[] = [];
    polylineYards.forEach((y, i) => {
      if (getYardHeads(y) === 0) {
        indices.push(i);
      }
    });
    return indices;
  };

  const baseCheckbox = (_, data) => {
    const newSet = new Set(selectedBases);
    if (data.checked) {
      newSet.add(data.value);
    } else {
      newSet.delete(data.value);
    }
    setSelectedBases(newSet);
  };

  const doAllEdges = async () => {
    setRunning(true);
    try {
      for (let i = 0; i < polylineYards.length; i++) {
        console.log('yard ', i);
        if (yardIndices.includes(i)) {
          await doWholeEdge(i);
        }
      }
      setRunning(false);
    } catch (err) {
      console.error(err);
      setRunning(false);
    }
  };
  const doWholeEdge = async (i: number) => {
    const polylineYards = getPolylineYards();
    setRunning(true);
    const filteredBases = sprinklerBases.filter((base) =>
      selectedBases.has(base.uuid),
    );
    const nozzleData = filteredBases.reduce(
      (acc: NozzleData[], base: SprinklerBase) => [
        ...acc,
        ...getGeneratePerfData(base),
      ],
      [],
    );
    const selectedYards = i > -1 ? [polylineYards[i]] : polylineYards;
    try {
      for (const yard of selectedYards) {
        const paperPoly = paperItemStore.get(yard.uuid);
        if (paperPoly instanceof PaperPolyline)
          if (paperPoly.paperItem) {
            const p = new paper.Path(
              (paperPoly.paperItem as paper.Path).pathData,
            );
            p.flatten(0.25);
            const points = p.segments.map((s) => ({
              x: s.point.x,
              y: s.point.y,
            }));
            const tmpOutline = new paper.Path(points);
            if (tmpOutline.clockwise) {
              points.reverse();
            }
            const bounds = p.bounds;
            tmpOutline.remove();
            const y: Yard = {
              ...defaultItem({ x: points[0].x, y: points[0].y }),
              points: points,
              height: bounds.height,
              width: bounds.width,
              keyPoints: generateKeyPoints(points, 210, 150, bounds.height),
              itemType: 'yard',
            };
            const cacheID = newUUID();

            for (let i = 0; i < y.keyPoints.length; i++) {
              console.log('edge ', i);
              const res = await postWholeEdge([y], nozzleData, cacheID, i);
              const sprinklers: Sprinkler[] = res.edgeShells
                .map((shell) => createSprinklerFromRaw(shell, filteredBases))
                .filter(Boolean);
              addElementsEdges(
                sprinklers.map((sprinkler): DesignSprinklerElement => {
                  const newHead: Sprinkler = {
                    ...sprinkler,
                    ...movePointAway(
                      sprinkler,
                      sprinkler.angle,
                      sprinkler.rotation,
                      scale,
                      headSize,
                    ),
                  };
                  return {
                    ...defaultItem({ x: newHead.x, y: newHead.y }),
                    itemType: 'design-element',
                    type: 'sprinkler',
                    props: sprinkler,
                  };
                }),
                [],
              );
            }
          }
      }
      setRunning(false);
    } catch (err) {
      console.error(err);
      setRunning(false);
    }
  };

  return (
    <Modal {...modalDefaults}>
      <Modal.Header>Edge Algorithm for Placing Heads</Modal.Header>
      <Modal.Content scrolling>
        <h5>Yards</h5>
        {polylineYards.map((y, i) => {
          const count = getYardHeads(y);
          return (
            <div
              style={{ fontWeight: i === activeYardIndex ? 'bold' : 'normal' }}
              key={i}
            >
              <Checkbox
                label={`Yard ${i + 1}`}
                checked={yardIndices.indexOf(i) > -1}
                onChange={(_, data) => {
                  const newIndices = [...yardIndices];
                  if (data.checked) {
                    newIndices.push(i);
                  } else {
                    newIndices.splice(newIndices.indexOf(i), 1);
                  }
                  setYardIndices(newIndices);
                }}
              />
              {count > 0 && (
                <span style={{ marginLeft: 16 }}>
                  <Icon name="exclamation" color="red" /> {count} edge heads
                  already
                </span>
              )}
            </div>
          );
        })}
        <Divider />
        <h5>Nozzles</h5>

        <List divided={true} verticalAlign="middle">
          {sprinklerBases.map((b, i) => (
            <List.Item key={i}>
              <span className="nozzle-name">
                <Checkbox
                  value={b.uuid}
                  checked={selectedBases.has(b.uuid)}
                  onChange={baseCheckbox}
                  label={`${b.nozzleModel} - ${b.nozzleSeries}`}
                />
              </span>
            </List.Item>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} disabled={running}>
          Cancel
        </Button>
        {!running && (
          <Button
            disabled={!yardIndices.length}
            primary
            onClick={() => doAllEdges()}
          >
            Run with {selectedBases.size} heads
          </Button>
        )}{' '}
        {running && (
          <Button primary loading={true}>
            Loading
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
