import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { ReactComponent as AHC } from '../images/ahc.svg';
import { ReactComponent as AHL } from '../images/ahl.svg';
import { ReactComponent as AHR } from '../images/ahr.svg';
import { ReactComponent as AVB } from '../images/avb.svg';
import { ReactComponent as AVC } from '../images/avc.svg';
import { ReactComponent as AVT } from '../images/avt.svg';
import { ReactComponent as HD } from '../images/hd.svg';
import { ReactComponent as VD } from '../images/vd.svg';
import { getState } from '../state';
import { changeElements } from '../state/changeElements';
import { isSprinkler } from '../tools/paper-items/paper-sprinkler';

export const AlignTools = () => {
  const distribute = (direction: 'horizontally' | 'vertically') => {
    const { selectedItems, elementCache } = getState();
    const elements = selectedItems.map((item) => elementCache[item.data.uuid]);
    // Compute the minimum and maximum values
    let min = Math.min(
      ...elements.map(
        (el) => el.position[direction === 'horizontally' ? 'x' : 'y'],
      ),
    );
    let max = Math.max(
      ...elements.map(
        (el) => el.position[direction === 'horizontally' ? 'x' : 'y'],
      ),
    );

    // Compute the distance between each point
    let interval = (max - min) / (elements.length - 1);

    // Create the new elements, preserving the original values
    const distributedEls = elements.map((el, index) => {
      return {
        ...el,
        position: {
          ...el.position,
          [direction === 'horizontally' ? 'x' : 'y']: min + index * interval,
        },
        props:
          el.type === `sprinkler`
            ? {
                ...el.props,
                [direction === 'horizontally' ? 'x' : 'y']:
                  min + index * interval,
              }
            : el.props,
      };
    });
    changeElements(distributedEls);
  };

  const align = (
    alignType: 'left' | 'right' | 'top' | 'middle' | 'bottom',
    propName: string,
  ) => {
    const { selectedItems, elementCache } = getState();
    const elements = selectedItems.map((item) => elementCache[item.data.uuid]);
    let alignmentValue: number;
    if (alignType === 'left' || alignType === 'top') {
      alignmentValue = Math.min(...elements.map((e) => e.position[propName]));
    } else if (alignType === 'middle') {
      const values = elements.map((e) => e[propName]);
      const sum = values.reduce((acc, curr) => acc + curr, 0);
      alignmentValue = sum / values.length;
    } else {
      alignmentValue = Math.max(...elements.map((e) => e.position[propName]));
    }
    const alignedEls = elements.map((e) => ({
      ...e,
      position: {
        ...e.position,
        [propName]: alignmentValue,
      },
      props: isSprinkler(e)
        ? {
            ...e.props,
            [propName]: alignmentValue,
          }
        : e.props,
    }));
    changeElements(alignedEls);
  };
  return (
    <Wrap>
      <h5>Align & Distribute</h5>
      <Button
        onClick={() => align('top', 'y')}
        title="Align Top"
        icon={<AVT />}
      />
      <Button
        onClick={() => align('middle', 'y')}
        title="Align Vertical Center"
        icon={<AVC />}
      />
      <Button
        onClick={() => align('bottom', 'y')}
        title="Align Bottom"
        icon={<AVB />}
      />
      <Button
        onClick={() => align('left', 'x')}
        title="Align Left"
        icon={<AHL />}
      />
      <Button
        onClick={() => align('middle', 'x')}
        title="Align Horizontal Center"
        icon={<AHC />}
      />
      <Button
        title="Align Horizontal Right"
        icon={<AHR />}
        onClick={() => align('right', 'x')}
      ></Button>
      <Button
        title="Distribute Vertically"
        onClick={() => distribute('vertically')}
        icon={<VD />}
      />
      <Button
        title="Distribute Horizontally"
        onClick={() => distribute('horizontally')}
        icon={<HD />}
      />
    </Wrap>
  );
};

export const Wrap = styled.div`
  padding: 8px;
  .ui.button {
    background: transparent;
    border: 0;
    color: white;
    &:hover {
      background: #333;
    }
  }
`;
