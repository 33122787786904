import { DesignElement, IEdge, IPoC, Zone } from '@shared-types';
import { IPOCDirectedGraph } from '../../../../../shared-types/pocDirectedGraph.helper';
import { POCDirectedGraph } from './pocDirectedGraph';
import { UndirectedGraph } from './undirectedGraph';

export const createMasterGraph = (
  edges: IEdge[],
  elements: DesignElement[],
): UndirectedGraph => {
  // console.time('masterGraph')
  const sources: string[] = [];
  const masterGraph = new UndirectedGraph();
  elements.forEach((el) => {
    if (el.type === 'meter' || el.type === 'pump') {
      sources.push(el.uuid);
    }
    masterGraph.addGraphNode(el.uuid);
  });
  edges.forEach((edge) => {
    masterGraph.addGraphEdge(edge.source, edge.target);
  });
  // console.timeEnd('masterGraph')
  return masterGraph;
};

export const createPOCGraphs = (
  elements: DesignElement[],
  masterGraph: UndirectedGraph,
  zones: Zone[],
): IPOCDirectedGraph[] => {
  // console.time('pocGraphs')
  const pocGraphs = elements
    .filter((el) => el.type === 'poc')
    .map((pocEl) => {
      if ((pocEl.props as IPoC).sourceID) {
        const path = masterGraph.pathToDest(
          pocEl.uuid,
          (pocEl.props as IPoC).sourceID as string,
        );
        path.forEach((_, i) => {
          if (i > 0) {
            masterGraph.deleteGraphNode(path[i]);
          }
        });
      }
      const graph = masterGraph.directGraph(pocEl.uuid);
      const p = new POCDirectedGraph(pocEl.uuid, graph, elements, zones);
      return p;
    });
  // console.timeEnd('pocGraphs')
  return pocGraphs;
};
