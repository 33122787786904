import { Confirm } from 'semantic-ui-react';
import { deleteAllHeads } from '../../paper-helpers/heads';
import { confirmDefaults } from '../../state/modal.service';

export const DeleteHeadsConfirmType = 'deleteHeadsConfirm';
export const DeleteHeadsConfirm = ({ closeModal }) => (
  <Confirm
    {...confirmDefaults}
    header="Delete All Heads"
    content="This will delete all heads in all yards"
    confirmButton="Delete"
    onConfirm={() => {
      deleteAllHeads();
      closeModal();
    }}
  />
);
