import { UserUI } from '@shared-types';
import { wrapFetch } from './fetchHelpers';

export const fetchProfile = (): Promise<{ user: UserUI }> =>
  wrapFetch(`/api/users/profile`);

export const saveProfile = (profile: UserUI): Promise<{ user: UserUI }> =>
  wrapFetch(`/api/users/profile`, 'PUT', profile);

export const changeCard = (token: string): Promise<{ user: UserUI }> =>
  wrapFetch(`/card`, 'POST', { token });

export const removeCard = (cardId: string, customerId: string): Promise<any> =>
  wrapFetch(`/api/stripe/card/${cardId}`, 'DELETE', { customerId });

export const defaultCard = (cardId: string, customerId: string): Promise<any> =>
  wrapFetch(`/api/stripe/card/default/${cardId}`, 'PUT', { customerId });

export const chargeCustomer = (customerId: string): Promise<any> =>
  wrapFetch(`/api/stripe/charge/${customerId}`, 'POST', {
    amount: '500',
  });

export const getCards = (customerId: string): Promise<{ cards: any[] }> =>
  wrapFetch(`/api/stripe/card/${customerId}`);

export const getCustomer = (customerId: string): Promise<{ customer: any }> =>
  wrapFetch(`/api/stripe/customer/${customerId}`);

export const attachCard = (customerId: string, tokenId: string): Promise<any> =>
  wrapFetch(`/api/stripe/card/add/${customerId}`, 'POST', { tokenId });
