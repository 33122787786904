import { Confirm } from 'semantic-ui-react';
import { deleteOrphanedFittings } from '../../state';
import { confirmDefaults } from '../../state/modal.service';

export const DeleteOrphansType = 'deleteOrphans';
export const DeleteOrphans = ({ closeModal }) => (
  <Confirm
    {...confirmDefaults}
    header="Delete All Orphans"
    content="This will delete all orphans (fittings) left when deleting pipes"
    confirmButton="Delete"
    onConfirm={() => {
      deleteOrphanedFittings();
      closeModal();
    }}
  />
);
