import { IPoint, Legend } from '@shared-types';
import paper from 'src/paper';
import { defaultItem } from '../../helpers';
import { createLegend } from '../../paper-helpers/legend';
import { getColor } from '../../paper-helpers/plot.helpers';
import { PaperItem } from './paper-item';

export const isPaperLegend = (item: PaperItem<any>): item is PaperLegend => {
  return item instanceof PaperLegend;
};
export class PaperLegend implements PaperItem<Legend> {
  private _group = new paper.Group();
  _item: Legend = {
    ...defaultItem(),
    itemType: 'legend',
  };
  get highlight() {
    return this._group.getItem({ name: 'highlight' });
  }
  get group() {
    return this._group;
  }
  private set group(group: paper.Group) {
    this._group = group;
  }
  constructor(item: Legend) {
    this.group.name = item.itemType;
    this.group.data = { uuid: item.uuid };
    this.create(item);
  }
  getUUID() {
    return this._item.uuid;
  }
  create(item: Legend) {
    this.group.removeChildren();
    createLegend(item, this.group);
    this._item = item;
  }
  update(item: Legend) {
    if (this.group.layer.visible) {
      const selected = this.highlight ? true : false;
      this.create(item);
      this._item = item;
      if (selected) {
        this.setSelectedStatus(true);
      }
    }
  }
  setSelectedStatus(selected: boolean) {
    if (this.highlight) {
      this.highlight.remove();
    }
    if (selected) {
      const rect = new paper.Rectangle(this.group.bounds);
      const highlight = new paper.Path.Rectangle(rect);
      highlight.strokeWidth = 0.2;
      highlight.strokeColor = getColor('#94baf7');
      highlight.fillColor = null;
      highlight.name = 'highlight';
      highlight.locked = true;
      this.group.addChild(highlight);
    }
  }
  destroy() {
    this.group.remove();
  }
  getItem() {
    return this.group;
  }
  setPosition(point: IPoint): void {
    this.group.position = new paper.Point(point);
  }
  setPivot(point: IPoint): void {
    this.group.pivot = new paper.Point(point);
  }
  toggleHighlight(show: boolean): void {
    this.setSelectedStatus(show);
  }
}
