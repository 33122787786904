import { DesignElement } from '@shared-types';
import { mapEls } from '../caches/element.cache';
import { addUndo } from '../contexts/design/undo';
import { paperItemStore } from '../helpers';
import { generateGraphs } from '../helpers/directedGraph';
import { hopPipes } from '../helpers/hopping';
import { updateLegend } from '../paper-helpers/legend';
import { updateAllHeadColors } from '../paper-helpers/plot.helpers';
import { updateDotOwnership } from './coverage';
import { getState, setState } from './state-basics';

export const changeElements = (payload: DesignElement[], undoable = true) => {
  const { edges, zones, scale, elements, groups } = getState();
  const oldEls = payload
    .map((el) => getState().elementCache[el.uuid])
    .filter((e) => !!e);
  if (undoable) {
    addUndo(() => {
      changeElements(oldEls, false);
    }, 'Change Elements');
  }
  const newElements = elements.map(
    (oldEl) => payload.find((el) => el.uuid === oldEl.uuid) || oldEl,
  );
  const elementCache = mapEls(newElements);
  let masterGraph = getState().masterGraph;
  let pocGraphs = getState().pocGraphs;
  const newGraphs = generateGraphs(edges, newElements, zones);
  masterGraph = newGraphs.masterGraph;
  pocGraphs = newGraphs.pocGraphs;
  const newEdges = hopPipes(
    zones,
    edges,
    newElements,
    elementCache,
    scale,
    pocGraphs,
  );
  setState({
    elements: newElements,
    masterGraph,
    pocGraphs,
    elementCache,
    edges: newEdges,
  });
  updateAllHeadColors(zones, groups);
  payload.forEach((el) => {
    const paperItem = paperItemStore.get(el.uuid);
    if (paperItem) {
      paperItem.update(el);
    }
  });
  newEdges.forEach((edge) => {
    const paperItem = paperItemStore.get(edge.uuid);
    if (paperItem) {
      paperItem.update(edge);
    }
  });
  updateDotOwnership(payload, elementCache);
  updateLegend();
};
