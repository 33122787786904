import { BaseStyledItem } from '@shared-types';
import { PanelInputWrap } from './PanelButton';

interface Props {
  item: BaseStyledItem;
  onChange: (item: BaseStyledItem) => void;
}
export const EditOpacity = ({ item, onChange }: Props) => {
  const handleChange = (e: any) => {
    const newValue = e.target.value ? parseFloat(e.target.value) : 0;
    onChange({
      ...item,
      style: {
        ...item.style,
        opacity: newValue / 100,
      },
    });
  };
  return (
    <PanelInputWrap
      label={'Opacity (%)'}
      onChange={handleChange}
      type="number"
      min={0}
      step={1}
      max={100}
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue >= 0 && floatValue <= 100;
      }}
      value={Math.round(
        (item.style.opacity !== undefined ? item.style.opacity : 1) * 100,
      )}
    />
  );
};
