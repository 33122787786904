import { Subject } from 'rxjs';
import { DesignElement, Tools } from '../../../../shared-types/workbench-types';
import { CustomEventType } from './shared/workbench-enums';
import { toolService } from './tool.service';
import { modeService } from './tools/mode.service';

export enum PaperEvType {
  PAPER_ELEMENT_CHANGE = 'paper-change-element',
  CHANGE_TOOL = 'change-paper-tool',
}

const dispatch = (type: PaperEvType | CustomEventType, payload: any) => {
  window.dispatchEvent(
    new CustomEvent(type, {
      detail: payload,
    }),
  );
};

export const paperChangeEvent = (el: DesignElement) => {
  // element change initiated from paper, change app data
  dispatch(PaperEvType.PAPER_ELEMENT_CHANGE, el);
};

export const toolChangeEvent = (tool: Tools) => {
  // a paper.js tool has changed. typically we wipe other tool ghosts out here
  toolService.activeTool$.next(tool);
  modeService.setSubMode('move');
  dispatch(PaperEvType.CHANGE_TOOL, tool);
};
export const smartSearchCommand$ = new Subject<string>();
export const doCommand = (command: string) => {
  smartSearchCommand$.next(command);
};
