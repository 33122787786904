import { useEffect, useState } from 'react';
import { Rectangle } from '../../../../../shared-types';
import { updateItem } from '../state/item-state';
import { PanelInputWrap, PanelLabel, PanelWrap } from './PanelButton';

interface Props {
  item: Rectangle;
}
export const RectangleActions = ({ item }: Props) => {
  const [localItem, setLocalItem] = useState<Rectangle>(item);

  useEffect(() => {
    setLocalItem(item);
  }, [item]);

  useEffect(() => {
    setLocalItem(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.position]);

  return (
    <PanelWrap>
      <PanelLabel>Rectangle Actions</PanelLabel>
      <PanelInputWrap
        label="Width"
        type="number"
        value={localItem.width}
        step={0.1}
        onChange={(e) => {
          const newRectangle: Rectangle = {
            ...localItem,
            width: parseFloat(e.target.value),
          };
          setLocalItem(newRectangle);
          updateItem(newRectangle);
        }}
      />
      <PanelInputWrap
        label="Height"
        type="number"
        value={localItem.height}
        step={0.1}
        onChange={(e) => {
          const newRectangle: Rectangle = {
            ...localItem,
            height: parseFloat(e.target.value),
          };
          setLocalItem(newRectangle);
          updateItem(newRectangle);
        }}
      />
      <PanelInputWrap
        type="number"
        label="Rotation"
        value={localItem.rotation}
        step={0.1}
        onChange={(e) => {
          const newRectangle: Rectangle = {
            ...localItem,
            rotation: parseFloat(e.target.value),
          };
          setLocalItem(newRectangle);
          updateItem(newRectangle);
        }}
      />
    </PanelWrap>
  );
};
