// import { Elements } from '@stripe/react-stripe-js';
// import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Grid, Header } from 'semantic-ui-react';
import { AccountForm } from 'src/components/AccountForm';
import { PortalContext } from 'src/contexts/portal/portal';
import { ProfileContext } from 'src/contexts/profile/profile';
import { Projects } from 'src/pages/Projects/Projects';
import { base } from 'src/styles/common';
import styled from 'styled-components';

export const ContractorDashboard = () => {
  const { user } = useContext(ProfileContext);
  const { loadProjects, loadCatalog } = useContext(PortalContext);
  // const { cards } = stripeInfo;
  // const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const stripePromise = await loadStripe(
  //         process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
  //       );
  //       setStripePromise(stripePromise);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   })();
  // }, []);
  // const [creditGroups, setCreditGroups] = useState({})
  // const [creditsLoaded, setCreditsLoaded] = useState(false)
  // const [bulkModal, setBulkModal] = useState(false)

  // const loadCredits = async () => {
  //   if (user) {
  //     const x = await getCreditsByContract(user.email)
  //     const groups = groupBy(x.rows, 'bulkContractId')
  //     console.log(groups)
  //     setCreditGroups(groups)
  //   }
  //   setCreditsLoaded(true)
  // }

  useEffect(() => {
    // todo: this loads twice for some reason
    loadProjects();
    loadCatalog();
    // loadBulkContracts()
    // loadCredits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrap>
      {/* {!!stripePromise && (
        <Elements stripe={stripePromise}> */}
      <Header as="h1">Contractor Dashboard</Header>
      <Grid stackable={true}>
        <Grid.Column width={4}>
          <div className="card-gallery">
            <Card fluid={true}>
              <Card.Content>
                <Card.Header>Your Information</Card.Header>
              </Card.Content>
              {user && (
                <Card.Content>
                  <AccountForm user={user} />
                </Card.Content>
              )}
            </Card>
          </div>
        </Grid.Column>
        <Grid.Column width={12}>
          <div className="card-gallery">
            <Card fluid={true}>
              <Card.Content>
                <Card.Header>Projects</Card.Header>
              </Card.Content>
              <Card.Content>
                <Projects
                  showDesigns={false}
                  showFiles={false}
                  showFilters={false}
                />
                <br />
                <Card.Content>
                  <Link to="/app/project-request">+ New Project Request</Link>
                </Card.Content>
              </Card.Content>
              <Card.Meta />
            </Card>
            <Card fluid>
              <Card.Content>
                By continuing to use irrigationstudio.com you agree to our{' '}
                <a href="/terms">terms of service</a>.
              </Card.Content>
            </Card>
            {/* <Card fluid={true}>
                  <Card.Content>
                    <Card.Header>Payment Method</Card.Header>
                  </Card.Content>
                  {user && <Card.Content>{cards && <CardForm />}</Card.Content>}
                </Card> */}
            {/* <Card fluid={true}>
              <Card.Content>
                <Card.Header>Bulk Contracts</Card.Header>
              </Card.Content>
              <Card.Content>
                <BulkContracts />
                <Button size="mini" color={'blue'} onClick={() => setBulkModal(true)}>
                  + New Bulk Contract
                </Button>
              </Card.Content>
            </Card> */}
          </div>
        </Grid.Column>
      </Grid>
      {/* <Modal open={bulkModal} size="small">
        <BulkContractForm onSave={() => setBulkModal(false)} />
      </Modal> */}
      {/* </Elements> */}
      {/* )} */}
    </Wrap>
  );
};

const Wrap = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${base * 2}px;
  .card-gallery {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    > .ui.card {
      margin: ${base}px;
    }
  }
`;
