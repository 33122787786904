import { IPoint } from '@shared-types';
import { takeUntil } from 'rxjs';
import paper from 'src/paper';
import { growingGrid } from '../components/Tester/growingGrid';
import { PaperEvType } from '../custom-events';
import { getColor } from '../paper-helpers/plot.helpers';
import { getPolylineYards } from '../polyline/polyline-helpers';
import { getPointsFromPolyline } from '../polyline/polyline.service';
import { getState } from '../state';
import { ToolService } from '../tool.service';

export const setupGridTool = (toolService: ToolService) => {
  let panning = false;
  let startPoint: IPoint | null = null;
  const newGrid = new paper.Group();
  newGrid.name = 'grid-point-proposal';

  window.addEventListener(PaperEvType.CHANGE_TOOL, () => {
    newGrid.removeChildren();
    growingGrid.reset();
  });

  const onKeyDown = (e: paper.KeyEvent) => {
    if (e.key === 'escape') {
      startPoint = null;
      newGrid.removeChildren();
    }
  };

  const onMouseUp = (e: paper.ToolEvent) => {
    if (panning) {
      panning = false;
    } else {
      if (!startPoint) {
        startPoint = e.point;
      }
      newGrid.removeChildren();
      // let yard = getState().yards[getState().activeYardIndex];
      const polylineYards = getPolylineYards();
      const yard = polylineYards[getState().activeYardIndex];
      const yardPoints = getPointsFromPolyline(yard);
      // console.time('grid')
      // const shiftPointX = 5
      // const shiftPointY = 5
      // const rotations = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45]
      let bestPoints: IPoint[] = [];
      // for (let i = 0; i < rotations.length; i++) {
      //   for (let j = 0; j < shiftPointX; j++) {
      //     for (let k = 0; k < shiftPointY; k++) {
      const points = growingGrid.growAGrid(yardPoints, 0, {
        x: e.point.x,
        y: e.point.y,
      });
      // if (points.length > bestPoints.length) {
      //   console.log(
      //     'is better, with shift',

      //     'points length:',
      //     points.length,
      //   )
      bestPoints = points;
      // }
      //     }
      //   }
      // }
      // console.timeEnd('grid')
      bestPoints.forEach((point) => {
        const circle = new paper.Path.Circle(point, growingGrid.radius);
        circle.fillColor = getColor('#000');
        circle.opacity = 0.2;
        const cenCircle = new paper.Path.Circle(point, 1);
        cenCircle.fillColor = getColor('#000');
        newGrid.addChildren([circle, cenCircle]);
      });
    }
  };

  toolService
    .keyDown$('grid')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onKeyDown);
  toolService
    .mouseUp$('grid')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseUp);
};
