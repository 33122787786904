import { DesignElement, IMiscItem, IPoint } from '@shared-types';
import paper from 'src/paper';

import { defaultItem } from '../../helpers';
import {
  renderMiscEl,
  renderUnknownEl,
  renderValveEl,
} from '../../paper-helpers/plot.helpers';
import { ITEMNAMES } from '../../shared/workbench-enums';
import { PaperItem } from './paper-item';

export const isPaperElement = (item: PaperItem<any>): item is PaperElement => {
  return item instanceof PaperElement;
};

export class PaperElement implements PaperItem<DesignElement> {
  private _element: DesignElement = {
    ...defaultItem({ x: 0, y: 0 }),
    type: 'fitting',
    props: {} as IMiscItem,
    itemType: 'design-element',
  };
  private _item: paper.Group;

  constructor(element: DesignElement) {
    // console.log('init element')
    this._element = element;
    this._item = new paper.Group();
    this._item.data = element;
    this.render();
  }
  setPosition(point: IPoint): void {
    throw new Error('Method not implemented.');
  }
  getItem(): paper.Item {
    return this._item;
  }
  setPivot(point: IPoint): void {
    throw new Error('Method not implemented.');
  }
  toggleHighlight(): void {
    throw new Error('Method not implemented.');
  }
  get element() {
    return this._element;
  }
  set element(el: DesignElement) {
    this._element = el;
  }
  get paperItem() {
    return this._item;
  }
  set paperItem(item) {
    this._item = item;
  }
  destroy = () => {
    this.paperItem.remove();
  };
  update = (el: DesignElement) => {
    this.element = el;
    this.paperItem.data = el;
    this.render(true);
  };
  private render = (replace = false) => {
    if (!replace) {
      this.paperItem.remove();
      this.paperItem = this.generateItem(this.element);
    } else {
      this.paperItem.layer.activate();
      let selected = this.paperItem.selected;
      this.paperItem.remove();
      this.paperItem = this.generateItem(this.element);
      this.paperItem.selected = selected;
    }
    this.paperItem.opacity = 1;
    this.paperItem.name = ITEMNAMES.ELEMENT;
    this.paperItem.data = this.element;
  };
  private generateItem = (el: DesignElement): paper.Group => {
    if (
      el.type === 'miscItem' ||
      el.type === 'fitting' ||
      el.type === 'poc' ||
      el.type === 'prv' ||
      el.type === 'meter' ||
      el.type === 'pump' ||
      el.type === 'booster pump' ||
      el.type === 'backflow'
    ) {
      return renderMiscEl(el, el.position);
    } else if (el.type === 'valve') {
      return renderValveEl(el, el.position);
    } else {
      return renderUnknownEl(el, el.position);
    }
  };
}
