import {
  ArcValues,
  DesignElement,
  DesignElementMap,
  IBackflowProduct,
  IMeter,
  IMiscItem,
  IPoC,
  IPoint,
  IPump,
  IValveProduct,
  ValveBox,
  WaterGroup,
  Zone,
} from '@shared-types';
import { useEffect } from 'react';
import paper from 'src/paper';
import { paperItemStore } from '../helpers';
import { localPaper } from '../localPaper';
import { setVisibleLayersFromPaperState } from '../shared/layers';
import { ITEMNAMES, LAYER_NAMES } from '../shared/workbench-enums';
import { getState } from '../state';
import { PaperElement } from '../tools/paper-items/paper-element';
import { PaperItem } from '../tools/paper-items/paper-item';
import {
  PaperSprinkler,
  isPaperSprinkler,
  isSprinkler,
} from '../tools/paper-items/paper-sprinkler';
import { createDripTransition } from './createDripTransition';
import { createGateValve } from './createGateValve';
import { createMasterValve } from './createMasterValve';
import { createQuickCoupler } from './createQuickCoupler';
import { createTextBox } from './createTextBox';
import { createTextShape } from './createTextShape';
import {
  renderValve,
  renderValveBoxes,
  renderValveInfoBoxes,
} from './valve.paper.helpers';

const colorCache = {};

export const getColor = (color: string): paper.Color => {
  if (!colorCache[color]) {
    colorCache[color] = new paper.Color(color);
  }
  return colorCache[color];
};

export const transparentColor = (color: string, alpha = 0.7): paper.Color => {
  const twhite = getColor(color);
  twhite.alpha = alpha;
  return twhite;
};

export const findNamedItems = (
  name: ITEMNAMES | string,
  inActiveLayer?: boolean,
) =>
  inActiveLayer
    ? localPaper.project.activeLayer.getItems({ name })
    : localPaper.project.getItems({ name });

export const removeNamedItems = (names: ITEMNAMES[]) => {
  names.forEach((name: ITEMNAMES) =>
    findNamedItems(name).forEach((h) => h.remove()),
  );
};
// TODO: test and fix this method, should be root of pipe hop issue
export const getArcPoints = (
  degrees: number,
  center: IPoint,
  radius: number,
): ArcValues => ({
  from: [center.x + radius, center.y],
  through: [
    center.x + Math.cos((Math.PI / 180) * (degrees / 2)) * radius,
    center.y + Math.sin((Math.PI / 180) * (degrees / 2)) * radius,
  ],
  to: [
    center.x + Math.cos((Math.PI / 180) * degrees) * radius,
    center.y + Math.sin((Math.PI / 180) * degrees) * radius,
  ],
});

export const createArc = (
  { from, through, to }: ArcValues,
  rotation: number,
  origin: paper.Point,
): paper.Path => {
  // TODO: creating a cache caused issues in the legend
  const arc = new paper.Path.Line({
    from: origin,
    to: new paper.Point(from),
  });
  const arcCurve = new paper.Path.Arc({ from, through, to });
  const arcEnd = new paper.Path.Line({
    from: origin,
    to: new paper.Point(to),
  });
  // join from, to, and arc together to form coverage
  arc.applyMatrix = false;
  arc.join(arcCurve);
  arc.join(arcEnd);
  arcCurve.remove();
  arcEnd.remove();
  arc.rotate(rotation, origin);
  return arc;
};

export const findOrCreateLayer = (
  name: LAYER_NAMES,
  startHidden = false,
): paper.Layer => {
  let layer = localPaper.project.layers.find((layer) => layer.name === name);
  if (!layer) {
    layer = localPaper.project.addLayer(new paper.Layer({ name }));
  }
  if (startHidden) {
    layer.visible = false;
  }
  return layer;
};

export const activateNamedLayer = (name: LAYER_NAMES) => {
  const layer = findOrCreateLayer(name);
  layer.activate();
};

export const showNamedLayer = (name: LAYER_NAMES) => {
  const layer = findOrCreateLayer(name);
  layer.visible = true;
  setVisibleLayersFromPaperState();
};
export const hideNamedLayer = (name: LAYER_NAMES) => {
  const layer = findOrCreateLayer(name);
  layer.visible = false;
  setVisibleLayersFromPaperState();
};

// export const plotSVG = (svg: string, scale: number) => {
//   activateNamedLayer(LAYER_NAMES.SVG);
//   removeNamedItems([ITEMNAMES.SVG_OUTLINE, ITEMNAMES.SVG_RASTER]);
//   const svgOutlines = localPaper.project.importSVG(svg);
//   if (svgOutlines) {
//     const plants = svgOutlines.getItem({ name: 'Plants' });
//     if (plants) {
//       plants.removeChildren();
//     }
//     // TODO: Remove Beds and Turf items before storing as the svg
//     svgOutlines.name = ITEMNAMES.SVG_OUTLINE;
//     svgOutlines.locked = true;
//     svgOutlines.scale(1 / scale, new paper.Point(0, 0));
//     svgOutlines.opacity = 0.15;
//     svgOutlines.strokeWidth = 0;
//   }
//   localPaper.project.activeLayer.visible = false;
//   activateNamedLayer(LAYER_NAMES.DEFAULT);
// };
export const updateAllHeadColors = (zones: Zone[], groups: WaterGroup[]) => {
  const heads = getState().elements.filter(isSprinkler);
  let unencounteredHeads = new Set(heads.map((h) => h.uuid));
  zones.forEach((zone) => {
    zone.headIds.forEach((id) => {
      const item = paperItemStore.get(id);
      if (item && isPaperSprinkler(item)) {
        unencounteredHeads.delete(id);
        item.updateColor(zone.color);
      }
    });
  });
  groups.forEach((group) => {
    group.headIds.forEach((id) => {
      const item = paperItemStore.get(id);
      if (item && isPaperSprinkler(item)) {
        unencounteredHeads.delete(id);
        item.updateColor(group.color);
      }
    });
  });
  for (const id of unencounteredHeads) {
    const item = paperItemStore.get(id);
    if (item && isPaperSprinkler(item)) {
      item.updateColor('black');
    }
  }
};

export const createMiscItemIcon = (
  el: DesignElement,
  scale: number,
): paper.Item => {
  const item = el.props as IMiscItem | IMeter | IPump | IPoC | IBackflowProduct;
  let icon: paper.Item;
  const point = { x: 0, y: 0 };
  if (item.name === 'Fitting') {
    icon = new paper.Path.Circle(new paper.Point(point), 3 / scale);
    icon.fillColor = getColor('red');
    icon.name = ITEMNAMES.FITTING;
  } else if (el.type === 'backflow') {
    icon = createTextBox(point, 'B', scale);
  } else if (el.type === 'prv') {
    icon = createTextBox(point, 'PRV', scale);
  } else if (el.type === 'pump') {
    icon = createTextBox(point, 'P', scale);
  } else if (el.type === 'booster pump') {
    icon = createTextBox(point, 'BP', scale);
  } else if (el.type === 'poc') {
    icon = createTextBox(
      point,
      (el.props as IPoC).name
        .split(' ')
        .map((a) => a.slice(0, 1))
        .join(''),
      scale,
    );
  } else {
    if (item.name === 'Gate Valve') {
      icon = createGateValve(point, scale);
    } else if (item.name === 'Drip Transition') {
      icon = createDripTransition(point, scale);
    } else if (item.name === 'Quick Coupler') {
      icon = createQuickCoupler(point, scale);
    } else if (item.name === 'Master Valve') {
      icon = createMasterValve(point, scale);
    } else if (item.name === 'Drain Valve') {
      icon = createTextShape(point, 6, scale, 'DV', 30);
    } else if (item.name === 'Air Relief Valve') {
      icon = createTextShape(point, 6, scale, 'A', 30);
    } else if (item.name === 'Hose Bib') {
      // icon = createTextShape(point, 6, scale, 'HB', 30);
      icon = createTextBox(point, 'HB', scale);
    } else {
      icon = createTextBox(
        point,
        item.name
          .split(' ')
          .map((a) => a.slice(0, 1))
          .join(''),
        scale,
      );
      icon.name = ITEMNAMES.MISC_ITEM;
    }
  }
  return icon;
};

export const renderValveEl = (
  el: DesignElement,
  point: IPoint,
): paper.Group => {
  const props = el.props as IValveProduct;
  const { zones, scale } = getState();
  const g = new paper.Group();
  g.name = ITEMNAMES.ELEMENT;
  g.data = el;
  const zone = zones.find((z) => z.valve === el.uuid);
  if (zone) {
    const valve = renderValve(props, zone, scale);
    g.pivot = valve.firstChild.position;
    g.addChild(valve);
  }
  g.position = new paper.Point(point);
  return g;
};
export const renderUnknownEl = (
  el: DesignElement,
  point: IPoint,
): paper.Group => {
  const g = new paper.Group();
  g.name = ITEMNAMES.ELEMENT;
  g.data = el;
  const c = new paper.Path.Circle(new paper.Point(point), 2);
  c.fillColor = getColor('orange');
  c.strokeColor = getColor('black');
  c.strokeWidth = 2;
  g.addChild(c);
  g.position = new paper.Point(point);
  return g;
};
export const renderMiscEl = (el: DesignElement, point: IPoint): paper.Group => {
  const { scale } = getState();
  const g = new paper.Group();
  g.name = ITEMNAMES.ELEMENT;
  g.data = el;
  g.addChild(createMiscItemIcon(el, scale));
  g.rotate((el.props as IMiscItem).rotation || 0);
  g.position = new paper.Point(point);
  return g;
};
export const renderElements = (elements: DesignElement[]) => {
  elements.forEach((el: DesignElement) => {
    if (isSprinkler(el)) {
      activateNamedLayer(LAYER_NAMES.HEADS);
      const item = new PaperSprinkler(el);
      paperItemStore.set(el.uuid, item);
      if (elements.length === 1) {
        // turn on the arcs when adding heads one at a time
        // issue: when edge algo only produces 1 head for a small line, this will turn on the arc
        item.toggleArc(true);
      }
    } else {
      activateNamedLayer(LAYER_NAMES.ELEMENTS);
      const item = new PaperElement(el);
      paperItemStore.set(el.uuid, item);
    }
  });
  activateNamedLayer(LAYER_NAMES.DEFAULT);
};

export const useZoneRenderingEffect = (
  zones: Zone[] | null,
  valveBoxes: ValveBox[],
  elements: DesignElement[],
  scale: number,
  zoneNumberOffset: number,
  groups: WaterGroup[],
  elementCache: DesignElementMap,
  showValveBoxPreviews: boolean,
  selectedItems: paper.Item[],
) => {
  useEffect(() => {
    if (zones) {
      renderValveBoxes(
        valveBoxes,
        zones,
        scale,
        elementCache,
        showValveBoxPreviews,
        selectedItems,
      );
    }
  }, [
    zones,
    valveBoxes,
    elements,
    scale,
    zoneNumberOffset,
    groups,
    elementCache,
    showValveBoxPreviews,
    selectedItems,
  ]);
};
export const useZoneBoxRenderingEffect = (
  zones: Zone[] | null,
  groups: WaterGroup[],
  valveBoxes: ValveBox[],
  elements: DesignElement[],
  scale: number,
  zoneNumberOffset: number,
  showValveInfoBoxNumbers: boolean,
) => {
  useEffect(() => {
    if (zones) {
      renderValveInfoBoxes(
        zones,
        groups,
        elements,
        valveBoxes,
        scale,
        showValveInfoBoxNumbers,
      );
    }
  }, [
    zones,
    groups,
    valveBoxes,
    elements,
    scale,
    zoneNumberOffset,
    showValveInfoBoxNumbers,
  ]);
};

export const blurElements = () => {
  document
    .querySelectorAll('input,textarea,button')
    .forEach(function (element) {
      if (element === document.activeElement) {
        return (
          element as HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement
        ).blur();
      }
    });
};

export const centerOnPaperItem = (item: PaperItem<any>) => {
  const position = item.getItem().position;
  localPaper.project.view.center = position;
};
