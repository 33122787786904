import { Tools } from '@shared-types';
import { doCommand } from './custom-events';
import {
  getState,
  setActiveTool,
  setLassoMode,
  setOrtho,
  setPipeSnap,
} from './state';
import { deleteItem } from './state/item-state';
import { deleteSelectedItems } from './tools/tool.helpers';

export const keyupHandler = (
  e: KeyboardEvent,
  isPaper: boolean,
  activeTool: Tools,
) => {
  const currentFocus = document.activeElement;
  if (
    currentFocus &&
    (currentFocus.tagName === 'INPUT' || currentFocus.tagName === 'TEXTAREA')
  ) {
    return;
  }
  if (isPaper) {
    // e.stopPropagation()
    switch (e.code) {
      case 'KeyV':
        console.log('switch to arrow mode');
        setActiveTool('Arrow');
        break;
      case 'KeyL':
        console.log('switch to lasso tool');
        setActiveTool('Lasso');
        break;
      case 'KeyM':
        console.log('switch to marquee tool');
        setActiveTool('Marquee');
        break;
      case 'KeyO':
        console.log('set ortho mode');
        if (activeTool === 'Pipes') {
          setOrtho(!getState().ortho);
        }
        break;
      case 'KeyS':
        if (activeTool === 'Pipes') {
          console.log('set snap mode');
          setPipeSnap(!getState().pipeSnap);
        }
        break;
      case 'Backspace':
        deleteSelectedItems();
        const selectedIDs = getState().selectedIDs;
        selectedIDs.forEach((id) => {
          deleteItem(id);
        });

        break;
      case 'Digit1':
        if (
          activeTool === 'Lasso' ||
          activeTool === 'Marquee' ||
          activeTool === 'Arrow'
        ) {
          setLassoMode('All');
        }
        break;
      case 'Digit2':
        if (
          activeTool === 'Lasso' ||
          activeTool === 'Marquee' ||
          activeTool === 'Arrow'
        ) {
          setLassoMode('Heads');
        }
        break;
      case 'Digit3':
        if (
          activeTool === 'Lasso' ||
          activeTool === 'Marquee' ||
          activeTool === 'Arrow'
        ) {
          setLassoMode('Plants');
        }
        break;
      // forward slash
      case 'Slash':
        doCommand('open-search');
        break;
    }
  }
};
