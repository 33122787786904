import { filter, map, merge, switchMap, takeUntil, tap } from 'rxjs';

import paper from 'src/paper';
import { IPoint } from '../../../../../shared-types';
import { polyHintPurple } from '../../../shared';
import { localZoom } from '../localPaper';
import { getColor } from '../paper-helpers/plot.helpers';
import { getEuclideanDistance } from '../shared/geometry';
import { toolService } from '../tool.service';

const { mouseUp$, mouseMove$, mouseDown$ } = toolService.getEvents('ruler');

const drawRuler$ = mouseUp$.pipe(
  map((e) => {
    const point = { x: e.downPoint.x, y: e.downPoint.y };
    const line = new paper.Path([new paper.Point(point)]);
    line.strokeWidth = 2 / (localZoom() || 1);
    line.strokeColor = getColor(polyHintPurple);
    const distanceText = new paper.PointText({
      point: new paper.Point(point),
      fontSize: 18 / (localZoom() || 1),
      justification: 'center',
      content: '',
    });
    return { point, line, distanceText };
  }),
  switchMap(
    ({
      point,
      line,
      distanceText,
    }: {
      point: IPoint;
      line: paper.Path;
      distanceText: paper.PointText;
    }) => {
      return mouseMove$.pipe(
        map((e) => {
          line.removeSegments();
          line.add(new paper.Point(point), new paper.Point(e.point));
          const d = getEuclideanDistance(point, e.point);
          distanceText.content = d.toFixed(2) + "'";
          distanceText.position = new paper.Point({
            x: e.point.x,
            y: e.point.y + 32 / (localZoom() || 1),
          });
        }),
        takeUntil(
          merge(
            toolService.escapeKey$,
            toolService.activeTool$.pipe(filter((mode) => mode !== 'ruler')),
            mouseDown$,
          ).pipe(
            tap(() => {
              distanceText.remove();
              line.remove();
            }),
          ),
        ),
      );
    },
  ),
);

export const rulerStreams$ = merge(drawRuler$);
