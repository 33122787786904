import { GraphStructure } from '@shared-types';

export class UndirectedGraph {
  graph: GraphStructure = {};

  addGraphEdge = (a: string, b: string) => {
    // TODO: what if graph node exists and is already a child of another thing??
    this.addGraphNode(a);
    this.graph[a].add(b);
    this.addGraphNode(b);
    this.graph[b].add(a);
  };
  addGraphNode = (node: string) => {
    if (!this.graph[node]) {
      this.graph[node] = new Set();
    }
  };
  deleteGraphNode = (node: string) => {
    // iterate over each of the node's adjacent nodes and delete original
    if (this.graph[node]) {
      const adjacents = [...this.graph[node]];
      for (let i = 0; i < adjacents.length; i++) {
        const adjacentList = this.graph[adjacents[i]];
        if (adjacentList && adjacentList.has(node)) {
          adjacentList.delete(node);
        }
      }
      // delete the node
      delete this.graph[node];
    }
  };
  // deleteGraphEdge = (a: string, b: string) => {
  //   if (this.graph[a] && this.graph[a].has(b)) {
  //     this.graph[a].delete(b);
  //   }
  //   if (this.graph[b] && this.graph[b].has(a)) {
  //     this.graph[b].delete(a);
  //   }
  // };
  hasNode = (node: string): boolean => {
    return !!this.graph[node];
  };
  pathToDest = (root: string, dest: string): string[] => {
    if (!this.hasNode(dest)) {
      return [];
    }
    const visited = new Set();
    const stack: { node: string; path: string[] }[] = [
      { node: root, path: [] },
    ];

    while (stack.length > 0) {
      const { node, path } = stack.pop() as { node: string; path: string[] };

      if (!visited.has(node)) {
        visited.add(node);

        if (node === dest) {
          const finalPath = [...path, node];
          return finalPath;
        }

        const adjacents = this.graph[node] ? [...this.graph[node]] : [];
        for (let adj of adjacents) {
          if (!visited.has(adj)) {
            stack.push({ node: adj, path: [...path, node] });
          }
        }
      }
    }
    return [];
  };
  directGraph = (v: string): GraphStructure => {
    // only returns back a temporarily directed version of this undirected graph
    let newGraph: GraphStructure = {};
    let visited = new Set<string>([v]);
    let q = [v];
    while (q.length) {
      const currentNode = q.shift();
      if (currentNode) {
        for (let child of this.graph[currentNode]) {
          if (!visited.has(child)) {
            visited.add(child);
            if (!newGraph[currentNode]) {
              newGraph[currentNode] = new Set<string>();
            }
            newGraph[currentNode].add(child);
            newGraph[child] = new Set<string>();
            q.push(child);
          }
        }
      }
    }
    return newGraph;
  };
  fullyConnected = (): boolean => {
    const nodes = Object.keys(this.graph);
    const randomRoot = nodes[0];
    const directedGraph = this.directGraph(randomRoot);
    return Object.keys(directedGraph).length === nodes.length;
  };
  isCyclic = () => {
    // https://www.geeksforgeeks.org/detect-cycle-undirected-graph/
    const keys = Object.keys(this.graph);
    let visited = new Set<string>();
    for (let key of keys) {
      if (!visited.has(key)) {
        if (this._isCyclicUtil(key, visited)) {
          return true;
        }
      }
    }
    return false;
  };
  _isCyclicUtil = (
    currentNode: string,
    visited: Set<string>,
    parent?: string,
  ) => {
    visited.add(currentNode);
    for (let child of this.graph[currentNode]) {
      if (!visited.has(child)) {
        if (this._isCyclicUtil(child, visited, currentNode)) {
          return true;
        }
      } else if (parent !== child) {
        return true;
      }
    }
    return false;
  };
}
