import {
  ProjectBasemapSlice,
  ProjectData,
  ProjectStatus,
  ProjectUI,
  Query,
} from '../../../shared-types/portal-types';
import { wrapFetch, wrapFetchWithQuery } from './fetchHelpers';

export const getProjects = async (
  query?: Query,
): Promise<{ projects: ProjectUI[]; total: number }> =>
  wrapFetchWithQuery(`/api/projects`, 'GET', query);

export const getLastProject = async (): Promise<{
  project: ProjectUI | null;
}> => wrapFetchWithQuery(`/api/projects/last`, 'GET');

export const getProject = async (id: number): Promise<{ project: ProjectUI }> =>
  wrapFetch(`/api/projects/${id}`);
export const getProjectDesigns = async (
  id: number,
): Promise<{
  designs: {
    id: number;
    title: string;
    createdAt: string;
    email: string;
  }[];
}> => wrapFetch(`/api/projects/${id}/designs`);

export const getAllProjectsBasemaps = (): Promise<{
  projects: ProjectBasemapSlice[];
}> => wrapFetch(`/api/projects/basemaps`);

export const newProject = (
  project: ProjectData,
): Promise<{ project: ProjectUI }> =>
  wrapFetch(`/api/projects`, 'POST', { project });

export const projectEstimate = (
  type: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  amount: string,
  notes: string,
): Promise<{ project: ProjectUI }> =>
  wrapFetch(`/estimate`, 'POST', {
    type,
    email,
    firstName,
    lastName,
    phone,
    amount,
    notes,
  });

// TODO: move project service calls into portal context (currently imported direct to Project.tsx)
export const updateProject = (
  id: number,
  project: ProjectData,
  code: string,
  updateString?: string,
): Promise<{ message: string }> =>
  wrapFetch(`/api/projects/${id}`, 'PUT', { project, code, updateString });

export const updateProjectDueDate = (
  id: number,
  project: ProjectData,
  dueDate: string,
): Promise<{ project: ProjectUI }> =>
  wrapFetch(`/api/projects/${id}`, 'PUT', { project, dueDate });

export const updateProjectPONumber = (
  id: number,
  project: ProjectData,
  poNumber: string,
): Promise<{ project: ProjectUI }> =>
  wrapFetch(`/api/projects/${id}`, 'PUT', { project, poNumber });

export const updateProjectNumber = (
  id: number,
  project: ProjectData,
  projectNumber: string,
): Promise<{ project: ProjectUI }> =>
  wrapFetch(`/api/projects/${id}`, 'PUT', { project, projectNumber });

export const deleteProject = (projectId: number): Promise<any> =>
  wrapFetch(`/api/projects/${projectId}`, 'DELETE');

export const setProjectStatus = (
  status: ProjectStatus,
  projectId: number,
  dueDate?: string,
): Promise<any> =>
  wrapFetch(`/api/projects/status/${projectId}`, 'PUT', { status, dueDate });

export const setProjectBasemapper = (
  basemapper: number,
  projectId: number,
): Promise<any> =>
  wrapFetch(`/api/projects/assign/${projectId}`, 'PUT', { basemapper });

export const setProjectDesigner = (
  designer: number,
  projectId: number,
): Promise<any> =>
  wrapFetch(`/api/projects/assign/${projectId}`, 'PUT', { designer });

export const setProjectOwner = (
  owner: number,
  projectId: number,
): Promise<any> =>
  wrapFetch(`/api/projects/assign/${projectId}`, 'PUT', { owner });
