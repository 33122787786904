import { ISleeve } from '@shared-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect, useState } from 'react';
import { DropdownProps } from 'semantic-ui-react';

import { sleeveDiameters } from 'src/shared';
import styled from 'styled-components';
import { PanelSelect } from '../../components/PanelButton';
import { getItemByUUID, isSleeve, paperItemStore } from '../../helpers';
import { isPaperSleeve } from './paper-sleeve';
import { getSleeveLines } from './sleeves.paper';
import { changeSleeve } from './state/changeSleeve';

interface Props {
  item: ISleeve;
}
export const SleeveForm = ({ item }: Props) => {
  const [localSleeve, setLocalSleeve] = useState<ISleeve>(item);

  useEffect(() => {
    setLocalSleeve(item);
  }, [item]);

  const doChangeSleeve = (newWidth: number | number[], propagateUp = false) => {
    const w = newWidth as number;
    if (!localSleeve) return;
    const updatedSleeve = {
      ...getItemByUUID(item.uuid, isSleeve),
      width: w,
      lines: getSleeveLines(localSleeve.pipe, w),
    };
    setLocalSleeve(updatedSleeve);
    const paperItem = paperItemStore.get(item.uuid);
    if (paperItem && isPaperSleeve(paperItem)) {
      paperItem.update(updatedSleeve);
    }
    if (propagateUp) {
      changeSleeve(updatedSleeve);
    }
  };
  const doChangeDiameter = (_, data: DropdownProps) => {
    const newDiameter = data.value as number;
    if (!localSleeve) return;
    const updatedSleeve = {
      ...getItemByUUID(item.uuid, isSleeve),
      width: localSleeve.width,
      lines: localSleeve.lines,
      diameter: newDiameter,
    };
    setLocalSleeve(updatedSleeve);
    changeSleeve(updatedSleeve);
  };

  return (
    <Wrap>
      {!!localSleeve && (
        <>
          <div>
            <PanelSelect
              label="Sleeve Diameter"
              compact={true}
              value={localSleeve.diameter}
              onChange={doChangeDiameter}
              options={sleeveDiameters.map((n: number) => ({
                text: `${n}"`,
                value: n,
              }))}
            />
          </div>
          <div>
            Width:
            <Slider
              defaultValue={localSleeve.width}
              value={localSleeve.width}
              onChange={doChangeSleeve}
              step={0.2}
              min={0.2}
              max={10}
              onChangeComplete={(data) => doChangeSleeve(data, true)}
            />
          </div>
        </>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  padding: 8px;
  div:first-child {
    margin-right: 8px;
  }
  div:nth-child(2) {
    width: 200px;
  }
`;
