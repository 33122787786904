import { Basemap, Sheet } from '../../../../../shared-types';

interface ImageCropbox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const effectiveCropbox = (
  basemap: Basemap,
  sheet: Sheet,
): ImageCropbox => {
  // Calculate the effective bounds of the basemap within the sheet
  const effectiveX = Math.max(basemap.position.x, sheet.position.x);
  const effectiveY = Math.max(basemap.position.y, sheet.position.y);
  const effectiveMaxX = Math.min(
    basemap.position.x + basemap.width,
    sheet.position.x + sheet.width,
  );
  const effectiveMaxY = Math.min(
    basemap.position.y + basemap.height,
    sheet.position.y + sheet.height,
  );

  // Calculate the crop box coordinates within the effective bounds
  const cropX = Math.max(basemap.cropBox.topLeft.x, effectiveX);
  const cropY = Math.max(basemap.cropBox.topLeft.y, effectiveY);
  const cropMaxX = Math.min(basemap.cropBox.bottomRight.x, effectiveMaxX);
  const cropMaxY = Math.min(basemap.cropBox.bottomRight.y, effectiveMaxY);

  // Ensure the crop box is within the visible region of the basemap on the sheet
  return {
    x: (cropX - basemap.position.x) * basemap.scale,
    y: (cropY - basemap.position.y) * basemap.scale,
    width: Math.max(0, (cropMaxX - cropX) * basemap.scale),
    height: Math.max(0, (cropMaxY - cropY) * basemap.scale),
  };
};

export const convertImageToBase64 = async (
  imageUrl: string,
  cropBox: ImageCropbox,
  extension: string,
): Promise<string> => {
  const img = await loadImage(imageUrl);
  const canvas = createHTMLCanvas(cropBox.width, cropBox.height);
  drawCroppedImageOnCanvas(img, cropBox, canvas);
  img.remove();
  if (extension !== 'jpg' && extension !== 'png') {
    throw new Error('Unsupported image type');
  }
  const fileType = extension === 'jpg' ? 'image/jpeg' : 'image/png';
  return canvas.toDataURL(fileType);
};
const loadImage = (imageUrl: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error('Image loading failed'));
    img.src = imageUrl;
  });

export const createHTMLCanvas = (width: number, height: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
};

const drawCroppedImageOnCanvas = (
  img: HTMLImageElement,
  cropBox: ImageCropbox,
  canvas: HTMLCanvasElement,
) => {
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(
      img,
      cropBox.x,
      cropBox.y,
      cropBox.width,
      cropBox.height,
      0,
      0,
      cropBox.width,
      cropBox.height,
    );
  }
};
