import { useContext, useEffect } from 'react';
import { Header } from 'semantic-ui-react';
import { base } from 'src/styles/common';
import styled from 'styled-components';
import { PortalContext } from '../../../contexts/portal/portal';

export const BasemapDashboard = () => {
  const { loadCatalog } = useContext(PortalContext);

  useEffect(() => {
    loadCatalog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Wrap>
      <Header as="h1">Basemap Designer Dashboard</Header>
    </Wrap>
  );
};

const Wrap = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${base * 3}px;
`;
