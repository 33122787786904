import { CloudinaryFile } from '@shared-types';
import {
  Button,
  Form,
  Icon,
  InputOnChangeData,
  Modal,
} from 'semantic-ui-react';
import { cloudinaryConfig } from '../cloudinary.config';
import {
  setClientAddress,
  setContractorAddress,
  setContractorLogo,
  setContractorName,
  setNorthRotation,
  setTitle,
  useDesignStore,
} from '../state';
import { modalDefaults } from '../state/modal.service';

export const SettingsModalType = 'settingsModal';
export const SettingsModal = ({ closeModal }) => {
  const contractorLogoURL = useDesignStore((state) => state.contractorLogoURL);
  const contractorAddress = useDesignStore((state) => state.contractorAddress);
  const contractorName = useDesignStore((state) => state.contractorName);
  const clientAddress = useDesignStore((state) => state.clientAddress);
  const title = useDesignStore((state) => state.title);
  const northRotation = useDesignStore((state) => state.northRotation);
  const doNorthRotation = (_, data: InputOnChangeData) => {
    const angle = parseInt(data.value, 10);
    setNorthRotation(angle);
  };

  const doSetLogos = (url: string) => {
    setContractorLogo(url);
  };
  const openLogoWidget = () => {
    let logo = '';
    (window as any).cloudinary.openUploadWidget(
      { ...cloudinaryConfig, multiple: false },
      (error, result) => {
        if (error) {
          console.log(error);
        } else {
          console.log(result);
          if (result.event === 'queues-end') {
            if (result.info.files.length) {
              logo = (result.info.files[0].uploadInfo as CloudinaryFile)
                .secure_url;
            }
          }
          if (result.event === 'close') {
            doSetLogos(logo);
          }
        }
      },
    );
  };

  return (
    <Modal
      {...modalDefaults}
      header="Design Settings"
      actions={[
        {
          key: 'done',
          content: 'Done',
          primary: true,
        },
      ]}
      onActionClick={closeModal}
      content={
        <Modal.Content scrolling={true}>
          <Form>
            <Form.Input
              label="North Direction"
              type="number"
              min="-360"
              max="360"
              value={northRotation}
              onChange={doNorthRotation}
            />
            <Icon
              name="arrow up"
              size="big"
              style={{
                transform: `rotate(${northRotation}deg)`,
                transformOrigin: 'center center',
              }}
            />
            <Form.Input
              label="Design Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Form.TextArea
              label="Site address"
              value={clientAddress}
              onChange={(_, f) => setClientAddress(f.value as string)}
            />
            <Form.Input
              label="Contractor name"
              value={contractorName}
              onChange={(e) => setContractorName(e.target.value)}
            />
            <Form.TextArea
              label="Contractor address"
              value={contractorAddress}
              onChange={(_, f) => setContractorAddress(f.value as string)}
            />
            <>
              {(contractorLogoURL && contractorLogoURL.length > 0 && (
                <>
                  <br />
                  <img
                    src={contractorLogoURL}
                    width="100"
                    alt="contractor-logo"
                  />
                </>
              )) || <div>No logo uploaded yet!</div>}
              <br />
              <Button type="button" onClick={openLogoWidget}>
                Upload Logo
              </Button>
            </>
          </Form>
        </Modal.Content>
      }
    />
  );
};
