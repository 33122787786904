import { Sheet } from '@shared-types';
import React from 'react';
import { isSheet } from '../../helpers';
import { showNamedLayer } from '../../paper-helpers/plot.helpers';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { sheetService } from '../../sheets/sheet.service';
import {
  selectAndCenterSingleID,
  setShowSheetPreviews,
  StoreState,
  useDesignStore,
} from '../../state';
import { deleteItem, updateItem } from '../../state/item-state';
import { ButtonAction } from './ButtonAction';
import { CheckboxAction } from './CheckboxAction';
import { LayerChild } from './LayerChild';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const SheetsLayer = ({ open, onOpen }) => {
  const sheets = useDesignStore((state: StoreState) =>
    state.items.filter(isSheet),
  );
  const showSheetPreviews = useDesignStore((state) => state.showSheetPreviews);
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.SHEETS,
  );
  const toggleChildVisibility = (item: Sheet) => {
    updateItem({ ...item, visible: !item.visible }, true);
  };
  const toggleChildLock = (item: Sheet) => {
    updateItem({ ...item, locked: !item.locked }, true);
  };
  const toggleSheetPreview = () => {
    setShowSheetPreviews(!showSheetPreviews);
    showNamedLayer(LAYER_NAMES.SHEETS);
  };
  const addSheet = () => {
    showNamedLayer(LAYER_NAMES.SHEETS);
    setShowSheetPreviews(true);
    sheetService.addSheet();
  };

  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Sheets (${sheets.length})`}
      open={open}
      onOpen={onOpen}
      actions={
        <>
          <CheckboxAction
            title="Print Preview"
            onClick={toggleSheetPreview}
            checked={showSheetPreviews}
          />
          <ButtonAction onClick={addSheet} title="Add Sheet"></ButtonAction>
        </>
      }
      childLayer={sheets.map((sheet, i) => (
        <LayerChild
          key={sheet.uuid}
          visible={sheet.visible}
          locked={sheet.locked}
          title={`Sheet ${i + 1}`}
          clickToggle={() => toggleChildVisibility(sheet)}
          clickLock={() => toggleChildLock(sheet)}
          clickDelete={() => deleteItem(sheet.uuid)}
          clickItem={() => selectAndCenterSingleID(sheet.uuid)}
          layerLocked={layerLocked}
          layerVisible={layerVisible}
        />
      ))}
    />
  );
};

export default React.memo(SheetsLayer);
