import { DefaultStrokeWidth } from './DefaultStrokeWidth';
import { PanelLabel, PanelWrap } from './PanelButton';

export const DrawCircleProperties = () => {
  return (
    <PanelWrap>
      <div>
        <PanelLabel>Drawing Circle</PanelLabel>
        <DefaultStrokeWidth />
      </div>
    </PanelWrap>
  );
};
