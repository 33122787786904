import { Checkbox } from 'semantic-ui-react';
import { styled } from 'styled-components';
import { hideNamedLayer, showNamedLayer } from '../paper-helpers/plot.helpers';
import { LAYER_NAMES } from '../shared/workbench-enums';
import {
  actionList,
  actionsHumanReadable,
  LayerList,
  PRESET_ACTIONS,
  toggleActions,
} from './presets';

export const PresetCustomizer = ({ preset, onSavePreset }): JSX.Element => {
  const toggleLayer = (layer: LAYER_NAMES) => {
    const hasLayer = preset.config.layersToShow.includes(layer);
    if (hasLayer) {
      hideNamedLayer(layer);
    } else {
      showNamedLayer(layer);
    }
    onSavePreset({
      ...preset,
      config: {
        ...preset.config,
        layersToShow: hasLayer
          ? preset.config.layersToShow.filter((l) => l !== layer)
          : [...preset.config.layersToShow, layer],
      },
    });
  };
  const toggleAction = (action: PRESET_ACTIONS, checked = false) => {
    toggleActions[action](checked);
    onSavePreset({
      ...preset,
      config: {
        ...preset.config,
        actions: checked
          ? [...preset.config.actions, action]
          : preset.config.actions.filter((a) => a !== action),
      },
    });
  };

  return (
    <Wrap>
      <div className="preset-name">Name: {preset.name}</div>
      <div className="layers">
        <strong>Layers To Show</strong>
        {LayerList.map((layer) => (
          <div key={layer.layerName}>
            <Checkbox
              key={layer.layerName}
              checked={preset.config.layersToShow.includes(layer.layerName)}
              onChange={() => toggleLayer(layer.layerName)}
              label={layer.readable}
            />
          </div>
        ))}
      </div>
      <div className="actions">
        <strong>Actions To Run</strong>
        {actionList.map((action) => (
          <div key={action}>
            <Checkbox
              checked={preset.config.actions.includes(action)}
              onChange={(e, data) => toggleAction(action, data.checked)}
              label={actionsHumanReadable[action]}
            />
          </div>
        ))}
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  border: 1px solid #aaa;
  .ui.checkbox {
    margin: 4px 0;
  }
  .preset-name {
    padding: 8px;
    background: #ccc;
    font-weight: bold;
  }
  .layers {
    padding: 8px;
    background: #eee;
  }
  .actions {
    padding: 8px;
    background: #ddd;
  }
`;
