import { List } from 'semantic-ui-react';
import { createMiniMapPagePDF } from '../helpers/pdf/coveragePages';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { createPacketPDF } from './createPacket';
import { applyPreset, Preset, PRESET_ACTIONS } from './presets';

const arcSheetPreset: Preset = {
  name: 'Arc Sheet',
  config: {
    layersToShow: [LAYER_NAMES.TRACING, LAYER_NAMES.HEADS, LAYER_NAMES.SHEETS],
    actions: [
      PRESET_ACTIONS.headArcs,
      PRESET_ACTIONS.headPreview,
      PRESET_ACTIONS.sheetPreview,
    ],
  },
};

const coveragePreset = {
  name: 'Coverage',
  config: {
    layersToShow: [
      LAYER_NAMES.TRACING,
      LAYER_NAMES.SHEETS,
      LAYER_NAMES.COVERAGE,
    ],
    actions: [PRESET_ACTIONS.sheetPreview],
  },
};

export const presetWorkflowPacket = async () => {
  applyPreset(coveragePreset);
  const coveragePdfPage = await createMiniMapPagePDF('Coverage Charts');
  applyPreset(arcSheetPreset);
  const arcPdfPage = await createMiniMapPagePDF('Arc Charts');
  await createPacketPDF([coveragePdfPage, arcPdfPage]);
};

export const PacketExport = (): JSX.Element => {
  return (
    <List.Item>
      <List.Icon name="file pdf" />
      <List.Content as="a" onClick={presetWorkflowPacket}>
        Export Packet PDF
      </List.Content>
    </List.Item>
  );
};
