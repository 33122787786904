import { NumericFormat } from 'react-number-format';
import { Select, SelectProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { ColorInput } from './ColorInput';
import { NumberSlider } from './NumberSlider';
export const PanelButton = styled.button<{ $active?: boolean }>`
  cursor: pointer;
  min-height: 1em;
  outline: 0;
  border: none;
  padding: 0.75em 1em;
  font-weight: 700;
  line-height: 1em;
  border-radius: 0.28571429rem;
  user-select: none;
  background: ${(p) => (p.$active ? '#2185d0' : '#4e4e4e')};
  color: rgba(255, 255, 255, 0.7);
  display: block;
  width: 100%;
  text-align: left;

  &:hover {
    background: ${(p) => (p.$active ? '#2185d0' : '#3e3e3e')};
  }
  &:disabled {
    background: #555;
    color: rgba(255, 255, 255, 0.3);
    cursor: default;
  }
`;
export const PanelWrap = styled.div`
  padding: 8px;
  .subpanel {
    border-bottom: 1px solid #333;
  }
  h5 {
    padding: 0 0 4px 0;
    margin: 0 0 8px 0;
    font-size: 1em;
    user-select: none;
    display: block;
    font-weight: bold;
    border-bottom: 1px solid #4e4e4e;
  }
  ${PanelButton} {
    margin-bottom: 8px;
  }
  .ui.checkbox {
    display: block;
    padding: 8px 4px;
  }
  .ui.checkbox,
  .ui.toggle.checkbox {
    label,
    input:focus ~ label,
    input:checked ~ .box,
    input:checked ~ label {
      color: inherit !important;
    }
  }
`;

export const PanelRangeInputWrap = ({
  value,
  suffix = '',
  label,
  onChange,
  ...props
}: any) => {
  return (
    <PanelInput>
      <label>{label}</label>
      <NumberSlider
        value={value}
        suffix={suffix}
        onChange={onChange}
        {...props}
      />
    </PanelInput>
  );
};

export const PanelDivider = styled.div`
  margin: 8px 0;
  border-bottom: 1px solid #4e4e4e;
`;
const PanelInput = styled.div`
  label {
    display: block;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 4px;
  }
  input {
    width: 100%;
    padding: 10px;
    border: 0;
    margin: 0;
    outline: none;
    border: 0;
    border-radius: 2px;
    &:disabled {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  .ui.selection {
    width: 100%;
    min-width: 80px;
  }
  & + & {
    margin-top: 16px;
  }
`;
export const PanelInputWrap = ({ onValueChange, label, ...props }: any) => {
  return (
    <PanelInput>
      <label>{label}</label>
      {props.type === 'color' && <ColorInput {...props} />}
      {props.type && props.type === 'number' && <NumericFormat {...props} />}
      {(!props.type ||
        (props.type && props.type !== 'color' && props.type !== 'number')) && (
        <input {...props} />
      )}
      {props.hint && <small className="hint">{props.hint}</small>}
    </PanelInput>
  );
};

export const PanelSelect = ({ label, ...props }: SelectProps) => {
  return (
    <PanelInput>
      <label>{label}</label>
      <Select {...props} />
    </PanelInput>
  );
};
export const PanelLabel = styled.div`
  padding: 0 0 4px 0;
  margin: 8px 0 8px 0;
  font-size: 1em;
  user-select: none;
  display: block;
`;
