import EventEmitter from 'events';
import {
  BaseItem,
  BaseItemType,
  IPoint,
  SingleItemHookType,
} from '../../../../../shared-types';
import { moveSleeve } from '../features/sleeves/sleeves.paper';
import { isBasemap, isPolyline, isSleeve } from '../helpers';
import { moveBasemap } from '../upload/basemap.service';
import { movePolylineVertices } from './polyline.service';

const itemEventEmitter = new EventEmitter();

export const changeItemPosition = (item: BaseItem, diff: IPoint): BaseItem => {
  if (item.locked) {
    return item;
  }
  let newItem: BaseItem = {
    ...item,
    position: {
      x: item.position.x + diff.x,
      y: item.position.y + diff.y,
    },
  };
  if (isPolyline(newItem)) {
    return movePolylineVertices(newItem, diff);
  }
  if (isBasemap(newItem)) {
    return moveBasemap(newItem, diff);
  }
  if (isSleeve(newItem)) {
    return moveSleeve(newItem, diff);
  }
  return newItem;
};

export const postAddItems = (items: BaseItem[]) => {
  items.forEach((item) => {
    emitSingleHook('postAddItem', item);
  });
};

export const postBulkUpdateItems = (items: BaseItem[]) => {
  items.forEach((item) => {
    emitSingleHook('postUpdateItem', item);
  });
};

export const registerStateHooks = <T extends BaseItem>(
  itemType: BaseItemType,
  handlers: Record<SingleItemHookType, (item: T) => void>,
) => {
  Object.entries(handlers).forEach(([hookType, handler]) => {
    itemEventEmitter.on(`${hookType}:${itemType}`, handler);
  });
};

export const emitSingleHook = (
  hookType: SingleItemHookType,
  item: BaseItem,
) => {
  itemEventEmitter.emit(`${hookType}:${item.itemType}`, item);
};
