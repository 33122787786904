import { Zone } from '@shared-types';
import { zoneColors } from 'src/vars';
import { compact, contains, uniq } from 'underscore';
import { mapEls } from '../caches/element.cache';
import { addUndo } from '../contexts/design/undo';
import { newUUID } from '../crypto-uuid';
import { generateGraphs } from '../helpers/directedGraph';
import { renderEdges } from '../paper-helpers/edges';
import {
  renderElements,
  updateAllHeadColors,
} from '../paper-helpers/plot.helpers';
import { generateValveAssembly, reorderZones } from '../paper-helpers/valves';
import { deleteZone } from './deleteZone';
import { getState, setState } from './state-basics';

export const addZone = (
  headIds: string[],
  isPlants: boolean,
  undoable = true,
) => {
  if (undoable) {
    addUndo(() => {
      const zone = getState().zones[getState().zones.length - 1];
      deleteZone(zone.uuid, false);
    }, 'Add Zone');
  }
  const { groups, dripValve, lateralValve, valveProducts } = getState();
  const defaultValve =
    (isPlants ? dripValve : lateralValve) || valveProducts[0];
  const { edge1, edge2, valveElement, inputFitting, outputFitting } =
    generateValveAssembly(defaultValve);
  const zColors = getState().zones.map((z) => z.color);
  const groupColors = groups.map((g) => g.color);
  const totalColors = [...zColors, ...groupColors];
  const usedColors = compact(uniq(totalColors));
  const unusedColors = zoneColors.filter(
    (color) => !usedColors.includes(color),
  );
  let zones = getState().zones.map((z) => ({
    ...z,
    headIds: isPlants
      ? z.headIds
      : z.headIds.filter((h) => !contains(headIds, h)),
  }));
  const newZone: Zone = {
    headIds: isPlants ? [] : headIds,
    plantIds: isPlants ? headIds : [],
    pipes: [],
    hoppedPipes: [],
    uuid: newUUID(),
    valveInputFitting: inputFitting.uuid,
    valveOutputFitting: outputFitting.uuid,
    valve: valveElement.uuid,
    orderNumber: -1,
    color: unusedColors[0] || 'black',
  };
  zones.push(newZone);
  zones = reorderZones(
    zones,
    getState().valveBoxes,
    getState().zoneNumberOffset,
  );
  updateAllHeadColors(zones, groups);
  const elements = [
    ...getState().elements,
    valveElement,
    inputFitting,
    outputFitting,
  ];
  const elementCache = mapEls(elements);
  const newEdges = [...getState().edges, edge1, edge2];
  const { masterGraph, pocGraphs } = generateGraphs(newEdges, elements, zones);
  setState({
    elements,
    zones,
    currentZone: newZone.uuid,
    edges: newEdges,
    elementCache,
    masterGraph,
    pocGraphs,
  });
  renderEdges([edge1, edge2]);
  renderElements([valveElement, inputFitting, outputFitting]);
};
