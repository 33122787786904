import { BaseItem } from '../../../../../../../shared-types';
import { isSleeve } from '../../../helpers';
import { getState, setState } from '../../../state';
import { deletePaperItems } from '../../../state/renderers';

export const deleteGeneratedSleeves = () => {
  const { items } = getState();
  const updatedItems: BaseItem[] = [];
  const toDelete: string[] = [];

  items.forEach((item) => {
    if (isSleeve(item) && !item.user) {
      toDelete.push(item.uuid);
    } else {
      updatedItems.push(item);
    }
  });
  setState({ items: updatedItems, selectedIDs: [] });
  deletePaperItems(toDelete);
};
