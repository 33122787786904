import {
  BaseItem,
  BaseStyledItem,
  DesignElement,
  IEdgeHopData,
} from '@shared-types';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { MultiHeadTooltip } from '../features/heads/MultiHeadTooltip';
import { SleeveForm } from '../features/sleeves/SleeveTooltip';
import {
  isBasemap,
  isCircle,
  isPolyline,
  isRectangle,
  isSleeve,
  isText,
} from '../helpers';
import { MultiPlantTooltip } from '../plants/MultiPlantTooltip';
import { isPlant } from '../plants/plants';
import { ITEMNAMES } from '../shared/workbench-enums';
import { getState, useDesignStore } from '../state';
import { TextTooltip } from '../texts/TextTooltip';
import { isSprinkler } from '../tools/paper-items/paper-sprinkler';
import { AlignTools } from './AlignTools';
import { BaseItemActions } from './BaseItemActions';
import { BasemapActions } from './BasemapActions';
import { CircleActions } from './CircleActions';
import { EdgeCloneTooltip } from './EdgeCloneTooltip';
import { ElementTooltip } from './ElementTooltip';
import { PipeProperties } from './PipeProperties';
import { PolylineActions } from './PolylineActions';
import { RectangleActions } from './RectangleActions';
import { StyledItemActions } from './StyledItemActions';
import { ValveBoxMenu } from './ValveBoxMenu';

export const TooltipMenu = () => {
  const selectedItems: paper.Item[] = useDesignStore(
    (state) => state.selectedItems,
  );
  const selectedIDs: string[] = useDesignStore((state) => state.selectedIDs);
  const [selectedBaseItems, setSelectedBaseItems] = useState<BaseItem[]>([]);

  useEffect(() => {
    const items = selectedIDs
      .map((id) => getState().items.find((i) => i.uuid === id))
      .filter((i): i is BaseItem => !!i);
    setSelectedBaseItems(items);
  }, [selectedIDs]);

  const isElement = (): boolean =>
    selectedItems.length === 1 && selectedItems[0].name === ITEMNAMES.ELEMENT;
  const isMultipleHeads = () =>
    selectedItems.length > 1 &&
    selectedItems.every(
      (h) => h.name === ITEMNAMES.ELEMENT && isSprinkler(h.data),
    );
  // const isMultiplePlants = () =>
  //   selectedItems.length > 1 &&
  //   selectedItems.every((item) => item.name === ITEMNAMES.PLANT_GROUP);
  // const isPlant = () =>
  //   selectedItems.length === 1 &&
  //   selectedItems[0].name === ITEMNAMES.PLANT_GROUP;
  const isEdge = () =>
    selectedItems.length === 1 && selectedItems[0].name === ITEMNAMES.PIPE;

  return (
    <Wrap>
      {!!selectedBaseItems.length && (
        <div>
          <BaseItemActions items={selectedBaseItems} />
          {selectedBaseItems.every((i) => i.hasOwnProperty('style')) && (
            <StyledItemActions items={selectedBaseItems as BaseStyledItem[]} />
          )}
          {selectedBaseItems.length === 1 && (
            <>
              {isPolyline(selectedBaseItems[0]) && (
                <PolylineActions item={selectedBaseItems[0]} />
              )}
              {isBasemap(selectedBaseItems[0]) && (
                <BasemapActions item={selectedBaseItems[0]} />
              )}
              {isCircle(selectedBaseItems[0]) && (
                <CircleActions item={selectedBaseItems[0]} />
              )}
              {isRectangle(selectedBaseItems[0]) && (
                <RectangleActions item={selectedBaseItems[0]} />
              )}
              {isText(selectedBaseItems[0]) && (
                <TextTooltip item={selectedBaseItems[0]} />
              )}
              {isSleeve(selectedBaseItems[0]) && (
                <SleeveForm item={selectedBaseItems[0]} />
              )}
            </>
          )}
          <>
            {selectedBaseItems.every(isPlant) && (
              <MultiPlantTooltip items={selectedBaseItems} />
            )}
          </>
        </div>
      )}
      <ValveBoxMenu />
      {selectedItems.length > 0 && (
        <div>
          {isMultipleHeads() && (
            <MultiHeadTooltip
              heads={(selectedItems as paper.Group[]).filter((item) =>
                isSprinkler(item.data),
              )}
            />
          )}
          {/* {isMultiplePlants() && (
            <MultiPlantTooltip plants={selectedItems as paper.Group[]} />
          )}
          {isPlant() && (
            <PlantTooltip uuid={(selectedItems[0].data as IPlant).uuid} />
          )} */}
          {/* {isSleeve() && <SleeveForm uuid={selectedItems[0].data.sleeveUUID} />} */}

          {isElement() && (
            <ElementTooltip item={selectedItems[0].data as DesignElement} />
          )}

          {/* {isText() && (
            <TextTooltip item={selectedItems[0] as paper.PointText} />
          )} */}
          {isEdge() && (
            <PipeProperties
              uuid={(selectedItems[0].data as IEdgeHopData).edgeUUID}
            />
          )}
          {selectedItems.length === 2 &&
            !selectedItems.every(
              (item) =>
                item.name === ITEMNAMES.ELEMENT &&
                (item.data as DesignElement).type === 'sprinkler',
            ) && <EdgeCloneTooltip items={selectedItems} />}
        </div>
      )}

      {selectedItems.length > 2 && <AlignTools />}
    </Wrap>
  );
};

const Wrap = styled.div`
  .ui .item {
    cursor: pointer;
  }
  .active-plant,
  .active-misc-item {
    color: #21ba45;
  }
  .ui.modal {
    right: 200px;
  }
  .tool-tip {
    padding: 8px;
  }
  .tool-tip.misc {
    max-height: 250px;
    overflow: auto;
  }
  .grid-tooltip {
    padding: 16px;
  }
`;
