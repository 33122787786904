import { IEdge } from '@shared-types';
import { addUndo } from '../contexts/design/undo';
import { generateGraphs } from '../helpers/directedGraph';
import { hopPipes } from '../helpers/hopping';
import { addElementsEdges } from './addElementsEdges';
import { paperState } from './paper.state';
import { getState, setState } from './state-basics';

export const deleteEdges = (payload: IEdge[], undoable = true) => {
  if (undoable) {
    addUndo(() => {
      addElementsEdges([], payload, false);
    }, 'set edges');
  }
  paperState.deleteItems(payload.map((e) => e.uuid));
  const { elements, zones, elementCache, scale, edges } = getState();
  let newEdges = edges.filter(
    (edge: IEdge) => !payload.some((e) => e.uuid === edge.uuid),
  );
  const { pocGraphs } = generateGraphs(newEdges, elements, zones);
  newEdges = hopPipes(
    zones,
    newEdges,
    elements,
    elementCache,
    scale,
    pocGraphs,
  );
  setState({ edges: newEdges });
};
