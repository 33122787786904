import { IPoint } from '@shared-types';
import paper from 'src/paper';
import { getColor } from './plot.helpers';

export const createGateValve = (point: IPoint, scale: number): paper.Item => {
  const g = new paper.Group();
  const leftPointingTriangle = new paper.Path.RegularPolygon(
    new paper.Point(0, 0),
    3,
    3 / scale,
  );
  leftPointingTriangle.rotate(-30);
  leftPointingTriangle.position = new paper.Point(-2 / scale, 0);
  const rightPointingTriangle = new paper.Path.RegularPolygon(
    new paper.Point(0, 0),
    3,
    3 / scale,
  );
  rightPointingTriangle.rotate(30);
  rightPointingTriangle.position = new paper.Point(2 / scale, 0);
  g.addChildren([leftPointingTriangle, rightPointingTriangle]);
  g.fillColor = getColor('black');
  g.position = new paper.Point(point.x, point.y);
  return g;
};
