import { Link, Outlet, createBrowserRouter } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import { RouteWithAuth } from './RouteWithAuth';
import { DrawerMenuComponent } from './components/DrawerMenu';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Home } from './pages/Home/Home';
import { ProjectRequestForm } from './pages/ProjectRequestForm/ProjectRequestForm';
import { Project } from './pages/Projects/Project';
import { Projects } from './pages/Projects/Projects';
import { Signup } from './pages/Signup/Signup';
import { Terms } from './pages/Terms/Terms';
import Workbench from './pages/Workbench/components/Workbench';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <DrawerMenuComponent />,
    errorElement: (
      <div style={{ flex: 1, padding: 24, textAlign: 'center' }}>
        <Message compact>
          <Message.Header>Page not found</Message.Header>
          Try going somewhere else?
        </Message>
        <Link to="/">Home Page</Link>
      </div>
    ),
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/signup',
        element: <Signup />,
      },
      {
        path: '/terms',
        element: <Terms />,
      },
      {
        path: '/app',
        element: <RouteWithAuth />,
        children: [
          {
            path: '',
            element: <Dashboard />,
          },
          {
            path: 'dashboard',
            element: <Dashboard />,
          },

          {
            path: 'projects',
            element: <Outlet />,
            children: [
              {
                path: '',
                element: <Projects />,
              },
              {
                path: ':id',
                element: <Project />,
              },
            ],
          },
          {
            path: 'project-request/:id?',
            element: <ProjectRequestForm />,
          },
          // {
          //   path: 'contracts',
          //   element: <BulkContracts />,
          //   // showRoute: (role) => contains(['admin'], role),
          // },
          // {
          //   path: 'users',
          //   element: <RoleBasedPrivateRoute requiredRole="admin" />,
          //   children: [
          //     {
          //       path: '',
          //       element: <Users />,
          //     },
          //   ],
          // },
          // {
          //   path: 'contract/:id',
          //   element: <BulkContract />,
          //   // showRoute: (role) => contains(['admin'], role),
          // },
          {
            path: 'workbench/:id',
            element: <Workbench />,
          },
        ],
      },
    ],
  },
]);
