import {
  DesignElement,
  DesignSprinklerElement,
  IBackflowProduct,
  IEdge,
  IMiscItem,
  IPRV,
  IPipeProduct,
  IPlant,
  IPump,
  ISleeve,
  IValveProduct,
  ItemCount,
  Sprinkler,
  ValveBox,
  WaterGroup,
  Zone,
} from '@shared-types';
import { mapKeys } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { Content, TableCell } from 'pdfmake/interfaces';
import { chain, contains, countBy, uniq } from 'underscore';
import { isSleeve } from '../../helpers';
import { totalPipeLength } from '../../paper-helpers/edges';
import { isPlant, mapPlantIdsToPlants } from '../../plants/plants';
import { getEuclideanDistance } from '../../shared/geometry';
import { getState } from '../../state';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';
import { findEdge } from '../directedGraph';
import { createPDFMakePage } from './createPDFMakePage';
import { mapHeadIdsToHeads } from './mapHeadIdsToHeads';
import { minimalTable, standardDefinition } from './pdf.helpers';

export async function createTakeoffPagePDF(): Promise<PDFDocument | void> {
  const definitionFunc = async () => standardDefinition();
  const getContent = async () => takeoffPage();
  return createPDFMakePage(definitionFunc, getContent);
}

const takeoffPage = (): Content[] => {
  const {
    title,
    contractorName,
    zones,
    elements,
    edges,
    valveBoxes,
    pipeProducts,
  } = getState();
  const plants = getState().items.filter(isPlant);
  const sleeves = getState().items.filter(isSleeve);

  const lateralRows = getLateralRows(
    edges,
    zones.filter((z) => z.headIds.length > 0),
    elements,
    pipeProducts,
  );
  const dripRows = getDripRows(
    edges,
    zones.filter((z) => z.isDrip || z.plantIds.length > 0),
    elements,
    pipeProducts,
  );
  const mainlineRows = getMainlineRows(edges, elements, pipeProducts);
  const valveRows = getValveRows(
    zones.filter((z) => !z.plantIds.length),
    elements,
  );
  const dripvalveRows = getDripValveRows(
    zones.filter((z) => z.isDrip || z.plantIds.length > 0),
    elements,
  );
  const valveBoxRows = getValveBoxRows(valveBoxes);
  const headRows = getHeadRows(elements);
  const plantRows = getPlantRows(
    plants.filter((plant) =>
      zones.some((z) => contains(z.plantIds, plant.uuid)),
    ),
  );
  const sleeveRows = getSleeveRows(sleeves);
  const backflowRows = getBackflowRows(
    elements
      .filter((el) => el.type === 'backflow' && !el.preExisting)
      .map((el) => el.props as IBackflowProduct),
  );
  const prvRows = getPRVRows(
    elements
      .filter((el) => el.type === 'prv' && !el.preExisting)
      .map((el) => el.props as IPRV),
  );
  const pumpRows = getPumpRows(
    elements
      .filter((el) => el.type === 'pump' && !el.preExisting)
      .map((el) => el.props as IPump),
  );
  const boosterPumpRows = getBoosterPumpRows(
    elements
      .filter((el) => el.type === 'booster pump' && !el.preExisting)
      .map((el) => el.props as IPump),
  );
  const miscItemRows = getMiscItemRows(
    elements.filter(
      (el) =>
        (el.type === 'miscItem' || el.type === 'quickCoupler') &&
        !el.preExisting,
    ),
  );
  const takeoffFooterText =
    'Note: All pipe lengths are approximate and should be validated and cut on-site. Lateral drip pipe and emitters for drip zones should be validated and cut on-site based on plant material. Connections and risers should be determined on-site.';
  const extras = [
    ...valveBoxRows,
    ...valveRows,
    ...dripvalveRows,
    ...miscItemRows,
    ...backflowRows,
    ...prvRows,
    ...pumpRows,
    ...boosterPumpRows,
  ];
  return [
    { text: 'Takeoff', style: 'header', margin: [0, 0, 0, 15] },
    { text: `Project: ${title}`, margin: [0, 0, 0, 0] },
    { text: `Contractor: ${contractorName}`, margin: [0, 0, 0, 15] },
    { text: takeoffFooterText, fontSize: 8, margin: [0, 0, 0, 15] },
    minimalTable('Pipes', [...lateralRows, ...mainlineRows, ...dripRows]),
    minimalTable('Heads & Nozzles', headRows),
    minimalTable(
      'Valves & Extras',
      extras,
      "* Drip valves: be sure to include the required flow control and filter kit depending on manufacturer's recommendations",
    ),
    zones.some((z) => z.isDrip || z.plantIds.length > 0)
      ? // only render the plant table if we have any drip zones
        minimalTable('Plants (for estimating emitters)', plantRows)
      : [],
    sleeves.length ? minimalTable('Sleeves', sleeveRows) : '',
  ];
};
export const getMiscItemRows = (miscItems: DesignElement[]): Content[] => {
  const props = miscItems.map((el) => el.props as IMiscItem);
  const countedItems = countBy(props, (item) => item.displayName || item.name);
  const content: Content[] = [];
  mapKeys(countedItems, (value, key) => {
    content.push([{ text: `x ${value.toLocaleString()}` }, { text: key }]);
  });
  return content;
};
export const getBackflowRows = (backflows: IBackflowProduct[]): Content[] => {
  const countedItems = countBy(
    backflows,
    (item) => `${item.brand} ${item.name} ${item.size}"`,
  );
  const content: Content[] = [];
  mapKeys(countedItems, (value, key) => {
    content.push([
      { text: `x ${value.toLocaleString()}` },
      { text: `${key} Backflow` },
    ]);
  });
  return content;
};
export const getPRVRows = (prvs: IPRV[]): Content[] => {
  const countedItems = countBy(
    prvs,
    (item) => `${item.name} (${item.outputPSI} psi)`,
  );
  const content: Content[] = [];
  mapKeys(countedItems, (value, key) => {
    content.push([
      { text: `x ${value.toLocaleString()}` },
      { text: `${key} PRV` },
    ]);
  });
  return content;
};
export const getPumpRows = (pumps: IPump[]): Content[] => {
  const countedItems = countBy(pumps, (item) => `Pump: ${item.name}`);
  const content: Content[] = [];
  mapKeys(countedItems, (value, key) => {
    content.push([{ text: `x ${value.toLocaleString()}` }, { text: key }]);
  });
  return content;
};
export const getBoosterPumpRows = (pumps: IPump[]): Content[] => {
  const countedItems = countBy(pumps, (item) => `Booster Pump: ${item.name}`);
  const content: Content[] = [];
  mapKeys(countedItems, (value, key) => {
    content.push([{ text: `x ${value.toLocaleString()}` }, { text: key }]);
  });
  return content;
};
export const getValveBoxRows = (valveBoxes: ValveBox[]): Content[] =>
  valveBoxCounts(valveBoxes).map((p) => [
    { text: `${p.count}` },
    { text: `${p.name}` },
  ]);
export const getValveRows = (
  zones: Zone[],
  elements: DesignElement[],
): Content[] =>
  valveCounts(zones, elements).map((p) => [
    { text: `${p.count}` },
    { text: `${p.name}` },
  ]);
export const getPlantRows = (allPlants: IPlant[], zone?: Zone): Content[] => {
  const zonePlants: IPlant[] = zone
    ? mapPlantIdsToPlants(allPlants, zone.plantIds)
    : allPlants;
  return plantCounts(zonePlants).map((p) => [
    { text: `${p.count}` },
    { text: `${p.name}` },
  ]);
};
export const getDripValveRows = (
  zones: Zone[],
  elements: DesignElement[],
): Content[] =>
  valveCounts(zones, elements).map((p) => [
    { text: `${p.count}` },
    { text: `${p.name} (Drip)` },
  ]);
export const getSleeveRows = (sleeves: ISleeve[]): Content[] =>
  sleeveCounts(sleeves).map((s) => [{ text: s.count }, { text: s.name }]);
export const getHeadRows = (
  elements: DesignElement[],
  zone?: Zone | WaterGroup,
): TableCell[] => {
  const allHeads = elements.filter(
    (el): el is DesignSprinklerElement => !el.preExisting && isSprinkler(el),
  );
  const zoneHeads: Sprinkler[] = zone
    ? mapHeadIdsToHeads(allHeads, zone.headIds)
    : allHeads.map((el) => el.props);
  return headCounts(zoneHeads)
    .map((h: ItemCount) => ({
      head: h,
      nozzles: nozzleCounts(
        zoneHeads.filter(
          (h1) => `${h1.base.headSeries}: ${h1.base.headModel}` === h.name,
        ),
      ),
    }))
    .reduce(
      (
        acc: TableCell[][],
        head: { head: ItemCount; nozzles: ItemCount[] },
      ): TableCell[][] => [
        ...acc,
        [
          [
            { text: head.head.count, bold: true, margin: [0, 8, 0, 0] },
            { text: head.head.name, bold: true, margin: [0, 8, 0, 0] },
          ],
          ...head.nozzles.map((n: ItemCount) => [
            { text: n.count },
            { text: n.name, margin: [8, 0, 0, 0] },
          ]),
        ],
      ],
      [],
    )
    .reduce((acc, n) => [...acc, ...n], []);
};
export const getMainlineRows = (
  edges: IEdge[],
  elements: DesignElement[],
  pipeProducts: IPipeProduct[],
): Content[] =>
  totalPipeCounts(edgesForMainline(edges), elements, pipeProducts).map((s) => [
    { text: `${s.count}` },
    { text: `${s.name} (Main)` },
  ]);
export const getLateralRows = (
  edges: IEdge[],
  zones: Zone[],
  elements: DesignElement[],
  pipeProducts: IPipeProduct[],
): Content[] =>
  totalPipeCounts(edgesForSprayZones(edges, zones), elements, pipeProducts).map(
    (s) => [{ text: `${s.count}` }, { text: `${s.name} (Lateral)` }],
  );
export const getDripRows = (
  edges: IEdge[],
  zones: Zone[],
  elements: DesignElement[],
  pipeProducts: IPipeProduct[],
): Content[] =>
  totalPipeCounts(edgesForDripZones(edges, zones), elements, pipeProducts).map(
    (s) => [{ text: `${s.count}` }, { text: `${s.name} (Drip)` }],
  );
export const nozzleCounts = (sprinklers: Sprinkler[]): ItemCount[] =>
  uniq(sprinklers.map((s) => `${s.base.nozzleSeries} - ${s.base.nozzleModel}`))
    .sort()
    .map((name: string) => ({
      name,
      count: `x ${sprinklers
        .filter(
          (s) => `${s.base.nozzleSeries} - ${s.base.nozzleModel}` === name,
        )
        .length.toLocaleString()}`,
    }));
export const headCounts = (sprinklers: Sprinkler[]): ItemCount[] =>
  uniq(
    sprinklers.map(
      (h: Sprinkler) => `${h.base.headSeries}: ${h.base.headModel}`,
    ),
  )
    .sort()
    .map((name: string) => ({
      name,
      count: `x ${sprinklers
        .filter((h) => `${h.base.headSeries}: ${h.base.headModel}` === name)
        .length.toLocaleString()}`,
    }));
export const valveBoxCounts = (valveBoxes: ValveBox[]): ItemCount[] =>
  uniq(valveBoxes.map((h: ValveBox) => h.maxSlots))
    .sort((a, b) => a - b)
    .map((slots: number) => ({
      name: `Valve Box (${slots} valves)`,
      count: `x ${valveBoxes
        .filter((s) => s.maxSlots === slots)
        .length.toLocaleString()}`,
    }));
export const plantCounts = (plants: IPlant[]): ItemCount[] =>
  uniq(plants.filter((p) => !p.preExisting).map((h: IPlant) => h.name))
    .sort()
    .map((name: string) => ({
      name,
      count: `x ${plants
        .filter((s) => s.name === name)
        .length.toLocaleString()}`,
    }));
export const valveCounts = (zones: Zone[], els: DesignElement[]): ItemCount[] =>
  uniq(
    els
      .filter(
        (el) =>
          !el.preExisting &&
          contains(
            zones.map((z) => z.valve),
            el.uuid,
          ),
      )
      .filter(
        (el) =>
          el.type === 'valve' && el.props && el.props.hasOwnProperty('brand'),
      )
      .map((el) => el.props as IValveProduct)

      .map((h: IValveProduct) => `${h.brand} ${h.name}`),
  )
    .sort()
    .map((name: string) => ({
      name,
      count: `x ${els
        .filter(
          (el) =>
            contains(
              zones.map((z) => z.valve),
              el.uuid,
            ) &&
            el.type === 'valve' &&
            el.props &&
            el.props.hasOwnProperty('brand'),
        )
        .map((el) => el.props as IValveProduct)
        .filter((h) => `${h.brand} ${h.name}` === name)
        .length.toLocaleString()}`,
    }));
export const sleeveCounts = (sleeves: ISleeve[]): ItemCount[] =>
  uniq(sleeves.map((p: ISleeve) => p.diameter))
    .sort((a, b) => a - b)
    .map(
      (diameter: number): ItemCount => ({
        name: `${diameter}" Pipe`,
        count: `${Math.ceil(
          totalSleeveLength(sleeves, diameter),
        ).toLocaleString()} ft`,
      }),
    )
    .reduce((acc: ItemCount[], curr) => [...acc, curr], []);
export const totalPipeCounts = (
  edges: IEdge[],
  elements: DesignElement[],
  pipeProducts: IPipeProduct[],
): ItemCount[] => {
  const sortedEdges = chain(edges)
    .sortBy((edge) => {
      const pipe = pipeProducts.find((p) => p.uuid === edge.pipe);
      return pipe && pipe.size;
    })
    .sortBy((edge) => {
      const pipe = pipeProducts.find((p) => p.uuid === edge.pipe);
      return pipe && pipe.series;
    })
    .value() as unknown as IEdge[];
  return uniq(
    sortedEdges
      .filter((edge) => !edge.preExisting && edge.pipe)
      .map((edge) => {
        const pipe = pipeProducts.find((p) => p.uuid === edge.pipe);
        return (pipe && pipe.name) || '';
      }),
  )
    .map((name: string) => ({
      name,
      count: `${Math.ceil(
        totalPipeLength(edges, name, elements, pipeProducts),
      ).toLocaleString()} ft`,
    }))
    .reduce((acc: ItemCount[], curr) => [...acc, curr], []);
};
export const totalSleeveLength = (
  sleeves: ISleeve[],
  diameter: number,
): number =>
  sleeves
    .filter((p) => p.diameter === diameter)
    .reduce(
      (acc: number, p: ISleeve) =>
        acc + getEuclideanDistance(p.pipe.start, p.pipe.end),
      0,
    );
export const edgesForDripZones = (edges: IEdge[], zones: Zone[]): IEdge[] => {
  const pocGraphs = getState().pocGraphs;
  const dripEdges: IEdge[] = [];
  const zoneValves = zones.filter((z) => z.isDrip).map((z) => z.valve);
  pocGraphs.forEach((dg) => {
    zoneValves
      .filter((v) => dg.hasNode(v))
      .forEach((v) => {
        const laterals = dg.getLaterals(v);
        laterals.forEach((lateral) => {
          const edge = findEdge(edges, lateral[0], lateral[1]);
          if (edge) {
            dripEdges.push(edge);
          }
        });
      });
  });
  return dripEdges;
};
export const edgesForSprayZones = (edges: IEdge[], zones: Zone[]): IEdge[] => {
  const pocGraphs = getState().pocGraphs;
  const lateralEdges: IEdge[] = [];
  const zoneValves = zones.map((z) => z.valve);
  pocGraphs.forEach((dg) => {
    zoneValves
      .filter((v) => dg.hasNode(v))
      .forEach((v) => {
        const laterals = dg.getLaterals(v);
        laterals.forEach((lateral) => {
          const edge = findEdge(edges, lateral[0], lateral[1]);
          if (edge) {
            lateralEdges.push(edge);
          }
        });
      });
  });
  return lateralEdges;
};
export const edgesForMainline = (edges: IEdge[]): IEdge[] => {
  const pocGraphs = getState().pocGraphs;
  const mainEdges: IEdge[] = [];
  pocGraphs.forEach((dg) => {
    const mains = dg.getMains();
    mains.forEach((main) => {
      const edge = findEdge(edges, main[0], main[1]);
      if (edge) {
        mainEdges.push(edge);
      }
    });
  });
  return mainEdges;
};
