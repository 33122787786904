import {
  DesignElement,
  DesignElementMap,
  IPoint,
  Polyline,
} from '@shared-types';
import paper from 'src/paper';
import { gradientColors } from 'src/vars';
import { newUUID } from '../crypto-uuid';
import { algoParams } from '../features/heads/head-optimizing';
import { getItemsByType, isPolyline, paperItemStore } from '../helpers';
import {
  getDistanceFromEdge,
  getDistanceFromKnownEdges,
  getDotSearchIndices,
  getEdges,
  pointIsInsideArc,
  pointIsInsideRotatedRectangle,
} from '../helpers/geometry.helpers';
import { localPaper } from '../localPaper';
import {
  activateNamedLayer,
  getArcPoints,
  getColor,
} from '../paper-helpers/plot.helpers';
import { isPaperPolyLine } from '../polyline/paper-polyline';
import { getPolylineYards } from '../polyline/polyline-helpers';
import { getPointsFromPaperPolyline } from '../polyline/polyline.service';
import { getEuclideanDistance, rotateAroundPoint } from '../shared/geometry';
import { ITEMNAMES, LAYER_NAMES } from '../shared/workbench-enums';
import { isSprinkler } from '../tools/paper-items/paper-sprinkler';
import { getState } from './state-basics';
import { setScore } from './ui.state';

export interface Dot {
  position: IPoint;
  uuid: string;
  item?: paper.Item;
  score: number;
  heads: Set<string>;
  isInside: boolean;
  distFromEdge: number;
}
export const dotCache: { [id: string]: Dot } = {};
export const yardDotCache: {
  [yardID: string]: {
    dots: Set<string>;
    edgeDots: Set<string>;
    resolution: number;
  };
} = {};
export const headDotCache: { [headID: string]: Set<string> } = {};

export const updateResolution = (newResolution: number) => {
  const { elements, elementCache } = getState();
  const polyYards = getPolylineYards();
  const layer: paper.Layer = localPaper.project.layers[LAYER_NAMES.COVERAGE];
  layer.removeChildren();
  Object.keys(dotCache).forEach((k) => {
    delete dotCache[k];
  });
  Object.keys(headDotCache).forEach((k) => {
    const headDots = headDotCache[k];
    headDots.clear();
  });
  generateDots(polyYards, newResolution);
  updateDotOwnership(elements, elementCache);
  updateDotScores(elementCache);
  updateFullScore();
};

const createDot = (
  p: paper.Point,
  resolution: number,
  color: paper.Color,
  uuid: string,
) => {
  const c = new paper.Path.Circle(p, 0.3 * resolution);
  c.fillColor = color;
  c.name = ITEMNAMES.COVERAGE_POINT;
  c.locked = true;
  c.visible = false;
  c.data = {
    uuid,
    score: 0,
  };
  return c;
};
export const generateDots = (polyYards: Polyline[], resolution: number) => {
  const bounds = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  };
  activateNamedLayer(LAYER_NAMES.COVERAGE);
  polyYards.forEach((polylineYard: Polyline, idx) => {
    const i = idx;
    console.time(`generate dots for polyyard ${i} at ${resolution}`);
    yardDotCache[polylineYard.uuid] = {
      dots: new Set(),
      edgeDots: new Set(),
      resolution,
    };
    const paperPoly = paperItemStore.get(polylineYard.uuid);
    if (paperPoly && isPaperPolyLine(paperPoly)) {
      const paperBounds = paperPoly.paperItem.bounds;
      bounds.minY = paperBounds.top;
      bounds.maxY = paperBounds.bottom;
      bounds.minX = paperBounds.left;
      bounds.maxX = paperBounds.right;
      const points = getPointsFromPaperPolyline(paperPoly);
      const pointsLength = points.length;
      const lines = points.map((p, i) => [
        p,
        points[i < pointsLength - 1 ? i + 1 : 0],
      ]);

      const paperYard = new paper.Path(points);
      if (paperYard.clockwise) {
        points.reverse();
      }
      const knownEdges = getEdges(points, true);
      const color = getColor(gradientColors[0]);
      for (let i = bounds.minX - 5; i < bounds.maxX + 5; i += resolution) {
        for (let j = bounds.minY - 5; j < bounds.maxY + 5; j += resolution) {
          const p = new paper.Point(i, j);
          if (paperYard.contains(p)) {
            const dist = getDistanceFromKnownEdges(knownEdges, p);
            const u = newUUID();
            const c = createDot(p, resolution, color, u);
            const dot: Dot = {
              item: c,
              position: { x: i, y: j },
              uuid: u,
              score: 0,
              heads: new Set(),
              isInside: true,
              distFromEdge: dist,
            };
            dotCache[u] = dot;
            yardDotCache[polylineYard.uuid].dots.add(u);
            for (const line of lines) {
              if (getDistanceFromEdge(line, p) < algoParams.edgeDotDistance) {
                yardDotCache[polylineYard.uuid].edgeDots.add(dot.uuid);
              }
            }
          }
        }
      }
      paperYard.remove();
      console.timeEnd(`generate dots for polyyard ${i} at ${resolution}`);
    }
  });
  activateNamedLayer(LAYER_NAMES.DEFAULT);
};

export const showYardDots = (shouldShow: boolean, i: number) => {
  const yardPolys = getItemsByType(isPolyline).filter(
    (p) => p.polyType === 'yard',
  );
  if (!Object.keys(yardDotCache).length) {
    updateResolution(getState().resolution);
  }
  if (yardPolys.length) {
    yardDotCache[yardPolys[i].uuid].dots.forEach((dotID) => {
      const dot = dotCache[dotID];
      if (dot.item && dot.score > 0) {
        dot.item.visible = shouldShow;
      }
    });
  }
};
export const showAllYardDots = (shouldShow: boolean) => {
  Object.values(dotCache).forEach((dot) => {
    if (dot.item && dot.score > 0) {
      dot.item.visible = shouldShow;
    }
  });
};

export const resolutionOptions = [
  { value: 1, text: "1' resolution" },
  { value: 1.5, text: "1.5' resolution" },
  { value: 2, text: "2' resolution" },
  { value: 2.5, text: "2.5' resolution" },
  { value: 3, text: "3' resolution" },
  { value: 3.5, text: "3.5' resolution" },
  { value: 4, text: "4' resolution" },
  { value: 4.5, text: "4.5' resolution" },
  { value: 5, text: "5' resolution" },
  { value: 10, text: "10' resolution" },
];

export const updateDotOwnership = (
  elements: DesignElement[],
  elementCache: DesignElementMap,
) => {
  const dots = Object.values(dotCache);
  dots.sort((a, b) => a.position.x - b.position.x);
  const dotPositions = dots.map((d) => d.position);
  elements.forEach((el) => {
    if (isSprinkler(el)) {
      if (!headDotCache[el.uuid]) {
        headDotCache[el.uuid] = new Set<string>();
      }
      const oldDots = [...headDotCache[el.uuid]];
      const props = el.props;
      const { leftIndex, rightIndex } = getDotSearchIndices(
        dotPositions,
        el.position.x,
        props.radius,
      );
      if (props.width > 0) {
        const maxD = Math.max(props.width, props.height);
        dots.forEach((dot) => {
          if (
            dot.position.x < el.position.x - maxD ||
            dot.position.x > el.position.x + maxD ||
            dot.position.y < el.position.y - maxD ||
            dot.position.y > el.position.y + maxD
          ) {
            return;
          } else {
            const d = getEuclideanDistance(dot.position, el.position);
            if (d <= maxD) {
              headDotCache[el.uuid].add(dot.uuid);
              dot.heads.add(el.uuid);
            }
          }
        });
      } else {
        // TODO: can we do the same thing with the Y value and speed up even more?
        dots.slice(leftIndex, rightIndex).forEach((dot) => {
          if (
            dot.position.x < el.position.x - props.radius ||
            dot.position.x > el.position.x + props.radius ||
            dot.position.y < el.position.y - props.radius ||
            dot.position.y > el.position.y + props.radius
          ) {
            return;
          } else {
            const d = getEuclideanDistance(dot.position, el.position);
            if (d <= props.radius) {
              headDotCache[el.uuid].add(dot.uuid);
              dot.heads.add(el.uuid);
            }
          }
        });
      }
      const newDots = headDotCache[el.uuid];
      [...oldDots, ...newDots].forEach((dotID) => {
        updateDotScore(dotID, elementCache);
      });
    }
  });
  updateFullScore();
};

export const updateDotScores = (elementCache: DesignElementMap) => {
  // console.time('update scores')
  Object.keys(dotCache).forEach((id) => updateDotScore(id, elementCache));
  const scores = Object.values(dotCache).map((d) => d.score);
  const score =
    scores.filter((s) => s > algoParams.minScore && s <= algoParams.maxScore)
      .length / scores.length;
  setScore(score);
  // console.timeEnd('update scores')
};
export const updateFullScore = () => {
  const scores = Object.values(dotCache).map((d) => d.score);
  const score =
    scores.filter((s) => s > algoParams.minScore && s <= algoParams.maxScore)
      .length / scores.length;
  setScore(score);
};
export const updateDotScore = (
  id: string,
  elementCache: DesignElementMap,
  replacedEl?: DesignElement,
) => {
  const dot = dotCache[id];
  let score = 0;
  dot.heads.forEach((headID) => {
    let el = elementCache[headID];
    if (isSprinkler(el)) {
      const props = el.props;
      let radius = props.radius;
      if (replacedEl && headID === replacedEl.uuid) {
        el = replacedEl;
        radius = props.radius;
      }
      if (props.width > 0) {
        // is strip
        if (
          pointIsInsideRotatedRectangle(
            el.position,
            props.origin,
            props.width,
            props.height,
            props.rotation,
            dot.position,
          )
        ) {
          const dist = getEuclideanDistance(el.position, dot.position);
          const maxD = Math.max(props.width, props.height);
          const pointCoverage = 1 - dist / maxD;
          score += pointCoverage;
        }
      } else {
        const d = getEuclideanDistance(el.position, dot.position);
        if (d <= radius) {
          if (props.angle !== 360) {
            const arcValues = getArcPoints(
              props.angle,
              new paper.Point(el.position),
              props.radius,
            );
            const rotatedFrom = rotateAroundPoint(
              el.position,
              { x: arcValues.from[0], y: arcValues.from[1] },
              -props.rotation,
            );
            const rotatedTo = rotateAroundPoint(
              el.position,
              { x: arcValues.to[0], y: arcValues.to[1] },
              -props.rotation,
            );
            const isInside = pointIsInsideArc(dot.position, {
              center: el.position,
              radius: props.radius,
              from: rotatedTo,
              to: rotatedFrom,
            });
            if (isInside) {
              const pointCoverage = 1 - d / radius;
              score += pointCoverage;
            }
          } else {
            const pointCoverage = 1 - d / radius;
            score += pointCoverage;
          }
        }
      }
    }
  });
  dot.score = score;
  updateDotColor(dot.uuid);
};
export const updateDotColor = (id: string) => {
  const dot = dotCache[id];
  if (dot.item) {
    const score = dotCache[dot.uuid].score;
    if (score) {
      const colorIndex = Math.min(
        Math.round(score * 10),
        gradientColors.length - 1,
      );
      dot.item.data = {
        ...dot.item.data,
        score,
      };
      dot.item.fillColor = getColor(gradientColors[colorIndex]);
    } else {
      dot.item.visible = false;
    }
  }
};

export const deleteCoverageCaches = (
  elementIDs: string[],
  elementCache: DesignElementMap,
) => {
  elementIDs.forEach((id) => {
    delete headDotCache[id];
    Object.values(dotCache).forEach((dot) => {
      dot.heads.delete(id);
    });
  });
  updateDotScores(elementCache);
};
