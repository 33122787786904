import { Zone } from '@shared-types';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { ZoneButton } from '../components/ChangeZone';
import {
  reduceHeadsGPMbyId,
  reducePlantsGPHbyId,
} from '../helpers/directedGraph';
import { headInZone } from '../selectors';
import { pocGraphByNode, unzonePlants, useDesignStore } from '../state';
import { isPlant } from './plants';

interface Props {
  plantIDs: string[];
  onAdd: (zoneId: string, plantIDs: string[]) => void;
}
export const ChangeDripZone = ({ plantIDs, onAdd }: Props): JSX.Element => {
  const zones = useDesignStore((state) => state.zones);
  const plants = useDesignStore((state) => state.items.filter(isPlant));
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const elementCache = useDesignStore((state) => state.elementCache);
  const pocGraphs = useDesignStore((state) => state.pocGraphs);

  const changeZone = (zoneId: string) => {
    onAdd(zoneId, plantIDs);
  };

  const removeFromZones = (plantIDs: string[]) => unzonePlants(plantIDs);

  return (
    <Wrap>
      <h5>
        Change zone of {plantIDs.length}{' '}
        {plantIDs.length === 1 ? 'plant' : 'plants'}
      </h5>
      <p>
        Selected plants have a total of{' '}
        {reducePlantsGPHbyId(plants, plantIDs).toFixed(2)} GPH
      </p>
      <Button onClick={removeFromZones.bind(null, plantIDs)}>
        Unzone Plant
      </Button>
      <br />
      {zones.map((zone: Zone, k: number) => {
        const gpmOfZonedItems =
          zone.headIds.length > 0
            ? reduceHeadsGPMbyId(zone.headIds, elementCache)
            : reducePlantsGPHbyId(plants, zone.plantIds) / 60;
        const dg = pocGraphByNode(zone.valve, pocGraphs);
        const perc = dg ? gpmOfZonedItems / dg.rootGPM(pipeProducts) : -1;
        return (
          <ZoneButton
            onClick={changeZone.bind(null, zone.uuid)}
            disabled={!zone.isDrip || zone.plantIds.includes(plantIDs[0])}
            key={k}
            $percentage={perc}
            $color={
              plantIDs.length === 1 && headInZone(zones, zone.uuid, plantIDs[0])
                ? 'transparent'
                : zone.color
            }
          >
            <div className="details">{gpmOfZonedItems.toFixed(1)} GPM</div>
            <div className="color">
              {zone.isDrip || zone.plantIds.length > 0
                ? `D${zone.orderNumber + 1}`
                : zone.orderNumber + 1}
            </div>
            {perc * 100 > 100 && <div>TOO FULL</div>}
            {perc * 100 < 100 && (
              <div className="bar">
                <div className="percentage" />
              </div>
            )}
          </ZoneButton>
        );
      })}
      <br />
      <div>
        <small>
          Note: if a drip zone is disabled, the plants are already in that zone,
          or that zone probably hasn't been marked as "is drip zone" in the zone
          panel
        </small>
      </div>
    </Wrap>
  );
};

const Wrap = styled.div``;
