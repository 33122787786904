import { DesignElement, IMiscItem } from '@shared-types';
import { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { styled } from 'styled-components';
import { changeElements } from '../state/changeElements';
import { PanelInputWrap, PanelWrap } from './PanelButton';

interface Props {
  element: DesignElement;
}
export const MiscItemTooltip = ({ element }: Props) => {
  const [gpmEffect, setGpmEffect] = useState(0);

  useEffect(() => {
    if (element.type === 'miscItem') {
      setGpmEffect((element.props as IMiscItem).gpmEffect || 0);
    }
  }, [element]);
  const updateDT = () => {
    changeElements([
      {
        ...element,
        props: {
          ...element.props,
          gpmEffect,
        },
      },
    ]);
  };

  return (
    <Wrap>
      <p>{(element.props as IMiscItem).gpmEffect?.toFixed(3)} GPM</p>
      {element.type === 'miscItem' &&
        (element.props as IMiscItem).name === 'Drip Transition' && (
          <>
            <PanelInputWrap
              type="number"
              label="GPM Effect"
              value={(element.props as IMiscItem).gpmEffect}
              onChange={(e) => setGpmEffect(parseFloat(e.target.value))}
            />
            <Button onClick={updateDT}>Update</Button>
          </>
        )}
    </Wrap>
  );
};
const Wrap = styled(PanelWrap)``;
