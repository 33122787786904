import { TextChunk } from '@shared-types';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  DropdownProps,
  Input,
  InputOnChangeData,
  Select,
  TextArea,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { updateItem } from '../state/item-state';

export const fontSizes = [
  8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72,
];

interface Props {
  item: TextChunk;
}
export const TextTooltip = ({ item }: Props) => {
  const [localText, setLocalText] = useState(item);
  const [width, setWidth] = useState(item.width);
  const [fontSize, setFontSize] = useState(item.fontSize);
  const [content, setContent] = useState(item.content);

  useEffect(() => {
    setLocalText(item);
    setWidth(item.width);
    setFontSize(item.fontSize);
    setContent(item.content);
  }, [item]);

  const handleChangingText = (e, d) => {
    e.stopPropagation();
    setContent(d.value);
    const updatedItem = {
      ...localText,
      content: d.value,
    };
    setLocalText(updatedItem);
    updateItem(updatedItem);
  };

  const handleChangeWidth = (e: ChangeEvent, d: InputOnChangeData) => {
    e.stopPropagation();
    const v = parseInt(d.value, 10);
    setWidth(v);
    const updatedItem = {
      ...localText,
      width: v,
    };
    setLocalText(updatedItem);
    updateItem(updatedItem);
  };

  const handleChangeFontSize = (_, data: DropdownProps) => {
    const newFontSize = data.value as number;
    setFontSize(newFontSize);
    const updatedItem = {
      ...localText,
      fontSize: newFontSize,
    };
    setLocalText(updatedItem);
    updateItem(updatedItem);
  };

  return (
    <Wrap>
      <label>Content</label>
      <TextArea
        value={content}
        rows={5}
        onChange={handleChangingText}
        cols="20"
      />
      <br />
      <br />
      <div className="flex-form-row">
        <label>Character Width: </label>
        <Input type="number" value={width} onChange={handleChangeWidth} />
      </div>
      <br />
      <br />
      <div className="flex-form-row">
        <label>Font Size: </label>
        <Select
          compact={true}
          value={fontSize}
          onChange={handleChangeFontSize}
          options={fontSizes.map((n: number) => ({
            text: `${n}`,
            value: n,
          }))}
        />
      </div>
    </Wrap>
  );
};
const Wrap = styled.div`
  padding: 8px;
  Textarea {
    width: 100%;
    padding: 8px;
  }
  .flex-form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    label {
      width: 50%;
      flex: 0 0 50%;
    }
    .ui.input {
      width: 50%;
      flex: 0 0 50%;
    }
    .ui.dropdown {
      width: 50%;
    }
  }
`;
