import { IPoint } from '@shared-types';
import paper from 'src/paper';
import { itemSizes } from 'src/shared';
import { ITEMNAMES } from '../shared/workbench-enums';
import { getColor } from './plot.helpers';

export const createTextBox = (
  point: IPoint,
  str: string,
  scale: number,
): paper.Item => {
  const p = new paper.Point(point);
  const fontSize = itemSizes.font / scale;
  const height = fontSize * 1.75;
  const width =
    str.length === 1 ? fontSize * 1.8 : str.length * (fontSize * 0.85);
  const textBoxGroup = new paper.Group();
  textBoxGroup.name = ITEMNAMES.MISC_ITEM;
  const outline = new paper.Path.Rectangle(
    new paper.Rectangle(
      new paper.Point(p.x - width / 2, p.y - height / 2),
      new paper.Size(width, height),
    ),
  );
  outline.strokeColor = getColor('#000');
  outline.strokeWidth = itemSizes.genericOutline / scale;
  outline.fillColor = getColor('#fff');
  const text = new paper.PointText(new paper.Point(p.x, p.y + fontSize / 2.5));
  text.content = str;
  text.fontSize = fontSize;
  text.justification = 'center';
  textBoxGroup.addChildren([outline, text]);
  return textBoxGroup;
};
