import { uniq } from 'underscore';
import { IEdge, ValveBox } from '../../../../../../shared-types';
import { addUndo } from '../../contexts/design/undo';
import { createEdgesBetweenValveSlots } from '../../paper-helpers/valves';
import { paperState } from '../paper.state';
import { getState, setState } from '../state-basics';
import { finalizeSteps } from '../zone.state';
import { addValveBoxLocation } from './addValveBoxLocation';

export const deleteValveBox = (payload: string, undoable = true) => {
  const { valveBoxes, edges, elements, zones, scale } = getState();
  const vb = valveBoxes.find((vb) => vb.uuid === payload);
  if (vb) {
    if (undoable) {
      addUndo(() => {
        addValveBoxLocation(
          payload,
          vb.maxSlots,
          vb.position,
          vb.rotation,
          false,
        );
      }, 'Delete Valve Box');
    }
    const edgeIDsToDelete = valveBoxEdgeIDsToDelete(vb, edges);
    paperState.deleteItems([
      vb.inputFitting,
      vb.outputFitting,
      ...edgeIDsToDelete,
    ]);
    const updatedEdges = edges.filter(
      (edge) => !edgeIDsToDelete.includes(edge.uuid),
    );
    const updatedElements = elements.filter(
      (el) => el.uuid !== vb.inputFitting && el.uuid !== vb.outputFitting,
    );
    const final = finalizeSteps(updatedElements, updatedEdges, zones, scale);
    const updatedValveBoxes = valveBoxes.filter((vb) => vb.uuid !== payload);
    setState({
      valveBoxes: updatedValveBoxes,
      elements: updatedElements,
      edges: final.finalizedEdges,
      elementCache: final.elementCache,
      masterGraph: final.masterGraph,
      pocGraphs: final.pocGraphs,
    });
  } else {
    console.error('Valve box not found');
  }
};
export const valveBoxEdgeIDsToDelete = (
  vb: ValveBox,
  edges: IEdge[],
): string[] => {
  /**
   * Note: when deleting a valve box, you should delete all the in between edges
   * and both of the edges that are connected to the valve box input/output fittings.
   * Otherwise, you're left with pipes that don't have an end point, so they'll render
   * invisibly the next time you open the design.
   * */
  return uniq(
    [
      ...(createEdgesBetweenValveSlots(
        vb,
        getState().zones,
        getState().mainPipe,
        getState().pipeProducts,
      )
        .map((edge) =>
          getState().edges.find(
            (e) =>
              (e.source === edge.source && e.target === edge.target) ||
              (e.source === edge.target && e.target === edge.source),
          ),
        )
        .filter((e: IEdge | undefined) => !!e) as IEdge[]),
      ...edges.filter(
        (edge: IEdge) =>
          edge.source === vb.inputFitting ||
          edge.target === vb.inputFitting ||
          edge.source === vb.outputFitting ||
          edge.target === vb.outputFitting,
      ),
    ].map((e) => e.uuid),
  );
};
