import React from 'react';
import { ValveBox } from '../../../../../../shared-types';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import {
  selectAndCenterSingleID,
  setShowValveBoxPreviews,
  useDesignStore,
} from '../../state';
import { changeValveBox } from '../../state/valvebox/changeValveBox';
import { deleteValveBox } from '../../state/valvebox/deleteValveBox';
import { CheckboxAction } from './CheckboxAction';
import { LayerChild } from './LayerChild';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const ValveBoxesLayer = ({ open, onOpen }) => {
  const showValveBoxPreviews = useDesignStore(
    (state) => state.showValveBoxPreviews,
  );
  const valveBoxes = useDesignStore((state) => state.valveBoxes);
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.VALVE_BOXES,
  );

  const toggleChildVisibility = (item: ValveBox) => {
    changeValveBox({ ...item, visible: !item.visible }, true);
  };

  const toggleChildLock = (item: ValveBox) => {
    changeValveBox({ ...item, locked: !item.locked }, true);
  };
  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Valve Boxes (${valveBoxes.length})`}
      open={open}
      onOpen={onOpen}
      actions={
        <>
          <CheckboxAction
            title="Preview"
            checked={showValveBoxPreviews}
            onClick={() => setShowValveBoxPreviews(!showValveBoxPreviews)}
          />
        </>
      }
      childLayer={valveBoxes.map((valveBox, i) => (
        <LayerChild
          key={valveBox.uuid}
          visible={valveBox.visible}
          locked={valveBox.locked}
          title={`ValveBox ${i + 1} (${valveBox.maxSlots} slots)`}
          clickToggle={() => toggleChildVisibility(valveBox)}
          clickLock={() => toggleChildLock(valveBox)}
          clickDelete={() => deleteValveBox(valveBox.uuid)}
          clickItem={() => selectAndCenterSingleID(valveBox.uuid)}
          layerLocked={layerLocked}
          layerVisible={layerVisible}
        />
      ))}
    />
  );
};

export default React.memo(ValveBoxesLayer);
