import { IMiscItem } from '@shared-types';
import { contains } from 'underscore';
import { isPlant } from '../../plants/plants';
import { useDesignStore } from '../../state';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';

export const StatsList = () => {
  const elements = useDesignStore((state) => state.elements);
  const edges = useDesignStore((state) => state.edges);
  const valveBoxes = useDesignStore((state) => state.valveBoxes);
  const zones = useDesignStore((state) => state.zones);
  const plants = useDesignStore((state) => state.items.filter(isPlant));
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const pocGraphs = useDesignStore((state) => state.pocGraphs);

  return (
    <div>
      {pocGraphs.length} graphs
      <br />
      {valveBoxes.length} valve boxes
      <br />
      {edges.length} edges
      <br />
      {elements.length} elements
      <ul>
        <li>{elements.filter((i) => i.type === 'fitting').length} fittings</li>
        <li>{elements.filter(isSprinkler).length} sprinklers</li>
        <li>{elements.filter((i) => i.type === 'valve').length} valves</li>
        <li>{elements.filter((i) => i.type === 'meter').length} meters</li>
        <li>{elements.filter((i) => i.type === 'pump').length} pumps</li>
        <li>
          {elements.filter((i) => i.type === 'poc').length} points of connection
        </li>
        <li>{plants.length} plants</li>
        <li>
          {
            plants.filter((plant) =>
              zones.some((z) => contains(z.plantIds, plant.uuid)),
            ).length
          }{' '}
          zoned plants
        </li>
        <li>
          {
            elements.filter(
              (i) =>
                i.type === 'miscItem' &&
                (i.props as IMiscItem).name === 'Quick Coupler',
            ).length
          }{' '}
          quick couplers
        </li>
      </ul>
      <br />
      {pocGraphs.map((dg, dgIndex) => (
        <div key={dgIndex}>
          graph {dgIndex + 1}: {dg.rootGPM(pipeProducts)} gpm and{' '}
          {dg.pocPSI(pipeProducts)} psi
        </div>
      ))}
    </div>
  );
};
