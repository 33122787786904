import { PDFDocument } from 'pdf-lib';
import { Content, TableCell } from 'pdfmake/interfaces';
import {
  IDesignState,
  IValveProduct,
  Sprinkler,
  Zone,
} from '../../../../../../shared-types';
import { getZoneEdgeLength } from '../../paper-helpers/edges';
import { getAvgPrecip, getPrecipStDev } from '../../paper-helpers/heads';
import { isPlant } from '../../plants/plants';
import { getState } from '../../state';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';
import { valveGPM } from '../directedGraph';
import { createPDFMakePage } from './createPDFMakePage';
import { mapHeadIdsToHeads } from './mapHeadIdsToHeads';
import { minimalTable, standardDefinition } from './pdf.helpers';
import {
  getDripRows,
  getHeadRows,
  getLateralRows,
  getPlantRows,
} from './takeoffPage';

export async function createZonesPagesPDF(): Promise<PDFDocument | void> {
  const definitionFunc = async () => standardDefinition();
  const getContent = async () => zonesPages();
  return createPDFMakePage(definitionFunc, getContent);
}
const generateZoneGraphs = () => {
  const zoneGraph = document.querySelectorAll('.zone-tree');
  const zoneCharts: string[] = [];
  zoneGraph.forEach((tree) => zoneCharts.push(tree.outerHTML));
  return zoneCharts;
};
const zonesPages = () => {
  const zoneCharts = generateZoneGraphs();
  if (!zoneCharts.length) throw new Error('No zone graphs found');
  const state = getState();
  return state.zones
    .sort((z1, z2) => z1.orderNumber - z2.orderNumber)
    .map((zone: Zone, i: number) =>
      singleZonePage(zone, zoneCharts[i], state, i === state.zones.length - 1),
    );
};
export const singleZonePage = (
  zone: Zone,
  chart: string,
  design: IDesignState,
  isLast = false,
): Content[] => {
  const { elements, edges, pipeProducts, groups, elementCache, pocGraphs } =
    design;
  const plants = design.items.filter(isPlant);

  const heads = elements.filter(isSprinkler);
  const zoneGPM =
    zone.plantIds.length > 0
      ? valveGPM(zone, pipeProducts, groups, pocGraphs).toFixed(1)
      : mapHeadIdsToHeads(heads, zone.headIds)
          .reduce((acc: number, h: Sprinkler) => acc + h.gpm, 0)
          .toFixed(1);
  const zonePrecip =
    zone.headIds.length > 0 ? getAvgPrecip(heads, zone.headIds).toFixed(2) : 0;
  const zoneStDev =
    zone.headIds.length > 0
      ? getPrecipStDev(heads, zone.headIds).toFixed(2)
      : 0;
  const headCount = zone.headIds.length;
  const plantCount = zone.plantIds.length;

  const pipeRows =
    zone.headIds.length > 0
      ? getLateralRows(edges, [zone], elements, pipeProducts)
      : getDripRows(edges, [zone], elements, pipeProducts);
  const valve = elementCache[zone.valve];
  const valveRows: TableCell[] = [
    [
      1,
      !!valve
        ? `${(valve.props as IValveProduct).brand} ${
            (valve.props as IValveProduct).name
          }`
        : 'n/a',
    ],
  ];
  const headRows = getHeadRows(heads, zone);
  const plantRows: Content = getPlantRows(plants, zone);
  let headsOrPlants: Content = '';
  const totalZonePipe = getZoneEdgeLength(edges, zone, elementCache, pocGraphs);
  if (zone.headIds.length && !zone.plantIds.length) {
    headsOrPlants = minimalTable('Heads & Nozzles', headRows);
  }
  if (!zone.isDrip && !zone.headIds.length && zone.plantIds.length > 0) {
    headsOrPlants = minimalTable('Plants (for estimating emitters)', plantRows);
  }
  let metaStacks: { text: string; subtext: string }[] = [];
  if (zone.isDrip || zone.plantIds.length) {
    metaStacks = [
      { text: `${zoneGPM}`, subtext: 'gpm' },
      { text: `${plantCount}`, subtext: 'plants' },
      { text: `${Math.ceil(totalZonePipe)}ft`, subtext: 'pipe' },
    ];
  } else {
    metaStacks = [
      {
        text: `${zonePrecip} in/hr`,
        subtext: `avg. precip (+/- ${zoneStDev})`,
      },
      { text: `${zoneGPM}`, subtext: 'gpm' },
      // { text: `00:00`, subtext: 'run time' },
      { text: `${headCount}`, subtext: 'heads' },
      { text: `${Math.ceil(totalZonePipe)}ft`, subtext: 'pipe' },
      // { text: `0 sqft`, subtext: 'zone area' }
    ];
  }
  const page: Content[] = [
    {
      text: `Zone ${zone.orderNumber + 1} ${
        zone.isDrip || zone.plantIds.length > 0 ? '(Drip) ' : ''
      }Details`,
      style: 'header',
      pageOrientation: 'portrait',
    },
    {
      margin: [0, 30, 0, 30],
      columns: [
        {
          width: '*',
          stack: [
            minimalTable(
              'Pipes',
              pipeRows,
              '** Drip tubing off laterals not recommended to exceed 200 feet. Drip tubing should be adjusted on-site for specific plant location. Not shown on plan.',
            ),
            headsOrPlants,
            minimalTable('Valves & Extras', valveRows),
          ],
        },
        {
          width: 150,
          alignment: 'center',
          stack: [
            ...metaStacks.map((s) => ({
              stack: [
                { text: s.text, style: 'numHead' },
                { text: s.subtext, style: 'numHeadSub' },
              ],
            })),
          ],
        },
      ],
      columnGap: 0,
    },
    { text: 'Zone flow chart', fontSize: 12, bold: true },
    {
      text: 'Chart shows pipe diameters at each change in pipe size as water flows through the zone. Residual pressure for each possible path of water through the zone is shown at the end of each branch (with the exception of drip zones). The path with the least residual pressure is used to determine the residual pressure for the whole zone. Not drawn to scale. On-site substitution of pipes should always use a pipe with a diameter equal to or greater than the one shown on the chart.',
      fontSize: 10,
    },
    { svg: chart ? chart : '<svg></svg>' },
  ];
  if (!isLast) {
    page.push({ text: '', pageBreak: 'after' });
  }
  return page;
};
