import styled from 'styled-components';
import { useDesignStore } from '../../state';
import { ValveBoxDiagram } from './ValveBoxDiagram';

export const ValveDiagrams = () => {
  const valveBoxes = useDesignStore((state) => state.valveBoxes);

  return (
    <Wrap>
      {valveBoxes.map((valveBox, i) => (
        <ValveBoxDiagram
          classTitle={`valve-diagram`}
          key={i}
          valveBox={valveBox}
        />
      ))}
    </Wrap>
  );
};
const Wrap = styled.div``;
