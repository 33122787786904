import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { irrigationBlue } from '../../../../vars';

const ButtonWrap = styled.button`
  background: none;
  padding: 0;
  border: 0;
  outline: 0;
  margin: 0;
  border-right: 1px solid #555;
  &:disabled {
    .icon {
      cursor: not-allowed;
      color: #555;
      &:hover {
        color: #555;
      }
    }
  }
  .icon {
    height: 30px;
    width: 30px;
    margin-right: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 30px;
    font-size: 12px;
    color: #222;
    cursor: pointer;
    &:hover {
      color: ${irrigationBlue};
    }
  }
`;
export const LockButton = ({ locked, disabled, onClick }) => {
  return (
    <LockButtonWrap
      $locked={locked}
      className="lock-button"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon name={locked ? 'lock' : 'unlock alternate'} />
    </LockButtonWrap>
  );
};

const LockButtonWrap = styled(ButtonWrap)<{ $locked: boolean }>`
  background: #5e5e5e;
  &:hover {
    background: #444;
  }
  .icon {
    color: black;
    font-size: 10px;
    opacity: ${(props) => (props.$locked ? 1 : 0.1)};
    &:hover {
      color: black;
    }
  }
`;
export const VisibilityButton = ({ visible, disabled, onClick }) => {
  return (
    <VisibilityButtonWrap
      $visible={visible}
      className="visible-button"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon name="square" />
    </VisibilityButtonWrap>
  );
};
const VisibilityButtonWrap = styled(ButtonWrap)<{ $visible: boolean }>`
  background: #5e5e5e;
  &:hover {
    background: #444;
  }
  .icon {
    color: black;
    font-size: 8px;
    opacity: ${(props) => (props.$visible ? 1 : 0.1)};
    &:hover {
      color: black;
    }
  }
`;

export const DeleteButton = ({ disabled, onClick }) => {
  return (
    <DeleteButtonWrap
      className="delete-button"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon name="trash alternate" />
    </DeleteButtonWrap>
  );
};
const DeleteButtonWrap = styled(ButtonWrap)`
  background: #5e5e5e;
  &:hover {
    background: #444;
  }
  .icon {
    color: #ccc;
    font-size: 10px;
    &:hover {
      color: red;
    }
  }
`;
