// - find top 5 grid points with worst coverage
// - pick one of them randomly to start with
// - look up the heads that are covering that grid point
// - add each head to the list of proposal heads to change
// - for each of those heads, create and test each proposal
//     - get the list of grid points the initial state of the head affects
//     - at any point, if proposal is worse than current proposal, exit
//     - else
//         - try to create a "score"
//             - % of score for "reasonability"
//             - % of score for % of overage reduced
//             - % of score for % of coverage maintained
//         - check for reasonability
//             - if head is reasonable (ie, similar enough to the closest 3 heads)
//             - maybe add a check for overspray, inside yard, etc
//         - check for radius or position change
//             - if changing radius reduces overage in initial grid points where needed
//             - if changing radius maintains coverage in initial grid points where needed
//             - if new grid points are scooped in during the radius expansion, check their initial scores and see if the overage has been reduced and the coverage has been maintained
import { Sprinkler } from '@shared-types';

export const headOptionCache: { [key: string]: Sprinkler[] } = {};
// export const edgeHeadCache = new Set<string>();

export const algoParams = {
  positionDistance: 15,
  edgeDotWeight: 0,
  decreasedCoverageCountWeight: 1,
  decreasedOverageCountWeight: 3,
  decreasedOutsideWeight: 0,
  headDecreaseWeight: 200,
  minScore: 0.9,
  maxScore: 1.4,
  edgeDotDistance: 1,
};

// export const outputLog = {};

// const getStartingPoint = (yardID: string): string => {
//   const dots = [...yardDotCache[yardID].dots]
//     .map((d) => dotCache[d])
//     .filter(
//       (d) =>
//         d.isInside &&
//         !yardDotCache[yardID].edgeDots.has(d.uuid) &&
//         d.score > 0 &&
//         (d.score > algoParams.maxScore || d.score < algoParams.minScore),
//     );
//   // console.log(`${dots.length} dots possible to start from`)
//   dots.sort((a, b) => b.score - a.score);
//   // return dots.length ? dots[0].uuid : ''
//   // return dots[math.randomInt(0, dots.length - 1)].uuid
//   return dots[math.randomInt(0, 10)].uuid;
// };

// export const generateAffectingHeads = (
//   dotID: string,
//   elements: DesignElement[],
// ): void => {
//   const heads = elements.filter(isSprinkler);
//   dotCache[dotID].heads = new Set();
//   heads.forEach((head) => {
//     const d = getEuclideanDistance(head, dotCache[dotID].position);
//     if (d <= head.props.radius) {
//       dotCache[dotID].heads.add(head.uuid);
//     }
//   });
// };

// interface Proposal {
//   headID: string;
//   type: 'r' | 'x' | 'y' | 'd';
//   val: number | Sprinkler;
// }
// export const createPositionProposals = (headIDs: string[]): Proposal[] => {
//   let positionChanges = algoParams.positionDistance;
//   let proposals: Proposal[] = [];
//   const nonEdgeHeads = headIDs.filter((id) => !edgeHeadCache.has(id));
//   for (let i = 0; i < nonEdgeHeads.length; i++) {
//     const headID = nonEdgeHeads[i];
//     // create position proposals for x and y
//     for (let d = 1; d <= positionChanges; d++) {
//       proposals.push({ headID, type: 'x', val: d });
//       proposals.push({ headID, type: 'x', val: -d });
//       proposals.push({ headID, type: 'y', val: d });
//       proposals.push({ headID, type: 'y', val: -d });
//     }
//   }
//   return proposals;
// };
// export const createDeleteProposals = (headIDs: string[]): Proposal[] => {
//   let proposals: Proposal[] = [];
//   for (let i = 0; i < headIDs.length; i++) {
//     const headID = headIDs[i];
//     proposals.push({ headID, type: 'd', val: 1 });
//   }
//   return proposals;
// };
// export const createHeadSwapProposals = (
//   headIDs: string[],
//   elementCache: DesignElementMap,
// ) => {
//   let proposals: Proposal[] = [];
//   for (let i = 0; i < headIDs.length; i++) {
//     const headID = headIDs[i];
//     // create radius proposals for this head
//     const radiusOptions = headOptionCache[headID];
//     for (let sprinkler of radiusOptions) {
//       const el = elementCache[headID];
//       if (isSprinkler(el) && sprinkler.radius !== el.props.radius) {
//         proposals.push({ headID, type: 'r', val: sprinkler });
//       }
//     }
//   }
//   return proposals;
// };
// export const createProposals = (
//   headIDs: string[],
//   elementCache: DesignElementMap,
// ): Proposal[] => {
//   let proposals: Proposal[] = [];
//   const headSwapProposals = createHeadSwapProposals(headIDs, elementCache);
//   const positionProposals = createPositionProposals(headIDs);
//   const deleteProposals = createDeleteProposals(headIDs);
//   proposals.push(
//     ...positionProposals,
//     ...headSwapProposals,
//     ...deleteProposals,
//   );
//   // proposals.push(...positionProposals, ...headSwapProposals, ...deleteProposals)
//   return proposals;
// };

// const createNewHeadEl = (
//   oldHead: DesignElement,
//   proposal: Proposal,
// ): DesignElement => {
//   const newHead: DesignElement = {
//     ...oldHead,
//     props: {
//       ...oldHead.props,
//     },
//   };
//   let props = newHead.props;
//   switch (proposal.type) {
//     case 'r':
//       props = proposal.val;
//       break;
//     case 'x':
//       newHead.x = newHead.x + (proposal.val as number);
//       props.x = props.x + (proposal.val as number);
//       break;
//     case 'y':
//       newHead.y = newHead.y + (proposal.val as number);
//       props.y = props.y + (proposal.val as number);
//       break;
//     case 'd':
//       props.radius = 0;
//       break;
//   }
//   newHead.props = props;
//   return newHead;
// };

// export const getDotsCovered = (el: DesignElement): string[] => {
//   let dotsCovered: string[] = [];
//   Object.values(dotCache).forEach((dot) => {
//     const d = getEuclideanDistance(dot.position, el);
//     if (d <= (el.props).radius) {
//       dotsCovered.push(dot.uuid);
//     }
//   });
//   return dotsCovered;
// };

// export const scoreDot = (
//   dotID: string,
//   elementCache: DesignElementMap,
//   replacedHead?: DesignElement,
// ) => {
//   const affectingHeads = [...dotCache[dotID].heads];
//   const dot = dotCache[dotID];
//   let score = 0;
//   for (let i = 0; i < affectingHeads.length; i++) {
//     let el = elementCache[affectingHeads[i]];
//     let radius = (el.props).radius;
//     if (replacedHead && affectingHeads[i] === replacedHead.uuid) {
//       el = replacedHead;
//       radius = (el.props).radius;
//     }
//     const d = getEuclideanDistance(el, dot.position);
//     if (d <= radius) {
//       const pointCoverage = 1 - d / radius;
//       score += pointCoverage;
//     }
//   }
//   return score;
// };

// const scoreProposal = (
//   proposal: Proposal,
//   yardID: string,
//   elementCache: DesignElementMap,
// ): { score: number; head: DesignElement; proposal: Proposal } => {
//   let score = -1000000;
//   const oldHeadEl = elementCache[proposal.headID];
//   if (!isSprinkler(oldHeadEl)) throw new Error('not a sprinkler')
//   // if ((oldHeadEl.props).angle !== 360) {
//   //   return { score, head: oldHeadEl, proposal }
//   // }
//   let dots = [...headDotCache[proposal.headID]];
//   let newHeadEl: DesignElement = oldHeadEl;
//   // let headDecrease = 0
//   if (proposal.type === 'd') {
//     // different way to setup scoring head deletion
//     // temporarily remove head from cache and dot cache
//     // headDecrease += 1
//   } else {
//     newHeadEl = createNewHeadEl(oldHeadEl, proposal);
//     const coveredDots = getDotsCovered(newHeadEl);
//     dots = uniq([...dots, ...coveredDots]);
//   }
//   // const decreasedCoverageCount = 0
//   // const decreasedOverageCount = 0
//   // let borderDotsIncluded = 0
//   let squaredDiffUnder = 0;
//   let squaredDiffOver = 0;
//   let squaredDiffOutside = 0;
//   for (let i = 0; i < dots.length; i++) {
//     const dotID = dots[i];
//     if (yardDotCache[yardID].dots.has(dotID)) {
//       // borderDotsIncluded += algoParams.edgeDotWeight
//     }
//     const oldScore = dotCache[dotID].score;
//     let newScore = oldScore;
//     if (proposal.type === 'd') {
//       dotCache[dotID].heads.delete(proposal.headID);
//       newScore = scoreDot(dotID, elementCache);
//       dotCache[dotID].heads.add(proposal.headID);
//     } else {
//       newScore = scoreDot(dotID, elementCache, newHeadEl);
//     }
//     if (dotCache[dotID].isInside) {
//       if (newScore < algoParams.minScore) {
//         const diff = algoParams.minScore - newScore;
//         squaredDiffUnder -= diff * 10 * (diff * 10);
//       } else if (newScore > algoParams.maxScore) {
//         const diff = newScore - algoParams.maxScore;
//         squaredDiffOver -= diff * 10 * (diff * 10);
//       }
//     } else {
//       squaredDiffOutside -= newScore;
//     }
//     // if (newScore < algoParams.minScore) {
//     //   decreasedCoverageCount += algoParams.decreasedCoverageCountWeight
//     // }
//     // if (newScore > algoParams.minScore && newScore < oldScore) {
//     //   decreasedOverageCount += algoParams.decreasedOverageCountWeight
//     // }
//   }
//   score =
//     // decreasedOverageCount * algoParams.decreasedOverageCountWeight -
//     // decreasedCoverageCount * algoParams.decreasedCoverageCountWeight +
//     // headDecrease * algoParams.headDecreaseWeight -
//     // borderDotsIncluded * algoParams.edgeDotWeight +
//     squaredDiffUnder * algoParams.decreasedCoverageCountWeight +
//     squaredDiffOver * algoParams.decreasedOverageCountWeight +
//     squaredDiffOutside * algoParams.decreasedOutsideWeight;
//   return { score, head: newHeadEl, proposal };
// };

// export const getBestProposal = (
//   proposals: Proposal[],
//   yardID: string,
//   elementCache: DesignElementMap,
// ) => {
//   let bestProposalIndex = 0;
//   let bestScore = -1000000;
//   let bestScoreHead: DesignElement | null = null;

//   for (let i = 0; i < proposals.length; i++) {
//     const { score, head } = scoreProposal(proposals[i], yardID, elementCache);
//     if (score > bestScore) {
//       bestScore = score;
//       bestProposalIndex = i;
//       bestScoreHead = head;
//     }
//   }
//   console.log(`best score: ${bestScore}`);
//   return { proposal: proposals[bestProposalIndex], bestScoreHead };
// };

// export const implementProposal = (
//   head: DesignElement,
//   proposal: Proposal,
//   elements: DesignElement[],
//   elementCache: DesignElementMap,
// ) => {
//   if (proposal.type === 'd') {
//     deleteElements([head.uuid]);
//     // localPaper.project.getItem({ data: { uuid: head.uuid } }).selected = true
//   } else {
//     // update cache
//     // headDotCache[head.uuid].element = head
//     const oldDots = [...headDotCache[head.uuid]];
//     const newDots = getDotsCovered(head);
//     headDotCache[head.uuid] = new Set(newDots);
//     // update affecting heads for each dot
//     oldDots.forEach((dot) => {
//       // TODO: can we be smarter than just comparing to all heads again?
//       generateAffectingHeads(dot, elements);
//       const score = scoreDot(dot, elementCache);
//       dotCache[dot].score = score;
//     });
//     newDots.forEach((dot) => {
//       // TODO: can we be smarter than just comparing to all heads again?
//       generateAffectingHeads(dot, elements);
//       const score = scoreDot(dot, elementCache);
//       dotCache[dot].score = score;
//     });
//     const item = localPaper.project.getItem({ data: { uuid: head.uuid } });
//     item.position = new paper.Point(head.x, head.y);
//     changeElements([head]);
//     updateDotOwnership([head], elementCache);
//     sprinklerStore[head.uuid].update(head);
//   }
// };

// export const headOptimizeAlgo = (
//   yardID: string,
//   elementCache: DesignElementMap,
//   elements: DesignElement[],
// ): boolean => {
//   const startingPoint = getStartingPoint(yardID);
//   if (startingPoint) {
//     console.log('startingPoint', startingPoint);
//     localPaper.project.deselectAll();
//     localPaper.project.getItem({ data: { uuid: startingPoint } }).selected =
//       true;
//     const headsToPropose = dotCache[startingPoint].heads;
//     // console.log('headsToPropose', headsToPropose)
//     const proposals = createProposals([...headsToPropose], elementCache);
//     // console.log('proposals', proposals.length)
//     const bestProposal = getBestProposal(proposals, yardID, elementCache);
//     // console.log(`implementing ${bestProposal.proposal.type}`)
//     // console.log(bestProposal.proposal)
//     // console.log('bestProposal', bestProposal)
//     // console.log(
//     //   'proposals',
//     //   proposals.length,
//     //   bestProposal.proposal.headID,
//     //   bestProposal.proposal.type,
//     //   bestProposal.proposal.val
//     // )
//     if (bestProposal.bestScoreHead) {
//       implementProposal(
//         bestProposal.bestScoreHead,
//         bestProposal.proposal,
//         elements,
//         elementCache,
//       );
//       return true;
//     } else {
//       console.log('no more proposals');
//       return false;
//     }
//   } else {
//     console.log(
//       `no more starting points to choose that are over ${algoParams.maxScore}`,
//     );
//     return false;
//   }
// };

// export const headOptions = (
//   point: IPoint,
//   sprinklerBases: SprinklerBase[],
// ): Sprinkler[] => {
//   const options: Sprinkler[] = [];
//   const nozzleData = sprinklerBases.reduce(
//     (acc: NozzleData[], base: SprinklerBase) => [
//       ...acc,
//       ...getGeneratePerfData(base),
//     ],
//     [],
//   );
//   const limits: RadiusWithAngleLimit[] = generateRadiusAngleLimits(nozzleData);
//   const radii: number[] = getRadiiFromLimits(limits, 199);
//   radii.forEach((r) => {
//     const shell: SprinklerShell = {
//       ...point,
//       angle: 360,
//       rotation: 0,
//       radius: r,
//     };
//     const s = createSprinklerFromRaw(shell, sprinklerBases);
//     if (s) {
//       options.push(s);
//     }
//   });
//   return options;
// };

// export const setupAlgorithm = (
//   elements: DesignElement[],
//   sprinklerBases: SprinklerBase[],
// ) => {
//   elements.forEach((el) => {
//     // get radius options
//     if (isSprinkler(el)) {
//       if ((el.props).angle < 360) {
//         edgeHeadCache.add(el.uuid);
//       }
//       const headChoices = headOptions(el, sprinklerBases);
//       headOptionCache[el.uuid] = headChoices;
//     }
//   });
// };

// export const doHeadAlgo = (
//   yards: Yard[],
//   activeYardIndex: number,
//   elementCache: DesignElementMap,
//   elements: DesignElement[],
//   sprinklerBases: SprinklerBase[],
// ) => {
//   setupAlgorithm(elements, sprinklerBases);
//   algoParams.decreasedCoverageCountWeight = 4;
//   for (let i = 0; i < 10; i++) {
//     console.log(`iteration ${i + 1}`);
//     const res = headOptimizeAlgo(
//       yards[activeYardIndex].uuid,
//       elementCache,
//       elements,
//     );
//     if (!res) {
//       break;
//     }
//   }
//   const yardCache = yardDotCache[yards[activeYardIndex].uuid];
//   const scores = [...yardCache.dots].map((d) => {
//     return dotCache[d].score;
//   });
//   const score =
//     scores.filter((s) => s > algoParams.minScore && s <= algoParams.maxScore)
//       .length / scores.length;
//   console.log(
//     `for ${
//       algoParams.decreasedCoverageCountWeight
//     } decreasedcoverage weight: ${(score * 100).toFixed(2)}%`,
//   );
// };
