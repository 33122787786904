import { Zone } from '@shared-types';
import { useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import styled from 'styled-components';
import { PanelButton, PanelWrap } from '../../components/PanelButton';
import { getZoneEdges } from '../../helpers/directedGraph';
import { getSelectedHeads } from '../../paper-helpers/heads';
import { getSelectedPlants } from '../../plants/plants';
import { addHeadsToZone, addPlantsToZone, useDesignStore } from '../../state';
import { deleteEdge } from '../../state/deleteEdge';
import { deleteZone } from '../../state/deleteZone';
import { ZoneDrip } from './ZoneDrip';
import { ZoneElevation } from './ZoneElevation';
import { ZonePiping } from './ZonePiping';
import { ZoneStaticLosses } from './ZoneStaticLosses';
import { ZoneValveInfo } from './ZoneValveInfo';

export const ZoneMenu = ({ zone }: { zone: Zone }) => {
  const edges = useDesignStore((state) => state.edges);
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  const [deleteZoneOpen, setDeleteZoneOpen] = useState(false);
  const [deletePipesOpen, setDeletePipesOpen] = useState(false);

  const doClearPipes = () => {
    if (zone) {
      const edgesToDelete = getZoneEdges(edges, zone, pocGraphs);
      edgesToDelete.forEach((edge) => {
        if (edge.source !== zone.valve && edge.target !== zone.valve) {
          deleteEdge(edge.uuid);
        }
      });
    }
  };

  const addSelectedHeadsToZone = (zoneID: string) => {
    const heads = getSelectedHeads();
    addHeadsToZone(
      zoneID,
      heads.map((h) => h.uuid),
    );
  };

  const addSelectedPlantsToZone = (zoneID: string) => {
    const plants = getSelectedPlants();
    addPlantsToZone(
      zoneID,
      plants.map((p) => p.uuid),
    );
  };

  return (
    <Wrap>
      <ZoneValveInfo />
      <ZoneElevation />
      <ZoneStaticLosses />
      <ZonePiping />
      <PanelButton onClick={() => setDeleteZoneOpen(true)}>
        Delete Zone
      </PanelButton>
      <PanelButton onClick={() => setDeletePipesOpen(true)}>
        Delete Zone Pipes
      </PanelButton>
      {!zone.isDrip && getSelectedHeads(zone).length > 0 && (
        <PanelButton onClick={() => addSelectedHeadsToZone(zone.uuid)}>
          Add {getSelectedHeads(zone).length} Selected Heads to Zone
        </PanelButton>
      )}
      {zone.isDrip && getSelectedPlants(zone).length > 0 && (
        <PanelButton onClick={() => addSelectedPlantsToZone(zone.uuid)}>
          Add {getSelectedPlants(zone).length} Selected Plants to Zone
        </PanelButton>
      )}
      <ZoneDrip />
      <Confirm
        size="mini"
        header="Delete Zone"
        content="This will delete the Zone"
        open={deleteZoneOpen}
        confirmButton="Delete"
        onCancel={() => setDeleteZoneOpen(false)}
        onConfirm={() => {
          deleteZone(zone.uuid);
          setDeleteZoneOpen(false);
        }}
      />
      <Confirm
        size="mini"
        header="Delete Zone Pipes"
        content="This will delete all pipes for this zone"
        open={deletePipesOpen}
        confirmButton="Delete"
        onCancel={() => setDeletePipesOpen(false)}
        onConfirm={() => {
          doClearPipes();
          setDeletePipesOpen(false);
        }}
      />
    </Wrap>
  );
};
const Wrap = styled(PanelWrap)``;
