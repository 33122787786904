import { DesignElement, Sprinkler } from '@shared-types';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';

export const mapHeadIdsToHeads = (
  elements: DesignElement[],
  headIds: string[],
): Sprinkler[] =>
  elements
    .filter(isSprinkler)
    .filter((el) => headIds.includes(el.uuid))
    .map((el) => el.props);
