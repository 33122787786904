import { DesignSprinklerElement, WaterGroup } from '@shared-types';
import styled from 'styled-components';
import {
  reduceHeadsGPMbyId,
  reducePlantsGPHbyId,
} from '../helpers/directedGraph';
import { isPlant } from '../plants/plants';
import { pocGraphByIndex, useDesignStore } from '../state';
import { isSprinkler } from '../tools/paper-items/paper-sprinkler';

interface Props {
  groups: WaterGroup[];
  headIds: string[];
  onAdd: (groupId: string, headIds: string[]) => void;
}
export const ChangeGroup = ({ groups, headIds, onAdd }: Props): JSX.Element => {
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const plants = useDesignStore((state) => state.items.filter(isPlant));
  const elementCache = useDesignStore((state) => state.elementCache);
  const changeGroup = (groupId: string) => onAdd(groupId, headIds);
  const totalGPM = (headIds: string[]) =>
    headIds
      .map((id) => elementCache[id])
      .filter(isSprinkler)
      .reduce((acc: number, h: DesignSprinklerElement) => acc + h.props.gpm, 0);
  const headInGroup = (groupID: string, headId: string) => {
    return (
      groups.length > 0 &&
      groups
        .filter((group) => group.uuid === groupID)[0]
        .headIds.includes(headId)
    );
  };
  return (
    <Wrap>
      <h5>
        Change group of {headIds.length}{' '}
        {headIds.length === 1 ? 'head' : 'heads'}
      </h5>
      <p>
        Selected {headIds.length === 1 ? 'head has' : 'heads have'} a total of{' '}
        {totalGPM(headIds).toFixed(1)} GPM
      </p>
      {groups.map((group: WaterGroup, k: number) => {
        const gpmOfGroupdItems =
          group.headIds.length > 0
            ? reduceHeadsGPMbyId(group.headIds, elementCache)
            : reducePlantsGPHbyId(plants, group.plantIds);
        const dgIndex = 0;
        const perc =
          dgIndex > -1
            ? gpmOfGroupdItems /
              pocGraphByIndex(dgIndex, pocGraphs).rootGPM(pipeProducts)
            : 0;
        return (
          <GroupButton
            onClick={changeGroup.bind(null, group.uuid)}
            disabled={
              headIds.length === 1 && headInGroup(group.uuid, headIds[0])
            }
            key={k}
            $percentage={perc}
            $color={
              headIds.length === 1 && headInGroup(group.uuid, headIds[0])
                ? 'transparent'
                : group.color
            }
          >
            <div className="details">{gpmOfGroupdItems.toFixed(1)} GPM</div>
            <div className="color">G{group.orderNumber + 1}</div>
            {perc * 100 > 100 && <div>TOO FULL</div>}
            {perc * 100 < 100 && (
              <div className="bar">
                <div className="percentage" />
              </div>
            )}
          </GroupButton>
        );
      })}
    </Wrap>
  );
};
export const GroupButton = styled.button<{
  $color: string;
  disabled: boolean;
  $percentage: number;
}>`
  font-size: 10px;
  display: inline-block;
  width: 70px;
  margin: 0 4px 8px 0;
  text-align: center;
  padding: 0;
  background: #fff;
  border: none;
  .details {
    padding: 4px;
  }
  .color {
    padding: 8px;
    background: ${(props) => (props.disabled ? '#ccc' : props.$color)};
    color: ${(props) => (props.$color === 'transparent' ? 'black' : 'white')};
    text-align: center;
    font-weight: bold;
  }
  .bar {
    position: relative;
    width: 100%;
    height: 8px;
    margin-top: 2px;
    background: #ccc;
    .percentage {
      width: ${(props) => props.$percentage * 100}%;
      height: 8px;
      position: absolute;
      top: 0;
      left: 0;
      background: ${(props) =>
        props.$percentage * 100 < 85 ? 'green' : 'red'};
    }
  }
`;
const Wrap = styled.div``;
