import paper from 'src/paper';
import { IPoint, Sheet } from '../../../../../shared-types/workbench-types';
import { applyAlpha, isSheet } from '../helpers';
import { getColor } from '../paper-helpers/plot.helpers';
import { getState } from '../state';
import { PaperItem } from '../tools/paper-items/paper-item';
import { sheetService } from './sheet.service';

export const isPaperSheet = (item: PaperItem<any>): item is PaperSheet => {
  return item instanceof PaperSheet;
};

export class PaperSheet extends PaperItem<Sheet> {
  private _group = new paper.Group();
  _item = sheetService.createItem();

  constructor(sheet: Sheet) {
    super();
    const sheetCount = getState().items.filter(isSheet).length;
    const scale = getState().scale;

    this.create(sheetCount, sheet, scale);
  }
  get id() {
    return this._item.uuid;
  }
  get highlight() {
    return this._group.getItem({ name: 'highlight' });
  }
  get group() {
    return this._group;
  }
  get paperItem() {
    return this._group.getItem({ name: 'sheet-rectangle' });
  }
  get paperBigNumber() {
    return this._group.getItem({ name: 'big-number' });
  }
  getRealItem(): Sheet {
    return this._item;
  }
  create(sheetCount: number, item: Sheet, scale: number) {
    this._item = item;
    const i = item.index;
    const rect = new paper.Rectangle(
      new paper.Point(item.position),
      new paper.Size(item.width, item.height),
    );
    const sheetText = `Sheet ${i + 1} of ${sheetCount}`;
    const sheetColor = getColor('#454545');
    const sheetFontSize = 6 / scale;
    const p = new paper.Path.Rectangle(rect);
    p.dashArray = [4 / scale, 2 / scale];
    p.strokeColor = sheetColor;
    p.strokeWidth = 1 / scale;
    p.name = 'sheet-rectangle';
    const sheetX = item.position.x;
    const sheetY = item.position.y;
    const topLeftPos = new paper.Point(
      sheetX + 1 / scale,
      sheetY + sheetFontSize,
    );
    const topLeft = new paper.PointText(topLeftPos);
    topLeft.content = sheetText;
    topLeft.fontSize = sheetFontSize;
    topLeft.fillColor = sheetColor;

    const w = topLeft.bounds.width + 1 / scale;

    const bottomLeftPos = new paper.Point(
      sheetX + 1 / scale,
      sheetY + item.height - 1 / scale,
    );
    const bottomLeft = new paper.PointText(bottomLeftPos);
    bottomLeft.content = sheetText;
    bottomLeft.fontSize = sheetFontSize;
    bottomLeft.fillColor = sheetColor;

    const bottomRightPos = new paper.Point(
      sheetX + item.width - w,
      sheetY + item.height - 1 / scale,
    );
    const bottomRight = new paper.PointText(bottomRightPos);
    bottomRight.content = sheetText;
    bottomRight.fontSize = sheetFontSize;
    bottomRight.fillColor = sheetColor;

    const topRightPos = new paper.Point(
      sheetX + item.width - w,
      sheetY + sheetFontSize,
    );
    const topRight = new paper.PointText(topRightPos);
    topRight.content = sheetText;
    topRight.fontSize = sheetFontSize;
    topRight.fillColor = sheetColor;
    const sheetNums = new paper.Group();
    sheetNums.opacity = 0.8;
    sheetNums.addChildren([topLeft, topRight, bottomRight, bottomLeft]);
    const bigNumber = new paper.PointText(
      new paper.Point(sheetX + item.width / 2, sheetY + item.height / 2),
    );
    bigNumber.content = `${i + 1}`;
    bigNumber.fontSize = item.width / 20;
    bigNumber.visible = false;
    bigNumber.locked = true;
    bigNumber.name = 'big-number';
    this._group.addChildren([p, sheetNums, bigNumber]);
    this._group.data = {
      uuid: item.uuid,
    };
    this._group.name = item.itemType;
    this._group.visible = item.visible;
    this._group.locked = item.locked;
    this.setPrintPreview(getState().showSheetPreviews);
  }
  update(item: Sheet): void {
    const setHighlight = !!this.highlight;
    this._group.removeChildren();
    const sheetCount = getState().items.filter(isSheet).length;
    const scale = getState().scale;
    this.create(sheetCount, item, scale);
    if (setHighlight) {
      this.setSelectedStatus(true);
    }
    this._item = item;
  }
  getItem() {
    return this.group;
  }
  setPosition(point: IPoint): void {
    this._group.position = new paper.Point(point);
  }
  setPivot(point: IPoint): void {
    this._group.pivot = new paper.Point(point);
  }
  setSelectedStatus(selected: boolean) {
    if (this.highlight) {
      this.highlight.remove();
    }
    if (selected) {
      const highlight = this.paperItem.clone();
      highlight.strokeWidth = 1;
      highlight.strokeColor = getColor('#94baf7');
      highlight.fillColor = null;
      highlight.dashArray = [];
      highlight.name = 'highlight';
      highlight.locked = true;
      this._group.addChild(highlight);
    }
  }
  toggleHighlight(show: boolean): void {
    this.setSelectedStatus(show);
  }
  destroy() {
    this._group.remove();
  }
  setPrintPreview(show: boolean) {
    if (show) {
      this.paperItem.fillColor = null;
      this.paperBigNumber.visible = false;
    } else {
      this.paperItem.fillColor = applyAlpha('red', 0.2);
      this.paperBigNumber.visible = true;
    }
  }
}
