import styled from 'styled-components';
import { PanelWrap } from '../components/PanelButton';

export const TextToolProperties = () => {
  return (
    <Wrap>
      <h5>Text Tool</h5>
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)``;
