import { useState } from 'react';
import styled from 'styled-components';
import { PanelLabel, PanelWrap } from '../PanelButton';
import BasemapsLayer from './BasemapsLayer';
import CoverageLayer from './CoverageLayer';
import DefaultLayer from './DefaultLayer';
import ElementsLayer from './ElementsLayer';
import HeadsLayer from './HeadsLayer';
import LegendLayer from './LegendLayer';
import PipesLayer from './PipesLayer';
import PlantsLayer from './PlantsLayer';
import SheetsLayer from './SheetsLayer';
import SleevesLayer from './SleevesLayer';
import TracingsLayer from './TracingsLayer';
import ValveBoxesLayer from './ValveBoxesLayer';
import ValveInfoBoxesLayer from './ValveInfoBoxesLayer';

export const LayerFolders = () => {
  const [openLayer, setOpenLayer] = useState('');

  const layers = [
    { name: 'foreground', Component: DefaultLayer },
    { name: 'sheets', Component: SheetsLayer },
    { name: 'legend', Component: LegendLayer },
    { name: 'infoBoxes', Component: ValveInfoBoxesLayer },
    { name: 'heads', Component: HeadsLayer },
    { name: 'elements', Component: ElementsLayer },
    { name: 'plants', Component: PlantsLayer },
    { name: 'sleeves', Component: SleevesLayer },
    { name: 'valveBoxes', Component: ValveBoxesLayer },
    { name: 'pipes', Component: PipesLayer },
    { name: 'coverage', Component: CoverageLayer },
    { name: 'tracings', Component: TracingsLayer },
    { name: 'background', Component: BasemapsLayer },
  ];
  return (
    <Wrap>
      <PanelLabel>Layers</PanelLabel>
      {layers.map(({ name, Component }) => (
        <Component
          key={name}
          open={openLayer === name}
          onOpen={(open) => setOpenLayer(open ? name : '')}
        />
      ))}
    </Wrap>
  );
};
const Wrap = styled(PanelWrap)``;
