import {
  DesignElement,
  DesignElementMap,
  IMeter,
  IPipeProduct,
  IPoC,
} from '@shared-types';
import { meterSizes } from 'src/shared';
import { IPOCDirectedGraph } from '../../../../../shared-types/pocDirectedGraph.helper';
import { pocGraphByNode } from '../state';
import { getElevationLoss, getMeterLoss } from './directedGraph';
import { calcFrictionLoss } from './geometry.helpers';

export const meterGPM = (
  meter: IMeter,
  pipeProducts: IPipeProduct[],
  poc: IPoC,
) => {
  const meterFlow = getSafeMeterFlow(meter);
  const servicePipe = pipeProducts.find(
    (pipe) =>
      pipe.series === meter.serviceLine && pipe.size === meter.serviceLineSize,
  );
  const servicePipeFlow = (servicePipe && servicePipe.safeFlow) || 10000;
  const minMainSourcePipeFlow = Math.min(
    ...poc.mainsToSource.map((p) => p.pipe.safeFlow),
  );
  return Math.min(meterFlow, servicePipeFlow, minMainSourcePipeFlow);
};

export const getMaxMeterFlow = (meter: IMeter): number => {
  let maxMeterFlow = 0;
  const meterSize = meterSizes.find((m) => m.size === meter.meterSize);
  if (meterSize && meterSize.losses) {
    maxMeterFlow = Math.max(...meterSize.losses.map((l) => l.flow));
  }
  return maxMeterFlow * (meter.gpmPercentage / 100);
};
export const getSafeMeterFlow = (meter: IMeter): number => {
  let safeFlow = 0;
  const meterSize = meterSizes.find((m) => m.size === meter.meterSize);
  if (meterSize) {
    safeFlow = meterSize.safeFlow;
  }
  return safeFlow * (meter.gpmPercentage / 100);
};

export const pocMeterLoss = (
  pocEl: DesignElement,
  pipeProducts: IPipeProduct[],
  gpm: number,
  elementMap: DesignElementMap,
): { meterLoss: number; serviceLineLoss: number; elevationLoss: number } => {
  const poc = pocEl.props as IPoC;
  let meterLoss = 0;
  let serviceLineLoss = 0;
  let elevationLoss = 0;
  if (poc.sourceID) {
    // here we find any connected meter to get related meter losses
    const source = elementMap[poc.sourceID];
    if (source && source.type === 'meter') {
      const meter = source.props as IMeter;
      // const gpm = calculatePOCGPMWithMeter(pocEl, meter, pipeProducts)
      elevationLoss = getElevationLoss(meter.serviceElevationChange);
      meterLoss = getMeterLoss(meter, gpm);
      const pipe = pipeProducts.find(
        (pipe) =>
          pipe.series === meter.serviceLine &&
          pipe.size === meter.serviceLineSize,
      );
      if (pipe) {
        serviceLineLoss = calcFrictionLoss(
          pipe.coefficient,
          pipe.insideDiameter,
          meter.serviceLineLength,
          gpm,
        );
      }
    }
  }
  return { meterLoss, serviceLineLoss, elevationLoss };
};
export const oldPOCMeterLoss = (
  pocEl: DesignElement,
  pipeProducts: IPipeProduct[],
  elementCache: DesignElementMap,
  pocGraphs: IPOCDirectedGraph[],
): { meterLoss: number; serviceLineLoss: number; elevationLoss: number } => {
  const poc = pocEl.props as IPoC;
  let meterLoss = 0;
  let serviceLineLoss = 0;
  let elevationLoss = 0;
  const dg = pocGraphByNode(pocEl.uuid, pocGraphs);
  if (poc.sourceID && dg) {
    // here we find any connected meter to get related meter losses
    const source = elementCache[poc.sourceID];
    if (source && source.type === 'meter') {
      const meter = source.props as IMeter;
      const gpm = dg.rootGPM(pipeProducts);
      elevationLoss = getElevationLoss(meter.serviceElevationChange);
      meterLoss = getMeterLoss(meter, gpm);
      const pipe = pipeProducts.find(
        (pipe) =>
          pipe.series === meter.serviceLine &&
          pipe.size === meter.serviceLineSize,
      );
      if (pipe) {
        serviceLineLoss = calcFrictionLoss(
          pipe.coefficient,
          pipe.insideDiameter,
          meter.serviceLineLength,
          gpm,
        );
      }
    }
  }
  return { meterLoss, serviceLineLoss, elevationLoss };
};
