import { WaterGroup } from '@shared-types';
import { useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import styled from 'styled-components';
import { PanelButton, PanelWrap } from '../../components/PanelButton';
import { getSelectedHeads } from '../../paper-helpers/heads';
import { addHeadsToGroup, deleteGroup } from '../../state';

export const GroupMenu = ({ group }: { group: WaterGroup }) => {
  const [deleteGroupOpen, setDeleteGroupOpen] = useState(false);

  const addSelectedHeadsToGroup = (groupID: string) => {
    const heads = getSelectedHeads();
    addHeadsToGroup(
      groupID,
      heads.map((h) => h.uuid),
    );
  };

  return (
    <Wrap>
      <PanelButton onClick={() => setDeleteGroupOpen(true)}>
        Delete Group
      </PanelButton>
      {getSelectedHeads(group).length > 0 && (
        <PanelButton onClick={() => addSelectedHeadsToGroup(group.uuid)}>
          Add {getSelectedHeads(group).length} Selected Heads to Group
        </PanelButton>
      )}
      <Confirm
        size="mini"
        header="Delete Group"
        content="This will delete the Group"
        open={deleteGroupOpen}
        confirmButton="Delete"
        onCancel={() => setDeleteGroupOpen(false)}
        onConfirm={() => {
          deleteGroup(group.uuid);
          setDeleteGroupOpen(false);
        }}
      />
    </Wrap>
  );
};
const Wrap = styled(PanelWrap)``;
