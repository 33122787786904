import { IPoint, ValveBox, Zone } from '@shared-types';
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { compact } from 'underscore';
import { getMidPoint } from '../../shared/geometry';
import { useDesignStore } from '../../state';

interface Props {
  classTitle: string;
  valveBox: ValveBox;
}

export const ValveBoxDiagram = ({ valveBox, classTitle }: Props) => {
  const diagramRef = useRef<any>();
  const padding = 60;
  const zones = useDesignStore((state) => state.zones);
  const edges = useDesignStore((state) => state.edges);
  const selectedItems = useDesignStore((state) => state.selectedItems);
  const pipeProducts = useDesignStore((state) => state.pipeProducts);

  useEffect(() => {
    if (!selectedItems.length) {
      d3.select(diagramRef.current).select('*').remove();
      const width = diagramRef.current?.clientWidth || 50;
      const height = diagramRef.current?.clientHeight || 50;
      const svg = d3.select(diagramRef.current).append('svg');
      svg.attr('width', width);
      svg.attr('height', height);
      svg.attr('class', classTitle);
      const g = svg.append('g').attr('transform', 'translate(10, 10)');
      const valveBoxZones: Zone[] = compact(
        valveBox.zoneIDs.map((uuid) => zones.find((z) => z.uuid === uuid)),
      );
      if (valveBoxZones.length) {
        const newFitting = (x: number, y: number) =>
          g
            .append('circle')
            .attr('cx', x)
            .attr('cy', y)
            .attr('r', 4)
            .attr('class', 'fitting')
            .attr('fill', 'black');
        const newPipeLabel = (point: IPoint, text: string) =>
          g
            .append('text')
            .attr('x', point.x)
            .attr('y', point.y)
            .text(text)
            .attr('font-size', 6)
            .attr('fill', '#000')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'central')
            .attr('font-family', 'Roboto');
        // .attr('font-family', 'sans-serif')

        const newPipe = (
          x1: number,
          y1: number,
          x2: number,
          y2: number,
          color?: string,
        ) =>
          g
            .append('line')
            .attr('x1', x1)
            .attr('y1', y1)
            .attr('x2', x2)
            .attr('y2', y2)
            .attr('stroke', color || '#000')
            .attr('stroke-dasharray', color ? '' : '5,2')
            .attr('stroke-width', 2);

        const addPipeWithText = (
          x1: number,
          y1: number,
          x2: number,
          y2: number,
          source: string,
          target: string,
          color?: string,
        ) => {
          const A = { x: x1, y: y1 };
          const B = { x: x2, y: y2 };
          newPipe(A.x, A.y, B.x, B.y, color);
          const edge = edges.find(
            (edge) => edge.source === source && edge.target === target,
          );
          if (edge && edge.pipe) {
            const pipe = pipeProducts.find((p) => p.uuid === edge.pipe);
            if (pipe) {
              const point = getMidPoint(A, B);
              g.append('rect')
                .attr('x', point.x - 19)
                .attr('y', point.y - 5)
                .attr('width', 38)
                .attr('height', 10)
                .attr('fill', '#ffffff')
                .attr('opacity', 0.9);
              newPipeLabel(point, `${pipe.size}" ${pipe.type}`);
            }
          }
        };

        // valve box input
        newFitting(0, padding * 2);

        // vb input to zone 1
        addPipeWithText(
          0,
          padding * 2,
          padding,
          padding * 2,
          valveBox.inputFitting,
          valveBoxZones[0].valveInputFitting,
        );

        // last zone to vb output
        addPipeWithText(
          valveBoxZones.length * padding,
          padding * 2,
          valveBoxZones.length * padding + padding,
          padding * 2,
          valveBoxZones[valveBoxZones.length - 1].valveInputFitting,
          valveBox.outputFitting,
        );

        // valve box output
        newFitting(valveBoxZones.length * padding + padding, padding * 2);

        for (let i = 0; i < valveBoxZones.length; i++) {
          const x = i * padding + padding;
          const input = { x, y: padding * 2 };
          const valve = { x, y: padding };
          const output = { x, y: 0 };

          // valve input to valve line
          addPipeWithText(
            input.x,
            input.y,
            valve.x,
            valve.y,
            valveBoxZones[i].valveInputFitting,
            valveBoxZones[i].valve,
          );
          // valve to valve output
          addPipeWithText(
            valve.x,
            valve.y,
            output.x,
            output.y,
            valveBoxZones[i].valve,
            valveBoxZones[i].valveOutputFitting,
            valveBoxZones[i].color,
          );

          // valve to next valve
          if (i < valveBoxZones.length - 1) {
            addPipeWithText(
              input.x,
              input.y,
              input.x + padding,
              input.y,
              valveBoxZones[i].valveInputFitting,
              valveBoxZones[i + 1].valveInputFitting,
            );
          }

          // valve inputs
          newFitting(input.x, input.y);

          // valve
          g.append('circle')
            .attr('cx', valve.x)
            .attr('cy', valve.y)
            .attr('r', 10)
            .attr('class', 'fitting')
            .attr('fill', '#fff')
            .attr('stroke-width', 2)
            .attr('stroke', () => {
              const zone = valveBoxZones[i];
              return zone ? zone.color : '#000';
            });
          g.append('text')
            .text(
              `${
                valveBoxZones[i].isDrip || valveBoxZones[i].plantIds.length > 1
                  ? 'D'
                  : ''
              }${valveBoxZones[i].orderNumber + 1}`,
            )
            .attr('x', valve.x)
            .attr('y', valve.y)
            .attr('font-size', 7)
            .attr('font-weight', 'bold')
            .attr('font-family', 'Roboto')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'central');

          // valve outputs
          newFitting(output.x, output.y);
        }
      }
    }
  }, [valveBox, zones, edges, selectedItems, classTitle, pipeProducts]);
  return (
    <Diagram
      ref={diagramRef}
      $valveCount={valveBox.zoneIDs.length}
      $padding={padding}
    />
  );
};

const Diagram = styled.div<{ $valveCount: number; $padding: number }>`
  width: ${(props) =>
    props.$valveCount * props.$padding + props.$padding + 20}px;
  height: ${(props) => props.$padding * 2 + 20}px;
  margin-bottom: 10px;
  background: #efefef;
  .link {
    stroke: #999;
    stroke-width: 1px;
  }
`;
