import { ProjectData, ProjectUI } from '@shared-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import 'semantic-ui-css/semantic.min.css';
import { Button, Divider, Icon, Table } from 'semantic-ui-react';
import { PortalContext } from 'src/contexts/portal/portal';
import { ProfileContext } from 'src/contexts/profile/profile';
import {
  getProject,
  newProject,
  updateProject,
} from 'src/services/project.service';
import { base } from 'src/styles/common';
import styled from 'styled-components';
import { CompanyInfo } from './CompanyInfo';
import { EquipmentStep } from './EquipmentStep';
import { FinalizeStep } from './FinalizeStep';
import { PointOfConnection } from './PointOfConnection';
import { SiteInfo } from './SiteInfo';
import { baseProjectData, projectType } from './helpers';

// const isValidEmail = (emailAddress: string) => {
//   const pattern = new RegExp(
//     /^(("[\w-\s]+")|([+\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
//   )
//   return pattern.test(emailAddress)
// }

// const innerFunction = debounce((email: string, fn: Function) => {
//   ;(async () => {
//     if (email && isValidEmail(email)) {
//       const remaining = await getRemainingProjects(email)
//       fn(remaining.count)
//     }
//   })()
// }, 1000)

export const getProjectEstimate = (
  form: ProjectData,
  remainingProjects?: number,
): string => {
  const pType = projectType.find((p) => p.id === form.size);
  if (form.size === 'Custom') {
    return 'Custom Quote Needed';
  }

  let basePrice = pType && pType.id === 'Comm-Small' ? 575 : 445;
  const extraAcres = Math.ceil((form.acres || 1) - 1);
  basePrice +=
    pType && pType.id === 'Comm-Small' ? extraAcres * 285 : extraAcres * 170;
  if (form.rush && basePrice !== -1) {
    basePrice = basePrice * 2;
  }
  return basePrice === -1 || form.rush
    ? 'Custom Quote Needed'
    : remainingProjects && remainingProjects > 0
      ? 'Project credit used!'
      : `${basePrice.toFixed(2)}`;
};
interface ProjectRequestFormProps {
  project?: ProjectUI;
  onSave?: () => void;
  isEdit?: boolean;
}
export const ProjectRequestForm = (props: ProjectRequestFormProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(ProfileContext);
  const [saving, setSaving] = useState(false);
  const [bulkCode, setBulkCode] = useState(
    props.project ? props.project.codeId : '',
  );
  const {
    catalog,
    loadingCatalog,
    loadLastProject,
    lastProject,
    pushNotification,
  } = useContext(PortalContext);
  const [form, setForm] = useState<ProjectData>(
    props.project ? props.project.data : baseProjectData,
  );
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [remainingProjects] = useState(0);
  const [steps, setSteps] = useState([
    { title: 'Company', complete: false },
    { title: 'Site', complete: false },
    { title: 'POC(s)', complete: false },
    { title: 'Equipment', complete: false },
    { title: 'Finish', complete: false },
  ]);

  const parseProject = async (projectId: number): Promise<void> => {
    try {
      const { project } = await getProject(projectId);
      setBulkCode(project.codeId);
      setForm(project.data);
      checkSteps(project.data, steps);
    } catch (err) {
      console.log('no active project');
    }
  };

  useEffect(
    () => {
      if (id) {
        parseProject(parseInt(id, 10));
      } else if (!!props.project) {
        setForm(props.project.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.project, id],
  );

  useEffect(() => {
    setSaveEnabled(steps.filter((s) => s.complete).length === 5);
  }, [steps]);

  const checkCompanyStep = (form: ProjectData): boolean =>
    !!(form.companyName && form.companyAddress);

  const checkSiteStep = (form: ProjectData): boolean =>
    !!(form.siteAddress && form.contractorFiles.length);

  const checkPocStep = (form: ProjectData): boolean =>
    !!form.pocs[0].gpm && !!form.pocs[0].dynamicPSI;

  const checkEquipmentStep = (form: ProjectData): boolean =>
    !!form.headsNozzles;

  const checkFinalizeStep = (form: ProjectData): boolean => !!form.title;

  const checkSteps = (form: ProjectData, steps: any[]) => {
    console.log('checking steps');
    const newSteps = steps.map((s) => {
      switch (s.title) {
        case 'Company':
          return { ...s, complete: checkCompanyStep(form) };
        case 'Site':
          return { ...s, complete: checkSiteStep(form) };
        case 'POC(s)':
          return { ...s, complete: checkPocStep(form) };
        case 'Equipment':
          return { ...s, complete: checkEquipmentStep(form) };
        case 'Finish':
          return { ...s, complete: checkFinalizeStep(form) };
        default:
          return s;
      }
    });
    setSteps(newSteps);
  };

  useEffect(
    () => {
      if (!!lastProject) {
        setForm({
          ...form,
          logos: [],
          // TODO: inject any properties from old project needed
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastProject],
  );

  useEffect(() => {
    loadLastProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      let newForm = form;
      if (user && !props.project) {
        newForm = {
          ...newForm,
          firstName: user.firstName,
          lastName: user.lastName,
          companyAddress: user.address,
        };
      }
      checkSteps(newForm, steps);
      setForm(newForm);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loadingCatalog, catalog],
  );

  const submitProject = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      if (id) {
        await updateProject(parseInt(id), form, bulkCode);
        if (props.onSave) {
          props.onSave();
        }
        pushNotification({
          message: 'Project Successfully Updated',
          type: 'success',
          lengthMs: 3000,
        });
        navigate(`/app/projects/${id}`);
      } else {
        await newProject(form);
        pushNotification({
          message: 'Project Successfully Created',
          type: 'success',
          lengthMs: 3000,
        });
        navigate('/app/dashboard');
      }
      setSaving(false);
    } catch (err) {
      setSaving(false);
      console.log('submit project error: ', err);
    }
  };

  const updateFormData = (form: ProjectData, newSteps: any[]) => {
    checkSteps(form, newSteps);
    setForm(form);
  };

  return (
    <Wrap>
      <div className="project-title">
        <span className="input-label">Project Details</span>
      </div>
      <br />
      <CompanyInfo
        complete={steps[0].complete}
        projectData={form}
        onUpdate={(form) => updateFormData(form, steps)}
        remainingProjects={remainingProjects}
      />
      <br />
      <PointOfConnection
        complete={steps[2].complete}
        projectData={form}
        onUpdate={(form) => updateFormData(form, steps)}
      />
      <br />
      <EquipmentStep
        complete={steps[3].complete}
        projectData={form}
        onUpdate={(form) => updateFormData(form, steps)}
      />
      <br />
      <SiteInfo
        complete={steps[1].complete}
        projectData={form}
        onUpdate={(form) => updateFormData(form, steps)}
      />
      <br />
      <FinalizeStep
        projectData={form}
        complete={steps[4].complete}
        remainingProjects={remainingProjects}
        onUpdate={(form) => updateFormData(form, steps)}
      />
      <Divider />
      <div className="save-actions">
        <Button
          color="green"
          onClick={submitProject}
          loading={saving}
          disabled={!saveEnabled || saving}
          data-testid={`save-button`}
        >
          Save Project
        </Button>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Step</Table.HeaderCell>
              <Table.HeaderCell>Complete</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {steps.map((s) => (
              <Table.Row key={s.title} error={!s.complete}>
                <Table.Cell>{s.title}</Table.Cell>
                <Table.Cell>
                  {s.complete ? (
                    <Icon name="check" color="green" />
                  ) : (
                    <Icon name="x" color="red" />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <br />
      <br />
      <p className="contact-footer">
        Call us at (719) 210-7356 or email us at team@irrigationstudio.com
      </p>
    </Wrap>
  );
};
const Wrap = styled.div`
  padding: ${base * 2}px;
  max-width: 630px;
  margin: 0 auto;

  .save-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }

  .contact-footer {
    text-align: center;
  }

  .request-header {
    margin: 20px auto !important;
    text-align: center;
  }
  .request-form {
    margin: 0 auto;

    section {
      border: 1px solid #ccc;
      padding: ${base * 2}px;
      margin-bottom: ${base * 3}px;
    }
  }
  .poc-section {
    background: #efefef;
    padding: ${base}px;
  }
  .poc-mains {
    display: flex;
    flex-wrap: wrap;
  }
  .thank-you {
    padding: ${base * 3}px;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: ${base * 3}px;
    i {
      margin-bottom: ${base * 2}px;
    }
  }
  /* semantic override */
  .ui.form .field > label {
    font-weight: normal;
  }

  .project-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    .input-label {
      font-weight: bold;
    }
  }

  .editable {
    border: 1px dashed #999;
    cursor: pointer;

    &.inline {
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }
    &.complete {
      border-style: solid;
      border-color: #efefef;
    }
    &:hover {
      border-color: green;
    }
  }
  .project-contractor-logo {
    margin-top: ${base * 3}px;
    .project-logo {
      margin-bottom: ${base * 2}px;
      color: green;
      padding: ${base}px;
    }
    .project-contractor {
      padding: ${base}px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      .email {
        margin-top: ${base}px;
      }
      .contractor {
        margin-bottom: ${base}px;
      }
    }
  }

  .project-basemap-site {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .project-files {
      flex: 1;
      padding: ${base}px;
      border-radius: 4px;
      border: 1px dashed #999;
      width: 90px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border-color: green;
      }
    }
    .project-site {
      display: inline-block;
      cursor: pointer;
      position: relative;
      padding: ${base}px;
      border-radius: 4px;
      border: 1px dashed #999;
      margin-right: ${base * 2}px;
      &:hover {
        border-color: green;
      }
    }
  }
  .poc-area {
    .add-poc {
      border-bottom: 1px dashed #999;
      display: inline-block;
      cursor: pointer;
    }
    .pocs {
      display: flex;
      margin-bottom: ${base * 2}px;

      .poc {
        border: 1px dashed #999;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: ${base * 2}px;
        flex: 0 0 160px;
      }
      .poc-tag {
        /* padding: ${base / 4}px ${base}px; */
        font-size: 12px;
        /* border: 1px solid #ccc; */
        /* border-radius: 4px; */
        display: inline-block;
        margin: 0 ${base / 2}px;
      }
    }
  }
  .wrapping-steps {
    @media (max-width: 600px) {
      overflow: hidden;
      max-width: 90vw;
    }
  }
  .ui.steps .step {
    @media (max-width: 600px) {
      padding-left: 1.3em;
      padding-right: 1em;
    }
  }
  .ui.steps .step .title {
    @media (max-width: 600px) {
      font-weight: normal;
    }
  }
  .ui.steps .step > .icon {
    line-height: 1;
    font-size: 1.5em;
    margin: 0 1rem 0 0;

    @media (max-width: 600px) {
      display: none;
      font-size: 1.25em;
    }
  }
  .step-num {
    font-weight: bold;
    font-size: 16px;
    margin-right: 8px;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .ui.card .custom-header {
    background-color: #f9fafb;
    border-bottom: none;
    &.complete {
      background-color: #c9e0d0;
    }
  }
`;
export const SectionHeader = styled.div`
  .ui.content {
    background-color: #f9fafb;
    border-bottom: none;
  }
  margin-bottom: ${base}px;
`;
export const SectionBody = styled.div`
  .wrapper {
    padding: ${base * 2}px;
    .ui.form .field > label {
      font-weight: bold;
    }
  }
`;
