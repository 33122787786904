import {
  DesignElement,
  IMainToSource,
  IMeter,
  IPoC,
  IPump,
} from '@shared-types';
import { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  DropdownItemProps,
  DropdownProps,
  Form,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { uniq } from 'underscore';
import { localPaper } from '../localPaper';
import { selectItems, useDesignStore } from '../state';
import { changeElements } from '../state/changeElements';
import {
  PanelButton,
  PanelInputWrap,
  PanelSelect,
  PanelWrap,
} from './PanelButton';

interface Props {
  element: DesignElement;
}

export const POCTooltip = ({ element }: Props) => {
  const mainPipe = useDesignStore((state) => state.mainPipe);
  const mainPipeSizes = useDesignStore((state) => state.mainPipeSizes);
  const elements = useDesignStore((state) => state.elements);
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const [poc, setPOC] = useState<IPoC>(element.props as IPoC);
  const [shouldOverridePSI, setShouldOverridePSI] = useState<boolean>(
    !!(element.props as IPoC).measuredPSI,
  );
  const [shouldOverrideGPM, setShouldOverrideGPM] = useState<boolean>(
    !!(element.props as IPoC).measuredGPM,
  );

  useEffect(
    () => {
      if (!shouldOverridePSI) {
        setPOC({
          ...poc,
          measuredPSI: undefined,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldOverridePSI],
  );

  useEffect(
    () => {
      if (!shouldOverrideGPM) {
        setPOC({
          ...poc,
          measuredGPM: undefined,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldOverrideGPM],
  );

  const handleSave = (): void => {
    changeElements([
      {
        ...element,
        props: poc,
      },
    ]);
    selectItems([]);
    localPaper.project.deselectAll();
  };

  const cancelSave = () => {
    setPOC(element.props as IPoC);
    selectItems([]);
    localPaper.project.deselectAll();
  };

  const changeStaticPSI = (e: React.ChangeEvent<HTMLInputElement>) => {
    const psi = e.currentTarget.value
      ? parseFloat(e.currentTarget.value)
      : undefined;
    setPOC({
      ...poc,
      staticPSI: psi,
    });
  };
  const changeOverridePSI = (e: React.ChangeEvent<HTMLInputElement>) => {
    const psi = parseFloat(e.currentTarget.value);
    setPOC({
      ...poc,
      measuredPSI: psi,
    });
  };
  const changeGPM = (e: React.ChangeEvent<HTMLInputElement>) => {
    const gpm = parseFloat(e.currentTarget.value);
    setPOC({
      ...poc,
      measuredGPM: gpm,
    });
  };

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPOC({
      ...poc,
      name: e.currentTarget.value,
    });
  };

  const changeElevation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const elevation = parseInt(e.currentTarget.value, 10);
    setPOC({
      ...poc,
      elevation,
    });
  };

  const toggleShouldOverridePSI = () => {
    setShouldOverridePSI(!shouldOverridePSI);
  };

  const toggleShouldOverrideGPM = () => {
    setShouldOverrideGPM(!shouldOverrideGPM);
  };

  const doSourceChange = (_, data: DropdownProps) => {
    setPOC({
      ...poc,
      sourceID: data.value as string,
    });
  };

  const addPOCMain = () => {
    const minMainSize = Math.min(...mainPipeSizes);

    const pipe = pipeProducts.find(
      (p) => p.series === mainPipe && p.size === minMainSize,
    );
    if (pipe) {
      setPOC({
        ...poc,
        mainsToSource: [...poc.mainsToSource, { pipe, distance: 10 }],
      });
    }
  };

  const deleteMain = (i: number) => {
    setPOC({
      ...poc,
      mainsToSource: poc.mainsToSource.filter((_, j) => j !== i),
    });
  };

  const doMainChange = (i: number, _, data: DropdownProps) => {
    const currentPipe = poc.mainsToSource[i];
    const newPipe = pipeProducts.find((p) => p.series === data.value);
    if (currentPipe && newPipe) {
      setPOC({
        ...poc,
        mainsToSource: poc.mainsToSource.map((m, j) => ({
          ...m,
          pipe: j === i ? newPipe : m.pipe,
        })),
      });
    }
  };
  const doMainSizeChange = (i: number, _, data: DropdownProps) => {
    const currentPipe = poc.mainsToSource[i];
    const newPipe = pipeProducts.find(
      (p) => p.series === currentPipe.pipe.series && p.size === data.value,
    );
    if (currentPipe && newPipe) {
      setPOC({
        ...poc,
        mainsToSource: poc.mainsToSource.map((m, j) => ({
          ...m,
          pipe: j === i ? newPipe : m.pipe,
        })),
      });
    }
  };
  const doDistanceChange = (
    i: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      e.currentTarget.value &&
      !isNaN(parseFloat(e.currentTarget.value as any))
    ) {
      setPOC({
        ...poc,
        mainsToSource: poc.mainsToSource.map((m, j) => ({
          ...m,
          distance: j === i ? parseFloat(e.currentTarget.value) : m.distance,
        })),
      });
    }
  };

  return (
    <Wrap>
      <h5>POC Details</h5>
      <PanelInputWrap
        label={'Name'}
        onChange={changeName}
        value={poc.name || 'Meter'}
      />
      <PanelInputWrap
        label={'Elevation'}
        type="number"
        onChange={changeElevation}
        value={poc.elevation || '0'}
      />
      <PanelInputWrap
        label={'Static PSI'}
        type="number"
        min="0"
        onChange={changeStaticPSI}
        value={poc.staticPSI !== undefined ? poc.staticPSI : ''}
      />
      <br />
      <Checkbox
        checked={shouldOverridePSI}
        onChange={toggleShouldOverridePSI}
        label="Override PSI"
      />
      <PanelInputWrap
        label={'Override PSI'}
        type="number"
        disabled={!shouldOverridePSI}
        min="0"
        onChange={changeOverridePSI}
        value={poc.measuredPSI || ''}
      />
      <br />
      <Checkbox
        checked={shouldOverrideGPM}
        onChange={toggleShouldOverrideGPM}
        label="Override GPM"
      />
      <PanelInputWrap
        label={'Override GPM'}
        type="number"
        disabled={!shouldOverrideGPM}
        min="0"
        value={poc.measuredGPM}
        onChange={changeGPM}
      />
      <PanelSelect
        label="Source"
        value={poc.sourceID || ''}
        onChange={doSourceChange}
        options={[
          { text: 'No source', value: '' },
          ...elements
            .filter((el) => el.type === 'meter' || el.type === 'pump')
            .map((el) => ({
              text: (el.props as IMeter | IPump).name,
              value: el.uuid,
            })),
        ]}
      />
      <Form>
        {poc.mainsToSource.map((m: IMainToSource, i: number) => (
          <div className="main" key={i}>
            <h5>Main to Source {i + 1}</h5>
            <PanelSelect
              label="Main Pipe"
              value={m.pipe.series}
              onChange={doMainChange.bind(null, i)}
              options={uniq(pipeProducts.map((p) => p.series)).map(
                (pipeName: string): DropdownItemProps => ({
                  text: pipeName,
                  value: pipeName,
                }),
              )}
            />
            {!!m.pipe && (
              <PanelSelect
                label="Main Pipe Size"
                value={m.pipe.size}
                onChange={doMainSizeChange.bind(null, i)}
                options={uniq(
                  pipeProducts
                    .filter((p) => p.series === mainPipe)
                    .map((p) => p.size),
                ).map(
                  (pipeName: number): DropdownItemProps => ({
                    text: pipeName,
                    value: pipeName,
                  }),
                )}
              />
            )}
            <PanelInputWrap
              label="Distance (ft)"
              value={m.distance}
              onChange={doDistanceChange.bind(null, i)}
            />
            <br />
            <PanelButton type="button" onClick={() => deleteMain(i)}>
              Delete Main to Source {i + 1}
            </PanelButton>
          </div>
        ))}
        <PanelButton
          type="button"
          onClick={() => addPOCMain()}
          disabled={!poc.sourceID}
        >
          Add Main to Source
        </PanelButton>

        <Button onClick={cancelSave}>Cancel</Button>
        <Button primary onClick={handleSave}>
          Save
        </Button>
      </Form>
    </Wrap>
  );
};
const Wrap = styled(PanelWrap)`
  .main {
    margin-top: 16px;
    padding: 16px;
  }
`;
