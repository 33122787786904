import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { irrigationBlue } from '../../../../vars';
import { LockButton, VisibilityButton } from './ButtonWraps';

interface Props {
  open: boolean;
  onOpen: (open: boolean) => void;
  visible: boolean;
  locked: boolean;
  onToggleLayerVisibility: () => void;
  onToggleLock: () => void;
  layerTitle: string;
  childLayer?: React.ReactNode;
  actions?: React.ReactNode;
  scrollChildren?: boolean;
}

export const StandardLayer = ({
  open = false,
  onOpen,
  visible = true,
  locked = false,
  onToggleLayerVisibility,
  onToggleLock,
  layerTitle,
  childLayer: childLayers,
  actions,
  scrollChildren = true,
}: Props) => {
  const openable = !!childLayers || !!actions;
  const clickOpen = () => {
    if (childLayers || actions) {
      onOpen(!open);
    }
  };
  return (
    <LayerWrap
      $open={open}
      $openable={openable}
      $locked={locked}
      $visible={visible}
      $scrollChildren={scrollChildren}
    >
      <div className="title-bar">
        <VisibilityButton
          disabled={false}
          visible={visible}
          onClick={onToggleLayerVisibility}
        />
        <LockButton disabled={false} locked={locked} onClick={onToggleLock} />
        <div className="name" onClick={clickOpen}>
          <span>{layerTitle}</span>
          {openable && (
            <div>
              <Icon name={open ? 'caret down' : 'caret right'} />
            </div>
          )}
        </div>
      </div>
      {open && (
        <div className="expanded-children">
          {!!childLayers && <div className="content">{childLayers}</div>}
          {!!actions && <div className="actions">{actions}</div>}
        </div>
      )}
    </LayerWrap>
  );
};

const darkGrey = '#444';
const hoverDarker = '#333';
const LayerWrap = styled.div<{
  $open: boolean;
  $openable: boolean;
  $locked: boolean;
  $visible: boolean;
  $scrollChildren: boolean;
}>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${darkGrey};
  border-left: ${(props) =>
    props.$open ? `3px solid ${hoverDarker}` : `3px solid ${darkGrey}`};
  margin-bottom: 4px;
  .title-bar {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: row;
    background: ${darkGrey};
    button {
      background: ${darkGrey};
      &:hover {
        background: ${hoverDarker};
      }
    }
    .name {
      user-select: none;
      padding-left: 8px;
      display: flex;
      ${(props) => (props.$openable ? 'cursor: pointer;' : '')}
      opacity: ${(props) => (props.$visible ? 1 : 0.5)};
      flex: 1;
      text-decoration: ${(props) => (props.$locked ? 'line-through' : '')};
      &:hover {
        ${(props) => (props.$openable ? `color: ${irrigationBlue};` : '')}
      }
    }
  }

  .layer-name {
    user-select: none;
  }
  .expanded-children {
  }
  .actions {
    display: flex;
    padding: 8px;
    > * {
      margin-right: 8px;
    }
  }
  .content {
    max-height: 200px;
    ${(props) => (props.$scrollChildren ? 'overflow-y: auto;' : '')}
  }
`;
