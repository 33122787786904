import { useContext } from 'react';
import { ProfileContext } from 'src/contexts/profile/profile';
import { AdminDashboard } from './AdminDashboard/AdminDashboard';
import { BasemapDashboard } from './BasemapDashboard/BasemapDashboard';
import { ContractorDashboard } from './ContractorDashboard/ContractorDashboard';
import { DesignerDashboard } from './DesignerDashboard/DesignerDashboard';

export const Dashboard = () => {
  const { user } = useContext(ProfileContext);
  return (
    <>
      {user?.role === 'admin' && <AdminDashboard />}
      {user?.role === 'readonly' && <AdminDashboard />}
      {user?.role === 'contractor' && <ContractorDashboard />}
      {user?.role === 'basemapper' && <BasemapDashboard />}
      {user?.role === 'designer' && <DesignerDashboard />}
    </>
  );
};
