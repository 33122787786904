import {
  DesignElement,
  IEdge,
  IMiscItem,
  IPoint,
  ValveBox,
} from '@shared-types';
import { addUndo } from '../../contexts/design/undo';
import { newUUID } from '../../crypto-uuid';
import { defaultFitting, defaultItem } from '../../helpers';
import { renderEdges } from '../../paper-helpers/edges';
import { renderElements } from '../../paper-helpers/plot.helpers';
import {
  createEdgesBetweenValveSlots,
  getFittingPosition,
} from '../../paper-helpers/valves';
import { getState, setState } from '../state-basics';
import { finalizeSteps } from '../zone.state';
import { deleteValveBox } from './deleteValveBox';

export const createValveBox = (valveBox: Partial<ValveBox>): ValveBox => {
  return {
    ...defaultItem(valveBox.position || { x: 0, y: 0 }),
    uuid: newUUID(),
    rotation: 0,
    textRotation: 0,
    left: false,
    name: '',
    maxSlots: 0,
    zoneIDs: [],
    inputFitting: '',
    outputFitting: '',
    itemType: 'valveBox',
    ...valveBox,
  };
};

export const addValveBoxLocation = (
  vbUUID: string,
  size: number,
  point: IPoint,
  rotation: number,
  undoable = true,
) => {
  const { zones, mainPipe, pipeProducts, scale, elements, edges, valveBoxes } =
    getState();
  if (undoable) {
    addUndo(() => {
      deleteValveBox(vbUUID, false);
    }, 'Add Valve Box');
  }
  const newValveBox = createValveBox({
    ...defaultItem(point),
    uuid: vbUUID,
    // point,
    rotation,
    maxSlots: size,
    zoneIDs: [...new Array(size)].map(() => ''),
  });
  // generate the fittings for the valve box
  const inputPosition = getFittingPosition(0, newValveBox, scale);
  const inputFitting: DesignElement = {
    ...defaultFitting({ x: inputPosition.x, y: inputPosition.y }),
    props: {
      name: 'Fitting',
      displayName: 'input',
    } as IMiscItem,
  };
  const outputPosition = getFittingPosition(size + 1, newValveBox, scale);
  const outputFitting: DesignElement = {
    ...defaultFitting({ x: outputPosition.x, y: outputPosition.y }),
    props: {
      name: 'Fitting',
      displayName: 'output',
    } as IMiscItem,
  };
  newValveBox.outputFitting = outputFitting.uuid;
  newValveBox.inputFitting = inputFitting.uuid;

  const slotEdges: IEdge[] = createEdgesBetweenValveSlots(
    newValveBox,
    zones,
    mainPipe,
    pipeProducts,
  );
  const newElements = [...elements, inputFitting, outputFitting];
  const newEdges = [...edges, ...slotEdges];
  const { masterGraph, pocGraphs, elementCache, finalizedEdges } =
    finalizeSteps(newElements, newEdges, zones, scale);
  const updatedValveBoxes = [...valveBoxes, newValveBox];
  setState({
    valveBoxes: updatedValveBoxes,
    elements: newElements,
    edges: finalizedEdges,
    elementCache,
    masterGraph,
    pocGraphs,
  });
  renderElements([inputFitting, outputFitting]);
  renderEdges(
    newEdges.filter((edge) => slotEdges.find((e) => e.uuid === edge.uuid)),
  );
};
