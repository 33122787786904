import { useCallback, useEffect, useState } from 'react';
import {
  findOrCreateLayer,
  hideNamedLayer,
  showNamedLayer,
} from '../../paper-helpers/plot.helpers';
import { setLockedLayersFromPaperState } from '../../shared/layers';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { useDesignStore } from '../../state';

export const useLayerState = (layerName: LAYER_NAMES) => {
  const visibleLayers = useDesignStore((state) => state.visibleLayers);

  const [layerVisible, setVisible] = useState(
    findOrCreateLayer(layerName)?.visible || false,
  );

  const [layerLocked, setLocked] = useState(
    findOrCreateLayer(layerName)?.locked || false,
  );

  useEffect(() => {
    setVisible(visibleLayers.includes(layerName));
  }, [layerName, visibleLayers]);

  const toggleLayer = useCallback(() => {
    if (layerVisible) {
      hideNamedLayer(layerName);
    } else {
      showNamedLayer(layerName);
    }
    setVisible(!layerVisible);
  }, [layerVisible, layerName]);

  const toggleLock = useCallback(() => {
    const layer = findOrCreateLayer(layerName);
    layer.locked = !layer.locked;
    setLocked(layer.locked);
    setLockedLayersFromPaperState();
  }, [layerName]);

  return { layerVisible, layerLocked, toggleLayer, toggleLock };
};
