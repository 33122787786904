import paper from 'src/paper';
import { IPoint, TextChunk } from '../../../../../shared-types/workbench-types';
import { getColor } from '../paper-helpers/plot.helpers';
import { getState } from '../state';
import { PaperItem } from '../tools/paper-items/paper-item';
import { formatText } from './text.helpers';
import { textService } from './text.service';

export const isPaperText = (item: PaperItem<any>): item is PaperText => {
  return item instanceof PaperText;
};
export class PaperText implements PaperItem<TextChunk> {
  private _group = new paper.Group();
  _item = textService.createItem();

  get highlight() {
    return this._group.getItem({ name: 'highlight' });
  }
  get group() {
    return this._group;
  }
  get paperItem(): paper.PointText {
    return this._group.getItem({ name: 'child-text' }) as paper.PointText;
  }

  constructor(item: TextChunk) {
    this.group.name = item.itemType;
    this.group.data = { uuid: item.uuid };
    this.create(item);
  }
  getRealItem(): TextChunk {
    return this._item;
  }
  create(item: TextChunk) {
    this.group.removeChildren();
    const r = this.createTextItem(item);
    this.group.addChild(r);
    this._item = item;
  }
  createTextItem(item: TextChunk) {
    const text = new paper.PointText(item.position);
    text.content = formatText(item.content, item.width);
    text.fontSize = item.fontSize / getState().scale;
    text.justification = 'left';
    text.name = 'child-text';
    return text;
  }
  update(item: TextChunk) {
    const selected = !!this.highlight;
    if (
      this._item.content !== item.content ||
      this._item.fontSize !== item.fontSize ||
      this._item.width !== item.width
    ) {
      this.create(item);
    }
    this._item = item;
    this.toggleHighlight(selected);
  }
  setSelectedStatus(selected: boolean) {
    if (this.highlight) {
      this.highlight.remove();
    }
    if (selected) {
      const rect = new paper.Rectangle(
        this._item.position.x,
        this.paperItem.bounds.topLeft.y,
        this.paperItem.bounds.width,
        this.paperItem.bounds.height,
      );
      const highlight = new paper.Path.Rectangle(rect);
      highlight.strokeWidth = 0.2;
      highlight.strokeColor = getColor('#94baf7');
      highlight.fillColor = null;
      highlight.name = 'highlight';
      highlight.locked = true;
      this.group.addChild(highlight);
    }
  }
  destroy() {
    this.group.remove();
  }
  getItem() {
    return this.group;
  }
  setPosition(point: IPoint): void {
    this.group.position = new paper.Point(point);
  }
  setPivot(point: IPoint): void {
    this.group.pivot = new paper.Point(point);
  }
  // getRotation(): number {
  //   return 0;
  // }
  // scaleItem(pivot: IPoint, scale: number): void {
  //   this.group.scale(scale, pivot);
  // }
  toggleHighlight(show: boolean): void {
    this.setSelectedStatus(show);
  }
}
