import { ProjectData } from '@shared-types';
import { SyntheticEvent } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  Card,
  DropdownProps,
  Form,
  Header,
  Icon,
  Input,
  InputOnChangeData,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { SectionBody, getProjectEstimate } from './ProjectRequestForm';
import { projectType } from './helpers';

interface FinalizeStepProps {
  projectData: ProjectData;
  remainingProjects: number;
  complete: boolean;
  onUpdate: (form: ProjectData) => void;
}
export const FinalizeStep = ({
  projectData,
  onUpdate,
  remainingProjects,
  complete,
}: FinalizeStepProps) => {
  const handleChange = (
    _e: SyntheticEvent<HTMLElement, Event>,
    f: InputOnChangeData,
  ) => {
    if (f.name) {
      onUpdate({ ...projectData, [f.name]: f.value });
    }
  };
  const handleSelectChange = (
    _e: SyntheticEvent<HTMLElement, Event>,
    f: DropdownProps,
  ) => {
    if (f.name) {
      onUpdate({ ...projectData, [f.name]: f.value });
    }
  };
  const handleAcreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      ...projectData,
      acres: parseFloat(e.currentTarget.value),
    });
  };
  return (
    <Wrap>
      <Card fluid>
        <Card.Content className={`custom-header ${complete ? 'complete' : ''}`}>
          <Card.Header>
            Finalize&nbsp;&nbsp;
            <Icon
              name="check circle"
              color={complete ? 'green' : 'grey'}
              size="small"
            />
          </Card.Header>
        </Card.Content>
        <SectionBody>
          <div className="wrapper">
            <Form>
              <Form.Input
                required={true}
                name="title"
                autoComplete="off"
                label="Project Title"
                placeholder="Project title"
                value={projectData.title}
                onChange={handleChange}
              />
            </Form>
            <br />
            <br />
            <Form widths={'equal'}>
              <Form.Group widths={'equal'}>
                <Form.Field
                  control={Input}
                  label={'Project Number'}
                  required={false}
                  name="projectNumber"
                  autoComplete="off"
                  value={projectData.projectNumber}
                  onChange={handleChange}
                />
                <Form.Field
                  control={Input}
                  label={'PO Number'}
                  required={false}
                  name="poNumber"
                  autoComplete="off"
                  value={projectData.poNumber}
                  onChange={handleChange}
                />
              </Form.Group>
              <br />
              <section>
                <Header as="h3">Price Estimate</Header>
                <Form.Select
                  label="Size"
                  name="size"
                  value={projectData.size}
                  options={projectType.map((s) => ({
                    text: s.text,
                    value: s.id,
                  }))}
                  onChange={handleSelectChange}
                />
                <label style={{ fontWeight: 'bold' }}>Acres</label>
                <NumericFormat
                  name="acres"
                  value={projectData.acres}
                  onChange={handleAcreChange}
                />
                <Header as="h4">
                  Estimate: {getProjectEstimate(projectData, remainingProjects)}
                </Header>
              </section>
            </Form>
          </div>
        </SectionBody>
      </Card>
    </Wrap>
  );
};

const Wrap = styled.div``;
