import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import styled from 'styled-components';

export const tab$ = new Subject<string>();

export function CustomComponent({ children }) {
  return <TabPanel>{children}</TabPanel>;
}

export const TabSystem = ({ tabs, initialTab }) => {
  const [activeTab, setActiveTab] = useState(initialTab || null);

  useEffect(() => {
    const sub = tab$.subscribe((tab) => {
      setActiveTab(tab);
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabClick = (tabName) => {
    if (activeTab === tabName) {
      setActiveTab(null);
    } else {
      if (activeTab !== null) {
        setActiveTab(null);
        setTimeout(() => setActiveTab(tabName), 0);
      } else {
        setActiveTab(tabName);
      }
    }
  };

  return (
    <TabSystemWrapper
      style={{ flexGrow: activeTab ? 1 : 0, minHeight: activeTab ? 220 : 0 }}
    >
      <TabList>
        {tabs.map((tab, i) => (
          <Tab
            key={i}
            role="tab"
            aria-selected={activeTab === tab.name}
            onClick={() => handleTabClick(tab.name)}
          >
            {tab.name}
          </Tab>
        ))}
      </TabList>
      {activeTab && (
        <TabPanelWrap>
          <CustomComponent>
            {tabs.find((tab) => tab.name === activeTab).content}
          </CustomComponent>
        </TabPanelWrap>
      )}
    </TabSystemWrapper>
  );
};

const TabPanel = styled.div`
  color: rgba(255, 255, 255, 0.8);
`;

export const TabList = styled.div`
  background-color: #3e3e3e;
  display: flex;
`;

export const Tab = styled.div`
  padding: 4px 8px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.25);
  border-right: 1px solid #2e2e2e;
  user-select: none;
  font-size: 14px;
  &:hover {
    background: #4e4e4e;
  }
  ${(props) =>
    props['aria-selected'] &&
    `
    background-color: #5e5e5e;
    color: #fff;
  `}
`;

export const TabPanelWrap = styled.div`
  overflow: auto;
  background-color: #5e5e5e;
  flex: 1;
  min-height: 220px;
`;

export const TabSystemWrapper = styled.div`
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
`;
