import { ISleeve } from '@shared-types';
import { getState, setState } from '../../../state';
import { updatePaperItem } from '../../../state/renderers';

export const changeSleeve = (payload: ISleeve) => {
  const updatedItems = getState().items.map((item) =>
    item.uuid === payload.uuid ? payload : item,
  );
  setState({
    items: updatedItems,
  });
  updatePaperItem(payload);
};
