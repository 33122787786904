import { DesignElement, IPoint, ValveBox } from '@shared-types';
import { takeUntil } from 'rxjs';
import paper from 'src/paper';
import { contains } from 'underscore';
import { paperChangeEvent } from '../custom-events';
import { paperItemStore } from '../helpers';
import { localPaper } from '../localPaper';
import {
  createSelectedSprinklerHandles,
  destroyHandles,
  doSprinklerThings,
  rotateArc,
} from '../paper-helpers/heads';
import { findNamedItems } from '../paper-helpers/plot.helpers';
import { ArrowMode, ITEMNAMES } from '../shared/workbench-enums';
import {
  clearSelectedItems,
  getState,
  overrideInfoBox,
  selectItems,
} from '../state';
import { changeElements } from '../state/changeElements';
import { changeValveBox } from '../state/valvebox/changeValveBox';
import { MouseDownPayload, ToolService } from '../tool.service';
import { isPaperElement } from './paper-items/paper-element';
import { isEdgeHopData, isPaperPipe } from './paper-items/paper-pipe';
import { isSprinkler } from './paper-items/paper-sprinkler';
import {
  getMatchingEdges,
  rotateEdgeHop,
  rotateMiscItem,
  rotateSprinkler,
  rotateValveBoxLocation,
} from './tool.helpers';
export type PipeWithPivotIndex = {
  pipe: paper.Path;
  segmentPivotIndex: number;
};

interface ArrowState {
  dragging: boolean;
  rotatingHead: boolean;
  downPoint: paper.Point;
  selectedHandle: paper.Item | null;
  handleStart: paper.Point | null;
  handleFrom: paper.Item | null;
  handleTo: paper.Item | null;
  mode: ArrowMode;
  stepSize: number;
}
export let arrowState: ArrowState;

const doRotationStuff = (item: paper.Item, amount: number) => {
  if (item.name === ITEMNAMES.ELEMENT) {
    const d = item as paper.Group;
    if (d.data.type === 'sprinkler') {
      rotateSprinkler(d, amount);
    } else if (d.data.type === 'miscItem') {
      rotateMiscItem(d, amount);
    }
  } else if (item.name === ITEMNAMES.VALVE_BOX_LOCATION) {
    rotateValveBoxLocation(item as paper.Group, -amount);
  } else if (
    item.name === ITEMNAMES.PIPE &&
    isEdgeHopData(item.data) &&
    item.data.hopIndex !== undefined
  ) {
    rotateEdgeHop(item, item.data.edgeUUID, item.data.hopIndex);
  }
};

const handleMoveMultiple = (items: paper.Item[], key: string) => {
  items.forEach((item) => {
    if (arrowState.mode === 'rotate') {
      if (key === 'right') {
        doRotationStuff(item, arrowState.stepSize);
      }
      if (key === 'left') {
        doRotationStuff(item, -arrowState.stepSize);
      }
    }
  });
};
export const resetArrowMode = () => {
  arrowState.rotatingHead = false;
  arrowState.selectedHandle = null;
  arrowState.mode = ArrowMode.MOVE;
  destroyHandles();
};

export const setupArrowTool = (toolService: ToolService) => {
  // let visibleOutlines = true;
  let tmpSelectedItem: paper.Item | null = null;
  let tmpSelectedPipes: PipeWithPivotIndex[] = [];

  arrowState = {
    dragging: false,
    rotatingHead: false,
    downPoint: new paper.Point(0, 0),
    selectedHandle: null,
    handleStart: null,
    handleFrom: null,
    handleTo: null,
    mode: ArrowMode.MOVE,
    stepSize: 0.5,
  };

  findNamedItems(ITEMNAMES.PIPE_TEXT).forEach((h) => (h.visible = true));

  // hide elements when panning to make it go faster
  const onMouseDown = ({ e, paperItem }: MouseDownPayload) => {
    const lassoMode = getState().lassoMode;
    arrowState.downPoint = e.point;
    arrowState.selectedHandle = null;
    if (
      paperItem &&
      tmpSelectedItem &&
      paperItem.data.uuid !== tmpSelectedItem.data.uuid &&
      !e.modifiers.shift &&
      !contains([ITEMNAMES.HANDLE_FROM, ITEMNAMES.HANDLE_TO], paperItem.name)
    ) {
      tmpSelectedItem = null;
      clearSelectedItems();
      localPaper.project.deselectAll();
      destroyHandles();
    }
    if (lassoMode === 'Plants') {
      if (paperItem?.name === ITEMNAMES.PLANT_GROUP) {
      } else {
        tmpSelectedItem = null;
        clearSelectedItems();
        localPaper.project.deselectAll();
      }
    } else {
      if (paperItem?.name === ITEMNAMES.PIPE) {
        tmpSelectedItem = paperItem;
      } else if (
        paperItem &&
        contains([ITEMNAMES.HANDLE_FROM, ITEMNAMES.HANDLE_TO], paperItem.name)
      ) {
        tmpSelectedItem = null;
        arrowState.rotatingHead = true;
        arrowState.selectedHandle = paperItem;
        arrowState.handleStart = paperItem.position;
      } else if (
        paperItem &&
        contains(
          [
            ITEMNAMES.ZONE_INFO_BOX_GROUP,
            ITEMNAMES.VALVE_BOX_LOCATION,
            ITEMNAMES.ELEMENT,
          ],
          paperItem.name,
        )
      ) {
        resetArrowMode();
        paperItem.pivot = e.point;
        tmpSelectedItem = paperItem;
        tmpSelectedItem.selected = true;
        if (paperItem.name === ITEMNAMES.ELEMENT) {
          const element = paperItem.data as DesignElement;
          tmpSelectedPipes = getMatchingEdges(e, element);
        }
      } else {
        resetArrowMode();
        tmpSelectedItem = null;
      }
    }
  };

  const onKeyUp = (e) => {
    const currentFocus = document.activeElement;
    if (
      currentFocus &&
      (currentFocus.tagName === 'INPUT' || currentFocus.tagName === 'TEXTAREA')
    ) {
      return;
    }
    if (e.key === 'escape') {
      selectItems([]);
      destroyHandles();
      localPaper.project.deselectAll();
    }
    switch (e.key) {
      case 'v':
        arrowState.mode = ArrowMode.MOVE;
        destroyHandles();
        break;
      case 'r':
        arrowState.mode = ArrowMode.ROTATE;
        destroyHandles();
        break;
      case 'a':
        arrowState.mode = ArrowMode.ANGLE;
        createSelectedSprinklerHandles();
        break;
    }
  };
  const onMouseDrag = (e) => {
    const diffX = e.point.x - arrowState.downPoint.x;
    const diffY = e.point.y - arrowState.downPoint.y;

    if (arrowState.rotatingHead) {
      rotateArc(e.point);
    } else {
      arrowState.dragging = true;
      if (e.modifiers.space) {
        // pan(e);
      } else if (
        tmpSelectedItem &&
        contains([ITEMNAMES.ZONE_INFO_BOX_GROUP], tmpSelectedItem.name)
      ) {
        tmpSelectedItem.position = e.point;
      } else if (tmpSelectedItem?.name === ITEMNAMES.VALVE_BOX_LOCATION) {
        arrowState.dragging = true;
        const vb = tmpSelectedItem.data as ValveBox;
        const itemsToMove = [vb.outputFitting, vb.inputFitting];
        getState().zones.forEach((zone) => {
          if (vb.zoneIDs.includes(zone.uuid)) {
            itemsToMove.push(
              zone.valve,
              zone.valveInputFitting,
              zone.valveOutputFitting,
            );
          }
        });
        itemsToMove.forEach((it) => {
          const item = paperItemStore.get(it);
          if (item && isPaperElement(item)) {
            item.paperItem.position = new paper.Point({
              x: item.element.position.x + diffX,
              y: item.element.position.y + diffY,
            });
          } else {
            console.error('hmm, el not found in store', it);
          }
        });
        tmpSelectedItem.position = e.point;
      } else if (tmpSelectedItem?.name === ITEMNAMES.ELEMENT) {
        const el = tmpSelectedItem.data as DesignElement;
        const { elementCache } = getState();

        const inputEl = elementCache[el.uuid];
        const changedEl: DesignElement = {
          ...(tmpSelectedItem.data as DesignElement),
          position: {
            x: inputEl.position.x + diffX,
            y: inputEl.position.y + diffY,
          },
        };
        tmpSelectedPipes.forEach((pipeEl) => {
          if (pipeEl.pipe.segments[pipeEl.segmentPivotIndex]) {
            pipeEl.pipe.segments[pipeEl.segmentPivotIndex].point =
              new paper.Point(changedEl.position.x, changedEl.position.y);
          }
        });
        tmpSelectedItem.position = e.point;
      }
    }
  };

  const onMouseUp = (e: paper.ToolEvent) => {
    const diffX = e.point.x - arrowState.downPoint.x;
    const diffY = e.point.y - arrowState.downPoint.y;
    const { selectedItems, elementCache } = getState();
    window.dispatchEvent(
      new CustomEvent('paperClick', {
        detail: {
          point: { x: e.point.x, y: e.point.y },
        },
      }),
    );
    if (arrowState.rotatingHead) {
      // console.log('just rotated a sprinkler arc')
      // get data from current selected thing
      const selectedPaperItems = localPaper.project.getItems({
        selected: true,
      });
      const sprinklerItem = selectedPaperItems.find(
        (item) =>
          item.name === ITEMNAMES.ELEMENT &&
          (item.data as DesignElement).type === 'sprinkler',
      );
      if (sprinklerItem) {
        changeElements([sprinklerItem.data as DesignElement]);
        createSelectedSprinklerHandles();
        // console.log('paper > react: change element')
      }
    } else if (arrowState.dragging && tmpSelectedItem) {
      arrowState.dragging = false;
      if (tmpSelectedItem.name === ITEMNAMES.ELEMENT) {
        const el = tmpSelectedItem.data as DesignElement;
        const inputEl = elementCache[el.uuid];

        const changedEl: DesignElement = {
          ...(tmpSelectedItem.data as DesignElement),
          position: {
            x: inputEl.position.x + diffX,
            y: inputEl.position.y + diffY,
          },
        };
        if (isSprinkler(changedEl)) {
          changedEl.props = {
            ...changedEl.props,
            x: inputEl.position.x + diffX,
            y: inputEl.position.y + diffY,
          };
        }
        paperChangeEvent(changedEl);
        selectItems([tmpSelectedItem]);
        tmpSelectedItem.selected = true;
      } else if (tmpSelectedItem.name === ITEMNAMES.VALVE_BOX_LOCATION) {
        const vb = tmpSelectedItem.data as ValveBox;
        const inputEl = elementCache[vb.inputFitting];
        changeValveBox({
          ...vb,
          position: {
            x: inputEl.position.x + diffX,
            y: inputEl.position.y + diffY,
          },
        });
        selectItems([tmpSelectedItem]);
      } else if (tmpSelectedItem.name === ITEMNAMES.ZONE_INFO_BOX_GROUP) {
        const d = tmpSelectedItem as {
          data: {
            vbUUID: string;
            vertical: boolean;
            point: IPoint;
            reversed: boolean;
          };
        };

        const newPoint = {
          x: tmpSelectedItem.bounds.left,
          y: tmpSelectedItem.bounds.top,
        };
        overrideInfoBox(
          d.data.vbUUID,
          newPoint,
          d.data.vertical,
          d.data.reversed,
        );
      }
    } else {
      if (e.item) {
        tmpSelectedPipes = [];
        if (
          e.item.name === ITEMNAMES.PIPE_TEXT ||
          e.item.name === ITEMNAMES.PIPE
        ) {
          const data = e.item.data;
          if (isEdgeHopData(data)) {
            const pipe = paperItemStore.get(data.edgeUUID);
            if (pipe && isPaperPipe(pipe)) {
              localPaper.project.deselectAll();
              pipe.updateSelection(true);
              selectItems([pipe.paperLaterals[0]]);
            }
          }
        } else {
          if (e.item.name === ITEMNAMES.OUTLINE) {
            // selecting a yard should do nothing. but the yard IS listening for a double click
          } else {
            if (e.item.name === ITEMNAMES.ELEMENT) {
              // fixes the pivot after it's mouseup'ed
              const x = getState().elementCache[e.item.data.uuid];
              e.item.pivot = new paper.Point(x.position);
            }
            const currentItemIDs = selectedItems
              .filter((item) => item.data)
              .map((item) => item.data.uuid);
            if (
              e.item.data &&
              e.item.data.uuid &&
              currentItemIDs.includes(e.item.data.uuid)
            ) {
              e.item.selected = false;
              const newlySelectedItems = selectedItems.filter((item) => {
                return item?.data?.uuid !== e?.item?.data?.uuid;
              });
              selectItems(newlySelectedItems);
              newlySelectedItems.forEach((item) => (item.selected = true));
            } else {
              e.item.selected = true;
              selectItems([...selectedItems, e.item]);
            }
          }
        }
      } else {
        if (getState().selectedItems.length) {
          // deselect on empty click
          clearSelectedItems();
          localPaper.project.deselectAll();
        }
        destroyHandles();
        // setCurrentZoneValue(-1)
      }
    }
  };

  const onKeyDown = (e: paper.KeyEvent) => {
    const selectedItems = localPaper.project.selectedItems;
    const selectedElements = selectedItems.filter(
      (p) => p.name === ITEMNAMES.ELEMENT,
    );
    const selectedValveBoxes = selectedItems.filter(
      (p) => p.name === ITEMNAMES.VALVE_BOX_LOCATION,
    );

    if (selectedValveBoxes.length) {
      const item = selectedValveBoxes[0];
      handleMoveMultiple([item], e.key);
    } else if (selectedElements.length) {
      const item = selectedElements[0];
      handleMoveMultiple(selectedElements, e.key);
      if (item.data.type === 'sprinkler') {
        doSprinklerThings(item, e);
      }
    } else {
      selectedItems.forEach((item) => {
        handleMoveMultiple([item], e.key);
      });
    }
  };

  toolService
    .keyUp$('Arrow')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onKeyUp);
  toolService
    .keyDown$('Arrow')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onKeyDown);
  toolService
    .mouseUp$('Arrow')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseUp);
  toolService
    .mouseDrag$('Arrow')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseDrag);
  toolService
    .mouseDown$('Arrow')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseDown);
};
