import { localPaper } from '../localPaper';
import { setLockedLayers, setVisibleLayers } from '../state';
import { LAYER_NAMES } from './workbench-enums';

export const layerNameMap: { [key in LAYER_NAMES]: string } = {
  [LAYER_NAMES.DEFAULT]: 'Default',
  [LAYER_NAMES.SVG]: 'Basemap SVG',
  [LAYER_NAMES.ELEMENTS]: 'Elements',
  [LAYER_NAMES.EDGES]: 'Pipes',
  [LAYER_NAMES.COVERAGE]: 'Coverage',
  [LAYER_NAMES.PLANTS]: 'Plants',
  [LAYER_NAMES.VALVE_INFO_BOXES]: 'Valve Info Boxes',
  [LAYER_NAMES.VALVE_BOXES]: 'Valve Boxes',
  [LAYER_NAMES.LEGEND]: 'Legend',
  [LAYER_NAMES.SHEETS]: 'Sheets',
  [LAYER_NAMES.TRACING]: 'Tracings',
  [LAYER_NAMES.OUTLINES]: 'Outlines',
  [LAYER_NAMES.ELEVATION]: 'Elevation Pins',
  [LAYER_NAMES.HEADS]: 'Heads',
  [LAYER_NAMES.BASEMAPS]: 'Basemaps',
  [LAYER_NAMES.SLEEVES]: 'Sleeves',
};
export const layerVisibility = [
  {
    name: LAYER_NAMES.DEFAULT,
  },
  {
    name: LAYER_NAMES.ELEMENTS,
    hideOnPan: true,
  },
  {
    name: LAYER_NAMES.PLANTS,
    hideOnPan: true,
  },
  {
    name: LAYER_NAMES.EDGES,
    hideOnPan: true,
  },
  {
    name: LAYER_NAMES.SHEETS,
    hideOnPan: true,
  },
  {
    name: LAYER_NAMES.COVERAGE,
  },
  {
    name: LAYER_NAMES.VALVE_INFO_BOXES,
    hideOnPan: true,
  },
  {
    name: LAYER_NAMES.VALVE_BOXES,
    hideOnPan: true,
  },
  {
    name: LAYER_NAMES.LEGEND,
  },
  {
    name: LAYER_NAMES.TRACING,
    hideOnPan: false,
  },
  {
    name: LAYER_NAMES.OUTLINES,
  },
  {
    name: LAYER_NAMES.BASEMAPS,
    hideOnPan: true,
  },
  {
    name: LAYER_NAMES.SVG,
    hideOnPan: true,
  },
];

export const setVisibleLayersFromPaperState = () => {
  setVisibleLayers(
    localPaper.project.layers
      .filter((l) => l.visible)
      .map((l) => l.name as LAYER_NAMES),
  );
};
export const setLockedLayersFromPaperState = () => {
  setLockedLayers(
    localPaper.project.layers
      .filter((l) => l.locked)
      .map((l) => l.name as LAYER_NAMES),
  );
};
