import { BaseItem } from '@shared-types';
import { addUndo } from '../contexts/design/undo';
import { deleteSleeve } from '../features/sleeves/state/deleteSleeves';
import { getItemByUUID, isSleeve } from '../helpers';
import {
  emitSingleHook,
  postAddItems,
  postBulkUpdateItems,
} from '../polyline/item.model';
import { getState, setState } from './state-basics';

export const addItems = (items: BaseItem[], undoable = true) => {
  if (undoable) {
    addUndo(() => {
      items.forEach((item) => deleteItem(item.uuid, false));
    }, 'add items');
  }
  setState({
    items: [...getState().items, ...items],
  });
  postAddItems(items);
};
export const addItem = (item: BaseItem, undoable = true) => {
  if (undoable) {
    addUndo(() => {
      deleteItem(item.uuid, false);
    }, 'add item');
  }
  setState({
    items: [...getState().items, item],
  });
  emitSingleHook('postAddItem', item);
};
export const deleteItem = (uuid: string, undoable = true) => {
  if (undoable) {
    const item = getItemByUUID(uuid);
    if (item) {
      addUndo(() => {
        addItem(item, false);
      }, 'delete item');
    }
  }
  const item = getItemByUUID(uuid);
  if (!item) throw new Error(`Item with uuid ${uuid} not found`);
  if (isSleeve(item)) {
    deleteSleeve(uuid);
  } else {
    setState({
      items: getState().items.filter((item) => item.uuid !== uuid),
      selectedIDs: getState().selectedIDs.filter((id) => id !== uuid),
    });
    emitSingleHook('postDeleteItem', item);
  }
};
export const updateItem = (item: BaseItem, undoable = true) => {
  const oldItem = JSON.parse(JSON.stringify(getItemByUUID(item.uuid)));
  if (undoable) {
    addUndo(() => {
      updateItem(oldItem, false);
    }, 'update item');
  }
  setState({
    items: getState().items.map((i) => (i.uuid === item.uuid ? item : i)),
  });
  emitSingleHook('postUpdateItem', item);
};
export const bulkUpdateItems = (updatedItems: BaseItem[], undoable = true) => {
  if (undoable) {
    const oldItems = updatedItems.map((i) => ({ ...getItemByUUID(i.uuid) }));
    addUndo(() => {
      bulkUpdateItems(oldItems, false);
    }, 'bulk update items');
  }

  setState({
    items: getState().items.map((i) => {
      const updated = updatedItems.find((u) => u.uuid === i.uuid);
      if (updated) {
        return updated;
      }
      return i;
    }),
  });
  postBulkUpdateItems(updatedItems);
};
