import { IPoint, Sprinkler } from '@shared-types';
import paper from 'src/paper';
import { ITEMNAMES } from '../shared/workbench-enums';
import { getArcPoints, getColor } from './plot.helpers';

const angles: number[] = [];

for (let i = 360; i >= 45; i -= 15) {
  angles.push(i);
}
const rotations: number[] = [];
for (let i = 0; i < 345; i += 15) {
  rotations.push(i);
}

export const handleCache = {};
export const headArcHandles = (
  sprinkler: Sprinkler,
  origin: IPoint,
  scale: number,
): paper.Path.Circle[] => {
  const handles: paper.Path.Circle[] = [];
  if (sprinkler.angle < 360 && sprinkler.width === 0) {
    const arcValues = getArcPoints(sprinkler.angle, origin, sprinkler.radius);
    const fromCircle = new paper.Path.Circle(
      new paper.Point(arcValues.from[0], arcValues.from[1]),
      6 / scale,
    );
    fromCircle.fillColor = getColor('red');
    fromCircle.data = {
      uuid: 'handle-from',
      sprinkler,
    };
    fromCircle.name = ITEMNAMES.HANDLE_FROM;
    const toCircle = new paper.Path.Circle(
      new paper.Point(arcValues.to[0], arcValues.to[1]),
      6 / scale,
    );
    toCircle.fillColor = getColor('black');
    toCircle.data = {
      uuid: 'handle-to',
      sprinkler,
    };
    toCircle.name = ITEMNAMES.HANDLE_TO;
    fromCircle.rotate(sprinkler.rotation, origin);
    toCircle.rotate(sprinkler.rotation, origin);
    handles.push(fromCircle, toCircle);
    handleCache['handle-from'] = fromCircle;
    handleCache['handle-to'] = toCircle;
  }
  return handles;
};
