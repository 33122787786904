import { IPlant, IPoint } from '@shared-types';
import { takeUntil } from 'rxjs';
import { defaultItem, paperItemStore } from '../helpers';
import { activateNamedLayer } from '../paper-helpers/plot.helpers';
import { ItemService } from '../polyline/ItemService';
import { registerStateHooks } from '../polyline/item.model';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { getState } from '../state';
import { deletePaperItem, updatePaperItem } from '../state/renderers';
import { toolService } from '../tool.service';
import { drawPlant$ } from './drawPlant';
import { PaperPlant, isPaperPlant } from './paper-plant';

class PlantService implements ItemService<IPlant> {
  init() {
    this.registerWithState();
    drawPlant$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
  }
  createItem = (point: IPoint): IPlant => ({
    ...defaultItem(point),
    itemType: 'plant',
    gpm: 0,
    gph: 0,
    width: 0,
    name: '',
  });
  colorPlants = (active: boolean) => {
    paperItemStore.forEach((item) => {
      if (isPaperPlant(item)) {
        item.toggleOutline(active);
      }
    });
  };
  renderItemToPaper = (item: IPlant, layer = LAYER_NAMES.PLANTS) => {
    activateNamedLayer(layer);
    const showPlantOutlines = getState().showPlantOutlines;
    const paperPlant = new PaperPlant(item);
    paperItemStore.set(item.uuid, paperPlant);
    paperPlant.toggleOutline(showPlantOutlines);
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  };
  registerWithState = () => {
    registerStateHooks<IPlant>('plant', {
      postAddItem: (item) => {
        this.renderItemToPaper(item);
      },
      postUpdateItem: (item) => {
        updatePaperItem(item);
      },
      postDeleteItem: (item) => {
        deletePaperItem(item.uuid);
      },
    });
  };
}

export const plantService = new PlantService();
