import { ProjectStatus, ProjectUI, UserUI } from '@shared-types';
import { addBusinessDays } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Dropdown,
  DropdownItemProps,
  Form,
  Header,
  Input,
  Table,
} from 'semantic-ui-react';
import { PortalContext } from 'src/contexts/portal/portal';
import { ProfileContext } from 'src/contexts/profile/profile';
import {
  setProjectOwner,
  setProjectStatus,
  updateProjectDueDate,
  updateProjectNumber,
  updateProjectPONumber,
} from 'src/services/project.service';
import styled from 'styled-components';

interface Props {
  project: ProjectUI;
  onEdit?: () => void;
}
export const ProjectDetails = ({ project }: Props) => {
  const { role } = useContext(ProfileContext);
  const {
    users,
    loadAllUsers,
    loadBasemappers,
    loadDesigners,
    // updateBasemapper,
    updateDesigner,
    // basemappers,
    designers,
  } = useContext(PortalContext);
  const [status, setStatus] = useState<ProjectStatus>(project.status);
  const [dueDate, setDueDate] = useState<string>(project.dueDate);
  const [designerItems, setDesignerItems] = useState<DropdownItemProps[]>([]);
  // const [basemapperItems, setBasemapperItems] = useState<DropdownItemProps[]>(
  //   [],
  // );
  const [ownerItems, setOwnerItems] = useState<DropdownItemProps[]>([]);
  // const [basemapper, setBasemapper] = useState<number>(project.basemapperId);
  const [designer, setDesigner] = useState<number>(project.designerId);
  const [owner, setOwner] = useState<number>(project.userId);
  const [poNumber, setPONumber] = useState<string>(project.data.poNumber);
  const [projectNumber, setProjectNumber] = useState<string>(
    project.data.projectNumber,
  );
  const [editPO, setEditPO] = useState(false);
  const [editProjectNumber, setEditProjectNumber] = useState(false);

  useEffect(
    () => {
      setStatus(project.status);
      // setEmail(project.email)
      setDueDate(project.dueDate);
      // setBasemapper(project.basemapperId);
      setDesigner(project.designerId);
      if (role === 'admin') {
        loadBasemappers();
        loadDesigners();
        loadAllUsers();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project],
  );

  // useEffect(
  //   () => {
  //     setBasemapperItems(
  //       basemappers.map(
  //         (user: UserUI): DropdownItemProps => ({
  //           text: user.email,
  //           value: user.id,
  //         }),
  //       ),
  //     );
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [basemappers],
  // );
  useEffect(
    () => {
      setDesignerItems(
        designers.map(
          (user: UserUI): DropdownItemProps => ({
            text: user.email,
            value: user.id,
          }),
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [designers],
  );
  useEffect(
    () => {
      setOwnerItems(
        users.map(
          (user: UserUI): DropdownItemProps => ({
            text: user.email,
            value: user.id,
          }),
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users],
  );

  const statusOptions: ProjectStatus[] = [
    'Draft',
    'Accepted',
    'Basemap Complete',
    'Design Complete',
    'Submitted',
  ];

  const doSetStatus = async (status: ProjectStatus) => {
    let dueDate;
    if (status === 'Accepted' && !project.dueDate) {
      dueDate = addBusinessDays(new Date(), 5).toLocaleDateString();
      setDueDate(dueDate);
    }
    setStatus(status);
    await setProjectStatus(status, project.id, dueDate);
  };

  // const doSetBasemapper = (id: number) => {
  //   updateBasemapper(id, project.id);
  //   setBasemapper(id);
  // };

  const doSetDesigner = (id: number) => {
    updateDesigner(id, project.id);
    setDesigner(id);
  };
  const doSetOwner = async (id: number) => {
    await setProjectOwner(id, project.id);
    setOwner(id);
  };
  const doSetPONumber = (_, d) => {
    setPONumber(d.value);
  };
  const doSetProjectNumber = (_, d) => {
    setProjectNumber(d.value);
  };
  const actuallySetPO = async () => {
    setEditPO(false);
    try {
      await updateProjectPONumber(project.id, project.data, poNumber);
    } catch (err) {
      console.error(err);
    }
  };
  const actuallySetProjectNumber = async () => {
    setEditProjectNumber(false);
    try {
      await updateProjectNumber(project.id, project.data, projectNumber);
    } catch (err) {
      console.error(err);
    }
  };

  const doSetDueDate = async (dueDate: Date) => {
    try {
      await updateProjectDueDate(
        project.id,
        project.data,
        dueDate.toLocaleDateString(),
      );
      setDueDate(dueDate.toLocaleDateString());
    } catch (err) {
      console.error(err);
    }
  };

  const statuses = statusOptions.map(
    (status): DropdownItemProps => ({
      text: status.toUpperCase(),
      value: status,
    }),
  );

  return (
    <Wrap>
      <Card fluid={true}>
        <Card.Content>
          <Card.Header>Project Details: {project.data.title}</Card.Header>
        </Card.Content>
        {status === 'Draft' && (role === 'admin' || role === 'contractor') && (
          <Card.Content>
            <Button
              primary={true}
              value={status}
              onClick={() => doSetStatus('Submitted')}
              type="submit"
            >
              Finish & Submit
            </Button>
            <br />
            <small>
              After a project is submitted it usually takes 12-24 hours before
              your project is reviewed and accepted. Once your project is
              accepted you will <strong>NOT</strong> be able to edit the details
              of your project without requesting a revision at an additional
              fee. Before it is accepted you can still edit your project.
            </small>
          </Card.Content>
        )}
        <Card.Content>
          {role !== 'contractor' && role !== 'admin' && (
            <div>Status: {status}</div>
          )}
          {role === 'admin' && (
            <>
              <Header as="h5" content="Status" />
              <Dropdown
                button
                label={'Status'}
                placeholder="Update Project Status"
                required={true}
                width={7}
                options={statuses}
                name="status"
                value={status}
                onChange={(_, e) => doSetStatus(e.value as ProjectStatus)}
              />

              <br />
              <Header as="h5" content="Basemapper" />
              {/* <Dropdown
                button
                label={'Basemapper'}
                placeholder="Assign Project Basemapper"
                required={true}
                options={[
                  { text: 'No basemapper', value: -1 },
                  ...basemapperItems,
                ]}
                search
                name="basemapper"
                value={basemapper}
                onChange={(_, e) => doSetBasemapper(e.value as number)}
              />
              <br /> */}
              <Header as="h5" content="Designer" />
              <Dropdown
                button
                label={'Designer'}
                search
                placeholder="Assign Project Designer"
                required={true}
                options={[{ text: 'No designer', value: -1 }, ...designerItems]}
                name="designer"
                value={designer}
                onChange={(_, e) => doSetDesigner(e.value as number)}
              />
              <br />
              <Header as="h5" content="Owner" />
              <Dropdown
                button
                label={'Owner'}
                search
                placeholder="Assign Project Owner"
                required={true}
                options={ownerItems}
                name="owner"
                value={owner}
                onChange={(_, e) => doSetOwner(e.value as number)}
              />
            </>
          )}
          <br />
          <br />
          {role !== 'contractor' && (
            <Form>
              <Form.Field>
                <label>Due Date</label>
                <DatePicker
                  value={dueDate ? new Date(dueDate).toLocaleDateString() : ''}
                  onChange={doSetDueDate}
                  disabled={role !== 'admin'}
                />
              </Form.Field>
            </Form>
          )}
          <>
            {status === 'Draft' &&
              (role === 'admin' || role === 'contractor') && (
                <Link to={`/app/project-request/${project.id}`}>
                  Edit Project
                </Link>
              )}
            <br />
            <br />
            <div>Notes</div>
            <div className="notes-area">{project.data.notes} </div>
            <Table compact>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Owner</Table.Cell>
                  <Table.Cell>
                    {project.owner ? project.owner.email : 'n/a'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Name</Table.Cell>
                  <Table.Cell>
                    <div>
                      {project.data.firstName} {project.data.lastName}
                    </div>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Company</Table.Cell>
                  <Table.Cell>
                    <div>
                      {project.data.companyName || '-'}
                      <br />
                      {project.data.companyAddress || '-'}
                    </div>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Site Address</Table.Cell>
                  <Table.Cell>
                    <div>{project.data.siteAddress || '-'}</div>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>PO Number</Table.Cell>
                  <Table.Cell>
                    {!editPO && (
                      <div>
                        {poNumber}{' '}
                        <Button
                          size="mini"
                          disabled={
                            !(role === 'admin' || role === 'contractor')
                          }
                          onClick={() => setEditPO(true)}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                    {editPO && (
                      <div>
                        <Form.Field
                          control={Input}
                          label={'PO Number'}
                          required={false}
                          name="poNumber"
                          autoComplete="off"
                          value={poNumber}
                          onChange={doSetPONumber}
                        />
                        <Button size="mini" onClick={actuallySetPO}>
                          Save
                        </Button>
                      </div>
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Project Number</Table.Cell>
                  <Table.Cell>
                    {!editProjectNumber && (
                      <div>
                        {projectNumber}{' '}
                        <Button
                          size="mini"
                          disabled={
                            !(role === 'admin' || role === 'contractor')
                          }
                          onClick={() => setEditProjectNumber(true)}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                    {editProjectNumber && (
                      <div>
                        <Form.Field
                          control={Input}
                          label={'Project Number'}
                          required={false}
                          name="projectNumber"
                          autoComplete="off"
                          value={projectNumber}
                          onChange={doSetProjectNumber}
                        />
                        <Button size="mini" onClick={actuallySetProjectNumber}>
                          Save
                        </Button>
                      </div>
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Title</Table.Cell>
                  <Table.Cell>{project.data.title}</Table.Cell>
                </Table.Row>
                {project.data.pocs.map((poc, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>POC {i + 1}</Table.Cell>
                    <Table.Cell>
                      <Table compact>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>GPM</Table.Cell>
                            <Table.Cell>{poc.gpm}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Dynamic PSI</Table.Cell>
                            <Table.Cell>{poc.dynamicPSI}</Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell>Equipment</Table.Cell>
                  <Table.Cell>
                    <div className="notes-area">
                      {project.data.headsNozzles}
                    </div>
                  </Table.Cell>
                </Table.Row>
                {role === 'admin' && (
                  <>
                    <Table.Row>
                      <Table.Cell>Rush Order?</Table.Cell>
                      <Table.Cell>
                        {project.data.rush ? 'Yes' : 'No'}{' '}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Size</Table.Cell>
                      <Table.Cell>{project.data.size} </Table.Cell>
                    </Table.Row>
                  </>
                )}
              </Table.Body>
            </Table>
          </>
        </Card.Content>
      </Card>
    </Wrap>
  );
};

const Wrap = styled.div`
  .notes-area {
    white-space: pre-line;
  }
`;
