import {
  Checkbox,
  DropdownItemProps,
  DropdownProps,
  Form,
  Modal,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { uniq } from 'underscore';
import {
  changeDripPipe,
  changeLateralPipe,
  changeMainPipe,
} from '../../paper-helpers/edges';
import {
  clearDripSizes,
  clearLateralSizes,
  clearMainSizes,
  setDripSizes,
  setLateralSizes,
  setMainSizes,
  toggleDripSize,
  toggleLateralSize,
  toggleMainSize,
  useDesignStore,
} from '../../state';
import { modalDefaults } from '../../state/modal.service';

export const PipeEquipmentModalType = 'pipeEquipmentModal';
export const PipeEquipmentModal = ({ closeModal }): JSX.Element => {
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const dripPipe = useDesignStore((state) => state.dripPipe);
  const dripPipeSizes = useDesignStore((state) => state.dripPipeSizes);
  const lateralPipeSizes = useDesignStore((state) => state.lateralPipeSizes);
  const mainPipeSizes = useDesignStore((state) => state.mainPipeSizes);
  const lateralPipe = useDesignStore((state) => state.lateralPipe);
  const mainPipe = useDesignStore((state) => state.mainPipe);

  const doMainPipeChange = (_, data: DropdownProps) => {
    changeMainPipe(data.value as string);
  };
  const dolateralPipeChange = (_, data: DropdownProps) => {
    changeLateralPipe(data.value as string);
  };
  const dodripPipeIDChange = (_, data: DropdownProps) => {
    changeDripPipe(data.value as string);
  };
  const getPipeSizes = (series: string) =>
    uniq(pipeProducts.filter((p) => p.series === series).map((p) => p.size));
  return (
    <Modal
      {...modalDefaults}
      actions={[{ key: 'done', content: 'Done', primary: true }]}
      header="Edit pipe equipment"
      onActionClick={closeModal}
      size="small"
      content={
        <Modal.Content>
          <Wrap>
            <Form className="form-wrap">
              <div>
                <Form.Select
                  label="Mainline Pipe"
                  value={mainPipe ? mainPipe : ''}
                  onChange={doMainPipeChange}
                  options={uniq(pipeProducts.map((p) => p.series)).map(
                    (pipeName: string): DropdownItemProps => ({
                      text: pipeName,
                      value: pipeName,
                    }),
                  )}
                />
                <div className="select-all">
                  Select: <a onClick={() => clearMainSizes()}>None</a> |{' '}
                  <a onClick={() => setMainSizes(getPipeSizes(mainPipe))}>
                    All
                  </a>
                </div>
                {!!mainPipe && (
                  <div>
                    {getPipeSizes(mainPipe).map((size: number, i) => (
                      <div key={i} className={`pipe-size`}>
                        <Checkbox
                          onClick={() => toggleMainSize(size)}
                          checked={mainPipeSizes.includes(size)}
                          label={size + '"'}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div>
                <Form.Select
                  label="Lateral Pipe"
                  onChange={dolateralPipeChange}
                  value={lateralPipe ? lateralPipe : ''}
                  options={uniq(pipeProducts.map((p) => p.series)).map(
                    (pipeName: string): DropdownItemProps => ({
                      text: pipeName,
                      value: pipeName,
                    }),
                  )}
                />
                <div className="select-all">
                  Select: <a onClick={() => clearLateralSizes()}>None</a> |{' '}
                  <a onClick={() => setLateralSizes(getPipeSizes(lateralPipe))}>
                    All
                  </a>
                </div>
                {!!lateralPipe && (
                  <div>
                    {getPipeSizes(lateralPipe).map((size: number, i) => (
                      <div key={i} className={`pipe-size`}>
                        <Checkbox
                          onClick={() => toggleLateralSize(size)}
                          checked={lateralPipeSizes.includes(size)}
                          label={size + '"'}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div>
                <Form.Select
                  label="Drip Pipe"
                  onChange={dodripPipeIDChange}
                  value={dripPipe ? dripPipe : ''}
                  options={uniq(pipeProducts.map((p) => p.series)).map(
                    (pipeName: string): DropdownItemProps => ({
                      text: pipeName,
                      value: pipeName,
                    }),
                  )}
                />
                <div className="select-all">
                  Select: <a onClick={() => clearDripSizes()}>None</a> |{' '}
                  <a onClick={() => setDripSizes(getPipeSizes(dripPipe))}>
                    All
                  </a>
                </div>
                {!!dripPipe && (
                  <div>
                    {getPipeSizes(dripPipe).map((size: number, i: number) => (
                      <div key={i} className={`pipe-size`}>
                        <Checkbox
                          onClick={() => toggleDripSize(size)}
                          checked={dripPipeSizes.includes(size)}
                          label={size + '"'}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Form>
          </Wrap>
        </Modal.Content>
      }
    />
  );
};

const Wrap = styled.div`
  .form-wrap {
    display: flex;
    justify-content: space-between;
  }
  .select-all {
    margin-bottom: 8px;
    a {
      cursor: pointer;
    }
  }
  .pipe-size {
    margin-bottom: 4px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 4px;
    .checkbox {
      display: block;
    }
  }
`;
