import { merge, switchMap, takeUntil, tap } from 'rxjs';
import paper from 'src/paper';
import { paperItemStore } from '../helpers';
import { localPaper } from '../localPaper';
import { findOrCreateLayer } from '../paper-helpers/plot.helpers';
import { addPoint, subtractPoint } from '../shared/geometry';
import { layerVisibility } from '../shared/layers';
import { getState } from '../state';
import { toolService } from '../tool.service';

export const hideOnPan = (timeout) => {
  layerVisibility.forEach((l) => {
    if (l.hideOnPan) {
      findOrCreateLayer(l.name).visible = false;
    }
  });
  if (timeout !== undefined) {
    clearTimeout(timeout);
  }
};

export const releaseTimeout = (timeout) =>
  setTimeout(() => {
    layerVisibility.forEach((l) => {
      findOrCreateLayer(l.name).visible = getState().visibleLayers.includes(
        l.name,
      );
    });
    hideOutOfBounds();
    timeout = undefined; // Reset the timeout ID
  }, 200);
export const hideOutOfBounds = () => {
  const bounds = localPaper.view.bounds;
  getState().elements.forEach((e) => {
    const paperItem = paperItemStore.get(e.uuid);
    if (
      e.position.x < bounds.left - 10 ||
      e.position.x > bounds.right + 10 ||
      e.position.y < bounds.top - 10 ||
      e.position.y > bounds.bottom + 10
    ) {
      if (paperItem) {
        paperItem.getItem().visible = false;
      }
    } else {
      if (paperItem) {
        paperItem.getItem().visible = true;
      }
    }
  });
};
export const pan$ = toolService.paperRightClickDown$.pipe(
  switchMap(() => {
    let wheelTimeout: any;
    return toolService.paperMouseDrag$.pipe(
      tap((e) => {
        hideOnPan(wheelTimeout);
        const lp = localPaper;
        const offset = subtractPoint(e.downPoint, e.point);
        lp.view.center = new paper.Point(addPoint(lp.view.center, offset));
      }),
      takeUntil(
        merge(toolService.paperMouseUp$, toolService.paperRightClickUp$).pipe(
          tap(() => {
            wheelTimeout = releaseTimeout(wheelTimeout);
          }),
        ),
      ),
    );
  }),
);
