import { ProjectData } from '@shared-types';
import { SyntheticEvent } from 'react';
import {
  Button,
  Card,
  Form,
  Header,
  Icon,
  InputOnChangeData,
  TextAreaProps,
} from 'semantic-ui-react';
import { base } from 'src/styles/common';
import styled from 'styled-components';
import { cloudinaryConfig } from '../Workbench/cloudinary.config';
import { SectionBody } from './ProjectRequestForm';

interface CompanyInfoProps {
  projectData: ProjectData;
  remainingProjects: number;
  complete: boolean;
  onUpdate: (form: ProjectData) => void;
}
export const CompanyInfo = ({
  projectData,
  remainingProjects,
  onUpdate,
  complete,
}: CompanyInfoProps) => {
  const handleChange = (
    _e: SyntheticEvent<HTMLElement, Event>,
    f: InputOnChangeData,
  ) => {
    if (f.name) {
      onUpdate({ ...projectData, [f.name]: f.value });
    }
  };
  const handleAreaChange = (_, f: TextAreaProps) => {
    if (f.name) {
      onUpdate({ ...projectData, [f.name]: f.value });
    }
  };
  const openUploadWidget = () => {
    const data = { ...projectData };
    (window as any).cloudinary.openUploadWidget(
      cloudinaryConfig,
      (error, result) => {
        if (error) {
          console.log(error);
        } else {
          if (result.event === 'queues-end') {
            if (result.info.files.length) {
              data.logos = [
                ...result.info.files
                  .filter((f) => f.status === 'success')
                  .map((f) => f.uploadInfo),
              ];
            }
          }
          if (result.event === 'close') {
            onUpdate({
              ...data,
            });
          }
        }
      },
    );
  };
  return (
    <Wrap>
      <Card fluid>
        <Card.Content className={`custom-header ${complete ? 'complete' : ''}`}>
          <Card.Header>
            Contractor Info&nbsp;&nbsp;
            <Icon
              name="check circle"
              color={complete ? 'green' : 'grey'}
              size="small"
            />
          </Card.Header>
        </Card.Content>

        <SectionBody>
          <div className="wrapper">
            <Form size="small">
              {remainingProjects > 0 && (
                <div>
                  <Icon name="check" color="green" size="small" /> You have{' '}
                  <strong>{remainingProjects}</strong> project credits!
                  <br />
                </div>
              )}
              <Form.Input
                label={'Contractor Name'}
                name="companyName"
                value={projectData.companyName}
                onChange={handleChange}
              />

              <Form.TextArea
                label={'Contractor Address'}
                name="companyAddress"
                value={projectData.companyAddress}
                onChange={handleAreaChange}
              />
            </Form>
            <div className="project-contractor-logo">
              <Header as="h5">Logo</Header>
              <div className="project-logo">
                <Button type="button" primary={true} onClick={openUploadWidget}>
                  Upload Logo
                </Button>
                <br />
                {projectData.logos.map((logo, i) => (
                  <img
                    alt="logo x"
                    key={i}
                    src={logo.thumbnail_url}
                    width="100"
                  />
                ))}
              </div>
            </div>
          </div>
        </SectionBody>
      </Card>
    </Wrap>
  );
};
const Wrap = styled.div`
  .wrapper {
    padding: ${base * 2}px;
  }
`;
