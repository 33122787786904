import { IDesignState } from '@shared-types';
import { designDefault } from 'src/shared/constants';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { createStore } from 'zustand/vanilla';
import { UndirectedGraph } from '../helpers/undirectedGraph';
import { localPaper, localZoom } from '../localPaper';

export type StoreState = IDesignState;

const createRootSlice = () => ({
  ...designDefault,
});

export const store = createStore<StoreState>(createRootSlice);

export const setState = (args: Partial<StoreState>) => {
  // put localstorage saving in here, middleware, etc
  store.setState(args);
};
export const getState = (): StoreState => {
  // this is a quick way to not have to import the store everywhere
  return store.getState();
};
export function useDesignStore<T>(
  selector?: (state: StoreState) => T,
  equals?: (a: T, b: T) => boolean,
) {
  return useStoreWithEqualityFn(store, selector!, equals);
}
export const saveNecessary = (state: IDesignState): Partial<StoreState> => {
  return {
    ...state,
    valveProducts: [],
    backflowProducts: [],
    pipeProducts: [],
    prvProducts: [],
    pumps: [],
    svg: '', // wiping locally injected svg inline code
    activeTool: 'Arrow',
    center: { x: localPaper.view.center.x, y: localPaper.view.center.y },
    elementCache: {},
    masterGraph: new UndirectedGraph(),
    pocGraphs: [],
    loading: false,
    zoomLevel: localZoom(),
    items: state.items.filter((item) => item.itemType !== 'ruler'),
    // deprecating things. uncomment these once we're ready to wipe
    // on saving old designs
    // plants: [],
    // sleeves: [],
    // texts: [],
    // basemaps: [],
    // sheets: [],
  };
};
