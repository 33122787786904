import { Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { LassoMode } from '../../../../../shared-types';
import { setLassoMode, useDesignStore } from '../state';
import { PanelWrap } from './PanelButton';

const lassoModes: LassoMode[] = ['Heads', 'Plants', 'All'];

export const SelectionToolProperties = () => {
  const lassoMode = useDesignStore((state) => state.lassoMode);
  const cycleModes = () => {
    const currentIndex = lassoModes.indexOf(lassoMode);
    const nextIndex = (currentIndex + 1) % lassoModes.length;
    setLassoMode(lassoModes[nextIndex]);
  };
  return (
    <Wrap>
      <h5>Selections</h5>
      Lasso Mode: <Label onClick={cycleModes}>{lassoMode}</Label>
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)``;
