import {
  DesignElement,
  DesignElementMap,
  IEdge,
  ValveBox,
  Zone,
} from '@shared-types';
import { uniq } from 'underscore';
import { addUndo } from '../../contexts/design/undo';
import { paperItemStore } from '../../helpers';
import { renderEdges } from '../../paper-helpers/edges';
import { updateAllHeadColors } from '../../paper-helpers/plot.helpers';
import {
  createEdgesBetweenValveSlots,
  getAssemblyPositions,
  reorderZones,
  valveBoxFittingPosition,
} from '../../paper-helpers/valves';
import { isPaperPipe } from '../../tools/paper-items/paper-pipe';
import { paperState } from '../paper.state';
import { getState, setState } from '../state-basics';
import { finalizeSteps } from '../zone.state';

export const changeValveBox = (valveBox: ValveBox, undoable = true) => {
  const {
    valveBoxes,
    elementCache,
    scale,
    zones,
    mainPipe,
    groups,
    pipeProducts,
    edges,
    elements,
    zoneNumberOffset,
  } = getState();
  const oldValveBox = valveBoxes.find((vb) => vb.uuid === valveBox.uuid);
  if (oldValveBox) {
    if (undoable) {
      addUndo(() => {
        changeValveBox(oldValveBox, false);
      }, 'Change Valve Box');
    }
    /* Changing a valvebox:
    Move the associated input and output fittings
    Move the associated valve assemblies for each zone assigned to the valvebox
    Delete any edges between the current valvebox and the zones
    Recreate the edges between the valve slots
  */
    const movedElements = movedVBItems(elementCache, valveBox, scale, zones);
    const matchedOldVBEdgeIDs = uniq(
      edges
        .filter((edge) =>
          createEdgesBetweenValveSlots(
            oldValveBox,
            zones,
            mainPipe,
            pipeProducts,
          ).find(
            (oldEdge) =>
              oldEdge.source === edge.source && oldEdge.target === edge.target,
          ),
        )
        .map((edge) => edge.uuid),
    );
    paperState.deleteItems(matchedOldVBEdgeIDs);
    const newVBEdges: IEdge[] = createEdgesBetweenValveSlots(
      valveBox,
      zones,
      mainPipe,
      pipeProducts,
    );

    const updatedEdges = [
      ...newVBEdges,
      ...edges.filter((edge) => !matchedOldVBEdgeIDs.includes(edge.uuid)),
    ];
    const updatedElements = elements.map(
      (el) => movedElements.find((ue) => ue.uuid === el.uuid) || el,
    );
    const updatedValveBoxes = valveBoxes.map((vb) =>
      vb.uuid === valveBox.uuid ? valveBox : vb,
    );
    const updatedZones = reorderZones(
      zones,
      updatedValveBoxes,
      zoneNumberOffset,
    );
    const final = finalizeSteps(updatedElements, updatedEdges, zones, scale);
    setState({
      elements: updatedElements,
      valveBoxes: updatedValveBoxes,
      zones: updatedZones,
      edges: final.finalizedEdges,
      elementCache: final.elementCache,
      masterGraph: final.masterGraph,
      pocGraphs: final.pocGraphs,
    });
    movedElements.forEach((el) => {
      paperItemStore.get(el.uuid)?.update(el);
      const elEdges = final.finalizedEdges.filter(
        (edge) => edge.source === el.uuid || edge.target === el.uuid,
      );
      elEdges.forEach((edge) => {
        const paperItem = paperItemStore.get(edge.uuid);
        if (paperItem && isPaperPipe(paperItem)) {
          paperItem.update(edge);
        }
      });
    });
    renderEdges(
      final.finalizedEdges.filter((edge) =>
        newVBEdges.find((e) => e.uuid === edge.uuid),
      ),
    );
    updateAllHeadColors(zones, groups);
  }
};
export const movedVBItems = (
  elementCache: DesignElementMap,
  valveBox: ValveBox,
  scale: number,
  zones: Zone[],
) => {
  const inputPosition = valveBoxFittingPosition(valveBox, scale, 'input');
  const updatedInput: DesignElement = {
    ...elementCache[valveBox.inputFitting],
    position: inputPosition,
  };
  const outputPosition = valveBoxFittingPosition(valveBox, scale, 'output');
  const updatedOutput: DesignElement = {
    ...elementCache[valveBox.outputFitting],
    position: outputPosition,
  };
  const movedElements: DesignElement[] = [updatedInput, updatedOutput];

  valveBox.zoneIDs.forEach((zoneID, i) => {
    const zone = zones.find((z) => z.uuid === zoneID);
    if (zone) {
      const updatedZoneInputPosition = getAssemblyPositions(
        i,
        'input',
        valveBox,
        scale,
      );
      const updatedZoneInput: DesignElement = {
        ...elementCache[zone.valveInputFitting],
        position: updatedZoneInputPosition,
      };
      const updatedValvePosition = getAssemblyPositions(
        i,
        'valve',
        valveBox,
        scale,
      );
      const updatedValve: DesignElement = {
        ...elementCache[zone.valve],
        position: updatedValvePosition,
      };
      const updatedZoneOutputPosition = getAssemblyPositions(
        i,
        'output',
        valveBox,
        scale,
      );
      const updatedZoneOutput: DesignElement = {
        ...elementCache[zone.valveOutputFitting],
        position: updatedZoneOutputPosition,
      };
      movedElements.push(updatedZoneInput, updatedZoneOutput, updatedValve);
    }
  });
  return movedElements;
};
