import { paperItemStore } from '../helpers';

class PaperState {
  deleteItems(ids: string[]) {
    ids.forEach(this.deleteItem);
  }
  private deleteItem(id: string) {
    if (paperItemStore.get(id)) {
      paperItemStore.get(id)?.destroy();
      paperItemStore.delete(id);
    } else {
      console.warn('there should have been a paper element to delete', id);
    }
  }
}
export const paperState = new PaperState();
