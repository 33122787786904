/*

    This is an algorithm that should be able to grow a grid to fit the bounds of the polygon
    Starting point: IPoint
    Rotation: 0
    Type of grid: Square or Triangle
    Radius: 20

*/

import { DesignElement, IPoint, SprinklerShell } from '@shared-types';
import { defaultItem } from '../../helpers';
import {
  getDistanceFromEdge,
  isInsidePoly,
  pointsEqual,
} from '../../helpers/geometry.helpers';
import { localPaper } from '../../localPaper';
import { rotateAroundPoint } from '../../shared/geometry';
import { GridType } from '../../shared/workbench-enums';
import { getState } from '../../state';
import { addElementsEdges } from '../../state/addElementsEdges';
import { createSprinklerFromRaw } from '../../tools/sprinkler';

class GrowingGrid {
  radius = 23;
  type: GridType = GridType.TRIANGLE;
  grid: IPoint[] = [];

  growAGrid = (
    polygon: IPoint[],
    rotation: number,
    startingPoint: IPoint,
  ): IPoint[] => {
    /* 
          While there is a queue of positions to add, we add them
          every time we add a new circle, we calculate its positions
          and we check if they are:
          a) inside the polygon
          b) not already in the grid
          c) within the radius of the polygon edge
          if all tests pass, we push them to the queue to be processed.
          Once the queue is complete we return a list of points that have been generated
      */
    this.grid = [];
    const queue: IPoint[] = [startingPoint];
    let x = 0;
    while (queue.length > 0) {
      x++;
      const point = queue.pop();
      if (point && !this.grid.some((p) => pointsEqual(p, point))) {
        this.grid.push(point);
        const newPoints = this.generatePoints(
          point,
          this.radius,
          rotation,
          this.type,
        );
        const filteredPoints = this.filterPoints(
          newPoints,
          polygon,
          this.radius,
        );
        queue.push(...filteredPoints);
      }
      if (x > 100000) {
        console.log('breaking');
        break;
      }
    }
    return this.grid;
  };

  applyGrid = () => {
    localPaper.project
      .getItem({ name: 'grid-point-proposal' })
      ?.removeChildren();
    const bases = getState().sprinklerBases;
    const elements: DesignElement[] = this.grid.map((p) => {
      const shell: SprinklerShell = {
        x: p.x,
        y: p.y,
        angle: 360,
        rotation: 0,
        radius: this.radius,
      };
      const s = createSprinklerFromRaw(shell, bases);
      if (!s) throw new Error('Could not create sprinkler from raw');
      let newEl: DesignElement = {
        ...defaultItem({ x: p.x, y: p.y }),
        type: 'sprinkler',
        props: s,
        itemType: 'design-element',
      };
      return newEl;
    });
    addElementsEdges(elements, []);
  };

  reset = () => {
    this.grid = [];
  };

  setType = (type: GridType) => {
    this.type = type;
  };

  setRadius = (radius: number) => {
    this.radius = radius;
  };

  private generatePoints = (
    point: IPoint,
    radius: number,
    rotation: number,
    type: 'square' | 'triangle',
  ): IPoint[] => {
    if (type === 'square') {
      return this.generateSquarePoints(point, radius, rotation);
    } else {
      return this.generateTrianglePoints(point, radius, rotation);
    }
  };

  private generateSquarePoints = (
    point: IPoint,
    radius: number,
    rotation: number,
  ): IPoint[] => {
    // Should generate the 4 points on the circumference of the circle
    // that are 90 degrees apart
    const points: IPoint[] = [];
    for (let i = 0; i < 4; i++) {
      const newPoint = rotateAroundPoint(
        point,
        { x: point.x + radius, y: point.y },
        rotation + i * 90,
      );
      points.push(newPoint);
    }
    return points;
  };

  private generateTrianglePoints = (
    point: IPoint,
    radius: number,
    rotation: number,
  ): IPoint[] => {
    // should generate the 6 points on the circumference of the circle
    // that are 60 degrees apart
    const points: IPoint[] = [];
    for (let i = 0; i < 6; i++) {
      const newPoint = rotateAroundPoint(
        point,
        { x: point.x + radius, y: point.y },
        rotation + i * 60,
      );
      points.push(newPoint);
    }
    return points;
  };

  private filterPoints = (
    points: IPoint[],
    polygon: IPoint[],
    radius: number,
  ): IPoint[] =>
    points.filter(
      (point) =>
        isInsidePoly(point, polygon) &&
        getDistanceFromEdge(polygon, point) >= radius,
    );
}

export const growingGrid = new GrowingGrid();
