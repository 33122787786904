import { InvoiceUI } from '@shared-types';
import { addDays, addHours } from 'date-fns';
import { FormEvent, useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Form, Header } from 'semantic-ui-react';
import { InvoiceList } from 'src/components/InvoiceList';
import { ProfileContext } from 'src/contexts/profile/profile';
import { createProjectInvoice } from 'src/services/invoice.service';
import styled from 'styled-components';

interface Props {
  invoices: InvoiceUI[];
  invoiceDescription: string;
  projectId: number;
  onUpdate: () => void;
}
export const ProjectInvoices = ({
  invoices,
  projectId,
  invoiceDescription,
  onUpdate,
}: Props) => {
  const { role } = useContext(ProfileContext);
  const [amount, setAmount] = useState<number>(0);
  const [description, setDescription] = useState(invoiceDescription);
  const [dueDate, setDueDate] = useState<Date>(addDays(new Date(), 14));
  const [creatingInvoice, setCreatingInvoice] = useState(false);

  const generateInvoice = async (e: FormEvent) => {
    try {
      e.preventDefault();
      setCreatingInvoice(true);
      if (
        amount &&
        projectId &&
        description &&
        dueDate &&
        ['admin', 'readonly'].includes(role)
      ) {
        await createProjectInvoice(
          projectId,
          amount,
          description,
          dueDate.getTime(),
        );
        setCreatingInvoice(false);
        setAmount(0);
        onUpdate();
      }
    } catch (err) {
      console.error(err);
      setCreatingInvoice(false);
      setAmount(0);
    }
  };

  return (
    <Wrap>
      {role === 'admin' && (
        <>
          <Header as="h4">Create Invoice</Header>
          <Form onSubmit={generateInvoice}>
            <Form.Input
              label="Invoice Description"
              value={description}
              onChange={(_, d) => setDescription(d.value)}
            />
            <Form.Group>
              <Form.Input
                label="Amount"
                type="number"
                value={amount}
                onChange={(_, d) => setAmount(parseInt(d.value, 10))}
              />
              <Form.Field>
                <label>Due Date</label>
                <DatePicker
                  value={dueDate.toLocaleDateString()}
                  minDate={addHours(new Date(), 1)}
                  onChange={(d) => (d ? setDueDate(d) : '')}
                />
              </Form.Field>
            </Form.Group>
            <Button
              primary={true}
              size="mini"
              disabled={creatingInvoice || !amount || !description || !dueDate}
              type="submit"
            >
              Create Draft
            </Button>
          </Form>
        </>
      )}
      {!!invoices.length && (
        <InvoiceList
          invoices={invoices}
          onPay={onUpdate}
          onVoid={onUpdate}
          onFinalize={onUpdate}
        />
      )}
      {!invoices.length && <div>No invoices to display</div>}
    </Wrap>
  );
};

const Wrap = styled.div``;
