import { IPoint } from '../../../../shared-types';
import {
  addPoints,
  getAngleBetweenPoints,
  getEuclideanDistance,
  subtractPoint,
} from './shared/geometry';

/**
 * Finds the new C point based on the given points A, B, C, and newB.
 *
 * @param a - The point A (fixed)
 * @param b - The point B (the point you are moving)
 * @param c - The point C (the third point that will move as a side effect)
 * @param newB - The new point B (the point you are moving to)
 * @returns The new C point.
 */
export const findNewCPoint = (
  a: IPoint,
  b: IPoint,
  c: IPoint,
  newB: IPoint,
): IPoint => {
  const scaleFactor = getScaleFactor(a, b, newB);
  const theta = getNewBTheta(a, b, newB);
  const translatedC = subtractPoint(c, a);
  const scaledC = scalePoint(translatedC, scaleFactor);
  const rotatedC = rotateCenterByTheta(scaledC, theta);
  const translateBack = addPoints(rotatedC, a);
  return translateBack;
};

export const getScaleFactor = (A: IPoint, B: IPoint, newB: IPoint): number => {
  const BA = getEuclideanDistance(B, A);
  const newBA = getEuclideanDistance(newB, A);
  if (BA === 0) throw new Error('BA is 0');
  const scaleFactor = newBA / BA;
  return scaleFactor;
};

export const getNewBTheta = (A: IPoint, B: IPoint, newB: IPoint): number =>
  getAngleBetweenPoints(A, newB) - getAngleBetweenPoints(A, B);

export const scalePoint = (center: IPoint, scale: number): IPoint => ({
  x: scale * center.x,
  y: scale * center.y,
});

export const rotateCenterByTheta = (C: IPoint, theta: number): IPoint => ({
  x: C.x * Math.cos(theta) - C.y * Math.sin(theta),
  y: C.x * Math.sin(theta) + C.y * Math.cos(theta),
});

export const getSideOfLine = (A: IPoint, B: IPoint, P: IPoint): number => {
  // Cross product of vector AB and vector AP
  return (B.x - A.x) * (P.y - A.y) - (B.y - A.y) * (P.x - A.x);
};

/**
 * Calculates the opposite circumference point of a triangle given three points.
 *
 * @param A - The first point of the chord.
 * @param B - The second point of the chord.
 * @param C - The center of the circle.
 * @returns The opposite circumference point.
 */
export const getOppositeCircumferencePoint = (
  A: IPoint,
  B: IPoint,
  C: IPoint,
  throughPoint?: IPoint,
): IPoint => {
  const midpoint = { x: (A.x + B.x) / 2, y: (A.y + B.y) / 2 };
  const vectorCM = { x: C.x - midpoint.x, y: midpoint.y - C.y };
  const magnitude = Math.sqrt(vectorCM.x ** 2 + vectorCM.y ** 2);
  const unitVector = { x: vectorCM.x / magnitude, y: vectorCM.y / magnitude };
  const radius = getEuclideanDistance(C, A);
  let D = {
    x: C.x + unitVector.x * radius,
    y: C.y - unitVector.y * radius,
  };

  // Check if D is on the same side as the throughPoint
  if (
    throughPoint &&
    getSideOfLine(A, B, throughPoint) * getSideOfLine(A, B, D) < 0
  ) {
    // Adjust D to the other side of the circle
    D = {
      x: C.x - unitVector.x * radius,
      y: C.y + unitVector.y * radius,
    };
  }

  return D;
};
