import {
  DesignElement,
  DesignElementMap,
  IPoint,
  IValveProduct,
  ValveBox,
  WaterGroup,
  Zone,
} from '@shared-types';
import paper from 'src/paper';
import { itemSizes } from 'src/shared/constants';
import { compact } from 'underscore';
import { getItemsByType, isPolyline, paperItemStore } from '../helpers';
import { valveGPM } from '../helpers/directedGraph';
import { localPaper } from '../localPaper';
import { getEuclideanDistance } from '../shared/geometry';
import { ITEMNAMES, LAYER_NAMES } from '../shared/workbench-enums';
import { getState } from '../state';
import { createValveIcon } from './createValveIcon';
import { activateNamedLayer, getColor } from './plot.helpers';
import { getAssemblyPositions } from './valves';

export const createValveTextBox = (
  point: IPoint,
  str: string,
  scale: number,
): paper.Item => {
  const p = new paper.Point(point);
  const scaledFont = itemSizes.valveTextFont / scale;
  const textBoxGroup = new paper.Group();
  const text = new paper.PointText(new paper.Point(p.x, p.y));
  text.translate(new paper.Point(0, scaledFont / 3));
  text.content = str;
  text.fontSize = scaledFont * 0.8;
  text.fontWeight = 800;
  text.justification = 'center';
  textBoxGroup.addChildren([text]);
  textBoxGroup.name = ITEMNAMES.VALVE_TEXT;
  return textBoxGroup;
};

export const renderValve = (
  valve: IValveProduct,
  zone: Zone,
  scale: number,
): paper.Group => {
  const color = zone.color;
  const position = { x: 0, y: 0 };
  const isDrip = zone.isDrip || zone.plantIds.length > 0;
  const valveTextGroup = new paper.Group();
  valveTextGroup.name = ITEMNAMES.VALVE;
  valveTextGroup.data = {
    valve,
    point: position,
  };
  const valveSymbol = createValveIcon(getColor(color), isDrip, scale);
  valveSymbol.position = new paper.Point(position);
  valveTextGroup.addChildren([valveSymbol]);
  valveTextGroup.selected = false;
  return valveTextGroup;
};

const vbHandle = (
  scale: number,
  w: number,
  h: number,
  start: IPoint,
  vb: ValveBox,
) => {
  const rectRounding = 4 / scale;
  const rect = new paper.Rectangle(start, new paper.Size(w, h));
  const c = new paper.Path.Rectangle(
    rect,
    new paper.Size(rectRounding, rectRounding),
  );
  c.fillColor = getColor('pink');
  c.sendToBack();
  c.name = 'pink-thing';
  c.data = vb;
  c.rotate(-vb.rotation, new paper.Point(vb.position));
  return c;
};

export const vbHandleCircle = (
  rectStart: IPoint,
  height: number,
  scale: number,
) => {
  const jbackPoint = new paper.Point({
    x: rectStart.x,
    y: rectStart.y + height / 2,
  });
  const jback = new paper.Path.Circle(jbackPoint, 5 / scale);
  jback.fillColor = getColor('black');
  return jback;
};

const vbHandleText = (
  i: number,
  vbTextBounds: paper.Rectangle,
  scale: number,
) => {
  const j = new paper.PointText({
    x: vbTextBounds.center.x,
    y: vbTextBounds.center.y + 2 / scale,
  });
  j.content = `${i + 1}`;
  j.fontSize = 6 / scale;
  j.fillColor = getColor('white');
  j.fontWeight = 'bold';
  j.justification = 'center';
  j.locked = true;
  return j;
};

export const renderValveBoxes = (
  valveBoxes: ValveBox[],
  zones: Zone[],
  scale: number,
  elementCache: DesignElementMap,
  showValveBoxPreviews: boolean,
  selectedItems: paper.Item[],
) => {
  // console.time('renderValveBoxes')
  activateNamedLayer(LAYER_NAMES.VALVE_BOXES);
  localPaper.project.activeLayer.removeChildren();
  valveBoxes.forEach((vb: ValveBox, i: number) => {
    const input = elementCache[vb.inputFitting];
    const output = elementCache[vb.outputFitting];
    if (input && output) {
      const g = new paper.Group();
      g.name = ITEMNAMES.VALVE_BOX_LOCATION;
      g.data = vb;
      if (!showValveBoxPreviews) {
        const vbHeight = 10 / scale;
        const vbPadding = 6 / scale;
        const vbWidth = getEuclideanDistance(input.position, output.position);
        const yPoint = vb.left
          ? input.position.y - vbHeight - vbPadding
          : input.position.y + vbPadding;
        const rectStart = { x: input.position.x, y: yPoint };
        g.addChild(vbHandle(scale, vbWidth, vbHeight, rectStart, vb));
        const vbText = new paper.Group();
        vbText.addChild(vbHandleCircle(rectStart, vbHeight, scale));
        vbText.rotate(-vb.rotation, new paper.Point(vb.position));
        vbText.addChild(vbHandleText(i, vbText.bounds, scale));
        g.addChild(vbText);
      }

      vb.zoneIDs.forEach((zid, j: number) => {
        const zone = zones.find((z) => z.uuid === zid);
        if (zone) {
          const fittingPosition = new paper.Point(
            getAssemblyPositions(j, 'label', vb, scale),
          );
          const isDrip = zone.isDrip || zone.plantIds.length > 0;
          const valveText = createValveTextBox(
            fittingPosition,
            isDrip ? `D${zone.orderNumber + 1}` : `${zone.orderNumber + 1}`,
            scale,
          );
          valveText.locked = true;
          valveText.name = ITEMNAMES.VALVE_TEXT;
        }
      });
      if (
        selectedItems.find(
          (i) => i.data && i.data.uuid && i.data.uuid === vb.uuid,
        )
      ) {
        g.selected = true;
      }
      g.sendToBack();
    }
  });
  activateNamedLayer(LAYER_NAMES.DEFAULT);
  // console.timeEnd('renderValveBoxes')
};

export const renderValveInfoBoxes = (
  zones: Zone[],
  groups: WaterGroup[],
  elements: DesignElement[],
  valveBoxes: ValveBox[],
  scale: number,
  showValveInfoBoxNumbers: boolean,
) => {
  activateNamedLayer(LAYER_NAMES.VALVE_INFO_BOXES);
  localPaper.project.activeLayer.removeChildren();
  // removeNamedItems([ITEMNAMES.ZONE_INFO_BOX_GROUP])
  const items = localPaper.project.getItems({
    match: (i) =>
      i.name === ITEMNAMES.SVG_OUTLINE ||
      i.name === ITEMNAMES.SVG_RASTER ||
      i.name === ITEMNAMES.BED ||
      i.name === ITEMNAMES.KEY_POINT,
  });
  const polylines = getItemsByType(isPolyline).filter(
    (p) => p.polyType === 'bed' || p.polyType === 'yard',
  );
  const paperYardsBeds = compact(
    polylines.map((p) => paperItemStore.get(p.uuid)),
  ).map((p) => p.getItem());
  items.push(...paperYardsBeds);
  let bounds = { maxX: 0, minX: 0, maxY: 0, minY: 0 };
  if (items.length) {
    bounds = {
      maxX: Math.max(...items.map((i) => i.bounds.right)),
      minX: Math.min(...items.map((i) => i.bounds.left)),
      maxY: Math.max(...items.map((i) => i.bounds.bottom)),
      minY: Math.min(...items.map((i) => i.bounds.top)),
    };
  }
  const locations: { point: IPoint; vertical: boolean }[] = valveBoxes.map(
    (vb: ValveBox) => {
      if (vb.infoBoxOverride) {
        return vb.infoBoxOverride;
      }
      const topDist = Math.abs(bounds.minY - vb.position.y);
      const rightDist = Math.abs(bounds.maxX - vb.position.x);
      const bottomDist = Math.abs(bounds.maxY - vb.position.y);
      const leftDist = Math.abs(bounds.minX - vb.position.x);
      const all = [topDist, rightDist, bottomDist, leftDist];
      const dist = 72 / scale; // put valve boxes in channel
      if (Math.min(...all) === topDist) {
        return { point: { x: vb.position.x, y: 0 }, vertical: false };
      }
      if (Math.min(...all) === bottomDist) {
        return {
          point: { x: vb.position.x, y: bounds.maxY - dist },
          vertical: false,
        };
      }
      if (Math.min(...all) === leftDist) {
        return { point: { x: 0, y: vb.position.y }, vertical: true };
      }
      if (Math.min(...all) === rightDist) {
        return {
          point: { x: bounds.maxX - dist, y: vb.position.y },
          vertical: true,
        };
      }
      // default return
      return { point: vb.position, vertical: true };
    },
  );

  // removeNamedItems([ITEMNAMES.ZONE_BOX])
  if (valveBoxes.length > 0) {
    const valveCounts: number[] = valveBoxes.map(() => 0);
    const width = 66 / scale;
    const height = 66 / scale;
    const fontLarge = 24 / scale;
    const fontSmall = 12 / scale;
    valveBoxes.forEach((vb: ValveBox, vIndex: number) => {
      const infoBoxGroup = new paper.Group();
      infoBoxGroup.name = ITEMNAMES.ZONE_INFO_BOX_GROUP;
      infoBoxGroup.data = {
        vbUUID: vb.uuid,
        vertical: locations[vIndex].vertical,
        point: locations[vIndex].point,
        reversed: vb.infoBoxOverride ? vb.infoBoxOverride.reversed : false,
      };
      const zoneIDs = [...vb.zoneIDs];
      if (vb.infoBoxOverride && vb.infoBoxOverride.reversed) {
        zoneIDs.reverse();
      }
      zoneIDs.forEach((z) => {
        const zone = zones.find((zone) => zone.uuid === z);
        if (zone) {
          // zones.filter(z => z.uuid === vIndex).forEach((zone: Zone, i: number) => {
          let origin = { x: 0, y: 0 };
          if (locations[vIndex].point) {
            if (locations[vIndex].vertical) {
              origin = {
                x: 0,
                y: 0 + valveCounts[vIndex] * (height + 2),
              };
            } else {
              origin = {
                x: 0 + valveCounts[vIndex] * (width + 2),
                y: 0,
              };
            }
            valveCounts[vIndex] += 1;
            const zoneBox = new paper.Group();
            const box = new paper.Path.Rectangle(
              new paper.Point(0, 0),
              new paper.Size(width, height),
            );
            box.strokeColor = getColor('#000000');
            box.strokeWidth = 1 / scale;
            box.fillColor = getColor('#ffffff');
            box.locked = true;
            const line = new paper.Path.Line(
              new paper.Point(0, height / 2),
              new paper.Point(width, height / 2),
            );
            line.strokeColor = getColor('#000000');
            line.strokeWidth = 0.5 / scale;
            const line2 = new paper.Path.Line(
              new paper.Point(width / 2, height / 2),
              new paper.Point(width / 2, height),
            );
            line2.strokeColor = getColor('#000000');
            line2.strokeWidth = 0.5 / scale;
            const zoneNumber = new paper.PointText(
              new paper.Point(width / 2, height / 4 + fontLarge / 2.5),
            );
            zoneNumber.justification = 'center';
            zoneNumber.content =
              zone.isDrip || zone.plantIds.length > 0
                ? `D${zone.orderNumber + 1}`
                : `${zone.orderNumber + 1}`;
            zoneNumber.fontSize = fontLarge;
            const valveSizeNumber = new paper.PointText(
              new paper.Point(
                width / 4,
                height / 2 + fontSmall / 2 + height / 4,
              ),
            );
            valveSizeNumber.justification = 'center';
            valveSizeNumber.fontSize = fontSmall;
            valveSizeNumber.content = '0"';
            if (zone.valve) {
              const valve = elements.find(
                (el) => el.type === 'valve' && el.uuid === zone.valve,
              );
              if (valve) {
                valveSizeNumber.content = `${
                  valve ? (valve.props as IValveProduct).size : 0
                }"`;
              }
            }
            const gpmNumber = new paper.PointText(
              new paper.Point(
                (width / 4) * 3,
                height / 2 + fontSmall / 2 + height / 4,
              ),
            );
            if (showValveInfoBoxNumbers) {
              // TODO: vomit
              const { pipeProducts, pocGraphs } = getState();
              gpmNumber.content = valveGPM(
                zone,
                pipeProducts,
                groups,
                pocGraphs,
              ).toFixed(1);
            } else {
              gpmNumber.content = '!!';
            }
            gpmNumber.justification = 'center';
            gpmNumber.fontSize = fontSmall;
            zoneBox.addChildren([
              box,
              zoneNumber,
              gpmNumber,
              valveSizeNumber,
              line,
              line2,
            ]);
            // zoneBox.position = new paper.Point(origin.x - 10, origin.y + i * 10)
            zoneBox.name = ITEMNAMES.ZONE_BOX;
            zoneBox.position = new paper.Point(origin.x, origin.y);
            infoBoxGroup.addChild(zoneBox);
          }
        }
      });
      infoBoxGroup.pivot = infoBoxGroup.bounds.topLeft;
      infoBoxGroup.position = new paper.Point(locations[vIndex].point);
      const boxBacker = new paper.Shape.Rectangle(
        new paper.Rectangle(infoBoxGroup.bounds),
      );
      boxBacker.fillColor = getColor('white');
      infoBoxGroup.addChild(boxBacker);
      boxBacker.sendToBack();
      // if (selectedItems.length === 1 && selectedItems[0].name === ITEMNAMES.ZONE_INFO_BOX_GROUP) {
      //   const item = selectedItems[0]
      //   infoBoxGroup.selected = (item.data as { vbUUID: string }).vbUUID === vb.uuid
      // }
    });
  }
  activateNamedLayer(LAYER_NAMES.DEFAULT);
};
