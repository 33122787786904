import {
  NozzleData,
  RadiusWithAngleLimit,
  Sprinkler,
  SprinklerBase,
  SprinklerCustomProps,
  SprinklerShell,
} from '@shared-types';
import { uniq } from 'underscore';
import {
  calculateSprinklerProps,
  getGeneratePerfData,
} from '../helpers/nozzleTransformer';

export const ANGLE_THRESHOLD = 5;

export const passesPerfData = (
  limits: RadiusWithAngleLimit[],
  shell: SprinklerShell,
): boolean => {
  const radiusFiltered = limits.filter(
    (l: RadiusWithAngleLimit) => l.radius === shell.radius,
  );
  if (!radiusFiltered.length) return false;
  // We just need the shell to pass any of the angle limit data, then the UI sorts out which one to use
  for (let i = 0; i < radiusFiltered.length; i++) {
    const lim = radiusFiltered[i];
    if (
      lim.fixed &&
      lim.angles.some(
        (angle: number) => Math.abs(angle - shell.angle) < ANGLE_THRESHOLD,
      )
    ) {
      return true;
    }
    if (
      !lim.fixed &&
      shell.angle >= Math.min(...lim.angles) &&
      shell.angle <= Math.max(...lim.angles)
    ) {
      return true;
    }
  }
  return false;
};

export const createSprinkler = (
  props: SprinklerCustomProps,
  base: SprinklerBase,
): Sprinkler => {
  const calculatedProps = calculateSprinklerProps(
    base,
    props.angle,
    props.radius,
  );
  return {
    base,
    ...props,
    ...calculatedProps,
  };
};
export const createSprinklerFromRaw = (
  raw: SprinklerShell,
  bases: SprinklerBase[],
): Sprinkler | null => {
  const matchingBases = bases.filter((b) => {
    const data = getGeneratePerfData(b);
    const limits = generateRadiusAngleLimits(data);
    return passesPerfData(limits, raw);
  });
  if (matchingBases.length > 0) {
    // TODO: taking the first matching base as the sprinkler to use. this could be smarter (based on precip for ex.)
    return createSprinkler(raw, matchingBases[0]);
  } else {
    return null;
  }
};

export const updateSprinkler = (
  current: Sprinkler,
  newProps: SprinklerCustomProps,
): Sprinkler => {
  const calculatedProps = calculateSprinklerProps(
    current.base,
    newProps.angle,
    newProps.radius,
  );
  return {
    ...current,
    ...newProps,
    ...calculatedProps,
  };
};
export const generateRadiusAngleLimits = (
  data: NozzleData[],
): RadiusWithAngleLimit[] => {
  const masterList: RadiusWithAngleLimit[] = [];
  const radii = uniq(data.map((d: NozzleData) => d.radius));
  const items: RadiusWithAngleLimit[] = [];
  radii.forEach((r: number) => {
    const sampleData = data.filter((d: NozzleData) => d.radius === r);
    items.push({
      radius: r,
      fixed: sampleData[0].fixed,
      angles: uniq(
        sampleData
          .map((base: NozzleData) => base.angle)
          .sort((a1: number, a2: number) => a1 - a2),
      ),
    });
    masterList.push(...items);
  });
  masterList.sort(
    (a1: RadiusWithAngleLimit, a2: RadiusWithAngleLimit) =>
      a2.radius - a1.radius,
  );
  return masterList;
};
export const getRadiiFromLimits = (
  limits: RadiusWithAngleLimit[],
  maxRadius: number,
): number[] =>
  uniq(
    limits
      .map((d: RadiusWithAngleLimit) => d.radius)
      .filter((r: number) => r <= maxRadius && r > 0),
  );
