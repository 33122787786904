import styled from 'styled-components';
import { useDesignStore } from '../../state';
import { ZoneTreeDiagram } from './ZoneTreeDiagram';

export const ZoneDiagrams = () => {
  const zones = useDesignStore((state) => state.zones);

  return (
    <Wrap>
      {zones
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((zone) => {
          return (
            <div key={zone.uuid}>
              <ZoneTreeDiagram
                classTitle={`zone-tree zone-${zone.orderNumber}`}
                zone={zone}
              />
            </div>
          );
        })}
    </Wrap>
  );
};
const Wrap = styled.div``;
