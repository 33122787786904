import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DesignElement } from '../../../../../../shared-types';
import { GroupList } from '../../components/GroupList';
import {
  PanelButton,
  PanelDivider,
  PanelWrap,
} from '../../components/PanelButton';
import { reduceHeadsGPMbyId } from '../../helpers/directedGraph';
import { getSelectedHeads } from '../../paper-helpers/heads';
import { ITEMNAMES } from '../../shared/workbench-enums';
import { addGroup, useDesignStore } from '../../state';
import { addZone } from '../../state/addZone';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';
import { ZoneList } from './ZoneList';

export const ZonePanel = (): JSX.Element => {
  const elementCache = useDesignStore((state) => state.elementCache);
  const selectedItems = useDesignStore((state) => state.selectedItems);
  const [heads, setHeads] = useState<DesignElement[]>([]);
  const [gpm, setGPM] = useState(0);
  useEffect(() => {
    const heads = selectedItems
      .filter((h) => h.name === ITEMNAMES.ELEMENT && isSprinkler(h.data))
      .map((h) => elementCache[h.data.uuid]);
    const gpm = reduceHeadsGPMbyId(
      heads.map((h) => h.uuid),
      elementCache,
    );
    setHeads(heads);
    setGPM(gpm);
  }, [elementCache, selectedItems]);
  return (
    <>
      <ZoneList />
      <Wrap>
        {/* <GenerateZones /> */}
        {heads.length} heads selected, {gpm.toFixed(1)} GPM
        <PanelButton
          onClick={() =>
            addZone(
              getSelectedHeads().map((h) => h.uuid),
              false,
            )
          }
        >
          + Add Zone
        </PanelButton>
        <PanelDivider />
        <PanelButton onClick={() => addGroup()}>+ Add Group</PanelButton>
        <GroupList />
      </Wrap>
    </>
  );
};

const Wrap = styled(PanelWrap)``;
