import { BaseItem, BaseItemType } from '@shared-types';
import { circleService } from '../circle/circle.service';
import { customSymbolService } from '../custom-symbol/custom-symbol.service';
import { sleeveService } from '../features/sleeves/sleeve.service';
import { paperItemStore } from '../helpers';
import { plantService } from '../plants/plant.service';
import { ItemService } from '../polyline/ItemService';
import { polylineService } from '../polyline/polyline.service';
import { rectangleService } from '../rectangle/rectangle.service';
import { sheetService } from '../sheets/sheet.service';
import { textService } from '../texts/text.service';
import { basemapService } from '../upload/basemap.service';
import { legendService } from './legend.service';

export const renderPaperItems = (items: BaseItem[]) => {
  items.forEach((item) => {
    renderPaperItem(item);
  });
};
export const renderPaperItem = (item: BaseItem) => {
  const itemServiceMap: { [key in BaseItemType]?: ItemService<BaseItem> } = {
    circle: circleService,
    rectangle: rectangleService,
    polyline: polylineService,
    sheet: sheetService,
    basemap: basemapService,
    plant: plantService,
    'text-item': textService,
    sleeve: sleeveService,
    legend: legendService,
    'custom-symbol': customSymbolService,
  };
  if (!itemServiceMap[item.itemType])
    throw new Error('Service has not been implemented');
  itemServiceMap[item.itemType]?.renderItemToPaper(item);
};
export const updatePaperItems = (items: BaseItem[]) => {
  items.forEach((item) => {
    updatePaperItem(item);
  });
};
export const updatePaperItem = (item: BaseItem) => {
  const paperItem = paperItemStore.get(item.uuid);
  if (paperItem) {
    paperItem.update(item);
  }
};
export const deletePaperItem = (uuid: string) => {
  const paperItem = paperItemStore.get(uuid);
  if (paperItem) {
    paperItem.destroy();
  }
};
export const deletePaperItems = (uuids: string[]) => {
  uuids.forEach((uuid) => {
    deletePaperItem(uuid);
  });
};
