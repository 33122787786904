import { IPipeProduct } from '@shared-types';
import { uniq } from 'underscore';

export const getPipeSizes = (
  series: string,
  pipeProducts: IPipeProduct[],
  minSize?: number,
) => {
  if (series) {
    return uniq(
      pipeProducts
        .filter((p) => {
          return p.series === series && (!minSize || p.size >= minSize);
        })
        .map((p) => p.size),
    );
  } else {
    return [];
  }
};
