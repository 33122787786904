import { getState } from '../state-basics';
import { changeValveBox } from './changeValveBox';

export const removeZoneFromValveBox = (
  zoneID: string,
  valveBoxUUID: string,
) => {
  const zone = getState().zones.find((z) => z.uuid === zoneID);
  const vb = getState().valveBoxes.find((vb) => vb.uuid === valveBoxUUID);
  if (zone && vb) {
    const newVB = {
      ...vb,
      zoneIDs: vb.zoneIDs.filter((z) => z !== zone.uuid),
    };
    changeValveBox(newVB);
  }
};
