import { PDFDocument } from 'pdf-lib';
import { Content } from 'pdfmake/interfaces';
import {
  IDesignState,
  Sprinkler,
  WaterGroup,
} from '../../../../../../shared-types';
import { getAvgPrecip, getPrecipStDev } from '../../paper-helpers/heads';
import { getState } from '../../state';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';
import { createPDFMakePage } from './createPDFMakePage';
import { mapHeadIdsToHeads } from './mapHeadIdsToHeads';
import { minimalTable, standardDefinition } from './pdf.helpers';
import { getHeadRows } from './takeoffPage';

export async function createGroupsPagesPDF(): Promise<PDFDocument | void> {
  const definitionFunc = async () => standardDefinition();
  const getContent = async () => groupsPages();
  return createPDFMakePage(definitionFunc, getContent);
}

const groupsPages = () => {
  const state = getState();
  const individualGroups = state.groups
    .sort((g1, g2) => g1.orderNumber - g2.orderNumber)
    .map((group: WaterGroup) => singleGroupPage(group, state));
  return individualGroups;
};
export const singleGroupPage = (
  group: WaterGroup,
  { elements }: IDesignState,
): Content[] => {
  const heads = elements.filter(isSprinkler);
  const zoneGPM = mapHeadIdsToHeads(heads, group.headIds)
    .reduce((acc: number, h: Sprinkler) => acc + h.gpm, 0)
    .toFixed(1);
  const zonePrecip =
    group.headIds.length > 0
      ? getAvgPrecip(heads, group.headIds).toFixed(2)
      : 0;
  const zoneStDev =
    group.headIds.length > 0
      ? getPrecipStDev(heads, group.headIds).toFixed(2)
      : 0;
  const headCount = group.headIds.length;
  const headRows = getHeadRows(heads, group);
  let headsOrPlants: Content = '';
  if (group.headIds.length) {
    headsOrPlants = minimalTable('Heads & Nozzles', headRows);
  }
  let metaStacks: { text: string; subtext: string }[] = [];
  metaStacks = [
    {
      text: `${zonePrecip} in/hr`,
      subtext: `avg. precip (+/- ${zoneStDev})`,
    },
    { text: `${zoneGPM}`, subtext: 'gpm' },
    { text: `${headCount}`, subtext: 'heads' },
  ];
  return [
    {
      text: `Group ${group.orderNumber + 1} Details`,
      style: 'header',
      pageOrientation: 'portrait',
    },
    {
      margin: [0, 30, 0, 30],
      columns: [
        {
          width: '*',
          stack: [headsOrPlants],
        },
        {
          width: 150,
          alignment: 'center',
          stack: [
            ...metaStacks.map((s) => ({
              stack: [
                { text: s.text, style: 'numHead' },
                { text: s.subtext, style: 'numHeadSub' },
              ],
            })),
          ],
        },
      ],
      columnGap: 0,
    },
  ];
};
