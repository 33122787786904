import {
  BaseItem,
  BaseItemType,
  BaseStyledItem,
  Basemap,
  Bed,
  Circle,
  DesignElement,
  IEdge,
  IMiscItem,
  IPoint,
  ISleeve,
  IStyle,
  Legend,
  Polyline,
  Rectangle,
  Ruler,
  Sheet,
  StylePreset,
  TextChunk,
  ValveBox,
  Yard,
} from '@shared-types';
import paper from 'src/paper';
import { newUUID } from './crypto-uuid';
import { getColor } from './paper-helpers/plot.helpers';
import { getState } from './state';
import { PaperItem } from './tools/paper-items/paper-item';

export const paperItemStore = new Map<string, PaperItem<BaseItem>>();

export const baseItemTypes: BaseItemType[] = [
  'basemap',
  'bed',
  'circle',
  'edge',
  'design-element',
  'plant',
  'sheet',
  'sleeve',
  'text-item',
  'valveBox',
  'yard',
  'polyline',
  'rectangle',
];
export const isBaseItemType = (str: string): str is BaseItemType => {
  return baseItemTypes.includes(str as BaseItemType);
};
export function getItemByUUID<T extends BaseItem>(
  uuid: string,
  isType: (item: BaseItem) => item is T,
): T;
export function getItemByUUID(uuid: string, isType?: undefined): BaseItem;

export function getItemByUUID<T extends BaseItem>(
  uuid: string,
  isType?: (item: BaseItem) => item is T,
): T | BaseItem {
  const item = getState().items.find((item) => item.uuid === uuid);
  if (!item) {
    throw new Error(`No item found with uuid ${uuid}`);
  }
  if (isType && !isType(item)) {
    throw new Error(`Type mismatch: ${item.itemType}`);
  }
  return item;
}

export const defaultStyle: IStyle = {
  strokeColor: '#000000',
  opacity: 1,
};

export const getItemsByType = <T extends BaseItem>(
  isType: (item: BaseItem) => item is T,
): T[] => {
  return getState().items.filter(isType);
};
export const defaultItem = (point: IPoint = { x: 0, y: 0 }): BaseItem => ({
  uuid: newUUID(),
  itemType: 'circle',
  position: point,
  rotation: 0,
  selected: false,
  visible: true,
  locked: false,
  selectable: true,
});
export const defaultFitting = (
  point: IPoint = { x: 0, y: 0 },
): DesignElement => ({
  ...defaultItem(point),
  type: 'fitting',
  props: {
    name: 'Fitting',
    displayName: '',
  } as IMiscItem,
  itemType: 'design-element',
});
export const defaultStyledItem = (
  point: IPoint = { x: 0, y: 0 },
): BaseStyledItem => ({
  ...defaultItem(point),
  style: defaultStyle,
  styleName: 'default',
});
export const presets: StylePreset[] = [
  {
    name: 'building',
    style: {
      strokeColor: '#000000',
      strokeWidth: 3,
    },
  },
  {
    name: 'tracing',
    style: {
      strokeColor: '#000000',
      strokeWidth: 1,
    },
  },
  {
    name: 'easement',
    style: {
      strokeColor: '#000000',
      strokeWidth: 1,
      dashArray: [2, 4, 2, 4],
    },
  },
];

export const applyAlpha = (
  fillColor: string | undefined,
  fillAlpha: number | undefined,
): paper.Color | null => {
  let color = fillColor ? getColor(fillColor) : null;
  if (color) {
    color = new paper.Color(color);
    color.alpha = fillAlpha !== undefined ? fillAlpha : 1;
  }
  return color;
};
export const isAnyItem = (item: BaseItem): item is BaseItem => {
  return !!item;
};
export const isStylable = (item: BaseItem): item is BaseStyledItem => {
  return item.hasOwnProperty('style');
};
export const isEdge = (item: BaseItem): item is IEdge => {
  return item.itemType === 'edge';
};
export const isSleeve = (item: BaseItem): item is ISleeve => {
  return item.itemType === 'sleeve';
};
export const isDesignElement = (item: BaseItem): item is DesignElement => {
  return item.itemType === 'design-element';
};
export const isSheet = (item: BaseItem): item is Sheet => {
  return item.itemType === 'sheet';
};
export const isText = (item: BaseItem): item is TextChunk => {
  return item.itemType === 'text-item';
};
export const isYard = (item: BaseItem): item is Yard => {
  return item.itemType === 'yard';
};
export const isBed = (item: BaseItem): item is Bed => {
  return item.itemType === 'bed';
};
export const isValveBox = (item: BaseItem): item is ValveBox => {
  return item.itemType === 'valveBox';
};
export const isBasemap = (item: BaseItem): item is Basemap => {
  return item.itemType === 'basemap';
};
export const isCircle = (item: BaseItem): item is Circle => {
  return item.itemType === 'circle';
};
export const isRectangle = (item: BaseItem): item is Rectangle => {
  return item.itemType === 'rectangle';
};
export const isRuler = (item: BaseItem): item is Ruler => {
  return item.itemType === 'ruler';
};
export const isPolyline = (item: BaseItem): item is Polyline => {
  return item.itemType === 'polyline';
};
export const isPolylineYard = (item: BaseItem): item is Polyline<'yard'> => {
  return isPolyline(item) && item.polyType === 'yard';
};
export const isPolylineBed = (item: BaseItem): item is Polyline<'bed'> => {
  return isPolyline(item) && item.polyType === 'bed';
};
export const isLegend = (item: BaseItem): item is Legend => {
  return item.itemType === 'legend';
};
export const toRealisticFixed = (n: number) => {
  return n.toFixed(Math.min(numZeroesAfterPoint(n) + 1, 4));
};
export const numZeroesAfterPoint = (x: number): number => {
  if (x % 1 == 0) {
    return 0;
  } else {
    return -1 - Math.floor(Math.log10(x % 1));
  }
};
