import { Cluster, IPoint, Zone } from '@shared-types';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Modal, Select } from 'semantic-ui-react';
import paper from 'src/paper';
import { makeHull } from '../../helpers/convex-hull';

import { PanelButton } from '../../components/PanelButton';
import { averagePoints } from '../../helpers/geometry.helpers';
import { localPaper } from '../../localPaper';
import { getColor } from '../../paper-helpers/plot.helpers';
import { isPlant, mapPlantIdsToPlants } from '../../plants/plants';
import { getState } from '../../state';

export const L1 = (
  deltaX: number,
  deltaY: number,
  deltaZ: number,
  z_weight: number,
): number => Math.abs(deltaX) + Math.abs(deltaY) + Math.abs(deltaZ) * z_weight;

export const L2 = (
  deltaX: number,
  deltaY: number,
  deltaZ: number,
  z_weight: number,
): number =>
  Math.pow(deltaX, 2) + Math.pow(deltaY, 2) + Math.pow(deltaZ, 2) * z_weight;

export const ValveBoxPredictions = () => {
  const [predictVBPosition, setVBPrediction] = useState(false);
  const [predictVBZones, setVBZonesPrediction] = useState(false);
  const [showHulls, setShowHulls] = useState(true);
  const [open, setOpen] = useState(false);
  const [valveBoxSize, setValveBoxSize] = useState(3);

  useEffect(() => {
    if (!predictVBPosition) {
      setVBZonesPrediction(false);
    }
  }, [predictVBPosition]);

  const createVBs = (points: IPoint[], sizeLimit: number) => {
    let type = 'L1';
    const n = points.length;
    let clusters: Cluster[] = [];
    for (let i = 0; i < n; i++) {
      clusters.push({ size: 1, pointIndices: [i] });
    }
    let cluster1index;
    let cluster2index;
    let deltaX;
    let deltaY;
    let min_distance;
    let dist;
    const zWeight = 0;
    let no_merge = false;
    const metric = type === 'L1' ? L1 : L2;
    while (!no_merge) {
      no_merge = true;
      min_distance = 1000000000;
      for (let i = 0; i < clusters.length; i++) {
        for (let j = i + 1; j < clusters.length; j++) {
          if (clusters[i].size + clusters[j].size <= sizeLimit) {
            // find closest points
            for (const u of clusters[i].pointIndices) {
              for (const v of clusters[j].pointIndices) {
                deltaX = points[u].x - points[v].x;
                deltaY = points[u].y - points[v].y;
                dist = metric(deltaX, deltaY, 1, zWeight);
                if (dist < min_distance) {
                  min_distance = dist;
                  cluster1index = i;
                  cluster2index = j;
                  no_merge = false;
                }
              }
            }
          }
        }
      }
      if (!no_merge) {
        clusters[cluster1index].size += clusters[cluster2index].size;
        for (const u of clusters[cluster2index].pointIndices) {
          clusters[cluster1index].pointIndices.push(u);
        }
        const lastPointIndex = clusters.length - 1;
        clusters[cluster2index] = clusters[lastPointIndex];
        clusters = clusters.slice(0, lastPointIndex);
      }
    }
    return clusters;
  };

  const clearHulls = () => {
    localPaper.project.getItems({ name: 'hull' }).forEach((i) => i.remove());
    localPaper.project
      .getItems({ name: 'centroid' })
      .forEach((i) => i.remove());
    localPaper.project
      .getItems({ name: 'vb-centroid' })
      .forEach((i) => i.remove());
  };

  const createHulls = (hullPoints: IPoint[], zone: Zone, centroid: IPoint) => {
    const hullShape = new paper.Path(hullPoints);
    hullShape.strokeWidth = 2;
    hullShape.fillColor = getColor(zone.color);
    hullShape.opacity = 0.3;
    hullShape.name = 'hull';
    const cent = new paper.Path.Circle(new paper.Point(centroid), 2);
    cent.fillColor = getColor(zone.color);
    cent.name = 'centroid';
  };

  const doHull = () => {
    const { zones, elementCache } = getState();
    const plants = getState().items.filter(isPlant);
    // valveBoxes.forEach((vb) => deleteValveBoxLocation(vb.uuid))
    clearHulls();
    const centroids: { zoneIndex: number; centroid: IPoint }[] = [];
    zones.forEach((zone, i) => {
      const els = zone.headIds.map((id) => elementCache[id].position);
      const plantEls = [...mapPlantIdsToPlants(plants, zone.plantIds)].map(
        (p) => p.position,
      );
      if (zone.isDrip || zone.plantIds.length) {
        const hullPoints = makeHull([...plantEls, ...els]);
        const centroid = averagePoints(hullPoints);
        centroids.push({ zoneIndex: i, centroid });
        if (showHulls) {
          createHulls(hullPoints, zone, centroid);
        }
      } else {
        const hullPoints = makeHull([...plantEls, ...els]);
        if (!hullPoints.length) {
          const centroid = averagePoints(
            zone.headIds.map((id) => elementCache[id].position),
          );
          if (showHulls) {
            const cent = new paper.Path.Circle(new paper.Point(centroid), 2);
            cent.fillColor = getColor(zone.color);
            cent.name = 'centroid';
            centroids.push({ zoneIndex: i, centroid });
          }
        } else {
          const centroid = averagePoints(hullPoints);
          centroids.push({ zoneIndex: i, centroid });
          if (showHulls) {
            createHulls(hullPoints, zone, centroid);
          }
        }
      }
    });
    if (predictVBPosition) {
      const clusters = createVBs(
        centroids.map((c) => c.centroid),
        valveBoxSize,
      );
      clusters.forEach((cluster) => {
        const vbPoint = averagePoints(
          cluster.pointIndices.map((i) => centroids[i].centroid),
        );
        const clusteredZones = cluster.pointIndices.map(
          (i) => zones[centroids[i].zoneIndex],
        );
        const cent = new paper.Path.Circle(new paper.Point(vbPoint), 4);
        cent.strokeColor = getColor('white');
        cent.strokeWidth = 0.5;
        cent.opacity = 0.9;
        cent.fillColor = getColor('white');
        cent.bringToFront();

        // cent.fillColor = getColor(zones[centroids[i].zoneIndex].color)
        cent.name = 'vb-centroid';
        if (predictVBZones) {
          clusteredZones.forEach((z, i) => {
            const cent = new paper.Path.Star({
              center: [vbPoint.x + i * 3, vbPoint.y],
              points: 5,
              radius1: 2,
              radius2: 1,
              fillColor: getColor(z.color),
            });
            cent.strokeWidth = 0.1;
            cent.strokeColor = getColor('white');
            cent.name = 'vb-centroid';
            cent.bringToFront();
          });
        }
        // addValveBoxLocation(
        //   vbPoint,
        //   0,
        //   cluster.pointIndices.map((i) => zones[centroids[i].zoneIndex].uuid)
        // )
      });
    }
  };

  return (
    <Modal
      open={open}
      size="mini"
      trigger={
        <PanelButton onClick={() => setOpen(true)}>
          Valve Box Predictions
        </PanelButton>
      }
    >
      <Modal.Header>Valve Box Predictions</Modal.Header>
      <Modal.Content>
        {open && (
          <>
            <Select
              value={valveBoxSize}
              onChange={(_, d) => setValveBoxSize(d.value as number)}
              options={[
                { text: 'Standard VB (3)', value: 3 },
                { text: 'Jumbo VB (5)', value: 5 },
              ]}
            ></Select>
            <br />
            <Checkbox
              checked={showHulls}
              onChange={() => setShowHulls(!showHulls)}
              label="Show hulls (with centroids)"
            />
            <br />
            <Checkbox
              checked={predictVBPosition}
              onChange={() => setVBPrediction(!predictVBPosition)}
              label="Predict VB locations (white circles)"
            />
            <br />
            <Checkbox
              disabled={!predictVBPosition}
              checked={predictVBZones}
              onChange={() => setVBZonesPrediction(!predictVBZones)}
              label="Predict VB zones (stars)"
            />
            <br />
            <Button onClick={doHull}>Show predictions</Button>
            <Button onClick={clearHulls}>Clear</Button>
            <hr />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => setOpen(false)}>
          Done
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
