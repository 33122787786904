import { BaseItem, IMiscItem, IPlant, Zone } from '@shared-types';
import paper from 'src/paper';
import { getEuclideanDistance } from 'src/shared';
import { plantSVGS } from 'src/shared/constants';
import { compact } from 'underscore';
import { paperItemStore } from '../helpers';
import { localPaper } from '../localPaper';
import { getColor } from '../paper-helpers/plot.helpers';
import { getState } from '../state';
import { changeElements } from '../state/changeElements';
import { isPaperPlant } from './paper-plant';

export const plantSymbolCache: { [key: string]: paper.SymbolDefinition } = {};
export const renderPlantOutlines = (show: boolean) => {
  const plants = getState().items.filter(isPlant);
  plants.forEach((plant) => {
    const plantItem = paperItemStore.get(plant.uuid);
    if (plantItem && isPaperPlant(plantItem)) {
      plantItem.toggleOutline(show);
    }
  });
};
export const setPlantSymbolCache = () => {
  if (!Object.keys(plantSymbolCache).length) {
    const preview = new paper.Path.Circle(new paper.Point(0, 0), 1);
    preview.strokeColor = getColor('#000');
    plantSymbolCache['preview'] = new paper.SymbolDefinition(preview);
    Object.keys(plantSVGS).forEach((key) => {
      const svg = plantSVGS[key];
      if (svg) {
        const realPlant = localPaper.project.importSVG(svg);
        realPlant.position = new paper.Point(0, 0);
        realPlant.strokeColor = getColor('black');
        realPlant.strokeWidth = 0.1;
        realPlant.clipMask = false;
        realPlant.children['Layer_2'].scale(0.98);
        plantSymbolCache[key] = new paper.SymbolDefinition(realPlant);
      }
    });
  }
};

export const distributeDripTransitionPoints = (zone: Zone) => {
  /* Goal: assign the closest plants to each drip transition points and tally up their GPM's so that each drip transition point has the correct GPM
    Example: If there are 12 plants and 3 drip transition points,
    iterate over each plant and find its closest drip transition point.
    Then add the plant's GPM to the drip transition point's GPM.
    */
  if (zone) {
    const plants = getState()
      .items.filter(isPlant)
      .filter((item) => zone.plantIds.includes(item.uuid));
    const dg = getState().pocGraphs.find((g) => g.hasNode(zone.valve));
    const allDripTransitionPoints = getState().elements.filter(
      (el) =>
        el.type === 'miscItem' &&
        (el.props as IMiscItem).name === 'Drip Transition',
    );
    if (dg) {
      const childNodesOfValve = dg.descendantsOfNode(zone.valve);
      const zoneDripTransitionPoints = allDripTransitionPoints.filter((el) =>
        childNodesOfValve.has(el.uuid),
      );
      zoneDripTransitionPoints.forEach((dtp) => {
        dtp.props = { ...dtp.props, gpmEffect: 0 };
      });
      plants.forEach((plant) => {
        const closestDripTransitionPoint = zoneDripTransitionPoints.reduce(
          (prev, curr) => {
            const prevDistance = getEuclideanDistance(
              plant.position,
              prev.position,
            );
            const currDistance = getEuclideanDistance(
              plant.position,
              curr.position,
            );
            if (prevDistance < currDistance) {
              return prev;
            } else {
              return curr;
            }
          },
        );
        // console.log(
        //   'adding' + plant.gpm + 'to' + closestDripTransitionPoint.uuid,
        // );
        const currentGPM = (closestDripTransitionPoint.props as IMiscItem)
          .gpmEffect;
        const newGPM = (currentGPM || 0) + plant.gph / 60;
        const roundedGPM = newGPM;
        closestDripTransitionPoint.props = {
          ...closestDripTransitionPoint.props,
          gpmEffect: roundedGPM,
        };
      });
      changeElements(zoneDripTransitionPoints);
    }
  }
};
export const sizeDripTransitions = () => {
  const dripZones = getState().zones.filter((z) => z.isDrip);
  dripZones.forEach((zone) => {
    distributeDripTransitionPoints(zone);
  });
};
export const getSelectedPlants = (filterOutZone?: Zone): IPlant[] => {
  // if filterOutZone, we don't want to send in the plants already in that zone
  const selectedItems = getState().selectedIDs;
  const plants = getState().items.filter(isPlant);
  const selectedPlants: IPlant[] = [];
  selectedItems.forEach((id) => {
    const plant = plants.find((p) => p.uuid === id);
    if (plant) {
      if (filterOutZone && filterOutZone.plantIds.includes(plant.uuid)) {
        return;
      }
      selectedPlants.push(plant);
    }
  });
  return selectedPlants;
};
export const isPlant = (item: BaseItem): item is IPlant => {
  return item.itemType === 'plant';
};
export const mapPlantIdsToPlants = (
  plants: IPlant[],
  plantIds: string[],
): IPlant[] =>
  compact(
    plantIds.map((plantId: string): IPlant | undefined =>
      plants.find((plant: IPlant) => plant.uuid === plantId),
    ),
  );
