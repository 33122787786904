import { Modal } from 'semantic-ui-react';
import { modalDefaults } from '../../state/modal.service';
import { HeadList } from './HeadList';

export const HeadEquipmentModalType = 'headEquipmentModal';
export const HeadEquipmentModal = ({ closeModal }) => {
  return (
    <Modal
      {...modalDefaults}
      actions={[{ key: 'done', content: 'Done', primary: true }]}
      size="large"
      onActionClick={closeModal}
      header="Head Equipment"
      content={
        <Modal.Content scrolling={true}>
          <HeadList />
        </Modal.Content>
      }
    />
  );
};
