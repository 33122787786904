import { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { styled } from 'styled-components';
import { BaseItem, IPoint } from '../../../../../shared-types';
import { defaultItem, getItemByUUID } from '../helpers';
import { changeItemPosition } from '../polyline/item.model';
import { addItem, deleteItem, updateItem } from '../state/item-state';
import { ARROW_SUBMODE, modeService, subModes } from '../tools/mode.service';
import { useModeValue } from '../tools/useModeValue';
import { PanelButton, PanelLabel, PanelWrap } from './PanelButton';

interface Props {
  items: BaseItem[];
}
let pasted = 1;
export const BaseItemActions = ({ items }: Props) => {
  const { subMode } = useModeValue();
  const [clipboard, setClipboard] = useState<string[]>([]);
  const [allLocked, setAllLocked] = useState(false);

  useEffect(() => {
    setClipboard([]);
    setAllLocked(items.every((i) => i.locked));
  }, [items]);

  const deleteItems = () => {
    items.forEach((item) => deleteItem(item.uuid));
  };
  const lockItems = () => {
    items.forEach((item) => {
      updateItem({ ...item, locked: !allLocked });
    });
    setAllLocked(!allLocked);
  };
  const duplicate = () => {
    items.forEach((item) => {
      const oldShape = getItemByUUID(item.uuid);
      if (oldShape) {
        const diff: IPoint = {
          x: 0,
          y: 0,
        };
        const newItem = changeItemPosition(oldShape, diff);
        newItem.uuid = defaultItem().uuid;
        addItem(newItem);
      }
    });
  };
  const copy = () => {
    setClipboard(items.map((i) => i.uuid));
    pasted = 1;
  };
  const paste = () => {
    clipboard.forEach((id) => {
      const oldShape = getItemByUUID(id);
      if (oldShape) {
        const diff: IPoint = {
          x: 2 * pasted,
          y: 2 * pasted,
        };
        const newItem = changeItemPosition(oldShape, diff);
        newItem.uuid = defaultItem().uuid;
        addItem(newItem);
      }
    });
    pasted += 1;
  };
  const setSubmode = (m: ARROW_SUBMODE) => {
    modeService.setSubMode(m);
  };
  return (
    <Wrap>
      <PanelWrap>
        {subModes.map((m) => (
          <Button
            key={m}
            size="mini"
            onClick={() => setSubmode(m)}
            active={subMode === m}
          >
            {m}
          </Button>
        ))}
        <PanelLabel>Base Item Actions</PanelLabel>
        <div className="split">
          <PanelButton onClick={deleteItems}>Delete</PanelButton>
          &nbsp; &nbsp;
          <PanelButton onClick={lockItems}>
            {allLocked ? 'Unlock' : 'Lock'}
          </PanelButton>
        </div>
        <div className="split">
          <PanelButton onClick={duplicate}>Duplicate</PanelButton>
          &nbsp; &nbsp;
          <PanelButton onClick={copy}>Copy</PanelButton>
          &nbsp; &nbsp;
          <PanelButton onClick={paste} disabled={!clipboard.length}>
            Paste
          </PanelButton>
        </div>
      </PanelWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  .split {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
