import { ImageFile } from '@shared-types';
import { useEffect, useState } from 'react';
import { Button, Icon, Modal, Select, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { scales } from './upload.helpers';

interface Props {
  file: ImageFile;
  removeFile: () => void;
  updateFile: (file: ImageFile) => void;
}

export const ImageTable = ({ file, removeFile, updateFile }: Props) => {
  const [_localFile, setLocalFile] = useState<ImageFile>(
    JSON.parse(JSON.stringify(file)),
  );
  const [imgPreview, setImgPreview] = useState('');

  useEffect(() => {
    setLocalFile({ ...file });
  }, [file]);

  const setScale = (scale: number) => {
    const newFile = {
      ...file,
      scale,
    };
    setLocalFile(newFile);
    updateFile(newFile);
  };

  return (
    <Wrap>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Preview</Table.HeaderCell>
            <Table.HeaderCell width={7}>Filename</Table.HeaderCell>
            <Table.HeaderCell>Scale</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <div className="preview-wrap">
                <div
                  onClick={() => setImgPreview(file.preview)}
                  className="icon-preview preview"
                >
                  <img src={file.preview} alt="Preview" />
                </div>
                <Button onClick={() => setImgPreview(file.preview)}>
                  Click for preview
                </Button>
              </div>
            </Table.Cell>
            <Table.Cell>
              <h4>{file.filename}</h4>
            </Table.Cell>
            <Table.Cell>
              <Select
                options={scales.map((s) => ({
                  key: s.label,
                  text: s.label,
                  value: s.value,
                }))}
                value={file.scale}
                onChange={(e, d) => setScale(d.value as number)}
              />
            </Table.Cell>
            <Table.Cell onClick={removeFile}>
              <Icon className="remove-file" name="close" />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Modal
        open={!!imgPreview}
        size="fullscreen"
        closeOnDimmerClick
        closeOnEscape
        closeOnDocumentClick
        closeIcon={true}
        onClose={() => setImgPreview('')}
      >
        <img src={imgPreview} width="100%" />
      </Modal>
    </Wrap>
  );
};
const Wrap = styled.div``;
