import { Polyline } from '@shared-types';
import { useState } from 'react';
import { Button, Checkbox, Select } from 'semantic-ui-react';
import styled from 'styled-components';
import { paperItemStore } from '../helpers';
import { blurElements } from '../paper-helpers/plot.helpers';
import { isPaperPolyLine } from '../polyline/paper-polyline';
import { getPolylineYards } from '../polyline/polyline-helpers';
import {
  setResolution,
  toggleAllCoverage,
  toggleCoverage,
  useDesignStore,
} from '../state';
import { resolutionOptions } from '../state/coverage';

export const CoverageOptions = () => {
  const resolution = useDesignStore((state) => state.resolution);
  const showCoverage = useDesignStore((state) => state.showCoverage);
  const showAllCoverage = useDesignStore((state) => state.showAllCoverage);
  const [localResolution, setLocalResolution] = useState(resolution);

  // const displayScore = (score: number): string =>
  //   `${(score * 100).toFixed(2)}%`;
  // const [activeYardScore, setActiveYardScore] = useState('0');

  const doResolutionChange = (f: number) => {
    setResolution(f);
  };

  const polyYardArea = (poly: Polyline, r: number) => {
    const paperPoly = paperItemStore.get(poly.uuid);
    if (paperPoly && isPaperPolyLine(paperPoly)) {
      const path = paperPoly.paperItem;
      const bounds = path.bounds;
      const minx = bounds.left;
      const maxx = bounds.right;
      const miny = bounds.top;
      const maxy = bounds.bottom;
      const dotCount = Math.round(((maxx - minx) * (maxy - miny)) / r);
      return dotCount;
    }
    return 0;
  };

  const totalDots = () =>
    getPolylineYards().reduce(
      (total, p) => total + polyYardArea(p, localResolution),
      0,
    );

  return (
    <Wrap>
      <div>
        {/* <p>Score: &nbsp; {displayScore(score)}</p>
        <p>Yard Score: &nbsp; {activeYardScore}%</p>
        <small>(Score: % of yard within 90-130% coverage)</small>
        <br /> */}
        <Select
          compact={true}
          value={localResolution}
          onChange={(_, f) => setLocalResolution(f.value as number)}
          options={resolutionOptions}
        />
        <Button
          className="update-coverage"
          onClick={() => doResolutionChange(localResolution)}
        >
          Update
        </Button>
        <div className="coverage-count">
          Will produce {totalDots().toLocaleString()} dots at {localResolution}'
          resolution
        </div>
        {getPolylineYards().length > 1 && (
          <div className="coverage-checkboxes">
            <Checkbox
              size="mini"
              checked={showCoverage}
              onChange={() => {
                toggleCoverage();
                blurElements();
              }}
              label="Active yard"
            />
            <Checkbox
              size="mini"
              checked={showAllCoverage}
              onChange={() => {
                toggleAllCoverage();
                blurElements();
              }}
              label="All yards"
            />
          </div>
        )}
      </div>
    </Wrap>
  );
};
const Wrap = styled.div`
  padding: 8px;
  .update-coverage {
    margin-left: 8px;
  }
  .coverage-count {
    margin: 8px 0;
  }
  .coverage-checkboxes {
    display: flex;
    gap: 8px;
    margin-top: 8px;
  }
`;
