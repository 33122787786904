import { PDFDocument } from 'pdf-lib';
import {
  ImageFile,
  ImageUpload,
  PDFFile,
  PDFUpload,
} from '../../../../../shared-types/workbench-types';
import { BASE_API_URL, getJWTToken } from '../../../services/fetchHelpers';
import { newUUID } from '../crypto-uuid';

const uploadFormDataAsFile = async (formData: FormData) => {
  try {
    await fetch(`${BASE_API_URL}/files/upload-file`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getJWTToken()}`,
      }),
      body: formData,
    });
  } catch (error) {
    console.error('Upload failed', error);
  }
};
function dataURItoBlob(dataURI: string): Blob {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

const uploadImageFile = async (file: ImageFile): Promise<ImageUpload> => {
  const extension = file.filename.split('.').pop();
  const uuid = newUUID();
  const formData = new FormData();
  formData.append('file', file.file);
  formData.append('filename', `${uuid}.${extension}`);
  await uploadFormDataAsFile(formData);
  return {
    uuid,
    type: 'image',
    scale: file.scale,
    originalFilename: file.filename,
    width: file.width,
    height: file.height,
    rotation: 0,
    createdDate: new Date().getTime(),
  };
};
const uploadPDFPages = async (file: PDFFile): Promise<PDFUpload[]> => {
  let uploads: PDFUpload[] = [];
  const uuid = newUUID();
  for (let i = 0; i < file.pages.length; i++) {
    const page = file.pages[i];
    const imageFileName = `${uuid}_page-${i}`;
    await uploadPDFPageAsPNG(imageFileName, page.jpgPreviewDataURL);
    await uploadPDFPageAsPDF(imageFileName, i, file);
    const pdfPageUpload: PDFUpload = {
      uuid: imageFileName,
      createdDate: new Date().getTime(),
      width: page.width,
      height: page.height,
      type: 'pdf',
      originalFilename: file.filename,
      pageNumber: i,
      scale: file.scale,
      rotation: page.rotation,
    };
    uploads.push(pdfPageUpload);
  }
  return uploads;
};
export const uploadPDFPageAsPNG = async (
  newFileName: string,
  jpgDataURL: string,
): Promise<void> => {
  const blob = dataURItoBlob(jpgDataURL);
  const formData = new FormData();
  formData.append('file', blob);
  formData.append('filename', `${newFileName}.png`);
  await uploadFormDataAsFile(formData);
};
export const uploadPDFPageAsPDF = async (
  newFileName: string,
  pageNumber: number,
  file: PDFFile,
): Promise<void> => {
  const newDoc = await PDFDocument.create();
  const formData = new FormData();
  const buffer = await file.file.arrayBuffer();
  const srcDoc = await PDFDocument.load(buffer);
  const [copiedPage] = await newDoc.copyPages(srcDoc, [pageNumber]);
  newDoc.addPage(copiedPage);
  const bytes = await newDoc.save();
  const blob = new Blob([bytes], { type: 'application/pdf' });
  formData.append('file', blob);
  formData.append('filename', newFileName + '.pdf');
  await uploadFormDataAsFile(formData);
};
export const uploadFile = async (
  file: PDFFile | ImageFile,
): Promise<(PDFUpload | ImageUpload)[]> => {
  if (isImageFile(file)) {
    const imageUpload = await uploadImageFile(file);
    return [imageUpload];
  } else if (isPDFFile(file)) {
    return uploadPDFPages(file);
  }
  return [];
};

export const scales = [
  { label: '1:4', value: 18 },
  { label: '1:8', value: 9 },
  { label: '1:10', value: 7.2 },
  { label: '1:16', value: 4.5 },
  { label: '1:20', value: 3.6 },
  { label: '1:50', value: 1.44 },
  { label: '1:30', value: 2.4 },
  { label: '1:100', value: 0.72 },
  { label: 'Unknown (1:1)', value: 72 },
];

export const isPDFFile = (file: ImageFile | PDFFile): file is PDFFile => {
  return file.type === 'pdf';
};
export const isImageFile = (file: ImageFile | PDFFile): file is ImageFile => {
  return file.type === 'image';
};
export const scaleValue = (scale: number) => {
  return scales.find((s) => s.value === scale)?.label || 'unknown';
};
