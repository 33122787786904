// useZoomValue.ts

import { useEffect, useState } from 'react';
import { merge, tap } from 'rxjs';
import { Tools } from '../../../../../shared-types';
import { ARROW_SUBMODE, modeService } from './mode.service';

export const useModeValue = (): {
  mode: Tools;
  subMode: ARROW_SUBMODE;
} => {
  const [mode, setModeValue] = useState<Tools>(modeService.mode);
  const [subMode, setSubMode] = useState<ARROW_SUBMODE>(modeService.subMode);

  useEffect(() => {
    const sub = merge(
      modeService.mode$.pipe(tap(setModeValue)),
      modeService.subMode$.pipe(tap(setSubMode)),
    ).subscribe();
    return () => sub.unsubscribe();
  }, []);

  return { mode, subMode };
};
