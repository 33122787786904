import { addUndo } from '../contexts/design/undo';
import { generateGraphs } from '../helpers/directedGraph';
import { updateAllHeadColors } from '../paper-helpers/plot.helpers';
import {
  cleanValveBoxes,
  removeZoneEdges,
  removeZoneElements,
  reorderZones,
} from '../paper-helpers/valves';
import { addZone } from './addZone';
import { paperState } from './paper.state';
import { getState, setState } from './state-basics';

export const deleteZone = (payload: string, undoable = true) => {
  const zone = getState().zones.find((z) => z.uuid === payload)!;
  if (undoable) {
    addUndo(() => {
      addZone(zone.headIds, false, false);
    }, 'Delete Zone');
  }
  paperState.deleteItems([
    zone.valveInputFitting,
    zone.valveOutputFitting,
    zone.valve,
  ]);
  const { edges, valveBoxes, mainPipe, pipeProducts, groups } = getState();
  const { keep, kill } = removeZoneEdges(
    edges,
    zone,
    valveBoxes,
    mainPipe,
    pipeProducts,
  );
  paperState.deleteItems(kill.map((e) => e.uuid));
  const newElements = removeZoneElements(getState().elements, zone);
  let zones = getState().zones.filter((z) => z.uuid !== zone.uuid);
  zones = reorderZones(
    zones,
    getState().valveBoxes,
    getState().zoneNumberOffset,
  );
  updateAllHeadColors(zones, groups);
  const { masterGraph, pocGraphs } = generateGraphs(keep, newElements, zones);
  const newValveBoxes = cleanValveBoxes(valveBoxes, zone.uuid);
  updateAllHeadColors(zones, groups);
  setState({
    edges: keep,
    elements: newElements,
    zones,
    valveBoxes: newValveBoxes,
    masterGraph,
    pocGraphs,
    currentZone:
      getState().currentZone === zone.uuid ? '' : getState().currentZone,
  });
};
