import { DesignElement, IMiscItem } from '@shared-types';
import { useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { HeadTooltip } from '../features/heads/HeadTooltip';
import { changeElements } from '../state/changeElements';
import { isSprinkler } from '../tools/paper-items/paper-sprinkler';
import { BackflowTooltip } from './BackflowTooltip';
import { MeterTooltip } from './MeterTooltip';
import { MiscItemTooltip } from './MiscItemTooltip';
import { POCTooltip } from './POCTooltip';
import { PRVTooltip } from './PRVTooltip';
import { PanelInputWrap, PanelWrap } from './PanelButton';
import { PumpTooltip } from './PumpTooltip';

interface Props {
  item: DesignElement;
}

export const ElementTooltip = ({ item }: Props) => {
  const [localItem, setLocalItem] = useState<IMiscItem | null>(null);
  useEffect(() => {
    if (item.type === 'miscItem') {
      const miscItem = item.props as IMiscItem;
      setLocalItem({
        ...miscItem,
      });
    }
  }, [item]);

  const editProductTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value;
    if (localItem) {
      setLocalItem({
        ...localItem,
        displayName: title,
      });
      changeElements([
        {
          ...item,
          props: {
            ...item.props,
            displayName: title,
          },
        },
      ]);
    }
  };

  const setPreExisting = (preExisting: boolean) => {
    const updatedEl: DesignElement = {
      ...item,
      preExisting,
    };
    changeElements([updatedEl]);
  };
  return (
    <Wrap>
      {item.type === 'miscItem' &&
        !!localItem &&
        (item.props as IMiscItem).name !== 'Drip Transition' && (
          <div className="generic-product">
            <h4>Misc Item</h4>
            <PanelInputWrap
              label="Display Name"
              placeholder="Enter display name..."
              onChange={editProductTitle}
              value={localItem.displayName}
            />
          </div>
        )}
      {isSprinkler(item) && <HeadTooltip element={item} />}
      {item.type === 'backflow' && <BackflowTooltip element={item} />}
      {item.type === 'prv' && <PRVTooltip element={item} />}
      {item.type === 'meter' && <MeterTooltip element={item} />}
      {item.type === 'poc' && <POCTooltip element={item} />}
      {(item.type === 'pump' || item.type === 'booster pump') && (
        <PumpTooltip element={item} />
      )}
      {item.type === 'miscItem' &&
        (item.props as IMiscItem).name === 'Drip Transition' && (
          <MiscItemTooltip element={item} />
        )}
      <PanelWrap>
        <Checkbox
          onClick={() => setPreExisting(!item.preExisting)}
          checked={item.preExisting}
          label="Pre-existing? (will not show in takeoff)"
        />
      </PanelWrap>
    </Wrap>
  );
};
const Wrap = styled.div`
  .generic-product {
    padding: 8px;
    width: 250px;
    input {
      width: 100%;
    }
  }
`;
