import React from 'react';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { setShowValveInfoBoxNumbers, useDesignStore } from '../../state';
import { CheckboxAction } from './CheckboxAction';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const ValveInfoBoxesLayer = ({ open, onOpen }) => {
  const showValveInfoBoxNumbers = useDesignStore(
    (state) => state.showValveInfoBoxNumbers,
  );
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.VALVE_INFO_BOXES,
  );
  const valveBoxes = useDesignStore((state) => state.valveBoxes);
  const toggleShowNumbers = () => {
    setShowValveInfoBoxNumbers(!showValveInfoBoxNumbers);
  };

  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Valve Info Boxes (${valveBoxes.length})`}
      open={open}
      onOpen={onOpen}
      actions={
        <>
          <CheckboxAction
            title="Show Numbers"
            onClick={toggleShowNumbers}
            checked={showValveInfoBoxNumbers}
          />
        </>
      }
    />
  );
};

export default React.memo(ValveInfoBoxesLayer);
