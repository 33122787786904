import { DesignElement, DesignSprinklerElement, IEdge } from '@shared-types';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { itemSizes } from 'src/shared/constants';
import styled from 'styled-components';
import { ChangeGroup } from '../../components/ChangeGroup';
import { ChangeZone } from '../../components/ChangeZone';
import { PanelButton, PanelWrap } from '../../components/PanelButton';
import { newUUID } from '../../crypto-uuid';
import { createDefaultEdge } from '../../paper-helpers/edges';
import { getEuclideanDistance } from '../../shared/geometry';
import { addHeadsToGroup, addHeadsToZone, useDesignStore } from '../../state';
import { addElementsEdges } from '../../state/addElementsEdges';
import { changeElements } from '../../state/changeElements';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';
import { ChangeHead } from './ChangeHead';

interface Props {
  element: DesignSprinklerElement;
}
export const HeadTooltip = ({ element }: Props) => {
  const zones = useDesignStore((state) => state.zones);
  const groups = useDesignStore((state) => state.groups);
  const sprinklerBases = useDesignStore((state) => state.sprinklerBases);
  const edges = useDesignStore((state) => state.edges);
  const scale = useDesignStore((state) => state.scale);
  const elementCache = useDesignStore((state) => state.elementCache);
  const [showChangeZone, setShowChangeZone] = useState(false);
  const [showChangeHead, setShowChangeHead] = useState(false);
  const [head, setHead] = useState(element.props);
  const [duplicateCount, setDuplicateCount] = useState(1);
  useEffect(
    () => {
      const h = elementCache[element.uuid];
      if (isSprinkler(h)) {
        setHead(h.props);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementCache],
  );

  useEffect(() => {
    setHead(element.props);
  }, [element]);

  const duplicateHead = () => {
    const newX =
      element.position.x + (itemSizes.headSize * 2 * duplicateCount) / scale;
    const newElement: DesignElement = {
      ...element,
      uuid: newUUID(),
      position: {
        ...element.position,
        x: newX,
      },
      props: {
        ...element.props,
        x: newX,
      },
    };
    addElementsEdges([newElement], []);
    setDuplicateCount(duplicateCount + 1);
  };

  const handleSave = (element: DesignElement): void => {
    changeElements([
      {
        ...element,
      },
    ]);
  };

  const handleAddToZone = (zoneID: string, headIds: string[]) => {
    addHeadsToZone(zoneID, headIds);
  };
  const handleAddToGroup = (groupID: string, headIds: string[]) => {
    addHeadsToGroup(groupID, headIds);
  };

  const pipeToClosestHead = () => {
    const edgeCount = edges.filter(
      (edge) => edge.source === element.uuid || edge.target === element.uuid,
    );
    if (edgeCount.length > 1) {
      console.error('we already got 2 edges connected');
      return;
    }
    const zone = zones.find((z) => z.headIds.includes(element.uuid));
    if (zone) {
      let closest = 1000000;
      let closestEl: DesignElement | undefined = undefined;
      zone.headIds.forEach((uuid) => {
        if (uuid !== element.uuid) {
          const el = elementCache[uuid];
          const dist = getEuclideanDistance(el.position, element.position);
          if (dist >= closest) {
            console.log('found one further than closest');
            return;
          }

          const isAlreadyConnected = edges.find(
            (edge) =>
              (edge.source === el.uuid && edge.target === element.uuid) ||
              (edge.source === element.uuid && edge.target === el.uuid),
          );
          if (isAlreadyConnected) {
            console.log('this one already connected to this head');
            return;
          }
          const connectedEdges = edges.filter(
            (edge) => edge.source === el.uuid || edge.target === el.uuid,
          );
          if (connectedEdges.length < 2) {
            closest = dist;
            closestEl = el;
          }
        }
      });
      if (closestEl) {
        const edge: IEdge = createDefaultEdge({
          source: element.uuid,
          target: (closestEl as DesignElement).uuid,
        });
        addElementsEdges([], [edge]);
      }
    }
  };
  const isUnzoned = () => {
    return !zones.find((z) => z.headIds.includes(element.uuid));
  };
  const headCurrentZone = () => {
    const z = zones.find((z) => z.headIds.includes(element.uuid));
    if (z) {
      return `Zone ${z.orderNumber + 1}`;
    }
    const g = groups.find((g) => g.headIds.includes(element.uuid));
    if (g) {
      return `Group ${g.orderNumber + 1}`;
    }
    return 'Unzoned';
  };
  return (
    <Wrap>
      <h5>Head Details</h5>
      <div>
        <p>
          Head: {head.base.headModel} {head.base.headSeries}
        </p>
        <p>
          Nozzle: {head.base.nozzleSeries} {head.base.nozzleModel}
        </p>
        <p>Angle: {head.angle.toFixed(2)}˚</p>
        <p>Rotation: {head.rotation.toFixed(2)}˚</p>
        <p>Zone/Group: {headCurrentZone()}</p>
      </div>
      <PanelButton onClick={() => setShowChangeZone(!showChangeZone)}>
        Change Zone/Group
      </PanelButton>
      <PanelButton onClick={() => duplicateHead()}>Duplicate Head</PanelButton>
      <PanelButton onClick={() => setShowChangeHead(!showChangeHead)}>
        Change Head
      </PanelButton>
      <PanelButton disabled={isUnzoned()} onClick={() => pipeToClosestHead()}>
        Pipe to closest head
      </PanelButton>
      <div>
        <div>
          <h5>Head key shortcuts</h5>
          <div className="hint">
            <Key>r</Key> rotate
          </div>
          <div className="hint">
            <Key>a</Key> angle
          </div>
          <div className="hint">
            <Key>q</Key> 90˚ arc
          </div>
          <div className="hint">
            <Key>h</Key> 180˚ arc
          </div>
          <div className="hint">
            <Key>f</Key> max arc (or 360˚)
          </div>
          <div className="hint">
            <Key>g</Key> min arc
          </div>
        </div>
      </div>
      <Modal open={showChangeHead} size="mini">
        <Modal.Header>Change Head</Modal.Header>
        <Modal.Content>
          <ChangeHead
            element={element}
            onSave={handleSave}
            bases={sprinklerBases}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setShowChangeHead(false)}>
            Done
          </Button>
        </Modal.Actions>
      </Modal>
      {/* Hiding Groups for now... */}
      <Modal open={showChangeZone} size="mini">
        <Modal.Header>Change Group/Zone</Modal.Header>
        <Modal.Content>
          <>
            <ChangeZone
              zones={zones}
              headIds={[element.uuid]}
              onAdd={handleAddToZone}
            />
            {/* <Divider /> */}
            <ChangeGroup
              groups={groups}
              headIds={[element.uuid]}
              onAdd={handleAddToGroup}
            />
          </>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setShowChangeZone(false)}>
            Done
          </Button>
        </Modal.Actions>
      </Modal>
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)``;

const Key = styled.span`
  font-weight: bold;
  border: 1px solid #666666;
  border-radius: 4px;
  height: 24px;
  line-height: 24px;
  margin-right: 5px;
  font-size: 12px;
  text-align: center;
  padding: 0 10px;
`;
