import {
  DesignElement,
  IEdge,
  IMeter,
  IMiscItem,
  IPoC,
  IPump,
} from '@shared-types';
import paper from 'src/paper';
import { PaperEvType } from '../custom-events';
import { defaultItem, paperItemStore } from '../helpers';
import { addItemToPipe } from '../paper-helpers/edges';
import { getColor } from '../paper-helpers/plot.helpers';
import { getEuclideanDistance } from '../shared/geometry';
import { ITEMNAMES } from '../shared/workbench-enums';
import { getState } from '../state';

import { takeUntil } from 'rxjs';
import { addElementsEdges } from '../state/addElementsEdges';
import { ToolService } from '../tool.service';
import {
  PaperPipe,
  isEdgeHopData,
  isPaperPipe,
} from './paper-items/paper-pipe';
import { getProtectedEdgeNodes } from './tool.helpers';

export const setupMiscTool = (toolService: ToolService) => {
  let dragging = false;
  const c = new paper.Path.Circle(new paper.Point(0, 0), 2);
  c.name = ITEMNAMES.TMP_ICON;
  c.visible = false;
  let snapMode = false;
  let snappedEdge: null | IEdge = null;
  const { scale } = getState();
  const closestPoint = new paper.Path.Circle(new paper.Point(0, 0), 3 / scale);
  closestPoint.name = 'closest-point';
  closestPoint.fillColor = new paper.Color('orange');
  closestPoint.visible = false;
  const overlapperRect = new paper.Rectangle(
    new paper.Point(0, 0),
    new paper.Size(8 / scale, 8 / scale),
  );
  const updateOverlapper = (event: paper.ToolEvent) => {
    const protectedEdgeNodes = getProtectedEdgeNodes();
    overlapperRect.center = event.point;
    // TODO: find "all" the edges that overlap, then get closest point on each one, then use that point
    const edges = getState().edges;
    const paperPipes = edges
      .map((edge) => paperItemStore.get(edge.uuid))
      .filter((p): p is PaperPipe => !!p && isPaperPipe(p));
    const overlappedEdges = paperPipes.filter(
      (e) =>
        !(
          protectedEdgeNodes.includes(e.edge.source) ||
          protectedEdgeNodes.includes(e.edge.target)
        ) &&
        e.paperLaterals.some(
          (p) =>
            getEuclideanDistance(p.getNearestPoint(event.point), event.point) <
            2,
        ),
    );
    if (overlappedEdges.length) {
      let closestDistance = 100000000;
      overlappedEdges.forEach((overlappedEdge) => {
        const closestPoints = overlappedEdge.paperLaterals.map((p) =>
          p.getNearestPoint(event.point),
        );

        closestPoints.sort((a, b) => {
          const distA = getEuclideanDistance(a, event.point);
          const distB = getEuclideanDistance(b, event.point);
          return distA - distB;
        });

        const distance = closestPoints[0].getDistance(event.point);
        // find the closest point on the line from event.point (current mouse point)
        if (distance < closestDistance) {
          closestDistance = distance;
          closestPoint.position = closestPoints[0];
          snappedEdge = overlappedEdge.edge;
        }
      });
      closestPoint.visible = true;
    } else {
      clearSnap();
    }
  };
  const clearSnap = () => {
    snappedEdge = null;
    closestPoint.visible = false;
  };
  window.addEventListener(PaperEvType.CHANGE_TOOL, () => {
    c.visible = false;
    snapMode = true;
  });
  const onMouseUp = (e: paper.ToolEvent) => {
    if (dragging) {
      dragging = false;
    } else {
      const {
        activeMiscItem,
        backflowProducts,
        mainPipeType,
        mainPipeSizes,
        prvProducts,
        elementCache,
      } = getState();
      const element: DesignElement = {
        ...defaultItem({ x: e.point.x, y: e.point.y }),
        type: 'miscItem',
        props: {
          name: activeMiscItem.name,
          displayName: activeMiscItem.name,
        } as IMiscItem,
        itemType: 'design-element',
      };
      const minMainSize = Math.min(...mainPipeSizes);
      switch (activeMiscItem.name) {
        case 'Meter':
          element.type = 'meter';
          element.props = {
            name: 'Meter',
            meterSize: 1,
            serviceLine: mainPipeType,
            serviceLineSize: minMainSize,
            serviceLineLength: 0,
            serviceElevationChange: 0,
            gpmPercentage: 100,
          } as IMeter;
          break;
        case 'Pump':
          element.type = 'pump';
          element.props = {
            name: 'Pump',
            outputPSI: 0,
            outputGPM: 0,
          } as IPump;
          break;
        case 'Booster Pump':
          console.log('create booster');
          element.type = 'booster pump';
          element.props = {
            name: 'Booster Pump',
            outputPSI: 0,
            outputGPM: 0,
          } as IPump;
          break;
        case 'Point of Connection':
          element.type = 'poc';
          element.props = {
            name: 'Point Of Connection',
            staticPSI: 0,
            mainsToSource: [],
          } as IPoC;
          break;
        case 'Backflow':
          element.type = 'backflow';
          element.props = backflowProducts[0];
          break;
        case 'Pressure Regulating Valve':
          element.type = 'prv';
          element.props = prvProducts[0];
          break;
        case 'Drip Transition':
          element.props = {
            ...element.props,
            gpmEffect: 1,
          };
          break;
        default:
          element.type = 'miscItem';
      }

      if (snapMode && snappedEdge) {
        const snappedPoint = closestPoint.position;
        element.position.x = snappedPoint.x;
        element.position.y = snappedPoint.y;
        addItemToPipe(snappedEdge, element, elementCache);
      } else if (
        e.item?.name === ITEMNAMES.PIPE &&
        isEdgeHopData(e.item.data)
      ) {
        const data = e.item.data;
        if (isEdgeHopData(data)) {
          const clickedEdge = getState().edges.find(
            (edge) => edge.uuid === data.edgeUUID,
          );
          if (clickedEdge) {
            addItemToPipe(clickedEdge, element, elementCache);
          }
        }
      } else {
        addElementsEdges([element], []);
      }
    }
    clearSnap();
  };
  const onKeyDown = (e: paper.KeyEvent) => {
    if (e.key === 'space') {
      e.preventDefault();
      return;
    }
    if (e.key === 's') {
      if (snapMode) {
        clearSnap();
      }
      snapMode = !snapMode;
    }
  };

  const onMouseMove = (e: paper.ToolEvent) => {
    if (dragging) {
      dragging = false;
    } else {
      if (snapMode) {
        updateOverlapper(e);
      }
      c.visible = true;
      c.position = e.point;
      c.strokeWidth = 0.1;
      c.strokeColor = getColor('#21ba45');
    }
  };

  toolService
    .keyDown$('Misc Item')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onKeyDown);
  toolService
    .mouseMove$('Misc Item')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseMove);
  toolService
    .mouseUp$('Misc Item')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseUp);
};
