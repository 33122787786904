import {
  DesignElement,
  DesignElementMap,
  IEdge,
  IPoint,
  Zone,
} from '@shared-types';
import { compact, uniq } from 'underscore';
import { findEdge } from './directedGraph';
import { generateSections, pointsEqual, sortZones } from './geometry.helpers';

import * as isect from 'isect';
import { IPOCDirectedGraph } from '../../../../../shared-types/pocDirectedGraph.helper';
import { getEuclideanDistance } from '../shared/geometry';
import { pocGraphByNode } from '../state';

interface Intersection {
  from: IPoint;
  to: IPoint;
  source: string;
  target: string;
}

const setNewEdges = (
  edges: IEdge[],
  elementCache: DesignElementMap,
  pocGraphs: IPOCDirectedGraph[],
): IEdge[] =>
  edges.map((edge) => {
    let isDrip = false;
    let isLateral = true;
    const start = elementCache[edge.source];
    const end = elementCache[edge.target];
    const dg = pocGraphByNode(edge.source, pocGraphs);
    if (dg) {
      isLateral = dg.nodeIsLateral(edge.source);
      isDrip = dg.nodeIsDrip(edge.source);
    }
    if (start && end) {
      return {
        ...edge,
        isLateral,
        isDrip,
        hoppedSections: [
          {
            type: 'lateral',
            values: {
              start: { x: start.position.x, y: start.position.y },
              end: { x: end.position.x, y: end.position.y },
            },
          },
        ],
      };
    } else {
      return { ...edge, isLateral, isDrip };
    }
  });

const getIntersections = (
  segments: Intersection[],
): { point: IPoint; segments: Intersection[] }[] => {
  return isect['bush'](segments, {}).run();
};
const edgeToIntersection = (
  edge: IEdge,
  _elements: DesignElement[],
  elementCache: DesignElementMap,
): Intersection => {
  const src = elementCache[edge.source];
  const trg = elementCache[edge.target];
  if (src && trg) {
    return {
      from: { x: src.position.x, y: src.position.y },
      to: { x: trg.position.x, y: trg.position.y },
      source: edge.source,
      target: edge.target,
    };
  } else {
    // console.error('could not find a matching element', src, trg, edge);
    throw new Error('could not find a matching element');
    return {
      from: { x: 0, y: 0 },
      to: { x: 0, y: 0 },
      source: edge.source,
      target: edge.target,
    };
  }
};

export const hopEdges = (
  zones: Zone[],
  elements: DesignElement[],
  elementCache: DesignElementMap,
  newEdges: IEdge[],
  scale: number,
  pocGraphs: IPOCDirectedGraph[],
) => {
  const segments = newEdges.map((edge) =>
    edgeToIntersection(edge, elements, elementCache),
  );
  const uniqueIntersections = getUniqueIntersections(segments);
  const filtered = filterIntersections(uniqueIntersections);
  let edges = [...newEdges];

  const [sprayZones, dripZones] = sortAndReverseZones(zones);
  const dripSortedZones = [...dripZones, ...sprayZones];

  const pointSet = new Set<string>();
  const filterFn = createFilterFn(pointSet);

  dripSortedZones.forEach((zone) => {
    const dg = pocGraphByNode(zone.valve, pocGraphs);
    if (dg) {
      const subtreeEdges = getSubtreeEdges(dg, zone.valve, edges);
      for (const edgeToHop of subtreeEdges) {
        const actualIntersections = getActualIntersections(
          filtered,
          filterFn,
          edgeToHop,
          pointSet,
        );
        if (actualIntersections.length) {
          edges = updateEdgesWithHoppedSections(
            edges,
            edgeToHop,
            actualIntersections,
            scale,
          );
        }
      }
    }
  });

  return edges;
};
const getUniqueIntersections = (
  segments: Intersection[],
): {
  point: IPoint;
  segments: Intersection[];
}[] => {
  const allIntersections = getIntersections(segments);
  return uniq(allIntersections, false, (int1) =>
    [int1.point.x, int1.point.y].join(),
  );
};

const filterIntersections = (
  uniqueIntersections: {
    point: IPoint;
    segments: Intersection[];
  }[],
): {
  point: IPoint;
  segments: Intersection[];
}[] => {
  return uniqueIntersections.filter(
    (int) =>
      int.segments[0].source !== int.segments[1].source &&
      int.segments[0].source !== int.segments[1].target &&
      int.segments[0].target !== int.segments[1].target &&
      int.segments[0].target !== int.segments[1].source,
  );
};

const sortAndReverseZones = (zones: Zone[]): [Zone[], Zone[]] => {
  const sprayZones = sortZones(
    zones.filter((z) => !z.isDrip && !z.plantIds.length),
  ).reverse();

  const dripZones = sortZones(
    zones.filter((z) => z.isDrip || z.plantIds.length),
  ).reverse();

  return [sprayZones, dripZones];
};

const createFilterFn = (pointSet: Set<string>) => {
  return (
    intersection: {
      point: IPoint;
      segments: Intersection[];
    },
    edgeToHop: IEdge,
  ) => {
    return (
      !pointSet.has(`${intersection.point.x}:${intersection.point.y}`) &&
      intersection.segments.some(
        (seg) =>
          seg.source === edgeToHop.source &&
          intersection.segments.some((seg) => seg.target === edgeToHop.target),
      ) &&
      !pointsEqual(intersection.point, intersection.segments[0].from) &&
      !pointsEqual(intersection.point, intersection.segments[0].to) &&
      !pointsEqual(intersection.point, intersection.segments[1].from) &&
      !pointsEqual(intersection.point, intersection.segments[1].to)
    );
  };
};
const getSubtreeEdges = (
  dg: IPOCDirectedGraph,
  valveUUID: string,
  edges: IEdge[],
) => {
  // console.time('getSubtreeEdges')
  const e = compact(
    dg.getDownstreamEdges(valveUUID).map((e) => findEdge(edges, e[0], e[1])),
  );
  // console.timeEnd('getSubtreeEdges')
  return e;
};

const getActualIntersections = (
  filtered: {
    point: IPoint;
    segments: Intersection[];
  }[],
  filterFn,
  edgeToHop: IEdge,
  pointSet: Set<string>,
) => {
  return filtered.filter((intersection) => {
    const does = filterFn(intersection, edgeToHop);
    if (does) {
      pointSet.add(`${intersection.point.x}:${intersection.point.y}`);
    }
    return does;
  });
};

const updateEdgesWithHoppedSections = (
  edges: IEdge[],
  edgeToHop: IEdge,
  actualIntersections,
  scale: number,
) => {
  const sampleIntersection = actualIntersections[0].segments.find(
    (seg) => seg.source === edgeToHop.source && seg.target === edgeToHop.target,
  );

  if (sampleIntersection) {
    const sortedIntersections = actualIntersections.sort(
      (a, b) =>
        getEuclideanDistance(a.point, sampleIntersection.from) -
        getEuclideanDistance(b.point, sampleIntersection.from),
    );
    const sections = generateSections(
      { start: sampleIntersection.from, end: sampleIntersection.to },
      sortedIntersections.map((int) => int.point),
      scale,
    );

    return edges.map((e) => ({
      ...e,
      hoppedSections:
        e.source === edgeToHop.source && e.target === edgeToHop.target
          ? sections
          : e.hoppedSections,
    }));
  }
  return edges;
};

export const generateHoppedPipedZones = (
  zones: Zone[],
  edges: IEdge[],
  elements: DesignElement[],
  elementCache: DesignElementMap,
  scale: number,
  pocGraphs: IPOCDirectedGraph[],
): IEdge[] => {
  // setNewEdges is super fast
  let newEdges: IEdge[] = setNewEdges(edges, elementCache, pocGraphs);
  if (newEdges.length) {
    // hop Edges not so much
    newEdges = hopEdges(
      zones,
      elements,
      elementCache,
      newEdges,
      scale,
      pocGraphs,
    );
  }
  return newEdges;
};
export const hopPipes = (
  zones: Zone[],
  edges: IEdge[],
  elements: DesignElement[],
  elementCache: DesignElementMap,
  scale: number,
  pocGraphs: IPOCDirectedGraph[],
): IEdge[] =>
  generateHoppedPipedZones(
    zones,
    edges,
    elements,
    elementCache,
    scale,
    pocGraphs,
  );
