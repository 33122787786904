import { useEffect, useState } from 'react';
import { Button, Menu, Radio } from 'semantic-ui-react';
import styled from 'styled-components';
import { getAvailableRadii } from '../features/heads/head-filling';
import { GridType } from '../shared/workbench-enums';
import { useDesignStore } from '../state';
import { PanelWrap } from './PanelButton';
import { growingGrid } from './Tester/growingGrid';

export const GridToolProperties = () => {
  const sprinklerBases = useDesignStore((state) => state.sprinklerBases);
  const [gridType, setGridType] = useState(growingGrid.type);
  const [gridRadius, setGridRadius] = useState(growingGrid.radius);
  const [radii, setRadii] = useState<number[]>([]);

  useEffect(() => {
    const r = getAvailableRadii(sprinklerBases);
    setRadii(r);
  }, [sprinklerBases]);

  return (
    <Wrap>
      <h5>Grid Tool</h5>
      {/* use semantic ui to render radio button for grid type */}
      <Radio
        label="Square"
        name="grid"
        value="square"
        checked={gridType === 'square'}
        onChange={() => {
          setGridType(GridType.SQUARE);
          growingGrid.setType(GridType.SQUARE);
        }}
      />
      <br />
      <Radio
        label="Triangle"
        name="grid"
        value="triangle"
        checked={gridType === 'triangle'}
        onChange={() => {
          setGridType(GridType.TRIANGLE);
          growingGrid.setType(GridType.TRIANGLE);
        }}
      />
      {/* semanticu ui select menu to get all the radii and select it */}
      <br />
      <Menu vertical>
        <Menu.Item header>Grid Radius</Menu.Item>
        {radii.slice(0, 5).map((r) => (
          <Menu.Item
            key={r}
            active={gridRadius === r}
            onClick={() => {
              setGridRadius(r);
              growingGrid.setRadius(r);
            }}
            content={r}
          />
        ))}
      </Menu>
      <Button onClick={() => growingGrid.applyGrid()}>Apply</Button>
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)``;
