import { styled } from 'styled-components';
import { isSheet } from '../helpers';
import { setScale, useDesignStore } from '../state';
import { deleteItem } from '../state/item-state';
import { scales } from '../upload/upload.helpers';
import { PanelButton, PanelSelect, PanelWrap } from './PanelButton';

export const SheetsPanel = () => {
  const sheets = useDesignStore((state) => state.items.filter(isSheet));
  const scale = useDesignStore((state) => state.scale);

  const doScaleChange = (_e: any, { value }: any) => {
    setScale(value);
  };

  const resetScale = () => {
    sheets.forEach((sheet) => deleteItem(sheet.uuid));
  };
  return (
    <Wrap>
      <h5>Sheets</h5>
      This design has {sheets.length} sheets
      <br />
      <br />
      {!!sheets.length && (
        <PanelButton onClick={resetScale}>
          Delete Sheets & Change Scale
        </PanelButton>
      )}
      {!sheets.length && (
        <PanelSelect
          label="Sheet Scale"
          onChange={doScaleChange}
          value={scale}
          options={scales.map((s) => ({
            text: s.label,
            value: s.value,
          }))}
        />
      )}
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)`
  .sheet-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #4e4e4e;
  }
`;
