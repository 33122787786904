export const setLocalIsMouse = (payload: boolean) => {
  try {
    sessionStorage.setItem('isMouse', payload.toString());
  } catch (e) {
    console.error(e);
  }
};

export const getLocalIsMouse = () => {
  try {
    return sessionStorage.getItem('isMouse') === 'true';
  } catch (e) {
    console.error(e);
    return true;
  }
};
