import {
  APIPhoenixRequest,
  DesignElement,
  HeadNozzleJoin,
  HeadProduct,
  IBackflowProduct,
  IDesign,
  IEdge,
  IFlowKitProduct,
  IPipeProduct,
  IPoint,
  IPRV,
  IPumpProduct,
  IValveProduct,
  NozzleData,
  Sprinkler,
  Yard,
  Zone,
} from '@shared-types';
import { wrapFetch } from '../../../services/fetchHelpers';
import { AlgoVersion } from '../components/PlotMap';
import { StoreState } from '../state';

export const prvProducts: IPRV[] = [
  // TODO: fill this in with more PRV products
  {
    name: 'Watts 1" LFN55B',
    outputPSI: 25,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1" LFN55B',
    outputPSI: 50,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1" LFN55B',
    outputPSI: 75,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1 1/4" LFN55B',
    outputPSI: 25,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1 1/4" LFN55B',
    outputPSI: 50,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1 1/4" LFN55B',
    outputPSI: 75,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1 1/2" LFN55B',
    outputPSI: 25,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1 1/2" LFN55B',
    outputPSI: 30,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1 1/2" LFN55B',
    outputPSI: 40,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1 1/2" LFN55B',
    outputPSI: 50,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1 1/2" LFN55B',
    outputPSI: 60,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1 1/2" LFN55B',
    outputPSI: 70,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
  {
    name: 'Watts 1 1/2" LFN55B',
    outputPSI: 75,
    maxInputPSI: 400,
    minOutputPSI: 25,
    maxOutputPSI: 75,
  },
];

export const fetchNozzles = () =>
  Promise.all([
    wrapFetch<{ heads: HeadProduct[]; brands: string[] }>(`/catalog/heads`),
    wrapFetch<{ nozzles: NozzleData[]; headNozzles: HeadNozzleJoin[] }>(
      `/catalog/nozzles`,
    ),
  ]);

export const fetchValves = (): Promise<{ valves: IValveProduct[] }> =>
  wrapFetch(`/catalog/valves`);

export const fetchPipes = (): Promise<{ pipes: IPipeProduct[] }> =>
  wrapFetch(`/catalog/pipes`);

export const fetchBackflows = (): Promise<{ backflows: IBackflowProduct[] }> =>
  wrapFetch(`/catalog/backflows`);
export const fetchFlowKits = (): Promise<{ flowKits: IFlowKitProduct[] }> =>
  wrapFetch(`/catalog/flow-kits`);
export const fetchPumps = (): Promise<{ pumps: IPumpProduct[] }> =>
  wrapFetch(`/catalog/pumps`);

export const getDesign = (id: number): Promise<{ design: any }> =>
  wrapFetch(`/api/designs/${id}`);

export const newDesign = (
  title: string,
  projectId: number,
  data?: IDesign,
): Promise<{ design: any }> =>
  wrapFetch(`/api/designs`, 'POST', { title, projectId, data });

export const saveDesign = (
  data: Partial<StoreState>,
  id: number,
): Promise<{ message: string; updatedAt: string }> =>
  wrapFetch(`/api/designs/${id}`, 'PUT', { data });

export const copyDesign = (id: number): Promise<any> =>
  wrapFetch(`/api/designs/copy/${id}`, 'PUT');

export const deleteDesign = (designId: number): Promise<any> =>
  wrapFetch(`/api/designs/${designId}`, 'DELETE');

export const postWholeEdge = (
  yards: Yard[],
  nozzleData: NozzleData[],
  cacheID: string,
  keyPointIndex?: number,
): Promise<any> =>
  wrapFetch(`/algorithm/whole-edge`, 'POST', {
    yards,
    nozzleData,
    keyPointIndex,
    cacheID,
    coverageThreshold: 0.95,
  });

// export const postCleanupEdge = (
//   yard: Yard,
//   elements: DesignElement[],
// ): Promise<any> =>
//   wrapFetch(`/algorithm/edge-cleanup`, 'POST', { yard, elements });

export const postLonelyCenters = (
  yards: Yard[],
  nozzleData: NozzleData[],
  currentSprinklers: Sprinkler[],
): Promise<any> =>
  wrapFetch(`/algorithm/center/lonely`, 'POST', {
    yards,
    nozzleData,
    currentSprinklers,
  });

// export const postConcentricCenters = (
//   yards: Yard[],
//   nozzleData: NozzleData[],
//   currentSprinklers: Sprinkler[],
// ): Promise<{ edgeShells: SprinklerCustomProps[] }> =>
//   wrapFetch(`/algorithm/center/concentric`, 'POST', {
//     yards,
//     nozzleData,
//     currentSprinklers,
//   });

export const postHeadsPhoenix = (
  request: APIPhoenixRequest,
): Promise<{ result: Zone[] }> =>
  wrapFetch(`/algorithm/phoenix`, 'POST', request);

// export const postPlantsPhoenix = (request: any): Promise<{ result: Zone[] }> =>
//   wrapFetch(`/algorithm/phoenix-plants`, 'POST', request);

// export const postAutoDT = (
//   beds: Bed[],
//   plants: IPlant[],
//   scale: number,
// ): Promise<{ result: IPoint[][] }> =>
//   wrapFetch(`/algorithm/auto-dt`, 'POST', { beds, plants, scale });

// export const postZonesMinotaur = (zones: Zone[]): Promise<any> =>
//   wrapFetch(`/algorithm/minotaur`, 'POST', {
//     zones,
//     valveLocation: { x: 0, y: 0 },
//   });

export const postMinotaurSingle = (
  yards: Yard[],
  elements: DesignElement[],
  type: string,
  slopeAngle: number,
): Promise<any> =>
  wrapFetch(`/algorithm/minotaur-single`, 'POST', {
    yards,
    elements,
    type,
    slopeAngle,
  });

// export const postZoneBalancing = (
//   elements: DesignElement[],
//   goalGPM: number,
//   edges: IEdge[],
// ): Promise<any> =>
//   wrapFetch(`/algorithm/zone-balancer`, 'POST', { edges, elements, goalGPM });

// export const postBalancerV1 = (
//   poly: IPoint[],
//   elements: DesignElement[],
//   goalGPM: number,
//   protectedEdges?: IEdge[],
// ): Promise<any> =>
//   wrapFetch(`/algorithm/balanceV1`, 'POST', {
//     elements,
//     goalGPM,
//     poly,
//     protectedEdges,
//   });

// export const pipeValves = (
//   zones: Zone[],
//   heads: Sprinkler[],
//   valveBoxes: ValveBox[],
//   yards: Yard[]
// ): Promise<{ pipes: LineSegment[][][] }> =>
//   wrapFetch(`/algorithm/valve-piping`, 'POST', {
//     zones,
//     heads,
//     valveBoxes,
//     yards,
//   })

export const postMST = (
  elements: DesignElement[],
): Promise<{ edges: IEdge[] }> =>
  wrapFetch(`/algorithm/mst`, 'POST', {
    elements,
  });

// export const getDesignHistory = (id: number): Promise<{ keys: string[] }> =>
//   wrapFetch(`/api/designs/history/${id}`, 'GET');

// export const restoreHistoryLog = (log: string): Promise<{ design: any }> =>
//   wrapFetch(`/api/designs/restore/${log}`, 'GET');

// export const zoneCost = ({
//   nodes,
//   edges,
//   obstacles,
//   yards,
//   beds,
// }: {
//   nodes: GraphNode[];
//   edges: { source: string; target: string; diameter: number }[];
//   obstacles: IPoint[][];
//   yards: IPoint[][];
//   beds: IPoint[][];
// }): Promise<{ edges: IEdge[] }> =>
//   // wrapFetch(`/`, 'POST', {
//   //   nodes,
//   //   edges,
//   // })
//   fetch(`http://localhost:8080/cost/pipe`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ nodes, edges, obstacles, yards, beds }),
//   }).then((res: Response) => res.json());

export const pipingAlgo = (
  // TODO: change piping algo to return a DesignElement that
  // has the new "position" attr
  {
    nodes,
    obstacles,
    yards,
    edges,
    beds,
    root,
    isPVC,
  }: {
    nodes: DesignElement[];
    obstacles: {
      type: 'valvebox' | 'plant';
      points: IPoint[];
    }[];
    yards: IPoint[][];
    edges: { start: IPoint; end: IPoint }[];
    beds: IPoint[][];
    root: string;
    isPVC: boolean;
  },
  version: string,
  pruneRoot: boolean,
): Promise<{
  edges: { start: string; end: string; diameter: number }[];
  newNodes: { id: string; x: number; y: number }[];
}> =>
  wrapFetch(`/algorithm/pipe`, 'post', {
    nodes,
    yards,
    obstacles,
    edges,
    beds,
    root,
    isPVC,
    version,
    pruneRoot,
  });

export const getPipeAlgoVersions = (): Promise<AlgoVersion[]> =>
  wrapFetch('/algorithm/pipe-versions');
