import paper from 'src/paper';

import { IPoint, Rectangle } from '@shared-types';
import { applyAlpha } from '../helpers';
import { getColor } from '../paper-helpers/plot.helpers';
import { getState } from '../state';
import { PaperItem } from '../tools/paper-items/paper-item';
import { rectangleService } from './rectangle.service';

export enum RECTANGLE {
  RECTANGLE = 'child-rectangle',
}
export class PaperRectangle extends PaperItem<Rectangle> {
  private _group = new paper.Group();
  private _item = rectangleService.createItem();

  get group() {
    return this._group;
  }
  get paperItem() {
    return this._group.getItem({ name: RECTANGLE.RECTANGLE });
  }
  get highlight() {
    return this._group.getItem({ name: 'highlight' });
  }

  constructor(item: Rectangle) {
    super();
    this.group.name = item.itemType;
    this.group.data = { uuid: item.uuid };
    this.create(item);
  }
  getRealItem(): Rectangle {
    return this._item;
  }
  create(item: Rectangle) {
    this.group.removeChildren();
    const r = this.createRectangle(item);
    this.group.addChild(r);
    if (item.locked) {
      this.group.locked = true;
    }
    if (!item.visible) {
      this.group.visible = false;
    }
    this._item = item;
  }
  createRectangle(item: Rectangle) {
    const scale = getState().scale;
    const rect = new paper.Rectangle(item.position, {
      width: item.width,
      height: item.height,
    });
    const r = new paper.Path.Rectangle(rect);
    r.strokeWidth = (item.style.strokeWidth || 1) / scale;
    const newFillColor = applyAlpha(item.style.fillColor, item.style.fillAlpha);
    r.fillColor = newFillColor;
    const newStrokeColor = applyAlpha(
      item.style.strokeColor,
      item.style.strokeAlpha,
    );
    if (item.rotation) {
      r.rotate(item.rotation, item.position);
    }
    r.strokeColor = newStrokeColor;
    r.name = RECTANGLE.RECTANGLE;
    return r;
  }
  update(item: Rectangle) {
    const scale = getState().scale;
    const selected = !!this.highlight;
    if (
      item.width !== this._item.width ||
      item.height !== this._item.height ||
      item.rotation !== this._item.rotation
    ) {
      this.group.removeChildren();
      const newRectangle = this.createRectangle(item);
      this.group.addChild(newRectangle);
    }
    if (
      item.style.fillColor !== this._item.style.fillColor ||
      item.style.fillAlpha !== this._item.style.fillAlpha
    ) {
      if (!item.style.fillColor) {
        this.paperItem.fillColor = null;
      } else {
        const newColor = applyAlpha(item.style.fillColor, item.style.fillAlpha);
        this.paperItem.fillColor = newColor;
      }
    }
    const newColor = applyAlpha(item.style.strokeColor, item.style.strokeAlpha);
    this.paperItem.strokeColor = newColor;
    this.paperItem.strokeWidth = (item.style.strokeWidth || 1) / scale;
    this.paperItem.opacity =
      item.style.opacity !== undefined ? item.style.opacity : 1;
    this.paperItem.dashArray = (item.style.dashArray || []).map(
      (d) => d / scale,
    );
    this.paperItem.dashOffset = (item.style.dashOffset || 0) / scale;
    this.paperItem.strokeCap = item.style.strokeCap || 'butt';
    this.paperItem.strokeJoin = item.style.strokeJoin || 'miter';

    if (
      item.position.x !== this._item.position.x ||
      item.position.y !== this._item.position.y
    ) {
      this.setPivot(item.position);
      this.setPosition(item.position);
    }
    if (item.locked !== this._item.locked) {
      this.group.locked = item.locked;
    }
    if (item.visible !== this._item.visible) {
      this.group.visible = item.visible;
    }
    this._item = item;
    this.toggleHighlight(selected);
  }
  setSelectedStatus(selected: boolean) {
    if (this.highlight) {
      this.highlight.remove();
    }
    if (selected) {
      const highlight = this.paperItem.clone();
      highlight.strokeWidth = 0.2;
      highlight.dashArray = [];
      highlight.strokeColor = getColor('#94baf7');
      highlight.name = 'highlight';
      highlight.fillColor = null;
      highlight.locked = true;
      this.group.addChild(highlight);
    }
  }
  destroy() {
    this.group.remove();
  }
  getItem() {
    return this.group;
  }
  setPosition(point: IPoint): void {
    this.group.position = new paper.Point(point);
  }
  setPivot(point: IPoint): void {
    this.group.pivot = new paper.Point(point);
  }
  // getRotation(): number {
  //   return this._item.rotation;
  // }
  // scaleItem(pivot: IPoint, scale: number): void {
  //   this.group.scale(scale, pivot);
  // }
  toggleHighlight(show: boolean): void {
    this.setSelectedStatus(show);
  }
}
