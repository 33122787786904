import React from 'react';
import { ISleeve } from '../../../../../../shared-types';
import { deleteSleeve } from '../../features/sleeves/state/deleteSleeves';
import { isSleeve } from '../../helpers';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { selectAndCenterSingleID, useDesignStore } from '../../state';
import { updateItem } from '../../state/item-state';
import { LayerChild } from './LayerChild';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const SleevesLayer = ({ open, onOpen }) => {
  const sleeves = useDesignStore((state) => state.items.filter(isSleeve));
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.SLEEVES,
  );

  const toggleChildVisibility = (item: ISleeve) => {
    updateItem({ ...item, visible: !item.visible }, true);
  };

  const toggleChildLock = (item: ISleeve) => {
    updateItem({ ...item, locked: !item.locked }, true);
  };
  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Sleeves (${sleeves.length})`}
      open={open}
      onOpen={onOpen}
      actions={<></>}
      childLayer={sleeves.map((sleeve, i) => (
        <LayerChild
          key={sleeve.uuid}
          visible={sleeve.visible}
          locked={sleeve.locked}
          title={`Sleeve ${i}`}
          clickToggle={() => toggleChildVisibility(sleeve)}
          clickLock={() => toggleChildLock(sleeve)}
          clickDelete={() => deleteSleeve(sleeve.uuid)}
          layerLocked={layerLocked}
          clickItem={() => selectAndCenterSingleID(sleeve.uuid)}
          layerVisible={layerVisible}
        />
      ))}
    />
  );
};

export default React.memo(SleevesLayer);
