import {
  HeadNozzleJoin,
  HeadProduct,
  NozzleData,
  SprinklerBase,
} from '@shared-types';
import * as math from 'mathjs';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  Breadcrumb,
  Divider,
  DropdownProps,
  Form,
  Grid,
  Icon,
  Label,
  List,
  Message,
  Select,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { uniq } from 'underscore';
import { newUUID } from '../../crypto-uuid';
import { hardCodedBaseOptions } from '../../helpers/head.helpers';
import { LocalNozzleData } from '../../helpers/nozzleTransformer';
import { addSprinklerBase, getState, useDesignStore } from '../../state';
import { Warn } from '../alerts/AlertList';

interface BaseConfig {
  inputPSI: number;
  brand: string;
  headSeries: string;
  headSize: string;
  headModel: string;
  outputPSI: number;
  nozzleSeries: string;
  nozzleGroupname: string;
}

const blankConfig = {
  inputPSI: 0,
  brand: '',
  headSeries: '',
  headSize: '',
  headModel: '',
  outputPSI: 0,
  nozzleSeries: '',
  nozzleGroupname: '',
};

const getNozzleSeriesNames = (
  config: BaseConfig,
  headNozzles: HeadNozzleJoin[],
): string[] =>
  uniq(
    headNozzles
      .filter((h: HeadNozzleJoin) => h.headSeries.includes(config.headSeries))
      .map((h) => h.nozzleSeries),
  );

const getHeadbyModel = (m: string, heads: HeadProduct[]): HeadProduct | null =>
  heads.find((h) => h.model === m) || null;

const getBrandPSIRange = (brand: string, heads: HeadProduct[]): number[] => {
  const brandHeads = heads.filter((h) => h.brand === brand);
  const brandMinPSI = Math.min(...brandHeads.map((h) => h.minPSI));
  const brandMaxPSI = Math.max(...brandHeads.map((h) => h.maxPSI));
  return [brandMinPSI, brandMaxPSI];
};
const getBrandSeriesPSIRange = (
  brand: string,
  series: string,
  heads: HeadProduct[],
): number[] => {
  const brandHeads = heads.filter(
    (h) => h.brand === brand && h.series === series,
  );
  const brandMinPSI = Math.min(...brandHeads.map((h) => h.minPSI));
  const brandMaxPSI = Math.max(...brandHeads.map((h) => h.maxPSI));
  return [brandMinPSI, brandMaxPSI];
};

const getNozzleSeriesPSIRange = (series: string, nozzles: NozzleData[]) => {
  const seriesNozzles = nozzles.filter((n) => n.series === series);
  const seriesMinPSI = Math.min(...seriesNozzles.map((h) => h.psi));
  const seriesMaxPSI = Math.max(...seriesNozzles.map((h) => h.psi));
  return [seriesMinPSI, seriesMaxPSI];
};
const getNozzleGroupPSIRange = (
  series: string,
  groupName: string,
  nozzles: NozzleData[],
) => {
  const groupNozzles = nozzles.filter(
    (n) => n.series === series && n.groupName === groupName,
  );
  const seriesMinPSI = Math.min(...groupNozzles.map((h) => h.psi));
  const seriesMaxPSI = Math.max(...groupNozzles.map((h) => h.psi));
  return [seriesMinPSI, seriesMaxPSI];
};
const getUniqueHeadSeries = (
  heads: HeadProduct[],
  config: BaseConfig,
): HeadProduct[] =>
  uniq(filterHeads(heads, config), false, (h) => h.series).sort(
    (a: HeadProduct, b: HeadProduct) => (a.series > b.series ? 1 : -1),
  );

const getUniqueHeadSizes = (
  heads: HeadProduct[],
  config: BaseConfig,
): string[] => uniq(filterHeads(heads, config).map((h) => h.size)).sort();

const getUniqueHeadBrands = (
  heads: HeadProduct[],
  config: BaseConfig,
): string[] => uniq(filterHeads(heads, config).map((h) => h.brand)).sort();

const getUniqueNozzleGroupnames = (
  nozzles: NozzleData[],
  config: BaseConfig,
): string[] =>
  uniq(
    nozzles
      .filter((h) => h.series === config.nozzleSeries)
      .map((h) => h.groupName),
  ).sort();

const getUniqueNozzleModels = (
  nozzles: NozzleData[],
  c: BaseConfig,
): string[] =>
  uniq(
    nozzles
      .filter(
        (h) => h.series === c.nozzleSeries && h.groupName === c.nozzleGroupname,
      )
      .map((h) => h.name),
  ).sort();

const BrandListItem = ({
  inputPSI,
  brand,
  onSelect,
  heads,
}: {
  inputPSI: number;
  brand: string;
  onSelect: any;
  heads: HeadProduct[];
}) => {
  const [low, high] = getBrandPSIRange(brand, heads);
  const badPSI = inputPSI < low || inputPSI > high;
  return (
    <List.Item>
      <List.Content as="a" disabled={true} onClick={onSelect.bind(null, brand)}>
        {badPSI && (
          <Label color="red" horizontal={true}>
            bad psi
          </Label>
        )}
        {brand}
      </List.Content>
      <List.Content floated="right">
        {low} - {high} PSI
      </List.Content>
    </List.Item>
  );
};

const HeadSeriesListItem = ({
  head,
  onSelect,
  heads,
  config,
}: {
  head: HeadProduct;
  onSelect: any;
  config: BaseConfig;
  heads: HeadProduct[];
}) => {
  const [low, high] = getBrandSeriesPSIRange(config.brand, head.series, heads);
  const badPSI = config.inputPSI < low || config.inputPSI > high;
  return (
    <List.Item>
      <List.Content as="a" onClick={onSelect.bind(null, head.series)}>
        {badPSI && (
          <Label color="red" horizontal={true}>
            bad psi
          </Label>
        )}
        {head.series}
      </List.Content>
      <List.Content floated="right">
        {low} - {high} PSI
      </List.Content>
    </List.Item>
  );
};

const filterHeads = (
  heads: HeadProduct[],
  config: BaseConfig,
): HeadProduct[] => {
  const isBrand = (head: HeadProduct) =>
    !config.brand || config.brand === head.brand;
  const isSeries = (head: HeadProduct) =>
    !config.headSeries || config.headSeries === head.series;
  const isSize = (head: HeadProduct) =>
    !config.headSize || config.headSize === head.size;
  const isPSI = (head: HeadProduct) =>
    config.inputPSI === 0 ||
    (config.inputPSI <= head.maxPSI &&
      config.inputPSI >= head.minPSI &&
      (!head.regulatedPSI ||
        (head.regulatedPSI && config.inputPSI <= head.regulatedPSI) ||
        (head.regulatedPSI && config.inputPSI >= head.regulatedPSI)));
  return heads.filter(isBrand).filter(isSeries).filter(isSize).filter(isPSI);
};

const NozzleSeriesListItem = ({
  series,
  onSelect,
  psi,
  nozzles,
}: {
  series: string;
  onSelect: any;
  psi: number;
  nozzles: NozzleData[];
}) => {
  const [low, high] = getNozzleSeriesPSIRange(series, nozzles);
  const badPSI = psi < low || psi > high;
  return (
    <List.Item>
      <List.Content as="a" onClick={onSelect.bind(null, series)}>
        {badPSI && (
          <Label color="red" horizontal={true}>
            bad psi
          </Label>
        )}{' '}
        {series}
      </List.Content>
      <List.Content floated="right">
        {low} - {high} PSI
      </List.Content>
    </List.Item>
  );
};

const NozzleGroupListItem = ({
  groupName,
  series,
  onSelect,
  psi,
  config,
  nozzles,
}: {
  groupName: string;
  series: string;
  onSelect: any;
  psi: number;
  config: BaseConfig;
  nozzles: NozzleData[];
}) => {
  const sprinklerBases = useDesignStore((state) => state.sprinklerBases);
  const [low, high] = getNozzleGroupPSIRange(series, groupName, nozzles);
  const badPSI = psi < low || psi > high;
  return (
    <div key={groupName}>
      <Grid divided={true} columns={2}>
        <Grid.Column width={13}>
          {badPSI && (
            <Label color="red" horizontal={true}>
              bad psi
            </Label>
          )}{' '}
          <strong>{groupName}</strong>
          <div>
            {getUniqueNozzleModels(nozzles, {
              ...config,
              nozzleGroupname: groupName,
            }).map((noz: string) => (
              <div key={noz}>
                <div>
                  {sprinklerBases.find((b) => {
                    return (
                      b.nozzleModel === noz &&
                      b.nozzleSeries === config.nozzleSeries &&
                      config.headModel === b.headModel &&
                      b.headSeries === config.headSeries
                    );
                  }) ? (
                    <>
                      <Icon name="check" /> {noz}
                    </>
                  ) : (
                    <>
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          onSelect(noz, {
                            ...config,
                            nozzleGroupname: groupName,
                          })
                        }
                      >
                        Add -
                      </a>{' '}
                      {noz}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </Grid.Column>
        <Grid.Column width={3}>
          {low} - {high} PSI
        </Grid.Column>
      </Grid>
      <Divider />
    </div>
  );
};

export const SelectHead = () => {
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const [config, setConfig] = useState<BaseConfig>({
    ...blankConfig,
  });
  const [heads, setHeads] = useState<HeadProduct[]>([]);
  const [nozzles, setNozzles] = useState<NozzleData[]>([]);
  const [headNozzles, setHeadNozzles] = useState<HeadNozzleJoin[]>([]);
  const [elevation2, setElevation2] = useState(0);

  const [minPSI, setMinPSI] = useState(0);

  const getSourceData = () => {
    const localData = localStorage.getItem('is.nozzleData');
    if (localData) {
      // TODO: clear local cache after a few days?
      const data = JSON.parse(localData) as LocalNozzleData;
      setHeads(data.heads);
      setNozzles(data.nozzles);
      setHeadNozzles(data.headNozzles);
    }
  };
  useEffect(() => {
    getSourceData();
    calcMinPSI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doSetInputPSI = (e: any) => {
    const inputPSI = parseFloat(e.currentTarget.value);
    setConfig({
      ...blankConfig,
      inputPSI,
    });
  };

  const calcMinPSI = () => {
    const pocGraphs = getState().pocGraphs;
    const psi = Math.min(
      ...pocGraphs.map((graph) => graph.pocPSI(pipeProducts)),
    );
    setMinPSI(psi);
  };

  const handleBrandChange = (brand: string) => {
    setConfig({ ...blankConfig, inputPSI: config.inputPSI, brand });
  };
  const handleHeadSeriesChange = (headSeries: string) => {
    setConfig({
      ...blankConfig,
      inputPSI: config.inputPSI,
      brand: config.brand,
      headSeries,
    });
  };
  const handleHeadSizeChange = (headSize: string) => {
    setConfig({
      ...blankConfig,
      inputPSI: config.inputPSI,
      brand: config.brand,
      headSeries: config.headSeries,
      headSize,
    });
  };
  const handleHeadModelChange = (headModel: string, heads: HeadProduct[]) => {
    const head = getHeadbyModel(headModel, heads);
    let outputPSI = config.inputPSI;
    if (head && head.regulatedPSI && config.inputPSI > head.regulatedPSI) {
      outputPSI = head.regulatedPSI;
    }
    setConfig({
      ...blankConfig,
      inputPSI: config.inputPSI,
      brand: config.brand,
      headSeries: config.headSeries,
      headSize: config.headSize,
      headModel,
      outputPSI,
    });
  };

  const handleNozzleSeriesChange = (nozzleSeries: string) => {
    setConfig({
      ...blankConfig,
      inputPSI: config.inputPSI,
      brand: config.brand,
      headSeries: config.headSeries,
      headSize: config.headSize,
      headModel: config.headModel,
      outputPSI: config.outputPSI,
      nozzleSeries,
    });
  };
  const handleNozzleGroupChange = (nozzleGroupname: string) => {
    setConfig({
      ...blankConfig,
      inputPSI: config.inputPSI,
      brand: config.brand,
      headSeries: config.headSeries,
      headSize: config.headSize,
      headModel: config.headModel,
      outputPSI: config.outputPSI,
      nozzleSeries: config.nozzleSeries,
      nozzleGroupname,
    });
  };

  const handleAddBase = (nozzleModel: string, config: BaseConfig) => {
    const base: SprinklerBase = {
      uuid: newUUID(),
      inputPSI: config.inputPSI,
      headSeries: config.headSeries,
      headModel: config.headModel,
      outputPSI: config.outputPSI,
      nozzleSeries: config.nozzleSeries,
      nozzleModel: nozzleModel,
    };
    addSprinklerBase(base);
  };

  const handlePresetSelect = (_, data: DropdownProps) => {
    const options = hardCodedBaseOptions.find((o) => o.name === data.value);
    if (options) {
      options.bases.forEach((base) => addSprinklerBase(base));
    }
  };

  return (
    <Wrap>
      <Message color="blue">
        <Message.Header>Available PSI Planner</Message.Header>
        {/* <p>PSI is lost or gained at a rate of 0.433 psi per foot of elevation change from the POC to the highest/lowest head</p> */}
        {/* <Input label={'POC Elevation'} type="number" name="elevation1" value={elevation1} onChange={(e, data) => setElevation1(parseInt(data.value, 10))} /> */}
        <Form>
          <Form.Field>
            <label>Gain/Loss to furthest head (in feet)</label>
            <Form.Input
              type="number"
              name="elevation2"
              value={elevation2}
              onChange={(_, data) => setElevation2(parseInt(data.value, 10))}
            />
            <small>
              The furthest head is {Math.abs(elevation2)}ft{' '}
              {elevation2 > 0 ? 'below' : 'above'} your POC
            </small>
          </Form.Field>
        </Form>
        <p>POC: {minPSI}</p>
        <p>
          Elevation Gain/Loss: {math.round(math.abs(elevation2) * 0.433, 2)} PSI
          is {elevation2 > 0 ? 'Gained' : 'Lost'}
        </p>
        <p>
          Recommended PSI: ({Math.round(minPSI * 0.8)}) + (
          {math.round(elevation2 * 0.433, 2)}) ={' '}
          <strong>
            {Math.round(minPSI * 0.8) + math.round(elevation2 * 0.433, 2)} PSI
          </strong>
        </p>
        <p>(80% of POC) + (Elevation) = Recommended</p>
      </Message>
      Put in your anticipated head PSI and select head by head
      <Form>
        <Form.Field>
          <Label>Head PSI</Label>
          {/* <Input value={config.inputPSI} onChange={doSetInputPSI} /> */}
          <NumericFormat
            name="inputPSI"
            value={config.inputPSI}
            onChange={doSetInputPSI}
          />
        </Form.Field>
      </Form>
      {!!config.inputPSI && (
        <>
          <br />
          <strong>Select a preset</strong>
          <br />
          <Select
            options={[
              { name: 'No preset' },
              ...hardCodedBaseOptions.filter(
                (b) => b.bases[0].inputPSI <= config.inputPSI,
              ),
            ].map((o) => ({
              text: o.name,
              value: o.name,
            }))}
            onChange={handlePresetSelect}
          />
        </>
      )}
      <br />
      <br />
      Or select head by head <br />
      <br />
      {minPSI - config.inputPSI < 15 && (
        <Warn>
          You will have {minPSI - config.inputPSI} psi left for friction loss in
          the system
        </Warn>
      )}
      <Breadcrumb size="small">
        {!!config.inputPSI && (
          <>
            Input PSI:{' '}
            <Breadcrumb.Section onClick={() => handleBrandChange('')}>
              {config.inputPSI}
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
          </>
        )}
        {!!config.brand && (
          <>
            Brand:{' '}
            <Breadcrumb.Section onClick={() => handleHeadSeriesChange('')}>
              {config.brand}
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
          </>
        )}
        {!!config.headSeries && (
          <>
            Head Series:{' '}
            <Breadcrumb.Section onClick={() => handleHeadSizeChange('')}>
              {config.headSeries}
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
          </>
        )}
        {!!config.headSize && (
          <>
            Head Size:{' '}
            <Breadcrumb.Section
              onClick={() => handleHeadModelChange('', heads)}
            >
              {config.headSize}
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
          </>
        )}
        {!!config.headModel && (
          <>
            Head Model:{' '}
            <Breadcrumb.Section onClick={() => handleNozzleSeriesChange('')}>
              {config.headModel} (output {config.outputPSI})
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
          </>
        )}
        {!!config.nozzleSeries && (
          <>
            Nozzle Series:{' '}
            <Breadcrumb.Section onClick={() => handleNozzleGroupChange('')}>
              {config.nozzleSeries}
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
          </>
        )}
        {!!config.nozzleGroupname && (
          <>
            Nozzle Group:{' '}
            <Breadcrumb.Section>{config.nozzleGroupname}</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
          </>
        )}
      </Breadcrumb>
      {!!config.outputPSI && (
        <small>
          {config.inputPSI} PSI into head, {config.outputPSI} PSI out of head
        </small>
      )}
      {!!config.inputPSI && !config.brand && (
        <>
          <h5>Select brand</h5>
          <List divided={true} relaxed={true}>
            {getUniqueHeadBrands(heads, config).map((b: string) => (
              <BrandListItem
                heads={heads}
                inputPSI={config.inputPSI}
                key={b}
                brand={b}
                onSelect={handleBrandChange}
              />
            ))}
          </List>
        </>
      )}
      {!!config.inputPSI && config.brand && !config.headSeries && (
        <>
          <h5>Select head series</h5>
          <div className="back-link" onClick={() => handleBrandChange('')}>
            &lt; back to brand
          </div>
          <List divided={true} relaxed={true}>
            {getUniqueHeadSeries(heads, config).map(
              (head: HeadProduct, i: number) => (
                <HeadSeriesListItem
                  key={i}
                  config={config}
                  heads={heads}
                  head={head}
                  onSelect={handleHeadSeriesChange}
                />
              ),
            )}
          </List>
        </>
      )}
      {!!config.inputPSI &&
        config.brand &&
        config.headSeries &&
        !config.headSize && (
          <>
            <h5>Select head size</h5>
            <div
              className="back-link"
              onClick={() => handleHeadSeriesChange('')}
            >
              &lt; back to head series
            </div>
            <List divided={true} relaxed={true}>
              {getUniqueHeadSizes(heads, config).map((s: string) => (
                <List.Item key={s}>
                  <List.Content
                    as="a"
                    onClick={handleHeadSizeChange.bind(null, s)}
                  >
                    {s}
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </>
        )}
      {!!config.inputPSI &&
        config.brand &&
        config.headSeries &&
        config.headSize &&
        !config.headModel && (
          <>
            <h5>Select head model</h5>
            <div className="back-link" onClick={() => handleHeadSizeChange('')}>
              &lt; back to head size
            </div>
            <List divided={true} relaxed={true}>
              {filterHeads(heads, config).map((h: HeadProduct) => (
                <List.Item key={h.model}>
                  <List.Content
                    verticalAlign="top"
                    as="a"
                    onClick={handleHeadModelChange.bind(null, h.model, heads)}
                  >
                    {h.model}
                    {h.description && h.description.length && (
                      <span> ({h.description})</span>
                    )}
                  </List.Content>
                  <List.Content floated="right" verticalAlign="top">
                    {h.minPSI} - {h.maxPSI} psi
                    {h.regulatedPSI && <span>, reg to {h.regulatedPSI}</span>}
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </>
        )}
      {!!config.inputPSI &&
        config.brand &&
        config.headSeries &&
        config.headSize &&
        config.headModel &&
        !config.nozzleSeries && (
          <>
            <h5>Select nozzle series</h5>
            <div
              className="back-link"
              onClick={() => handleHeadModelChange('', heads)}
            >
              &lt; back to head model
            </div>
            <List divided={true} relaxed={true}>
              {getNozzleSeriesNames(config, headNozzles).map((noz: string) => (
                <NozzleSeriesListItem
                  key={noz}
                  series={noz}
                  psi={config.outputPSI}
                  onSelect={handleNozzleSeriesChange}
                  nozzles={nozzles}
                />
              ))}
            </List>
          </>
        )}
      {!!config.inputPSI &&
        config.brand &&
        config.headSeries &&
        config.headSize &&
        config.headModel &&
        config.nozzleSeries &&
        !config.nozzleGroupname && (
          <>
            <h5>Select nozzle group</h5>
            <div
              className="back-link"
              onClick={() => handleNozzleSeriesChange('')}
            >
              &lt; back to nozzle series
            </div>
            <List divided={true} relaxed={true}>
              {getUniqueNozzleGroupnames(nozzles, config).map(
                (groupName: string) => (
                  <NozzleGroupListItem
                    key={groupName}
                    config={config}
                    series={config.nozzleSeries}
                    groupName={groupName}
                    psi={config.outputPSI}
                    onSelect={handleAddBase}
                    nozzles={nozzles}
                  />
                ),
              )}
            </List>
          </>
        )}
    </Wrap>
  );
};
const Wrap = styled.div`
  flex: 1;
  padding-bottom: ${8 * 3}px;

  .back-link {
    color: blue;
    cursor: pointer;
  }
`;
