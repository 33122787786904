import { IPoint, TitleBarConfig } from '@shared-types';
import { PDFDocument, degrees } from 'pdf-lib';
import pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { CustomTableLayout, TDocumentDefinitions } from 'pdfmake/interfaces';
import { Basemap, CropBox, Sheet } from '../../../../../shared-types';
import paper from '../../../paper';
import {
  getItemByUUID,
  isBasemap,
  isSheet,
  isText,
  paperItemStore,
} from '../helpers';
import { masterSidebarNew } from '../helpers/masterSidebarNew';
import { convertBlobToBase64, fetchAsBlob } from '../helpers/pdf/pdf.helpers';
import { localPaper } from '../localPaper';
import { getColor } from '../paper-helpers/plot.helpers';
import { ITEMNAMES } from '../shared/workbench-enums';
import { PaperSheet, isPaperSheet } from '../sheets/paper-sheet';
import { getState } from '../state';
import { formatText } from '../texts/text.helpers';
import {
  PaperBasemap,
  getPresignedBasemapPDFUpload,
  getPresignedImageUpload,
  isPaperBasemap,
} from '../upload/paper-basemap';
import { convertImageToBase64, effectiveCropbox } from './cropImage';
import { filterIntersectingBasemaps } from './filterIntersectingBasemaps';

export const createFullDesignSheets = async (
  titleBarConfig: TitleBarConfig,
): Promise<PDFDocument> => {
  if (
    titleBarConfig.titleBarWidth > 0 &&
    titleBarConfig.pageMargin * 3 +
      titleBarConfig.titleBarWidth +
      titleBarConfig.sheetWidth !==
      titleBarConfig.printWidth
  ) {
    throw new Error('Title bar too wide for print width');
  }
  const sheets = getState().items.filter(isSheet);
  const currentProject = localPaper.project.exportJSON();
  const { p2, canvas } = alteredPaper(currentProject);
  const svgs = svgsPerSheet(titleBarConfig, sheets, p2, canvas);
  const finalPDF = await exportDesign(svgs, titleBarConfig);
  return finalPDF;
};

const exportDesign = async (
  sheetSVGs: string[],
  titleBarConfig: TitleBarConfig,
): Promise<PDFDocument> => {
  const { items } = getState();
  const sheets = items.filter(isSheet);
  const basemaps = items.filter(isBasemap).filter((b) => b.visible);
  const paperBasemaps = [...paperItemStore.values()]
    .filter(isPaperBasemap)
    .filter((paperBasemap) => paperBasemap.realItem.visible);
  const paperSheets = [...paperItemStore.values()].filter(isPaperSheet);
  const finalPDF = await PDFDocument.create();
  for (let sheetIndex = 0; sheetIndex < sheets.length; sheetIndex++) {
    // For each sheet, create a new PDF document and attach it to the final PDF
    const sheet = sheets[sheetIndex];
    const paperSheet = paperSheets.find(
      (s) => s.getItem().data.uuid === sheet.uuid,
    );
    if (!paperSheet) {
      throw new Error('Paper sheet not found');
    }
    const doc = await PDFDocument.create();
    if (basemaps.length && paperBasemaps.length) {
      await addContentToDocument(
        doc,
        sheet,
        paperSheet,
        basemaps,
        paperBasemaps,
        sheetSVGs[sheetIndex],
        titleBarConfig,
      );
    }
    if (titleBarConfig.titleBarWidth > 0) {
      await addTitleBarToDocument(doc, sheetIndex, titleBarConfig);
    }
    const [sheetPage] = await finalPDF.copyPages(doc, [0]);
    finalPDF.addPage(sheetPage);
  }
  return finalPDF;
};

const addContentToDocument = async (
  doc: PDFDocument,
  sheet: Sheet,
  paperSheet: PaperSheet,
  basemaps: Basemap[],
  paperBasemaps: PaperBasemap[],
  svgSheet: string,
  config: TitleBarConfig,
): Promise<void> => {
  const validBasemaps = filterIntersectingBasemaps(
    paperBasemaps,
    paperSheet,
    basemaps,
  );
  paperBasemaps.forEach((basemap) => {
    // remove the actual basemap images from paper.js so they are not exported
    // because instead we will pull the original PDF or image from the server
    basemap.destroy();
  });
  await addBasemapsAndSVGToDocument(
    doc,
    validBasemaps,
    svgSheet,
    sheet,
    config,
  );
};
const addBasemapsAndSVGToDocument = async (
  doc: PDFDocument,
  basemaps: Basemap[],
  svg: string,
  sheet: Sheet,
  config: TitleBarConfig,
): Promise<void> => {
  const scale = getState().scale;
  // embeds basemaps and svgs together into a new pdf page
  doc.addPage([config.printWidth * 72, config.printHeight * 72]);
  for (let i = 0; i < basemaps.length; i++) {
    const basemap = basemaps[i];
    if (basemap.original?.type === 'pdf') {
      await embedBasemapPDFintoDocument(doc, basemap, sheet, scale, config);
    } else if (basemap.original?.type === 'image') {
      await embedBasemapImageIntoDocument(doc, basemap, sheet, scale, config);
    }
  }
  // add the paper.js layer
  await embedPaperSVGIntoDocument(doc, svg, config);
};
const addTitleBarToDocument = async (
  doc: PDFDocument,
  sheetIndex: number,
  config: TitleBarConfig,
) => {
  const { contractorLogoURL } = getState();
  let logo = '';

  if (contractorLogoURL) {
    const file = await fetchAsBlob(contractorLogoURL);
    logo = await convertBlobToBase64(file);
  }
  const titleBarDefinition = getTitleBarDefinition(config, logo, sheetIndex);
  const newPDF = await getPdfMakeBuffer(titleBarDefinition);
  const pdfDoc = await PDFDocument.load(newPDF);
  const originalPage = pdfDoc.getPage(0);
  const embeddedTitleBar = await doc.embedPage(originalPage);
  const leftPosition =
    config.pageMargin + config.sheetWidth + config.pageMargin;
  doc.getPage(0).drawPage(embeddedTitleBar, {
    x: leftPosition * 72,
    y: 0,
  });
};
const getTitleBarDefinition = (
  config: TitleBarConfig,
  logo: string,
  sheetIndex: number,
): TDocumentDefinitions => {
  const { title, scale } = getState();
  return {
    pageSize: {
      width: config.titleBarWidth * 72,
      height: config.printHeight * 72,
    },
    pageMargins: [0, 0, 0, 0],
    content: [
      {
        columns: [
          ...masterSidebarNew(
            title,
            getState(),
            logo,
            scale,
            config,
            '',
            sheetIndex,
          ),
        ],
      },
    ],
  };
};
const embedPaperSVGIntoDocument = async (
  doc: PDFDocument,
  svg: string,
  config: TitleBarConfig,
): Promise<void> => {
  const updatedSvgContent = svg.replace(
    /(stroke-dasharray=")([^"]+)"/g,
    (match, p1, p2) => {
      // PDFMake doesn't support dash arrays with zeros
      const newValues = p2
        .split(',')
        .map((value) => (parseFloat(value) === 0 ? 0.000001 : value))
        .join(',');
      return `${p1}${newValues}"`;
    },
  );
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const docDefinition: TDocumentDefinitions = {
    pageSize: {
      width: config.sheetWidth * 72, // 2592
      height: config.sheetHeight * 72, // 1728
    },
    pageMargins: [0, 0, 0, 0],
    content: [{ svg: updatedSvgContent }],
  };
  const pdfFromPDFMAKEDefinition = await getPdfMakeBuffer(docDefinition);
  const pdfDoc = await PDFDocument.load(pdfFromPDFMAKEDefinition);
  const embedded = await doc.embedPage(pdfDoc.getPage(0));
  doc.getPage(0).drawPage(embedded, {
    x: config.pageMargin * 72,
    y: config.pageMargin * 72,
  });
};

export const getPdfMakeBuffer = async (
  docDefinition: TDocumentDefinitions,
  tableLayouts: { [key: string]: CustomTableLayout } = {},
): Promise<Buffer> =>
  new Promise((resolve, reject) => {
    pdfMake
      .createPdf(docDefinition, tableLayouts)
      .getBuffer((buffer: Buffer) => {
        resolve(buffer);
      });
  });
export const positionCropboxOnSheet = (cb: CropBox, sheet: Sheet) => {
  // all in feet
  const topOffset = cb.topLeft.y - sheet.position.y;
  const cropBoxHeight = cb.bottomRight.y - cb.topLeft.y;
  const bottomOfCropboxFromTop = topOffset + cropBoxHeight;
  const x = cb.topLeft.x - sheet.position.x;
  const y = sheet.height - bottomOfCropboxFromTop;
  return { x, y };
};
const fetchBuffer = async (url: string): Promise<ArrayBuffer> => {
  const originalImageResponse = await fetch(url);
  return await originalImageResponse.arrayBuffer();
};
export const embedPosition = (
  margin: number,
  x: number,
  y: number,
  scale: number,
): IPoint => ({
  x: Math.max(margin * 72 + x * scale, margin * 72),
  y: Math.max(margin * 72 + y * scale, margin * 72),
});
const embedBasemapImageIntoDocument = async (
  doc: PDFDocument,
  basemap: Basemap,
  sheet: Sheet,
  scale: number,
  config: TitleBarConfig,
): Promise<void> => {
  const extension = basemap.original.filename.split('.').pop();
  if (!extension) throw new Error('Invalid file extension');
  // downloads the image and embeds it into a new PDF
  const presignedResponse = await getPresignedImageUpload(
    basemap['jpgUUID'],
    extension,
  );
  const imageBuffer = await fetchBuffer(presignedResponse.presignedURL);
  const imageUrl = URL.createObjectURL(new Blob([imageBuffer]));
  const imageCropbox = effectiveCropbox(basemap, sheet);
  const croppedImage = await convertImageToBase64(
    imageUrl,
    imageCropbox,
    extension,
  );
  if (!croppedImage) throw new Error('Failed to crop image');
  const embeddedImage =
    extension === 'jpg'
      ? await doc.embedJpg(croppedImage)
      : extension === 'png'
        ? await doc.embedPng(croppedImage)
        : null;
  if (!embeddedImage) throw new Error('Unsupported image type');
  const scaledImageInFeet = embeddedImage.scale(1 / basemap.scale);
  const { x, y } = positionCropboxOnSheet(basemap.cropBox, sheet);
  doc.getPage(0).drawImage(embeddedImage, {
    ...embedPosition(config.pageMargin, x, y, scale),
    width: scaledImageInFeet.width * scale,
    height: scaledImageInFeet.height * scale,
    opacity: basemap.style.opacity || 1,
    rotate: degrees(-basemap.rotation),
  });
};

const embedBasemapPDFintoDocument = async (
  doc: PDFDocument,
  basemap: Basemap,
  sheet: Sheet,
  scale: number,
  config: TitleBarConfig,
): Promise<void> => {
  // downloads the pdf and embeds it into a new PDF
  // remember, PDFs can only be embedded at 0, 90, 180, or 270 degrees
  const presignedResponse = await getPresignedBasemapPDFUpload(
    basemap['jpgUUID'],
  );
  const originalPDFBuffer = await fetchBuffer(presignedResponse.presignedURL);
  const originalPDF = await PDFDocument.load(originalPDFBuffer);
  const croppedScaled = await cropAndScalePDFPage(
    basemap,
    originalPDF,
    sheet,
    scale,
  );
  const embedded = await doc.embedPage(croppedScaled.getPage(0));
  const { x, y } = positionCropboxOnSheet(basemap.cropBox, sheet);
  doc.getPage(0).drawPage(embedded, {
    ...embedPosition(config.pageMargin, x, y, scale),
    opacity: basemap.style.opacity || 1,
  });
};

export const cropAndScalePDFPage = async (
  basemap: Basemap,
  pdfDoc: PDFDocument,
  sheet: Sheet,
  scale: number,
): Promise<PDFDocument> => {
  const cropBox = effectiveCropbox(basemap, sheet); // original basemap scale
  // divide by basemap scale to get app size
  // multiply by app scale and divide by 72 to get pdf size
  const scaleFactor = (1 / basemap.scale) * scale;
  const croppedPdfDoc = await PDFDocument.create();
  const [embeddedPage] = await croppedPdfDoc.embedPdf(pdfDoc, [0]);
  const originalPage = pdfDoc.getPages()[0];
  const originalHeight = originalPage.getHeight();
  const rotation = originalPage.getRotation().angle;
  const croppedPage = croppedPdfDoc.addPage([cropBox.width, cropBox.height]);

  croppedPage.drawPage(embeddedPage, {
    x: -cropBox.x,
    y: -(originalHeight - cropBox.y - cropBox.height), // Adjust Y to crop from the correct position
    rotate: degrees(-rotation),
  });
  croppedPage.scale(scaleFactor, scaleFactor);
  try {
    await croppedPdfDoc.save();
    return croppedPdfDoc;
  } catch (err) {
    throw new Error('Failed to save cropped PDF');
  }
};

export async function saveAndDownloadPDF(
  pdfDoc: PDFDocument,
  title = 'modified-document.pdf',
): Promise<void> {
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = title;
  a.click();
  URL.revokeObjectURL(url);
  a.remove();
}
export const svgsPerSheet = (
  config: TitleBarConfig,
  sheets: Sheet[],
  p2: paper.PaperScope,
  canvas: HTMLCanvasElement,
): string[] => {
  // This is a port of the old way of doing this
  /*
    What's happening here? First, we're caching the current state of paper.js
    Then, we're iterating over each sheet, removing any edges and elements that
    are entirely contained or overlap the edge of the sheet, then exporting that
    svg. Then we restore paper.js to the cached version, that way we can kind of "temporarily"
    remove items when we  to make it faster.
  */
  const cached = p2.project.exportJSON();
  const edited = sheets.map((sheet, i) => {
    if (i > 0) {
      // We only need to clear the project on the 2nd time through
      p2.project.clear();
      p2.project.importJSON(cached);
    }
    const sheetObjects = p2.project.getItems({ name: ITEMNAMES.SHEET });
    if (sheetObjects[i]) {
      sheetObjects[i].remove();
    }
    const printWidth = config.sheetWidth * 72;
    const printHeight = config.sheetHeight * 72;
    const overlapperRect = new paper.Rectangle(
      new paper.Point(sheet.position.x, sheet.position.y),
      new paper.Size(sheet.width, sheet.height),
    );
    const rectItem = new paper.Path.Rectangle(overlapperRect);

    const overlappedEdges = p2.project.getItems({
      match: (item: paper.Item) =>
        (item.name === ITEMNAMES.PIPE || item.name === ITEMNAMES.ELEMENT) &&
        !item.intersects(rectItem) &&
        !item.isInside(overlapperRect),
    });
    if (config.border) {
      rectItem.strokeColor = getColor('black');
      rectItem.strokeWidth = config.border;
    } else {
      rectItem.remove();
    }
    overlappedEdges.forEach((item) => item.remove());
    const svgDoc = exportSheetContentsToSVG(
      sheet,
      printWidth,
      printHeight,
      p2,
      canvas,
    );
    const textGroups = svgDoc.querySelectorAll('#text-item');
    textGroups.forEach((group) => {
      const data = group.getAttribute('data-paper-data');
      const textElement = group.querySelector('text');
      if (data && textElement) {
        textElement.innerHTML = '';
        const parsedData = JSON.parse(data);
        const item = getItemByUUID(parsedData.uuid, isText);
        const formatted = formatText(item.content, item.width);
        const lines = formatted.split('\n');
        lines.forEach((line, i) => {
          let tspan = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'tspan',
          );
          tspan.textContent = line.length === 0 ? '_' : line; // Replace empty string with a non-breaking space
          if (line.length === 0) {
            tspan.setAttribute('fill', 'transparent');
          }
          tspan.setAttribute('x', textElement.getAttribute('x') || '');
          tspan.setAttribute('dy', `1.2em`);
          textElement.appendChild(tspan);
        });
      }
    });
    // Finally, we create a new div and append the svg to that
    const d = document.createElement('div');
    document.body.append(d);
    d.append(svgDoc);
    /*
        1. render the SVG in order to remove things outside the BBox of the sheet
        2. use the internal x/y coordinates plus things like width, height, radius to remove elements (polylines become problematic)
        3. go back to paper.js solution,  the cropped basemap layer from paper.js instead of the original SVG
    */
    // const newDoc = parser.parseFromString(newSVG.outerHTML, 'image/svg+xml')
    const originalLayers = d.querySelectorAll(
      '#Plants,#Curves_lines,#closest-point',
    );
    originalLayers.forEach((item) => item.remove());
    const items = d.querySelectorAll(
      '#design-element,#pipe,#zone-info-box-group,#valve-text,#plant,#legend,#Outlines,#House',
    );
    items.forEach((item) => {
      const rect = item.getBoundingClientRect();
      if (
        rect.right < 0 ||
        rect.left > printWidth ||
        rect.top > printHeight ||
        rect.bottom < 0
      ) {
        item.remove();
      }
    });
    const croppedSVG = d.children[0];
    d.remove();
    return croppedSVG.outerHTML;
  });
  return edited;
};
const exportSheetContentsToSVG = (
  sheet: Sheet,
  printWidth: number,
  printHeight: number,
  p2: paper.PaperScope,
  canvas: HTMLCanvasElement,
): SVGElement => {
  // First, we reset the canvas width to the size of the sheet,
  // then  the content (not the outlines) to an SVG with correct print viewBox
  canvas.setAttribute('width', `${sheet.width}`);
  canvas.setAttribute('height', `${sheet.height}`);
  p2.view.zoom = 1;
  const center = new paper.Point(
    sheet.position.x + p2.view.bounds.width / 2,
    sheet.position.y + p2.view.bounds.height / 2,
  );
  p2.view.center = center;

  const svg = p2.project.exportSVG({
    matchShapes: true,
  }) as SVGElement;
  svg.setAttribute('width', `${printWidth}`);
  svg.setAttribute('height', `${printHeight}`);
  svg.setAttribute('viewBox', `0 0 ${sheet.width} ${sheet.height}`);
  return svg;
};
export const alteredPaper = (
  originalJSON: string,
): { p2: paper.PaperScope; canvas: HTMLCanvasElement } => {
  const canvas = document.createElement('canvas');
  canvas.width = 10000;
  canvas.height = 10000;
  const tmpPaperScope = new paper.PaperScope();
  tmpPaperScope.setup(canvas);
  tmpPaperScope.project.importJSON(originalJSON);

  removeHelperItems(tmpPaperScope);
  removeHiddenLayers(tmpPaperScope);
  removeDataFromItems(tmpPaperScope);
  return { p2: tmpPaperScope, canvas };
};

const removeHiddenLayers = (p2: paper.PaperScope) => {
  p2.project.layers.forEach((layer) => {
    if (!layer.visible) {
      layer.removeChildren();
      layer.remove();
    }
  });
};

const removeHelperItems = (p2: paper.PaperScope) => {
  // Should only remove design helpers, not anything the user has turned on
  const itemsToRemove = [
    ITEMNAMES.VALVE_BOX_LOCATION,
    ITEMNAMES.BED,
    ITEMNAMES.OUTLINE,
    ITEMNAMES.FITTING,
    ITEMNAMES.KEY_POINT,
    ITEMNAMES.YARD_INDEX,
    ITEMNAMES.SVG_RASTER,
    ITEMNAMES.ORTHO_DOT,
    ITEMNAMES.SVG_OUTLINE,
    ITEMNAMES.TMP_ICON,
    ITEMNAMES.ELEVATION_PIN,
  ];

  p2.project
    .getItems({
      match: (item) => itemsToRemove.some((i) => i === item.name),
    })
    .forEach((h) => h.remove());
};
const removeDataFromItems = (p2: paper.PaperScope) => {
  p2.project
    .getItems({ recursive: true })
    .filter((i) => i.name !== ITEMNAMES.TEXT)
    .forEach((item) => {
      item.data = {};
    });
};
