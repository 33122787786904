import { IPoint } from '@shared-types';
import paper from 'src/paper';
import { getColor } from './plot.helpers';

export const createMasterValve = (point: IPoint, scale: number): paper.Item => {
  const g = new paper.Group();
  const p = new paper.Path.Rectangle({
    point: [0, 0],
    size: [5 / scale, 5 / scale],
  });
  p.rotate(-45);
  p.fillColor = getColor('black');
  g.addChild(p);
  g.position = new paper.Point(point);
  return g;
};
