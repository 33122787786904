import { setStrokeWidth, useDesignStore } from '../state';
import { PanelInputWrap } from './PanelButton';

export const DefaultStrokeWidth = () => {
  const strokeWidth = useDesignStore((state) => state.strokeWidth);

  return (
    <PanelInputWrap
      label="Set Stroke Width"
      value={strokeWidth}
      type="number"
      min={0}
      step={0.1}
      onChange={(e: any) => {
        if (e.target.value >= 0) {
          setStrokeWidth(Number(e.target.value));
        }
      }}
    />
  );
};
