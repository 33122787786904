import { IValveProduct, Zone } from '@shared-types';
import { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  reduceHeadsGPMbyId,
  reducePlantsGPHbyId,
  valveGPM,
} from '../../helpers/directedGraph';
import { isPlant } from '../../plants/plants';
import { pocGraphByNode, useDesignStore } from '../../state';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';
import { ZoneMenu } from './ZoneMenu';

export const ZoneDetails = ({ zone }: { zone: Zone }) => {
  const plants = useDesignStore((state) => state.items.filter(isPlant));
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  const groups = useDesignStore((state) => state.groups);
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const elementCache = useDesignStore((state) => state.elementCache);
  const [gpm, setGpm] = useState(0);

  const getMaxDiffPrecip = () => {
    const precips = zone.headIds
      .map((id) => elementCache[id])
      .filter(isSprinkler)
      .map((head) => head.props.precipAverage);
    const max = Math.max(...precips);
    const min = Math.min(...precips);
    return !precips.length || max === min ? 0 : (max - min).toFixed(3);
  };

  useEffect(() => {
    const dg = pocGraphByNode(zone.valve || '', pocGraphs);
    const g = dg ? dg.rootGPM(pipeProducts) : 0;
    setGpm(g);
  }, [pipeProducts, pocGraphs, zone]);

  return (
    <Wrap>
      {zone.headIds.length > 0 ? (
        <div>
          {reduceHeadsGPMbyId(zone.headIds, elementCache) > gpm && (
            <Icon name="exclamation" color="red" />
          )}
          {reduceHeadsGPMbyId(zone.headIds, elementCache).toFixed(1)} gpm across{' '}
          {zone.headIds.length} heads{' '}
        </div>
      ) : (
        ''
      )}
      {zone.isDrip || zone.plantIds.length ? (
        <div>
          {reducePlantsGPHbyId(plants, zone.plantIds) / 60 > gpm && (
            <Icon name="exclamation" color="red" />
          )}
          {(reducePlantsGPHbyId(plants, zone.plantIds) / 60).toFixed(2)} gpm
          across {zone.plantIds.length} plants
        </div>
      ) : (
        ''
      )}
      <div>
        {valveGPM(zone, pipeProducts, groups, pocGraphs).toFixed(2)} actual
        piped gpm
      </div>
      Precip diff: {getMaxDiffPrecip()} in/hr
      <div>
        Valve: {(elementCache[zone.valve]?.props as IValveProduct).name}
      </div>
      <ZoneMenu zone={zone} />
    </Wrap>
  );
};

const Wrap = styled.div`
  padding: 8px;
  background: #5e5e5e;
`;
