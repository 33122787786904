import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PortalContext } from 'src/contexts/portal/portal';
import { base } from 'src/styles/common';
import styled from 'styled-components';
import { InvoiceTable } from './InvoiceTable';

export const AdminDashboard = () => {
  const { invoices, loadInvoices, loadCatalog } = useContext(PortalContext);

  useEffect(() => {
    loadInvoices();
    loadCatalog();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrap>
      <Link to="/app/project-request">New Project</Link>
      <br />
      <br />
      <InvoiceTable invoices={invoices} />
    </Wrap>
  );
};

const Wrap = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${base * 3}px;
`;
