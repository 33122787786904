import { BehaviorSubject } from 'rxjs';
import { Tools } from '../../../../../shared-types';

export type ARROW_SUBMODE = 'move' | 'stamp';

export const subModes: ARROW_SUBMODE[] = ['move', 'stamp'];

class ModeService {
  private _mode: Tools = 'Arrow';
  private _subMode: ARROW_SUBMODE = 'move';
  private _mode$ = new BehaviorSubject<Tools>(this._mode);
  private _subMode$ = new BehaviorSubject<ARROW_SUBMODE>(this._subMode);

  get mode() {
    return this._mode;
  }
  get mode$() {
    return this._mode$.asObservable();
  }
  get subMode() {
    return this._subMode;
  }
  get subMode$() {
    return this._subMode$.asObservable();
  }
  setMode(value: Tools) {
    this._mode$.next(value);
    this._subMode$.next('move');
  }
  setSubMode(value: ARROW_SUBMODE) {
    if (this._mode === 'Arrow') {
      this._subMode$.next(value);
    }
  }
}
export const modeService = new ModeService();
