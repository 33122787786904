import React from 'react';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const LegendLayer = ({ open, onOpen }) => {
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.LEGEND,
  );

  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Legend`}
      open={open}
      onOpen={onOpen}
    />
  );
};

export default React.memo(LegendLayer);
