import { ValveBox } from '@shared-types';
import { addUndo } from '../../contexts/design/undo';
import { updateAllHeadColors } from '../../paper-helpers/plot.helpers';
import { reorderZones } from '../../paper-helpers/valves';
import { getState, setState } from '../state-basics';

export const changeValveBoxOrder = (vbs: ValveBox[], undoable = true) => {
  const { valveBoxes, zones, zoneNumberOffset, groups } = getState();
  if (undoable) {
    addUndo(() => {
      changeValveBoxOrder(valveBoxes, false);
    }, 'change valve box order');
  }
  const updatedZones = reorderZones(zones, vbs, zoneNumberOffset);
  updateAllHeadColors(updatedZones, groups);
  setState({
    valveBoxes: vbs,
    zones: updatedZones,
  });
};
