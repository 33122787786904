export const breakTextToLines = (txt: string, max: number) => {
  const lines: string[] = [];
  let space = -1;
  function cut() {
    for (var i = 0; i < txt.length; i++) {
      txt[i] == ' ' && (space = i);
      if (i >= max) {
        (space == -1 || txt[i] == ' ') && (space = i);
        if (space > 0) {
          lines.push(txt.slice(txt[0] == ' ' ? 1 : 0, space));
        }
        txt = txt.slice(txt[0] == ' ' ? space + 1 : space);
        space = -1;
        break;
      }
    }
    check();
  }
  function check() {
    if (txt.length <= max) {
      lines.push(txt[0] == ' ' ? txt.slice(1) : txt);
      txt = '';
    } else if (txt.length) {
      cut();
    }
    return;
  }
  check();
  return lines;
};
export const wordWrap = (
  txt: string,
  max: number,
  item: paper.PointText,
): string => {
  const lines = breakTextToLines(txt, max);
  return (item.content = lines.join('\n'));
};

export const formatText = (input: string, charLimit: number): string => {
  let result = '';
  let currentLine = '';

  for (const char of input) {
    if (char === '\n') {
      result += currentLine + '\n';
      currentLine = '';
    } else if (currentLine.length >= charLimit && char === ' ') {
      result += currentLine + '\n';
      currentLine = '';
    } else {
      currentLine += char;
    }
  }

  return result + currentLine;
};
