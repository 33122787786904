export enum PipeSectionType {
  HOP = 'hop',
  LATERAL = 'lateral',
}

export enum GridType {
  SQUARE = 'square',
  TRIANGLE = 'triangle',
}

export enum StripCorner {
  BOTTOM_LEFT = 'BL',
  BOTTOM_RIGHT = 'BR',
  BOTTOM_CENTER = 'BC',
}

export enum LAYER_NAMES {
  DEFAULT = 'default-layer',
  SVG = 'basemap-layer',
  HEADS = 'head-layer',
  ELEMENTS = 'element-layer',
  EDGES = 'pipe-layer',
  COVERAGE = 'coverage-layer',
  PLANTS = 'plant-layer',
  VALVE_INFO_BOXES = 'valve-info-box-layer',
  VALVE_BOXES = 'valve-box-layer',
  // HANDLES = 'handles-layer',
  LEGEND = 'legend-layer',
  SHEETS = 'sheets-layer',
  TRACING = 'tracing-layer',
  OUTLINES = 'outline-layer',
  ELEVATION = 'elevation-pins',
  BASEMAPS = 'basemaps-layer',
  SLEEVES = 'sleeves-layer',
}

export enum ITEMNAMES {
  ARC = 'arc',
  COVERAGE_POINT = 'coverage-point',
  BED = 'bed',
  GPM_TEXT = 'gpm-text',
  PRECIP_TEXT = 'precip-text',
  KEY_POINT = 'key-yard-point',
  LEGEND = 'legend',
  PIPE = 'pipe',
  HEAD_CIRCLE = 'head-circle',
  CLUE = 'head-clue',
  FITTING = 'fitting',
  MISC_ITEM = 'misc-item',
  ORTHO_DOT = 'ortho-dot',
  OUTLINE = 'yard-outline-path',
  PHOENIX_HEAD = 'phoenix-head',
  PIPE_TEXT = 'pipe-size-text',
  PLANT_GROUP = 'drip-head-group',
  SLEEVE = 'sleeve',
  SVG_OUTLINE = 'svg-outline',
  SVG_RASTER = 'svg-raster',
  TMP_HEAD_GROUP = 'tmp-head',
  TMP_HEAD_VERTEX = 'tmp-head-vertex',
  TMP_ICON = 'tmp-icon',
  VALVE = 'valve',
  VALVE_BOX_LOCATION = 'valve-box-location',
  VALVE_TEXT = 'valve-text',
  YARD_INDEX = 'yard-index',
  ZONE_BOX = 'zone-box',
  ELEMENT = 'design-element',
  ZONE_INFO_BOX_GROUP = 'zone-info-box-group',
  SHEET = 'sheet',
  HANDLE_TO = 'handle-to',
  HANDLE_FROM = 'handle-from',
  TEXT = 'text-item',
  BASEMAP_JPG = 'basemap-jpg',
  ELEVATION_PIN = 'elevation-pin',
  SNAP_POINT = 'snap-point',
}
export enum SheetSize {
  SMALL = '24x36',
  LARGE = '36x48',
}
export enum CustomEventType {
  GLOBAL_NOTIFICATION = 'global-notification',
}
export enum ArrowMode {
  MOVE = 'move',
  ROTATE = 'rotate',
  ANGLE = 'angle',
}
