import { IPoint, TextChunk } from '@shared-types';
import { takeUntil } from 'rxjs';
import { defaultItem, paperItemStore } from '../helpers';
import { activateNamedLayer } from '../paper-helpers/plot.helpers';
import { ItemService } from '../polyline/ItemService';
import { registerStateHooks } from '../polyline/item.model';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { addToSelectedIDs, clearSelectedIDs } from '../state';
import { deletePaperItem, updatePaperItem } from '../state/renderers';
import { toolService } from '../tool.service';
import { drawText$ } from './drawText';
import { PaperText } from './paper-text';

class TextService implements ItemService<TextChunk> {
  init() {
    this.registerWithState();
    drawText$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
  }
  createItem = (point: IPoint = { x: 0, y: 0 }): TextChunk => ({
    ...defaultItem(point),
    width: 30,
    itemType: 'text-item',
    point,
    align: 'left',
    fontSize: 12,
    content: 'Example',
  });
  renderItemToPaper = (item: TextChunk, layer = LAYER_NAMES.DEFAULT) => {
    activateNamedLayer(layer);
    paperItemStore.set(item.uuid, new PaperText(item));
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  };
  registerWithState = () => {
    registerStateHooks<TextChunk>('text-item', {
      postAddItem: (item) => {
        this.renderItemToPaper(item);
        // after drawing a text box, go into edit mode
        clearSelectedIDs();
        addToSelectedIDs([item.uuid]);
      },
      postUpdateItem: (item) => {
        updatePaperItem(item);
      },
      postDeleteItem: (item) => {
        deletePaperItem(item.uuid);
      },
    });
  };
}

export const textService = new TextService();
