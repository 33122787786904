import React from 'react';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { CoverageOptions } from '../CoverageOptions';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const CoverageLayer = ({ open, onOpen }) => {
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.COVERAGE,
  );
  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Coverage Layer`}
      open={open}
      onOpen={onOpen}
      scrollChildren={false}
      childLayer={<CoverageOptions key="coverage" />}
    />
  );
};

export default React.memo(CoverageLayer);
