export type ISegment = number[];
export type ICoods = ISegment[];

function ab(segment: ICoods): ISegment {
  const start = segment[0];
  const end = segment[1];
  return [end[0] - start[0], end[1] - start[1]];
}

function crossProduct(v1: ISegment, v2: ISegment): number {
  return v1[0] * v2[1] - v2[0] * v1[1];
}

function add(v1: ISegment, v2: ISegment): ISegment {
  return [v1[0] + v2[0], v1[1] + v2[1]];
}

function sub(v1: ISegment, v2: ISegment): ISegment {
  return [v1[0] - v2[0], v1[1] - v2[1]];
}

function scalarMult(s: number, v: ISegment): ISegment {
  return [s * v[0], s * v[1]];
}

function intersectSegments(a: ICoods, b: ICoods): ISegment {
  const p = a[0];
  const r = ab(a);
  const q = b[0];
  const s = ab(b);

  const cross = crossProduct(r, s);
  const qmp = sub(q, p);
  const numerator = crossProduct(qmp, s);
  const t = numerator / cross;
  const intersection = add(p, scalarMult(t, r));
  return intersection;
}

function isParallel(a: ICoods, b: ICoods): boolean {
  const r = ab(a);
  const s = ab(b);
  return crossProduct(r, s) === 0;
}

export default function intersection(a: ICoods, b: ICoods): ISegment | false {
  if (isParallel(a, b)) return false;
  return intersectSegments(a, b);
}

export const lineOffset = (coords: ICoods, offsetDegrees: number) => {
  // Valdiation
  if (!coords) throw new Error('coords is required');
  if (
    offsetDegrees === undefined ||
    offsetDegrees === null ||
    isNaN(offsetDegrees)
  )
    throw new Error('offsetDegrees is required');

  return [
    ...lineOffsetFeature(coords, -offsetDegrees / 2),
    // ...lineOffsetFeature(coords, offsetDegrees / 2).reverse(),
  ];
};

function lineOffsetFeature(coords: ICoods, offsetDegrees: number): ICoods {
  const segments: ICoods[] = [];
  const finalCoords: ICoods = [];
  coords.forEach(function (currentCoords, index) {
    if (index !== coords.length - 1) {
      const segment = processSegment(
        currentCoords,
        coords[index + 1],
        offsetDegrees,
      );
      segments.push(segment);
      if (index > 0) {
        const seg2Coords = segments[index - 1];
        const intersects = intersection(segment, seg2Coords);

        if (intersects !== false) {
          seg2Coords[1] = intersects;
          segment[0] = intersects;
        }

        finalCoords.push(seg2Coords[0]);
        if (index === coords.length - 2) {
          finalCoords.push(segment[0]);
          finalCoords.push(segment[1]);
        }
      }
      if (coords.length === 2) {
        finalCoords.push(segment[0]);
        finalCoords.push(segment[1]);
      }
    }
  });
  return finalCoords;
}

function processSegment(
  point1: ISegment,
  point2: ISegment,
  offset: number,
): ICoods {
  const L = Math.sqrt(
    (point1[0] - point2[0]) * (point1[0] - point2[0]) +
      (point1[1] - point2[1]) * (point1[1] - point2[1]),
  );

  const out1x = point1[0] + (offset * (point2[1] - point1[1])) / L;
  const out2x = point2[0] + (offset * (point2[1] - point1[1])) / L;
  const out1y = point1[1] + (offset * (point1[0] - point2[0])) / L;
  const out2y = point2[1] + (offset * (point1[0] - point2[0])) / L;
  return [
    [out1x, out1y],
    [out2x, out2y],
  ];
}
