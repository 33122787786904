import {
  DesignElement,
  DesignSprinklerElement,
  IPoint,
  Sprinkler,
} from '@shared-types';
import { getState } from 'src/pages/Workbench/state';
import paper from 'src/paper';
import { defaultItem } from '../../helpers';
import { sprinklerSymbols } from '../../paper-helpers/heads';
import {
  createArc,
  getArcPoints,
  getColor,
} from '../../paper-helpers/plot.helpers';
import { ITEMNAMES, StripCorner } from '../../shared/workbench-enums';
import { PaperItem } from './paper-item';

export const isSprinkler = (
  el: DesignElement,
): el is DesignSprinklerElement => {
  return el?.type === 'sprinkler';
};

export const isPaperSprinkler = (
  item: PaperItem<any>,
): item is PaperSprinkler => {
  return item instanceof PaperSprinkler;
};

export class PaperSprinkler extends PaperItem<DesignSprinklerElement> {
  setPosition(point: IPoint): void {
    throw new Error('Method not implemented.');
  }
  private element: DesignSprinklerElement = {
    ...defaultItem({ x: 0, y: 0 }),
    type: 'sprinkler',
    props: {
      angle: 360,
      rotation: 0,
      radius: 0,
      type: 'Spray',
      width: 0,
      height: 0,
      origin: StripCorner.BOTTOM_LEFT,
      x: 0,
      y: 0,
      gpm: 0,
      precipAverage: 0,
      precipSquare: 0,
      precipTriangle: 0,
      base: {
        uuid: '',
        inputPSI: 0,
        headSeries: '',
        headModel: '',
        outputPSI: 0,
        nozzleSeries: '',
        nozzleModel: '',
      },
    },
    itemType: 'design-element',
  };
  private _arcVisible = false;
  private _gpmVisible = false;
  private _precipVisible = false;
  private color: string = '#000';
  private _group: paper.Group;

  constructor(element: DesignSprinklerElement, color = '#000') {
    super();
    this.element = element;
    this.color = color;
    this._group = new paper.Group();
    this._group.data = element;
    this.render();
    this.toggleArc(getState().showArcs);
    this.toggleHeadPreview(getState().showHeadPreviews);
    this.toggleGPM(getState().showGPM);
  }
  get paperGroup() {
    return this._group;
  }
  get arcVisible() {
    return this._arcVisible;
  }
  set arcVisible(show: boolean) {
    this._arcVisible = show;
  }
  get gpmVisible() {
    return this._gpmVisible;
  }
  set gpmVisible(show: boolean) {
    this._gpmVisible = show;
  }
  get precipVisible() {
    return this._precipVisible;
  }
  set precipVisible(show: boolean) {
    this._precipVisible = show;
  }
  get paperArc() {
    return this._group.getItem({ name: ITEMNAMES.ARC });
  }
  get paperGPM() {
    return this._group.getItem({ name: ITEMNAMES.GPM_TEXT });
  }
  get paperPrecip() {
    return this._group.getItem({ name: ITEMNAMES.PRECIP_TEXT });
  }
  get paperClue() {
    return this._group.getItem({ name: ITEMNAMES.CLUE });
  }
  get paperHeadCircle() {
    return this._group.getItem({ name: ITEMNAMES.HEAD_CIRCLE });
  }
  get paperZoneDot() {
    return this._group.getItem({ name: ITEMNAMES.PHOENIX_HEAD });
  }
  getRealItem() {
    return this.element;
  }
  getItem() {
    return this._group;
  }
  hideForPiping = (hide: boolean) => {
    if (this.paperArc) {
      this.paperArc.visible = !hide;
    }
    if (this.paperHeadCircle) {
      this.paperHeadCircle.visible = !hide;
    }

    if (this.paperClue) {
      this.paperClue.visible = !hide;
    }
  };

  update = (el: DesignSprinklerElement) => {
    this.element = el;
    this._group.data = el;
    this.render(true);
  };
  updateArc = (sprinkler: Sprinkler) => {
    const size = getState().headClueSize;
    this.element.props = sprinkler;
    this._group.data = this.element;
    this.arcVisible = true;
    this.paperArc?.replaceWith(this.generateArc(this.element));
    this.paperClue?.replaceWith(this.generateSmallArcClue(this.element, size));
    this.paperHeadCircle?.replaceWith(
      this.generateHeadCircleOutline(this.element),
    );
  };
  updateSmallArc = (size?: number) => {
    this.paperClue?.remove();
    const clue = this.generateSmallArcClue(
      this.element,
      size || getState().headClueSize,
    );
    this._group.addChild(clue);
    // TODO: Getting the position of an item checks its bounds. Should try to use something else.
    this._group.pivot = this.paperHeadCircle.position;
  };
  updateHeadSize = () => {
    this.paperHeadCircle?.remove();
    const headCircle = this.generateHeadCircleOutline(this.element);
    this._group.addChild(headCircle);
    this._group.pivot = this.paperHeadCircle.position;
  };
  updateZoneDotSize = (size?: number) => {
    this.paperZoneDot?.remove();
    const dot = this.generateZoneDot(this.element, this.color, size);
    this._group.addChild(dot);
    this._group.pivot = this.paperHeadCircle.position;
  };
  updateColor = (color = '#000') => {
    if (color !== this.color) {
      this.color = color;
      this.colorZoneDot(color);
    }
  };
  toggleArc = (show: boolean) => {
    this.arcVisible = show;
    this.paperArc?.remove();
    if (show) {
      const arc = this.generateArc(this.element);
      this._group.addChild(arc);
      arc.sendToBack();
      this._group.pivot = this.paperHeadCircle.position;
    }
  };
  toggleGPM = (show: boolean) => {
    this.gpmVisible = show;
    this.paperGPM?.remove();
    if (show) {
      const gpmText = this.generateGPMText(this.element);
      this._group.addChild(gpmText);
      this._group.pivot = this.paperHeadCircle.position;
    }
  };
  togglePrecip = (show: boolean) => {
    this.precipVisible = show;
    this.paperPrecip?.remove();
    if (show) {
      const gpmText = this.generatePrecipText(this.element);
      this._group.addChild(gpmText);
      this._group.pivot = this.paperHeadCircle.position;
    }
  };
  private render = (replace = false) => {
    this._group.opacity = 1;
    this._group.name = ITEMNAMES.ELEMENT;
    this._group.data = this.element;
    const size = getState().headClueSize;
    if (!replace) {
      this._group.removeChildren();
      const dot = this.generateZoneDot(this.element, this.color);
      const headCircle = this.generateHeadCircleOutline(this.element);
      const clue = this.generateSmallArcClue(this.element, size);
      this._group.addChildren([dot, clue, headCircle]);
      if (this.arcVisible) {
        const arc = this.generateArc(this.element);
        this._group.insertChild(1, arc);
      }
    } else {
      this.arcVisible = true;
      this.paperZoneDot?.replaceWith(
        this.generateZoneDot(this.element, this.color),
      );
      this.paperArc?.replaceWith(this.generateArc(this.element));
      this.paperClue?.replaceWith(
        this.generateSmallArcClue(this.element, size),
      );
      this.paperHeadCircle?.replaceWith(
        this.generateHeadCircleOutline(this.element),
      );
    }
    this._group.pivot = this.paperHeadCircle.position;
    this._group.visible = this.element.visible;
    this._group.locked = this.element.locked;
  };
  private generateHeadCircleOutline = (
    el: DesignSprinklerElement,
  ): paper.SymbolItem => {
    const sprinkler = el.props;
    const type = sprinkler.type || 'Spray';
    const point = new paper.Point(el.position.x, el.position.y);
    // const scale = getState().scale;
    let textContent = sprinkler.height
      ? `${sprinkler.height}`
      : `${Math.floor(sprinkler.radius)}`;
    if (!sprinkler.gpm || !sprinkler.precipAverage) {
      textContent = '!';
    }
    let symbol: paper.SymbolItem | undefined = undefined;
    if (type === 'Rotor') {
      if (textContent && sprinklerSymbols.rotorByRadius[textContent]) {
        symbol = (
          sprinklerSymbols.rotorByRadius[textContent] as paper.SymbolDefinition
        ).place(new paper.Point(point));
        // symbol.strokeWidth = 0.5 / scale;
      } else if (sprinklerSymbols.rotorSymbol) {
        symbol = sprinklerSymbols.rotorSymbol.place(new paper.Point(point));
        // symbol.strokeWidth = 1 / scale;
      }
    } else if (type === 'Strip') {
      if (textContent && sprinklerSymbols.stripByRadius[textContent]) {
        symbol = (
          sprinklerSymbols.stripByRadius[textContent] as paper.SymbolDefinition
        ).place(new paper.Point(point));
        // symbol.strokeWidth = 0.5 / scale;
      } else if (sprinklerSymbols.stripSymbol) {
        symbol = sprinklerSymbols.stripSymbol.place(new paper.Point(point));
        // symbol.strokeWidth = 1 / scale;
        // point.y -= 0.3
      }
    } else {
      if (textContent && sprinklerSymbols.sprayByRadius[textContent]) {
        symbol = (
          sprinklerSymbols.sprayByRadius[textContent] as paper.SymbolDefinition
        ).place(new paper.Point(point));
        // symbol.strokeWidth = 0.5 / scale;
      } else if (sprinklerSymbols.spraySymbol) {
        // symbol = sprinklerSymbols.spraySymbol.place(new paper.Point(point))
        symbol = (
          sprinklerSymbols.sprayByRadius[8] as paper.SymbolDefinition
        ).place(new paper.Point(point));
        // symbol.strokeWidth = 0.5 / scale;
      }
    }
    if (symbol === undefined) {
      symbol = new paper.SymbolItem(
        new paper.Path.Circle(new paper.Point(point), 5),
      );
    }
    symbol.name = ITEMNAMES.HEAD_CIRCLE;
    return symbol;
  };
  private generateArc = (el: DesignSprinklerElement): paper.Path => {
    const sprinkler = el.props;
    const origin = new paper.Point(el.position.x, el.position.y);
    const isCircle = sprinkler.angle === 360;
    const isStrip = sprinkler.width > 0;
    let arc: paper.Path;
    if (isStrip) {
      const rect = new paper.Rectangle({
        from: [origin.x, origin.y],
        to: [origin.x + sprinkler.width, origin.y + sprinkler.height],
      });
      arc = new paper.Path([
        rect.bottomLeft,
        rect.topLeft,
        rect.topRight,
        rect.bottomRight,
        rect.bottomLeft,
      ]);
      if (sprinkler.origin === StripCorner.BOTTOM_LEFT) {
        const rect = new paper.Rectangle({
          from: [origin.x, origin.y],
          to: [origin.x + sprinkler.width, origin.y - sprinkler.height],
        });
        arc = new paper.Path([
          rect.bottomLeft,
          rect.topLeft,
          rect.topRight,
          rect.bottomRight,
          rect.bottomLeft,
        ]);
      } else if (sprinkler.origin === StripCorner.BOTTOM_RIGHT) {
        const rect = new paper.Rectangle({
          from: [origin.x, origin.y],
          to: [origin.x - sprinkler.width, origin.y - sprinkler.height],
        });
        arc = new paper.Path([
          rect.bottomLeft,
          rect.topLeft,
          rect.topRight,
          rect.bottomRight,
          rect.bottomLeft,
        ]);
      } else if (sprinkler.origin === StripCorner.BOTTOM_CENTER) {
        const rect = new paper.Rectangle({
          from: [origin.x - sprinkler.width / 2, origin.y],
          to: [origin.x + sprinkler.width / 2, origin.y - sprinkler.height],
        });
        arc = new paper.Path([
          rect.bottomLeft,
          rect.topLeft,
          rect.topRight,
          rect.bottomRight,
          rect.bottomLeft,
        ]);
      }
      arc.applyMatrix = false;
      arc.rotate(sprinkler.rotation, origin);
    } else if (isCircle) {
      const tmpCircle = new paper.Shape.Circle(
        new paper.Point(origin),
        sprinkler.radius,
      );
      arc = tmpCircle.toPath();
      tmpCircle.remove();
    } else {
      const arcValues = getArcPoints(sprinkler.angle, origin, sprinkler.radius);
      arc = createArc(arcValues, sprinkler.rotation, origin);
    }
    arc.strokeColor = getColor('#333');
    arc.strokeWidth = 0.1;
    arc.name = ITEMNAMES.ARC;
    arc.locked = true;
    arc.visible = this._arcVisible;
    arc.opacity = 0.5;
    arc.dashArray = [1, 0.5];
    return arc;
  };
  private generateGPMText = (el: DesignSprinklerElement): paper.PointText => {
    const sprinkler = el.props;
    const roundedGPM = (sprinkler.gpm || 0).toFixed(2);
    const gpmText = new paper.PointText(
      new paper.Point(el.position.x + 1.2, el.position.y + 4),
    );
    gpmText.content = `${roundedGPM} GPM`;
    gpmText.fontWeight = 'bold';
    gpmText.fontSize = 1.2;
    gpmText.name = ITEMNAMES.GPM_TEXT;
    return gpmText;
  };
  private generatePrecipText = (
    el: DesignSprinklerElement,
  ): paper.PointText => {
    const sprinkler = el.props;
    const roundedGPM = (sprinkler.precipAverage || 0).toFixed(2);
    const gpmText = new paper.PointText(
      new paper.Point(el.position.x + 1.2, el.position.y + 1.2),
    );
    gpmText.content = `${roundedGPM} in/hr`;
    gpmText.fontWeight = 'bold';
    gpmText.fontSize = 1.2;
    gpmText.name = ITEMNAMES.PRECIP_TEXT;
    return gpmText;
  };
  private generateSmallArcClue = (
    el: DesignSprinklerElement,
    size: number,
    showHeadPreviews = false,
  ): paper.PathItem => {
    const headSmallArc = size;
    const sprinkler = el.props;
    const origin = new paper.Point(el.position.x, el.position.y);
    const type = sprinkler.type || 'Spray';
    const { scale } = getState();
    const isCircle = sprinkler.angle === 360;
    const radius = headSmallArc / scale;
    // let arc: paper.Path = new paper.Path();
    if (!isCircle && type !== 'Strip') {
      const arcValues = getArcPoints(sprinkler.angle, origin, radius);
      const arc = createArc(arcValues, sprinkler.rotation, origin);
      if (showHeadPreviews) {
        arc.fillColor = getColor('#ffffff');
        arc.strokeColor = getColor('#333333');
        arc.strokeWidth = 1 / scale;
      } else {
        arc.fillColor = getColor('black');
      }
      arc.name = ITEMNAMES.CLUE;
      arc.locked = true;
      return arc;
    }
    const p = new paper.Path();
    p.name = ITEMNAMES.CLUE;
    return p;
  };
  private generateZoneDot = (
    el: DesignSprinklerElement,
    color: string,
    size?: number,
  ): paper.Shape.Circle => {
    const { scale } = getState();
    const headZoneDotSize = size || getState().headZoneDotSize;
    const zoneDotRealPx = headZoneDotSize / scale;
    const zoneDot = new paper.Shape.Circle(
      new paper.Point(el.position),
      zoneDotRealPx,
    );
    zoneDot.name = ITEMNAMES.PHOENIX_HEAD;
    zoneDot.fillColor = getColor(color || '#000');
    zoneDot.opacity = 0.8;
    return zoneDot;
  };
  private colorZoneDot = (color: string) => {
    if (this.paperZoneDot) this.paperZoneDot.fillColor = getColor(color);
  };
  deleteArc = () => {
    // used just for legend
    this.paperArc?.remove();
  };
  destroy = () => {
    this._group.remove();
  };
  setPivot(point: IPoint): void {
    this._group.pivot = new paper.Point(point);
  }
  toggleHighlight = (show: boolean): void => {
    this._group.selected = show;
  };
  toggleVisibility(show: boolean): void {
    this._group.visible = show;
  }
  toggleHeadPreview = (show: boolean): void => {
    const size = getState().headClueSize;
    this.paperClue?.replaceWith(
      this.generateSmallArcClue(this.element, size, show),
    );
  };
}
