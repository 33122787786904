import { SprinklerBase } from '@shared-types';
import { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { uniq } from 'underscore';
import { PanelDivider } from '../../components/PanelButton';
import { getGeneratePerfData } from '../../helpers/nozzleTransformer';
import { useDesignStore } from '../../state';

interface Props {
  onSet: (uuid: string, radius: number) => void;
  activeKey: string;
  activeRadius: number;
}
export const NozzleOptions = ({
  activeKey,
  onSet,
  activeRadius,
}: Props): JSX.Element => {
  const sprinklerBases = useDesignStore((state) => state.sprinklerBases);
  const [radius, setRadius] = useState<number>(activeRadius);
  const [key, setKey] = useState<string>(activeKey);
  const [full, setFull] = useState<boolean>(true);
  const [adjustable, setAdjustable] = useState<boolean>(true);

  const radiusOptions = (key: string): number[] => {
    const base = sprinklerBases.find((s) => s.uuid === key);
    if (base) {
      const data = getGeneratePerfData(base);
      return uniq(data.map((d) => d.radius)).sort((a, b) => a - b);
    }
    return [];
  };

  useEffect(() => {
    const base = sprinklerBases.find((s) => s.uuid === activeKey);
    if (base) {
      const data = getGeneratePerfData(base);
      const radiusMatches = data.filter((d) => d.radius === activeRadius);
      if (radiusMatches.length) {
        const uniqueAngles = uniq(radiusMatches.map((d) => d.angle));
        if (uniqueAngles.length === 1) {
          if (uniqueAngles[0] === 360) {
            setFull(true);
          } else {
            setFull(false);
          }
          setAdjustable(false);
        } else {
          setFull(false);
          setAdjustable(true);
        }
      }
    }
  }, [activeKey, activeRadius, sprinklerBases]);

  useEffect(
    () => {
      if (key.length) {
        if (radius) {
          onSet(key, radius);
        } else {
          const radii = radiusOptions(key);
          if (radii.length) {
            setRadius(radii[0]);
            onSet(key, radii[0]);
          } else {
            console.error('hmm, something is not right');
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, radius],
  );

  const setLocalRadius = (n: number, k: string) => {
    setRadius(n);
    setKey(k);
  };

  const nozzleOptions = (
    bases: SprinklerBase[],
    fullCircle: boolean,
    checkAdjustable: boolean,
  ): SprinklerBase[] => {
    const b = bases.filter((base) => {
      const data = getGeneratePerfData(base);
      const uniqueAngles = uniq(data.map((d) => d.angle));
      if (fullCircle) {
        return fullCircle
          ? uniqueAngles.length === 1 && uniqueAngles[0] === 360
          : uniqueAngles.length > 1 || uniqueAngles[0] !== 360;
      } else {
        const isPart = uniqueAngles.length > 1 || uniqueAngles[0] !== 360;
        const isAdjustable = uniqueAngles.length > 1;
        return checkAdjustable
          ? isPart && isAdjustable
          : isPart && !isAdjustable;
      }
    });
    b.sort((a, b) => {
      const aData = getGeneratePerfData(a);
      const bData = getGeneratePerfData(b);
      const aAngles = uniq(aData.map((d) => d.angle));
      const bAngles = uniq(bData.map((d) => d.angle));
      return (
        Math.min(...aAngles) - Math.min(...bAngles) ||
        Math.max(...aAngles) - Math.max(...bAngles)
      );
    });
    return b;
  };

  const nozzleType = (base: SprinklerBase): string => {
    const data = getGeneratePerfData(base);
    const uniqueAngles = uniq(data.map((d) => d.angle));
    return uniqueAngles.length === 1
      ? uniqueAngles[0] === 360
        ? ''
        : `Fixed ${uniqueAngles[0]}˚`
      : `Adjustable ${Math.min(...uniqueAngles)}˚ - ${Math.max(
          ...uniqueAngles,
        )}˚`;
  };

  return (
    <Wrap>
      <Button.Group>
        <Button primary={full} onClick={() => setFull(true)}>
          Full Circle (360˚)
        </Button>
        <Button.Or />
        <Button primary={!full} onClick={() => setFull(false)}>
          Part Circle
        </Button>
      </Button.Group>
      <PanelDivider />
      {!full && (
        <>
          <Button.Group>
            <Button primary={adjustable} onClick={() => setAdjustable(true)}>
              Adjustable Angle
            </Button>
            <Button.Or />
            <Button primary={!adjustable} onClick={() => setAdjustable(false)}>
              Fixed Angle
            </Button>
          </Button.Group>
          <PanelDivider />
        </>
      )}
      <div className="nozzle-list">
        {nozzleOptions(sprinklerBases, full, adjustable).map((b, i) => (
          <div
            key={i}
            className={`nozzle-list-item ${b.uuid === key ? 'active' : ''}`}
          >
            <div key={i}>
              <div className="nozzle-title">
                {b.nozzleModel} : {b.nozzleSeries} (
                {Math.min(...radiusOptions(b.uuid))}' -
                {Math.max(...radiusOptions(b.uuid))}')
              </div>{' '}
              <div className="angle-type">{nozzleType(b)}</div>
              <div className="buttons">
                {radiusOptions(b.uuid).map((r, j) => (
                  <Button
                    primary={radius === r && b.uuid === key}
                    size="tiny"
                    key={j}
                    onClick={() => setLocalRadius(r, b.uuid)}
                  >
                    {r}'
                  </Button>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  .active {
    background: #333;
  }
  .nozzle-name {
    font-size: 11px;
  }
  .nozzle-list-item {
    margin-bottom: 16px;
    padding: 8px;
    width: 100%;
    &.active {
      background: #3e3e3e;
    }
  }
  .nozzle-title {
    font-size: 1.2em;
  }
  .angle-type {
    font-weight: bold;
    padding-left: 4px;
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
    button {
      margin: 4px;
    }
  }
`;
