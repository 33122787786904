import paper from 'src/paper';

import { compact } from 'underscore';
import {
  Bed,
  IPoint,
  Polyline,
  Vertex,
  Yard,
} from '../../../../../shared-types';
import {
  getItemsByType,
  isPolylineBed,
  isPolylineYard,
  paperItemStore,
} from '../helpers';
import { findNewCPoint, getOppositeCircumferencePoint } from '../polymath';
import {
  getAngleBetweenPoints,
  getArcCenter,
  getArcMidPoint,
  getArcTangentAngle,
  getEuclideanDistance,
  getNextArcCenter,
  getOppositePoint,
  getPointOnCircle,
  getPointOnCircleBetweenTwoPoints,
  snapTo45Degrees,
} from '../shared/geometry';
import { drawPolyService } from './draw-poly.service';
import { PaperPolyline } from './paper-polyline';
import { defaultVertex, polylineService } from './polyline.service';

export const getAnglePoint = (
  vertices: Vertex[],
  lastVertex: Vertex,
  point: IPoint,
  nextLength: number,
): paper.Point => {
  let referenceAngle = 0;
  if (vertices.length > 1) {
    const preLastVertex = vertices[vertices.length - 2];
    referenceAngle = getAngleBetweenPoints(
      preLastVertex.position,
      lastVertex.position,
    );
  }
  const currentAngle = getAngleBetweenPoints(lastVertex.position, point);
  const snappedAngle = snapTo45Degrees(currentAngle, referenceAngle);
  const newPoint = getPointOnCircle(
    lastVertex.position,
    nextLength || getEuclideanDistance(lastVertex.position, point),
    snappedAngle,
  );
  return new paper.Point(newPoint);
};

export const getPointAtDistance = (
  lastVertex: Vertex,
  point: paper.Point,
  d: number,
) => {
  const vector = point.subtract(new paper.Point(lastVertex.position));
  const unitVector = vector.normalize();
  const scaledVector = unitVector.multiply(d);
  const newPoint = new paper.Point(lastVertex.position).add(scaledVector);
  return newPoint;
};

export const getArcThru = (
  a: IPoint,
  b: IPoint,
  lastVertex: Vertex,
  thruPoint: IPoint | null,
  vertices: Vertex[],
) => {
  let arcThruPoint = { x: a.x, y: a.y };
  if (lastVertex?.through) {
    const potentialThruPoint = getNewArcThroughPoint(b, vertices, thruPoint);
    if (potentialThruPoint) {
      arcThruPoint = potentialThruPoint;
    }
  }
  return arcThruPoint;
};

export const getNewArcThroughPoint = (
  currentClickPosition: IPoint,
  vertices: Vertex[],
  currentThruPoint: IPoint | null,
): IPoint | null => {
  if (!shouldCreateNewArc(currentThruPoint, vertices)) return currentThruPoint;

  const secondLastVertexPosition = vertices[vertices.length - 2].position;
  const lastVertex = vertices[vertices.length - 1];

  const lastArcCenter = getArcCenter(
    secondLastVertexPosition,
    lastVertex.through!,
    lastVertex.position,
  );

  if (!lastArcCenter) return currentThruPoint;

  const nextArcCenter = getNextArcCenter(
    lastArcCenter,
    lastVertex.position,
    currentClickPosition,
  );

  if (!nextArcCenter) return null;

  const potentialThruPoint =
    getPointOnCircleBetweenTwoPoints(
      nextArcCenter,
      lastVertex.position,
      currentClickPosition,
    ) ?? currentThruPoint;

  const isNewArcValid = validateNewArcTangency(
    secondLastVertexPosition,
    lastVertex,
    currentClickPosition,
    potentialThruPoint,
  );

  return isNewArcValid
    ? potentialThruPoint
    : getOppositePoint(nextArcCenter, potentialThruPoint);
};
export const shouldCreateNewArc = (
  thruPoint: IPoint | null,
  vertices: Vertex[],
): boolean => {
  // If there's no thruPoint, we shouldn't create a new arc
  if (!thruPoint) {
    return false;
  }
  // Get the last vertex from the vertices array
  const lastVertex = vertices[vertices.length - 1];
  // Check if the last vertex has a defined "through" point
  const lastVertexHasThrough = Boolean(lastVertex.through);
  // Return true if the last vertex has a through point, otherwise false
  return lastVertexHasThrough;
};

export const validateNewArcTangency = (
  secondLastVertexPosition: IPoint,
  lastVertex: Vertex,
  currentClickPosition: IPoint,
  potentialThruPoint: IPoint,
): boolean => {
  const lastVertexArc = new paper.Path.Arc(
    secondLastVertexPosition,
    lastVertex.through!,
    lastVertex.position,
  );

  const potentialArc = new paper.Path.Arc(
    lastVertex.position,
    potentialThruPoint,
    currentClickPosition,
  );

  const angle1 = getArcTangentAngle(lastVertexArc, true);
  const angle2 = getArcTangentAngle(potentialArc, false);

  lastVertexArc.remove();
  potentialArc.remove();

  return Math.abs(angle1 - angle2) < 0.0001;
};

export const renderPolyHintArc = (
  overrideMode: boolean,
  point: paper.Point,
  thruPoint: any,
  vertices: Vertex[],
) => {
  const lastVertex = vertices[vertices.length - 1];
  drawPolyService.updateHintDashArray([2, 1]);
  if (overrideMode) {
    drawPolyService.addPointToHint(point);
  } else if (!thruPoint) {
    drawPolyService.addPointToHint(point);
  } else if (thruPoint.override) {
    const pp = new paper.Point(thruPoint);
    if (!pp.equals(point)) {
      drawPolyService.addArcToHint(pp, point);
    }
  } else {
    let arcThruPoint = thruPoint;
    if (lastVertex.through) {
      const potentialThruPoint = getNewArcThroughPoint(
        point,
        vertices,
        thruPoint,
      );
      if (potentialThruPoint) arcThruPoint = potentialThruPoint;
    }
    const arcThru = new paper.Point(arcThruPoint);
    if (!arcThru.equals(point)) {
      drawPolyService.addArcToHint(arcThru, point);
    }
  }
};
export const renderPolyHintLine = (
  shift: boolean,
  vertices: Vertex[],
  point: paper.Point,
  nextLength: number,
) => {
  const lastVertex = vertices[vertices.length - 1];
  drawPolyService.updateHintDashArray([]);
  if (shift) {
    const anglePoint = getAnglePoint(vertices, lastVertex, point, nextLength);
    drawPolyService.addPointToHint(anglePoint);
  } else if (nextLength) {
    const newPoint = getPointAtDistance(lastVertex, point, nextLength);
    drawPolyService.addPointToHint(newPoint);
  } else {
    drawPolyService.addPointToHint(point);
  }
};
// export const renderPolyHintClose = (vertices: Vertex[], point: paper.Point) => {
//   // const lastVertex = vertices[vertices.length - 1];
//   const firstVertex = vertices[0];
//   if (firstVertex) {
//     polyService.addPointToHint(new paper.Point(firstVertex.position));
//   }
// };

export const setThroughPoint = (point: paper.Point, override: boolean) => {
  /*
    When in arc mode and we're overriding the through point, we're setting
    the current point to the thru point and making sure to mark it as override.
    Then we're setting overrideMode to false so we escape out of it.
  */
  drawPolyService.setThruPoint({
    x: point.x,
    y: point.y,
    override,
  });
  if (override) {
    drawPolyService.setOverrideMode(false);
  }
};
export const addOverrideArc = (
  point: IPoint,
  thruPoint: IPoint,
  vertices: Vertex[],
) => {
  // there is an overriden through point, so use that one
  const lastVertex = vertices[vertices.length - 1];
  /* Now we are using the overriden throughPoint so we can restart the tangential arcs */
  const newThru = getArcMidPoint(lastVertex.position, thruPoint, point);
  drawPolyService.addVertex({
    ...defaultVertex(),
    position: { x: point.x, y: point.y },
    through: newThru,
  });
  const arcThru = getArcThru(point, point, lastVertex, newThru, vertices);
  drawPolyService.setThruPoint(arcThru);
};

export const addRegularArc = (
  point: paper.Point,
  thruPoint: any,
  vertices: Vertex[],
) => {
  const lastVertex = vertices[vertices.length - 1];
  // there is a through point, so we're using it to create an arc
  const arcThru = getArcThru(thruPoint, point, lastVertex, thruPoint, vertices);
  if (!new paper.Point(arcThru).equals(point)) {
    drawPolyService.addVertex({
      ...defaultVertex(),
      position: { x: point.x, y: point.y },
      through: arcThru,
    });
  }
};
export const addStandardVertex = (
  point: paper.Point,
  vertices: Vertex[],
  shift: boolean,
  nextLength: number,
) => {
  /* of course, we also want to handle straight edges too */
  const lastVertex = vertices[vertices.length - 1];
  let p = point;
  if (shift && lastVertex) {
    p = getAnglePoint(vertices, lastVertex, p, nextLength);
  } else if (nextLength && lastVertex) {
    p = getPointAtDistance(lastVertex, p, nextLength);
  }
  drawPolyService.addVertex({
    ...defaultVertex(),
    position: { x: p.x, y: p.y },
    through: null,
  });
};
export const moveVertices = (
  vertex: Vertex,
  point: paper.Point,
  vertices: Vertex[],
  i: number,
  isThrough: boolean,
): Vertex[] => {
  const newVertices = [...vertices];
  if (isThrough) {
    return newVertices.map((v, j) => {
      if (j === i) {
        return {
          ...v,
          through: { x: point.x, y: point.y },
        };
      } else {
        return v;
      }
    });
  }
  // when we move THIS vertex, we need to modify this through point
  // if this vertex's next vertex ALSO has a through point, then we need to modify that one too
  // if this vertex does not have a through point, then just move it
  if (!vertex.through) {
    return newVertices.map((v, j) => {
      if (j === i) {
        return {
          ...v,
          position: { x: point.x, y: point.y },
        };
      } else {
        return v;
      }
    });
  }
  const prevVertex = vertices[i - 1];
  const prevVertexPosition = prevVertex.position;

  const prevArcCenter = getArcCenter(
    prevVertexPosition,
    vertex.through,
    vertex.position,
  );
  if (prevArcCenter) {
    const newCenter = findNewCPoint(
      prevVertexPosition,
      vertex.position,
      prevArcCenter,
      point,
    );
    const newThrough = getOppositeCircumferencePoint(
      prevVertexPosition,
      vertex.position,
      newCenter,
      vertex.through,
    );
    newVertices.splice(i, 1, {
      ...vertex,
      position: { x: point.x, y: point.y },
      through: newThrough,
    });
  }
  const nextVertex = vertices[i + 1];
  if (nextVertex && nextVertex.through) {
    const nextVertexPosition = nextVertex.position;
    const nextArcCenter = getArcCenter(
      vertex.position,
      nextVertex.through,
      nextVertexPosition,
    );
    if (nextArcCenter) {
      const newCenter = findNewCPoint(
        nextVertexPosition,
        vertex.position,
        nextArcCenter,
        point,
      );
      const newThrough = getOppositeCircumferencePoint(
        nextVertexPosition,
        vertex.position,
        newCenter,
        nextVertex.through,
      );
      newVertices.splice(i + 1, 1, {
        ...nextVertex,
        through: newThrough,
      });
    }
  }
  return newVertices;
};

export const convertYardToPolyline = (yard: Yard): Polyline => {
  const points = yard.points.map((p) => ({ x: p.x, y: p.y }));
  const newPolyline = polylineService.createItem();
  newPolyline.closed = true;
  newPolyline.visible = false;
  newPolyline.polyType = 'yard';
  newPolyline.vertices = points.map((p) => {
    return {
      ...defaultVertex(),
      position: { x: p.x, y: p.y },
    };
  });
  return newPolyline;
};
export const convertBedToPolyline = (bed: Bed): Polyline => {
  const points = bed.points.map((p) => ({ x: p.x, y: p.y }));
  const newPolyline = polylineService.createItem();
  newPolyline.closed = true;
  newPolyline.visible = false;
  newPolyline.polyType = 'bed';
  newPolyline.vertices = points.map((p) => {
    return {
      ...defaultVertex(),
      position: { x: p.x, y: p.y },
    };
  });
  return newPolyline;
};
export const getPolylineYards = (): Polyline<'yard'>[] => {
  return getItemsByType(isPolylineYard);
};
export const getPaperPolylineYards = (): PaperPolyline[] => {
  return compact(
    getPolylineYards().map((p) => {
      const paperPoly = paperItemStore.get(p.uuid);
      if (paperPoly instanceof PaperPolyline) {
        return paperPoly;
      }
      return null;
    }),
  );
};

export const getPolylineBeds = (): Polyline<'bed'>[] => {
  return getItemsByType(isPolylineBed);
};
