import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { base } from '../../styles/common';
import details from './details.jpg';
import grass from './grass.jpg';
import logoBar from './logo-bar.jpg';
import sheet from './sheet.jpg';
import takeoff from './takeoff.jpg';
import arcs from './thing/arcs.jpg';
import breakdown from './thing/breakdown.jpg';
import coverage from './thing/coverage.jpg';
import mainImg from './thing/main-img.jpg';
import pipeSquare from './thing/pipe-square.jpg';
import zoomedTakeoff from './thing/takeoff.jpg';

export const Home = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const signup = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  };

  return (
    <Wrap>
      <header>
        <div className="login-buttons">
          {!isAuthenticated && (
            <>
              <Button onClick={signup}>Signup</Button>
              <Button onClick={() => loginWithRedirect()}>Login</Button>
            </>
          )}
          {isAuthenticated && (
            <>
              {' '}
              <Button as={NavLink} to="/app">
                Dashboard
              </Button>
              <Button onClick={() => logout()}>Logout</Button>
            </>
          )}
        </div>
        <section className="masthead">
          <h1>We Design. You Install.</h1>
          <p>
            Spend less time and money on your irrigation designs. No software to
            learn, no more lost bids.
          </p>
          <p>Call now! (719) 210-7356</p>
        </section>
        <section className="splash-images">
          <div className="main-img" />
          <div className="takeoff" />
          <div className="large-circle" />
          <div className="arcs" />
          <div className="breakdown" />
          <div className="coverage" />
        </section>
      </header>
      <main>
        <section className="deliverables">
          <h2>What We Deliver</h2>
          <div className="deliverable-list">
            <div className="deliverable takeoff">
              <img src={takeoff} alt="takeoff" height="100%" />
              <h4>Takeoff</h4>
              <a
                className="ui button blue mini takeoff"
                href="https://res.cloudinary.com/irrigation-studio/image/upload/v1593739175/Takeoff_-_Irrigation_Studio_vxisw6.pdf"
                target="blank"
                download="Takeoff - Irrigation Studio"
              >
                Download
              </a>
            </div>
            <div className="deliverable sheets">
              <img src={sheet} alt="sheet" height="100%" />
              <h4>Design Sheets</h4>
              <a
                className="ui button blue mini design"
                href="https://res.cloudinary.com/irrigation-studio/image/upload/v1593773090/Design_Sheets_-_Irrigation_Studio_jorkmb.pdf"
                target="blank"
                download="Design Sheets - Irrigation Studio"
              >
                Download
              </a>
            </div>
            <div className="deliverable system">
              <img src={details} alt="details" height="100%" />
              <h4>Details</h4>
              <a
                className="ui button blue mini details"
                href="https://res.cloudinary.com/irrigation-studio/image/upload/v1593739936/System_Details_-_Irrigation_Studio_yalig1.pdf"
                target="blank"
                download="System Details - Irrigation Studio"
              >
                Download
              </a>
            </div>
          </div>
        </section>
        {/* <section className="turnaround">
          <div className="banner">
            <div className="banner-left">5 days</div>
            <div className="banner-right">Our average turnaround time</div>
          </div>
        </section> */}
        <section className="pricing">
          <div className="price">
            <div className="description">Residential</div>
            <div className="amount">$445</div>
            <div className="first-acre">first acre</div>
            <div className="list">
              <div className="item">Material Takeoff</div>
              <div className="item">Friction loss details</div>
              <div className="item">Color-coded zone map</div>
              <div className="item">Branded with your logo</div>
            </div>
            <div className="additional">$170 each additional acre</div>
          </div>
          <div className="price">
            <div className="description">Commercial</div>
            <div className="amount">$575</div>
            <div className="first-acre">first acre</div>
            <div className="list">
              <div className="item">Material Takeoff</div>
              <div className="item">Friction loss details</div>
              <div className="item">Color-coded zone map</div>
              <div className="item">Branded with your logo</div>
            </div>
            <div className="additional">$285 each additional acre</div>
          </div>
        </section>
        <section className="signup">
          Signup for your <strong>free</strong> account now!
          <br />
          <br />
          <Button size="huge" primary onClick={signup}>
            Signup
          </Button>{' '}
          <br />
          <br />
          <div>
            <div>* Submit and manage design requests</div>
            <div>* View and pay invoices easily online</div>
          </div>
        </section>
        <div className="logo-bar" />
      </main>
      <footer>
        <div>
          <Icon name="envelope" />
          team@irrigationstudio.com
        </div>
        <div>
          <Icon name="phone" />
          (719) 210-7356
        </div>
      </footer>
    </Wrap>
  );
};
const Wrap = styled.div`
  header {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
    text-align: center;
    background: #212b18 url(${grass}) bottom center no-repeat;
    padding: ${base * 2}px;
    background-size: cover;
    border-radius: 0 0 135% 135%/20px;

    .login-buttons {
      position: absolute;
      top: 16px;
      right: 16px;
      button {
        margin-left: 8px;
      }
    }

    .splash-images {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;

      @media (max-width: 880px) {
        transform: scale(0.9);
      }
      @media (max-width: 650px) {
        transform: scale(0.8);
      }
      @media (max-width: 500px) {
        transform: scale(0.5);
      }

      div {
        border: 1px solid #ccc;
        box-shadow: 0px 3px 6px -3px #333;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
      }

      .main-img {
        background: #fff url(${mainImg}) center center no-repeat;
        background-size: cover;
        transform: translate(-50%, -50%);
        width: 431px;
        height: 244px;
      }

      .large-circle {
        border-radius: 50%;
        width: 180px;
        height: 180px;
        background: #fff url(${pipeSquare}) center center no-repeat;
        transform: translate(-280px, 10px);
      }
      .arcs {
        width: 190px;
        height: 120px;
        background: #fff url(${arcs}) center center no-repeat;
        transform: translate(-19px, 40px);
        background-size: 240%;
      }
      .coverage {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        background: #fff url(${coverage}) center center no-repeat;
        transform: translate(150px, 80px);
        background-size: cover;
      }
      .breakdown {
        width: 117px;
        height: 211px;
        background: #fff url(${breakdown}) center center no-repeat;
        transform: translate(199px, -90px);
        background-size: cover;
      }
      .takeoff {
        width: 150px;
        height: 220px;
        background: #fff url(${zoomedTakeoff}) left center no-repeat;
        transform: translate(-320px, -110px);
        background-size: cover;
      }
    }
    .masthead {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: -100px;
      @media (max-width: 880px) {
        margin-top: -85px;
      }
      @media (max-width: 650px) {
        margin-top: -70px;
      }
      @media (max-width: 500px) {
        margin-top: -60px;
      }
    }
    h1 {
      font-size: 48px;
      opacity: 0.9;
    }
    p {
      max-width: 480px;
      font-size: 20px;
      opacity: 0.9;
    }
  }
  main {
    margin-top: 150px;

    @media (max-width: 500px) {
      margin-top: 80px;
    }

    h2 {
      text-align: center;
      font-weight: normal;
      font-size: 40px;
      margin-top: ${base * 2}px;
    }
    p {
      text-align: center;
    }
    .turnaround {
      text-align: center;
      .banner {
        text-align: center;
        margin-bottom: 24px;
        background: #ccc;
        width: 200px;
        border: 2px solid blue;
        .banner-left {
          color: #4183c4;
          font-size: 48px;
          margin-right: 16px;
          display: block;
          line-height: 60px;
        }
        .banner-right {
          text-transform: uppercase;
          font-size: 16px;
          color: #333;
        }
      }
    }
    .pricing {
      background: #fff;
      text-align: center;
      margin-bottom: ${base * 5}px;
      .price {
        width: 300px;
        border-radius: 10px;
        color: #fff;
        display: inline-block;
        text-align: center;
        @media (max-width: 750px) {
          margin: 32px auto 0 auto;
          display: block;
          text-align: center;
        }
        &:first-child {
          background: #41c469;
          margin-right: 16px;
          @media (max-width: 750px) {
            margin: 0 auto 0 auto;
          }
          .list {
            background: #54ca78;
            margin: 16px 0;
            .item {
              border-top: 1px solid #a0e2b4;
              padding: 8px;

              &:last-child {
                border-bottom: 1px solid #a0e2b4;
              }
            }
          }
        }
        &:nth-of-type(2) {
          background: #4183c4;
          .list {
            background: #548fca;
            margin: 16px 0;
            .item {
              border-top: 1px solid #a0c1e2;
              padding: 8px;

              &:last-child {
                border-bottom: 1px solid #a0c1e2;
              }
            }
          }
        }
        .description {
          font-size: 18px;
          line-height: 24px;
          padding-top: 16px;
          &:after {
          }
        }
        .amount {
          font-size: 48px;
          font-weight: bold;
          line-height: 48px;
          margin-top: 12px;
        }
        .additional {
          font-weight: bold;
          padding-bottom: 16px;
        }
      }
    }
    .deliverables {
      text-align: center;
      padding: ${base * 5}px ${base * 2}px;

      .deliverable-list {
        display: inline-flex;
        justify-content: center;
        margin-top: ${base * 2}px;
        flex-wrap: wrap;
      }
      .deliverable {
        display: block;
        margin: ${base * 2}px;
        text-align: center;
        @media (max-width: 750px) {
          margin: ${base}px;
        }
        img {
          box-shadow: 0px 3px 6px -3px #333;
          margin-bottom: ${base}px;
          border: 1px solid #999;
          height: 200px;
          @media (max-width: 750px) {
            height: 93px;
          }
        }
        h4 {
          margin-top: 0;
          font-size: 24px;
          font-weight: normal;
          @media (max-width: 750px) {
            font-size: 16px;
          }
        }
      }

      .deliverable.takeoff {
        flex: 1;
      }
      .deliverable.system {
        flex: 1;
      }
      .deliverable.sheets {
        flex: 2;
      }
    }
    h3 {
      text-align: center;
      margin-top: ${base * 5}px;
      font-weight: normal;
    }
    .quote {
      background: #efefef;
      padding: ${base * 4}px ${base * 2}px;
      border-radius: 135%/20px 20px 0 0;
    }
    .project-types,
    .project-sizes {
      align-items: flex-start;
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .project-size,
    .project-type {
      font-size: 16px;
      @media (max-width: 700px) {
        font-size: 12px;
        width: 100px;
      }
    }

    .presser {
      border: 2px solid #ccc;
      border-radius: 3px;
      margin-bottom: ${base}px;
      width: 200px;
      height: 123px;
      position: relative;
      background: #fff;

      @media (max-width: 700px) {
        width: 100%;
        height: 80px;
      }

      img {
        height: 100%;
      }

      .selected {
        display: none;
        background: #fff;
      }

      &.active {
        border: 2px solid green;
        box-shadow: 0px 3px 6px -3px #333;
        .selected {
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          transform: translate(4px, 6px);
          border-radius: inherit;
          height: 20px;
          width: 20px;
          @media (max-width: 700px) {
            height: 18px;
            width: 18px;
          }
          .icon {
            margin: 0;
            padding: 0;
            transform: translate(-6px, -4px);
          }
        }
      }
      &:hover {
        border: 2px solid green;
      }
    }

    .project-type,
    .project-size {
      display: block;
      margin: ${base}px;
      text-align: center;
      cursor: pointer;
    }
    .contact-info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: ${base * 2}px;
    }
    .submit-area {
      text-align: center;
    }
    .thanks {
      padding: ${base * 4}px;
      text-align: center;
      height: 400px;
      max-height: 100%;

      .note {
        font-size: 18px;
      }
    }
  }
  .signup {
    font-size: 28px;
    padding: ${base * 2}px;
    text-align: center;
    margin-bottom: ${base * 10}px;
    line-height: 38px;
  }
  .quotation {
    font-size: 28px;
    padding: ${base * 2}px;
    text-align: center;

    line-height: 38px;
  }
  .logo-bar {
    width: 100%;
    height: 116px;
    background: #fff url(${logoBar}) center center no-repeat;
    background-size: cover;

    @media (max-width: 700px) {
      height: 56px;
    }
  }
  footer {
    background: #333;
    color: #fff;
    font-size: 18px;
    padding: ${base * 3}px;
    > div {
      padding: ${base}px;
    }
    .icon {
      margin-right: ${base}px;
    }
  }
`;
