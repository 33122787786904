import { DesignElement, Sprinkler, WaterGroup, Zone } from '@shared-types';
import * as math from 'mathjs';
import paper from 'src/paper';
import { uniq } from 'underscore';
import { paperItemStore } from '../helpers';
import { getAngle } from '../helpers/geometry.helpers';
import { getGeneratePerfData } from '../helpers/nozzleTransformer';
import { mapHeadIdsToHeads } from '../helpers/pdf/mapHeadIdsToHeads';
import { localPaper } from '../localPaper';
import { isPaperPolyLine } from '../polyline/paper-polyline';
import { getPolylineYards } from '../polyline/polyline-helpers';
import { getPointAlongLine } from '../shared/geometry';
import { ITEMNAMES, LAYER_NAMES } from '../shared/workbench-enums';
import { clearSelectedItems, getState, selectItems } from '../state';
import { changeElements } from '../state/changeElements';
import { deleteElements } from '../state/deleteElements';
import { arrowState, resetArrowMode } from '../tools/arrow.tool';
import {
  isPaperSprinkler,
  isSprinkler,
} from '../tools/paper-items/paper-sprinkler';
import { updateSprinkler } from '../tools/sprinkler';
import { activateNamedLayer, getColor } from './plot.helpers';
import { handleCache, headArcHandles } from './plotter.helpers';

const angles: number[] = [];

for (let i = 360; i >= 45; i -= 15) {
  angles.push(i);
}
const rotations: number[] = [];
for (let i = 0; i < 345; i += 15) {
  rotations.push(i);
}
export const sprinklerSymbols: {
  rotorSymbol: paper.SymbolDefinition | null;
  spraySymbol: paper.SymbolDefinition | null;
  dripSymbol: paper.SymbolDefinition | null;
  stripSymbol: paper.SymbolDefinition | null;
  currentScale: number;
  currentHeadSize: number;
  sprayByRadius: {
    [key: string]: paper.SymbolDefinition | null;
  };
  stripByRadius: {
    [key: string]: paper.SymbolDefinition | null;
  };
  rotorByRadius: {
    [key: string]: paper.SymbolDefinition | null;
  };
} = {
  rotorSymbol: null,
  spraySymbol: null,
  dripSymbol: null,
  stripSymbol: null,
  currentScale: 0,
  currentHeadSize: 0,
  sprayByRadius: {},
  stripByRadius: {},
  rotorByRadius: {},
};
const styleIcon = (
  icon: paper.Item,
  scale: number,
  showHeadPreviews: boolean,
) => {
  icon.fillColor = getColor('#ffffff');
  if (showHeadPreviews) {
    icon.strokeColor = getColor('#333333');
    icon.strokeWidth = 1 / scale;
  }
  icon.name = ITEMNAMES.TMP_ICON;
};

const createRotorIcon = (
  scale: number,
  headSize: number,
  radius: string,
  showHeadPreviews: boolean,
): paper.Group => {
  const realPx = headSize;
  const g = new paper.Group();
  const icon = new paper.Shape.Circle(new paper.Point(0, 0), realPx / scale);
  styleIcon(icon, scale, showHeadPreviews);
  const scaledFont = headSize / scale;
  const headText = headIconText(new paper.Point(0, 0), radius, scaledFont);
  headText.position = new paper.Point(
    headText.position.x,
    headText.position.y + scaledFont / 4,
  );
  if (radius === '!') {
    headText.fillColor = new paper.Color('white');
    headText.fontWeight = 'bold';
    icon.fillColor = new paper.Color('#999');
  }
  g.addChildren([icon, headText]);
  return g;
};
const createSprayIcon = (
  scale: number,
  headSize: number,
  radius: string,
  showHeadPreviews: boolean,
): paper.Group => {
  const realPx = headSize;
  const g = new paper.Group();
  const icon = new paper.Path.RegularPolygon(
    new paper.Point(0, 0),
    6,
    realPx / scale,
  );
  styleIcon(icon, scale, showHeadPreviews);
  const scaledFont = headSize / scale;
  const headText = headIconText(new paper.Point(0, 0), radius, scaledFont);
  headText.position = new paper.Point(
    headText.position.x,
    headText.position.y + scaledFont / 4,
  );
  if (radius === '!') {
    headText.fontWeight = 'bold';
    headText.fillColor = new paper.Color('white');
    icon.fillColor = new paper.Color('#999');
  }
  g.addChildren([icon, headText]);
  return g;
};
const createStripIcon = (
  scale: number,
  headSize: number,
  width: string,
  showHeadPreviews: boolean,
): paper.Group => {
  const realPx = headSize;
  const g = new paper.Group();
  const icon = new paper.Path.RegularPolygon(
    new paper.Point(0, 0),
    3,
    (realPx / scale) * 1.2,
  );
  styleIcon(icon, scale, showHeadPreviews);
  const scaledFont = headSize / scale;
  const headText = headIconText(new paper.Point(0, 0), width, scaledFont);
  headText.position = new paper.Point(
    headText.position.x,
    headText.position.y + scaledFont / 4,
  );
  if (width === '!') {
    headText.fillColor = new paper.Color('white');
    headText.fontWeight = 'bold';
    icon.fillColor = new paper.Color('#999');
  }
  g.addChildren([icon, headText]);
  return g;
};

export const storeSymbols = (scale: number, headSize: number) => {
  if (
    !sprinklerSymbols.rotorSymbol ||
    sprinklerSymbols.currentScale !== scale ||
    sprinklerSymbols.currentHeadSize !== headSize
  ) {
    sprinklerSymbols.rotorSymbol = new paper.SymbolDefinition(
      createRotorIcon(scale, headSize, '1', true),
    );
    for (const radius of [...new Array(300)].map((_, i) => i + 14)) {
      sprinklerSymbols.rotorByRadius[radius] = new paper.SymbolDefinition(
        createRotorIcon(scale, headSize, `${radius}`, true),
      );
    }
    sprinklerSymbols.rotorByRadius['!'] = new paper.SymbolDefinition(
      createRotorIcon(scale, headSize, '!', true),
    );
  }
  if (
    !sprinklerSymbols.spraySymbol ||
    sprinklerSymbols.currentScale !== scale ||
    sprinklerSymbols.currentHeadSize !== headSize
  ) {
    sprinklerSymbols.spraySymbol = new paper.SymbolDefinition(
      createSprayIcon(scale, headSize, '1', true),
    );
    for (const radius of [...new Array(200)].map((_, i) => i + 3)) {
      sprinklerSymbols.sprayByRadius[radius] = new paper.SymbolDefinition(
        createSprayIcon(scale, headSize, `${radius}`, true),
      );
    }
    sprinklerSymbols.sprayByRadius['!'] = new paper.SymbolDefinition(
      createSprayIcon(scale, headSize, '!', true),
    );
  }
  if (
    !sprinklerSymbols.stripSymbol ||
    sprinklerSymbols.currentScale !== scale ||
    sprinklerSymbols.currentHeadSize !== headSize
  ) {
    sprinklerSymbols.stripSymbol = new paper.SymbolDefinition(
      createStripIcon(scale, headSize, '1', true),
    );
    for (const radius of [...new Array(40)].map((_, i) => i + 2)) {
      sprinklerSymbols.stripByRadius[radius] = new paper.SymbolDefinition(
        createStripIcon(scale, headSize, `${radius}`, true),
      );
    }
    sprinklerSymbols.stripByRadius['!'] = new paper.SymbolDefinition(
      createStripIcon(scale, headSize, '!', true),
    );
  }
  sprinklerSymbols.currentHeadSize = headSize;
  sprinklerSymbols.currentScale = scale;
};

export const headIconText = (
  p: paper.Point,
  content: string,
  fontSize: number,
): paper.PointText => {
  const headText = new paper.PointText(new paper.Point(p.x, p.y));
  headText.translate(new paper.Point(0, fontSize * 0.1));
  headText.content = content;
  headText.fontSize = fontSize;
  headText.justification = 'center';
  return headText;
};

export const bulkResizeSprinklerParts = () => {
  const { elements, headClueSize, headZoneDotSize } = getState();
  elements.filter(isSprinkler).forEach((el) => {
    const item = paperItemStore.get(el.uuid);
    if (item && isPaperSprinkler(item)) {
      item.updateZoneDotSize(headZoneDotSize);
      item.updateSmallArc(headClueSize);
      item.updateHeadSize();
    }
  });
};

export const selectYardHeads = () => {
  const { elements, activeYardIndex } = getState();
  const polylineYards = getPolylineYards();
  const activeYard = polylineYards[activeYardIndex];
  const paperPolyline = paperItemStore.get(activeYard.uuid);
  if (paperPolyline && isPaperPolyLine(paperPolyline)) {
    const heads = elements.filter((el) =>
      paperPolyline.paperItem.contains(new paper.Point(el.position)),
    );
    const headIDs = heads.map((h) => h.uuid);
    const paperHeads = localPaper.project.getItems({
      match: (h) =>
        headIDs.includes(h.data.uuid) &&
        h.name === ITEMNAMES.ELEMENT &&
        (h.data as DesignElement).type === 'sprinkler',
    });
    paperHeads.forEach((head) => (head.selected = true));
    selectItems(paperHeads);
  }
};

export const deleteAllHeads = () => {
  const elements = getState().elements;
  const heads = elements.filter(isSprinkler);
  clearSelectedItems();
  deleteElements(heads.map((h) => h.uuid));
};

export const getSelectedHeads = (
  filterOutZone?: Zone | WaterGroup,
): DesignElement[] => {
  // if filterOutZone, we don't want to send in the heads already in that zone
  const selectedItems = getState().selectedItems as paper.Item[];
  const heads: DesignElement[] = [];
  selectedItems.forEach((item) => {
    if (item.data?.uuid) {
      const el = getState().elementCache[item.data.uuid];
      if (isSprinkler(el)) {
        if (filterOutZone && filterOutZone.headIds.includes(el.uuid)) {
          return;
        }
        heads.push(el);
      }
    }
  });
  return heads;
};
export const doSprinklerThings = (item: paper.Item, e: paper.KeyEvent) => {
  const el = item.data as DesignElement;
  if (!isSprinkler(el)) return;
  const sprinkler = el.props;
  const filteredData = getGeneratePerfData(sprinkler.base);
  const uniqueAngles = uniq(filteredData.map((n) => n.angle)).sort(
    (a, b) => a - b,
  );
  const minAngle = Math.min(...uniqueAngles);
  const maxAngle = Math.max(...uniqueAngles);
  const fixed = filteredData[0].fixed;
  if (!fixed) {
    let newAngle = sprinkler.angle;
    if (e.key === 'h' && 180 >= minAngle && 180 <= maxAngle) {
      newAngle = 180;
    }
    if (e.key === 'q' && 90 >= minAngle && 90 <= maxAngle) {
      newAngle = 90;
    }
    if (e.key === 'f') {
      // if (e.key === 'f' && 360 >= minAngle && 360 <= maxAngle) {
      newAngle = maxAngle;
    }
    if (e.key === 'g') {
      newAngle = minAngle;
    }
    if (newAngle !== sprinkler.angle) {
      const updatedSprinkler: Sprinkler = updateSprinkler(sprinkler, {
        ...sprinkler,
        angle: newAngle,
      });
      const sprinklerEl: DesignElement = {
        ...el,
        props: updatedSprinkler,
      };
      changeElements([sprinklerEl]);
    }
  }
};
export const createSelectedSprinklerHandles = (): void => {
  destroyHandles();
  const sprinklerItem = localPaper.project.selectedItems.find(
    (item) =>
      item.name === ITEMNAMES.ELEMENT &&
      (item.data as DesignElement).type === 'sprinkler',
  );
  if (sprinklerItem) {
    if (!isSprinkler(sprinklerItem.data)) return;
    let sprinkler = sprinklerItem.data.props;
    const filteredData = getGeneratePerfData(sprinkler.base);
    if (!filteredData.length || filteredData[0].fixed) {
      resetArrowMode();
    } else {
      const origin = { x: sprinkler.x, y: sprinkler.y };
      activateNamedLayer(LAYER_NAMES.DEFAULT);
      const handles = headArcHandles(sprinkler, origin, getState().scale);
      handles.forEach((handle) => {
        if (handle.name === ITEMNAMES.HANDLE_FROM) {
          arrowState.handleFrom = handle;
        }
        if (handle.name === ITEMNAMES.HANDLE_TO) {
          arrowState.handleTo = handle;
        }
        handle.bringToFront();
      });
      arrowState.selectedHandle = null;
    }
  }
};
export const destroyHandles = () => {
  arrowState.handleFrom = null;
  arrowState.handleTo = null;
  arrowState.selectedHandle = null;
  Object.keys(handleCache).forEach((key) => {
    handleCache[key].remove();
    delete handleCache[key];
  });
};
export const rotateArc = (currentPoint: paper.Point) => {
  const selectedItems = localPaper.project.selectedItems;
  const fromHandle = arrowState.handleFrom;
  const toHandle = arrowState.handleTo;
  const item = selectedItems.find(
    (item) =>
      item.name === ITEMNAMES.ELEMENT &&
      (item.data as DesignElement).type === 'sprinkler',
  );
  const sprinklerItem = paperItemStore.get(item?.data.uuid);
  if (sprinklerItem && isPaperSprinkler(sprinklerItem)) {
    if (!isSprinkler(sprinklerItem.paperGroup.data)) return;
    const sprinklerPivot = sprinklerItem.getRealItem().props;
    const sprinkler = sprinklerItem.paperGroup.data.props;
    const p2 = getPointAlongLine(sprinkler.radius, {
      start: sprinklerPivot,
      end: currentPoint,
    });
    let newSprinkler = sprinkler;
    if (
      arrowState.downPoint &&
      fromHandle &&
      toHandle &&
      arrowState.handleStart &&
      arrowState.selectedHandle
    ) {
      if (arrowState.selectedHandle.data.uuid === fromHandle.data.uuid) {
        const movedAngle = getAngle(
          arrowState.handleStart,
          sprinklerPivot,
          p2,
          true,
        );
        newSprinkler = updateSprinkler(sprinkler, {
          ...sprinkler,
          angle: getAngle(p2, sprinklerPivot, toHandle.position, true),
          rotation:
            (arrowState.selectedHandle.data.sprinkler.rotation + movedAngle) %
            360,
        });
        arrowState.selectedHandle.position = new paper.Point(p2);
        sprinklerItem.updateArc(newSprinkler);
      } else if (arrowState.selectedHandle.data.uuid === toHandle.data.uuid) {
        newSprinkler = updateSprinkler(sprinkler, {
          ...sprinkler,
          angle: getAngle(fromHandle.position, sprinklerPivot, p2, true),
        });
        arrowState.selectedHandle.position = new paper.Point(p2);
        sprinklerItem.updateArc(newSprinkler);
      }
    }
  } else {
    console.log('hmmm, not finding a sprinkler');
  }
};
export const headsGPM = (heads: paper.Group[]) => {
  return heads.reduce((acc, curr) => {
    const el = curr.data as DesignElement;
    if (!isSprinkler(el)) return acc;
    return acc + (el.props as Sprinkler).gpm;
  }, 0);
};
export const getAvgPrecip = (
  heads: DesignElement[],
  headIds: string[],
): number =>
  headIds.length > 0
    ? math.mean(
        mapHeadIdsToHeads(heads, headIds).map((matchingHead: Sprinkler) =>
          matchingHead ? matchingHead.precipAverage : 0,
        ),
      )
    : 0;
export const getPrecipStDev = (
  heads: DesignElement[],
  headIds: string[],
): number =>
  headIds.length > 0
    ? math.std(
        ...headIds.map((uuid: string): number => {
          const matchingHead = heads.find(
            (head: DesignElement) => head.uuid === uuid,
          );
          return matchingHead && isSprinkler(matchingHead)
            ? matchingHead.props.precipAverage
            : 0;
        }),
      )
    : 0;
