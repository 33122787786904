import { BaseItem, BaseStyledItem, Basemap } from '@shared-types';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Icon } from 'semantic-ui-react';
import { styled } from 'styled-components';
import { isBasemap, paperItemStore } from '../helpers';
import { localPaper } from '../localPaper';
import { getEuclideanDistance } from '../shared/geometry';
import { useDesignStore } from '../state';
import { updateItem } from '../state/item-state';
import { scaleBasemap } from '../upload/basemap.service';
import { isPaperBasemap } from '../upload/paper-basemap';
import { scales } from '../upload/upload.helpers';
import { EditOpacity } from './EditOpacity';
import { EditRotation } from './EditRotation';
import {
  PanelInputWrap,
  PanelLabel,
  PanelSelect,
  PanelWrap,
} from './PanelButton';

interface Props {
  item: Basemap;
}
export const BasemapActions = ({ item }: Props) => {
  const liveItem = useDesignStore(
    // have to get the live item because it could be changed from paper.js
    (state) => state.items.filter(isBasemap).find((i) => i.uuid === item.uuid)!,
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  );
  const [distance, setDistance] = useState('');
  const [overrideDistance, setOverrideDistance] = useState(0);
  const [showActions, setShowActions] = useState(true);
  // const [showCropBox, setShowCropBox] = useState(false);
  const [showScaleHandles, setShowScaleHandles] = useState(false);
  const [sizeMode, setSizeMode] = useState('');
  const [selectedScale, setSelectedScale] = useState(scales[0].value);
  const [pivot, setPivot] = useState('topLeft');

  useEffect(() => {
    const d = getEuclideanDistance(
      liveItem.knownPoints.a,
      liveItem.knownPoints.b,
    );
    setSelectedScale(liveItem.scale);
    setDistance(d.toFixed(2));
  }, [liveItem]);

  useEffect(() => {
    if (sizeMode === 'knownPoints') {
      beginSetSize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeMode]);

  // useEffect(() => {
  //   const paperBasemap = paperItemStore.get(liveItem.uuid);
  //   if (paperBasemap && isPaperBasemap(paperBasemap)) {
  //     paperBasemap.showCropbox(showCropBox);
  //   }
  // }, [showCropBox, liveItem]);

  useEffect(() => {
    const paperBasemap = paperItemStore.get(liveItem.uuid);
    if (paperBasemap && isPaperBasemap(paperBasemap)) {
      paperBasemap.showScaleHandles(showScaleHandles);
    }
  }, [showScaleHandles, liveItem]);

  const setScale = () => {
    const newBasemap: Basemap = {
      ...liveItem,
      scale: selectedScale,
      // cropBox: {
      //   topLeft: {
      //     x: liveItem.position.x,
      //     y: liveItem.position.y,
      //   },
      //   bottomRight: {
      //     x: liveItem.position.x + (liveItem.width * selectedScale) / 72,
      //     y: liveItem.position.y + (liveItem.height * selectedScale) / 72,
      //   },
      // },
    };
    updateItem(newBasemap);
    setSizeMode('');
  };

  const beginSetSize = () => {
    const paperBasemap = paperItemStore.get(item.uuid);
    if (paperBasemap && isPaperBasemap(paperBasemap)) {
      paperBasemap.showKnownPoints(true);
      const d = getEuclideanDistance(item.knownPoints.a, item.knownPoints.b);
      setDistance(d.toFixed(2));
    }
  };

  const handleDistanceOverride = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setOverrideDistance(parseFloat(e.target.value));
    }
  };

  const changeScale = () => {
    const scaleFactor = overrideDistance / parseFloat(distance);
    const newScale = liveItem.scale / scaleFactor;
    const newBasemap: Basemap = {
      ...liveItem,
      scale: newScale,
      cropBox: {
        topLeft: {
          x: liveItem.position.x,
          y: liveItem.position.y,
        },
        bottomRight: {
          x: liveItem.position.x + liveItem.width / newScale,
          y: liveItem.position.y + liveItem.height / newScale,
        },
      },
    };
    updateItem(newBasemap);
    setDistance('');
    setSizeMode('');
  };

  const handleWidthOverride = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const newBasemap = scaleBasemap(
        liveItem,
        pivot,
        Math.round(parseFloat(e.target.value)),
      );
      updateItem(newBasemap);
    }
  };

  const changeStyle = (newItem: BaseStyledItem) => {
    updateItem(newItem);
  };
  const togglePrintable = () => {
    const newBasemap: Basemap = {
      ...liveItem,
      visible: !liveItem.visible,
    };
    updateItem(newBasemap);
  };
  const updateRotation = (newItem: BaseItem[]) => {
    const b = newItem[0];
    if (isBasemap(b)) {
      updateItem(b);
    }
  };

  const resetKnownPoints = () => {
    const center = localPaper.view.center;
    const newBasemap: Basemap = {
      ...liveItem,
      knownPoints: {
        a: { x: center.x, y: center.y },
        b: { x: center.x + 10, y: center.y + 10 },
      },
    };
    updateItem(newBasemap);
  };

  return (
    <Wrap>
      <PanelWrap>
        <PanelLabel onClick={() => setShowActions(!showActions)}>
          <Icon name={showActions ? 'caret down' : 'caret right'}></Icon>{' '}
          Basemap Actions
        </PanelLabel>
        {showActions && (
          <>
            <EditOpacity item={liveItem} onChange={changeStyle} />

            <table>
              <tbody>
                <tr>
                  <td>Original Page</td>
                  <td>
                    {liveItem.width}px wide, {liveItem.height}px tall
                  </td>
                </tr>
                <tr>
                  <td>px Scale</td>
                  <td>{liveItem.scale.toFixed(2)}px = 1ft</td>
                </tr>
                <tr>
                  <td>Scale</td>
                  <td>1 inch (72px) = {(72 / liveItem.scale).toFixed(2)}ft</td>
                </tr>
                <tr>
                  <td>Scaled Page</td>
                  <td>
                    {(liveItem.width * (1 / liveItem.scale)).toFixed(2)}px wide,{' '}
                    {(liveItem.height * (1 / liveItem.scale)).toFixed(2)}px tall
                  </td>
                </tr>
                <tr>
                  <td valign="top">Cropbox</td>
                  <td>
                    {(
                      liveItem.cropBox.bottomRight.x -
                      liveItem.cropBox.topLeft.x
                    ).toFixed(2)}
                    px wide,{' '}
                    {(
                      liveItem.cropBox.bottomRight.y -
                      liveItem.cropBox.topLeft.y
                    ).toFixed(2)}
                    px tall
                    <br />
                    Offset Left:{' '}
                    {(liveItem.cropBox.topLeft.x - liveItem.position.x).toFixed(
                      2,
                    )}
                    px
                    <br />
                    Offset Top:{' '}
                    {(liveItem.cropBox.topLeft.y - liveItem.position.y).toFixed(
                      2,
                    )}
                    px
                  </td>
                </tr>
              </tbody>
            </table>
            <PanelInputWrap
              label={'Left Crop Offset'}
              onChange={(e) => {
                const newBasemap = {
                  ...liveItem,
                  cropBox: {
                    ...liveItem.cropBox,
                    topLeft: {
                      x: liveItem.position.x + parseFloat(e.target.value),
                      y: liveItem.cropBox.topLeft.y,
                    },
                  },
                };
                updateItem(newBasemap);
              }}
              min={0}
              step={0.1}
              type="number"
              value={liveItem.cropBox.topLeft.x - liveItem.position.x}
            />
            <PanelInputWrap
              label={'Top Crop Offset'}
              onChange={(e) => {
                const newBasemap = {
                  ...liveItem,
                  cropBox: {
                    ...liveItem.cropBox,
                    topLeft: {
                      x: liveItem.cropBox.topLeft.x,
                      y: liveItem.position.y + parseFloat(e.target.value),
                    },
                  },
                };
                updateItem(newBasemap);
              }}
              min={0}
              step={0.1}
              type="number"
              value={liveItem.cropBox.topLeft.y - liveItem.position.y}
            />
            <PanelInputWrap
              label={'Right Crop Offset'}
              onChange={(e) => {
                const newBasemap = {
                  ...liveItem,
                  cropBox: {
                    ...liveItem.cropBox,
                    bottomRight: {
                      x: liveItem.position.x + parseFloat(e.target.value),
                      y: liveItem.cropBox.bottomRight.y,
                    },
                  },
                };
                updateItem(newBasemap);
              }}
              min={0}
              step={0.1}
              type="number"
              value={liveItem.cropBox.bottomRight.x - liveItem.position.x}
            />
            <PanelInputWrap
              label={'Bottom Crop Offset'}
              onChange={(e) => {
                const newBasemap = {
                  ...liveItem,
                  cropBox: {
                    ...liveItem.cropBox,
                    bottomRight: {
                      x: liveItem.cropBox.bottomRight.x,
                      y: liveItem.position.y + parseFloat(e.target.value),
                    },
                  },
                };
                updateItem(newBasemap);
              }}
              min={0}
              step={0.1}
              type="number"
              value={liveItem.cropBox.bottomRight.y - liveItem.position.y}
            />
            {/* <Button onClick={() => setShowCropBox(!showCropBox)}>
              {showCropBox ? 'Hide' : 'Show'} Crop Box
            </Button> */}
            <EditRotation items={[liveItem]} onChange={updateRotation} />
            <Checkbox
              label="Printable"
              checked={liveItem.visible}
              onClick={togglePrintable}
            />

            <PanelLabel>Size</PanelLabel>
            {scales.find((s) => s.value === liveItem.scale) && (
              <div>
                Scale: {scales.find((s) => s.value === liveItem.scale)?.label}
                <Button size="mini" onClick={() => setSizeMode('edit')}>
                  Edit
                </Button>
              </div>
            )}
            {!scales.find((s) => s.value === liveItem.scale) && (
              <div>
                <div>
                  Width: {Math.round(liveItem.width / liveItem.scale)}
                  <br />
                  Height: {Math.round(liveItem.height / liveItem.scale)}
                </div>
                <div>
                  <Button size="mini" onClick={() => setSizeMode('edit')}>
                    Edit
                  </Button>
                </div>
              </div>
            )}
            {sizeMode === 'edit' && (
              <div>
                <div onClick={() => setSizeMode('preset')}>
                  Use Preset Scale
                </div>
                <div onClick={() => setSizeMode('dimensions')}>
                  Set Dimensions
                </div>
                <div onClick={() => setSizeMode('knownPoints')}>
                  Set 2 Known Points
                </div>
                <div onClick={() => setShowScaleHandles(true)}>
                  Scale Manually
                </div>
              </div>
            )}

            {sizeMode === 'preset' && (
              <div>
                <PanelSelect
                  value={selectedScale}
                  options={scales.map((s) => ({
                    text: s.label,
                    value: s.value,
                  }))}
                  onChange={(e, data) => {
                    setSelectedScale(data.value as number);
                  }}
                />
                <Button onClick={() => setSizeMode('')}>Cancel</Button>
                <Button onClick={setScale}>Save</Button>
              </div>
            )}

            {sizeMode === 'knownPoints' && (
              <div className="custom-distance">
                Use the ruler to measure the longest known distance on the map
                and input the length below. Or if you already know the scale of
                the map, choose from one of the presets.
                <br />
                <br /> Current Distance: {distance}'
                <br />
                Override Distance: {overrideDistance.toFixed(2)}'
                <br />
                <div className="custom-set-distance">
                  <div className="custom-input-wrap">
                    <PanelInputWrap
                      label={'Distance'}
                      onChange={handleDistanceOverride}
                      min={0.1}
                      step={0.1}
                      type="number"
                      value={overrideDistance}
                    />
                  </div>
                </div>
                <br />
                <Button onClick={() => setSizeMode('')}>Cancel</Button>
                <Button onClick={changeScale}>Save</Button>
                <Button onClick={resetKnownPoints}>Reset Points</Button>
              </div>
            )}
            {sizeMode === 'dimensions' && (
              <div>
                <br />
                <Button
                  primary={pivot === 'topLeft'}
                  size="mini"
                  onClick={() => setPivot('topLeft')}
                >
                  Top Left
                </Button>
                <Button
                  primary={pivot === 'topRight'}
                  size="mini"
                  onClick={() => setPivot('topRight')}
                >
                  Top Right
                </Button>
                <Button
                  primary={pivot === 'bottomLeft'}
                  size="mini"
                  onClick={() => setPivot('bottomLeft')}
                >
                  Bottom Left
                </Button>
                <Button
                  primary={pivot === 'bottomRight'}
                  size="mini"
                  onClick={() => setPivot('bottomRight')}
                >
                  Bottom Right
                </Button>
                <div className="custom-set-distance">
                  <div className="custom-input-wrap">
                    <PanelInputWrap
                      label={'Width'}
                      onChange={handleWidthOverride}
                      min={1}
                      step={1}
                      type="number"
                      value={Math.round(liveItem.width / liveItem.scale)}
                    />
                  </div>
                </div>
                <br />
                <div className="custom-set-distance">
                  <div className="custom-input-wrap">
                    <PanelInputWrap
                      label={'Height'}
                      min={1}
                      step={1}
                      disabled={true}
                      type="number"
                      value={Math.round(liveItem.height / liveItem.scale)}
                    />
                  </div>
                </div>
                <Button onClick={() => setSizeMode('')}>Cancel</Button>
                <Button onClick={() => setSizeMode('')}>Save</Button>
              </div>
            )}
          </>
        )}
      </PanelWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  .custom-distance {
    background: #666;
    padding: 8px;
    margin: 8px 0;
  }
  .custom-set-distance {
    display: flex;
    align-items: flex-end;
    .custom-input-wrap {
      flex: 1;
    }
    button {
      margin-left: 4px;
      flex: 0 0 60px;
      margin-bottom: 0;
      text-align: center;
    }
  }
  .known-scales {
    .known-scale {
      display: inline-block;
      color: #efefef;
      text-decoration: underline;
      padding: 4px;
      cursor: pointer;
    }
  }
`;
