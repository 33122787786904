import paper from 'src/paper';
import { Basemap, IPoint } from '../../../../../shared-types/workbench-types';
import { wrapFetch } from '../../../services/fetchHelpers';
import { polyHintPurple } from '../../../shared';
import { localZoom } from '../localPaper';
import { getColor, transparentColor } from '../paper-helpers/plot.helpers';
import { PaperItem } from '../tools/paper-items/paper-item';
import { basemapService } from './basemap.service';

export const getPresignedBasemapUpload = async (
  filename: string,
  quality: number,
) => {
  return await wrapFetch<{ presignedURL: string }>(
    `/files/presigned-image?filename=${filename}.png`,
  );
};

export const getPresignedImageUpload = async (
  filename: string,
  extension = 'png',
) => {
  return await wrapFetch<{ presignedURL: string }>(
    `/files/presigned-image?filename=${filename}.${extension}`,
  );
};

export const getPresignedBasemapPDFUpload = async (filename: string) => {
  return await wrapFetch<{ presignedURL: string }>(
    `/files/presigned-image?filename=${filename}.pdf`,
  );
};

export const isPaperBasemap = (item: PaperItem<any>): item is PaperBasemap => {
  return item instanceof PaperBasemap;
};

export const cropBoxHandleName = 'handle.cropBox';
export const knownPointHandleName = 'handle.known-point';
export const scaleHandleName = 'handle.scale';

const createCropBoxHandle = (
  position: IPoint,
  type: 'topLeft' | 'bottomRight',
  uuid: string,
) => {
  const handle = new paper.Path.Circle({
    center: new paper.Point(position),
    radius: 7,
    fillColor: getColor('black'),
  });
  handle.name = cropBoxHandleName;
  handle.data = {
    basemapID: uuid,
    type,
  };
  return handle;
};

const createScaleHandle = (
  position: IPoint,
  type: 'topLeft' | 'bottomRight',
  uuid: string,
) => {
  const handle = new paper.Path.Circle({
    center: new paper.Point(position),
    radius: 3 / localZoom(),
    fillColor: getColor('purple'),
  });
  handle.name = scaleHandleName;
  handle.data = {
    basemapID: uuid,
    type,
  };
  return handle;
};

export class PaperBasemap extends PaperItem<Basemap> {
  private _group = new paper.Group();
  private _item = basemapService.createItem();
  private _cropBoxHandles: paper.Path.Circle[] = [];
  private _scaleHandles: paper.Path.Circle[] = [];
  private _showKnownPoints = false;
  private _showCropBoxHandles = false;
  private _showScaleHandles = false;
  private _startHandle = new paper.Group();
  private _endHandle = new paper.Group();
  private _line = new paper.Path();

  get id() {
    return this._item.uuid;
  }
  get group() {
    return this._group;
  }
  get paperItem() {
    return this._group.getItem({ name: 'raster' });
  }
  get highlight() {
    return this._group.getItem({ name: 'highlight' });
  }
  get clipMask() {
    return this._group.getItem({ name: 'clip-mask' });
  }
  get realItem() {
    return this._item;
  }

  constructor(item: Basemap) {
    super();
    this.group.name = item.itemType;
    this.group.data = { uuid: item.uuid };
    this.create(item);
  }
  async create(item: Basemap) {
    this.group.removeChildren();
    await this.createRaster(item);
    const b = this.createClipMask(item);
    this.group.addChild(b);
    if (item.locked) {
      this.group.locked = true;
    }
    if (!item.visible) {
      this.group.visible = false;
    }
    this._item = item;
  }

  update(item: Basemap) {
    if (
      item.jpgUUID !== this._item.jpgUUID ||
      item.quality !== this._item.quality
    ) {
      this.create(item);
    } else if (item.scale !== this._item.scale) {
      // const newCenter = {
      //   x:
      //     item.position.x +
      //     (item.cropBox.bottomRight.x - item.cropBox.topLeft.x) / 2,
      //   y:
      //     item.position.y +
      //     (item.cropBox.bottomRight.y - item.cropBox.topLeft.y) / 2,
      // };

      this.paperItem.scale(this._item.scale, this._item.position);
      this.paperItem.scale(1 / item.scale, item.position);
      // const centerPosition = new paper.Point(newCenter);
      // this.paperItem.position = centerPosition;
      this.resetClipMask(item);
    } else if (
      item.cropBox.topLeft !== this._item.cropBox.topLeft ||
      item.cropBox.bottomRight !== this._item.cropBox.bottomRight
    ) {
      this.resetClipMask(item);
    }
    if (
      this._showKnownPoints &&
      (item.knownPoints.a !== this._item.knownPoints.a ||
        item.knownPoints.b !== this._item.knownPoints.b)
    ) {
      this.createKnownPoints(item);
    }
    if (this._showCropBoxHandles) {
      this.createCropBoxHandles(item);
    }
    if (this._showScaleHandles) {
      this.createScaleHandles(item);
    }
    if (this.highlight) {
      this.setSelectedStatus(true);
    }
    if (item.rotation !== this._item.rotation) {
      this.paperItem.rotation = item.rotation || 0;
      this.resetClipMask(item);
    }
    if (this._item.style.opacity !== item.style.opacity) {
      this.paperItem.opacity = item.style.opacity || 0.5;
    }
    if (item.locked !== this._item.locked) {
      this.group.locked = item.locked;
    }
    if (item.visible !== this._item.visible) {
      this.group.visible = item.visible;
    }
    this._item = item;
  }
  private resetClipMask = (item: Basemap) => {
    const b = this.createClipMask(item);
    this.clipMask.replaceWith(b);
  };
  private createRaster = async (item: Basemap) => {
    try {
      let url = '';
      if (item.original.type === 'image') {
        const extension = item.original.filename.split('.').pop();
        const res = await getPresignedImageUpload(item.jpgUUID, extension);
        url = res.presignedURL;
      } else {
        const res = await getPresignedBasemapUpload(item.jpgUUID, item.quality);
        url = res.presignedURL;
      }
      const topLeftPosition = new paper.Point(item.position);
      const p = new paper.Raster({
        source: url,
        position: new paper.Point(
          topLeftPosition.x + item.width / 2,
          topLeftPosition.y + item.height / 2,
        ),
      });
      p.scale(1 / item.scale, topLeftPosition);
      p.name = 'raster';
      p.data = item;
      p.opacity = item.style.opacity || 0.5;
      p.rotation = item.rotation || 0;
      this.group.addChild(p);
    } catch (err) {
      console.log('could not load image');
    }
  };
  createClipMask = (item: Basemap) => {
    const box = new paper.Path.Rectangle({
      from: new paper.Point(item.cropBox.topLeft),
      to: new paper.Point(item.cropBox.bottomRight),
    });
    // box.rotate(item.rotation, new paper.Point(item.position));
    // box.strokeColor = getColor('purple');
    // box.strokeWidth = 4;
    box.rotation = item.rotation;
    box.name = 'clip-mask';
    box.clipMask = true;
    return box;
  };

  private createCropBoxHandles(item: Basemap) {
    this._cropBoxHandles.forEach((h) => {
      h.remove();
    });
    const { topLeft, bottomRight } = item.cropBox;
    const tl = createCropBoxHandle(topLeft, 'topLeft', item.uuid);
    const br = createCropBoxHandle(bottomRight, 'bottomRight', item.uuid);
    tl.bringToFront();
    br.bringToFront();
    this._cropBoxHandles = [tl, br];
  }
  private hideCropBoxHandles() {
    this._cropBoxHandles.forEach((h) => {
      h.remove();
    });
    this._cropBoxHandles = [];
  }
  destroy() {
    this.group?.remove();
    this._cropBoxHandles.forEach((h) => {
      h.remove();
    });
  }
  getItem() {
    return this.group;
  }
  setPosition(point: IPoint): void {
    if (!this._item.locked) {
      this.group.position = new paper.Point(point);
      this._cropBoxHandles.forEach((v) => {
        v.visible = false;
      });
    }
  }
  setPivot(point: IPoint): void {
    if (!this._item.locked) {
      this.group.pivot = new paper.Point(point);
    }
  }
  // getRotation(): number {
  //   return this._item.rotation;
  // }
  // scaleItem(pivot: IPoint, scale: number): void {
  //   this.group.scale(scale, pivot);
  // }
  createKnownPoints(item: Basemap) {
    this.createLine(item);
    this.createKnownPointHandles(item);
  }
  private createKnownPointHandles = (item: Basemap) => {
    this._startHandle.removeChildren();
    const newHandle = this.createKnownHandle(item.knownPoints.a);
    this._startHandle.data = {
      basemapID: item.uuid,
      type: 'a',
    };
    this._startHandle.name = knownPointHandleName;
    const startLittleDot = this.createFocusDot(item.knownPoints.a);
    this._startHandle.addChildren([newHandle, startLittleDot]);

    this._endHandle.removeChildren();
    const newHandle2 = this.createKnownHandle(item.knownPoints.b);
    const endLittleDot = this.createFocusDot(item.knownPoints.b);
    this._endHandle.data = {
      basemapID: item.uuid,
      type: 'b',
    };
    this._endHandle.name = knownPointHandleName;
    this._endHandle.addChildren([newHandle2, endLittleDot]);
  };
  private createKnownHandle = (position: IPoint, color = 'red') => {
    const handle = new paper.Path.Circle(position, 30 / localZoom());
    handle.fillColor = transparentColor('white');
    handle.strokeColor = getColor(color);
    handle.strokeWidth = 2 / localZoom();
    handle.selected = false;
    return handle;
  };
  private createFocusDot = (position: IPoint) => {
    const littleDot = new paper.Path.Circle(position, 2 / localZoom());
    littleDot.fillColor = getColor('black');
    littleDot.locked = true;
    littleDot.selected = false;
    return littleDot;
  };
  private createLine = (item: Basemap) => {
    this._line.removeSegments();
    this._line.add(item.knownPoints.a);
    this._line.add(item.knownPoints.b);
    this._line.strokeColor = getColor(polyHintPurple);
    const z = localZoom();
    this._line.strokeWidth = 2 / z;
    this._line.dashArray = [6 / z, 4 / z, 6 / z, 4 / z];
    this._line.locked = true;
  };
  private createScaleHandles = (item: Basemap) => {
    this._scaleHandles.forEach((h) => {
      h.remove();
    });
    const topLeft = new paper.Point(item.position);
    const bottomRight = new paper.Point(
      item.position.x + item.width / item.scale,
      item.position.y + item.height / item.scale,
    );
    const tl = createScaleHandle(topLeft, 'topLeft', item.uuid);
    const br = createScaleHandle(bottomRight, 'bottomRight', item.uuid);
    tl.bringToFront();
    br.bringToFront();
    this._scaleHandles = [tl, br];
  };
  toggleHighlight(show: boolean): void {
    this.setSelectedStatus(show);
    this.showKnownPoints(false);
    this.showCropbox(false);
    this.showScaleHandles(false);
    this.group.selected = false;
  }
  setSelectedStatus(selected: boolean) {
    if (this.highlight) {
      this.highlight.remove();
    }
    if (selected) {
      const highlight = new paper.Path.Rectangle(this.paperItem.bounds);
      highlight.strokeWidth = 1;
      highlight.strokeColor = getColor('#94baf7');
      highlight.name = 'highlight';
      highlight.locked = true;
      this.group.addChild(highlight);
    }
  }
  showKnownPoints(show: boolean) {
    if (show) {
      this.hideCropBoxHandles();
      this.createKnownPoints(this._item);
    } else {
      this._startHandle?.removeChildren();
      this._endHandle?.removeChildren();
      this._line?.removeSegments();
    }
    this._showKnownPoints = show;
    this._startHandle.selected = false;
    this._endHandle.selected = false;
  }
  showCropbox(show: boolean) {
    this._showCropBoxHandles = show;
    if (show) {
      this.createCropBoxHandles(this._item);
    } else {
      // this.createCropBoxHandles(this._item);
      this.hideCropBoxHandles();
    }
  }
  showScaleHandles(show: boolean) {
    this._showScaleHandles = show;
    if (show) {
      this.createScaleHandles(this._item);
    } else {
      this._scaleHandles.forEach((h) => {
        h.remove();
      });
    }
  }
}
