import { createStore, useStore } from 'zustand';

export interface ModalStore {
  modal: string;
  openModal: (type: string) => void;
  closeModal: () => void;
}

const store = createStore<ModalStore>((set) => ({
  modal: '',
  openModal: (type: string) => set(() => ({ modal: type })),
  closeModal: () =>
    set(() => ({
      modal: '',
    })),
}));

export const setModalState = (args: Partial<ModalStore>) => {
  store.setState(args);
};
export const getModalState = (): ModalStore => {
  return store.getState();
};

export function useModalStore<T>(
  selector?: (state: ModalStore) => T,
  equals?: (a: T, b: T) => boolean,
) {
  return useStore(store, selector!, equals);
}

export const openModal = (type: string) => {
  setModalState({ modal: type });
};
