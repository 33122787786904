import styled from 'styled-components';
import { PanelLabel } from './PanelButton';
import { Slider } from './Slider';

export const NumberSlider = ({
  value,
  onChange,
  suffix = '',
  label,
  step = 1,
  min = 0,
  max = 100,
}: any) => {
  return (
    <>
      <PanelLabel>{label}</PanelLabel>
      <Wrap>
        <Slider
          value={value}
          min={min}
          step={step}
          max={max}
          onChange={onChange}
        />
        <div className="opacity-value">
          {value}
          {suffix}
        </div>
      </Wrap>
    </>
  );
};
export const Wrap = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  .opacity-value {
    flex: 0 0 60px;
    font-size: 14px;
    text-align: center;
  }
`;
