import { IEdge } from '@shared-types';
import { addUndo } from '../contexts/design/undo';
import { generateGraphs } from '../helpers/directedGraph';
import { hopPipes } from '../helpers/hopping';
import { getProtectedEdgeNodes } from '../tools/tool.helpers';
import { addElementsEdges } from './addElementsEdges';
import { paperState } from './paper.state';
import { getState, setState } from './state-basics';

export const deleteEdge = (uuid: string, undoable = true) => {
  console.log('delete edge', uuid);
  const oldEdge = getState().edges.find((edge: IEdge) => edge.uuid === uuid);
  const protectedEdgeNodes = getProtectedEdgeNodes();
  if (
    oldEdge &&
    (protectedEdgeNodes.includes(oldEdge.source) ||
      protectedEdgeNodes.includes(oldEdge.target))
  ) {
    console.error('cannot delete protected edge', uuid);
    return;
  }

  if (oldEdge) {
    if (undoable) {
      addUndo(() => {
        addElementsEdges([], [oldEdge], false);
      }, 'delete edge');
    }
    const { elements, zones, elementCache, scale, edges } = getState();
    let newEdges = edges.filter((edge: IEdge) => edge.uuid !== uuid);
    paperState.deleteItems([oldEdge.uuid]);
    const { masterGraph, pocGraphs } = generateGraphs(
      newEdges,
      elements,
      zones,
    );
    newEdges = hopPipes(
      zones,
      newEdges,
      elements,
      elementCache,
      scale,
      pocGraphs,
    );
    setState({
      edges: newEdges,
      masterGraph,
      pocGraphs,
    });
  } else {
    console.error('edge not found', uuid);
  }
};
