import {
  ArcValues,
  HopProperties,
  IEdge,
  IEdgeHopData,
  IPoint,
  LateralProperties,
} from '@shared-types';
import paper from 'src/paper';
import { itemSizes } from 'src/shared/constants';
import { createDefaultEdge } from '../../paper-helpers/edges';
import { activateNamedLayer, getColor } from '../../paper-helpers/plot.helpers';
import { getEuclideanDistance, getMidPoint } from '../../shared/geometry';
import { ITEMNAMES, LAYER_NAMES } from '../../shared/workbench-enums';
import { getState } from '../../state';
import { PaperItem } from './paper-item';

export const labelCache: { [key: number]: paper.SymbolDefinition } = {};

export const isEdgeHopData = (data: unknown): data is IEdgeHopData => {
  return (data as any).hasOwnProperty('edgeUUID');
};

export const isPaperPipe = (item: PaperItem<any>): item is PaperPipe => {
  return item instanceof PaperPipe;
};

export class PaperPipe implements PaperItem<IEdge> {
  edge: IEdge = createDefaultEdge({
    uuid: 'xyz', // sorted, colon separated source/target
  });
  private _labelVisible = false;
  private color: string = '#000';
  private _laterals: paper.Path[] = [];
  private _hops: paper.Path.Arc[] = [];
  private _labels: paper.SymbolItem[] = [];
  private _renderHop = true;

  constructor(edge: IEdge, color = '#000') {
    this.edge = edge;
    this.color = color;
    this.render();
    this.toggleLabel(getState().showPipeLabels);
  }
  setPosition(point: IPoint): void {
    throw new Error('Method not implemented.');
  }
  getItem(): paper.Item {
    throw new Error('Method not implemented.');
  }
  setPivot(point: IPoint): void {
    throw new Error('Method not implemented.');
  }
  toggleHighlight(): void {
    throw new Error('Method not implemented.');
  }

  get paperLaterals(): paper.Path[] {
    return this._laterals;
  }
  get paperHops(): paper.Path.Arc[] {
    return this._hops;
  }
  get paperLabels(): paper.SymbolItem[] {
    return this._labels;
  }
  get labelVisible() {
    return this._labelVisible;
  }
  set labelVisible(show: boolean) {
    this._labelVisible = show;
  }
  destroy = () => {
    this.paperLaterals.forEach((lateral) => {
      lateral.remove();
    });
    this.paperHops.forEach((hop) => {
      hop.remove();
    });
    this.paperLabels.forEach((label) => {
      label.remove();
    });
    this._laterals = [];
    this._hops = [];
    this._labels = [];
  };
  update = (edge: IEdge) => {
    if (edgeIsDifferent(edge, this.edge)) {
      this.edge = edge;
      this.render(true);
    }
  };
  updateColor = (color = '#000') => {
    if (color !== this.color) {
      this.color = color;
      this.colorPipe(color);
    }
  };
  _canShowLabel = (lateral: LateralProperties) => {
    const ids = new Set<string>();
    const { zones, pipeProducts, lateralPipeSizes, mainPipeSizes, scale } =
      getState();
    const minLateralSize = Math.min(...lateralPipeSizes);
    const minMainSize = Math.min(...mainPipeSizes);
    zones.forEach((zone) => {
      ids.add(zone.valveInputFitting);
      ids.add(zone.valve);
    });
    const e = this.edge;
    const pipeProd = pipeProducts.find((p) => p.uuid === this.edge.pipe);

    if (pipeProd && !ids.has(e.source) && !ids.has(e.target)) {
      if (
        e.showLabel ||
        (((e.isLateral && pipeProd.size !== minLateralSize) ||
          (!e.isLateral && !e.isDrip && pipeProd.size !== minMainSize) ||
          (e.isDrip && pipeProd.size !== minLateralSize)) &&
          // e.hoppedSections.length === 1 &&
          getEuclideanDistance(lateral.start, lateral.end) > 5)
      ) {
        if (
          e.showLabel ||
          (e.pipe &&
            getEuclideanDistance(lateral.start, lateral.end) > 3 / scale)
        ) {
          return true;
        }
      }
    }
    return false;
  };
  toggleLabel = (show: boolean) => {
    this.labelVisible = show;
    this.paperLabels.forEach((label) => {
      label.remove();
    });
    if (show) {
      this.showLabels();
    }
  };
  updateSelection = (selected: boolean) => {
    this.paperLaterals.forEach((lateral) => {
      lateral.selected = selected;
    });
    this.paperHops.forEach((hop) => {
      hop.selected = selected;
    });
  };
  private showLabels = () => {
    this.edge.hoppedSections.forEach((section) => {
      if (
        section.type === 'lateral' &&
        this._canShowLabel(section.values as LateralProperties)
      ) {
        this._labels.push(
          this.generateLabel(section.values as LateralProperties),
        );
      }
    });
  };
  private render = (replace = false) => {
    activateNamedLayer(LAYER_NAMES.EDGES);
    let selected = false;
    if (replace) {
      selected = !!this.paperLaterals.find((lateral) => lateral.selected);
      this.destroy();
    }
    if (this._renderHop) {
      this.edge.hoppedSections.forEach((section, hopIndex) => {
        if (section.type === 'lateral') {
          this._laterals.push(
            this.generateLateral(section.values as LateralProperties),
          );
        } else {
          this._hops.push(
            this.generateHop(section.values as HopProperties, hopIndex),
          );
        }
      });
    } else {
      const start = getState().elements.find(
        (el) => el.uuid == this.edge.source,
      );
      const end = getState().elements.find((el) => el.uuid == this.edge.target);
      if (start && end) {
        this._laterals.push(
          this.generateLateral({ start: start.position, end: end.position }),
        );
      }
    }
    if (this.labelVisible) {
      this.showLabels();
    }

    this._laterals.forEach((lateral) => {
      lateral.locked = this.edge.locked;
      lateral.visible = this.edge.visible;
      lateral.selected = selected;
    });
    this._hops.forEach((hop) => {
      hop.locked = this.edge.locked;
      hop.visible = this.edge.visible;
      hop.selected = selected;
    });
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  };
  private generateLabel = ({
    start,
    end,
  }: LateralProperties): paper.SymbolItem => {
    activateNamedLayer(LAYER_NAMES.EDGES);
    const { scale, pipeProducts } = getState();
    const pipe = pipeProducts.find((p) => p.uuid === this.edge.pipe);
    let labelInstance = new paper.SymbolItem(
      new paper.Path.Circle(new paper.Point(0, 0), 1),
    );
    if (pipe) {
      const midPoint = new paper.Point(getMidPoint(start, end));
      if (labelCache[pipe.size]) {
        labelInstance = labelCache[pipe.size].place(midPoint);
      } else {
        const pipeLabel = new paper.Group();
        const label = new paper.PointText(midPoint);
        label.content = `${pipe.size}"`;
        label.fontSize = itemSizes.edgeFont / scale;
        label.justification = 'center';
        const rect = new paper.Path.Rectangle(label.bounds);
        rect.scale(1.5, 1.2);
        rect.fillColor = getColor('#ffffff');
        rect.opacity = 0.8;
        pipeLabel.addChildren([rect, label]);
        const pipeLabelSymbol = new paper.SymbolDefinition(pipeLabel);
        labelInstance = pipeLabelSymbol.place(midPoint);
        labelCache[pipe.size] = pipeLabelSymbol;
      }
      labelInstance.name = ITEMNAMES.PIPE_TEXT;
      const data: IEdgeHopData = {
        edgeUUID: this.edge.uuid,
      };
      labelInstance.data = data;
    }
    labelInstance.bringToFront();
    activateNamedLayer(LAYER_NAMES.DEFAULT);
    return labelInstance;
  };
  private generateLateral = ({ start, end }: LateralProperties): paper.Path => {
    const { scale } = getState();
    const pipe = new paper.Path.Line(
      new paper.Point(start),
      new paper.Point(end),
    );
    pipe.strokeColor = getColor(this.color);
    pipe.strokeWidth = itemSizes.pipeSize / scale;
    pipe.strokeCap = 'round';
    pipe.name = ITEMNAMES.PIPE;
    pipe.data = {
      edgeUUID: this.edge.uuid,
    } as IEdgeHopData;
    pipe.visible = true;
    if (!this.edge.isLateral && !this.edge.isDrip) {
      pipe.dashArray = itemSizes.mainDashArray(scale);
    }
    if (this.edge.vertical) {
      pipe.dashArray = itemSizes.dripDashArray(scale);
    }
    return pipe;
  };
  private generateHop = (
    { pre, post, through }: HopProperties,
    hopIndex: number,
  ) => {
    const { scale } = getState();
    const arcValues: ArcValues = {
      from: [pre.x, pre.y],
      through: [through.x, through.y],
      to: [post.x, post.y],
    };
    const arc = new paper.Path.Arc(arcValues);
    arc.strokeColor = getColor(this.color);
    arc.strokeWidth = itemSizes.pipeSize / scale;
    const hopData: IEdgeHopData = {
      edgeUUID: this.edge.uuid,
      isHop: true,
      hopIndex: hopIndex,
    };
    arc.data = hopData;
    arc.name = ITEMNAMES.PIPE;

    return arc;
  };

  private colorPipe = (color: string) => {
    this.paperLaterals.forEach((lateral) => {
      lateral.strokeColor = getColor(color);
    });
    this.paperHops.forEach((hop) => {
      hop.strokeColor = getColor(color);
    });
  };
}

const edgeIsDifferent = (a: IEdge, b: IEdge) => {
  return (
    a.source !== b.source ||
    a.target !== b.target ||
    a.uuid !== b.uuid ||
    a.pipe !== b.pipe ||
    a.visible !== b.visible ||
    a.locked !== b.locked ||
    a.preExisting !== b.preExisting ||
    a.isLateral !== b.isLateral ||
    a.isDrip !== b.isDrip ||
    a.showLabel !== b.showLabel ||
    a.color !== b.color ||
    a.vertical !== b.vertical ||
    a.hoppedSections.length !== b.hoppedSections.length ||
    a.hoppedSections.some((section, i) => {
      if (section.type === 'lateral') {
        return (
          (section.values as LateralProperties).start.x !==
            (b.hoppedSections[i].values as LateralProperties).start.x ||
          (section.values as LateralProperties).start.y !==
            (b.hoppedSections[i].values as LateralProperties).start.y ||
          (section.values as LateralProperties).end.x !==
            (b.hoppedSections[i].values as LateralProperties).end.x ||
          (section.values as LateralProperties).end.y !==
            (b.hoppedSections[i].values as LateralProperties).end.y
        );
      } else {
        return (
          (section.values as HopProperties).pre.x !==
            (b.hoppedSections[i].values as HopProperties).pre.x ||
          (section.values as HopProperties).pre.y !==
            (b.hoppedSections[i].values as HopProperties).pre.y ||
          (section.values as HopProperties).through.x !==
            (b.hoppedSections[i].values as HopProperties).through.x ||
          (section.values as HopProperties).through.y !==
            (b.hoppedSections[i].values as HopProperties).through.y ||
          (section.values as HopProperties).post.x !==
            (b.hoppedSections[i].values as HopProperties).post.x ||
          (section.values as HopProperties).post.y !==
            (b.hoppedSections[i].values as HopProperties).post.y
        );
      }
    })
  );
};
