import { Circle } from '../../../../../shared-types';
import { updateItem } from '../state/item-state';
import { PanelInputWrap, PanelLabel, PanelWrap } from './PanelButton';

interface Props {
  item: Circle;
}
export const CircleActions = ({ item }: Props) => {
  return (
    <PanelWrap>
      <PanelLabel>Circle Actions</PanelLabel>
      <PanelInputWrap
        label="Radius"
        type="number"
        value={item.radius}
        step={0.1}
        onChange={(e) => {
          const newCircle: Circle = {
            ...item,
            radius: parseFloat(e.target.value),
          };
          updateItem(newCircle);
        }}
      />
    </PanelWrap>
  );
};
