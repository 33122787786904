import { Item } from 'semantic-ui-react';
import { PlantType, plantOptions } from 'src/shared/constants';
import styled from 'styled-components';
import { PlantBase } from '../../../../../shared-types';
import { setActivePlant, useDesignStore } from '../state';

import { ReactComponent as Deciduous } from '../../../images/plants/deciduous-tree.svg';
import { ReactComponent as Evergreen } from '../../../images/plants/evergreen.svg';
import { ReactComponent as Perennial } from '../../../images/plants/perennial.svg';

const plantMap = (type: string) => {
  switch (type) {
    case PlantType.DECIDUOUS_TREE:
      return <Deciduous />;
    case PlantType.EVERGREEN_TREE:
      return <Evergreen />;
    case PlantType.DECIDUOUS_SHRUB:
      return <Deciduous />;
    case PlantType.EVERGREEN_SHRUB:
      return <Evergreen />;
    case PlantType.PERENNIAL:
      return <Perennial />;
    default:
      return null;
  }
};

export const PlantToolProperties = () => {
  const activePlant = useDesignStore((state) => state.activePlant);

  const setPlant = (plant: PlantBase) => {
    setActivePlant(plant);
  };

  return (
    <Wrap>
      <h3>Active plant:</h3>
      <Item.Group>
        {plantOptions.map((p, i) => (
          <div
            key={i}
            onClick={() => setPlant(p)}
            className={
              p.name === activePlant?.name
                ? 'plant-option active'
                : 'plant-option'
            }
          >
            <div className="plant-svg">{plantMap(p.name)}</div>
            <span>{p.name}</span>
          </div>
        ))}
      </Item.Group>
    </Wrap>
  );
};

const Wrap = styled.div`
  .plant-option {
    display: flex;
    align-items: center;
    color: #ccc;
    padding: 4px;
    cursor: pointer;
    &:hover,
    &.active {
      color: #fff;
    }
    .plant-svg {
      width: 30px;
      height: 30px;
      margin-right: 8px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
