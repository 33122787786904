import { IEdge } from '@shared-types';
import { createEdge } from '../paper-helpers/edges';
import { bulkResizeSprinklerParts } from '../paper-helpers/heads';
import { protectedIDs } from '../tools/tool.helpers';
import { deleteElements } from './deleteElements';
import { getState, setState } from './state-basics';

export const deleteOrphanedFittings = () => {
  const orphans = getState().elements.filter(
    (el) =>
      el.type === 'fitting' &&
      !getState().edges.some(
        (edge) => edge.source === el.uuid || edge.target === el.uuid,
      ),
  );
  const protectedItesm = protectedIDs();
  const filteredOrphans = orphans
    .map((el) => el.uuid)
    .filter((uuid) => protectedItesm.indexOf(uuid) === -1);
  if (filteredOrphans.length) {
    deleteElements(filteredOrphans);
  }
};
export const setPrintPreview = (payload: boolean) => {
  setState({
    printPreview: payload,
  });
};
export const setHeadSize = (payload: number) => {
  setState({
    headSize: payload,
    headClueSize: payload + 2,
    headZoneDotSize: payload + 1,
  });
  bulkResizeSprinklerParts();
};
export const setHeadClueSize = (payload: number) => {
  setState({ headClueSize: payload });
  bulkResizeSprinklerParts();
};
export const setZoneDotSize = (payload: number) => {
  setState({ headZoneDotSize: payload });
  bulkResizeSprinklerParts();
};

export const reverseEdges = (payloadEdges: IEdge[]) => {
  const { pipeProducts, mainPipe, edges, mainPipeSizes } = getState();
  const newEdges = edges.map((edge) => {
    const toReverse = payloadEdges.find(
      (pEdge) => pEdge.source === edge.target && pEdge.target === edge.source,
    );
    if (toReverse) {
      return createEdge(toReverse, pipeProducts, mainPipe, mainPipeSizes);
    }
    return edge;
  });
  setState({
    edges: newEdges,
  });
};
