import { useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

interface ColorInputProps {
  onChange?: (color: string | null) => void;
  value: string | null;
}

export const ColorInput: React.FC<ColorInputProps> = ({ onChange, value }) => {
  const [color, setColor] = useState<string | null>(value);
  const [transparent, setTransparent] = useState<boolean>(value ? false : true);

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
    onChange && onChange(event.target.value);
  };

  const handleNoColorChange = () => {
    if (transparent) {
      onChange && onChange('#000000');
    } else {
      onChange && onChange(null);
    }
  };

  useEffect(() => {
    setColor(value);
    setTransparent(value ? false : true);
  }, [value]);

  return (
    <Wrap>
      <input
        type="color"
        value={color || '#000000'}
        onChange={handleColorChange}
        disabled={transparent}
      />{' '}
      <label>
        <Checkbox
          checked={transparent}
          onClick={handleNoColorChange}
          label={'Transparent'}
        />
      </label>
    </Wrap>
  );
};
const Wrap = styled.div`
  display: flex;
  input {
    height: 40px;
    padding: 0 !important;
    background: none !important;
    cursor: pointer;
    position: relative;
    &:disabled {
      opacity: 0.2;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          to top right,
          transparent 49%,
          red 46%,
          red 54%,
          transparent 51%
        );
      }
    }
  }
`;
