import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { takeUntil } from 'rxjs';
import { Button, Icon } from 'semantic-ui-react';
import paper from 'src/paper';
import styled from 'styled-components';
import { debounce, throttle } from 'underscore';
import { Tools } from '../../../../../shared-types';
import { circleService } from '../circle/circle.service';
import { doUndo } from '../contexts/design/undo';
import { PaperEvType } from '../custom-events';
import { customSymbolService } from '../custom-symbol/custom-symbol.service';
import { sleeveService } from '../features/sleeves/sleeve.service';
import { paperItemStore } from '../helpers';
import { keyupHandler } from '../key-handler';
import { localPaper } from '../localPaper';
import { renderEdges, updateZoneColors } from '../paper-helpers/edges';
import { storeSymbols } from '../paper-helpers/heads';
import {
  activateNamedLayer,
  blurElements,
  findOrCreateLayer,
  renderElements,
  updateAllHeadColors,
  useZoneBoxRenderingEffect,
  useZoneRenderingEffect,
} from '../paper-helpers/plot.helpers';
import { plantService } from '../plants/plant.service';
import { setPlantSymbolCache } from '../plants/plants';
import { polylineService } from '../polyline/polyline.service';
import { rectangleService } from '../rectangle/rectangle.service';
import { northArrowStreams$ } from '../ruler/north-arrow.stream';
import { rulerStreams$ } from '../ruler/ruler.streams';
import { getPipeAlgoVersions } from '../services/design.service';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { sheetService } from '../sheets/sheet.service';
import {
  getState,
  setActiveTool,
  setPipeAlgoVersion,
  setZoomLevel,
  useDesignStore,
} from '../state';
import { changeElements } from '../state/changeElements';
import { deleteItem } from '../state/item-state';
import { legendService } from '../state/legend.service';
import { renderPaperItems } from '../state/renderers';
import { textService } from '../texts/text.service';
import { toolService } from '../tool.service';
import { arrow$ } from '../tools/arrow-new';
import { setupArrowTool } from '../tools/arrow.tool';
import { setupEdgeTool } from '../tools/edge.tool';
import { setupGridTool } from '../tools/grid.tool';
import { setupHeadTool } from '../tools/head.tool';
import { setupLassoTool } from '../tools/lasso.tool';
import { setupMarqueeTool } from '../tools/marquee.tool';
import { setupMiscTool } from '../tools/misc.tool';
import {
  hideOnPan,
  hideOutOfBounds,
  pan$,
  releaseTimeout,
} from '../tools/pan.tool';
import { isPaperSprinkler } from '../tools/paper-items/paper-sprinkler';
import { setupValveBoxLocationTool } from '../tools/valvebox.tool';
import { basemapService } from '../upload/basemap.service';
import { LayerPopup } from './LayerPopup';
import { MainMenu } from './MainMenu';
import { RightMenu } from './RightMenu';
import { SmartSearch } from './SmartSearch';
import { tab$ } from './TabSystem';
import { ToolList } from './ToolList';
import { useEventListener } from './useEventListener';

export interface AlgoVersion {
  name: string;
  version: string;
}
export let algoVersions: AlgoVersion[] = [];
const debouncedUpdateZoneColors = debounce(updateZoneColors, 500);
document.addEventListener('click', (e) => {
  if (e.target instanceof HTMLButtonElement) {
    blurElements();
  }
});
const useValveBoxes = () => useDesignStore((state) => state.valveBoxes);
const useActiveTool = () => useDesignStore((state) => state.activeTool);
const useEdges = () => useDesignStore((state) => state.edges);
const useHeadSize = () => useDesignStore((state) => state.headSize);
const useSelectedItems = () => useDesignStore((state) => state.selectedItems);
const useScale = () => useDesignStore((state) => state.scale);
const useCenter = () => useDesignStore((state) => state.center);
const useElements = () => useDesignStore((state) => state.elements);
const usePocGraphs = () => useDesignStore((state) => state.pocGraphs);
const useZoneNumberOffset = () =>
  useDesignStore((state) => state.zoneNumberOffset);
const useElementCache = () => useDesignStore((state) => state.elementCache);
const useGroups = () => useDesignStore((state) => state.groups);
const usePipeAlgoVersion = () =>
  useDesignStore((state) => state.pipeAlgoVersion);
const useShowValveboxPreviews = () =>
  useDesignStore((state) => state.showValveBoxPreviews);
const useGetZones = () => useDesignStore((state) => state.zones);

export const PlotMap = () => {
  const myref = useRef<any>();
  const location = useLocation();
  const navigate = useNavigate();
  // context / design
  const activeTool = useActiveTool();
  const edges = useEdges();
  const selectedItems = useSelectedItems();
  const headSize = useHeadSize();
  const scale = useScale();
  const center = useCenter();
  const elements = useElements();
  const pocGraphs = usePocGraphs();
  const showValveInfoBoxNumbers = useDesignStore(
    (state) => state.showValveInfoBoxNumbers,
  );
  const elementCache = useElementCache();
  const valveBoxes = useValveBoxes();
  const zones = useGetZones();
  const groups = useGroups();
  const zoneNumberOffset = useZoneNumberOffset();
  const pipeAlgoVersion = usePipeAlgoVersion();
  const showValveBoxPreviews = useShowValveboxPreviews();
  const [isPaper, setIsPaper] = useState(false);
  const [pipeMode, setPipeMode] = useState(false);

  const resize = (): void => {
    const c = myref.current as HTMLCanvasElement;
    if (c) {
      c.width = c.parentElement ? c.parentElement.clientWidth : c.clientWidth;
      c.height = c.parentElement
        ? c.parentElement.clientHeight
        : c.clientHeight;
      localPaper.view.viewSize = new paper.Size(c.width, c.height);
      (window as any).localPaper = localPaper;
    }
    hideOutOfBounds();
  };
  useEffect(() => {
    const keydownHandler = (e) => {
      e.stopImmediatePropagation();
      if (e.code === 'KeyZ' && (e.ctrlKey || e.metaKey)) {
        doUndo();
      }
    };
    window.addEventListener('keydown', keydownHandler);
    return () => {
      window.removeEventListener('keydown', keydownHandler);
    };
  }, []);

  useEffect(() => {
    const innerZoom = debounce((z: number) => {
      setZoomLevel(z);
    }, 500);
    const innerResize = debounce(() => {
      resize();
    }, 500);
    const innerChange = throttle((type, d) => {
      if (type === PaperEvType.PAPER_ELEMENT_CHANGE) {
        changeElements([d]);
      }
    }, 1500);
    myref.current.addEventListener('mousedown', (e) => {
      blurElements();
    });
    myref.current.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    const debounceSetZoom = (data: number): void => innerZoom(data);
    const debounceResize = (): void => innerResize();
    // const debounceShowItems = (): void => innerShow();
    const debounceChangeElements = (type, d) => innerChange(type, d);

    window.addEventListener('resize', () => {
      debounceResize();
    });
    window.addEventListener(PaperEvType.PAPER_ELEMENT_CHANGE, (d: any) => {
      debounceChangeElements(PaperEvType.PAPER_ELEMENT_CHANGE, d.detail);
    });
    localPaper.setup(myref.current as HTMLCanvasElement);
    resize();

    setIsPaper(true);
    localPaper.view.zoom = getState().zoomLevel;

    setTimeout(() => {
      localPaper.view.center = new paper.Point(center);
    }, 100);

    initRender();
    let wheelTimeout: any;
    const view = localPaper.view;
    myref.current.onwheel = (e: WheelEvent) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      hideOnPan(wheelTimeout);
      const isMouse = getState().isMouse;
      if (e.ctrlKey || isMouse) {
        const speed = isMouse ? 1.2 : 1.05;
        const zoom = e.deltaY < 0 ? view.zoom * speed : view.zoom / speed;
        view.zoom = Math.min(Math.max(zoom, 0.01), 100);
        // perform desired zoom action here
        debounceSetZoom(zoom);
      } else {
        // const offset = e.downPoint.subtract(e.point);
        const zoomFactor = 1 / view.zoom; // Adjust the pan speed based on zoom level
        const panSpeed = 0.8 * zoomFactor; // Adjust this value as needed
        const center = view.center;
        const newX = center.x + e.deltaX * panSpeed;
        const newY = center.y + e.deltaY * panSpeed;
        view.center = new paper.Point(newX, newY);
      }
      wheelTimeout = releaseTimeout(wheelTimeout);
      // const zoom = zoomPaper(e);
      // debounceShowItems();
    };

    return (): void => {
      window.removeEventListener('resize', resize);
      localPaper.project.activeLayer.removeChildren();
      // createTools();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initServices = () => {
    sheetService.init();
    circleService.init();
    basemapService.init();
    rectangleService.init();
    polylineService.init();
    plantService.init();
    textService.init();
    sleeveService.init();
    legendService.init();
    customSymbolService.init();
  };

  const initRender = async () => {
    const items = getState().items;
    layerSetup();
    createTools();
    setActiveTool('Arrow');
    setPlantSymbolCache();
    // initial render of elements. everything else happens live
    storeSymbols(scale, headSize);
    initServices();
    renderElements(elements);
    renderEdges(edges);
    renderPaperItems(items);
    updateAllHeadColors(zones, groups);
    const versions = await getPipeAlgoVersions();
    algoVersions = versions;
    if (versions.length && !pipeAlgoVersion) {
      setPipeAlgoVersion(versions[versions.length - 1].version);
    }

    rulerStreams$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
    northArrowStreams$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
    arrow$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
    pan$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
  };
  const createTools = () => {
    toolService.setupTool();
    setupArrowTool(toolService);
    setupEdgeTool(toolService);
    setupMarqueeTool(toolService);
    setupLassoTool(toolService);
    setupHeadTool(toolService);
    setupGridTool(toolService);
    setupValveBoxLocationTool(toolService);
    setupMiscTool(toolService);
  };

  const layerSetup = () => {
    findOrCreateLayer(LAYER_NAMES.BASEMAPS);
    findOrCreateLayer(LAYER_NAMES.SVG);
    findOrCreateLayer(LAYER_NAMES.OUTLINES);
    findOrCreateLayer(LAYER_NAMES.TRACING);
    findOrCreateLayer(LAYER_NAMES.COVERAGE);
    findOrCreateLayer(LAYER_NAMES.PLANTS);
    findOrCreateLayer(LAYER_NAMES.EDGES);
    findOrCreateLayer(LAYER_NAMES.ELEMENTS);
    findOrCreateLayer(LAYER_NAMES.SLEEVES);
    findOrCreateLayer(LAYER_NAMES.HEADS);
    findOrCreateLayer(LAYER_NAMES.VALVE_INFO_BOXES);
    findOrCreateLayer(LAYER_NAMES.VALVE_BOXES);
    findOrCreateLayer(LAYER_NAMES.LEGEND, true);
    findOrCreateLayer(LAYER_NAMES.SHEETS, true);
    findOrCreateLayer(LAYER_NAMES.DEFAULT);
    const visibleLayers = getState().visibleLayers;
    const lockedLayers = getState().lockedLayers;
    localPaper.project.layers.forEach((layer) => {
      if (layer.name) {
        layer.visible = !!visibleLayers.includes(layer.name as LAYER_NAMES);
        layer.locked = !!lockedLayers.includes(layer.name as LAYER_NAMES);
      }
    });
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  };
  useEventListener('keyup', (e) => keyupHandler(e, isPaper, activeTool));

  useZoneRenderingEffect(
    zones,
    valveBoxes,
    elements,
    scale,
    zoneNumberOffset,
    groups,
    elementCache,
    showValveBoxPreviews,
    selectedItems,
  );
  useZoneBoxRenderingEffect(
    zones,
    groups,
    valveBoxes,
    elements,
    scale,
    zoneNumberOffset,
    showValveInfoBoxNumbers,
  );

  useEffect(() => {
    debouncedUpdateZoneColors(edges, zones, pocGraphs);
  }, [edges, zones, pocGraphs]);

  useEffect(() => {
    if (activeTool !== 'ruler') {
      const ruler = getState().items.find((i) => i.itemType === 'ruler');
      if (ruler) {
        deleteItem(ruler.uuid);
      }
    }
    if (activeTool === 'Pipes') {
      setPipeMode(true);
      getState().elements.forEach((e) => {
        const paperItem = paperItemStore.get(e.uuid);
        if (paperItem && isPaperSprinkler(paperItem)) {
          paperItem.hideForPiping(true);
        }
      });
    } else {
      if (pipeMode) {
        setPipeMode(false);
        getState().elements.forEach((e) => {
          const paperItem = paperItemStore.get(e.uuid);
          if (paperItem && isPaperSprinkler(paperItem)) {
            paperItem.hideForPiping(false);
            paperItem.toggleArc(false);
          }
        });
      }
    }
    const switchToProps: Tools[] = [
      'Head Placement',
      'Pipes',
      'Valve Location',
      'grid',
      'text-tool',
      'Sleeve',
      'Misc Item',
    ];
    if (switchToProps.includes(activeTool)) {
      tab$.next('Properties');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTool]);

  const goBackOrDashboard = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/app/dashboard'); // Replace '/dashboard' with your dashboard route
    }
  };
  return (
    <Wrap className="plotmap">
      <div className="main-menu">
        {isPaper && (
          <>
            <Button className="back" onClick={goBackOrDashboard}>
              <Icon name="arrow left" />
              Back
            </Button>
            <SmartSearch />
            <MainMenu />
          </>
        )}
      </div>
      <div className="col-3">
        <div className="col-left">
          <ToolList />
        </div>
        <div className="col-middle">
          <div className="map-canvas">
            <canvas width="100" height="100" id="canv" ref={myref} />
          </div>
          {isPaper && <LayerPopup />}
        </div>
        <div className="col-right">
          {isPaper && (
            <>
              {/* <div className="flodating-tools">
                {(selectedIDs.length > 0 ||
                  selectedItems.length > 0 ||
                  activeTool !== 'Arrow') && <TooltipMenu />}
              </div> */}
              <RightMenu />
            </>
          )}
        </div>
        {/* {isPaper && <TooltipMenu />} */}
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  flex: 1;
  position: relative;
  flex-direction: column;
  display: flex;
  min-width: 0;
  .main-menu {
    width: 100%;
    background: #222;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col-3 {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
    position: relative;
  }
  .floating-tools {
    bottom: 8px;
    left: 8px;
    position: absolute;
    z-index: 10;
  }
  .map-canvas {
    flex: 1 1 auto;
    min-width: 0;
    overflow: hidden;
    canvas {
      /* // background: #efefef; */
    }
  }
  .col-left {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 30;
  }
  .col-middle {
    position: relative;
    flex: 1;
    display: flex;
    min-width: 0;
  }
  .col-right {
    flex: 0 0 350px;
    padding: 2px;
    background: #333;
    border-left: 1px solid #000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .zone-menu {
    position: absolute;
    bottom: 10px;
    right: 10px;
    // transform: translate(-50%);
    z-index: 30;
  }
  .ui.tabular {
    margin-top: 2px;
    border-radius: 0;
  }
  .ui.tabular.menu {
    min-height: 0;
  }
  .ui.tabular.menu .item {
    padding: 8px;
    border-radius: 0 !important;
    /* background: #444; */
    color: rgba(255, 255, 255, 0.2);
    border-color: #000;
    &.active {
      color: white;
      background: #555;
      font-weight: normal;
    }
  }
  .ui.tab {
    background: #555;
    color: rgba(255, 255, 255, 0.7);
    border-color: #000;
  }
  .ui.segment[class*='bottom attached'] {
    border-radius: 0;
  }
  .ui.button {
    margin: 8px;
    &.back {
      background: transparent;
      color: white;
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;
