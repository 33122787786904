import { Circle } from '@shared-types';
import { filter, map, merge, switchMap, takeUntil, tap } from 'rxjs';
import paper from 'src/paper';
import { getEuclideanDistance, polyHintPurple } from 'src/shared';
import { localZoom } from '../localPaper';
import { getColor } from '../paper-helpers/plot.helpers';
import { getState } from '../state';
import { addItem } from '../state/item-state';
import { toolService } from '../tool.service';
import { circleService } from './circle.service';

const { mouseDown$, mouseDrag$, mouseUp$ } = toolService.getEvents('circle');

const createTmpCircle = (point: paper.Point, radius: number, scale: number) => {
  const circle = new paper.Path.Circle({
    center: point,
    radius,
    strokeColor: getColor(polyHintPurple),
    strokeWidth: 1 / localZoom(),
  });
  return circle;
};

const dragDrawCircle$ = mouseDown$.pipe(
  filter(({ e }) => !e.modifiers.space),
  map(({ e }) => {
    const scale = getState().scale;
    const radius = 0.01;
    const tmpCircle = createTmpCircle(e.point, radius, scale);
    return { tmpCircle, radius, downPoint: e.downPoint };
  }),
  switchMap(({ tmpCircle, radius, downPoint }) =>
    mouseDrag$.pipe(
      tap((e) => {
        const newRadius = getEuclideanDistance(downPoint, e.point);
        if (newRadius !== 0 && radius !== 0) {
          tmpCircle.scale(newRadius / radius);
          radius = newRadius;
        }
      }),
      takeUntil(
        merge(
          mouseUp$.pipe(
            tap(() => {
              const p = tmpCircle.position;
              const circle: Circle = {
                ...circleService.createItem(),
                position: { x: p.x, y: p.y },
                radius: radius,
              };
              addItem(circle);
            }),
          ),
          // escapeKey$
        ).pipe(
          tap(() => {
            tmpCircle.remove();
          }),
        ),
      ),
    ),
  ),
);

export const drawCircle$ = merge(dragDrawCircle$);
