import { CloudinaryFile } from '@shared-types';
import { format, parseISO } from 'date-fns';
import { Table } from 'semantic-ui-react';
import { LinkWithConfirm } from 'src/components/LinkWithConfirm';
import { base } from 'src/styles/common';
import styled from 'styled-components';

interface Props {
  files: CloudinaryFile[];
  onDelete?: (i: number) => void;
  canDelete?: boolean;
  canDownload?: boolean;
}
export const FileListing = ({
  files,
  onDelete,
  canDelete = true,
  canDownload = true,
}: Props) => {
  return (
    <Wrap>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Filename</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Size</Table.HeaderCell>
            {canDownload && <Table.HeaderCell>Download</Table.HeaderCell>}
            {canDelete && <Table.HeaderCell>Delete</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {files.map((file, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                <img src={file.thumbnail_url} height={50} />
              </Table.Cell>
              <Table.Cell>
                {file.original_filename} ({file.format})
              </Table.Cell>
              <Table.Cell>
                {format(parseISO(file.created_at), 'MMM d, yyyy h:mm a')}
              </Table.Cell>
              <Table.Cell>
                {file.bytes / 1000000 < 1
                  ? `${Math.round((file.bytes / 1000000) * 1000)} KB`
                  : `${Math.round(file.bytes / 1000000)} MB`}
              </Table.Cell>
              {canDownload && (
                <Table.Cell>
                  <a
                    href={file.secure_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={file.original_filename}
                  >
                    download
                  </a>
                </Table.Cell>
              )}
              {canDelete && (
                <Table.Cell>
                  <>
                    &nbsp;
                    {!!onDelete && (
                      <LinkWithConfirm onConfirm={() => onDelete(i)}>
                        delete
                      </LinkWithConfirm>
                    )}
                  </>
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Wrap>
  );
};

const Wrap = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${base}px;
`;
