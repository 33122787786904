import { takeUntil } from 'rxjs';
import { IPoint, Sheet } from '../../../../../shared-types/workbench-types';
import {
  defaultItem,
  getItemsByType,
  isSheet,
  paperItemStore,
} from '../helpers';
import { activateNamedLayer } from '../paper-helpers/plot.helpers';
import { ItemService } from '../polyline/ItemService';
import { registerStateHooks } from '../polyline/item.model';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { getState } from '../state';
import { addItem, updateItem } from '../state/item-state';
import { deletePaperItem, updatePaperItem } from '../state/renderers';
import { toolService } from '../tool.service';
import { arrowSheet$ } from './draw-sheet';
import { PaperSheet } from './paper-sheet';

class SheetService implements ItemService<Sheet> {
  init(): void {
    this.registerWithState();
    arrowSheet$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
  }
  createItem(position?: IPoint): Sheet {
    const sheets = getItemsByType(isSheet);
    const { scale } = getState();
    const width = (31 * 72) / scale;
    const height = (23 * 72) / scale;
    if (!position && sheets.length) {
      const topRightSheet = sheets.reduce(
        (acc: Sheet, s: Sheet) =>
          s.position.x > acc.position.x && s.position.y < acc.position.y
            ? s
            : acc,
        sheets[sheets.length - 1],
      );
      const overlap = width / 12;
      position = {
        x: topRightSheet.position.x + width - overlap,
        y: topRightSheet.position.y,
      };
    }
    return {
      ...defaultItem(position),
      itemType: 'sheet',
      index: sheets.length,
      width,
      height,
    };
  }
  renderItemToPaper(item: Sheet): void {
    activateNamedLayer(LAYER_NAMES.SHEETS);
    paperItemStore.set(item.uuid, new PaperSheet(item));
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  }
  addSheet = (position?: IPoint) => {
    const sheet = this.createItem(position);
    addItem(sheet);
  };
  reindex = () => {
    const sheets = getItemsByType(isSheet).map((s, i) => ({ ...s, index: i }));
    sheets.forEach((sheet) => updateItem(sheet));
  };
  registerWithState = () =>
    registerStateHooks<Sheet>('sheet', {
      postAddItem: (item: Sheet) => {
        this.renderItemToPaper(item);
        this.reindex();
      },
      postUpdateItem: (item: Sheet) => {
        updatePaperItem(item);
      },
      postDeleteItem: (item: Sheet) => {
        deletePaperItem(item.uuid);
        this.reindex();
      },
    });
}

export const sheetService = new SheetService();
