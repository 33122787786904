import { useState } from 'react';
import { useParams } from 'react-router';
import { Dropdown, Icon, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { irrigationBlue } from '../../../vars';
import { AlertsModalType } from '../features/alerts/AlertsModal';
import { HeadEquipmentModalType } from '../features/heads/HeadEquipment';
import { PipeEquipmentModalType } from '../features/pipes/PipeEquipment';
import { HydraulicsModalType } from '../features/pressure/HydraulicsModal';
import { StatsModalType } from '../features/stats/StatsModal';
import { ValveEquipmentModalType } from '../features/valves/ValveEquipment';
import { saveDesign } from '../services/design.service';
import {
  getState,
  saveNecessary,
  setLastSave,
  setLoading,
  useDesignStore,
} from '../state';
import { openModal } from '../state/modal.store';
import { SettingsModalType } from './SettingsModal';

export const MainMenu = () => {
  const { id } = useParams<'id'>();
  const loading = useDesignStore((state) => state.loading);
  const [saving, setSaving] = useState(false);

  const save = async () => {
    if (id) {
      setSaving(true);
      setLoading(true);
      const savedDesign = await saveDesign(
        saveNecessary({
          ...getState(),
        }),
        parseInt(id, 10),
      );
      if (savedDesign.updatedAt) {
        setLastSave(savedDesign.updatedAt);
      } else {
        console.error(savedDesign.message);
      }
      setSaving(false);
      setLoading(false);
    }
  };

  return (
    <Wrap>
      {/* <Experimental /> */}
      {/* <GroupList /> */}
      {/* {(loading || saving) && <Loader size="mini" active inline />} */}
      <Dropdown
        pointing="top right"
        icon={null}
        trigger={
          <CustomButton>
            <Icon name="setting" />
            <span className="button-label">Settings</span>
          </CustomButton>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => openModal(SettingsModalType)}>
            Design Settings
          </Dropdown.Item>
          {/* <Dropdown.Item>Scale Settings</Dropdown.Item>
          <Dropdown.Item>Coverage Settings</Dropdown.Item> */}
          <Dropdown.Item onClick={() => openModal(ValveEquipmentModalType)}>
            Valve Equipment
          </Dropdown.Item>
          <Dropdown.Item onClick={() => openModal(HeadEquipmentModalType)}>
            Head Equipment
          </Dropdown.Item>
          <Dropdown.Item onClick={() => openModal(PipeEquipmentModalType)}>
            Pipe Equipment
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <CustomButton>
        <Icon name="download" />
      </CustomButton> */}
      <Dropdown
        pointing="top right"
        icon={null}
        trigger={
          <CustomButton>
            <Icon name="info circle" />
            <span className="button-label">Info</span>
          </CustomButton>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => openModal(AlertsModalType)}>
            Alerts
          </Dropdown.Item>
          <Dropdown.Item onClick={() => openModal(StatsModalType)}>
            Stats
          </Dropdown.Item>
          <Dropdown.Item onClick={() => openModal(HydraulicsModalType)}>
            Hydraulics
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <CustomButton style={{ width: 80 }} disabled={saving} onClick={save}>
        {loading && <Loader size="mini" active={true} inline />}
        {!loading && (
          <>
            <Icon name="save" />
            <span className="button-label">Save</span>
          </>
        )}
      </CustomButton>
    </Wrap>
  );
};

export const Wrap = styled.div`
  text-align: right;
  display: flex;
  margin-right: 8px;
  .ui.button {
    margin: 8px;
    &.logout {
      background: transparent;
      color: white;
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const CustomButton = styled.button`
  display: block;
  outline: 0;
  border: none;
  line-height: 1em;
  color: #efefef;
  height: 3em;
  min-width: 3em;
  text-align: center;
  background: #333;
  border-radius: 4px;
  margin-left: 8px;
  padding: 0 8px;
  cursor: pointer;
  svg {
    width: 1.2em;
  }
  i.icon {
    margin: 0;
  }
  &:hover {
    color: ${irrigationBlue};
  }
  .button-label {
    margin-left: 4px;
  }
`;
