import { ProjectData } from '@shared-types';
import {
  Button,
  Card,
  Form,
  Header,
  Icon,
  Segment,
  TextArea,
  TextAreaProps,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { FileListing } from '../Projects/FileListing';
import { cloudinaryConfig } from '../Workbench/cloudinary.config';
import { SectionBody } from './ProjectRequestForm';

interface SiteInfoProps {
  projectData: ProjectData;
  complete: boolean;
  onUpdate: (form: ProjectData) => void;
}
export const SiteInfo = ({
  projectData,
  onUpdate,
  complete,
}: SiteInfoProps) => {
  const deleteContractorFiles = (i: number) => {
    const contractorFiles = projectData.contractorFiles.filter(
      (_file, j) => j !== i,
    );
    onUpdate({ ...projectData, contractorFiles });
  };
  const handleAreaChange = (_, f: TextAreaProps) => {
    if (f.name) {
      onUpdate({ ...projectData, [f.name]: f.value });
    }
  };

  const openUploadWidget = () => {
    const data = { ...projectData };
    (window as any).cloudinary.openUploadWidget(
      cloudinaryConfig,
      (error, result) => {
        if (error) {
          console.log(error);
        } else {
          if (result.event === 'queues-end') {
            if (result.info.files.length) {
              data.contractorFiles = [
                ...data.contractorFiles,
                ...result.info.files
                  .filter((f) => f.status === 'success')
                  .map((f) => f.uploadInfo),
              ];
            }
          }
          if (result.event === 'close') {
            onUpdate({
              ...data,
            });
          }
        }
      },
    );
  };

  return (
    <Wrap>
      <Card fluid>
        <Card.Content className={`custom-header ${complete ? 'complete' : ''}`}>
          <Card.Header>
            Site Info&nbsp;&nbsp;
            <Icon
              name="check circle"
              color={complete ? 'green' : 'grey'}
              size="small"
            />
          </Card.Header>
        </Card.Content>

        <SectionBody>
          <div className="wrapper">
            <Form>
              <Form.TextArea
                label={'Address'}
                name="siteAddress"
                value={projectData.siteAddress}
                onChange={handleAreaChange}
              />
              <FileListing
                files={projectData.contractorFiles}
                onDelete={deleteContractorFiles}
              />
              <Segment placeholder>
                <Header icon>
                  <Icon name="file pdf outline" />
                  {!projectData.contractorFiles.length && (
                    <span>No basemap files have been uploaded.</span>
                  )}
                </Header>
                <Button onClick={openUploadWidget}>
                  <Icon name="upload" />
                  Upload Files
                </Button>
                <br />
                <small>
                  Make sure POC position and all relevant information is marked
                  on the basemap
                </small>
              </Segment>
              {/* <Form.Select
                label={'Scale'}
                options={scales.map(scale => ({ text: scale.label, value: scale.value }))}
                name="scale"
                value={projectData.scale}
                onChange={handleSelectChange}
              /> */}
              <br />
              <Form.Field
                control={TextArea}
                rows={5}
                label="Project Notes"
                name="notes"
                autoComplete="off"
                placeholder="Enter project notes here"
                value={projectData.notes}
                onChange={handleAreaChange}
              />
            </Form>
          </div>
        </SectionBody>
      </Card>
    </Wrap>
  );
};

const Wrap = styled.div``;
