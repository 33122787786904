import {
  DesignElement,
  DesignSprinklerElement,
  HopProperties,
  IEdge,
  IEdgeHopData,
  IMiscItem,
  IPoint,
  ValveBox,
  Zone,
} from '@shared-types';
import { compare } from 'mathjs';
import { clearSelectedItems, getState } from 'src/pages/Workbench/state';
import { getMidPoint } from 'src/shared';
import { paperChangeEvent } from '../custom-events';
import { isBaseItemType, paperItemStore } from '../helpers';
import { localPaper } from '../localPaper';
import { ITEMNAMES } from '../shared/workbench-enums';
import { deleteEdge } from '../state/deleteEdge';
import { deleteElements } from '../state/deleteElements';
import { changeValveBox } from '../state/valvebox/changeValveBox';
import { deleteValveBox } from '../state/valvebox/deleteValveBox';
import { PipeWithPivotIndex } from './arrow.tool';
import { isEdgeHopData } from './paper-items/paper-pipe';
import { isPaperSprinkler, isSprinkler } from './paper-items/paper-sprinkler';

export const rotateSprinkler = (item: paper.Group, amount: number) => {
  if (!isSprinkler(item.data)) return;
  const props = item.data.props;
  const newEl: DesignSprinklerElement = {
    ...item.data,
    props: {
      ...props,
      rotation: props.rotation + amount,
    },
  };
  const paperItem = paperItemStore.get(item.data.uuid);
  if (paperItem && isPaperSprinkler(paperItem)) {
    paperItem.updateArc({
      ...props,
      rotation: props.rotation + amount,
    });
  }
  paperChangeEvent(newEl);
};
export const rotateMiscItem = (item: paper.Group, amount: number) => {
  const props = item.data.props as IMiscItem;
  item.rotate(amount);
  const newEl = {
    ...item.data,
    props: {
      ...props,
      rotation: item.rotation,
    },
  };
  paperChangeEvent(newEl);
};
export const rotateValveBoxLocation = (item: paper.Group, amount: number) => {
  const { valveBoxes } = getState();
  const d = item.data;
  const vb = valveBoxes.find((v) => v.uuid === d.uuid);
  if (vb) {
    changeValveBox({
      ...vb,
      rotation: (vb.rotation + amount) % 360,
    });
  }
};

export const rotateEdgeHop = (
  item: paper.Item,
  uuid: string,
  hopIndex?: number,
) => {
  const edge = getState().edges.find((e) => e.uuid === uuid);
  if (edge && hopIndex !== undefined) {
    const pipeSection = edge.hoppedSections[hopIndex];
    const hopValues = pipeSection.values as HopProperties;
    const midpoint = getMidPoint(hopValues.pre, hopValues.post);
    item.rotate(180, new paper.Point(midpoint));
  }
};
export const bulkDelete = (items: paper.Item[]) => {
  const elements = items.filter((i) => i.name === ITEMNAMES.ELEMENT);
  if (elements.length) {
    const uuids = elements.map((e) => (e.data as DesignElement).uuid);
    deleteElements(uuids);
  } else {
    items.forEach((i) => deleteItemHandler(i));
  }
};
export const deleteItemHandler = (item: paper.Item) => {
  if (item.name === 'plant') {
    // deletePlant((item as paper.Group).data.uuid);
  } else if (item.name === ITEMNAMES.BASEMAP_JPG) {
    // deleteBasemap(item.data.uuid);
    // item.remove();
  } else if (item.name === ITEMNAMES.ELEMENT) {
    const data = item.data as DesignElement;
    const uuid = data.uuid;
    deleteElements([uuid]);
  }

  if (item.name === ITEMNAMES.PIPE && isEdgeHopData(item.data)) {
    deleteEdge(item.data.edgeUUID);
  }
  if (item.name === ITEMNAMES.VALVE_BOX_LOCATION) {
    const { valveBoxes } = getState();
    const d = item.data;
    const vb = valveBoxes.find((v) => v.uuid === d.uuid);
    if (vb) {
      deleteValveBox(vb.uuid);
    }
  }
  // new Items
  if (isBaseItemType(item.name)) {
    // const id = item.data.uuid;
    // deleteItem(id);
  }
};
export const getProtectedEdgeNodes = () => {
  // returns a list of elements that have protected edges
  // TODO: the one edge case is a VB with no edges :thinking:
  const { zones } = getState();
  const zoneItems = zones.reduce((acc: string[], z: Zone) => {
    return [...acc, z.valve, z.valveInputFitting];
  }, []);
  return [...zoneItems];
};
export const protectedIDs = () => {
  const { valveBoxes, zones } = getState();
  const zoneItems = zones.reduce((acc: string[], z: Zone) => {
    return [...acc, z.valve, z.valveInputFitting, z.valveOutputFitting];
  }, []);
  const vbItems = valveBoxes.reduce((acc: string[], vb: ValveBox) => {
    acc.push(...[vb.inputFitting, vb.outputFitting]);
    return [...acc, vb.inputFitting, vb.outputFitting];
  }, []);
  return [...zoneItems, ...vbItems];
};
export const deleteSelectedItems = () => {
  const { selectedItems } = getState();
  clearSelectedItems();
  const protectedElementUUIDs = protectedIDs();
  const itemsToDelete = selectedItems.filter((i) => {
    if (i.data && i.data.uuid && protectedElementUUIDs.includes(i.data.uuid)) {
      return false;
    }
    return true;
  });
  bulkDelete(itemsToDelete);
};

// export const renderOrthoDots = (
//   path: paper.Path,
//   currentPoint: paper.Point,
//   angleStep: number,
// ) => {
//   let targetPoint = currentPoint;
//   const dots: IPoint[] = [];
//   const start = path.lastSegment.point;
//   const d = getEuclideanDistance(start, currentPoint);
//   let closest = 10000;
//   let last = path?.lastSegment?.previous?.point || {
//     x: path.lastSegment.point.x,
//     y: path.lastSegment.point.y - 2,
//   };
//   const angle = Math.round(getRotation(last, path.lastSegment.point));
//   for (let i = angle; i < 360 + angle; i += angleStep) {
//     const p1: IPoint = {
//       x: start.x + d * Math.cos(toRadians(i)),
//       y: start.y + d * Math.sin(toRadians(i)),
//     };
//     const distance = getEuclideanDistance(currentPoint, p1);
//     if (distance < closest) {
//       closest = distance;
//       targetPoint = new paper.Point(p1);
//     }
//     dots.push(p1);
//   }
//   return { targetPoint, dots };
// };

// export const createPaperDots = (dots: IPoint[], scale: number): paper.Path[] =>
//   dots.map((dot) => {
//     const p = new paper.Path.Circle(new paper.Point(dot), 2 / scale);
//     p.strokeColor = getColor('red');
//     p.strokeWidth = 2 / scale;
//     p.name = ITEMNAMES.ORTHO_DOT;
//     p.data = {};
//     p.locked = true;
//     return p;
//   });
const isMatchingEdge = (e: paper.ToolEvent, edge: IEdge): boolean =>
  edge.source === e.item.data.uuid || edge.target === e.item.data.uuid;

const findMatchingEdges = (e: paper.ToolEvent): IEdge[] =>
  getState().edges.filter((edge) => isMatchingEdge(e, edge));

const findMatchingPaperPipes = (matchingPipeIDs: string[]): paper.Path[] =>
  localPaper.project.getItems({
    name: ITEMNAMES.PIPE,
    match: (item) =>
      matchingPipeIDs.includes((item.data as IEdgeHopData).edgeUUID),
  }) as paper.Path[];

const findSegmentPivotIndex = (pipe: paper.Path, point: IPoint): number =>
  pipe.segments.findIndex(
    (seg) =>
      compare(seg.point.x, point.x) === 0 &&
      compare(seg.point.y, point.y) === 0,
  );

const createPipeWithPivotIndex = (
  pipe: paper.Path,
  point: IPoint,
): PipeWithPivotIndex => ({
  pipe,
  segmentPivotIndex: findSegmentPivotIndex(pipe, point),
});

export const getMatchingEdges = (
  event: paper.ToolEvent,
  element: DesignElement,
): PipeWithPivotIndex[] => {
  const matchingEdges = findMatchingEdges(event);
  const matchingPipeIDs = matchingEdges.map((edge) => edge.uuid);
  const matchingPipes = findMatchingPaperPipes(matchingPipeIDs);
  return matchingPipes
    .map((pipe) => createPipeWithPivotIndex(pipe, element.position))
    .filter((pipeWithPivotIndex) => pipeWithPivotIndex.segmentPivotIndex > -1);
};
