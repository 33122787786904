export interface Undo {
  undo: Function;
  timestamp: number;
  name?: string;
}
export const undoLength = 50;
export const undoStack: Undo[] = [];

export const addUndo = (fn: Function, name = '') => {
  if (undoStack.length > undoLength) {
    undoStack.pop();
  }
  const u: Undo = {
    timestamp: Date.now(),
    undo: () => fn(),
  };
  if (name.length) {
    u.name = name;
  }
  undoStack.unshift(u);
};
export const doUndo = () => {
  if (undoStack.length) {
    const thing = undoStack.shift();
    if (thing) {
      if (thing.name) {
        console.log(`Undoing ${thing.name}...`);
      } else {
        console.log('Undoing...');
      }
      thing.undo();
    }
  }
};
