import { PDFDocument } from 'pdf-lib';
import { Content } from 'pdfmake/interfaces';
import { createPDFMakePage } from '../helpers/pdf/createPDFMakePage';
import {
  convertBlobToBase64,
  fetchAsBlob,
  pocLines,
  standardDefinition,
} from '../helpers/pdf/pdf.helpers';
import { getState } from '../state';

export async function createTitlePagePDF(): Promise<PDFDocument | void> {
  const definitionFunc = async () => standardDefinition();
  const getContent = async () => createTitlePage();
  return createPDFMakePage(definitionFunc, getContent);
}

const createTitlePage = async (): Promise<Content[]> => {
  const {
    title,
    contractorAddress,
    contractorLogoURL,
    contractorName,
    clientAddress,
    pipeProducts,
    pocGraphs,
    elementCache,
  } = getState();
  const titlePage: Content[] = [
    {
      text: 'Irrigation Design',
      fontSize: 15,
      alignment: 'center',
      margin: [0, 160, 0, 0],
    },
    {
      text: title,
      fontSize: 24,
      bold: true,
      alignment: 'center',
      margin: [0, 10, 0, 0],
    },
    { text: `${clientAddress}`, alignment: 'center', margin: [0, 20, 0, 0] },
  ];
  if (contractorLogoURL) {
    const file = await fetchAsBlob(contractorLogoURL);
    const base64 = await convertBlobToBase64(file);
    // throws errors if no logo is present, so gotta conditional it
    titlePage.push({
      image: base64,
      width: 150,
      alignment: 'center',
      margin: [0, 80, 0, 30],
    });
  } else {
    titlePage.push({ text: '', margin: [0, 0, 0, 30] });
    await Promise.resolve();
  }
  titlePage.push(
    {
      text: `${contractorName}`,
      bold: true,
      alignment: 'center',
      margin: [0, 0, 0, 0],
    },
    { text: `${contractorAddress}`, alignment: 'center', margin: [0, 0, 0, 0] },
  );
  titlePage.push({ text: '', margin: [0, 0, 0, 80] });
  titlePage.push(...pocLines(pipeProducts, elementCache, pocGraphs));

  return titlePage;
};
