import { DesignSprinklerElement, SprinklerBase } from '@shared-types';
import { useEffect, useState } from 'react';
import { getGeneratePerfData } from '../../helpers/nozzleTransformer';
import { createSprinkler } from '../../tools/sprinkler';
import { NozzleOptions } from './NozzleOptions';

interface Props {
  element: DesignSprinklerElement;
  bases: SprinklerBase[];
  onSave: (item: DesignSprinklerElement) => void;
}

export const ChangeHead = ({ onSave, element, bases }: Props): JSX.Element => {
  const [localSprinkler, setLocalSprinkler] = useState(element.props);

  useEffect(() => {
    setLocalSprinkler(element.props);
  }, [element]);

  const doSet = (uuid: string, radius: number) => {
    const baseIndex = bases.findIndex((n) => n.uuid === uuid);
    if (baseIndex > -1) {
      const nozzleData = getGeneratePerfData(bases[baseIndex]);
      const maxAngle = Math.max(...nozzleData.map((n) => n.angle));
      let angle = localSprinkler.angle;
      if (nozzleData.length && nozzleData[0].fixed) {
        angle = maxAngle;
      } else {
        if (localSprinkler.angle > maxAngle) {
          angle = maxAngle;
        }
      }
      const newSprinkler: DesignSprinklerElement = {
        ...element,
        props: createSprinkler(
          {
            radius,
            angle,
            rotation: localSprinkler.rotation,
            x: localSprinkler.x,
            y: localSprinkler.y,
          },
          bases[baseIndex],
        ),
      };
      onSave({ ...newSprinkler });
      setLocalSprinkler(newSprinkler.props);
    }
  };

  return (
    <>
      <NozzleOptions
        activeKey={localSprinkler.base.uuid}
        activeRadius={localSprinkler.radius}
        onSet={doSet}
      />
    </>
  );
};
