import { DesignSprinklerElement, Zone } from '@shared-types';
import styled from 'styled-components';
import {
  getPOCGraphIndex,
  reduceHeadsGPMbyId,
  reducePlantsGPHbyId,
} from '../helpers/directedGraph';
import { isPlant } from '../plants/plants';
import { headInZone } from '../selectors';
import { pocGraphByIndex, useDesignStore } from '../state';
import { isSprinkler } from '../tools/paper-items/paper-sprinkler';

interface Props {
  zones: Zone[];
  headIds: string[];
  onAdd: (zoneId: string, headIds: string[]) => void;
}
export const ChangeZone = ({ zones, headIds, onAdd }: Props): JSX.Element => {
  const pipeProducts = useDesignStore((state) => state.pipeProducts);
  const plants = useDesignStore((state) => state.items.filter(isPlant));
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  const elementCache = useDesignStore((state) => state.elementCache);
  const changeZone = (zoneId: string) => onAdd(zoneId, headIds);
  const totalGPM = (headIds: string[]) =>
    headIds
      .map((id) => elementCache[id])
      .filter(isSprinkler)
      .reduce((acc: number, h: DesignSprinklerElement) => acc + h.props.gpm, 0);

  return (
    <Wrap>
      <h5>
        Change zone of {headIds.length}{' '}
        {headIds.length === 1 ? 'head' : 'heads'}
      </h5>
      <p>
        Selected {headIds.length === 1 ? 'head has' : 'heads have'} a total of{' '}
        {totalGPM(headIds).toFixed(1)} GPM
      </p>
      {zones.map((zone: Zone, k: number) => {
        const gpmOfZonedItems =
          zone.headIds.length > 0
            ? reduceHeadsGPMbyId(zone.headIds, elementCache)
            : reducePlantsGPHbyId(plants, zone.plantIds) / 60;
        const dgIndex = getPOCGraphIndex(zone.valve);
        const perc =
          dgIndex > -1
            ? gpmOfZonedItems /
              pocGraphByIndex(dgIndex, pocGraphs).rootGPM(pipeProducts)
            : 0;
        return (
          <ZoneButton
            onClick={changeZone.bind(null, zone.uuid)}
            disabled={
              headIds.length === 1 && headInZone(zones, zone.uuid, headIds[0])
            }
            key={k}
            $percentage={perc}
            $color={
              headIds.length === 1 && headInZone(zones, zone.uuid, headIds[0])
                ? 'transparent'
                : zone.color
            }
          >
            <div className="details">{gpmOfZonedItems.toFixed(1)} GPM</div>
            <div className="color">
              {zone.isDrip || zone.plantIds.length > 0
                ? `D${zone.orderNumber + 1}`
                : zone.orderNumber + 1}
            </div>
            {perc * 100 > 100 && <div>TOO FULL</div>}
            {perc * 100 < 100 && (
              <div className="bar">
                <div className="percentage" />
              </div>
            )}
          </ZoneButton>
        );
      })}
    </Wrap>
  );
};
export const ZoneButton = styled.button<{
  $color: string;
  disabled: boolean;
  $percentage: number;
}>`
  font-size: 10px;
  display: inline-block;
  width: 70px;
  margin: 0 4px 8px 0;
  text-align: center;
  padding: 0;
  background: #fff;
  border: none;
  cursor: pointer;
  .details {
    padding: 4px;
  }
  .color {
    padding: 8px;
    background: ${(props) => (props.disabled ? '#ccc' : props.$color)};
    color: ${(props) => (props.$color === 'transparent' ? 'black' : 'white')};
    text-align: center;
    font-weight: bold;
  }
  .bar {
    position: relative;
    width: 100%;
    height: 8px;
    margin-top: 2px;
    background: #ccc;
    .percentage {
      width: ${(props) => props.$percentage * 100}%;
      height: 8px;
      position: absolute;
      top: 0;
      left: 0;
      background: ${(props) =>
        props.$percentage * 100 < 85 ? 'green' : 'red'};
    }
  }
`;
const Wrap = styled.div``;
