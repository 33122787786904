import { DesignElement } from '@shared-types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getLateralPipeProduct } from '../features/pipes/Pipes';
import { cloneEdge, createDefaultEdge } from '../paper-helpers/edges';
import { getState, setActiveTool } from '../state';
import { addElementsEdges } from '../state/addElementsEdges';
import { PanelButton, PanelWrap } from './PanelButton';
import { useEventListener } from './useEventListener';

export const EdgeCloneTooltip = ({ items }: { items: paper.Item[] }) => {
  const { edges } = getState();
  const [cloning, setCloning] = useState(false);

  const paperClick = (d) => {
    if (cloning) {
      cloneEdge(items, d.detail.point, edges);
      setCloning(false);
    }
  };

  useEventListener('keyup', (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      setCloning(false);
      window.removeEventListener('paperClick', paperClick);
    }
  });

  useEffect(() => {
    if (cloning) {
      setActiveTool('Arrow');
      window.addEventListener('paperClick', paperClick, { once: true });
    }
    return () => window.removeEventListener('paperClick', paperClick);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloning, setActiveTool]);

  const pipeTogether = () => {
    const pipe = getLateralPipeProduct();
    // note: these are assumed to be items or heads
    const itemsHaveCapacity = items.every(
      (el) =>
        ((el.data as DesignElement).type !== 'sprinkler' &&
          edges.filter(
            (edge) =>
              edge.source === el.data.uuid || edge.target === el.data.uuid,
          ).length < 3) ||
        ((el.data as DesignElement).type === 'sprinkler' &&
          edges.filter(
            (edge) =>
              edge.source === el.data.uuid || edge.target === el.data.uuid,
          ).length < 2),
    );
    if (items.length === 2 && itemsHaveCapacity) {
      const edge = createDefaultEdge({
        source: items[0].data.uuid,
        target: items[1].data.uuid,
        pipe: pipe ? pipe.uuid : '',
        isLateral: true,
      });
      addElementsEdges([], [edge]);
    } else {
      console.error('something went wrong when trying to pipe together');
    }
  };

  return (
    <Wrap>
      {items.length} elements selected
      {items.length === 2 && (
        <>
          <PanelButton onClick={() => setCloning(true)}>Clone edge</PanelButton>
          <PanelButton onClick={pipeTogether}>Pipe Together</PanelButton>
        </>
      )}
    </Wrap>
  );
};
const Wrap = styled(PanelWrap)``;
