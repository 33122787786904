import { Form, Input, Modal } from 'semantic-ui-react';
import { designDefault } from 'src/shared/constants';
import { setHeadClueSize, setHeadSize, setZoneDotSize } from '../../state';
import { modalDefaults } from '../../state/modal.service';
import { useDesignStore } from '../../state/state-basics';

export const HeadSizeModalType = 'headSizeModal';
export const HeadSizeModal = ({ closeModal }) => {
  const headSize = useDesignStore((state) => state.headSize);
  const headZoneDotSize = useDesignStore((state) => state.headZoneDotSize);
  const headClueSize = useDesignStore((state) => state.headClueSize);

  return (
    <Modal
      {...modalDefaults}
      onActionClick={closeModal}
      actions={[{ key: 'done', content: 'Done', primary: true }]}
      header="Change Head Sizes"
      content={
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Head size (default: {designDefault.headSize})</label>
              <Input
                size="mini"
                value={headSize}
                type="number"
                onChange={(_, d) => setHeadSize(parseInt(d.value, 10))}
              />
            </Form.Field>
            <Form.Field>
              <label>
                Head clue size (default: {designDefault.headClueSize})
              </label>
              <Input
                value={headClueSize}
                size="mini"
                type="number"
                onChange={(_, d) => setHeadClueSize(parseInt(d.value, 10))}
              />
            </Form.Field>
            <Form.Field>
              <label>
                Zone Dot size (default: {designDefault.headZoneDotSize})
              </label>
              <Input
                value={headZoneDotSize}
                size="mini"
                type="number"
                onChange={(_, d) => setZoneDotSize(parseInt(d.value, 10))}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
      }
    />
  );
};
