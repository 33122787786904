import { Vertex } from '@shared-types';
import { useEffect, useState } from 'react';
import { merge, tap } from 'rxjs';
import { drawPolyService } from './draw-poly.service';

export function usePolyService(): {
  overrideMode: boolean;
  arcMode: boolean;
  snapMode: boolean;
  nextLength: number;
  vertices: Vertex[];
  closePath: boolean;
} {
  const [overrideMode, setOverrideMode] = useState<boolean>(false);
  const [arcMode, setArcMode] = useState<boolean>(false);
  const [snapMode, setSnapMode] = useState<boolean>(false);
  const [nextLength, setNextLength] = useState<number>(0);
  const [vertices, setVertices] = useState<Vertex[]>([]);
  const [closePath, setClosePath] = useState<boolean>(false);

  useEffect(() => {
    const subscription = merge(
      drawPolyService.overrideMode$.pipe(tap(setOverrideMode)),
      drawPolyService.arcMode$.pipe(tap(setArcMode)),
      drawPolyService.nextLength$.pipe(tap(setNextLength)),
      drawPolyService.closePath$.pipe(tap(setClosePath)),
      drawPolyService.snapMode$.pipe(tap(setSnapMode)),
      drawPolyService.vertices$.pipe(
        tap((v) => {
          setVertices([...v]);
        }),
      ),
    ).subscribe();
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return { overrideMode, arcMode, nextLength, vertices, closePath, snapMode };
}
