import {
  OperatorFunction,
  combineLatest,
  filter,
  map,
  merge,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';
import paper from 'src/paper';
import { IPoint, LineSegment } from '../../../../../shared-types';
import { getItemsByType, isPolyline } from '../helpers';
import {
  getClosestPoint,
  getIntersection,
  pointsEqual,
} from '../helpers/geometry.helpers';
import { localZoom } from '../localPaper';
import { transparentColor } from '../paper-helpers/plot.helpers';
import { getEuclideanDistance } from '../shared/geometry';
import { toolService } from '../tool.service';
import { drawPolyService } from './draw-poly.service';
import {
  addOverrideArc,
  addRegularArc,
  addStandardVertex,
  getAnglePoint,
  renderPolyHintArc,
  renderPolyHintLine,
  setThroughPoint,
} from './polyline-helpers';

const isPolyMode =
  <T>(): OperatorFunction<T, T> =>
  (source) =>
    source.pipe(
      withLatestFrom(toolService.activeTool$),
      filter(([_, mode]) => mode === 'polyline'),
      map(([e]) => e),
    );

const { mouseDown$, mouseUp$, mouseMove$, keyUp$ } =
  toolService.getEvents('polyline');

const polyArcToggle$ = keyUp$.pipe(
  filter((e) => e.key === 'a'),
  tap((e) => {
    drawPolyService.toggleArcMode();
  }),
);
const polySnapToggle$ = keyUp$.pipe(
  filter((e) => e.key === 's'),
  tap((e) => {
    drawPolyService.toggleSnapMode();
  }),
);
const polyOverrideToggle$ = keyUp$.pipe(
  filter((e) => e.key === '2'),
  tap((e) => {
    drawPolyService.toggleOverrideMode();
  }),
);
const closestVertex$ = drawPolyService.snapMode$.pipe(
  filter((snapMode) => snapMode),
  map(() => {
    const snap = new paper.Path.Circle({
      radius: 8 / localZoom(),
      fillColor: transparentColor('orange', 0.5),
    });
    const polylines = getItemsByType(isPolyline);
    const vertices: IPoint[] = [];
    const lines: LineSegment[] = [];
    polylines.forEach((poly) => {
      poly.vertices.forEach((v, i, arr) => {
        vertices.push(v.position);
        if (v.through) {
          vertices.push(v.through);
        }

        const next = arr[i + 1];
        if (next && !next.through && !pointsEqual(v.position, next.position)) {
          lines.push({ start: v.position, end: next.position });
        }
      });
    });
    const zoom = localZoom();
    const vertexLimit = 12 / zoom;
    const lineLimit = 12 / zoom;
    return { snap, vertices, lines, lineLimit, vertexLimit };
  }),
  switchMap(({ snap, vertices, lines, vertexLimit, lineLimit }) =>
    mouseMove$.pipe(
      throttleTime(100), // Throttle mouse move events to once every 100ms
      map((e) => {
        const shift = e.modifiers.shift;
        const v = drawPolyService.vertices;
        if (shift && v.length > 0) {
          const lastVertex = v[v.length - 1];
          const shiftedPoint = getAnglePoint(
            drawPolyService.vertices,
            lastVertex,
            e.point,
            0,
          );
          const thisLine = {
            start: lastVertex.position,
            end: { x: shiftedPoint.x, y: shiftedPoint.y },
          };
          const intersections = lines.map((l) => getIntersection(thisLine, l));
          const intersection = intersections.reduce(
            (acc: { distance: number; pos: IPoint | null }, intersect) => {
              if (intersect) {
                const d = getEuclideanDistance(intersect, shiftedPoint);
                if (d < acc.distance) {
                  return { distance: d, pos: intersect };
                }
              }
              return acc;
            },
            { distance: Infinity, pos: null },
          );
          if (intersection.pos && intersection.distance < lineLimit) {
            snap.position = new paper.Point(intersection.pos);
            snap.visible = true;
            drawPolyService.setClosestVertexPosition({
              x: intersection.pos.x,
              y: intersection.pos.y,
            });
          } else {
            drawPolyService.setClosestVertexPosition(null);
            snap.visible = false;
          }
        } else {
          const closestVertex = vertices.reduce(
            (acc: { distance: number; pos: IPoint | null }, v) => {
              const distance = getEuclideanDistance(v, e.point);
              if (distance < acc.distance) {
                return { distance, pos: v };
              }
              return acc;
            },
            { distance: Infinity, pos: null },
          );
          const closestLinePoint = getClosestPoint(lines, e.point);
          const closestLinePointDistance = getEuclideanDistance(
            closestLinePoint,
            e.point,
          );
          if (closestVertex.distance < vertexLimit && closestVertex.pos) {
            snap.position = new paper.Point(closestVertex.pos);
            snap.visible = true;
            drawPolyService.setClosestVertexPosition({
              x: closestVertex.pos.x,
              y: closestVertex.pos.y,
            });
          } else if (closestLinePointDistance < lineLimit && closestLinePoint) {
            snap.position = new paper.Point(closestLinePoint);
            snap.visible = true;
            drawPolyService.setClosestVertexPosition({
              x: closestLinePoint.x,
              y: closestLinePoint.y,
            });
          } else {
            drawPolyService.setClosestVertexPosition(null);
            snap.visible = false;
          }
        }
      }),
      takeUntil(
        merge(
          toolService.activeTool$.pipe(filter((mode) => mode !== 'polyline')),
          drawPolyService.snapMode$.pipe(filter((snapMode) => !snapMode)),
        ).pipe(tap(() => snap.remove())),
      ),
    ),
  ),
);

const polyHint$ = combineLatest([
  mouseMove$,
  drawPolyService.arcMode$,
  drawPolyService.vertices$,
  drawPolyService.thruPoint$,
  drawPolyService.overrideMode$,
  drawPolyService.nextLength$,
]).pipe(
  filter(([_, arcMode, vertices]) => vertices.length > 0),
  tap(([e, arcMode, vertices, thruPoint, overrideMode, nextLength]) => {
    const lastVertex = vertices[vertices.length - 1];
    drawPolyService.resetPolyHint();
    drawPolyService.addPointToHint(new paper.Point(lastVertex.position));
    if (arcMode) {
      renderPolyHintArc(overrideMode, e.point, thruPoint, vertices);
    } else {
      renderPolyHintLine(e.modifiers.shift, vertices, e.point, nextLength);
    }
  }),
);

const showCloseLine$ = toolService.activeTool$.pipe(
  filter((mode) => mode === 'polyline'),
  switchMap(() =>
    combineLatest([drawPolyService.closePath$, drawPolyService.vertices$]).pipe(
      tap(([closePath, vertices]) => {
        drawPolyService.resetPolyClose();
        if (closePath && vertices.length > 1) {
          drawPolyService.createCloseLine();
        }
      }),
    ),
  ),
);

const addVertex$ = mouseDown$.pipe(
  filter(({ e }) => !e.modifiers.space),
  withLatestFrom(
    drawPolyService.arcMode$,
    drawPolyService.thruPoint$,
    drawPolyService.overrideMode$,
    drawPolyService.vertices$,
    drawPolyService.nextLength$,
    drawPolyService.snapMode$,
  ),
  switchMap(
    ([_, arcMode, thruPoint, overrideMode, vertices, nextLength, snapMode]) =>
      mouseUp$.pipe(
        take(1),
        takeUntil(keyUp$),
        tap((e) => {
          const thisPoint = snapMode
            ? new paper.Point(drawPolyService.closestVertexPosition || e.point)
            : e.point;
          const lastVertex = vertices[vertices.length - 1];
          if (arcMode && lastVertex) {
            if (overrideMode) {
              setThroughPoint(thisPoint, true);
            } else if (thruPoint?.override) {
              addOverrideArc(
                { x: thisPoint.x, y: thisPoint.y },
                { x: thruPoint.x, y: thruPoint.y },
                vertices,
              );
            } else {
              if (thruPoint) {
                addRegularArc(thisPoint, thruPoint, vertices);
              } else {
                console.log('not sure how you would get here');
              }
            }
          } else {
            addStandardVertex(
              thisPoint,
              vertices,
              e.modifiers.shift,
              nextLength,
            );
          }
        }),
      ),
  ),
);
const drawActivePoly$ = drawPolyService.vertices$.pipe(
  isPolyMode(),
  tap((vertices) => {
    if (vertices.length) {
      drawPolyService.resetPoly();
      vertices.forEach((v) => {
        if (v.through) {
          drawPolyService.addArcToPoly(
            new paper.Point(v.through),
            new paper.Point(v.position),
          );
        } else {
          drawPolyService.addPointToPoly(new paper.Point(v.position));
        }
      });
    } else {
      drawPolyService.cleanupPoly();
    }
  }),
);
const endOnClickC$ = merge(
  keyUp$.pipe(filter((e) => e.key === 'c')),
  toolService.escapeKey$,
).pipe(
  isPolyMode(),
  withLatestFrom(drawPolyService.closePath$),
  tap(([e, closePath]) => {
    drawPolyService.finalize(closePath);
  }),
);

const endOnModeSwitch$ = toolService.activeTool$.pipe(
  filter((mode) => mode !== 'polyline'),
  tap(() => {
    drawPolyService.end();
  }),
);
// const endDrawingOnEscape$ = escapeKey$.pipe(
//   isPolyMode(),
//   tap(() => {
//     polyService.finalize()
//   })
// )
export const drawPolyline$ = merge(
  polyHint$,
  addVertex$,
  drawActivePoly$,
  endOnModeSwitch$,
  showCloseLine$,
  endOnClickC$,
  closestVertex$,
  polySnapToggle$,
  polyArcToggle$,
  polyOverrideToggle$,
);
