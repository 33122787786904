import React, { useState } from 'react';
import { localPaper } from '../../localPaper';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';
import { useUserInteraction } from './useUserInteraction';

const DefaultLayer = ({ open, onOpen }) => {
  const [count, setCount] = useState(0);
  const [title, setTitle] = React.useState('Default');
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.DEFAULT,
  );

  useUserInteraction(() => {
    const c = localPaper.project.layers[LAYER_NAMES.DEFAULT]?.children.length;
    if (c !== count) {
      setCount(c);
      setTitle(`Default (${c})`);
    }
  }, 1500);

  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={title}
      open={open}
      onOpen={onOpen}
    />
  );
};

export default React.memo(DefaultLayer);
