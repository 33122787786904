import { IEdge } from '@shared-types';
import { addUndo } from '../contexts/design/undo';
import { paperItemStore } from '../helpers';
import { generateGraphs } from '../helpers/directedGraph';
import { hopPipes } from '../helpers/hopping';
import { isPaperPipe } from '../tools/paper-items/paper-pipe';
import { getState, setState } from './state-basics';

export const changeEdges = (payload: IEdge[], undoable = true) => {
  if (undoable) {
    const oldEdges = getState().edges;
    addUndo(() => {
      changeEdges(oldEdges, false);
    }, 'change edges');
  }
  const { elements, zones, elementCache, scale, edges } = getState();
  let newEdges = edges.map(
    (edge: IEdge) => payload.find((e) => e.uuid === edge.uuid) || edge,
  );
  const { masterGraph, pocGraphs } = generateGraphs(newEdges, elements, zones);
  newEdges = hopPipes(
    zones,
    newEdges,
    elements,
    elementCache,
    scale,
    pocGraphs,
  );
  setState({
    edges: newEdges,
    masterGraph,
    pocGraphs,
  });
  payload.forEach((edge) => {
    const paperItem = paperItemStore.get(edge.uuid);
    if (paperItem && isPaperPipe(paperItem)) {
      paperItem.update(edge);
    }
  });
};
