import { takeUntil } from 'rxjs';
import paper from 'src/paper';
import {
  HANDLE_TYPE,
  IPoint,
  Polyline,
  Vertex,
} from '../../../../../shared-types';
import {
  defaultStyledItem,
  getItemByUUID,
  getItemsByType,
  isPolyline,
  paperItemStore,
} from '../helpers';
import { activateNamedLayer } from '../paper-helpers/plot.helpers';
import { addPoints } from '../shared/geometry';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { getState, refreshSelectedIDs } from '../state';
import { deletePaperItem, updatePaperItem } from '../state/renderers';
import { toolService } from '../tool.service';
import { ItemService } from './ItemService';
import { drawPolyline$ } from './drawPolyline';
import { registerStateHooks } from './item.model';
import { PaperPolyline, isPaperPolyLine } from './paper-polyline';
import { getPolylineYards } from './polyline-helpers';

class PolylineService implements ItemService<Polyline> {
  init() {
    this.registerWithState();
    drawPolyline$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
  }
  createItem = (): Polyline => {
    const item = defaultStyledItem();
    return {
      ...item,
      itemType: 'polyline',
      vertices: [],
      closed: false,
      polyType: 'tracing',
      style: {
        ...item.style,
        strokeWidth: getState().strokeWidth,
      },
    };
  };
  renderItemToPaper = (item: Polyline, layer = LAYER_NAMES.TRACING) => {
    activateNamedLayer(layer);
    paperItemStore.set(item.uuid, new PaperPolyline(item));
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  };
  registerWithState = () => {
    registerStateHooks<Polyline>('polyline', {
      postAddItem: (item) => {
        this.renderItemToPaper(item);
      },
      postUpdateItem: (item) => {
        updatePaperItem(item);
        if (getState().selectedIDs.includes(item.uuid)) {
          refreshSelectedIDs();
        }
      },
      postDeleteItem: (item) => {
        deletePaperItem(item.uuid);
      },
    });
  };
}

export const polylineService = new PolylineService();

export const defaultVertex = (): Vertex => ({
  ...defaultStyledItem(),
  itemType: 'vertex',
  through: null,
  position: { x: 0, y: 0 },
});

export const movePolylineVertices = (
  polyline: Polyline,
  diffPosition: IPoint,
): Polyline => {
  const vertices = polyline.vertices.map((vertex) => ({
    ...vertex,
    position: addPoints(vertex.position, diffPosition),
    through: vertex.through ? addPoints(vertex.through, diffPosition) : null,
  }));
  return {
    ...polyline,
    vertices,
  };
};
// export const rotatePolylineVertices = (
//   polyline: Polyline,
//   center: IPoint,
//   angle: number,
// ): Polyline => {
//   const vertices = polyline.vertices.map((vertex) => {
//     return {
//       ...vertex,
//       position: rotateAroundPoint(center, vertex.position, angle),
//       through: vertex.through
//         ? rotateAroundPoint(center, vertex.through, angle)
//         : null,
//     };
//   });
//   return {
//     ...polyline,
//     vertices,
//   };
// };

export function isHandleType(itemName: string): itemName is HANDLE_TYPE {
  return [
    'handle.vertex',
    'handle.rotate',
    'handle.scale',
    'handle.poly',
  ].includes(itemName);
}

export const getPolyYardIndex = (uuid: string) => {
  const item = getItemByUUID(uuid, isPolyline);
  const yardPolys = getPolylineYards();
  return yardPolys.findIndex((y) => y.uuid === item.uuid);
};
export const getPointsFromPolyline = (polyline: Polyline) => {
  const paperPoly = paperItemStore.get(polyline.uuid);
  if (paperPoly && isPaperPolyLine(paperPoly)) {
    return getPointsFromPaperPolyline(paperPoly);
  } else {
    throw new Error('No paperPoly found for polyline');
  }
};
export const getPointsFromPaperPolyline = (paperPoly: PaperPolyline) => {
  const paperPolyItem = paperPoly.paperItem;
  const p = new paper.Path(paperPolyItem.pathData);
  p.flatten(0.25);
  const points = p.segments.map((s) => ({ x: s.point.x, y: s.point.y }));
  p.remove();
  return points;
};

export const updateActivePoly = (activeYardIndex: number) => {
  const polyYards = getItemsByType(isPolyline).filter(
    (p) => p.polyType === 'yard',
  );
  polyYards.forEach((p, i) => {
    const shouldActivate = i === activeYardIndex;
    const paperPoly = paperItemStore.get(p.uuid);
    if (paperPoly && isPaperPolyLine(paperPoly)) {
      paperPoly.activateYard(shouldActivate);
    }
  });
};
