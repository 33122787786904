import { DesignElement, IEdge, IPoint, Tools } from '@shared-types';
import { takeUntil } from 'rxjs';
import paper from 'src/paper';
import { polyHintPurple } from '../../../shared';
import { newUUID } from '../crypto-uuid';
import { PaperEvType } from '../custom-events';
import { getLateralPipeProduct } from '../features/pipes/Pipes';
import { defaultFitting, paperItemStore } from '../helpers';
import { getRotation, pointsEqual } from '../helpers/geometry.helpers';
import { localPaper, localZoom } from '../localPaper';
import { bisectPipe, createDefaultEdge } from '../paper-helpers/edges';
import { getColor } from '../paper-helpers/plot.helpers';
import { getEuclideanDistance, toRadians } from '../shared/geometry';
import { ITEMNAMES } from '../shared/workbench-enums';
import { getState, selectItems } from '../state';
import { addElementsEdges } from '../state/addElementsEdges';
import { ToolService } from '../tool.service';
import {
  PaperPipe,
  isEdgeHopData,
  isPaperPipe,
} from './paper-items/paper-pipe';
import { isSprinkler } from './paper-items/paper-sprinkler';
import { getProtectedEdgeNodes } from './tool.helpers';

export const setupEdgeTool = (toolService: ToolService) => {
  let newSegment = new paper.Path({
    strokeWidth: 0,
  });

  let snappedEdge: null | IEdge = null;

  const { scale } = getState();
  const overlapperRect = new paper.Rectangle(
    new paper.Point(0, 0),
    new paper.Size(8 / scale, 8 / scale),
  );

  const closestPoint = new paper.Path.Circle(new paper.Point(0, 0), 3 / scale);
  closestPoint.name = ITEMNAMES.SNAP_POINT;
  closestPoint.fillColor = getColor('orange');
  closestPoint.visible = false;
  let c = new paper.Path.Line(new paper.Point(0, 0), new paper.Point(0, 0));
  c.name = 'tmp';
  let start: IPoint | null = null;
  let end: IPoint | null = null;
  let isDragging = false;
  const g = new paper.Group();
  const a = 45;
  const setStart = (point: IPoint) => {
    start = { x: point.x, y: point.y };
  };
  const setEnd = (point: IPoint) => {
    end = { x: point.x, y: point.y };
  };
  let pathPoint = new paper.Point(0, 0);
  const edge = createDefaultEdge({});
  const cancel = () => {
    closestPoint.visible = false;
    newSegment = new paper.Path({
      strokeWidth: 0,
    });
    start = null;
    end = null;
    c.remove();
  };
  const clearSnap = () => {
    snappedEdge = null;
    closestPoint.visible = false;
  };
  window.addEventListener(PaperEvType.CHANGE_TOOL, (d: any) => {
    const tool = d.detail as Tools;
    if (tool !== 'Pipes') {
      cancel();
    }
    if (tool === 'Pipes') {
      selectItems([]);
      localPaper.project.deselectAll();
    }
  });

  const updateOverlapper = (event: paper.ToolEvent) => {
    const edges = getState().edges;
    const paperPipes = edges
      .map((edge) => paperItemStore.get(edge.uuid))
      .filter((p): p is PaperPipe => !!p && isPaperPipe(p));
    const protectedEdgeNodes = getProtectedEdgeNodes();
    overlapperRect.center = event.point;
    // TODO: find "all" the edges that overlap, then get closest point on each one, then use that point
    const overlappedEdges = paperPipes.filter(
      (e) =>
        !(
          protectedEdgeNodes.includes(e.edge.source) ||
          protectedEdgeNodes.includes(e.edge.target)
        ) &&
        e.paperLaterals.some(
          (p) =>
            getEuclideanDistance(p.getNearestPoint(event.point), event.point) <
            2,
        ),
    );
    if (overlappedEdges.length) {
      let closestDistance = 100000000;
      overlappedEdges.forEach((overlappedEdge) => {
        const closestPoints = overlappedEdge.paperLaterals.map((p) =>
          p.getNearestPoint(event.point),
        );

        closestPoints.sort((a, b) => {
          const distA = getEuclideanDistance(a, event.point);
          const distB = getEuclideanDistance(b, event.point);
          return distA - distB;
        });

        const distance = closestPoints[0].getDistance(event.point);
        // find the closest point on the line from event.point (current mouse point)
        if (distance < closestDistance) {
          closestDistance = distance;
          closestPoint.position = closestPoints[0];
          snappedEdge = overlappedEdge.edge;
        }
      });
      closestPoint.visible = true;
    } else {
      clearSnap();
    }
  };

  const onKeyUp = (e: paper.KeyEvent) => {
    if (e.key === 'escape') {
      cancel();
    }
  };
  const onKeyDown = (e: paper.KeyEvent) => {
    if (e.key === 'space') {
      e.preventDefault();
      return;
    }
  };
  const onMouseUp = (e: paper.ToolEvent) => {
    if (isDragging) {
      isDragging = false;
    } else {
      const { edges, pipeSnap } = getState();
      if (!edge.pipe) {
        const pipe = getLateralPipeProduct();
        edge.pipe = pipe ? pipe.uuid : '';
      }
      let newElement: DesignElement | null = null;
      if (!start && !isDragging) {
        if (e.item && e.item.name === ITEMNAMES.PIPE) {
          console.log('bisecting pipe');
          const pipeData = e.item.data;
          if (isEdgeHopData(pipeData)) {
            const clickedEdge = getState().edges.find(
              (e) => e.uuid === pipeData.edgeUUID,
            );
            if (clickedEdge) {
              const newFitting = bisectPipe(clickedEdge, {
                x: e.point.x,
                y: e.point.y,
              });
              if (newFitting) {
                setStart(e.point);
                newSegment.add(e.point);
                clickedEdge.source = newFitting;
              }
            }
          }
        } else if (e.item && e.item.name === ITEMNAMES.ELEMENT) {
          const clickedItem = e.item.data as DesignElement;
          const goodSprinkler =
            isSprinkler(clickedItem) &&
            edges.filter(
              (edge) =>
                edge.source === clickedItem.uuid ||
                edge.target === clickedItem.uuid,
            ).length < 2;
          if (clickedItem.type !== 'sprinkler' || goodSprinkler) {
            setStart(e.item.position);
            newSegment.add(e.point);
            edge.source = clickedItem.uuid;
          }
          if (isSprinkler(clickedItem) && !goodSprinkler) {
            console.error('this head already has 2 pipes');
          }
        } else {
          if (pipeSnap && snappedEdge) {
            const snappedPoint = closestPoint.position;
            const newFitting = bisectPipe(snappedEdge, {
              x: snappedPoint.x,
              y: snappedPoint.y,
            });
            if (newFitting) {
              setStart(snappedPoint);
              newSegment.add(snappedPoint);
              edge.source = newFitting;
            }
          } else {
            const newFitting: DesignElement = {
              ...defaultFitting({ x: e.point.x, y: e.point.y }),
            };
            newElement = newFitting;
            edge.source = newFitting.uuid;
            setStart(e.point);
            newSegment.add(e.point);
          }
        }
      } else {
        if (e.item && e.item.name === ITEMNAMES.PIPE) {
          const clickedEdge = e.item.data;
          if (isEdgeHopData(clickedEdge)) {
            const edge = getState().edges.find(
              (e) => e.uuid === clickedEdge.edgeUUID,
            );
            if (edge) {
              const newFitting = bisectPipe(edge, e.point);
              if (newFitting) {
                setEnd(e.point);
                newSegment.add(e.point);
                edge.target = newFitting;
              }
            }
          }
        } else if (e.item && e.item.name === ITEMNAMES.ELEMENT) {
          const clickedItem = e.item.data as DesignElement;
          const goodSprinkler =
            isSprinkler(clickedItem) &&
            edges.filter(
              (edge) =>
                edge.source === clickedItem.uuid ||
                edge.target === clickedItem.uuid,
            ).length < 2;
          if (clickedItem.type !== 'sprinkler' || goodSprinkler) {
            if (edge.source !== clickedItem.uuid) {
              setEnd(e.item.position);
              newSegment.add(e.point);
              edge.target = clickedItem.uuid;
            } else {
              console.log('...same misc item');
            }
          }
          if (isSprinkler(clickedItem) && !goodSprinkler) {
            console.error('this head already has 2 pipes');
          }
        } else {
          if (pipeSnap && snappedEdge) {
            const snappedPoint = closestPoint.position;
            const newFitting = bisectPipe(snappedEdge, {
              x: snappedPoint.x,
              y: snappedPoint.y,
            });
            if (newFitting) {
              setEnd(snappedPoint);
              newSegment.add(snappedPoint);
              edge.target = newFitting;
            }
          } else {
            let p = e.point;
            if (getState().ortho) {
              p = pathPoint;
            }
            const newFitting: DesignElement = {
              ...defaultFitting({ x: p.x, y: p.y }),
            };
            newElement = newFitting;
            edge.target = newFitting.uuid;
            setEnd(p);
            newSegment.add(p);
          }
        }
      }
      if (start && end) {
        const els = newElement ? [newElement] : [];
        if (
          els.length &&
          getState().elements.find((el) =>
            pointsEqual(el.position, els[0].position),
          )
        ) {
          alert('Cannot place two fittings in the same spot');
          return;
        } else {
          addElementsEdges(els, [{ ...edge, uuid: newUUID() }]);
          edge.source = edge.target;
          edge.target = '';
          newSegment = new paper.Path({
            strokeWidth: 0,
          });
          newSegment.add(new paper.Point(start), new paper.Point(end));
          start = { ...end };
          end = null;
        }
      } else {
        if (newElement) {
          addElementsEdges([newElement], []);
        }
      }
    }
    clearSnap();
  };
  const onMouseMove = (event: paper.ToolEvent) => {
    const { pipeSnap, ortho } = getState();
    if (pipeSnap) {
      updateOverlapper(event);
    }
    if (!pipeSnap) {
      clearSnap();
    }
    if (start) {
      c.remove();
      pathPoint = event.point;
      if (ortho) {
        const d = getEuclideanDistance(start, event.point);
        let angle = 0;
        if (newSegment.segments.length > 1) {
          angle = Math.round(
            getRotation(
              newSegment.lastSegment.previous.point,
              newSegment.lastSegment.point,
            ),
          );
        }
        g.removeChildren();
        let closest = 10000;

        for (let i = angle; i < 360 + angle; i += a) {
          const p1: IPoint = {
            x: start.x + d * Math.cos(toRadians(i)),
            y: start.y + d * Math.sin(toRadians(i)),
          };
          const distance = getEuclideanDistance(event.point, p1);
          if (distance < closest) {
            closest = distance;
            pathPoint = new paper.Point(p1);
          }
          const p = new paper.Path.Circle(new paper.Point(p1), 3 / localZoom());
          p.fillColor = getColor('red');
          p.name = ITEMNAMES.ORTHO_DOT;
          g.addChild(p);
        }
      }
      c = new paper.Path.Line(new paper.Point(start), pathPoint);
      c.strokeColor = getColor(polyHintPurple);
      c.strokeWidth = 1 / localZoom();
      c.name = 'real';
      c.locked = true;
    } else {
      c = new paper.Path.Line(new paper.Point(0, 0), new paper.Point(0, 0));
      c.name = 'tmp';
    }
  };

  toolService
    .keyUp$('Pipes')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onKeyUp);
  toolService
    .keyDown$('Pipes')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onKeyDown);
  toolService
    .mouseMove$('Pipes')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseMove);
  toolService
    .mouseUp$('Pipes')
    .pipe(takeUntil(toolService.destroyTools$))
    .subscribe(onMouseUp);
};
