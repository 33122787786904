import * as pdfjs from 'pdfjs-dist/build/pdf.mjs';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.mjs';
import React, { useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  ImageFile,
  ImageUpload,
  PDFFile,
  PDFUpload,
} from '../../../../../shared-types/workbench-types';
import { ImageTable } from './ImageTable';
import { PDFTable } from './PDFTable';
import { isImageFile, isPDFFile, scales, uploadFile } from './upload.helpers';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const PDFUploader = ({
  onFinished,
  onCancel,
  limit = 0,
}: {
  onFinished: (originals: (PDFUpload | ImageUpload)[]) => void;
  onCancel: () => void;
  limit?: number;
}) => {
  const [userFiles, setUserFiles] = useState<any>({});
  const [uploading, setUploading] = useState(false);
  const [done, setDone] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [imgPreview, setImgPreview] = useState('');

  const handleImageUpload = async (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = new Image();
      image.onload = () => {
        const newFile: ImageFile = {
          type: 'image',
          filename: file.name,
          file: file,
          width: image.width,
          height: image.height,
          scale: scales[3].value,
          originalScale: scales[3].value,
          preview: e.target?.result as string,
        };
        setUserFiles((prev: any) => ({
          ...prev,
          [file.name]: newFile,
        }));
      };
      image.src = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  };
  const handlePDFUpload = async (file: File) => {
    const reader = new FileReader();
    const newPDF: PDFFile = {
      type: 'pdf',
      filename: file.name,
      file: file,
      pageCount: 0,
      pages: [],
      scale: scales[3].value,
      originalScale: scales[3].value,
    };
    setUserFiles((prev: any) => ({
      ...prev,
      [file.name]: newPDF,
    }));
    reader.onload = async function () {
      const typedarray = new Uint8Array(this.result as ArrayBuffer);
      const doc = await pdfjs.getDocument(typedarray);
      const d = await doc.promise;
      const originalPDF: PDFFile = {
        filename: file.name,
        type: 'pdf',
        file: file,
        pages: [],
        pageCount: d.numPages,
        scale: scales[3].value,
        originalScale: scales[3].value,
      };
      setUserFiles((prev: any) => ({
        ...prev,
        [file.name]: originalPDF,
      }));
      for (let i = 1; i <= d.numPages; i++) {
        const page = await d.getPage(i);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        if (context) {
          const renderContext = {
            background: 'rgba(0,0,0,0)',
            canvasContext: context,
            viewport,
          };
          const renderTask = page.render(renderContext);
          await renderTask.promise;
          setUserFiles((prev: { [x: string]: any }) => {
            const f = prev[file.name];
            if (isPDFFile(f)) {
              return {
                ...prev,
                [file.name]: {
                  ...f,
                  pages: [
                    ...f.pages,
                    {
                      pageNumber: i,
                      width: viewport.width,
                      height: viewport.height,
                      jpgPreviewDataURL: canvas.toDataURL('image/png', 90),
                      rotation: viewport.rotation,
                    },
                  ],
                },
              };
            }
            return prev;
          });
        }
      }
    };
    console.log(file);
    reader.readAsArrayBuffer(file);
  };

  const handleFilesChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fileList = Array.from(event.target.files || []);
    fileList.forEach((f) => {
      if (f.type === 'image/jpeg' || f.type === 'image/png') {
        handleImageUpload(f);
      } else if (f.type === 'application/pdf') {
        handlePDFUpload(f);
      }
    });
  };
  const handleDropZoneClick = () => {
    fileInputRef.current?.click();
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const fileList = Array.from(event.dataTransfer.files).filter(
      (f) => f.type.startsWith('image/') || f.type === 'application/pdf',
    );
    // Reuse the same method we defined above
    handleFilesChange({ target: { files: fileList } } as any);
  };

  const handleUploadAll = async () => {
    setUploading(true);
    const files = Object.values(userFiles) as (PDFFile | ImageFile)[];
    const originals: (PDFUpload | ImageUpload)[] = [];
    // What's happening here? we're resolving each file
    // and making sure they have the scale brought from the UI.
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      const data = await uploadFile(f);
      originals.push(...data);
    }
    onFinished(originals);
    setUploading(false);
    setDone(true);
  };

  const cancel = () => {
    setUserFiles({});
    setDone(false);
    onCancel();
  };

  const removeFile = (filename: string) => {
    const newFiles = {
      ...userFiles,
    };
    delete newFiles[filename];
    setUserFiles(newFiles);
  };

  return (
    <Wrap>
      {!Object.keys(userFiles).length && (
        <div
          className={'dropzone' + (isDragging ? ' is-dragging' : '')}
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
          onDragEnter={() => setIsDragging(true)}
          onDragLeave={() => setIsDragging(false)}
          onClick={handleDropZoneClick}
        >
          Click to upload a PDF file
          <br />
          <br />
          or drag and drop a file here
        </div>
      )}
      <div
        className={`file-column ${!!Object.keys(userFiles).length && 'open'}`}
      >
        <div className="file-list">
          {(Object.values(userFiles) as (PDFFile | ImageFile)[]).map(
            (file, pdfIndex) => (
              <div key={pdfIndex}>
                {isImageFile(file) && (
                  <ImageTable
                    file={file}
                    removeFile={() => removeFile(file.filename)}
                    updateFile={(updatedImageFile) => {
                      setUserFiles((prev: any) => ({
                        ...prev,
                        [file.filename]: updatedImageFile,
                      }));
                    }}
                  />
                )}
                {isPDFFile(file) && (
                  <PDFTable
                    file={file}
                    removeFile={() => removeFile(file.filename)}
                    updateFile={(updatedPDFFile) => {
                      setUserFiles((prev: any) => ({
                        ...prev,
                        [file.filename]: updatedPDFFile,
                      }));
                    }}
                  />
                )}
              </div>
            ),
          )}
        </div>
        {!done && !!Object.keys(userFiles).length && (
          <div className="action-buttons">
            <Button onClick={handleDropZoneClick}>+ More Files</Button>
            <div className="upload-button">
              <Button onClick={cancel}>Cancel</Button>
              <Button
                primary
                disabled={uploading}
                onClick={handleUploadAll}
                loading={uploading}
              >
                Upload
              </Button>
            </div>
          </div>
        )}
        {done && (
          <div className="upload-button">
            <Icon name="check" color="green" /> Done
          </div>
        )}
      </div>
      <Modal
        open={!!imgPreview}
        size="fullscreen"
        closeOnDimmerClick
        closeOnEscape
        closeOnDocumentClick
        closeIcon={true}
        onClose={() => setImgPreview('')}
      >
        <img src={imgPreview} width="100%" />
      </Modal>
      <input
        type="file"
        ref={fileInputRef}
        multiple={limit > 1}
        accept="application/pdf,image/jpeg,image/png"
        onChange={handleFilesChange}
        style={{ display: 'none' }}
      />
    </Wrap>
  );
};
const Wrap = styled.div`
  padding: 16px;
  display: flex;
  overflow: hidden;
  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dropzone {
    border: 2px dashed #ccc;
    text-align: center;
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s linear;
    &:hover {
      cursor: pointer;
      border-color: #000;
    }
    &.is-dragging {
      background-color: #51c59c;
    }
  }
  .file-column {
    flex: 0;
    min-width: 0;
    &.open {
      flex: 1;
    }
    .upload-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 16px;
    }
    .status {
      margin-right: 16px;
      font-size: 10px;
    }
  }
  .pdf-record {
    margin-bottom: 16px;
    border: 1px solid #ccc;
    padding: 8px;
  }
  .pdf-header {
    display: flex;
    align-items: center;
    .icon {
      cursor: pointer;
      margin-right: 8px;
    }
  }
  .file-list {
    height: 60vh;
    overflow: auto;
    padding-right: 8px;
    .table {
      margin-bottom: 32px;
    }
  }
  .file-item {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    font-size: 12px;
    &:nth-child(2n) {
      background: #f5f5f5;
    }
  }
  .size {
    span {
      display: inline-block;
      margin-right: 16px;
    }
  }
  .status-icon {
    display: block;
    flex: 0 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file-description {
    padding: 8px 0;
  }
  .preview-wrap {
    display: flex;
    align-items: center;
  }
  .icon-preview {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #eee; */
    margin-right: 8px;
    &.preview {
      cursor: pointer;
    }

    .icon {
      font-size: 14px;
      line-height: 14px;
      margin: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      object-position: center center;
    }
  }
  .icon.remove-file {
    cursor: pointer;
    color: #ccc;
    &:hover {
      color: #980505;
    }
  }
`;
