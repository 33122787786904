import { Catalog } from '@shared-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Loader } from 'semantic-ui-react';
import { PortalContext } from 'src/contexts/portal/portal';
import { ProfileContext } from 'src/contexts/profile/profile';
import styled from 'styled-components';
import { LocalNozzleData, getLocalNozzles } from '../helpers/nozzleTransformer';
import {
  fetchNozzles,
  getDesign,
  getPipeAlgoVersions,
} from '../services/design.service';
import { migrateDesign } from '../shared/design-migrator';
import {
  getState,
  setDesign,
  setLastSave,
  setLateralPipe,
  setMainPipe,
} from '../state';
import { toolService } from '../tool.service';
import { PlotMap } from './PlotMap';

const getNozzleData = async () => {
  const data = await fetchNozzles();
  const newLocalData: LocalNozzleData = {
    updated: new Date().getTime(),
    nozzles: data[1].nozzles,
    heads: data[0].heads,
    headNozzles: data[1].headNozzles,
  };
  localStorage.setItem('is.nozzleData', JSON.stringify(newLocalData));
};

export interface AlgoVersion {
  name: string;
  version: string;
}

export const algoVersions: AlgoVersion[] = [];
// let localSaveTimer;

const Workbench = () => {
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const portal = useContext(PortalContext);
  const { user } = useContext(ProfileContext);

  const { catalog, loadCatalog } = portal;
  const [loading, setLoading] = useState(true);
  const [owner, setOwner] = useState<number | null>();
  useEffect(() => {
    toolService.init();
    return () => {
      toolService.destroy();
    };
  }, []);

  const refreshData = async (id: number, catalog: Catalog) => {
    setLoading(true);
    const nozzles = getLocalNozzles();
    if (!nozzles.length) {
      await getNozzleData();
    }
    try {
      const { design } = await getDesign(id);
      const versions = await getPipeAlgoVersions();
      // const data: IStore = design.data
      // accounting for new additions to the design json...
      let data = migrateDesign(design.data, catalog);
      algoVersions.push(...versions);
      if (versions.length && !data.pipeAlgoVersion) {
        data.pipeAlgoVersion = versions[versions.length - 1].version;
      }
      data.lastSave = design.updatedAt;
      const svg = await (await fetch(data.svgURL)).text();
      setOwner(design.userId);
      setDesign({
        ...data,
        svg,
      });
      setLastSave(design.updatedAt);
      setLoading(false);
    } catch (err) {
      navigate('/app');
    }
  };

  useEffect(
    () => {
      if (!catalog.pipes.length) {
        loadCatalog();
      } else {
        if (
          catalog.pipes.length &&
          catalog.backflows.length &&
          catalog.prvs.length &&
          catalog.valves.length &&
          catalog.flowKits.length &&
          id
        ) {
          refreshData(parseInt(id, 10), catalog);
          if (!getState().lateralPipe) {
            setLateralPipe(catalog.pipes[0].series);
          }
          if (!getState().mainPipe) {
            setMainPipe(catalog.pipes[0].series);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, catalog],
  );
  return (
    <Wrap className="workbench">
      <>
        {user && owner && user.email && user.id !== owner && (
          <div className="not-yours">
            You are not the owner of this design, so you are in read-only mode.
          </div>
        )}
        {loading && (
          <Loader size="huge" className="loader-thing" active inline="centered">
            Loading
          </Loader>
        )}
        {!loading && !!id && <PlotMap />}
      </>
    </Wrap>
  );
};
const Wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 0;
  /* overflow: hidden;
  position: relative; */
  .loader-thing {
    align-self: center;
    justify-content: center;
  }
  .not-yours {
    padding: 4px;
    color: red;
    font-weight: bold;
    position: absolute;
    top: 5px;
    left: 5px;
  }
  .chart {
    flex: 1;
    overflow: hidden;
    position: relative;
    display: flex;
  }
`;

const designedWorkbench = () => <Workbench />;
export default designedWorkbench;
