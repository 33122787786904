import { Query, UserUI } from '@shared-types';
import { wrapFetch, wrapFetchWithQuery } from './fetchHelpers';

export const getUsers = async (
  query?: Query,
): Promise<{ users: UserUI[]; total: number }> =>
  wrapFetchWithQuery(`/api/users`, 'GET', query);

export const editUser = async (user: UserUI): Promise<{ user: UserUI }> =>
  wrapFetch(`/api/users/${user.id}`, 'PUT', { user });

export const getBasemappers = async (): Promise<{ users: UserUI[] }> =>
  wrapFetch(`/api/users/basemappers`);

export const getDesigners = async (): Promise<{ users: UserUI[] }> =>
  wrapFetch(`/api/users/designers`);
