import Slider from 'rc-slider/lib/Slider';
import { useState } from 'react';
import { Button, Container, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { PanelButton } from '../../components/PanelButton';
import { generateSleeves } from './sleeves.paper';

export const GenerateSleeves = () => {
  const [threshold, setThreshold] = useState(1);
  const [generateOpen, setGenerateOpen] = useState(false);
  return (
    <Wrap>
      <PanelButton onClick={() => setGenerateOpen(true)}>
        Generate Sleeves
      </PanelButton>
      <Modal open={generateOpen} size="mini">
        <Modal.Header>Generate Sleeves</Modal.Header>
        <Modal.Content>
          <Container>
            <Slider
              defaultValue={threshold}
              value={threshold}
              onChange={(w) => setThreshold(w as number)}
              step={0.1}
              min={0.1}
              max={10}
            />{' '}
            Only generate sleeves larger than {threshold} feet
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setGenerateOpen(false)}>Cancel</Button>
          <Button
            primary
            onClick={() => {
              generateSleeves(threshold);
              setGenerateOpen(false);
            }}
          >
            Generate
          </Button>
        </Modal.Actions>
      </Modal>
    </Wrap>
  );
};

const Wrap = styled.div``;
