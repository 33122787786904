import { UserUI } from '@shared-types';
import { useState } from 'react';
import { Button, Dimmer, Form, Loader, Table } from 'semantic-ui-react';
import { saveProfile } from 'src/services/account.service';
import { states } from 'src/shared/constants';
import styled from 'styled-components';

export const AccountForm = ({ user }: { user: UserUI }) => {
  const [account, setAccount] = useState(user);
  const [loading, setLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState(false);

  const handleChange = (_, f) => {
    if (f.name) {
      setAccount({
        ...account,
        [f.name]: f.value,
      });
    }
  };

  const handleSaveProfile = async (acc: UserUI) => {
    setLoading(true);
    await saveProfile(acc);
    setLoading(false);
    setEdit(false);
  };

  return (
    <Wrap>
      {!!account.id && (
        <>
          {!edit && (
            <div>
              <Table basic="very" collapsing={true}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>First Name</Table.Cell>
                    <Table.Cell>{account.firstName || '-'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Last Name</Table.Cell>
                    <Table.Cell>{account.lastName || '-'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Company</Table.Cell>
                    <Table.Cell>{account.company || '-'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Phone</Table.Cell>
                    <Table.Cell>{account.phone || '-'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Address</Table.Cell>
                    <Table.Cell>{account.address || '-'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>City</Table.Cell>
                    <Table.Cell>{account.city || '-'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>State</Table.Cell>
                    <Table.Cell>{account.state || '-'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Zip</Table.Cell>
                    <Table.Cell>{account.zip || '-'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Country</Table.Cell>
                    <Table.Cell>{account.country || '-'}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Button size="mini" onClick={() => setEdit(true)}>
                Edit Profile
              </Button>
            </div>
          )}
          {!!edit && (
            <>
              <Form
                size="tiny"
                onSubmit={handleSaveProfile.bind(null, account)}
              >
                <Table basic="very" collapsing={true}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>First Name</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          value={account.firstName || ''}
                          name="firstName"
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Last Name</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          value={account.lastName || ''}
                          name="lastName"
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Company</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          value={account.company || ''}
                          name="company"
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Phone</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          value={account.phone || ''}
                          name="phone"
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Address</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          value={account.address || ''}
                          name="address"
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>City</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          value={account.city || ''}
                          name="city"
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>State</Table.Cell>
                      <Table.Cell>
                        <Form.Select
                          value={account.state || ''}
                          name="state"
                          options={states.map((s) => ({ text: s, value: s }))}
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Zip</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          value={account.zip || ''}
                          name="zip"
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Country</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          value={account.country || ''}
                          name="country"
                          onChange={handleChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {account.role === 'admin' && (
                      <Table.Row>
                        <Table.Cell>Role</Table.Cell>
                        <Table.Cell>
                          <Form.Select
                            name="role"
                            disabled={account.role === 'admin'}
                            options={[
                              'admin',
                              'basemap',
                              'designer',
                              'contractor',
                            ].map((s) => ({
                              text: s,
                              value: s,
                            }))}
                            value={account.role}
                            onChange={handleChange}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                    <Table.Row>
                      <Table.Cell colSpan="2">
                        <Form.Input
                          hidden={true}
                          type="hidden"
                          value={account.id}
                          name="id"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                  <Table.Footer>
                    <Table.Cell colSpan="2">
                      <Button type="submit">Save</Button>
                    </Table.Cell>
                  </Table.Footer>
                </Table>
              </Form>
              <Dimmer active={loading} inverted={true}>
                <Loader inline={true} />
              </Dimmer>
            </>
          )}
        </>
      )}
    </Wrap>
  );
};

const Wrap = styled.div``;
