import { InvoiceUI } from '@shared-types';
import { format, fromUnixTime } from 'date-fns';
import { useContext } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { ProfileContext } from 'src/contexts/profile/profile';
import {
  deleteDraftInvoice,
  finalizeInvoice,
  markInvoiceAsPaid,
  payInvoice,
  voidInvoice,
} from 'src/services/invoice.service';
import { emptyStateHeight } from 'src/styles/common';
import styled from 'styled-components';

interface Props {
  invoices: InvoiceUI[];
  onPay: () => void;
  onVoid: () => void;
  onFinalize: () => void;
}
export const InvoiceList = ({ invoices, onPay, onVoid, onFinalize }: Props) => {
  const { user } = useContext(ProfileContext);

  const isVoidDisplayed = (invoice: InvoiceUI): boolean =>
    user?.role === 'admin' &&
    invoice.status !== 'paid' &&
    invoice.status !== 'draft' &&
    invoice.status !== 'void';

  const isPayDisplayed = (invoice: InvoiceUI): boolean =>
    user?.role === 'contractor' &&
    invoice.status !== 'draft' &&
    invoice.status !== 'paid' &&
    invoice.status !== 'void';

  const isMarkAsPaidDisplayed = (invoice: InvoiceUI): boolean =>
    user?.role === 'admin' &&
    invoice.status !== 'draft' &&
    invoice.status !== 'paid' &&
    invoice.status !== 'void';
  const isDeleteDisplayed = (invoice: InvoiceUI): boolean =>
    user?.role === 'admin' && invoice.status === 'draft';

  const payInvoiceHandler = async (invoiceId: string) => {
    await payInvoice(invoiceId);
    onPay();
  };
  const voidInvoiceHandler = async (invoiceId: string) => {
    await voidInvoice(invoiceId);
    onVoid();
  };
  const deleteInvoiceHandler = async (invoiceId: string) => {
    await deleteDraftInvoice(invoiceId);
    onVoid();
  };

  const markInvoiceAsPaidHandler = async (invoiceId: string) => {
    await markInvoiceAsPaid(invoiceId);
    onPay();
  };
  const finalizedInvoice = async (invoiceId: string) => {
    await finalizeInvoice(invoiceId);
    onFinalize();
  };

  return (
    <Wrap>
      {!invoices.length && (
        <div className="empty-state">
          There are currently no invoices on file.
        </div>
      )}
      {!!invoices.length && (
        <Table size="small" striped={true} celled={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Invoice # / status</Table.HeaderCell>
              <Table.HeaderCell>Due</Table.HeaderCell>
              <Table.HeaderCell>Desc</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {invoices
              .filter((invoice) => {
                if (user?.role === 'admin') {
                  return true;
                } else {
                  return invoice.status !== 'draft';
                }
              })
              .map((invoice: InvoiceUI, i: number) => (
                <Table.Row
                  key={i}
                  warning={
                    invoice.status !== 'paid' && invoice.status !== 'void'
                  }
                  positive={invoice.status === 'paid'}
                  disabled={invoice.status === 'void'}
                >
                  <Table.Cell>
                    ${(invoice.amount_due / 100).toLocaleString()}
                  </Table.Cell>
                  <Table.Cell>
                    {invoice.status !== 'draft' && <div>{invoice.status}</div>}
                    {invoice.status === 'draft' && (
                      <div>
                        Invoice is in draft mode.
                        <br />
                        Click to finalize and send to {invoice.customer_email}.
                        <br />
                        <br />
                        <Button
                          size="mini"
                          primary
                          onClick={() => finalizedInvoice(invoice.id)}
                        >
                          Finalize
                        </Button>
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {invoice.due_date
                      ? format(fromUnixTime(invoice.due_date), 'MMM d, yyyy')
                      : 'n/a'}
                  </Table.Cell>
                  <Table.Cell>{invoice.description}</Table.Cell>
                  <Table.Cell>
                    {user && invoice.status === 'paid' && (
                      <div>
                        <a
                          href={invoice.hosted_invoice_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Receipt
                        </a>
                      </div>
                    )}
                    <Button.Group size="mini">
                      {isPayDisplayed(invoice) && (
                        <Button
                          basic={true}
                          color="orange"
                          onClick={() => payInvoiceHandler(invoice.id)}
                        >
                          Pay
                        </Button>
                      )}
                      {isVoidDisplayed(invoice) && (
                        <Button
                          basic={true}
                          color="red"
                          onClick={() => voidInvoiceHandler(invoice.id)}
                        >
                          Void
                        </Button>
                      )}
                      {isDeleteDisplayed(invoice) && (
                        <Button
                          basic={true}
                          color="red"
                          onClick={() => deleteInvoiceHandler(invoice.id)}
                        >
                          Delete
                        </Button>
                      )}
                      {isMarkAsPaidDisplayed(invoice) && (
                        <Button
                          basic={true}
                          color="blue"
                          onClick={() => markInvoiceAsPaidHandler(invoice.id)}
                        >
                          Mark as Paid
                        </Button>
                      )}
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          {!!user &&
            user.role === 'contractor' &&
            invoices.some((invoice) => invoice.status !== 'paid') && (
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan="5">
                    {/* {user && (
                      <Card.Content>{cards && <CardForm />}</Card.Content>
                    )} */}
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            )}
        </Table>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  flex: 1;
  overflow: auto;

  .help-text {
    color: orange;
  }

  .empty-state {
    height: ${emptyStateHeight}px;
  }
`;
