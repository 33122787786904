import { PDFDocument, PDFPage } from 'pdf-lib';
import { TitleBarConfig } from '../../../../../../shared-types';
import { createFullDesignSheets } from '../../components/saveAndDownloadPDF';

export const createMiniMapPagePDF = async (
  title: string,
): Promise<PDFDocument> => {
  const titleBarConfig: TitleBarConfig = {
    pageMargin: 0,
    titleBarWidth: 0,
    sidebarGap: 0,
    sheetWidth: 31,
    sheetHeight: 23,
    printWidth: 36,
    printHeight: 24,
    border: 0.5,
  };
  const finalPDF = await createFullDesignSheets(titleBarConfig);
  return await shrinkAndTilePDF(finalPDF, title);
};

const PAGE_WIDTH = 8.5 * 72; // Convert inches to points
const PAGE_HEIGHT = 11 * 72;
const TILE_WIDTH = 7 * 72;
const MARGIN = 0.4 * 72;
const SHEET_FONT_SIZE = 12;
const TITLE_FONT_SIZE = 16;

export const shrinkAndTilePDF = async (
  originalPDF: PDFDocument,
  title: string,
): Promise<PDFDocument> => {
  const pages = originalPDF.getPages();
  const tiledPDF = await PDFDocument.create();

  for (let i = 0; i < pages.length; i += 2) {
    const newPage = tiledPDF.addPage([PAGE_WIDTH, PAGE_HEIGHT]);
    let pageTopOffset = i === 0 ? 0.6 * 72 : SHEET_FONT_SIZE;

    if (i === 0) {
      drawTitle(newPage, title);
    }

    await drawTiledPage(newPage, pages[i], i + 1, pageTopOffset);

    if (i + 1 < pages.length) {
      await drawTiledPage(newPage, pages[i + 1], i + 2, pageTopOffset, true);
    }
  }

  return tiledPDF;
};

const drawTitle = (page: PDFPage, title: string) => {
  page.drawText(title, {
    x: MARGIN,
    y: PAGE_HEIGHT - MARGIN - TITLE_FONT_SIZE,
    size: TITLE_FONT_SIZE,
  });
};

const drawTiledPage = async (
  newPage: PDFPage,
  originalPage: PDFPage,
  sheetNumber: number,
  pageTopOffset: number,
  isSecondPage: boolean = false,
) => {
  const originalPageEmbedded = await newPage.doc.embedPage(originalPage);
  const [originalPageWidth, originalPageHeight] = [
    originalPage.getWidth(),
    originalPage.getHeight(),
  ];
  const tileHeight = (TILE_WIDTH * originalPageHeight) / originalPageWidth;
  const yOffset = isSecondPage
    ? calculateSecondPageYOffset(tileHeight)
    : PAGE_HEIGHT - tileHeight - MARGIN / 2;

  newPage.drawText(`Sheet ${sheetNumber}`, {
    x: MARGIN,
    y: yOffset + tileHeight - pageTopOffset - SHEET_FONT_SIZE,
    size: SHEET_FONT_SIZE,
  });

  newPage.drawPage(originalPageEmbedded, {
    x: MARGIN,
    y: yOffset - pageTopOffset,
    width: TILE_WIDTH,
    height: tileHeight,
  });
};

const calculateSecondPageYOffset = (tileHeight: number) => {
  return PAGE_HEIGHT - 2 * tileHeight - 2 * MARGIN - SHEET_FONT_SIZE;
};
