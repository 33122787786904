import { BehaviorSubject, Observable } from 'rxjs';
import paper from 'src/paper';
import { IPoint, Polyline, Vertex } from '../../../../../shared-types';
import { polyHintPurple } from '../../../shared';
import { addUndo } from '../contexts/design/undo';
import { localZoom } from '../localPaper';
import { getColor } from '../paper-helpers/plot.helpers';
import { addItem } from '../state/item-state';
import { polylineService } from './polyline.service';

class DrawPolyService {
  private _close = false;
  private _arc = false;
  private _thruPoint: { x: number; y: number; override?: boolean } | null =
    null;
  private _vertices: Vertex[] = [];
  private _overrideMode = false;
  private _snap = false;
  private _snapMode$ = new BehaviorSubject(this._snap);
  private _arcMode$ = new BehaviorSubject(this._arc);
  private _overrideMode$ = new BehaviorSubject(this._overrideMode);
  private _vertices$ = new BehaviorSubject<Vertex[]>([]);
  private _close$ = new BehaviorSubject<boolean>(this._close);
  private _thruPoint$ = new BehaviorSubject<{
    x: number;
    y: number;
    override?: boolean;
  } | null>(this._thruPoint);
  private _nextLength = 0;
  private _nextLength$ = new BehaviorSubject<number>(this._nextLength);

  private _polyline: Polyline | null = null;

  closestVertexPosition: IPoint | null = null;

  constructor() {}

  get overrideMode$(): Observable<boolean> {
    return this._overrideMode$.asObservable();
  }
  get arcMode$(): Observable<boolean> {
    return this._arcMode$.asObservable();
  }
  get thruPoint$(): Observable<{
    x: number;
    y: number;
    override?: boolean;
  } | null> {
    return this._thruPoint$.asObservable();
  }
  get vertices$(): Observable<Vertex[]> {
    return this._vertices$.asObservable();
  }
  get nextLength$(): Observable<number> {
    return this._nextLength$.asObservable();
  }
  get closePath$(): Observable<boolean> {
    return this._close$.asObservable();
  }
  get snapMode$(): Observable<boolean> {
    return this._snapMode$.asObservable();
  }
  get vertices(): Vertex[] {
    return this._vertices;
  }

  polyPath: paper.Path | null = null;
  polyHintPath: paper.Path | null = null;
  polyClosePath: paper.Path | null = null;

  createPoly = () => {
    this.polyPath = new paper.Path();
    this.polyPath.name = 'poly-tmp';
    this.polyPath.strokeColor = getColor(polyHintPurple);
    this.polyPath.strokeWidth = 1 / (localZoom() || 1);
    this.polyPath.dashArray = [];
  };
  createPolyHint = () => {
    this.polyHintPath = new paper.Path();
    this.polyHintPath.name = 'polyhint';
    this.polyHintPath.strokeColor = getColor(polyHintPurple);
    this.polyHintPath.strokeWidth = 1 / (localZoom() || 1);
  };
  createPolyClose = () => {
    this.polyClosePath = new paper.Path();
    this.polyClosePath.name = 'poly-close';
    this.polyClosePath.strokeColor = getColor(polyHintPurple);
    this.polyClosePath.strokeWidth = 1 / (localZoom() || 1);
    this.polyClosePath.dashArray = [4 / localZoom(), 4 / localZoom()];
  };
  resetPolyClose = () => {
    if (!this.polyClosePath) {
      this.createPolyClose();
    } else {
      this.polyClosePath.removeSegments();
    }
  };
  updateHintDashArray = (n: number[]) => {
    if (this.polyHintPath) {
      this.polyHintPath.dashArray = n;
    }
  };
  resetPoly = () => {
    if (!this.polyPath) {
      this.createPoly();
    }
    if (this.polyPath) {
      this.polyPath.removeSegments();
    }
  };
  resetPolyHint = () => {
    if (!this.polyHintPath) {
      this.createPolyHint();
    } else {
      this.polyHintPath.removeSegments();
    }
  };
  addPointToHint = (point: paper.Point) => {
    this.polyHintPath?.add(point);
  };
  addArcToHint = (through: paper.Point, to: paper.Point) => {
    this.polyHintPath?.arcTo(through, to);
  };
  addPointToPoly = (point: paper.Point) => {
    this.polyPath?.add(point);
  };
  addArcToPoly = (through: paper.Point, to: paper.Point) => {
    this.polyPath?.arcTo(through, to);
  };
  createCloseLine = () => {
    const firstVertex = this._vertices[0];
    const lastVertex = this._vertices[this._vertices.length - 1];
    this.polyClosePath?.add(new paper.Point(firstVertex.position));
    this.polyClosePath?.add(new paper.Point(lastVertex.position));
  };
  cleanupPoly = () => {
    if (this.polyPath) {
      this.polyPath.remove();
      this.polyPath = null;
    }
  };
  cleanupHint = () => {
    if (this.polyHintPath) {
      this.polyHintPath.remove();
      this.polyHintPath = null;
    }
  };
  addVertex = (vertex: Vertex, undoable = true) => {
    if (undoable) {
      addUndo(() => {
        this.deleteVertex(vertex.uuid, false);
      }, 'add vertex');
    }
    this._vertices.push(vertex);
    this._vertices$.next(this._vertices);
  };
  deleteVertex = (id: string, undoable = true) => {
    const index = this._vertices.findIndex((v) => v.uuid === id);
    if (undoable) {
      addUndo(() => {
        this.addVertex(this._vertices[index], false);
      }, 'delete vertex');
    }
    if (index !== -1) {
      this._vertices.splice(index, 1);
      this._vertices$.next(this._vertices);
    }
  };
  clearVertices = () => {
    this._vertices.length = 0;
    this._vertices$.next(this._vertices);
  };
  setThruPoint = (p: { x: number; y: number; override?: boolean }) => {
    this._thruPoint = p;
    this._thruPoint$.next(this._thruPoint);
  };
  clearThruPoint = () => {
    this._thruPoint = null;
    this._thruPoint$.next(this._thruPoint);
  };
  setArcMode = (arc: boolean) => {
    this._arc = arc;
    this._arcMode$.next(this._arc);
    this.setOverrideMode(true);
    this.clearThruPoint();
  };
  toggleArcMode = () => {
    this.setArcMode(!this._arc);
    if (this._arc) {
      this.setNextLength(0);
    }
  };
  setOverrideMode = (mode: boolean) => {
    this._overrideMode = mode;
    this._overrideMode$.next(this._overrideMode);
  };
  setNextLength = (length: number) => {
    this._nextLength = length;
    this._nextLength$.next(this._nextLength);
  };
  finalize = (close = false) => {
    if (!this._polyline) {
      this._polyline = {
        ...polylineService.createItem(),
      };
    }
    this._polyline.vertices = [...this._vertices];
    this._polyline.closed = close;
    if (this._polyline.vertices.length > 1) {
      addItem(this._polyline);
    }
    this.setNextLength(0);
    this.clearThruPoint();
    this.clearVertices();
    this.cleanupHint();
    this.cleanupPoly();
    this.setArcMode(false);
    this._polyline = {
      ...polylineService.createItem(),
    };
  };
  end = () => {
    // stop drawing and don't add
    this.clearThruPoint();
    this.clearVertices();
    this.cleanupPoly();
    this.cleanupHint();
  };
  setClosePath = (close: boolean) => {
    this._close = close;
    this._close$.next(this._close);
  };
  toggleSnapMode = () => {
    this._snap = !this._snap;
    this._snapMode$.next(this._snap);
  };
  setClosestVertexPosition = (position: IPoint | null) => {
    this.closestVertexPosition = position;
  };
  toggleOverrideMode = () => {
    this.setOverrideMode(!this._overrideMode);
  };
  hydrate = (polyline: Polyline) => {
    this.setClosePath(polyline.closed);
    this._polyline = polyline;
    this._vertices = polyline.vertices;
    this._vertices$.next(this._vertices);
    this.resetPoly();
    this._vertices.forEach((v) => {
      if (v.through) {
        this.addArcToPoly(
          new paper.Point(v.through),
          new paper.Point(v.position),
        );
      } else {
        this.addPointToPoly(new paper.Point(v.position));
      }
    });
  };
}

export const drawPolyService = new DrawPolyService();
