import { base } from 'src/styles/common';
import styled from 'styled-components';

import { Dropdown, Table } from 'semantic-ui-react';
import { Filter, ProjectStatus } from '../../../../shared-types/portal-types';

const statusOptions: { text: ProjectStatus; value: ProjectStatus }[] = [
  { text: 'Draft', value: 'Draft' },
  { text: 'Submitted', value: 'Submitted' },
  { text: 'Accepted', value: 'Accepted' },
  { text: 'Basemap Complete', value: 'Basemap Complete' },
  { text: 'Design Complete', value: 'Design Complete' },
];
interface Props {
  filters: Filter[];
  onFilterChange: (value: any) => void;
}
export const ProjectFilters = ({ filters, onFilterChange }: Props) => {
  return (
    <Wrap>
      <Table basic="very" celled={true} collapsing={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Filters</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Status</Table.Cell>
            <Table.Cell>
              <Dropdown
                clearable
                options={statusOptions}
                placeholder="select"
                value={filters.length ? filters[0]['status'] : undefined}
                onChange={(_, e) => onFilterChange(e.value)}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Wrap>
  );
};
const Wrap = styled.div`
  margin: ${base}px ${base * 2}px;
`;
