import { useEffect, useState } from 'react';
import { Button, Checkbox, DropdownProps } from 'semantic-ui-react';
import { Polyline, PolylineType } from '../../../../../shared-types';
import { getItemByUUID, isPolyline, paperItemStore } from '../helpers';
import { drawPolyService } from '../polyline/draw-poly.service';
import { isPaperPolyLine } from '../polyline/paper-polyline';
import { getState, setActiveTool, setStrokeWidth } from '../state';
import { deleteItem, updateItem } from '../state/item-state';
import {
  PanelDivider,
  PanelLabel,
  PanelSelect,
  PanelWrap,
} from './PanelButton';

interface Props {
  item: Polyline;
}
export const PolylineActions = ({ item }: Props) => {
  const [toggleBisects, setToggleBisects] = useState(false);
  const [localItem, setLocalItem] = useState<Polyline>(item);

  useEffect(() => {
    setLocalItem(item);
  }, [item]);

  const changePolyType = (_e, data: DropdownProps) => {
    const currentPoly = getState().items.find((i) => i.uuid === localItem.uuid);
    if (currentPoly && isPolyline(currentPoly) && currentPoly.closed) {
      const d = data.value as PolylineType;
      const updatedPoly: Polyline = {
        ...currentPoly,
        polyType: d,
        visible: true,
      };
      setLocalItem(updatedPoly);
      updateItem(updatedPoly);
    }
  };
  const setPrintability = (_e, data: any) => {
    const currenItem = getItemByUUID(localItem.uuid, isPolyline);
    if (currenItem) {
      const updated: Polyline = {
        ...currenItem,
        visible: data.checked,
      };
      setLocalItem(updated);
      updateItem(updated);
    }
  };

  const setLocked = (_e, data: any) => {
    const currenItem = getItemByUUID(localItem.uuid, isPolyline);
    if (currenItem) {
      const updated: Polyline = {
        ...currenItem,
        locked: data.checked,
      };
      setLocalItem(updated);
      updateItem(updated);
    }
  };
  const closePoly = () => {
    const updatedPoly = {
      ...localItem,
      closed: true,
    };
    setLocalItem(updatedPoly);
    updateItem(updatedPoly);
  };
  const openPoly = () => {
    const updatedPoly = {
      ...localItem,
      closed: false,
    };
    setLocalItem(updatedPoly);
    updateItem(updatedPoly);
  };
  const extendPolyline = () => {
    deleteItem(localItem.uuid);
    setStrokeWidth(localItem.style.strokeWidth || 1);
    drawPolyService.hydrate(localItem);
    setActiveTool('polyline');
  };

  useEffect(() => {
    const paperPolyline = paperItemStore.get(localItem.uuid);
    if (paperPolyline && isPaperPolyLine(paperPolyline)) {
      paperPolyline.showAllBisects(toggleBisects);
    }
  }, [localItem.uuid, toggleBisects]);

  return (
    <PanelWrap>
      <PanelLabel>Polyline Actions</PanelLabel>
      <PanelSelect
        options={[
          { text: 'Tracing', value: 'tracing' },
          { text: 'Bed', value: 'bed', disabled: !localItem.closed },
          { text: 'Yard', value: 'yard', disabled: !localItem.closed },
        ]}
        value={localItem.polyType}
        onChange={changePolyType}
        label="Polyline Type"
      />
      <Checkbox
        label="Close Path"
        checked={localItem.closed}
        onChange={localItem.closed ? openPoly : closePoly}
      />
      <PanelDivider />
      <Checkbox
        label="Printable"
        checked={localItem.visible}
        onChange={setPrintability}
      />
      <Checkbox
        label="Lock Position"
        checked={localItem.locked}
        onChange={setLocked}
      />
      <Checkbox
        label="Show Bisect Points (b)"
        checked={toggleBisects}
        onChange={() => setToggleBisects(!toggleBisects)}
      />
      {localItem.vertices.length} vertices
      <Button onClick={extendPolyline}>Extend Polyline</Button>
    </PanelWrap>
  );
};
