import { IPoint, ISleeve, LineSegment } from '@shared-types';
import { map, merge, switchMap, takeUntil, tap } from 'rxjs';
import paper from 'src/paper';
import { polyHintPurple } from '../../../../shared';
import { getColor } from '../../paper-helpers/plot.helpers';
import { getState } from '../../state';
import { toolService } from '../../tool.service';
import { getLateralPipeProduct } from '../pipes/Pipes';
import { sleeveService } from './sleeve.service';
import { getSleeveLines } from './sleeves.paper';
import { addSleeve } from './state/addSleeves';

const { mouseDown$, mouseUp$, mouseDrag$ } = toolService.getEvents('Sleeve');

export const drawSleeve$ = mouseDown$.pipe(
  map(({ e }) => {
    const start: IPoint = { x: e.point.x, y: e.point.y };
    const line = new paper.Path();
    let end = { x: e.point.x, y: e.point.y };
    const scale = getState().scale;
    return { start, line, end, scale };
  }),
  switchMap(({ start, line, end, scale }) =>
    mouseDrag$.pipe(
      tap((e) => {
        end = { x: e.point.x, y: e.point.y };
        line.remove();
        line = new paper.Path([start, e.point]);
        line.strokeWidth = 2 / scale;
        line.opacity = 0.5;
        line.strokeColor = getColor(polyHintPurple);
      }),
      takeUntil(
        merge(
          mouseUp$.pipe(
            tap((e) => {
              const sleeve = createSleeveOnMouseUp(start, end);
              addSleeve(sleeve);
            }),
          ),
        ).pipe(
          tap(() => {
            line.remove();
          }),
        ),
      ),
    ),
  ),
);

const createSleeveOnMouseUp = (start: IPoint, end: IPoint): ISleeve => {
  const { lateralPipe } = getState();
  const pipe = getLateralPipeProduct();
  if (!pipe || !lateralPipe) throw new Error('No pipe found');
  const ls: LineSegment = {
    start,
    end,
  };
  const sleeve = sleeveService.createItem({
    position: start,
    pipe: {
      ...ls,
      pipe: pipe.uuid,
    },
    width: pipe.size,
    diameter: 2,
    lines: getSleeveLines(ls, 1),
    user: true,
  });
  return sleeve;
};
