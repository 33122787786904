import { DesignElement, IEdge } from '@shared-types';
import { mapEls } from '../caches/element.cache';
import { addUndo } from '../contexts/design/undo';
import { generateGraphs } from '../helpers/directedGraph';
import { hopPipes } from '../helpers/hopping';
import { createEdge, renderEdges } from '../paper-helpers/edges';
import { renderElements } from '../paper-helpers/plot.helpers';
import { updateDotOwnership } from './coverage';
import { deleteEdge } from './deleteEdge';
import { deleteElements } from './deleteElements';
import { getState, setState } from './state-basics';

export const addElementsEdges = (
  newEls: DesignElement[],
  incomingEdges: IEdge[],
  undoable = true,
) => {
  const { mainPipe, pipeProducts, mainPipeSizes, zones, scale } = getState();
  if (undoable) {
    addUndo(() => {
      const ids = newEls.map((el) => el.uuid);
      deleteElements(ids, false);
      incomingEdges.forEach((edge) => {
        deleteEdge(edge.uuid, false);
      });
    }, 'add elements and edges');
  }
  let newEdges = incomingEdges.map((edge) =>
    createEdge(edge, pipeProducts, mainPipe, mainPipeSizes),
  );

  const newElements = [...getState().elements, ...newEls];
  const elementCache = mapEls(newElements);
  updateDotOwnership(newEls, elementCache);
  newEdges = [...getState().edges, ...newEdges];
  const { masterGraph, pocGraphs } = generateGraphs(
    newEdges,
    newElements,
    zones,
  );
  newEdges = hopPipes(
    zones,
    newEdges,
    newElements,
    elementCache,
    scale,
    pocGraphs,
  );
  setState({
    elements: newElements,
    edges: newEdges,
    masterGraph,
    pocGraphs,
    elementCache,
  });
  renderElements(newEls);
  renderEdges(
    newEdges.filter((e) => incomingEdges.find((i) => i.uuid === e.uuid)),
  );
};
