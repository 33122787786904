import { Modal } from 'semantic-ui-react';
import { modalDefaults } from '../../state/modal.service';
import { StatsList } from './StatsList';

export const StatsModalType = 'statsModal';

export const StatsModal = ({ closeModal }) => {
  return (
    <Modal
      {...modalDefaults}
      style={{ maxHeight: '70vh' }}
      header="Stats"
      actions={[
        {
          key: 'done',
          content: 'Done',
          primary: true,
        },
      ]}
      onActionClick={closeModal}
      content={
        <Modal.Content>
          <StatsList />
        </Modal.Content>
      }
    />
  );
};
