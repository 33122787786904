import {
  BaseItem,
  Basemap,
  Circle,
  DesignElement,
  IEdge,
  IPlant,
  IPoint,
  Polyline,
  Ruler,
  TextChunk,
} from '@shared-types';

export const paperStore: {
  [uuid: string]: PaperItem<
    | IEdge
    | DesignElement
    | IPlant
    | Circle
    | Basemap
    | Ruler
    | Polyline
    | TextChunk
  >;
} = {};

export abstract class PaperItem<T extends BaseItem> {
  abstract destroy(): void;
  abstract update(item: T): void;
  abstract getItem(): paper.Item;
  abstract setPivot(point: IPoint): void;
  abstract setPosition(point: IPoint): void;
  abstract toggleHighlight(show: boolean): void;
}
