import {
  DesignElement,
  NozzleData,
  RadiusWithAngleLimit,
  SprinklerBase,
  SprinklerShell,
} from '@shared-types';
import { defaultItem } from '../../helpers';
import { getGeneratePerfData } from '../../helpers/nozzleTransformer';
import { Dot } from '../../state/coverage';
import {
  createSprinklerFromRaw,
  generateRadiusAngleLimits,
  getRadiiFromLimits,
} from '../../tools/sprinkler';

export const getAvailableRadii = (sprinklerBases: SprinklerBase[]) => {
  const nozzleData = sprinklerBases.reduce(
    (acc: NozzleData[], base: SprinklerBase) => [
      ...acc,
      ...getGeneratePerfData(base),
    ],
    [],
  );
  const limits: RadiusWithAngleLimit[] = generateRadiusAngleLimits(nozzleData);
  const limits360 = limits.filter((l) => l.angles.indexOf(360) > -1);
  const radii: number[] = getRadiiFromLimits(limits360, 199);
  return radii;
};

export const getBestHead = (
  dot: Dot,
  sprinklerBases: SprinklerBase[],
): DesignElement | undefined => {
  const radii = getAvailableRadii(sprinklerBases);
  const filteredRadii = radii.filter((r) => r <= dot.distFromEdge);
  console.log(filteredRadii);
  for (let radius of filteredRadii) {
    const shell: SprinklerShell = {
      ...dot.position,
      angle: 360,
      rotation: 0,
      radius: radius,
    };
    const s = createSprinklerFromRaw(shell, sprinklerBases);
    if (!s) return undefined;
    let newEl: DesignElement = {
      ...defaultItem({ x: dot.position.x, y: dot.position.y }),
      type: 'sprinkler',
      itemType: 'design-element',
      props: s,
    };

    return newEl;
  }
  return undefined;
};

// const getDryPoints = (sprinklerBases: SprinklerBase[]) => {
//   const radii = getAvailableRadii(sprinklerBases);
//   let dryPoints = Object.keys(dotCache)
//     .map((d) => dotCache[d])
//     .filter(
//       (d) =>
//         d.score > algoParams.minScore &&
//         d.score < algoParams.maxScore &&
//         d.distFromEdge >= Math.min(...radii),
//     );
//   dryPoints.sort((a, b) => a.score - b.score);
//   // dryPoints.sort((a, b) => b.distFromEdge - a.distFromEdge)
//   console.log(JSON.stringify(dryPoints.slice(0, 5).map((d) => d.score)));
//   return dryPoints;
// };

// export const fillDrySpots = (sprinklerBases: SprinklerBase[]) => {
//   let maxHeads = 0;

//   while (maxHeads < 10) {
//     maxHeads += 1;
//     let dryPoints = getDryPoints(sprinklerBases);
//     if (dryPoints.length === 0) {
//       break;
//     }
//     const dot = dryPoints[0];
//     console.log('dot score', dot.score, dot.uuid);
//     const newElement = getBestHead(dot, sprinklerBases);
//     if (newElement) {
//       addElements([newElement]);
//     }
//   }
// };
