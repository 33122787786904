import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, Divider, Input, Modal } from 'semantic-ui-react';
import { PanelButton } from '../../components/PanelButton';
import {
  addStaticLossItem,
  deleteStaticLossItem,
  editStaticLossItemDesc,
  editStaticLossItemValue,
  useDesignStore,
} from '../../state';

export const ZoneStaticLosses = () => {
  const currentZone = useDesignStore((state) =>
    state.zones.find((zone) => zone.uuid === state.currentZone),
  );
  const [open, setOpen] = useState(false);

  const changeLossItemValue = (
    i: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (currentZone) {
      editStaticLossItemValue(
        currentZone.uuid,
        i,
        parseFloat(e.currentTarget.value),
      );
    }
  };
  const changeLossItemDesc = (
    i: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (currentZone) {
      editStaticLossItemDesc(currentZone.uuid, i, e.target.value);
    }
  };
  const deleteLossItem = (i: number) => {
    if (currentZone) {
      deleteStaticLossItem(currentZone.uuid, i);
    }
  };
  return (
    <Modal
      size="mini"
      open={open}
      trigger={
        <PanelButton onClick={() => setOpen(true)}>
          Static Loss Items ({currentZone?.staticLossItems?.length || 0})
        </PanelButton>
      }
    >
      <Modal.Header>Static Loss Items</Modal.Header>
      <Modal.Content>
        These items are just static losses in the system for a zone that don't
        change.
        <Divider />
        {currentZone && (
          <div>
            {currentZone.staticLossItems &&
            currentZone.staticLossItems.length > 0 ? (
              <label>Static Loss Item(s)</label>
            ) : (
              <></>
            )}
            {(currentZone.staticLossItems || []).map((lossItem, i) => (
              <div className="loss-item" key={i}>
                <div>
                  <label>Description</label>
                  <Input
                    name="Label"
                    value={lossItem.description}
                    onChange={changeLossItemDesc.bind(null, i)}
                  />
                </div>
                <div>
                  <label>Loss</label>
                  <NumericFormat
                    name="lossItemChange"
                    value={lossItem.loss || 0}
                    onBlur={changeLossItemValue.bind(null, i)}
                  />
                </div>
                <Button
                  size="mini"
                  type="button"
                  onClick={deleteLossItem.bind(null, i)}
                >
                  delete
                </Button>
              </div>
            ))}
            <Button
              size="mini"
              type="button"
              onClick={() => addStaticLossItem(currentZone.uuid)}
            >
              Add static loss item
            </Button>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => setOpen(false)}>
          Done
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
