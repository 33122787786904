import { IPlant, PlantBase, Zone } from '@shared-types';
import { contains, uniq } from 'underscore';
import { addUndo } from '../contexts/design/undo';
import { getItemByUUID, paperItemStore } from '../helpers';
import { emitSingleHook } from '../polyline/item.model';
import { getState, setState } from '../state/state-basics';
import { isPaperPlant } from './paper-plant';
import { isPlant } from './plants';

export const addPlant = (payload: IPlant, undoable = true) => {
  if (undoable) {
    addUndo(() => {
      deletePlant(payload.uuid, false);
    });
  }
  const currentZone = getState().currentZone;
  let zones = getState().zones;
  if (currentZone) {
    zones.forEach((z) => {
      if (z.uuid === currentZone && z.isDrip) {
        z.plantIds.push(payload.uuid);
      }
    });
  }
  setState({
    items: [...getState().items, payload],
    zones,
  });
  emitSingleHook('postAddItem', payload);
};
export const deletePlant = (payload: string, undoable = true) => {
  const oldPlant = getItemByUUID(payload, isPlant);
  if (undoable) {
    const zone = getState().zones.find((z) => z.plantIds.includes(payload));
    addUndo(() => {
      if (oldPlant) {
        addPlant(oldPlant, false);
        if (zone) {
          addPlantsToZone(zone.uuid, [oldPlant.uuid], false);
        }
      }
    });
  }
  setState({
    items: getState().items.filter((item) => item.uuid !== payload),
    zones: getState().zones.map((zone: Zone) =>
      contains(zone.plantIds, payload)
        ? {
            ...zone,
            plantIds: zone.plantIds.filter((v: string) => v !== payload),
          }
        : zone,
    ),
  });
  emitSingleHook('postDeleteItem', oldPlant);
};
export const addPlantsToZone = (
  zoneID: string,
  plantIDs: string[],
  undoable = true,
) => {
  if (undoable) {
    const oldZones = getState().zones.filter((z) =>
      z.plantIds.some((p) => contains(plantIDs, p)),
    );
    addUndo(() => {
      unzonePlants(plantIDs, false);
      oldZones.forEach((z) => {
        addPlantsToZone(z.uuid, z.plantIds, false);
      });
    });
  }
  const zone = getState().zones.find((z) => z.uuid === zoneID);
  const newZones = getState().zones.map((z) => ({
    ...z,
    plantIds:
      z.uuid === zoneID
        ? uniq([...z.plantIds, ...plantIDs])
        : z.plantIds.filter((h) => !contains(plantIDs, h)),
  }));
  updatePlantColors(plantIDs, zone);
  setState({
    zones: newZones,
  });
};
export const unzonePlants = (plantIDs: string[], undoable = true) => {
  if (undoable) {
    const oldZones = getState().zones.filter((z) =>
      z.plantIds.some((p) => contains(plantIDs, p)),
    );
    addUndo(() => {
      oldZones.forEach((z) => {
        addPlantsToZone(z.uuid, z.plantIds, false);
      });
    });
  }
  const newZones = getState().zones.map((z) => ({
    ...z,
    plantIds: z.plantIds.filter((h) => !contains(plantIDs, h)),
  }));
  updatePlantColors(plantIDs);
  setState({
    zones: newZones,
  });
};
export const setPlants = (payload: IPlant[]) => {
  setState({
    items: [...getState().items, ...payload],
    zones: getState().zones.map((zone) => ({
      ...zone,
      plantIds: [],
    })),
  });
};
export const setActivePlant = (plantBase: PlantBase) => {
  setState({ activePlant: plantBase });
};

const updatePlantColors = (plantIDs: string[], zone?: Zone) => {
  plantIDs.forEach((p) => {
    const paperPlant = paperItemStore.get(p);
    if (paperPlant && isPaperPlant(paperPlant)) {
      paperPlant.updateColor(zone?.color);
    }
  });
};
