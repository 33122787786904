import { filter, map, merge, switchMap, takeUntil, tap } from 'rxjs';
import paper from 'src/paper';

import {
  activateNamedLayer,
  getColor,
  showNamedLayer,
} from '../paper-helpers/plot.helpers';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { getState, setActiveTool } from '../state';
import { toolService } from '../tool.service';
import { noModifiers } from '../tools/arrow-new';
import { sheetService } from './sheet.service';

const { mouseDown$, mouseMove$ } = toolService.getEvents('sheet');

const sheetMove$ = toolService.activeTool$.pipe(
  filter((tool) => tool === 'sheet'),
  map(() => {
    showNamedLayer(LAYER_NAMES.SHEETS);
    activateNamedLayer(LAYER_NAMES.DEFAULT);
    const { scale } = getState();
    const width = (31 * 72) / scale;
    const height = (23 * 72) / scale;
    const r = new paper.Rectangle(
      new paper.Point(0, 0),
      new paper.Size(width, height),
    );
    const g = new paper.Path.Rectangle(r);
    g.fillColor = getColor('blue');
    g.opacity = 0.2;
    return { g };
  }),
  switchMap(({ g }) =>
    mouseMove$.pipe(
      tap((e) => {
        g.position = e.point;
      }),
      takeUntil(
        merge(
          toolService.activeTool$.pipe(
            filter((tool) => tool !== 'sheet'),
            tap(() => {
              g.remove();
            }),
          ),
          toolService.escapeKey$.pipe(tap(() => setActiveTool('Arrow'))),
        ),
      ),
    ),
  ),
);
const clickAddSheet$ = mouseDown$.pipe(
  filter(({ e }) => noModifiers(e)),
  tap(({ e }) => {
    const { scale } = getState();
    const width = (31 * 72) / scale;
    const height = (23 * 72) / scale;
    const position = {
      x: e.point.x - width / 2,
      y: e.point.y - height / 2,
    };
    sheetService.addSheet(position);
  }),
);
export const arrowSheet$ = merge(sheetMove$, clickAddSheet$);
