import { styled } from 'styled-components';
import { CustomSymbolType } from '../../../../../shared-types';
import { customSymbols } from '../custom-symbol/customSymbols';
import { setActiveCustomSymbol, useDesignStore } from '../state';
import { PanelLabel, PanelWrap } from './PanelButton';

export const DrawCustomSymbolProperties = () => {
  const activeCustomSymbol = useDesignStore(
    (state) => state.activeCustomSymbol,
  );
  return (
    <Wrap>
      <PanelWrap>
        <div>
          <PanelLabel>Drawing Custom Symbol</PanelLabel>
          <div className="symbols">
            {Object.entries(customSymbols).map(
              ([key, { name, svgString }], i) => (
                <div
                  onClick={() => setActiveCustomSymbol(key as CustomSymbolType)}
                  className={`symbol-row ${
                    key === activeCustomSymbol ? 'active' : ''
                  }`}
                  key={i}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: svgString,
                    }}
                  />
                  {name}
                </div>
              ),
            )}
          </div>
        </div>
      </PanelWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  .symbols {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 8px;
    .symbol-row {
      padding: 8px;
      border: 1px solid #333;
      border-radius: 4px;
      user-select: none;
      cursor: pointer;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #333;
      background: #999;
      &.active {
        border-color: #fff;
      }
      svg {
        display: block;
        width: 60px;
        height: 60px;
      }
    }
  }
`;
