import React from 'react';
import { DesignElement } from '../../../../../../shared-types';
import { paperItemStore } from '../../helpers';
import { localPaper } from '../../localPaper';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import {
  clearSelectedIDs,
  clearSelectedItems,
  selectItems,
  useDesignStore,
} from '../../state';
import { changeElements } from '../../state/changeElements';
import { deleteElements } from '../../state/deleteElements';
import { hideOutOfBounds } from '../../tools/pan.tool';
import { isPaperElement } from '../../tools/paper-items/paper-element';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';
import { LayerChild } from './LayerChild';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const ElementsLayer = ({ open, onOpen }) => {
  const elements = useDesignStore((state) =>
    state.elements.filter((el) => !isSprinkler(el)),
  );
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.ELEMENTS,
  );

  const toggleChildVisibility = (el: DesignElement) => {
    changeElements([{ ...el, visible: !el.visible }]);
  };

  const toggleChildLock = (el: DesignElement) => {
    changeElements([{ ...el, locked: !el.locked }]);
  };
  const clickEl = (el: DesignElement) => {
    clearSelectedIDs();
    clearSelectedItems();
    localPaper.project.deselectAll();
    const item = paperItemStore.get(el.uuid);
    if (item) {
      if (isPaperElement(item)) {
        selectItems([item.paperItem]);
        item.paperItem.selected = true;
        localPaper.project.view.center = item.paperItem.position;
        hideOutOfBounds();
      }
    }
  };

  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Elements (${elements.length})`}
      open={open}
      onOpen={onOpen}
      childLayer={elements.map((el) => (
        <LayerChild
          key={el.uuid}
          visible={el.visible}
          locked={el.locked}
          title={`${el.type}`}
          clickToggle={() => toggleChildVisibility(el)}
          clickLock={() => toggleChildLock(el)}
          clickItem={() => clickEl(el)}
          clickDelete={() => deleteElements([el.uuid])}
          layerLocked={layerLocked}
          layerVisible={layerVisible}
        />
      ))}
    />
  );
};

export default React.memo(ElementsLayer);
