import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { Button, Header, Icon } from 'semantic-ui-react';
import { base } from 'src/styles/common';
import styled from 'styled-components';

export const Signup = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const signup = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  };
  const login = () => {
    loginWithRedirect();
  };

  return (
    <Wrap>
      {!isAuthenticated && (
        <div className="signup">
          <Header as="h2">Irrigation Studio Contractor Portal</Header>
          <p>
            Setup a free account at Irrigation Studio in order to manage your
            irrigation design projects
          </p>
          <Button primary={true} onClick={signup}>
            Signup free
          </Button>
          <Button onClick={login}>Login</Button>
          <br />
          <br />
          <br />
          <div>
            <Header as="h4">In the portal you can...</Header>
            <div>* Submit and manage design requests</div>
            <div>* View and pay invoices easily online</div>
            <div>* Setup bulk contracts for discount pricing</div>
          </div>
        </div>
      )}
      {isAuthenticated && (
        <div className="signup">
          <Icon name="check" size="huge" color="green" />
          <Header as="h4">Looks like you're already logged in!</Header>
          <p>
            Head to your <Link to="/app/dashboard">Dashboard</Link> to see your
            projects
          </p>
        </div>
      )}
    </Wrap>
  );
};
const Wrap = styled.div`
  padding: ${base * 2}px;
  .signup {
    text-align: center;
    padding: 100px 0;
  }
`;
