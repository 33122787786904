import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';
import { ProfileContext } from 'src/contexts/profile/profile';
import styled from 'styled-components';
import { contains } from 'underscore';
import logo from './../images/logo-2-clear.png';

export const DrawerMenuComponent = () => {
  const location = useLocation();
  const { role, user } = useContext(ProfileContext);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const clickLogin = (e) => {
    e.preventDefault();
    loginWithRedirect();
  };
  const clickSignup = (e) => {
    e.preventDefault();
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  };
  const showMenu = () => {
    return (
      location.pathname.indexOf('/app/workbench') !== 0 &&
      location.pathname !== '/'
    );
  };
  return (
    <>
      {showMenu() && (
        <Wrap className="drawer-menu">
          {isAuthenticated && (
            <div className="top-nav">
              <Menu>
                <Menu.Item
                  disabled
                  as={NavLink}
                  to="/app/dashboard"
                  name="Dashboard"
                >
                  <img className="logo" src={logo} alt="logo" />
                </Menu.Item>
                {isAuthenticated && (
                  <>
                    <Menu.Item name="Home" as={NavLink} to="/app/dashboard" />
                    {contains(
                      ['admin', 'designer', 'basemapper', 'readonly'],
                      role,
                    ) && (
                      <Menu.Item
                        name="Projects"
                        as={NavLink}
                        to="/app/projects"
                      />
                    )}
                  </>
                )}
                <Menu.Menu position="right" className="right-menu">
                  {!!user && (
                    <Dropdown
                      text={user.email?.charAt(0).toUpperCase() || 'U'}
                      className="user-dropdown"
                      icon={null}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Header>{user.email}</Dropdown.Header>
                        {/* <Dropdown.Item as={NavLink} to="settings">
                    Settings
                  </Dropdown.Item>  */}

                        <Dropdown.Item name="Logout" onClick={() => logout()}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {!user && (
                    <>
                      <Menu.Item name="Signup" onClick={clickSignup} />
                      <Menu.Item name="Login" onClick={clickLogin} />
                    </>
                  )}
                </Menu.Menu>
              </Menu>
            </div>
          )}
        </Wrap>
      )}
      <Outlet />
    </>
  );
};

const Wrap = styled.div`
  .top-nav {
    margin: 8px;
  }
  .user-dropdown {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1rem;
    margin-right: 4px;
    background: #efefef;
    border-radius: 50%;
    &:hover {
      color: #999;
      color: #333;
    }
    i {
      margin: 0;
    }
  }
  .right-menu {
    display: flex;
    align-items: center;
  }
`;
