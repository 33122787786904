export const irrigationBlue = '#01aef0';
export const paperColors = {
  grid: '#cccccc',
  water: '#6198C8',
  pipe: '#000000',
  pipeTmp: '#ccc',
  headTmp: 'red',
  head: '#999999',
  valveTmp: 'black',
  valve: '#999999',
  outline: '#555555',
  vertex: 'black',
  tmpArc: 'blue',
  intersection: 'red',
};

export const usefulGradients = [
  '#FFFAE8',
  '#FFF6D1',
  '#FFF1B9',
  '#FFECA2',
  '#FFE88B',

  '#FFE374',
  '#FFDF5D',
  '#FFDA46',
  '#339900',
  '#339900',

  '#339900',
  '#339900',
  '#339900',
  '#339900',
  '#4F7DD1',

  '#5D6FB9',
  '#6B61A2',
  '#79538B',
  '#864674',
  '#94385D',

  '#A22A46',
  '#B01C2E',
  '#BE0E17',
];
/*

  '#1ABF00', // 10
  'yellow', // 11
  'orange', // 12
  'red', // 13
  'purple', // 14
  'blue', // 15
  'brown',
  'black',
  */

export const gradientColors = [
  '#FFFFFF',
  '#FFFFFF',
  '#DBE0DA',
  '#DBE0DA',
  '#B7C1B6',
  '#B7C1B6',
  '#94A392',
  '#94A392',
  '#4CCD38',
  '#4CCD38',
  '#1ABF00',
  '#1ABF00',
  '#28B403',
  '#28B403',
  '#36A906',
  '#36A906',
  '#449E09',
  '#449E09',
  '#52930C',
  '#52930C',
  '#61880F',
  '#61880F',
  '#6F7D13',
  '#6F7D13',
  '#7D7216',
  '#7D7216',
  '#8B6719',
  '#8B6719',
  '#995C1C',
  '#995C1C',
  '#A8521F',
  '#A8521F',
  '#B64722',
  '#B64722',
  '#C43C26',
  '#C43C26',
  '#D23129',
  '#D23129',
  '#E0262C',
  '#E0262C',
  '#E8212E',
  '#E8212E',
];

export const gradientColorsOriginal = [
  '#FFFFFF',
  '#EDEFEC',
  '#DBE0DA',
  '#C9D1C8',
  '#B7C1B6',
  '#A6B2A4',
  '#94A392',
  '#829380',
  '#4CCD38',
  '#33C61C',
  '#1ABF00',
  '#21B901',
  '#28B403',
  '#2FAE04',
  '#36A906',
  '#3DA307',
  '#449E09',
  '#4B980B',
  '#52930C',
  '#598D0E',
  '#61880F',
  '#688311',
  '#6F7D13',
  '#767814',
  '#7D7216',
  '#846D17',
  '#8B6719',
  '#92621A',
  '#995C1C',
  '#A0571E',
  '#A8521F',
  '#AF4C21',
  '#B64722',
  '#BD4124',
  '#C43C26',
  '#CB3627',
  '#D23129',
  '#D92B2A',
  '#E0262C',
  '#E8212E',
  '#C5111D',
];

export const zoneColors = [
  '#1CE6FF',
  '#FF34FF',
  '#FF4A46',
  '#008941',
  '#006FA6',
  '#7A4900',
  '#0000A6',
  '#B79762',
  '#004D43',
  '#8FB0FF',
  '#997D87',
  '#5A0007',
  '#809693',
  '#1B4400',
  '#4FC601',
  '#3B5DFF',
  '#4A3B53',
  '#FF2F80',
  '#61615A',
  '#BA0900',
  '#6B7900',
  '#00C2A0',
  '#FFAA92',
  '#FF90C9',
  '#B903AA',
  '#D16100',
  '#000035',
  '#7B4F4B',
  '#A1C299',
  '#300018',
  '#0AA6D8',
  '#013349',
  '#00846F',
  '#372101',
  '#FFB500',
  '#A079BF',
  '#CC0744',
  '#C0B9B2',
  '#001E09',
  '#00489C',
  '#6F0062',
  '#0CBD66',
  '#EEC3FF',
  '#456D75',
  '#B77B68',
  '#7A87A1',
  '#788D66',
  '#885578',
  '#FAD09F',
  '#FF8A9A',
  '#D157A0',
  '#BEC459',
  '#456648',
  '#0086ED',
  '#886F4C',
  '#34362D',
  '#B4A8BD',
  '#00A6AA',
  '#452C2C',
  '#c77557',
  '#a14fc4',
  '#936ecf',
  '#38a878',
  '#5238a8',
  '#326c95',
  '#9dcd6a',
  '#c6535b',
  '#a84f38',
  '#75d182',
  '#a538a8',
  '#d9b98c',
  '#c95e6d',
  '#66ccc0',
  '#a3d47d',
  '#94cf6e',
  '#79cd6a',
  '#9fcd6a',
  '#3b64b0',
  '#d98c98',
  '#915e30',
  '#c14452',
  '#a86538',
  '#3bb07f',
  '#d9998c',
  '#37a49f',
  '#40aabf',
  '#d581b3',
  '#37a136',
  '#57c791',
  '#cd6ab3',
  '#4469c1',
  '#c85b7a',
  '#8fd685',
  '#37a4a1',
  '#d279d1',
  '#3db86e',
  '#a9d279',
  '#d0c071',
  '#d9cb8c',
  '#845ec9',
  '#d98cc2',
  '#9d7f34',
  '#ccc966',
  '#7e2f8e',
  '#995533',
  '#707a29',
  '#70349d',
  '#873bb0',
  '#86682d',
  '#7d8ed4',
  '#8bb03b',
  '#69c144',
  '#4086bf',
  '#56a838',
  '#4752c2',
  '#cf7c6e',
  '#642d86',
  '#7d84d4',
  '#8e442f',
  '#8ea838',
  '#93c85b',
  '#a771d0',
  '#6697cc',
  '#d7d288',
  '#c79c57',
  '#c1446b',
  '#c44f57',
  '#799933',
  '#913930',
  '#4fc4b5',
  '#4c7e2a',
  '#2e8a36',
  '#47c2c0',
  '#5bc6c8',
  '#7a3929',
  '#3bb071',
  '#cfd98c',
  '#af53c6',
  '#53c677',
  '#3737a4',
  '#448cc1',
  '#52c144',
  '#86492d',
  '#7e2a7b',
  '#2a7e48',
  '#c37b4b',
  '#8cd9b4',
  '#26735b',
  '#793ebb',
  '#b86ecf',
  '#4b4fc3',
  '#7bd071',
  '#329563',
  '#8a812e',
  '#44c1ae',
  '#695bc8',
  '#2f348e',
  '#37a44d',
  '#bec757',
  '#8b6acd',
  '#4f5fc4',
  '#d07c71',
  '#3ca8b4',
  '#5f9933',
  '#d1d279',
  '#bb4bc3',
  '#bf406c',
  '#ac5639',
  '#79c34b',
  '#339972',
  '#3e7728',
  '#7aac39',
  '#8a2e6e',
  '#5ec980',
  '#62cb89',
  '#713091',
  '#c757b2',
  '#35297a',
  '#2a5f7e',
  '#4756c2',
  '#5036a1',
  '#282f77',
  '#d58184',
  '#7a5229',
  '#a84738',
  '#329546',
  '#2d8633',
  '#7aa437',
  '#81bed5',
  '#66b03b',
  '#37a49a',
  '#6e53c6',
  '#76b83d',
  '#309188',
  '#b466cc',
  '#7da3d4',
  '#b8cb62',
  '#2e8a5a',
  '#3baab0',
  '#44c16d',
  '#c8c65b',
  '#2b827f',
  '#c79c57',
  '#915630',
  '#d071c6',
  '#8c8cd9',
  '#a6cf6e',
  '#36a149',
  '#3779a4',
  '#799933',
  '#b03b9a',
  '#d7bd88',
  '#7997d2',
  '#993b33',
  '#bd71d0',
  '#5789c7',
  '#2f738e',
  '#b888d7',
  '#d5d581',
  '#39862d',
  '#6dcb62',
  '#47c26a',
  '#4b8dc3',
  '#5eb6c9',
  '#b43c40',
  '#7e2a46',
  '#d2798b',
  '#d7d98c',
  '#7d8ed4',
  '#cb62c4',
  '#81cd6a',
  '#6a297a',
  '#bfd98c',
  '#a8383c',
  '#8a2e46',
  '#c571d0',
  '#32954b',
  '#d788d4',
  '#cf6eba',
  '#4b83c3',
  '#29397a',
  '#762b82',
  '#735826',
  '#8ad788',
  '#368a2e',
  '#953256',
  '#aa62cb',
  '#267358',
  '#d1bf75',
  '#66cc90',
  '#c144a1',
  '#4d36a1',
  '#6ecfa1',
  '#62adcb',
  '#3db8b4',
  '#2b6482',
  '#bb3e68',
  '#b83d68',
  '#5c9933',
  '#344d9d',
];
