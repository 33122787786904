import { Divider, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { useDesignStore } from '../../state';
import { GroupTurfPressureTable } from './PressureTables/GroupTurfPressureTable';
import { POCPressureTable } from './PressureTables/POCPressureTable';
import { TurfPressureTable } from './PressureTables/TurfPressureTable';

export const PressureTables = () => {
  const zones = useDesignStore((state) => state.zones);
  const groups = useDesignStore((state) => state.groups);
  const pocGraphs = useDesignStore((state) => state.pocGraphs);

  return (
    <Wrap>
      <Header as="h4">POC Loss Tables</Header>
      <small>Autosizing aims for a residual pressure of &gt;= 2.5</small>
      {pocGraphs.map((_, treeIndex) => (
        <div key={treeIndex}>
          <POCPressureTable treeIndex={treeIndex} />
          <Divider />
        </div>
      ))}

      <Header as="h4">Zone Loss Tables</Header>
      {zones
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((zone, i) => (
          <div key={i}>
            <TurfPressureTable zone={zone} />
            <Divider />
          </div>
        ))}
      {groups
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((group, i) => (
          <div key={i}>
            <GroupTurfPressureTable group={group} />
            <Divider />
          </div>
        ))}
    </Wrap>
  );
};
const Wrap = styled.div`
  .chart {
    background: #ccc;
  }
  .ui.list .list,
  ol.ui.list ol,
  ul.ui.list ul {
    border-left: 1px solid #ccc;
  }

  .list .item a {
    &:hover {
      text-decoration: underline;
    }
  }
`;
