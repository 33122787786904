import { format, parseISO } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Divider, Grid, Icon, Table } from 'semantic-ui-react';
import { ProfileContext } from 'src/contexts/profile/profile';

import {
  DesignElement,
  IDesign,
  IPoC,
  ProjectData,
  ProjectPOC,
  ProjectUI,
} from '@shared-types';
import {
  deleteProject,
  getProject,
  getProjectDesigns,
  updateProject,
} from 'src/services/project.service';
import { designDefault } from 'src/shared/constants';
import { base } from 'src/styles/common';
import styled from 'styled-components';
import { contains } from 'underscore';
import { LinkWithConfirm } from '../../components/LinkWithConfirm';
import { cloudinaryConfig } from '../Workbench/cloudinary.config';
import { defaultItem } from '../Workbench/helpers';
import {
  copyDesign,
  deleteDesign,
  newDesign,
} from '../Workbench/services/design.service';
import { FileListing } from './FileListing';
import { ProjectDetails } from './ProjectDetails';
import { ProjectInvoices } from './ProjectInvoices';

export const fetchAsBlob = async (url: string) =>
  fetch(url).then((response) => response.blob());

// export const convertBlobToBase64 = (blob: any): Promise<string> =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onerror = reject;
//     reader.onload = (e: any) => {
//       resolve(e.target.result);
//     };
//     reader.readAsDataURL(blob);
//   });

// export const convertBlobToSVG = (blob: any): Promise<string> =>
//   new Promise((resolve, _reject) => {
//     const reader = new FileReader();
//     reader.onload = (e: any) => {
//       resolve(e.target.result);
//     };
//     reader.readAsText(blob);
//   });

interface ProjectPermissions {
  basemaps: boolean;
  invoices: boolean;
  details: boolean;
  logs: boolean;
  basemapUpload: boolean;
  logoUpload: boolean;
  deleteProject: boolean;
  removeCredits: boolean;
  deleteBasemaps: boolean;
  workbenchDesigns: boolean;
  packets: boolean;
  projectNotes: boolean;
  uploadDesign: boolean;
}

export const canDownloadFiles = (_: ProjectUI): boolean => true;
// project.status === ProjectStatus.DESIGN_COMPLETE &&
// (!project.invoices.length ||
//   project.invoices.every((invoice: InvoiceUI) => invoice.status === 'paid'))

export const Project = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeProject, setActiveProject] = useState<ProjectUI | null>(null);
  const { role } = useContext(ProfileContext);
  // const [nextCode, setNextCode] = useState('');
  const [generating, setGenerating] = useState(false);
  const [permissions, setPermissions] = useState<ProjectPermissions>({
    basemaps: false,
    invoices: false,
    details: false,
    logs: false,
    basemapUpload: false,
    logoUpload: false,
    deleteProject: false,
    removeCredits: false,
    deleteBasemaps: false,
    workbenchDesigns: false,
    packets: false,
    projectNotes: false,
    uploadDesign: false,
  });

  const parseProject = async (projectId: number): Promise<void> => {
    try {
      const { project } = await getProject(projectId);
      const { designs } = await getProjectDesigns(projectId);
      setActiveProject({
        ...project,
        designs,
      });
      if (!project.codeId && role === 'admin' && project.owner) {
        // const next = await getNextCredit(project.owner.email);
        // setNextCode(next.code);
      }
    } catch (err) {
      console.log('no active project');
    }
  };
  useEffect(
    () => {
      if (id) {
        parseProject(parseInt(id, 10));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  useEffect(() => {
    setPermissions({
      basemaps: contains(['admin', 'basemapper', 'designer'], role),
      invoices: contains(['admin', 'contractor', 'readonly'], role),
      logs: role === 'admin',
      details: contains(
        ['admin', 'basemapper', 'designer', 'readonly', 'contractor'],
        role,
      ),
      basemapUpload: contains(['admin', 'basemapper'], role),
      logoUpload: role === 'admin',
      deleteProject: role === 'admin',
      removeCredits: role === 'admin',
      deleteBasemaps: contains(['admin', 'basemapper'], role),
      packets: contains(['admin', 'designer', 'contractor'], role),
      workbenchDesigns: contains(['admin', 'designer'], role),
      projectNotes: true,
      uploadDesign: contains(['admin', 'designer'], role),
    });
  }, [role]);

  const onDesignDelete = async (designId: number) => {
    await deleteDesign(designId);
    if (activeProject) {
      parseProject(activeProject.id);
    }
  };
  const onDesignCopy = async (designId: number) => {
    await copyDesign(designId);
    if (activeProject) {
      parseProject(activeProject.id);
    }
  };

  const getDefaultInvoiceDescription = (project: ProjectUI): string => {
    const { poNumber, projectNumber } = project.data;
    let defaultInvoiceDescription = 'Irrigation Design';
    if (projectNumber) {
      defaultInvoiceDescription += ` - Project: ${project.data.projectNumber}`;
    }
    if (poNumber) {
      defaultInvoiceDescription += ` - PO: ${project.data.poNumber}`;
    }
    return defaultInvoiceDescription;
  };

  const generateDesignFromProject = async (project: ProjectUI) => {
    setGenerating(true);
    try {
      const elements: DesignElement[] = [];
      project.data.pocs.forEach((poc: ProjectPOC, i: number, arr) => {
        let newSource: DesignElement | undefined;
        const pocProps: IPoC = {
          name:
            arr.length > 1
              ? `Point Of Connection ${i + 1}`
              : 'Point Of Connection',
          staticPSI: 0,
          mainsToSource: poc.mains,
          measuredPSI: poc.dynamicPSI || undefined,
          measuredGPM: poc.gpm || undefined,
          sourceID: newSource ? newSource.uuid : undefined,
        };
        const x = (i * 20) / project.data.scale;
        const newPOC: DesignElement = {
          ...defaultItem({ x, y: 0 }),
          type: 'poc',
          props: pocProps,
          itemType: 'design-element',
        };
        elements.push(newPOC);
      });
      const designData: IDesign = {
        ...designDefault,
        title: project.data.title,
        contractorAddress: project.data.companyAddress,
        contractorName: project.data.companyName,
        clientAddress: project.data.siteAddress,
        elements,
      };
      if (project.data.logos.length) {
        designData.contractorLogoURL = project.data.logos[0].secure_url;
      }
      await newDesign(project.data.title, project.id, designData);
      if (id) {
        parseProject(parseInt(id, 10));
        setGenerating(false);
      }
    } catch (err) {
      console.log(err);
      setGenerating(false);
    }
  };

  const openFileWidget = () => {
    if (activeProject) {
      const data = activeProject.data;
      (window as any).cloudinary.openUploadWidget(
        cloudinaryConfig,
        (error, result) => {
          if (error) {
            console.log(error);
          } else {
            if (result.event === 'queues-end') {
              if (result.info.files.length) {
                data.contractorFiles = [
                  ...data.contractorFiles,
                  ...result.info.files
                    .filter((f) => f.status === 'success')
                    .map((f) => f.uploadInfo),
                ];
              }
            }
            if (result.event === 'close') {
              setActiveProject({
                ...activeProject,
                data,
              });
              updateProject(
                activeProject.id,
                data,
                activeProject.codeId,
                'fileUpload',
              );
            }
          }
        },
      );
    }
  };

  const openDesignWidget = () => {
    if (activeProject) {
      const data = { ...activeProject.data };
      (window as any).cloudinary.openUploadWidget(
        cloudinaryConfig,
        (error, result) => {
          if (error) {
            console.log(error);
          } else {
            if (result.event === 'queues-end') {
              if (result.info.files.length) {
                data.designPackets = [
                  ...data.designPackets,
                  ...result.info.files
                    .filter((f) => f.status === 'success')
                    .map((f) => f.uploadInfo),
                ];
                updateProject(activeProject.id, data, activeProject.codeId);
              }
            }
            if (result.event === 'close') {
              setActiveProject({
                ...activeProject,
                data,
              });
            }
          }
        },
      );
    }
  };

  const openLogoWidget = () => {
    if (activeProject) {
      const data = { ...activeProject.data };
      (window as any).cloudinary.openUploadWidget(
        cloudinaryConfig,
        (error, result) => {
          if (error) {
            console.log(error);
          } else {
            if (result.event === 'queues-end') {
              if (result.info.files.length && activeProject) {
                data.logos = [
                  ...activeProject.data.logos,
                  ...result.info.files
                    .filter((f) => f.status === 'success')
                    .map((f) => f.uploadInfo),
                ];
                updateProject(activeProject.id, data, activeProject.codeId);
              }
            }
            if (result.event === 'close') {
              setActiveProject({
                ...activeProject,
                data,
              });
            }
          }
        },
      );
    }
  };

  const deleteContractorFile = (i: number) => {
    if (activeProject) {
      const contractorFiles = activeProject.data.contractorFiles.filter(
        (_file, j) => j !== i,
      );
      const data: ProjectData = { ...activeProject.data, contractorFiles };
      setActiveProject({ ...activeProject, data });
      updateProject(activeProject.id, data, activeProject.codeId);
    }
  };
  const deleteLogoFile = (i: number) => {
    if (activeProject) {
      const logos = activeProject.data.logos.filter((_file, j) => j !== i);
      const data: ProjectData = { ...activeProject.data, logos };
      setActiveProject({ ...activeProject, data });
      updateProject(activeProject.id, data, activeProject.codeId);
    }
  };

  const deleteDesignPacketFile = (i: number) => {
    if (activeProject) {
      const designPackets = activeProject.data.designPackets.filter(
        (_file, j) => j !== i,
      );
      const data: ProjectData = { ...activeProject.data, designPackets };
      setActiveProject({ ...activeProject, data });
      updateProject(activeProject.id, data, activeProject.codeId);
    }
  };

  const handleDelete = async () => {
    if (activeProject) {
      await deleteProject(activeProject.id);
      navigate('/app/dashboard');
    }
  };

  return (
    <Wrap>
      {!!activeProject && (
        <Grid stackable={true} columns={2}>
          {permissions.details && (
            <Grid.Column width={6}>
              <ProjectDetails
                project={activeProject}
                onEdit={() => parseProject(activeProject.id)}
              />
              <Card fluid={true}>
                <Card.Content>
                  <Card.Header>Logo</Card.Header>
                </Card.Content>
                <Card.Content>
                  <>
                    {activeProject.data.logos &&
                      (activeProject.data.logos || []).length > 0 && (
                        <FileListing
                          files={activeProject.data.logos}
                          onDelete={deleteLogoFile}
                          canDelete={
                            (role === 'contractor' &&
                              activeProject.status === 'Draft') ||
                            role === 'admin'
                          }
                        />
                      )}
                    {!activeProject.data.logos ||
                      (!activeProject.data.logos.length && (
                        <div>No logos have been uploaded for this project</div>
                      ))}
                    {permissions.logoUpload && (
                      <Button
                        type="button"
                        primary={true}
                        onClick={openLogoWidget}
                      >
                        Upload Logo
                      </Button>
                    )}
                  </>
                </Card.Content>
              </Card>
              {permissions.deleteProject && (
                <div>
                  <Divider />
                  <LinkWithConfirm onConfirm={handleDelete}>
                    Delete Project
                  </LinkWithConfirm>
                </div>
              )}
            </Grid.Column>
          )}
          <Grid.Column width={10}>
            {permissions.invoices && (
              <Card fluid={true}>
                <Card.Content>
                  <Card.Header>Project Invoices</Card.Header>
                  {activeProject.data.projectNumber && (
                    <Card.Description>
                      Project #: {activeProject.data.projectNumber}
                    </Card.Description>
                  )}
                  {activeProject.data.poNumber && (
                    <Card.Description>
                      PO #: {activeProject.data.poNumber}
                    </Card.Description>
                  )}
                </Card.Content>
                <Card.Content>
                  <ProjectInvoices
                    projectId={activeProject.id}
                    invoices={activeProject.invoices}
                    invoiceDescription={getDefaultInvoiceDescription(
                      activeProject,
                    )}
                    onUpdate={() => parseProject(activeProject.id)}
                  />
                  {!!activeProject.codeId && (
                    <div>
                      <Divider />
                      This design is using a project credit
                      {permissions.removeCredits && (
                        <LinkWithConfirm
                          onConfirm={async () => {
                            await updateProject(
                              activeProject.id,
                              activeProject.data,
                              '',
                            );
                            parseProject(activeProject.id);
                          }}
                        >
                          Remove credit
                        </LinkWithConfirm>
                      )}
                    </div>
                  )}
                  {/* {!activeProject.codeId &&
                    nextCode.length > 0 &&
                    role === 'admin' && (
                      <>
                        <Divider />
                        <Button
                          size="mini"
                          onClick={async () => {
                            await updateProject(
                              activeProject.id,
                              activeProject.data,
                              nextCode,
                            );
                            parseProject(activeProject.id);
                          }}
                        >
                          Use credit for this project
                        </Button>
                      </>
                    )} */}
                </Card.Content>
              </Card>
            )}
            <Grid stackable={true}>
              <Grid.Column>
                <Card fluid={true}>
                  <Card.Content>
                    <Card.Header>Project Files</Card.Header>
                  </Card.Content>
                  <Card.Content>
                    <div className="file-list-wrap">
                      {(activeProject.data.contractorFiles || []).length >
                        0 && (
                        <FileListing
                          files={activeProject.data.contractorFiles}
                          onDelete={deleteContractorFile}
                          canDelete={
                            (role === 'contractor' &&
                              activeProject.status === 'Draft') ||
                            role === 'admin'
                          }
                        />
                      )}
                      {!activeProject.data.contractorFiles.length && (
                        <div>No files have been uploaded for this project</div>
                      )}
                    </div>
                    <Button onClick={openFileWidget}>
                      <Icon name="upload" />
                      Upload Files
                    </Button>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid>

            {permissions.workbenchDesigns && (
              <>
                <Card fluid={true}>
                  <Card.Content>
                    <Card.Header>Workbench Designs</Card.Header>
                    <small>Only admins and designers can see designs</small>
                  </Card.Content>
                  <Card.Content>
                    {!activeProject.designs.length && (
                      <div>No workbench designs have been created yet</div>
                    )}
                    {!!activeProject.designs.length && (
                      <Table compact={true}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Design</Table.HeaderCell>
                            <Table.HeaderCell>User</Table.HeaderCell>
                            <Table.HeaderCell>Created</Table.HeaderCell>
                            <Table.HeaderCell />
                            <Table.HeaderCell />
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {activeProject.designs
                            .sort((d1, d2) =>
                              d1.createdAt.localeCompare(d2.createdAt),
                            )
                            .map((d, i) => (
                              <Table.Row key={i}>
                                <Table.Cell>
                                  <Link to={`/app/workbench/${d.id}`}>
                                    {' '}
                                    {d.title}
                                  </Link>
                                </Table.Cell>
                                <Table.Cell>{d.email}</Table.Cell>
                                <Table.Cell>
                                  {format(
                                    parseISO(d.createdAt),
                                    'MMM d, yyyy h:mm a',
                                  )}
                                </Table.Cell>
                                <Table.Cell>
                                  {role === 'admin' && (
                                    <LinkWithConfirm
                                      onConfirm={() => onDesignDelete(d.id)}
                                    >
                                      Delete
                                    </LinkWithConfirm>
                                  )}
                                </Table.Cell>
                                <Table.Cell>
                                  <LinkWithConfirm
                                    onConfirm={() => onDesignCopy(d.id)}
                                  >
                                    Copy
                                  </LinkWithConfirm>
                                </Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    )}
                  </Card.Content>
                  <Card.Content extra={true}>
                    <Button
                      size="mini"
                      onClick={() => generateDesignFromProject(activeProject)}
                      primary={true}
                      disabled={generating}
                    >
                      Generate Design
                    </Button>
                  </Card.Content>
                </Card>
              </>
            )}
            {permissions.packets && (
              <Card fluid={true}>
                <Card.Content>
                  <Card.Header>Finished Designs</Card.Header>
                </Card.Content>
                <Card.Content>
                  {(activeProject.data.designPackets || []).length > 0 &&
                    (activeProject.status === 'Design Complete' ||
                      role === 'admin') && (
                      <FileListing
                        files={activeProject.data.designPackets}
                        canDownload={
                          role === 'admin' || canDownloadFiles(activeProject)
                        }
                        onDelete={deleteDesignPacketFile}
                        canDelete={role === 'admin'}
                      />
                    )}
                  {!activeProject.data.designPackets.length && (
                    <div>
                      After we have completed your design and it has been paid
                      for, your downloadable files will appear here
                    </div>
                  )}
                </Card.Content>
                {permissions.uploadDesign && (
                  <Card.Content extra={true}>
                    <Button
                      size="mini"
                      type="button"
                      primary={true}
                      onClick={openDesignWidget}
                    >
                      Upload Finished Design File(s)
                    </Button>

                    <div>
                      <small>
                        Only admins and designers can upload finished design
                        files
                      </small>
                    </div>
                  </Card.Content>
                )}
              </Card>
            )}
            {/* {permissions.projectNotes && (
              <Card fluid={true}>
                <Card.Content>
                  <Card.Header>Notes</Card.Header>
                </Card.Content>
                <Card.Content>
                  <ProjectNotes
                    onUpdate={() => parseProject(activeProject.id)}
                    notes={activeProject.notes}
                    projectId={activeProject.id}
                  />
                </Card.Content>
              </Card>
            )}
            {permissions.logs && (
              <Card fluid={true}>
                <Card.Content>
                  <Card.Header>Audit Log</Card.Header>
                </Card.Content>
                <Card.Content>
                  <ProjectLogs logs={activeProject.logs} />
                </Card.Content>
              </Card>
            )} */}
          </Grid.Column>
        </Grid>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  padding: ${base * 3}px;
  .file-list-wrap {
    max-height: 320px;
    overflow: auto;
  }
`;
