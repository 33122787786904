import React from 'react';
import { IPlant } from '../../../../../../shared-types';
import { isPlant } from '../../plants/plants';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import {
  selectAndCenterSingleID,
  setShowPlantOutlines,
  useDesignStore,
} from '../../state';
import { deleteItem, updateItem } from '../../state/item-state';
import { CheckboxAction } from './CheckboxAction';
import { LayerChild } from './LayerChild';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const PlantsLayer = ({ open, onOpen }) => {
  const plants = useDesignStore((state) => state.items.filter(isPlant));
  const showOutlines = useDesignStore((state) => state.showPlantOutlines);
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.PLANTS,
  );
  const toggleChildVisibility = (item: IPlant) => {
    updateItem({ ...item, visible: !item.visible }, true);
  };

  const toggleChildLock = (item: IPlant) => {
    updateItem({ ...item, locked: !item.locked }, true);
  };
  const toggleShowOutlines = () => {
    setShowPlantOutlines(!showOutlines);
  };

  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Plants (${plants.length})`}
      open={open}
      onOpen={onOpen}
      actions={
        <>
          <CheckboxAction
            title="Show Outlines"
            onClick={toggleShowOutlines}
            checked={showOutlines}
          />
        </>
      }
      childLayer={plants.map((plant) => (
        <LayerChild
          key={plant.uuid}
          visible={plant.visible}
          locked={plant.locked}
          title={`${plant.name}`}
          clickToggle={() => toggleChildVisibility(plant)}
          clickLock={() => toggleChildLock(plant)}
          clickDelete={() => deleteItem(plant.uuid)}
          clickItem={() => selectAndCenterSingleID(plant.uuid)}
          layerLocked={layerLocked}
          layerVisible={layerVisible}
        />
      ))}
    />
  );
};

export default React.memo(PlantsLayer);
