import { DesignElement, IPoint } from '@shared-types';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, Modal } from 'semantic-ui-react';
import { moveSleeve } from '../features/sleeves/sleeves.paper';
import { bulkChangeSleeves } from '../features/sleeves/state/bulkChangeSleeves';
import { isSleeve } from '../helpers';
import { getState } from '../state';
import { changeElements } from '../state/changeElements';
import { modalDefaults } from '../state/modal.service';
import { isSprinkler } from '../tools/paper-items/paper-sprinkler';

export const MoveItemsModalType = 'moveItemsModal';
export const MoveItemsModal = ({ closeModal }) => {
  const [moves, setMoves] = useState<IPoint[]>([]);
  const [moveX, setMoveX] = useState(0);
  const [moveY, setMoveY] = useState(0);
  const doMoveX = (e) => {
    setMoveX(e.floatValue);
  };
  const doMoveY = (e) => {
    setMoveY(e.floatValue);
  };
  const moveElements = () => {
    const els = getState().elements;
    const movePoint = { x: moveX, y: moveY };
    changeElements(
      els.map((el) => {
        const newPoint = {
          x: el.position.x + moveX,
          y: el.position.y + moveY,
        };
        const newEl: DesignElement = {
          ...el,
          position: newPoint,
        };
        if (isSprinkler(el)) {
          newEl.props = {
            ...newEl.props,
            ...newPoint,
          };
        }
        return newEl;
      }),
    );
    const updatedSleeves = getState()
      .items.filter(isSleeve)
      .map((sleeve) => moveSleeve(sleeve, movePoint));
    bulkChangeSleeves(updatedSleeves);
    setMoves([...moves, { x: moveX, y: moveY }]);
    setMoveX(0);
    setMoveY(0);
  };
  return (
    <Modal
      actions={[{ key: 'done', content: 'Done', primary: true }]}
      {...modalDefaults}
      header="Move Items"
      onActionClick={closeModal}
      content={
        <Modal.Content>
          <h3>Move Items</h3>
          <label>Move X </label>
          <NumericFormat value={moveX} onValueChange={doMoveX} />
          <br />
          <label>Move Y </label>
          <NumericFormat value={moveY} onValueChange={doMoveY} />
          <br />
          <Button size="mini" primary={true} onClick={moveElements}>
            Move Items
          </Button>
          <br />
          <small>
            This will move all elements. Positive X is right, Positive Y is
            down. Valve boxes should be moved manually
          </small>
          <br />
          {moves.length > 0 && (
            <div>
              Recent moves:
              <ol>
                {moves.map((move, i) => (
                  <li key={i}>
                    x: {move.x}, y: {move.y}
                  </li>
                ))}
              </ol>
            </div>
          )}
        </Modal.Content>
      }
    />
  );
};
