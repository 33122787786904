import { ISleeve } from '@shared-types';
import { getState, setState } from '../../../state';
import { sleeveService } from '../sleeve.service';

export const addSleeve = (payload: ISleeve) => {
  setState({
    items: [...getState().items, payload],
    selectedIDs: [payload.uuid],
  });
  sleeveService.renderItemToPaper(payload);
};
