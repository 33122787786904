import React from 'react';
import { Basemap } from '../../../../../../shared-types';
import { isBasemap } from '../../helpers';
import { LAYER_NAMES } from '../../shared/workbench-enums';
import { selectAndCenterSingleID, useDesignStore } from '../../state';
import { deleteItem, updateItem } from '../../state/item-state';
import { BasemapUploader } from '../../upload/BasemapUploader';
import { LayerChild } from './LayerChild';
import { StandardLayer } from './StandardLayer';
import { useLayerState } from './useLayerState';

const BasemapsLayer = ({ open, onOpen }) => {
  const basemaps = useDesignStore((state) => state.items.filter(isBasemap));
  const { layerVisible, layerLocked, toggleLayer, toggleLock } = useLayerState(
    LAYER_NAMES.BASEMAPS,
  );

  const toggleChildVisibility = (item: Basemap) => {
    updateItem({ ...item, visible: !item.visible }, true);
  };

  const toggleChildLock = (item: Basemap) => {
    updateItem({ ...item, locked: !item.locked }, true);
  };

  return (
    <StandardLayer
      visible={layerVisible}
      locked={layerLocked}
      onToggleLayerVisibility={toggleLayer}
      onToggleLock={toggleLock}
      layerTitle={`Basemaps (${basemaps.length})`}
      open={open}
      onOpen={onOpen}
      actions={[<BasemapUploader key="basemap-uploader" />]}
      childLayer={basemaps.map((basemap, i) => {
        return (
          <LayerChild
            key={basemap.uuid}
            visible={basemap.visible}
            locked={basemap.locked}
            title={`Basemap ${i + 1}`}
            clickToggle={() => toggleChildVisibility(basemap)}
            clickLock={() => toggleChildLock(basemap)}
            layerLocked={layerLocked}
            layerVisible={layerVisible}
            clickDelete={() => deleteItem(basemap.uuid)}
            clickItem={() => selectAndCenterSingleID(basemap.uuid)}
          />
        );
      })}
    />
  );
};

export default React.memo(BasemapsLayer);
