import paper from 'src/paper';
import { itemSizes } from 'src/shared/constants';
import { getPointOnCircleBetweenTwoPoints } from '../shared/geometry';
import { getColor } from './plot.helpers';

export const createValveIcon = (
  color: paper.Color,
  isDrip: boolean,
  scale: number,
): paper.Group => {
  const valveRadius = itemSizes.valveDiameter / 2 / scale;
  const valveGroup = new paper.Group();
  const valveInnards = new paper.Group();
  const valveMask = new paper.Shape.Circle(
    new paper.Point(valveRadius, valveRadius),
    valveRadius,
  );
  const rectGroup = new paper.Group();
  const rA = { x: valveRadius, y: 0 };
  const rB = { x: valveRadius, y: valveRadius };
  const rC = { x: valveRadius * 2, y: valveRadius };
  const rect = new paper.Path([
    new paper.Point(rA),
    new paper.Point(rB),
    new paper.Point(rC),
  ]);
  rect.arcTo(getPointOnCircleBetweenTwoPoints(rB, rA, rC), new paper.Point(rA));
  const pA = { x: 0, y: valveRadius };
  const pB = { x: valveRadius, y: valveRadius };
  const pC = { x: valveRadius, y: valveRadius * 2 };
  const rect2 = new paper.Path([
    new paper.Point(pA),
    new paper.Point(pB),
    new paper.Point(pC),
  ]);
  rect2.arcTo(
    getPointOnCircleBetweenTwoPoints(pB, pA, pC),
    new paper.Point(pA),
  );

  rectGroup.addChildren([rect, rect2]);
  rectGroup.fillColor = color;
  rectGroup.name = 'valve-rects';
  valveInnards.addChild(valveMask);
  valveInnards.addChild(rectGroup);

  const valveOutline = new paper.Shape.Circle(
    new paper.Point(valveRadius, valveRadius),
    valveRadius,
  );
  valveOutline.strokeWidth = itemSizes.genericOutline / scale;
  valveOutline.strokeColor = color;
  valveOutline.fillColor = getColor('white');
  valveOutline.name = 'valve-outline';

  valveGroup.addChildren([valveOutline, valveInnards]);

  if (isDrip) {
    const offset = valveRadius / 3;
    const dripDotSize = itemSizes.dripDot / scale;
    const dripDots = new paper.Group();
    dripDots.addChildren([
      new paper.Shape.Circle(
        new paper.Point(valveRadius - offset, valveRadius - offset),
        dripDotSize,
      ),
      new paper.Shape.Circle(
        new paper.Point(valveRadius + offset, valveRadius + offset),
        dripDotSize,
      ),
    ]);
    dripDots.strokeColor = getColor('black');
    dripDots.strokeWidth = itemSizes.dripDot / scale;
    valveGroup.addChild(dripDots);
  }
  valveGroup.locked = true;
  return valveGroup;
};
