import { Button, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { ReactComponent as Mouse } from '../images/mouse.svg';
import { ReactComponent as Touch } from '../images/touch.svg';
import { setIsMouse, useDesignStore } from '../state';
import { PanelWrap } from './PanelButton';

export const LayerPopup = () => {
  const isMouse = useDesignStore((s) => s.isMouse);

  return (
    <Wrap>
      <Popup
        trigger={
          <Button
            size="mini"
            icon={isMouse ? <Mouse /> : <Touch />}
            className="custom-button"
            onClick={() => setIsMouse(!isMouse)}
          ></Button>
        }
        on="hover"
        content={
          isMouse ? (
            <div>
              <strong>Current Mode: Mouse</strong>
              <li>Right-click and drag to move around</li>
              <li>Scroll to zoom</li>
              <small>Click button to switch to trackpad</small>
            </div>
          ) : (
            <div>
              <strong>Current Mode: Trackpad</strong>
              <li>Pinch to zoom</li>
              <li>2 fingers to move around</li>
              <small>Click button to switch to mouse</small>
            </div>
          )
        }
      />
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)`
  position: absolute;
  bottom: 8px;
  right: 8px;

  .custom-button {
    margin: 4px !important;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
