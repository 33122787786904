import styled from 'styled-components';
import { DeleteHeadsConfirmType } from '../features/heads/DeleteAllHeads';
import { EdgeAlgoModalType } from '../features/heads/EdgeAlgoModal';
import { HeadSizeModalType } from '../features/heads/HeadSizeTools';
import { NozzleOptions } from '../features/heads/NozzleOptions';
import { selectYardHeads } from '../paper-helpers/heads';
import {
  setActiveBaseKey,
  setActiveBaseRadius,
  useDesignStore,
} from '../state';
import { openModal } from '../state/modal.store';
import { PanelButton, PanelDivider, PanelWrap } from './PanelButton';

export const HeadToolProperties = () => {
  const activeBaseKey = useDesignStore((state) => state.activeBaseKey);
  const activeBaseRadius = useDesignStore((state) => state.activeBaseRadius);

  const doActiveBaseChange = (uuid: string, radius: number) => {
    setActiveBaseKey(uuid);
    setActiveBaseRadius(radius);
  };

  return (
    <Wrap>
      <h5>Head Tool</h5>
      <div>
        Click anywhere in the design to add a head. Click below to select a
        nozzle before adding.
      </div>
      <br />
      <NozzleOptions
        activeKey={activeBaseKey}
        activeRadius={activeBaseRadius}
        onSet={doActiveBaseChange}
      />
      <PanelDivider />
      <PanelButton onClick={() => openModal(EdgeAlgoModalType)}>
        Edge Algorithm
      </PanelButton>
      <PanelButton onClick={selectYardHeads}>
        Select heads inside active yard
      </PanelButton>
      <PanelButton onClick={() => openModal(DeleteHeadsConfirmType)}>
        Delete all heads
      </PanelButton>
      <PanelButton onClick={() => openModal(HeadSizeModalType)}>
        Head Sizes
      </PanelButton>
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)``;
