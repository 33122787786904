import { Modal } from 'semantic-ui-react';
import { modalDefaults } from '../../state/modal.service';
import { AlertList } from './AlertList';

export const AlertsModalType = 'alertsModal';
export const AlertsModal = ({ closeModal }) => {
  return (
    <Modal
      {...modalDefaults}
      style={{ maxHeight: '70vh' }}
      header="Alerts"
      actions={[
        {
          key: 'done',
          content: 'Done',
          primary: true,
        },
      ]}
      onActionClick={closeModal}
      content={
        <Modal.Content scrolling>
          <AlertList />
        </Modal.Content>
      }
    />
  );
};
