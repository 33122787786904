import { IEdge, IPoint, Zone } from '../../../../../../shared-types';
import { createDefaultEdge } from '../../paper-helpers/edges';
import { getState } from '../../state';
import { addElementsEdges } from '../../state/addElementsEdges';
import { deleteElements } from '../../state/deleteElements';

export const cleanColinearPipes = (zones: Zone[]) => {
  let changesMade;
  let breaker = 0;
  do {
    if (breaker > 1000) {
      throw new Error('cleanColinearPipes is stuck in an infinite loop');
    }
    changesMade = false;
    const offLimitsFittings: string[] = [];
    zones.forEach((z) => {
      offLimitsFittings.push(
        z.valve,
        z.valveInputFitting,
        z.valveOutputFitting,
      );
    });
    const elements = getState().elements;
    const fittings = elements.filter(
      (el) => el.type === 'fitting' && !offLimitsFittings.includes(el.uuid),
    );
    for (let i = 0; i < fittings.length; i++) {
      const elements = getState().elements;
      const edges = getState().edges;
      const fitting = fittings[i];
      const fittingEdges = edges.filter(
        (edge) => edge.source === fitting.uuid || edge.target === fitting.uuid,
      );
      if (fittingEdges.length === 2) {
        const sibling1ID =
          fittingEdges[0].source === fitting.uuid
            ? fittingEdges[0].target
            : fittingEdges[0].source;
        const sibling2ID =
          fittingEdges[1].source === fitting.uuid
            ? fittingEdges[1].target
            : fittingEdges[1].source;
        const sibling1El = elements.find((el) => el.uuid === sibling1ID);
        const sibling2El = elements.find((el) => el.uuid === sibling2ID);
        if (sibling1El && sibling2El) {
          const areColinear = checkColinearity(
            fitting.position,
            sibling1El.position,
            sibling2El.position,
          );
          if (areColinear) {
            deleteElements([fitting.uuid]);
            const newEdge: IEdge = createDefaultEdge({
              source: sibling1ID,
              target: sibling2ID,
            });
            addElementsEdges([], [newEdge]);
            changesMade = true;
            break;
          }
        }
      }
    }
    breaker++;
  } while (changesMade);
};

export const checkColinearity = (a: IPoint, b: IPoint, c: IPoint): boolean => {
  // Calculate the vectors ab and bc
  const ab = { x: b.x - a.x, y: b.y - a.y };
  const bc = { x: c.x - b.x, y: c.y - b.y };
  // Calculate the cross product of vectors ab and bc
  const crossProduct = ab.x * bc.y - ab.y * bc.x;
  // Check if the cross product is close to zero (considering floating-point precision)
  return Math.abs(crossProduct) < 1e-10; // adjust the threshold as necessary
};
