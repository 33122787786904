import { filter, map, merge, switchMap, takeUntil, tap } from 'rxjs';

import paper from 'src/paper';
import { IPoint } from '../../../../../shared-types';
import { polyHintPurple } from '../../../shared';
import { localPaper, localZoom } from '../localPaper';
import { findNamedItems, getColor } from '../paper-helpers/plot.helpers';
import { getState, setNorthRotation } from '../state';
import { toolService } from '../tool.service';

const toolName = 'north-arrow';

const { mouseUp$, mouseDown$, mouseDrag$ } = toolService.getEvents(toolName);

const normalizeRotation = (rotation: number) => {
  return (rotation + 90) % 360;
};

const buildArrow = (rootPoint: IPoint, target: IPoint) => {
  const root = new paper.Point(rootPoint);
  const radius = 5 / (localZoom() || 1);
  const rootCircle = new paper.Path.Circle(root, radius);
  rootCircle.fillColor = getColor(polyHintPurple);
  const path = new paper.Path([root, target]);
  path.strokeWidth = 2 / (localZoom() || 1);
  path.strokeColor = getColor(polyHintPurple);
  const triangle = new paper.Path.RegularPolygon(
    new paper.Point(target),
    3,
    radius * 2,
  );
  const angle = path.lastSegment.point.subtract(path.firstSegment.point).angle;
  triangle.rotate(angle - 270);
  triangle.fillColor = getColor(polyHintPurple);
  const arrow = new paper.Group([path, triangle, rootCircle]);
  arrow.name = 'tmp-arrow-group';
  return arrow;
};

const destroyNorthArrow = () => {
  findNamedItems('tmp-arrow-group').forEach((item) => {
    item.remove();
  });
  findNamedItems('tmp-north-text').forEach((item) => {
    item.remove();
  });
};

const createText = (point: IPoint, content: string) => {
  const text = new paper.PointText({
    point: new paper.Point(point),
    fontSize: 18 / (localZoom() || 1),
    justification: 'center',
    content,
  });
  text.name = 'tmp-north-text';
  return text;
};

const showNorthArrow$ = toolService.activeTool$.pipe(
  filter((tool) => tool === toolName),
  map(() => {
    const northArrowMarginTop = 32 / (localZoom() || 1);
    const center = localPaper.view.center;
    const rotation = getState().northRotation;

    const target = new paper.Point(center).add(
      new paper.Point({
        length: 100 / (localZoom() || 1),
        angle: rotation - 90,
      }),
    );
    const text = createText(
      { x: target.x, y: target.y + northArrowMarginTop },
      `North Rotation: ${normalizeRotation(rotation).toFixed(2)}˚`,
    );
    const arrow = buildArrow(center, target);
    return { arrow, text };
  }),
  takeUntil(
    merge(
      toolService.escapeKey$,
      toolService.activeTool$.pipe(filter((tool) => tool !== toolName)),
    ).pipe(
      tap(() => {
        destroyNorthArrow();
      }),
    ),
  ),
);

const eraseNorthArrow$ = toolService.escapeKey$.pipe(
  tap(() => {
    destroyNorthArrow();
  }),
);

const changeNorthArrow$ = mouseDown$.pipe(
  map((e) => {
    destroyNorthArrow();
    const center = e.e.point;
    const text = createText(center, '');
    const arrow = buildArrow(center, center);
    const rotation = getState().northRotation;
    const downPoint = e.e.downPoint;
    return { arrow, rotation, downPoint, text };
  }),
  switchMap(({ arrow, rotation, downPoint, text }) => {
    return mouseDrag$.pipe(
      map((e2) => {
        const northArrowMarginTop = 32 / (localZoom() || 1);
        const newRotation = e2.point.subtract(downPoint).angle;
        arrow.remove();
        arrow = buildArrow(downPoint, e2.point);
        rotation = newRotation;
        text.content = `${normalizeRotation(rotation).toFixed(
          2,
        )}° (release to set)`;
        text.position = new paper.Point({
          x: e2.point.x,
          y: e2.point.y + northArrowMarginTop,
        });
      }),
      takeUntil(
        merge(
          merge(
            toolService.escapeKey$,
            toolService.activeTool$.pipe(filter((tool) => tool !== toolName)),
          ).pipe(
            tap(() => {
              arrow.remove();
              text.remove();
            }),
          ),
          mouseUp$.pipe(
            tap(() => {
              const north = normalizeRotation(rotation);
              setNorthRotation(north);
              text.content = `North Rotation: ${normalizeRotation(
                rotation,
              ).toFixed(2)}˚`;
            }),
          ),
        ),
      ),
    );
  }),
);

export const northArrowStreams$ = merge(
  showNorthArrow$,
  changeNorthArrow$,
  eraseNorthArrow$,
);
