import {
  DesignElement,
  IEdge,
  IPipeProduct,
  IPoint,
  IValveProduct,
  ValveBox,
  Zone,
} from '@shared-types';
import { itemSizes } from 'src/shared/constants';
import { contains } from 'underscore';
import { getLateralPipeProduct } from '../features/pipes/Pipes';
import { defaultFitting, defaultItem } from '../helpers';
import { rotateAroundPoint } from '../shared/geometry';
import { getState } from '../state';
import { createDefaultEdge } from './edges';

export const getFittingPosition = (
  i: number,
  vb: ValveBox,
  scale: number,
): IPoint => {
  const origin = vb.position;
  const pointToBeRotated = {
    x: vb.position.x + i * (itemSizes.valveDiameter / scale),
    y: vb.position.y,
  };
  return rotateAroundPoint(origin, pointToBeRotated, vb.rotation);
};
export const getAssemblyPositions = (
  index: number,
  assemblyType: 'input' | 'output' | 'valve' | 'label',
  vb: ValveBox,
  scale: number,
): IPoint => {
  const valveSize = itemSizes.valveDiameter / scale;
  let yOffset = 0;

  switch (assemblyType) {
    case 'valve':
      yOffset = vb.left ? valveSize : -valveSize;
      break;
    case 'output':
      yOffset = valveSize * (vb.left ? 2 : -2);
      break;
    case 'label':
      yOffset = (vb.left ? -1 : 1) * valveSize;
      break;
    default:
      yOffset = 0; // For 'input' and any other unspecified types, no offset
      break;
  }
  const position = {
    x: vb.position.x + valveSize * (index + 1),
    y: vb.position.y + yOffset,
  };

  return rotateAroundPoint(vb.position, position, vb.rotation);
};

export const generateValveAssembly = (
  defaultValve: IValveProduct,
): {
  edge1: IEdge;
  edge2: IEdge;
  valveElement: DesignElement;
  inputFitting: DesignElement;
  outputFitting: DesignElement;
} => {
  const { pipeProducts, mainPipe, mainPipeSizes } = getState();
  const minMainSize = Math.min(...mainPipeSizes);
  const main = pipeProducts.find(
    (p) => p.series === mainPipe && p.size >= minMainSize,
  );
  const lateral = getLateralPipeProduct();
  const valveElement: DesignElement = {
    ...defaultItem({ x: 0, y: 1 }),
    type: 'valve',
    props: defaultValve,
    itemType: 'design-element',
  };
  const inputFitting: DesignElement = {
    ...defaultFitting({ x: 0, y: 0 }),
  };
  const outputFitting: DesignElement = {
    ...defaultFitting({ x: 0, y: 2 }),
  };
  const edge1 = createDefaultEdge({
    source: inputFitting.uuid,
    target: valveElement.uuid,
    pipe: main ? main.uuid : '',
  });
  const edge2 = createDefaultEdge({
    source: valveElement.uuid,
    target: outputFitting.uuid,
    pipe: lateral ? lateral.uuid : '',
    isLateral: true,
  });
  return { edge1, edge2, valveElement, inputFitting, outputFitting };
};

export const createEdgesBetweenValveSlots = (
  valveBox: ValveBox,
  zones: Zone[],
  mainPipe: string,
  pipeProducts: IPipeProduct[],
): IEdge[] => {
  const main = pipeProducts.find((p) => p.series === mainPipe);
  const edges: IEdge[] = [];
  let starting = valveBox.inputFitting;
  valveBox.zoneIDs.forEach((zoneID: string) => {
    if (zoneID) {
      const zone = zones.find((zone) => zone.uuid === zoneID);
      if (zone) {
        edges.push(
          createDefaultEdge({
            source: starting,
            target: zone.valveInputFitting,
            pipe: main ? main.uuid : '',
          }),
        );
        starting = zone.valveInputFitting;
      }
    }
  });
  edges.push(
    createDefaultEdge({
      source: starting,
      target: valveBox.outputFitting,
      pipe: main ? main.uuid : '',
    }),
  );
  return edges;
};

export const valveBoxFittingPosition = (
  valveBox: ValveBox,
  scale: number,
  side: 'input' | 'output',
): IPoint =>
  getFittingPosition(
    side === 'input' ? 0 : valveBox.maxSlots + 1,
    valveBox,
    scale,
  );

export const cleanValveBoxes = (
  valveBoxes: ValveBox[],
  zoneID: string,
): ValveBox[] =>
  valveBoxes.map((vb) => ({
    ...vb,
    zoneIDs: vb.zoneIDs.map((id) => (id === zoneID ? '' : id)),
  }));

export const removeZoneElements = (
  elements: DesignElement[],
  zone: Zone,
): DesignElement[] =>
  elements.filter(
    (el) =>
      el.uuid !== zone.valve &&
      el.uuid !== zone.valveInputFitting &&
      el.uuid !== zone.valveOutputFitting,
  );

export const removeEdgesByTargetSource = (
  edges: IEdge[],
  edgesToDelete: IEdge[],
): IEdge[] =>
  edges.filter(
    (edge) =>
      !edgesToDelete.some(
        (e) =>
          // Keep this as a search on source, target search
          (e.source === edge.source && e.target === edge.target) ||
          (e.source === edge.target && e.target === edge.source),
      ),
  );

export const removeZoneEdges = (
  edges: IEdge[],
  zone: Zone,
  valveBoxes: ValveBox[],
  mainPipe: string,
  pipeProducts: IPipeProduct[],
): { keep: IEdge[]; kill: IEdge[] } => {
  // headIds on a zone are the actual sprinkler.headUUIDs, not the element IDs,
  // so we need to map over the elements to get the actual element ids that the edges are connecting
  let newEdges = [...edges];
  const valveBox = valveBoxes.find((vb) => contains(vb.zoneIDs, zone.uuid));
  if (valveBox) {
    newEdges = removeEdgesByTargetSource(
      newEdges,
      createEdgesBetweenValveSlots(valveBox, [zone], mainPipe, pipeProducts),
    );
  }
  const keep = newEdges.filter(
    (edge) =>
      edge.source !== zone.valve &&
      edge.target !== zone.valve &&
      edge.source !== zone.valveOutputFitting &&
      edge.target !== zone.valveOutputFitting &&
      edge.source !== zone.valveInputFitting &&
      edge.target !== zone.valveInputFitting,
  );
  const kill = newEdges.filter(
    (edge) =>
      edge.source === zone.valve ||
      edge.target === zone.valve ||
      edge.source === zone.valveOutputFitting ||
      edge.target === zone.valveOutputFitting ||
      edge.source === zone.valveInputFitting ||
      edge.target === zone.valveInputFitting,
  );
  return { keep, kill };
};

export const reorderZones = (
  zones: Zone[],
  valveBoxes: ValveBox[],
  zoneOffset: number,
): Zone[] => {
  let zoneIndex = 0;
  const zoneIDsToNums: { [zoneID: string]: number } = {};
  valveBoxes.forEach((vb) => {
    vb.zoneIDs.forEach((z) => {
      if (z) {
        const zone = zones.find((zone) => zone.uuid === z);
        if (zone) {
          zoneIDsToNums[z] = zoneIndex;
          zoneIndex += 1;
        }
      }
    });
  });
  return zones.map((zone) => {
    if (zoneIDsToNums[zone.uuid] >= 0) {
      return {
        ...zone,
        orderNumber: zoneIDsToNums[zone.uuid] + zoneOffset,
      };
    } else {
      const orderNumber = zoneIndex;
      zoneIndex += 1;
      return {
        ...zone,
        orderNumber,
      };
    }
  });
};
