import { Circle } from '@shared-types';
import { takeUntil } from 'rxjs';
import { defaultStyledItem, paperItemStore } from '../helpers';
import { activateNamedLayer } from '../paper-helpers/plot.helpers';
import { ItemService } from '../polyline/ItemService';
import { registerStateHooks } from '../polyline/item.model';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { getState, refreshSelectedIDs } from '../state';
import { deletePaperItem, updatePaperItem } from '../state/renderers';
import { toolService } from '../tool.service';
import { drawCircle$ } from './drawCircle';
import { PaperCircle } from './paper-circle';

class CircleService implements ItemService<Circle> {
  radius = 0;
  init() {
    this.registerWithState();
    drawCircle$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
  }
  createItem = (): Circle => {
    const item = defaultStyledItem();
    return {
      ...item,
      itemType: 'circle',
      radius: this.radius,
      style: {
        ...item.style,
        strokeWidth: getState().strokeWidth,
      },
    };
  };
  setCustomRadius = (radius: number) => {
    this.radius = radius;
  };
  renderItemToPaper = (item: Circle, layer = LAYER_NAMES.TRACING) => {
    activateNamedLayer(layer);
    paperItemStore.set(item.uuid, new PaperCircle(item));
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  };
  registerWithState = () => {
    registerStateHooks<Circle>('circle', {
      postAddItem: (item) => {
        this.renderItemToPaper(item);
      },
      postUpdateItem: (item) => {
        updatePaperItem(item);
        if (getState().selectedIDs.includes(item.uuid)) {
          refreshSelectedIDs();
        }
      },
      postDeleteItem: (item) => {
        deletePaperItem(item.uuid);
      },
    });
  };
}

export const circleService = new CircleService();
