import { TextChunk } from '@shared-types';
import { filter, map, merge, switchMap, takeUntil, tap } from 'rxjs';
import paper from 'src/paper';

import { getColor } from '../paper-helpers/plot.helpers';
import { getState } from '../state';
import { addItem } from '../state/item-state';
import { toolService } from '../tool.service';
import { textService } from './text.service';

const createTmpRectangle = (rect: paper.Rectangle) => {
  const r = new paper.Path.Rectangle(rect);
  r.strokeColor = getColor('black');
  r.strokeWidth = 0.2;
  return r;
};
const { mouseDown$, mouseUp$, mouseDrag$ } = toolService.getEvents('text-tool');

const dragDrawTextBox$ = mouseDown$.pipe(
  filter(({ e }) => !e.modifiers.space),
  map(({ e }) => {
    const rect = new paper.Rectangle(e.point, e.point);
    const paperRect = createTmpRectangle(rect);
    return { rect, paperRect };
  }),
  switchMap(({ rect, paperRect }) =>
    mouseDrag$.pipe(
      tap((e) => {
        paperRect.remove();
        const xDiff = e.point.x - e.downPoint.x;
        const yDiff = e.point.y - e.downPoint.y;
        const maxDiff = Math.max(Math.abs(xDiff), Math.abs(yDiff));

        const xDirection = xDiff < 0 ? -1 : 1;

        rect = new paper.Rectangle(
          e.downPoint,
          new paper.Point({
            x: e.downPoint.x + maxDiff * xDirection,
            y: e.downPoint.y + 2,
          }),
        );
        paperRect = createTmpRectangle(rect);
      }),
      takeUntil(
        merge(
          mouseUp$.pipe(
            tap(() => {
              if (rect.width * rect.height > 1) {
                const newRect: TextChunk = {
                  ...textService.createItem({
                    x: rect.topLeft.x,
                    y: rect.topLeft.y + 12 / getState().scale,
                  }),
                  width: Math.round(rect.width),
                  fontSize: 12,
                };
                addItem(newRect);
                paperRect.remove();
              }
            }),
          ),
        ).pipe(
          tap(() => {
            paperRect.remove();
          }),
        ),
      ),
    ),
  ),
);

export const drawText$ = merge(dragDrawTextBox$);
