import { Circle, IPoint } from '@shared-types';
import paper from 'src/paper';
import { applyAlpha } from '../helpers';
import { getColor } from '../paper-helpers/plot.helpers';
import { getState } from '../state';
import { PaperItem } from '../tools/paper-items/paper-item';
import { circleService } from './circle.service';

export const isPaperCircle = (item: any): item is PaperCircle => {
  return item instanceof PaperCircle;
};

export class PaperCircle extends PaperItem<Circle> {
  private _group = new paper.Group();
  private _item = circleService.createItem();

  get highlight() {
    return this._group.getItem({ name: 'highlight' });
  }
  get group() {
    return this._group;
  }
  get paperItem() {
    return this._group.getItem({ name: 'child-circle' });
  }

  constructor(item: Circle) {
    super();
    this.group.name = item.itemType;
    this.group.data = { uuid: item.uuid };
    this.create(item);
  }
  getRealItem(): Circle {
    return this._item;
  }
  create(item: Circle) {
    this.group.removeChildren();
    const r = this.createCircle(item);
    this.group.addChild(r);
    if (item.locked) {
      this.group.locked = true;
    }
    if (!item.visible) {
      this.group.visible = false;
    }
    this._item = item;
  }
  createCircle(item: Circle) {
    const scale = getState().scale;
    const r = new paper.Path.Circle(item.position, item.radius);
    r.strokeWidth = (item.style.strokeWidth || 1) / scale;
    const newFillColor = applyAlpha(item.style.fillColor, item.style.fillAlpha);
    r.fillColor = newFillColor;
    const newStrokeColor = applyAlpha(
      item.style.strokeColor,
      item.style.strokeAlpha,
    );
    r.strokeColor = newStrokeColor;
    r.name = 'child-circle';
    return r;
  }
  update(item: Circle) {
    const scale = getState().scale;
    const selected = !!this.highlight;
    if (
      item.style.fillColor !== this._item.style.fillColor ||
      item.style.fillAlpha !== this._item.style.fillAlpha
    ) {
      const fillColor = applyAlpha(item.style.fillColor, item.style.fillAlpha);
      this.paperItem.fillColor = fillColor;
    }
    const strokeColor = applyAlpha(
      item.style.strokeColor,
      item.style.strokeAlpha,
    );
    this.paperItem.strokeColor = strokeColor;
    this.paperItem.strokeWidth = (item.style.strokeWidth || 1) / scale;
    this.paperItem.opacity =
      item.style.opacity !== undefined ? item.style.opacity : 1;
    this.paperItem.dashArray = (item.style.dashArray || []).map(
      (d) => d / scale,
    );
    this.paperItem.dashOffset = (item.style.dashOffset || 0) / scale;
    this.paperItem.strokeCap = item.style.strokeCap || 'butt';
    this.paperItem.strokeJoin = item.style.strokeJoin || 'miter';
    if (item.radius !== this._item.radius) {
      this.group.removeChildren();
      const newCircle = this.createCircle(item);
      this.group.addChild(newCircle);
    }
    if (
      item.position.x !== this._item.position.x ||
      item.position.y !== this._item.position.y
    ) {
      this.setPivot(item.position);
      this.setPosition(item.position);
    }
    if (item.locked !== this._item.locked) {
      this.group.locked = item.locked;
    }
    if (item.visible !== this._item.visible) {
      this.group.visible = item.visible;
    }
    this._item = item;
    this.toggleHighlight(selected);
  }
  setSelectedStatus(selected: boolean) {
    if (this.highlight) {
      this.highlight.remove();
    }
    if (selected) {
      const highlight = this.paperItem.clone();
      highlight.dashArray = [];
      highlight.strokeWidth = 0.2;
      highlight.strokeColor = getColor('#94baf7');
      highlight.fillColor = null;
      highlight.name = 'highlight';
      highlight.locked = true;
      this.group.addChild(highlight);
    }
  }
  destroy() {
    this.group.remove();
  }
  getItem() {
    return this.group;
  }
  setPosition(point: IPoint): void {
    this.group.position = new paper.Point(point);
  }
  setPivot(point: IPoint): void {
    this.group.pivot = new paper.Point(point);
  }
  // getRotation(): number {
  //   return 0;
  // }
  // scaleItem(pivot: IPoint, scale: number): void {
  //   this.group.scale(scale, pivot);
  // }
  toggleHighlight(show: boolean): void {
    this.setSelectedStatus(show);
  }
}
