import { useEffect, useRef, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { base } from 'src/styles/common';
import styled from 'styled-components';

interface Props {
  onAgree: (agreed: boolean) => void;
}

const useOnScreen = (ref, rootMargin = '0px') => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
};

export const BulkTermAgreement = ({ onAgree }: Props) => {
  const ref = useRef<HTMLDivElement>();
  const onScreen = useOnScreen(ref, '0px');
  return (
    <Form.Field>
      <ScrollableDiv>
        Bulk Design Agreement
        <br />
        <br />
        THIS AGREEMENT is effective as of acceptance of this document
        ("Effective Date") and is entered into by and between Irrigation Studio
        LLC, and Account Holder on www.irrigationstudio.com, ("Contractor")
        (collectively, the "Parties").
        <br />
        In consideration of the covenants and conditions contained herein, and
        for other good and valuable consideration, the receipt and adequacy of
        which are hereby acknowledged, the Parties agree as follows: 1. Scope of
        Work. Irrigation Studio LLC shall furnish the goods and services
        ("Deliverables"), detailed as Residential Irrigation Design up to ONE
        (1) acre, Commercial Irrigation Design up to ONE HALF (1/2) acre on
        www.irrigationstudio.com ("Packages") which is incorporated as a part of
        this Agreement. After selecting the Deliverables package on
        www.irrigationstudio.com, Contractor will then submit the design package
        and accompanying notes, pictures, base-map and/or concept diagrams under
        Contractor’s account on www.irrigationstudio.com. The turnaround time
        commences once Irrigation Studio LLC "accepts" Contractor’s selected
        design. Of the FIFTY (50) designs, all must be in the standard "Small
        Residential," "Medium Residential" or "Small Commercial" irrigation
        pricing packages on www.irrigationstudio.com. If, however, a "Large
        Residential, Large Commercial, or Multiple Points Of Connection" on
        www.irrigationstudio.com constituting a Custom Quote, a Revision is
        requested, or a Rush Order is requested, Contractor will be invoiced on
        a per project basis as applicable. Revisions for designs submitted will
        have the same turnaround time as bulk design package selected per
        revision submitted (i.e. 5 business days).
        <br />
        <br />
        2a. Price. 12 Installment Option: Pricing is based upon Contractor’s
        commitment to pay for FIFTY (50) designs. Accordingly, the initial price
        under this Agreement is $20,000.00. Contractor will pay the foregoing
        amount as follows: 1/12 ($1,666.67) of Contract Agreement each month for
        the duration of the contract. First payment will be invoiced on
        execution of this Agreement. All amounts are non-refundable including if
        Contractor does not utilize FIFTY (50) designs within the ONE (1) year
        term of this Agreement. If Contractor utilizes more than FIFTY (50)
        designs within the ONE (1) year term, the pricing on the additional
        designs is as follows: pricing will return to standard pricing structure
        on www.irrigationstudio.com, with payment made on a per design basis.
        <br />
        <br />
        2b. Price. 2 Installment Option: Pricing is based upon Contractor’s
        commitment to pay for FIFTY (50) designs. Accordingly, the initial price
        under this Agreement is $17,500.00. Contractor will pay the foregoing
        amount as follows: 50% ($8,750.00) upon execution of this Agreement and
        remaining 50% ($8,750.00) within 60 days thereafter. First payment will
        be invoiced on execution of this Agreement. All amounts are
        non-refundable including if Contractor does not utilize FIFTY (50)
        designs within the ONE (1) year term of this Agreement. If Contractor
        utilizes more than FIFTY (50) designs within the ONE (1) year term, the
        pricing on the additional designs is as follows: pricing will return to
        standard pricing structure on www.irrigationstudio.com, with payment
        made on a per design basis.
        <br />
        <br />
        2c. Price. Multiple Blocks of 50 Purchase: Contractor may purchase
        multiple blocks of 50 designs. Under this Agreement multiple blocks will
        be combined. Pricing will be combined and invoiced dependent on
        Contractors choice of Installment Option (2a) or (2b). First payment
        will be invoiced on execution of this Agreement. All amounts are
        non-refundable including if Contractor does not utilize combined total
        of multiple blocks within the ONE (1) year term of this Agreement. If
        Contractor utilizes more than combined total of multiple blocks within
        the ONE (1) year term, the pricing on the additional designs is as
        follows: pricing will return to standard pricing structure on
        www.irrigationstudio.com, with payment made on a per design basis.
        <br />
        <br />
        <br />
        3. Term. The term of this Agreement is for ONE (1) year from acceptance
        of this Agreement at which time this Agreement will expire unless
        extended by the Parties in writing under mutually agreeable provisions.{' '}
        <br />
        4. Breach. In the event of Contractor’s breach of this Agreement
        including, but not limited to, failure to make timely payments, interest
        on unpaid sums will accrue at 1.5% per month and Irrigation Studio LLC
        will be entitled to an award of all its costs, including attorneys’
        fees, associated with Contractor’s breach. Irrigation Studio LLC
        reserves the right to terminate or suspend deliverables stated in
        Contract in the event of Contractor’s breach of this Agreement. <br />
        <br />
        5. License. Irrigation Studio LLC grants to Contractor a non-exclusive,
        non-transferable, revocable license to use the Deliverables included as
        part of this Agreement. All such Deliverables and all associated
        materials are the sole property of Irrigation Studio LLC until delivered
        to Contractor and protected by copyright and other laws that protect
        intellectual property and proprietary rights. Irrigation Studio LLC does
        not grant Contractor any licenses, express or implied, to the
        intellectual property including, but not limited to, trade secrets of
        Irrigation Studio LLC. Contractor agrees to observe all copyright and
        other proprietary laws, notices, legends or other restrictions contained
        in the Deliverables and will not make any changes thereto.
        <br />
        <br />
        6. Limitation Liability; Disclaimer. TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, IN NO EVENT SHALL IRRIGATION STUDIO LLC BE LIABLE FOR
        ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
        DAMAGES INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE OR
        PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THIS AGREEMENT
        WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
        OTHERWISE, EVEN IF IRRIGATION STUDIO LLC HAS BEEN ADVISED OF THE
        POSSIBILITY OF DAMAGES. IRRIGATION STUDIO LLC MAKES NO WARRANTY, EXPRESS
        OR IMPLIED, THAT THE DESIGNS ARE MERCHANTABLE OR FIT FOR A PARTICULAR
        PURPOSE AND IRRIGATION STUDIO LLC EXPRESSLY DISCLAIMS THE SAME. IN ALL
        EVENTS, IRRIGATION STUDIO LLC’S MAXIMUM AMOUNT OF DAMAGES SHALL BE THE
        AMOUNT COLLECTED BY IRRIGATION STUDIO LLC FROM CONTRACTOR WITHIN THE
        TERM OF THIS AGREEMENT. <br />
        <br />
        7. Miscellaneous. The Parties agree that no joint venture, partnership,
        employment, or agency relationship exists between them as a result of
        this Agreement. This Agreement will bind and inure to the benefit of and
        be enforceable the Parties and their respective representatives, heirs,
        successors and assigns. This Agreement is governed by the laws of the
        State of Colorado and the Parties hereby consent to the exclusive
        jurisdiction of the courts located in Colorado. If any part of this
        Agreement is determined to be invalid or unenforceable pursuant to
        applicable law including, but not limited to, the warranty disclaimers
        and liability limitations set forth above, then the invalid or
        unenforceable provision will be deemed superseded by a valid,
        enforceable provision that most closely matches the intent of the
        original provision, and the remainder of this Agreement shall continue
        in full force and effect. Neither party shall be liable in damages or
        have the right to terminate this Agreement for any delay or default in
        performing hereunder if such delay or default is caused by conditions
        beyond its control including, but not limited to Acts of God, Government
        restrictions, wars, terrorist attacks, computer viruses, labor
        shortages, materials/supplies shortages, and/or any other cause beyond
        the reasonable control of the party whose performance is affected. This
        Agreement is not for the benefit of any third party, and shall not be
        considered to grant any right, warranty, or remedy to any third party.
        The language used in this Agreement shall be deemed to be the language
        chosen by the Parties to express their mutual intent, and no rule of
        construction against the author shall be applied against any party. Each
        party hereby acknowledges that they have had the opportunity to consult
        with professional advisors concerning this Agreement to the extent they
        desire. No modification of this Agreement shall be valid unless approved
        in writing by the parties. This Agreement constitutes the entire
        understanding between the Parties and supersedes all prior or
        contemporaneous communications and proposals, whether electronic, oral
        or written, between the Parties.
        <br />
        <br />
        WE HAVE READ AND UNDERSTAND THIS AGREEMENT, AND ACCEPT ITS TERMS AND
        CONDITIONS. <br />
        <br />
        <div className="bottom-check" ref={ref} />
      </ScrollableDiv>
      <br />
      <br />
      <Form.Checkbox
        disabled={!onScreen}
        label="I Agree"
        onChange={(_, d) => onAgree(d.checked || false)}
      />
    </Form.Field>
  );
};

const ScrollableDiv = styled.div`
  max-height: 200px;
  overflow: auto;
  border: 1px solid #ccc;
  padding: ${base}px;
`;
