import { DesignElement, IPump } from '@shared-types';
import { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { localPaper } from '../localPaper';
import { selectItems } from '../state';
import { changeElements } from '../state/changeElements';
import { PanelInputWrap, PanelWrap } from './PanelButton';

interface Props {
  element: DesignElement;
}

export const PumpTooltip = ({ element }: Props) => {
  const [pump, setPump] = useState<IPump>(element.props as IPump);

  const handleSave = (): void => {
    changeElements([
      {
        ...element,
        props: pump,
      },
    ]);
    selectItems([]);
    localPaper.project.deselectAll();
  };

  const changeOutputPSI = (e: React.ChangeEvent<HTMLInputElement>) => {
    const psi = parseFloat(e.currentTarget.value);
    setPump({
      ...pump,
      outputPSI: psi,
    });
  };
  const changeOutputGPM = (e: React.ChangeEvent<HTMLInputElement>) => {
    const psi = parseFloat(e.currentTarget.value);
    setPump({
      ...pump,
      outputGPM: psi,
    });
  };
  const changeElevation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const elevation = parseFloat(e.currentTarget.value);
    setPump({
      ...pump,
      elevation: elevation || 0,
    });
  };

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPump({
      ...pump,
      name: e.currentTarget.value,
    });
  };

  return (
    <Wrap>
      <h5>Pump Details</h5>
      <Form onSubmit={handleSave}>
        <PanelInputWrap
          label="Name"
          onChange={changeName}
          value={pump.name || 'Meter'}
        />
        <PanelInputWrap
          label="Output PSI"
          value={pump.outputPSI}
          onChange={changeOutputPSI}
        />
        <PanelInputWrap
          label="Output GPM"
          value={pump.outputGPM}
          onChange={changeOutputGPM}
        />
        <PanelInputWrap
          label="Elevation"
          value={pump.elevation || 0}
          onChange={changeElevation}
        />
        <br />
        <Button primary type="submit">
          Save
        </Button>
      </Form>
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)``;
