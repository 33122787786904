import { useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import styled from 'styled-components';
import { PanelButton } from '../../components/PanelButton';
import { deleteUserSleeves } from './state/deleteUserSleeves';

export const DeleteUserSleevesButton = () => {
  const [userDeleteOpen, setUserDeleteOpen] = useState(false);
  return (
    <Wrap>
      <PanelButton onClick={() => setUserDeleteOpen(true)}>
        Delete User Sleeves
      </PanelButton>
      <Confirm
        size="mini"
        header="Delete User-Created Sleeves"
        content="This will delete any sleeves drawn by hand"
        open={userDeleteOpen}
        confirmButton="Delete"
        onCancel={() => setUserDeleteOpen(false)}
        onConfirm={() => {
          deleteUserSleeves();
          setUserDeleteOpen(false);
        }}
      />
    </Wrap>
  );
};

const Wrap = styled.div`
  .ui.mini.modal {
    padding: 16px;
  }
`;
