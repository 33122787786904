import { IPoint, LineSegment } from '@shared-types';
import { sideOfLine } from '../helpers/geometry.helpers';

export const toDegrees = (radians: number): number => (radians * 180) / Math.PI;

export const toRadians = (angle: number): number => angle * (Math.PI / 180);

// export const roundSingle = (n: number): number => Math.round(n * 10000) / 10000;

export const distSquared = (a: IPoint, b: IPoint): number =>
  Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2);

export const getEuclideanDistance = (a: IPoint, b: IPoint): number =>
  Math.sqrt(distSquared(a, b));

// export const getSlope = (a: IPoint, b: IPoint): number =>
//   (b.y - a.y) / (b.x - a.x);

// export const getSlopeAngle = (s1: IPoint, s2: IPoint): number =>
//   (Math.atan((s2.y - s1.y) / (s2.x - s1.x)) * 180) / Math.PI;

export const getMidPoint = (p1: IPoint, p2: IPoint): IPoint => ({
  x: (p1.x + p2.x) / 2,
  y: (p1.y + p2.y) / 2,
});

export const rotateAroundPoint = (
  origin: IPoint,
  point: IPoint,
  angle: number,
): IPoint => {
  const radians = (Math.PI / 180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = cos * (point.x - origin.x) + sin * (point.y - origin.y) + origin.x;
  const ny = cos * (point.y - origin.y) - sin * (point.x - origin.x) + origin.y;
  return { x: nx, y: ny };
};

// export const linesIntersect = (
//   x1: number,
//   y1: number,
//   x2: number,
//   y2: number,
//   x3: number,
//   y3: number,
//   x4: number,
//   y4: number,
// ): any => {
//   // Check if none of the lines are of length 0
//   if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
//     return false;
//   }

//   const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

//   // Lines are parallel
//   if (denominator === 0) {
//     return false;
//   }

//   const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
//   const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

//   // is the intersection along the segments
//   if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
//     return false;
//   }

//   // Return a object with the x and y coordinates of the intersection
//   const x = x1 + ua * (x2 - x1);
//   const y = y1 + ua * (y2 - y1);

//   return { x, y };
// };

export const getPointAlongLine = (
  distance: number,
  pipe: LineSegment,
): IPoint => {
  const pipeLength = getEuclideanDistance(pipe.start, pipe.end);
  const progress = distance / pipeLength;

  // https://math.stackexchange.com/a/409721/582161
  const x = pipe.start.x + progress * (pipe.end.x - pipe.start.x);
  const y = pipe.start.y + progress * (pipe.end.y - pipe.start.y);
  return { x, y };
};
export const getTheta = (point2: IPoint, point1: IPoint): number => {
  const radians = Math.atan2(point2.y - point1.y, point1.x - point2.x);
  return radians < 0 ? radians + Math.PI * 2 : radians;
};

// export const getAngle = (a: IPoint, b: IPoint, c: IPoint): number => {
//   // must be counterclockwise
//   const aDeg = toDegrees(getTheta(a, b));
//   const cDeg = toDegrees(getTheta(c, b));
//   return cDeg >= aDeg ? cDeg - aDeg : 360 - (aDeg - cDeg);
// };

// export const getRotation = (a: IPoint, b: IPoint): number => {
//   // must be counterclockwise
//   let aDeg = toDegrees(getTheta(a, b));
//   aDeg = aDeg === 0 ? 360 : aDeg;
//   return 360 - aDeg;
// };

export const subtractPoint = (a: IPoint, b: IPoint): IPoint => ({
  x: a.x - b.x,
  y: a.y - b.y,
});
export const addPoint = (a: IPoint, b: IPoint): IPoint => ({
  x: a.x + b.x,
  y: a.y + b.y,
});
// export const multiplyPoint = (a: IPoint, k: number): IPoint => ({
//   x: a.x * k,
//   y: a.y * k,
// });

// export const dot2D = (v1: IPoint, v2: IPoint): number =>
//   v1.x * v2.x + v1.y * v2.y;

// // AB x CD
// export const cross2D = (A: IPoint, B: IPoint, C: IPoint, D: IPoint): number =>
//   (B.x - A.x) * (D.y - C.y) - (D.x - C.x) * (B.y - A.y);

// export const collinear = (a: IPoint, b: IPoint, c: IPoint): boolean =>
//   // "Return true if a, b, and c all lie on the same line."
//   (b.x - a.x) * (c.y - a.y) == (c.x - a.x) * (b.y - a.y);

// export const manhattanDist = (a: IPoint, b: IPoint): number => {
//   const dx = Math.abs(a.x - b.x);
//   const dy = Math.abs(a.y - b.y);
//   return dx + dy;
// };
export const getClosestPointInArray = (points: IPoint[], somePoint: IPoint) => {
  let least = 10000000;
  let newPoint = somePoint;
  points.forEach((p: IPoint) => {
    const distance = getEuclideanDistance(p, somePoint);
    if (distance < least) {
      least = distance;
      newPoint = p;
    }
  });
  return newPoint;
};

/**
 * Returns a point a given distance along a linestring or polygon
 * @param  {IPoint[]} points
 * @param  {number} distanceGoal
 * @param  {boolean} closed
 */
export const traversePoly = (
  points: IPoint[],
  distanceGoal: number,
  closed: boolean,
  startIndex: number,
): IPoint | null => {
  // assume points are ordered counter clockwise
  let pointAIndex = startIndex || 0;
  let distanceTravelled = 0;
  let pointsTried = 0;
  while (true) {
    const reachedBeginningOfPoly = closed && pointsTried === points.length;
    const reachedEndofLinestring = !closed && pointsTried === points.length - 1;
    if (reachedBeginningOfPoly || reachedEndofLinestring) {
      console.error('the line is too short');
      return null;
    }
    const pointBIndex = pointAIndex === points.length - 1 ? 0 : pointAIndex + 1;
    const thisPoint = points[pointAIndex];
    const nextPoint = points[pointBIndex];

    const segmentLength = getEuclideanDistance(thisPoint, nextPoint);
    const distanceRemaining = distanceGoal - distanceTravelled;

    if (distanceTravelled + segmentLength < distanceGoal) {
      // this line is not enough, keep moving
      pointAIndex = pointBIndex;
      distanceTravelled += segmentLength;
      pointsTried++;
    } else {
      const reachedGoal = distanceTravelled + segmentLength === distanceGoal;
      // happened to land on an endpoint or this is the line that should have the new point, get it!
      return reachedGoal
        ? nextPoint
        : getPointAlongLine(distanceRemaining, {
            start: thisPoint,
            end: nextPoint,
          });
    }
  }
};

export const subtractPoints = (p1: IPoint, p2: IPoint) => {
  return { x: p1.x - p2.x, y: p1.y - p2.y };
};
export const addPoints = (p1: IPoint, p2: IPoint) => {
  return { x: p1.x + p2.x, y: p1.y + p2.y };
};

export const getOppositePoint = (center: IPoint, p1: IPoint): IPoint => {
  const angle = getAngleBetweenPoints(center, p1);
  return {
    x: center.x + getEuclideanDistance(center, p1) * Math.cos(angle + Math.PI),
    y: center.y + getEuclideanDistance(center, p1) * Math.sin(angle + Math.PI),
  };
};
export const getArcCenter = (
  p1: IPoint,
  p2: IPoint,
  p3: IPoint,
): IPoint | null => {
  const bisect1 = perpendicularBisector(p1, p2);
  const bisect2 = perpendicularBisector(p2, p3);

  const intersection = whereWillLineSegmentsCross(
    bisect1[0],
    bisect1[1],
    bisect2[0],
    bisect2[1],
  );
  return intersection;
};
export const perpendicularBisector = (p1: IPoint, p2: IPoint): IPoint[] => {
  const a = subtractPoints(p1, p2);
  const b = dividePoint(a, 2);
  const c = addPoints(b, p2);
  const d = { x: -a.y, y: a.x };
  const e = dividePoint(d, 2);
  const f = addPoints(c, e);
  const g = subtractPoints(c, e);
  return [f, g];
};

export const dividePoint = (p1: IPoint, n: number) => {
  return { x: p1.x / n, y: p1.y / n };
};
export const whereWillLineSegmentsCross = (
  p1: IPoint,
  p2: IPoint,
  p3: IPoint,
  p4: IPoint,
): IPoint | null => {
  const a1 = p2.y - p1.y;
  const b1 = p1.x - p2.x;
  const c1 = a1 * p1.x + b1 * p1.y;
  const a2 = p4.y - p3.y;
  const b2 = p3.x - p4.x;

  const c2 = a2 * p3.x + b2 * p3.y;
  const determinant = a1 * b2 - a2 * b1;

  if (determinant === 0) {
    // The lines are parallel.
    return null;
  } else {
    const x = (b2 * c1 - b1 * c2) / determinant;
    const y = (a1 * c2 - a2 * c1) / determinant;
    return { x, y };
  }
};
export const getNextArcCenter = (
  firstCenter: IPoint,
  arcEnd: IPoint,
  newPoint: IPoint,
) => {
  const bisect = perpendicularBisector(arcEnd, newPoint);
  const intersection = whereWillLineSegmentsCross(
    firstCenter,
    arcEnd,
    bisect[0],
    bisect[1],
  );
  return intersection;
};
export const getPointOnCircleBetweenTwoPoints = (
  center: IPoint,
  p1: IPoint,
  p2: IPoint,
): IPoint => {
  const angle1 = getAngleBetweenPoints(center, p1);
  const angle2 = getAngleBetweenPoints(center, p2);
  const angle = (angle1 + angle2) / 2;
  return {
    x: center.x + getEuclideanDistance(center, p1) * Math.cos(angle),
    y: center.y + getEuclideanDistance(center, p1) * Math.sin(angle),
  };
};
export const getArcTangentAngle = (arc: paper.Path.Arc, end = true): number => {
  // Get the derivative of the arc's curve function
  const derivative = arc.getTangentAt(end ? arc.length : 0);
  // Calculate the angle between the tangent vector and the x-axis
  const angle = Math.atan2(derivative.y, derivative.x);
  // Convert the angle to degrees
  return angle * (180 / Math.PI);
};
export const getAngleBetweenPoints = (
  center: IPoint,
  point: IPoint,
): number => {
  return Math.atan2(point.y - center.y, point.x - center.x);
};
export const snapTo45Degrees = (
  currentAngle: number,
  referenceAngle = 0,
): number => {
  // Determine how many 45-degree segments the current angle spans
  const segments = Math.round((currentAngle - referenceAngle) / (Math.PI / 4));
  return referenceAngle + segments * (Math.PI / 4);
};

export const getArcMidPoint = (
  from: IPoint,
  through: IPoint,
  to: IPoint,
): IPoint => {
  const center = getArcCenter(from, through, to);
  if (!center) throw new Error('Center is null');
  const radius = Math.hypot(from.x - center.x, from.y - center.y);
  const isLeft = sideOfLine(from, through, to);
  let angleFrom = getAngleBetweenPoints(center, from);
  let angleTo = getAngleBetweenPoints(center, to);
  if (isLeft > 0) {
    angleFrom = getAngleBetweenPoints(center, to);
    angleTo = getAngleBetweenPoints(center, from);
  }
  const midAngle = midpointAngle(angleFrom, angleTo);
  const p = getPointOnCircle(center, radius, midAngle);
  return p;
};
const midpointAngle = (angle1: number, angle2: number): number => {
  let diff = angle2 - angle1;
  if (diff < 0) diff += 2 * Math.PI;
  return angle1 + diff / 2;
};
export const getPointOnCircle = (
  center: IPoint,
  radius: number,
  angle: number,
): IPoint => {
  return {
    x: center.x + radius * Math.cos(angle),
    y: center.y + radius * Math.sin(angle),
  };
};
