import { PDFDocument } from 'pdf-lib';
import { Content } from 'pdfmake/interfaces';
import { createPDFMakePage } from './createPDFMakePage';
import { standardDefinition } from './pdf.helpers';

export async function createValveboxesPagePDF(): Promise<PDFDocument | void> {
  const definitionFunc = async () => standardDefinition();
  const getContent = async () => valveBoxesPages();
  return createPDFMakePage(definitionFunc, getContent);
}
const generateValveDiagrams = () => {
  const valveDiagrams = document.querySelectorAll('.valve-diagram');
  if (!valveDiagrams.length) throw new Error('No valve diagrams found');
  const valveCharts: string[] = [];
  valveDiagrams.forEach((diagram) => valveCharts.push(diagram.outerHTML));
  return valveCharts;
};
export const valveBoxesPages = (): Content[] => {
  const valveCharts = generateValveDiagrams();
  return [
    { text: 'Valve Box Diagrams', style: 'header' },
    {
      text: `These diagrams are a close-up representation of the valve box installation configuration.`,
      margin: [0, 0, 0, 18],
    },
    {
      stack: valveCharts.map((chart, i) => {
        return [
          {
            text: `Valve Box ${i + 1}`,
            fontSize: 12,
          },
          {
            svg: chart || '<svg></svg>',
            margin: [0, 18, 0, 36],
          },
        ];
      }),
    },
  ];
};
