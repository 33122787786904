import { DesignElement, IBackflowProduct } from '@shared-types';
import { useState } from 'react';
import styled from 'styled-components';
import { uniq } from 'underscore';
import { useDesignStore } from '../state';
import { changeElements } from '../state/changeElements';
import { PanelSelect, PanelWrap } from './PanelButton';

interface Props {
  element: DesignElement;
}
const stringifyBackflow = (backflow: IBackflowProduct) =>
  `${backflow.brand} ${backflow.name} ${backflow.size}"`;

export const BackflowTooltip = ({ element }: Props) => {
  const [backflow, setBackflow] = useState<string>(
    stringifyBackflow(element.props as IBackflowProduct),
  );
  const backflowProducts = useDesignStore((state) => state.backflowProducts);

  const doSetBackflow = (stringedBF: string, backflows: IBackflowProduct[]) => {
    const bf = backflows.find((bf) => stringifyBackflow(bf) === stringedBF);
    if (bf) {
      const newBackflow = {
        ...element,
        props: bf,
      };
      changeElements([newBackflow]);
      setBackflow(stringedBF);
    }
  };

  const backflowOptions = (backflows: IBackflowProduct[]): string[] =>
    uniq(backflows.map((bf) => stringifyBackflow(bf)));

  return (
    <Wrap>
      <h5>Backflow details</h5>
      <PanelSelect
        options={backflowOptions(backflowProducts).map((bf) => ({
          value: bf,
          text: bf,
        }))}
        value={backflow}
        onChange={(_e, { value }) =>
          doSetBackflow(value as string, backflowProducts)
        }
        label="Backflow Product"
      />
    </Wrap>
  );
};
const Wrap = styled(PanelWrap)``;
