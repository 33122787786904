import { setState } from './state-basics';

export const setTitle = (payload: string) => {
  setState({ title: payload });
};
export const setClientAddress = (payload: string) => {
  setState({ clientAddress: payload });
};
export const setContractorName = (payload: string) => {
  setState({ contractorName: payload });
};
export const setContractorAddress = (payload: string) => {
  setState({ contractorAddress: payload });
};
export const setContractorLogo = (payload: string) => {
  setState({ contractorLogoURL: payload });
};
export const setNorthRotation = (payload: number) => {
  setState({ northRotation: payload });
};
