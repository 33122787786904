import {
  BulkContractUI,
  Catalog,
  IValveProduct,
  InvoiceUI,
  NotificationUI,
  ProjectData,
  ProjectUI,
  Query,
  UserUI,
} from '@shared-types';
import { createContext, useEffect, useState } from 'react';
import _, { uniq } from 'underscore';
import {
  fetchBackflows,
  fetchFlowKits,
  fetchPipes,
  fetchPumps,
  fetchValves,
  prvProducts,
} from '../../pages/Workbench/services/design.service';
import { CustomEventType } from '../../pages/Workbench/shared/workbench-enums';
import {
  getBulkContracts,
  newBulkContract,
} from '../../services/bulk-contract.service';
import { getAllInvoices } from '../../services/invoice.service';
import {
  getLastProject,
  getProjects,
  newProject,
  setProjectBasemapper,
  setProjectDesigner,
} from '../../services/project.service';
import {
  editUser,
  getBasemappers,
  getDesigners,
  getUsers,
} from '../../services/user.service';
import { NOTIFICATION_DURATION_MS } from '../../shared';

export const PortalContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  projects: [] as ProjectUI[],
  projectsCount: 0,
  bulkContracts: [] as BulkContractUI[],
  bulkContractsCount: 0,
  users: [] as UserUI[],
  basemappers: [] as UserUI[],
  designers: [] as UserUI[],
  invoices: [] as InvoiceUI[],
  invoicesCount: 0,
  usersCount: 0,
  notifications: [] as NotificationUI[],
  catalog: {
    pipes: [],
    valves: [],
    backflows: [],
    prvs: [],
    brands: [],
    flowKits: [],
    pumps: [],
  } as Catalog,
  loadingProjects: false,
  loadingUsers: false,
  loadingBasemappers: false,
  loadingDesigners: false,
  loadingInvoices: false,
  loadingBulkContracts: false,
  loadingCatalog: false,
  lastProject: null as ProjectUI | null,
  loadProjects: (query?: Query) => {},
  loadLastProject: () => {},
  loadInvoices: (query?: Query) => {},
  loadBulkContracts: (query?: Query) => {},
  loadCatalog: () => {},
  loadUsers: (query?: Query) => {},
  loadAllUsers: () => {},
  loadBasemappers: () => {},
  loadDesigners: () => {},
  updateUser: (user: UserUI) => {},
  updateBasemapper: (basemapperId: number, projectId: number) => {},
  updateDesigner: (designerId: number, projectId: number) => {},
  createProject: (project: ProjectData) => {},
  createBulkContract: (payments: number, title: string, count: number) => {},
  pushNotification: (notification: NotificationUI) => {},
  /* eslint-enable @typescript-eslint/no-unused-vars */
});

export const PortalConsumer = PortalContext.Consumer;

export const PortalProvider = (props: any) => {
  const [lastProject, setLastProject] = useState<ProjectUI | null>(null);
  const [projects, setProjects] = useState<ProjectUI[]>([]);
  const [projectsCount, setProjectsCount] = useState<number>(0);
  const [bulkContractsCount, setBulkContractsCount] = useState<number>(0);
  const [bulkContracts, setBulkContracts] = useState<BulkContractUI[]>([]);
  const [users, setUsers] = useState<UserUI[]>([]);
  const [basemappers, setBasemappers] = useState<UserUI[]>([]);
  const [designers, setDesigners] = useState<UserUI[]>([]);
  const [invoices, setInvoices] = useState<InvoiceUI[]>([]);
  const [usersCount, setUsersCount] = useState<number>(0);
  const [invoicesCount, setInvoicesCount] = useState<number>(0);
  const [catalog, setCatalog] = useState<Catalog>({
    pipes: [],
    valves: [],
    backflows: [],
    flowKits: [],
    prvs: [],
    brands: [],
    pumps: [],
  });
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [loadingBasemappers, setLoadingBasemappers] = useState<boolean>(false);
  const [loadingDesigners, setLoadingDesigners] = useState<boolean>(false);
  const [loadingBulkContracts, setLoadingBulkContracts] =
    useState<boolean>(false);
  const [loadingInvoices, setLoadingInvoices] = useState<boolean>(false);
  const [loadingCatalog, setLoadingCatalog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>([]);

  const pushNotification = (notification: NotificationUI) => {
    setNotifications([notification]);
    setTimeout(() => setNotifications([]), NOTIFICATION_DURATION_MS);
  };

  const pushErrorNotification = (message: string) => {
    pushNotification({
      type: 'error',
      message,
      lengthMs: 10000,
    });
  };
  const pushSuccessNotification = (message: string) => {
    pushNotification({
      type: 'success',
      message,
      lengthMs: 5000,
    });
  };

  const getValves = async (): Promise<IValveProduct[]> => {
    const v = await fetchValves();
    return _(v.valves)
      .chain()
      .sortBy((valve) => valve.size)
      .sortBy((valve) => valve.name)
      .value();
  };
  const getPipes = async () => {
    const v = await fetchPipes();
    return _(v.pipes)
      .chain()
      .sortBy((pipe) => pipe.size)
      .sortBy((pipe) => pipe.series)
      .value();
  };

  const getBackflows = async () => {
    const v = await fetchBackflows();
    return _(v.backflows)
      .chain()
      .sortBy((backflow) => backflow.size)
      .sortBy((backflow) => backflow.name)
      .value();
  };

  const getFlowKits = async () => {
    const v = await fetchFlowKits();
    return _(v.flowKits)
      .chain()
      .sortBy((fk) => fk.size)
      .sortBy((fk) => fk.name)
      .value();
  };
  const getPumps = async () => {
    const v = await fetchPumps();
    return _(v.pumps)
      .chain()
      .sortBy((pump) => pump.inputGPM)
      .sortBy((pump) => pump.name)
      .value();
  };

  const loadCatalog = async () => {
    try {
      if (!catalog.pipes.length) {
        setLoadingCatalog(true);
        const valves = await getValves();
        const pipes = await getPipes();
        const backflows = await getBackflows();
        const flowKits = await getFlowKits();
        const pumps = await getPumps();
        setLoadingCatalog(false);
        setCatalog({
          valves,
          pipes,
          backflows,
          flowKits,
          pumps,
          prvs: prvProducts,
          brands: uniq(valves.map((v) => v.brand)),
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    // loadCatalog()
    listenForNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listenForNotifications = () => {
    window.addEventListener(CustomEventType.GLOBAL_NOTIFICATION, (d: any) => {
      pushNotification(d.detail as NotificationUI);
    });
  };

  const loadProjects = async (query?: Query): Promise<void> => {
    try {
      setLoadingProjects(true);
      const { projects, total } = await getProjects(query);
      setLoadingProjects(false);
      setProjects(projects);
      setProjectsCount(total);
    } catch (err) {
      setLoadingProjects(false);
      pushErrorNotification('Failed to load Projects');
      console.error(err);
    }
  };

  const loadLastProject = async (): Promise<void> => {
    try {
      const { project } = await getLastProject();
      setLastProject(project);
    } catch (err) {
      console.error(err);
    }
  };

  const loadInvoices = async (query?: Query): Promise<void> => {
    try {
      setLoadingInvoices(true);
      const { invoices, total } = await getAllInvoices(query);
      setLoadingInvoices(false);
      setInvoices(invoices);
      setInvoicesCount(total);
    } catch (err) {
      setLoadingInvoices(false);
      pushErrorNotification('Failed to load Invoices');
      console.error(err);
    }
  };

  const loadBulkContracts = async (query?: Query): Promise<void> => {
    try {
      setLoadingBulkContracts(true);
      const { bulkContracts, total } = await getBulkContracts(query);
      setLoadingBulkContracts(false);
      setBulkContracts(bulkContracts);
      setBulkContractsCount(total);
    } catch (err) {
      setLoadingBulkContracts(false);
      pushErrorNotification('Failed to load Bulk Contracts');
      console.error(err);
    }
  };

  const createProject = async (project: ProjectData) => {
    try {
      await newProject(project);
      await loadProjects();
      pushSuccessNotification('Project Created Successfully');
    } catch (err) {
      pushErrorNotification('Failed to create Project');
      console.error(err);
    }
  };

  const createBulkContract = async (
    payments: number,
    title: string,
    count: number,
  ) => {
    try {
      setLoadingBulkContracts(true);
      await newBulkContract(payments, title, count);
      await loadBulkContracts();
      pushSuccessNotification('Bulk Contract Created Successfully');
    } catch (err) {
      pushErrorNotification('Failed to create Bulk Contract');
      console.error(err);
    }
  };

  const loadUsers = async (query?: Query) => {
    try {
      setLoadingUsers(true);
      const { users, total } = await getUsers(query);
      setUsers(users);
      setUsersCount(total);
      setLoadingUsers(false);
    } catch (err) {
      setLoadingUsers(false);
      pushErrorNotification('Failed to load Users');
      console.error(err);
    }
  };

  // TODO: what other way can we use to decide assignees for the email field search?
  const loadAllUsers = async () => {
    try {
      setLoadingUsers(true);
      const { users, total } = await getUsers();
      setUsers(users);
      setUsersCount(total);
      setLoadingUsers(false);
    } catch (err) {
      setLoadingUsers(false);
      pushErrorNotification('Failed to load Users');
      console.error(err);
    }
  };

  const loadBasemappers = async () => {
    try {
      setLoadingBasemappers(true);
      const { users } = await getBasemappers();
      setBasemappers(users);
      setLoadingBasemappers(false);
    } catch (err) {
      setLoadingBasemappers(false);
      pushErrorNotification('Failed to load Basemappers');
      console.error(err);
    }
  };

  const loadDesigners = async () => {
    try {
      setLoadingDesigners(true);
      const { users } = await getDesigners();
      setDesigners(users);
      setLoadingDesigners(false);
    } catch (err) {
      setLoadingDesigners(false);
      pushErrorNotification('Failed to load Designers');
      console.error(err);
    }
  };

  const updateUser = async (user: UserUI) => {
    try {
      await editUser(user);
      await loadUsers();
      pushSuccessNotification('Successfully updated User');
    } catch (err) {
      pushErrorNotification('Failed to update User');
      console.error(err);
    }
  };

  const updateBasemapper = async (basemapperId: number, projectId: number) => {
    try {
      await setProjectBasemapper(basemapperId, projectId);
      pushSuccessNotification('Successfully updated Basemapper');
    } catch (err) {
      pushErrorNotification('Failed to update Basemapper');
      console.error(err);
    }
  };

  const updateDesigner = async (designerId: number, projectId: number) => {
    try {
      await setProjectDesigner(designerId, projectId);
      pushSuccessNotification('Successfully updated Designer');
    } catch (err) {
      pushErrorNotification('Failed to update Designer');
      console.error(err);
    }
  };

  return (
    <PortalContext.Provider
      value={{
        projects,
        projectsCount,
        users,
        basemappers,
        designers,
        usersCount,
        bulkContracts,
        bulkContractsCount,
        loadInvoices,
        invoices,
        invoicesCount,
        loadProjects,
        loadCatalog,
        loadBulkContracts,
        loadingCatalog,
        loadingUsers,
        loadingBasemappers,
        loadingDesigners,
        loadingProjects,
        loadingBulkContracts,
        loadingInvoices,
        loadUsers,
        loadAllUsers,
        loadBasemappers,
        loadDesigners,
        updateUser,
        updateBasemapper,
        updateDesigner,
        createProject,
        createBulkContract,
        catalog,
        lastProject,
        loadLastProject,
        notifications,
        pushNotification,
      }}
    >
      {props.children}
    </PortalContext.Provider>
  );
};
