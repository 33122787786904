import { DesignElement, SprinklerBase } from '@shared-types';
import { Button, Grid, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { uniq } from 'underscore';
import { deleteSprinklerBase, useDesignStore } from '../../state';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';
import { SelectHead } from './SelectHead';

export const HeadList = (): JSX.Element => {
  const sprinklerBases = useDesignStore((state) => state.sprinklerBases);
  const elements = useDesignStore((state) => state.elements);

  const handleDeleteBase = (id: string) => {
    deleteSprinklerBase(id);
  };

  const differentPSIs = (bases: SprinklerBase[]): boolean =>
    uniq(bases.map((b) => b.outputPSI)).length > 1;

  const canDelete = (baseUUID: string, elements: DesignElement[]): boolean =>
    elements.filter(isSprinkler).every((s) => s.props.base.uuid !== baseUUID);

  return (
    <Wrap>
      <Grid divided={true} columns={2}>
        <Grid.Column width={8}>
          <SelectHead />
        </Grid.Column>
        <Grid.Column width={8}>
          {differentPSIs(sprinklerBases) && (
            <Message color="red">
              <Message.Header>Warning!</Message.Header>
              <p>You have heads with different PSIs. This may cause errors.</p>
            </Message>
          )}
          {sprinklerBases.length} Sprinkler Bases Selected
          <br />
          <br />
          <em>
            Note: sprinklers that are currently being used in the design cannot
            be deleted
          </em>
          <br />
          <br />
          <Grid celled>
            <Grid.Row>
              <Grid.Column width={2}></Grid.Column>
              <Grid.Column width={3}>Model</Grid.Column>
              <Grid.Column width={3}>Output PSI</Grid.Column>
              <Grid.Column width={8}>Nozzle</Grid.Column>
            </Grid.Row>
            {sprinklerBases.map((base: SprinklerBase) => {
              const disabled = !canDelete(base.uuid, elements);
              return (
                <Grid.Row key={base.uuid}>
                  <Grid.Column width={2}>
                    <Button
                      icon="trash"
                      size="tiny"
                      basic
                      disabled={disabled}
                      color={disabled ? 'grey' : 'red'}
                      onClick={handleDeleteBase.bind(null, base.uuid)}
                    />
                  </Grid.Column>
                  <Grid.Column width={3}>{base.headModel}</Grid.Column>
                  <Grid.Column width={3}>
                    <strong>{base.outputPSI}</strong>
                  </Grid.Column>
                  <Grid.Column width={8}>{base.nozzleModel}</Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Grid.Column>
      </Grid>
    </Wrap>
  );
};
const Wrap = styled.div`
  .nozzle {
    display: inline-block;
  }
  li {
    margin-bottom: 8px;
    button {
      margin-right: 8px;
    }
  }
`;
