import { Rectangle } from '@shared-types';

import { takeUntil } from 'rxjs';
import { defaultStyledItem, paperItemStore } from '../helpers';
import { activateNamedLayer } from '../paper-helpers/plot.helpers';
import { ItemService } from '../polyline/ItemService';
import { registerStateHooks } from '../polyline/item.model';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { getState, refreshSelectedIDs } from '../state';
import { deletePaperItem, updatePaperItem } from '../state/renderers';
import { toolService } from '../tool.service';
import { drawRectangle$ } from './drawRectangle';
import { PaperRectangle } from './paper-rectangle';

class RectangleService implements ItemService<Rectangle> {
  init() {
    this.registerWithState();
    drawRectangle$.pipe(takeUntil(toolService.destroyTools$)).subscribe();
  }
  createItem = (): Rectangle => {
    const item = defaultStyledItem();
    return {
      ...item,
      itemType: 'rectangle',
      width: 0,
      height: 0,
      style: {
        ...item.style,
        strokeWidth: getState().strokeWidth,
      },
    };
  };
  renderItemToPaper = (item: Rectangle, layer = LAYER_NAMES.TRACING) => {
    activateNamedLayer(layer);
    paperItemStore.set(item.uuid, new PaperRectangle(item));
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  };
  registerWithState = () => {
    registerStateHooks<Rectangle>('rectangle', {
      postAddItem: (item) => {
        this.renderItemToPaper(item);
      },
      postUpdateItem: (item) => {
        updatePaperItem(item);
        if (getState().selectedIDs.includes(item.uuid)) {
          refreshSelectedIDs();
        }
      },
      postDeleteItem: (item) => {
        deletePaperItem(item.uuid);
      },
    });
  };
}

export const rectangleService = new RectangleService();
