import { DesignElement, DesignElementMap, IPoC } from '@shared-types';
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { compact, contains } from 'underscore';
import { IPOCDirectedGraph } from '../../../../../shared-types/pocDirectedGraph.helper';
import { isPlant } from '../plants/plants';
import { getMidPoint } from '../shared/geometry';
import {
  getState,
  pocGraphByIndex,
  pocGraphByNode,
  useDesignStore,
} from '../state';
import { isSprinkler } from '../tools/paper-items/paper-sprinkler';

interface Props {
  classTitle: string;
  graphIndex: number;
}

interface GraphType {
  id: string;
  type: string;
  children: GraphType[] | null;
}

export const renderTree = (
  dg: IPOCDirectedGraph,
  elementCache: DesignElementMap,
  id?: string,
): GraphType | null => {
  let root = id ? id : dg.pocRoot;
  const el = elementCache[root];
  const rootKids = dg.graph[root];
  if (rootKids) {
    const children = [...rootKids].map((s) => renderTree(dg, elementCache, s));
    return { id: root, type: el.type, children: compact(children) };
  } else {
    return null;
  }
};

export const TreeDiagram = ({ classTitle, graphIndex }: Props) => {
  const treeRef = useRef<any>();
  const elements = useDesignStore((state) => state.elements);
  const edges = useDesignStore((state) => state.edges);
  const zones = useDesignStore((state) => state.zones);
  const groups = useDesignStore((state) => state.groups);
  const plants = useDesignStore((state) => state.items.filter(isPlant));
  const pocGraphs = useDesignStore((state) => state.pocGraphs);
  const elementCache = useDesignStore((state) => state.elementCache);
  const selectedItems = useDesignStore((state) => state.selectedItems);
  useEffect(() => {
    const { pipeProducts, valveProducts, backflowProducts } = getState();
    if (!selectedItems.length && treeRef.current) {
      d3.select(treeRef.current).select('*').remove();

      const width = treeRef.current.clientWidth;
      const height = treeRef.current.clientHeight;
      const padding = 60;
      const paddedWidth = width - padding;
      const paddedHeight = height - padding;
      const dg = pocGraphByIndex(graphIndex, pocGraphs);
      if (dg) {
        const hierarchicalTree = renderTree(dg, elementCache);
        if (hierarchicalTree) {
          const hierarchy = d3.hierarchy<GraphType>(hierarchicalTree);
          const d3tree = d3
            .tree<GraphType>()
            .size([paddedWidth, paddedHeight])
            .separation((a, b) => (a.parent === b.parent ? 1 : 2));
          const root = d3tree(hierarchy);
          // const d3Cluster = d3
          //   .cluster<GraphType>()
          //   .size([paddedWidth, paddedHeight])
          // const root = d3Cluster(hierarchy)
          const svg = d3.select(treeRef.current).append('svg');
          svg.attr('width', width);
          svg.attr('height', height);
          svg.attr('class', classTitle);
          const g = svg.append('g').attr('transform', 'translate(10,30)');

          g.selectAll('.link')
            .data(root.links())
            .enter()
            .append('line')
            .attr('class', 'link')
            .attr('x1', (d) => d.source.x)
            .attr('y1', (d) => d.source.y)
            .attr('x2', (d) => d.target.x)
            .attr('y2', (d) => d.target.y)
            .attr('stroke', '#999')
            .attr('stroke-width', 0.5);

          g.selectAll('.link-size')
            .data(root.links())
            .enter()
            .append('text')
            .attr('class', 'link-size')
            .attr('x', (d) => getMidPoint(d.source, d.target).x)
            .attr('y', (d) => getMidPoint(d.source, d.target).y)
            .attr('font-size', '5px')
            .attr('font-weight', 'normal')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'central')
            .attr('font-family', 'sans-serif')
            .text((d) => {
              const edge = edges.find(
                (edge) =>
                  edge.source === d.source.data.id &&
                  edge.target === d.target.data.id,
              );
              const parentEdge = edges.find(
                (e) => e.target === d.source.data.id,
              );
              if (edge && edge.pipe) {
                const pipe = pipeProducts.find((p) => p.uuid === edge.pipe);
                const parentPipe = pipeProducts.find(
                  (p) => p.uuid === (parentEdge ? parentEdge.pipe : ''),
                );
                if (!d.source.parent && pipe) {
                  return `${pipe.size}"`;
                } else if (
                  parentEdge &&
                  parentPipe &&
                  pipe &&
                  parentPipe.size > pipe.size
                ) {
                  return `${pipe.size}"`;
                } else {
                  return '';
                }
              }
              return 'n/a';
            });

          // general nodes
          g.selectAll('circle.node')
            .data(root.descendants().filter((d) => d.data.type !== 'valve'))
            .enter()
            .append('circle')
            .classed('node', true)
            .attr('cx', (d) => d.x)
            .attr('cy', (d) => d.y)
            .attr('r', (d) =>
              isSprinkler(d.data as unknown as DesignElement) ? 1.5 : 0.9,
            )
            .attr('fill', (d) =>
              isSprinkler(d.data as unknown as DesignElement)
                ? 'steelblue'
                : '#333',
            );

          // valves
          g.selectAll('circle.valve')
            .data(root.descendants().filter((d) => d.data.type === 'valve'))
            .enter()
            .append('circle')
            .classed('valve', true)
            .attr('cx', (d) => d.x)
            .attr('cy', (d) => d.y)
            .attr('r', 3)
            .attr('fill', (d) => {
              const zone = zones.find((z) => z.valve === d.data.id);
              return zone ? zone.color : '#000';
            });

          g.selectAll('.zonenum')
            .data(root.descendants().filter((d) => d.data.type === 'valve'))
            .enter()
            .append('text')
            .attr('class', 'zonenum')
            .attr('x', (d) => d.x)
            .attr('y', (d) => d.y - 7)
            .text((d) => {
              const zone = zones.find((z) => z.valve === d.data.id);
              return zone
                ? `${zone.isDrip || zone.plantIds.length > 0 ? 'D' : ''}${
                    zone.orderNumber + 1
                  }`
                : 'z';
            })
            .attr('font-family', 'sans-serif')
            .attr('font-weight', 'bold')
            .attr('font-size', '6px')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'central');

          let worstResidual = 10000;
          g.selectAll('.loss')
            .data(
              root
                .descendants()
                .filter((d) => !d.children && d.data.type === 'sprinkler')
                .map((d) => {
                  const zone = zones.find((z) =>
                    contains(z.headIds, d.data.id),
                  );
                  let residual = 0;
                  if (zone) {
                    const dg = pocGraphByNode(zone.valve, pocGraphs);
                    if (dg) {
                      const losses = dg.lossesAtZone(
                        zone,
                        pipeProducts,
                        valveProducts,
                        backflowProducts,
                        edges,
                      );
                      const thisLoss = losses.find(
                        (loss) => loss.candidateID === d.data.id,
                      );
                      if (thisLoss) {
                        residual = thisLoss.residualPSI;
                        if (thisLoss.residualPSI < worstResidual) {
                          worstResidual = thisLoss.residualPSI;
                        }
                      }
                    }
                  }
                  const group = groups.find((g) =>
                    g.headIds.includes(d.data.id),
                  );
                  if (group) {
                    const dg = pocGraphByNode(
                      group.headIds[0] || '',
                      pocGraphs,
                    );
                    if (dg) {
                      const losses = dg.lossesAtGroup(
                        group,
                        pipeProducts,
                        backflowProducts,
                        edges,
                      );
                      const thisLoss = losses.find(
                        (loss) => loss.candidateID === d.data.id,
                      );
                      if (thisLoss) {
                        residual = thisLoss.residualPSI;
                        if (thisLoss.residualPSI < worstResidual) {
                          worstResidual = thisLoss.residualPSI;
                        }
                      }
                    }
                  }

                  return {
                    ...d,
                    residual,
                  };
                }),
            )
            .enter()
            .append('text')
            .attr('class', 'loss')
            .attr('x', (d) => d.x + 3)
            .attr('y', (d) => d.y)
            // .text(d => `${getLeafLateralLoss(d.data.id, tree, gpm, zones, plants).toFixed(1)}`)
            .text((d) => d.residual.toFixed(2))
            .attr('font-family', 'sans-serif')
            .attr('font-weight', (d) =>
              d.residual === worstResidual ? 'bold' : 'normal',
            )
            .attr('fill', (d) =>
              d.residual < 0
                ? 'red'
                : d.residual === worstResidual
                  ? 'steelblue'
                  : '#333333',
            )
            .attr('font-size', '6px')
            .attr('text-anchor', 'start')
            .attr('alignment-baseline', 'central')
            .attr('transform', (d) => `rotate(90,${d.x},${d.y}) `);

          g.selectAll('.gpm')
            .data(root.descendants())
            .enter()
            .append('text')
            .attr('class', 'gpm')
            .attr('x', (d) => d.x)
            .attr('y', (d) => d.y - 4)
            .text((d) => {
              const node = elementCache[d.data.id];

              if (node) {
                if (node.type === 'meter') {
                  return 'Meter';
                } else if (node.type === 'poc') {
                  return (node.props as IPoC).name || 'POC';
                } else if (node.type === 'prv') {
                  return 'PRV';
                } else if (node.type === 'pump') {
                  return 'Pump';
                } else if (node.type === 'backflow') {
                  return 'Backflow';
                }
              }
              return '';
            })
            .attr('font-family', 'sans-serif')
            .attr('font-size', '5px')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'central');
        }
      }
    }
  }, [
    selectedItems,
    classTitle,
    elements,
    plants,
    zones,
    groups,
    edges,
    graphIndex,
    elementCache,
    pocGraphs,
  ]);
  return <Diagram ref={treeRef} />;
};

const Diagram = styled.div`
  width: 540px;
  height: 600px;
  border: 1px solid black;
  .link {
    stroke: #999;
    stroke-width: 1px;
  }
`;
