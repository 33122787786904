import { Basemap, IPoint } from '@shared-types';
import { defaultStyledItem, paperItemStore } from '../helpers';
import { activateNamedLayer } from '../paper-helpers/plot.helpers';
import { ItemService } from '../polyline/ItemService';
import { registerStateHooks } from '../polyline/item.model';
import { addPoints } from '../shared/geometry';
import { LAYER_NAMES } from '../shared/workbench-enums';
import { deletePaperItem, updatePaperItem } from '../state/renderers';
import { PaperBasemap } from './paper-basemap';

export type BASEMAP_HANDLE = 'handle.cropBox';

export const moveBasemap = (
  basemap: Basemap,
  diffPosition: IPoint,
): Basemap => ({
  ...basemap,
  knownPoints: {
    a: addPoints(basemap.knownPoints.a, diffPosition),
    b: addPoints(basemap.knownPoints.b, diffPosition),
  },
  cropBox: {
    topLeft: addPoints(basemap.cropBox.topLeft, diffPosition),
    bottomRight: addPoints(basemap.cropBox.bottomRight, diffPosition),
  },
});

export const scaleBasemap = (
  basemap: Basemap,
  pivot: string,
  newWidth: number,
): Basemap => {
  let scaledWidth = basemap.width / basemap.scale;
  let scaledHeight = basemap.height / basemap.scale;

  let pivotPoint: IPoint = {
    x: basemap.position.x,
    y: basemap.position.y,
  };
  const ratio = basemap.width / basemap.height;
  const newHeight = newWidth / ratio;
  // const newScale = newWidth / scaledWidth;
  const newScale = basemap.width / newWidth;
  let newObjectX = pivotPoint.x;
  let newObjectY = pivotPoint.y;

  if (pivot === 'topRight') {
    pivotPoint = {
      x: basemap.position.x + scaledWidth,
      y: basemap.position.y,
    };
    newObjectX = pivotPoint.x - newWidth;
    newObjectY = pivotPoint.y;
  } else if (pivot === 'bottomLeft') {
    pivotPoint = {
      x: basemap.position.x,
      y: basemap.position.y + scaledHeight,
    };
    newObjectY = pivotPoint.y - newHeight;
    newObjectX = pivotPoint.x;
  } else if (pivot === 'bottomRight') {
    pivotPoint = {
      x: basemap.position.x + scaledWidth,
      y: basemap.position.y + scaledHeight,
    };
    newObjectX = pivotPoint.x - newWidth;
    newObjectY = pivotPoint.y - newHeight;
  }

  const newBasemap: Basemap = {
    ...basemap,
    scale: newScale,
    position: {
      x: newObjectX,
      y: newObjectY,
    },
    cropBox: {
      topLeft: {
        x: newObjectX,
        y: newObjectY,
      },
      bottomRight: {
        x: newObjectX + newWidth,
        y: newObjectY + newHeight,
      },
    },
  };

  return newBasemap;
};

class BasemapService implements ItemService<Basemap> {
  init() {
    this.registerWithState();
  }
  createItem = (): Basemap => ({
    ...defaultStyledItem(),
    itemType: 'basemap',
    scale: 72,
    width: 0,
    height: 0,
    selectable: true,
    showCropBox: true,
    quality: 40,
    knownPoints: {
      a: {
        x: 0,
        y: 0,
      },
      b: {
        x: 7,
        y: 0,
      },
    },
    cropBox: {
      topLeft: {
        x: 0,
        y: 0,
      },
      bottomRight: {
        x: 0,
        y: 0,
      },
    },
    jpgUUID: '',
    original: {
      type: 'image',
      filename: '',
      width: 0,
      height: 0,
      scale: 72,
      rotation: 0,
    },
  });
  renderItemToPaper = (item: Basemap, layer = LAYER_NAMES.BASEMAPS) => {
    activateNamedLayer(layer);
    paperItemStore.set(item.uuid, new PaperBasemap(item));
    activateNamedLayer(LAYER_NAMES.DEFAULT);
  };
  registerWithState = () => {
    registerStateHooks<Basemap>('basemap', {
      postAddItem: (item) => {
        this.renderItemToPaper(item);
      },
      postUpdateItem: (item) => {
        updatePaperItem(item);
      },
      postDeleteItem: (item) => {
        deletePaperItem(item.uuid);
      },
    });
  };
}

export const basemapService = new BasemapService();
