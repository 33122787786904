import {
  HeadNozzleJoin,
  HeadProduct,
  NozzleData,
  SprinklerBase,
  SprinklerCalculatedProps,
} from '@shared-types';
import { range, uniq } from 'underscore';
import { getMissingCoordinateY } from './geometry.helpers';

const precipConstant = 96.25;
const triangleSpacing = 0.866;
const getArea = (radius: number): number => Math.PI * Math.pow(radius, 2);

export interface LocalNozzleData {
  updated: number;
  nozzles: NozzleData[];
  heads: HeadProduct[];
  headNozzles: HeadNozzleJoin[];
}
const perfCache: any = {};

export const getLocalNozzles = (): NozzleData[] => {
  const localData = localStorage.getItem('is.nozzleData');
  let nozzles: NozzleData[] = [];
  if (localData) {
    const data = JSON.parse(localData) as LocalNozzleData;
    nozzles = data.nozzles;
  }
  return nozzles;
};

export const calcSquarePrecip = (gpm: number, radius: number, angle: number) =>
  Math.round(
    ((gpm * precipConstant) / Math.pow(radius, 2)) * 2 * (180 / angle) * 100,
  ) / 100;

export const calcTrianglePrecip = (
  gpm: number,
  radius: number,
  angle: number,
) =>
  Math.round(
    ((gpm * precipConstant) / (Math.pow(radius, 2) * triangleSpacing)) *
      2 *
      (180 / angle) *
      100,
  ) / 100;

export const calcSquarePrecipStrip = (
  gpm: number,
  width: number,
  height: number,
) => Math.round(((gpm * precipConstant) / ((width * height) / 2)) * 100) / 100;

export const seriesModelFilter =
  (model: string, series: string) => (n: NozzleData) =>
    n.name === model && n.series === series;

export const angleFilter = (angle: number) => (n: NozzleData) =>
  n.angle === angle;

export const psiFilter = (psi: number) => (n: NozzleData) => n.psi === psi;

export const generateRotorData = (data: NozzleData[]): NozzleData[] => {
  if (data.length !== 1) {
    console.error(
      'a nozzle got through that is either not a rotor, or has more data than a rotor usually does',
    );
    return [];
  } else {
    // Knowledge: for rotors the gpm doesn't change when changing angle, only the precip
    const firstRotor = data[0];
    const minAngle: NozzleData = {
      ...firstRotor,
      angle: 30,
      precipSquare: calcSquarePrecip(
        firstRotor.gpm,
        firstRotor.radius,
        firstRotor.angle,
      ),
      precipTriangle: calcTrianglePrecip(
        firstRotor.gpm,
        firstRotor.radius,
        firstRotor.angle,
      ),
    };
    const maxAngle: NozzleData = {
      ...firstRotor,
      angle: 360,
      precipSquare: calcSquarePrecip(
        firstRotor.gpm,
        firstRotor.radius,
        firstRotor.angle,
      ),
      precipTriangle: calcTrianglePrecip(
        firstRotor.gpm,
        firstRotor.radius,
        firstRotor.angle,
      ),
    };
    return [minAngle, maxAngle];
  }
};
export const generateReducedRadiusData = (data: NozzleData[]): NozzleData[] => {
  const reducedData: NozzleData[] = [];
  data.forEach((d: NozzleData) => {
    // ignoring minRadius of -1
    if (d.minRadius === -1 || d.minRadius >= d.radius) return;
    range(d.minRadius, d.radius).forEach((r: number) => {
      let gpm = d.gpm;
      if (d.reduceGPM) {
        const arcMultiplier = d.angle / 360;
        const originalArea = getArea(d.radius) * arcMultiplier;
        const newArea = getArea(r) * arcMultiplier;
        const ratio = newArea / originalArea;
        gpm = d.gpm * ratio;
      }
      reducedData.push({
        ...d,
        gpm,
        radius: r,
        precipSquare: calcSquarePrecip(gpm, r, d.angle),
        precipTriangle: calcTrianglePrecip(gpm, r, d.angle),
      });
    });
  });
  return reducedData;
};

const getSlopedPropFromPSI = (
  nozzles: NozzleData[],
  psiList: number[],
  prop: string,
  psi: number,
): number => {
  let p1;
  let p2;
  if (psi < Math.min(...psiList) || psi > Math.max(...psiList)) return 0;
  for (let i = 0; i < psiList.length; i++) {
    if (psi < psiList[i]) break;
    const thisNozzle = nozzles.filter((n) => n.psi === psiList[i]);
    const nextNozzle = nozzles.filter((n) => n.psi === psiList[i + 1]);
    if (!thisNozzle.length || !nextNozzle.length) break;
    p1 = {
      psi: psiList[i],
      [prop]: thisNozzle[0][prop],
    };
    p2 = {
      psi: psiList[i + 1],
      [prop]: nextNozzle[0][prop],
    };
  }
  if (!p1 || !p2) return 0;
  const slope = (p2[prop] - p1[prop]) / (p2.psi - p1.psi);
  return getMissingCoordinateY({ x: p2.psi, y: p2[prop] }, psi, slope);
};

const getSlopedPropFromAngle = (
  nozzles: NozzleData[],
  angleList: number[],
  prop: string,
  angle: number,
): number => {
  let p1;
  let p2;
  if (Math.max(...angleList) < angle) return 0;
  for (let i = 0; i < angleList.length; i++) {
    if (angle < angleList[i]) break;
    p1 = {
      angle: angleList[i],
      [prop]: nozzles.filter((n) => n.angle === angleList[i])[0][prop],
    };
    p2 = {
      angle: angleList[i + 1],
      [prop]: nozzles.filter((n) => n.angle === angleList[i + 1])[0][prop],
    };
  }
  if (!p1 || !p2) return 0;
  const slope = (p2[prop] - p1[prop]) / (p2.angle - p1.angle);
  return getMissingCoordinateY({ x: p2.angle, y: p2[prop] }, angle, slope);
};

const generateKnownArcCustomPSI = (
  angle: number,
  psi: number,
  nozzles: NozzleData[],
  isStrip: boolean,
): { gpm: number; radius: number } => {
  let gpm = 0;
  let radius = 0;
  const knownNozzles = isStrip ? nozzles : nozzles.filter(angleFilter(angle));
  const uniquePSI = uniq(knownNozzles.map((n) => n.psi)).sort(
    (a: number, b: number) => a - b,
  );
  if (uniquePSI.length > 1) {
    gpm = getSlopedPropFromPSI(knownNozzles, uniquePSI, 'gpm', psi);
    radius = isStrip
      ? 0
      : Math.floor(
          getSlopedPropFromPSI(knownNozzles, uniquePSI, 'radius', psi),
        );
  } else {
    console.error('have asked for a psi that is outside the min/max');
  }
  return {
    gpm,
    radius,
  };
};

export const getGeneratePerfData = (base: SprinklerBase): NozzleData[] => {
  if (perfCache[base.uuid]) return perfCache[base.uuid];
  const nozzles = getLocalNozzles();
  const perfDataModel = nozzles.filter(
    seriesModelFilter(base.nozzleModel, base.nozzleSeries),
  );
  if (!perfDataModel.length) return []; // FAIL: no model or series data for this nozzle
  let originalData: NozzleData[] = [];
  const perfDataPSI = perfDataModel.filter(psiFilter(base.outputPSI));
  const isStrip = perfDataModel[0].width > 0;
  const isRotor = perfDataModel[0].type === 'Rotor';
  if (perfDataPSI.length > 0) {
    // console.log('yep, have it')
    // return all data at this psi
    originalData = perfDataPSI;
  } else {
    // generate new data at this psi
    // console.info(`${base.outputPSI} psi does not exist, generating new data`)
    const uniqueAngles = uniq(perfDataModel.map((n) => n.angle)).sort(
      (a, b) => a - b,
    );
    const generatedNozzles: NozzleData[] = [];
    uniqueAngles.forEach((angle: number) => {
      const { gpm, radius } = generateKnownArcCustomPSI(
        angle,
        base.outputPSI,
        perfDataModel,
        isStrip,
      );
      // console.log(`for angle ${angle} we have ${gpm} gpm and ${radius}`)
      generatedNozzles.push({
        ...perfDataModel[0],
        psi: base.outputPSI,
        angle,
        gpm,
        radius,
      });
    });
    originalData = generatedNozzles;
  }
  const expandedData: NozzleData[] =
    isRotor && !originalData[0].fixed
      ? generateRotorData(originalData)
      : originalData;
  const reducedData: NozzleData[] = isStrip
    ? []
    : generateReducedRadiusData(originalData);
  const allData = [...expandedData, ...reducedData];
  perfCache[base.uuid] = allData;
  return allData;
};

export const calculateSprinklerProps = (
  base: SprinklerBase,
  angle: number,
  radius: number,
): SprinklerCalculatedProps => {
  const perfData = getGeneratePerfData(base);
  const calculatedProps: SprinklerCalculatedProps = {
    radius: 0,
    gpm: 0,
    precipTriangle: 0,
    precipSquare: 0,
    precipAverage: 0,
    type: '',
    width: 0,
    height: 0,
    origin: '',
  };
  if (!perfData.length) return calculatedProps;

  const filteredData = perfData.filter((d) => d.radius === radius);
  if (!filteredData.length) return calculatedProps;

  let gpm = 0;
  // generate angle for good PSI
  const angleData = filteredData.filter(angleFilter(angle));
  if (angleData.length === 1) {
    gpm = angleData[0].gpm;
  } else {
    const uniqueAngles = uniq(filteredData.map((n) => n.angle)).sort(
      (a, b) => a - b,
    );
    gpm = getSlopedPropFromAngle(filteredData, uniqueAngles, 'gpm', angle);
  }
  calculatedProps.type = filteredData[0].type;
  calculatedProps.origin = filteredData[0].origin;
  calculatedProps.width = filteredData[0].width;
  calculatedProps.height = filteredData[0].height;
  calculatedProps.radius = filteredData[0].radius;
  calculatedProps.gpm = gpm;
  const isStrip =
    filteredData.filter((n: NozzleData) => n.width && n.height).length > 0;
  let precipSquare = filteredData[0].precipSquare;
  let precipTriangle = filteredData[0].precipTriangle;
  if (isStrip) {
    precipSquare = calcSquarePrecipStrip(
      calculatedProps.gpm,
      calculatedProps.width,
      calculatedProps.height,
    );
    precipTriangle = precipSquare;
  } else {
    precipTriangle = calcTrianglePrecip(
      calculatedProps.gpm,
      calculatedProps.radius,
      angle,
    );
    precipSquare = calcSquarePrecip(
      calculatedProps.gpm,
      calculatedProps.radius,
      angle,
    );
  }
  const precipAverage = (precipTriangle + precipSquare) / 2;
  return {
    ...calculatedProps,
    precipTriangle,
    precipSquare,
    precipAverage,
  };
};
