import { WaterGroup } from '@shared-types';
import styled from 'styled-components';
import { GroupDetails } from '../features/zones/GroupDetails';
import { StyledZoneSwatch } from '../features/zones/ZoneItem';
import { setCurrentZone, useDesignStore } from '../state';

export const GroupList = () => {
  const currentZone = useDesignStore((state) => state.currentZone);
  const groups = useDesignStore((state) => state.groups);

  const setZone = (id: string) => {
    setCurrentZone(id);
  };

  const toggleCurrentZone = (id: string, currentZone: string) => {
    setZone(id === currentZone ? '' : id);
  };

  return (
    <Wrap>
      {groups
        .sort((g1, g2) => g1.orderNumber - g2.orderNumber)
        .map((group: WaterGroup) => (
          <div key={group.uuid} className="zone">
            <StyledZoneSwatch
              $active={group.uuid === currentZone}
              $color={group.orderNumber > -1 ? group.color : '#999'}
              onClick={toggleCurrentZone.bind(null, group.uuid, currentZone)}
            >
              <div className="zone-color" />
              <div className="zone-text">
                <div className="zone-id">Group {group.orderNumber + 1}</div>
              </div>
            </StyledZoneSwatch>
            {group.uuid === currentZone && <GroupDetails group={group} />}
          </div>
        ))}
    </Wrap>
  );
};
const Wrap = styled.div`
  .ui.selection.dropdown .menu > .item.bad {
    color: #666;
  }
  .details {
    padding: 8px;
    background-color: #5e5e5e;
  }
`;
