import {
  Filter,
  ListPagination,
  LogUI,
  ProjectSortCols,
  ProjectStatus,
  ProjectUI,
} from '@shared-types';
import { differenceInBusinessDays, parseISO } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Dimmer,
  Dropdown,
  DropdownItemProps,
  Icon,
  Loader,
  Message,
  Pagination,
  Table,
} from 'semantic-ui-react';
import { PortalContext } from 'src/contexts/portal/portal';
import { ProfileContext } from 'src/contexts/profile/profile';
import { parseDate } from 'src/helpers/dateHelpers';
import { isSorted } from 'src/helpers/sortHelpers';
import { base, emptyStateHeight } from 'src/styles/common';
import styled from 'styled-components';
import { contains, last } from 'underscore';
import { ProjectFilters } from './ProjectFilters';

interface ColPermissions {
  dates: boolean;
  designs: boolean;
  invoices: boolean;
  emails: boolean;
  files: boolean;
  basemaps: boolean;
}
const limitOptions: DropdownItemProps[] = [
  { key: '10', value: 10, text: '10' },
  { key: '25', value: 25, text: '25' },
  { key: '50', value: 50, text: '50' },
  { key: '100', value: 100, text: '100' },
];
const getStatusLogs = (logs: LogUI[]) =>
  logs.filter((l) => l.data.type === 'Status Change');

const getDaysUntilDueDate = (project: ProjectUI): number =>
  project.dueDate
    ? differenceInBusinessDays(new Date(project.dueDate), new Date())
    : 20;

const getProjectDueDate = (project: ProjectUI): string =>
  project.dueDate ? parseISO(project.dueDate).toLocaleDateString() : 'N/A';

const getProjectAcceptedDate = (project: ProjectUI): string =>
  project.acceptedAt
    ? parseISO(project.acceptedAt).toLocaleDateString()
    : 'N/A';

const isProjectIncomplete = (project: ProjectUI): boolean =>
  getStatusLogs(project.logs || []).find((l) =>
    l.data.message.includes('Design Complete' as ProjectStatus),
  ) === undefined;

const negativeDueDateColor = (project: ProjectUI): boolean =>
  getDaysUntilDueDate(project) <= 0 && isProjectIncomplete(project);

const warningDueDateColor = (project: ProjectUI): boolean =>
  getDaysUntilDueDate(project) <= 2 &&
  getDaysUntilDueDate(project) > 0 &&
  isProjectIncomplete(project);

interface Props {
  showFiles?: boolean;
  showDesigns?: boolean;
  showFilters?: boolean;
}
export const Projects = ({
  // showDesigns = true,
  // showFiles = true,
  showFilters = true,
}: Props) => {
  const { role } = useContext(ProfileContext);
  const { projects, loadProjects, loadingProjects, projectsCount } =
    useContext(PortalContext);
  const [permissions, setPermissions] = useState<ColPermissions>({
    dates: false,
    designs: false,
    invoices: false,
    emails: false,
    files: false,
    basemaps: false,
  });
  const [filters, setFilters] = useState<Filter[]>([]);
  const [sortCol, setSortCol] = useState<ProjectSortCols>('createdAt');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [pagination, setPagination] = useState<ListPagination>({
    limit: 10,
    offset: 0,
  });
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const handleSortClick = (targetSortCol: ProjectSortCols) => {
    if (targetSortCol === sortCol) {
      sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
    } else {
      setSortCol(targetSortCol);
    }
  };

  const handleStatusFilterChange = (value: any) => {
    if (value !== '') {
      setFilters([{ status: value }]);
    } else {
      setFilters([]);
    }
  };

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * pagination.limit;
    setPagination({ ...pagination, offset: newOffset });
    setActivePage(page);
  };

  const handleLimitChange = (value: number) => {
    setPagination({ ...pagination, limit: value });
  };

  const calculateDaysOpen = (logs: LogUI[]): number | undefined => {
    const statusLogs = getStatusLogs(logs);
    if (!statusLogs.length) {
      return undefined;
    }

    const openLogs: LogUI[] = statusLogs.filter(
      (l) =>
        l.data.message === 'Accepted' || l.data.message === 'Basemap Complete',
    );
    const lastOpenLog = last(openLogs);
    const lastAcceptedLogs = statusLogs.filter(
      (l) => l.data.message === 'Accepted',
    );
    const lastAcceptedLog = last(lastAcceptedLogs);

    const completedLogs: LogUI[] = statusLogs.filter(
      (l) => l.data.message === 'Design Complete',
    );
    const lastCompletedLog = last(completedLogs);

    let days: number | undefined = undefined;
    if (lastOpenLog && lastCompletedLog && lastAcceptedLog) {
      // covers status reversion
      if (lastOpenLog.createdAt > lastCompletedLog.createdAt) {
        days = differenceInBusinessDays(
          new Date(),
          new Date(lastAcceptedLog.createdAt),
        );
      } else {
        days = differenceInBusinessDays(
          new Date(lastCompletedLog.createdAt),
          new Date(lastAcceptedLog.createdAt),
        );
      }
    }
    if (lastAcceptedLog && !lastCompletedLog) {
      days = differenceInBusinessDays(
        new Date(),
        new Date(lastAcceptedLog.createdAt),
      );
    }

    return days;
  };

  useEffect(() => {
    setTotalPages(Math.ceil(projectsCount / pagination.limit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, pagination, projectsCount]);

  useEffect(
    () => {
      loadProjects({ filters, pagination, sort: { sortOrder, sortCol } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortCol, sortOrder, pagination, filters],
  );

  useEffect(() => {
    setPermissions({
      dates: contains(['admin', 'designer', 'readonly'], role),
      designs: contains(['admin', 'designer'], role),
      invoices: contains(['admin', 'contractor', 'readonly'], role),
      emails: contains(['admin', 'readonly'], role),
      files: role !== 'contractor',
      basemaps: contains(['basemapper', 'admin'], role),
    });
  }, [role]);

  return (
    <Wrap>
      <Dimmer active={loadingProjects} inverted={true}>
        <Loader />
      </Dimmer>
      {!loadingProjects &&
        !!projects.length &&
        projects.some((p) => p.status === 'Draft') && (
          <Message color="orange">
            Projects must be <strong>submitted</strong> before they can begin.
            Make sure to include all the information.
          </Message>
        )}
      {showFilters && (
        <ProjectFilters
          filters={filters}
          onFilterChange={handleStatusFilterChange}
        />
      )}
      {!projects.length && !loadingProjects && (
        <div className="empty-state">There are no projects yet.</div>
      )}
      {!!projects.length && !loadingProjects && (
        <div className="table-wrap">
          <Table compact={true} sortable className="project-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell selectable={false} disabled={true}>
                  Title
                </Table.HeaderCell>
                {permissions.emails && (
                  <>
                    <Table.HeaderCell
                      sorted={isSorted(sortOrder, sortCol, 'owner')}
                      onClick={() => handleSortClick('owner')}
                    >
                      Owner
                    </Table.HeaderCell>
                    {/* <Table.HeaderCell
                      sorted={isSorted(sortOrder, sortCol, 'basemapper')}
                      onClick={() => handleSortClick('basemapper')}
                    >
                      Basemapper
                    </Table.HeaderCell> */}
                    {/* <Table.HeaderCell
                      sorted={isSorted(sortOrder, sortCol, 'designer')}
                      onClick={() => handleSortClick('designer')}
                    >
                      Designer
                    </Table.HeaderCell> */}
                  </>
                )}
                <Table.HeaderCell
                  sorted={isSorted(sortOrder, sortCol, 'status')}
                  onClick={() => handleSortClick('status')}
                >
                  Status
                </Table.HeaderCell>
                {permissions.dates && (
                  <>
                    <Table.HeaderCell
                      sorted={isSorted(sortOrder, sortCol, 'createdAt')}
                      onClick={() => handleSortClick('createdAt')}
                    >
                      Created Date
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={isSorted(sortOrder, sortCol, 'acceptedAt')}
                      onClick={() => handleSortClick('acceptedAt')}
                    >
                      Accepted Date
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={isSorted(sortOrder, sortCol, 'dueDate')}
                      onClick={() => handleSortClick('dueDate')}
                    >
                      Due Date
                    </Table.HeaderCell>
                    <Table.HeaderCell>Days Open</Table.HeaderCell>
                  </>
                )}
                {/* {permissions.files && showFiles && <Table.HeaderCell>Files</Table.HeaderCell>}
                  {permissions.designs &&
                    showDesigns && <Table.HeaderCell>Designs</Table.HeaderCell>}
                  {permissions.basemaps && <Table.HeaderCell>Basemaps</Table.HeaderCell>} */}
                {permissions.invoices && (
                  <Table.HeaderCell>Invoice</Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {projects.map((project: ProjectUI, i: number) => (
                <Table.Row key={i} warning={project.status === 'Draft'}>
                  <Table.Cell>
                    <Link to={`/app/projects/${project.id}`}>
                      {project.data.title || 'Untitled Project'}
                    </Link>
                  </Table.Cell>
                  {permissions.emails && (
                    <>
                      <Table.Cell>
                        <div>{project.owner ? project.owner.email : ''}</div>
                      </Table.Cell>
                      {/* <Table.Cell>
                        <div>
                          {project.basemapper ? project.basemapper.email : ''}
                        </div>
                      </Table.Cell> */}
                      {/* <Table.Cell>
                        <div>
                          {project.designer ? project.designer.email : ''}
                        </div>
                      </Table.Cell> */}
                    </>
                  )}
                  <Table.Cell>
                    <div>
                      {project.status}
                      {project.status === 'Draft' && (
                        <span>
                          &nbsp; &nbsp; &nbsp;
                          <Icon name="arrow left" color="red" /> make sure to
                          review and submit this project
                        </span>
                      )}
                    </div>
                  </Table.Cell>
                  {permissions.dates && (
                    <>
                      <Table.Cell>
                        <div>{parseDate(project.createdAt)}</div>
                      </Table.Cell>
                      <Table.Cell>
                        <div>{getProjectAcceptedDate(project)}</div>
                      </Table.Cell>
                      <Table.Cell
                        negative={
                          !!project.dueDate && negativeDueDateColor(project)
                        }
                        warning={
                          !!project.dueDate && warningDueDateColor(project)
                        }
                      >
                        <div>{getProjectDueDate(project)}</div>
                      </Table.Cell>
                      <Table.Cell>
                        <div>{calculateDaysOpen(project.logs || [])}</div>
                      </Table.Cell>
                    </>
                  )}
                  {/* {permissions.files &&
                      showFiles && (
                        <Table.Cell>
                          <div>
                            {project.data.contractorFiles && project.data.contractorFiles.length}
                          </div>
                        </Table.Cell>
                      )}
                    {permissions.designs &&
                      showDesigns && (
                        <Table.Cell>
                          <div>{project.designs.length}</div>
                        </Table.Cell>
                      )}
                    {permissions.basemaps && (
                      <Table.Cell>
                        <div>{project.data.tracedBasemaps.length}</div>
                      </Table.Cell>
                    )} */}
                  {permissions.invoices && (
                    <Table.Cell>
                      {!!project.codeId && 'Uses project credit'}
                      {project.invoices
                        .filter(
                          (invoice) =>
                            invoice.status !== 'void' &&
                            invoice.status !== 'uncollectible',
                        )
                        .map((invoice, j) => (
                          <div key={j}>
                            {invoice.status === 'paid' && (
                              <Icon size="small" name="check" color="green" />
                            )}
                            ${invoice.amount_due / 100}
                          </div>
                        ))}
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {totalPages > 1 && (
            <>
              <Pagination
                totalPages={totalPages}
                defaultActivePage={activePage}
                firstItem={null}
                lastItem={null}
                onPageChange={(_, p) =>
                  handlePageChange(p.activePage as number)
                }
              />
              <span className="limit-options">
                <Dropdown
                  placeholder="limit"
                  inline={true}
                  options={limitOptions}
                  value={pagination.limit}
                  onChange={(_, o) => handleLimitChange(o.value as number)}
                />
                per page
              </span>
            </>
          )}
        </div>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  flex: 1;
  overflow: auto;

  .empty-state {
    height: ${emptyStateHeight}px;
    text-align: center;
  }

  .limit-options {
    margin-left: ${base}px;
  }

  .unsortable {
    font-weight: normal !important;
  }
  .table-wrap {
    padding: 16px;
  }
`;
