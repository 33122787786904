import { Button, Checkbox, List, Modal } from 'semantic-ui-react';
import { doAutoSize, doFixPipeDirections } from '../features/pipes/Pipes';
import { ValveDiagrams } from '../features/valves/ValveDiagrams';
import { ZoneDiagrams } from '../features/zones/ZoneDiagrams';
import { sizeDripTransitions } from '../plants/plants';
import {
  getState,
  toggleAllCoverage,
  toggleCoverage,
  useDesignStore,
} from '../state';
import { CoverageOptions } from './CoverageOptions';
import { FullTreeDiagrams } from './FullTreeDiagrams';
import { PacketExport } from './PacketExport';
import { PanelButton } from './PanelButton';
import { SheetExport } from './SheetExport';
import { TakeoffExport } from './TakeoffExport';

export const ExportTrigger = () => {
  const showCoverage = useDesignStore((state) => state.showCoverage);
  return (
    <Modal size="tiny" trigger={<PanelButton>Export</PanelButton>}>
      <Modal.Header>Export</Modal.Header>
      <Modal.Content scrolling>
        <List>
          <List.Item>
            1. Size Drip Transitions{' '}
            <Button size="mini" onClick={sizeDripTransitions}>
              Size Drip Transitions
            </Button>
          </List.Item>
          <List.Item>
            2. Fix Pipe Directions{' '}
            <Button size="mini" onClick={doFixPipeDirections}>
              Fix Pipe Directions
            </Button>
          </List.Item>
          <List.Item>
            3. Autosize Pipes{' '}
            <Button size="mini" onClick={doAutoSize}>
              Autosize
            </Button>
          </List.Item>
          <List.Item>
            <Checkbox
              checked={showCoverage}
              disabled={!!showCoverage}
              label="4. Coverage"
              onChange={() => {
                const showAllCoverage = getState().showAllCoverage;
                toggleCoverage();
                if (!showAllCoverage) {
                  toggleAllCoverage();
                }
              }}
            />
          </List.Item>
        </List>
        {showCoverage && <CoverageOptions />}
        <List>
          <PacketExport />
          <TakeoffExport />
          {/* {showCoverage && <CoverageExport />} */}
          <SheetExport />
          <br />
          <small>
            Note about pipe sizes. The charts show pipe sizes only when they
            have changed to a smaller diameter
          </small>
          <ZoneDiagrams />
          <FullTreeDiagrams />
          <ValveDiagrams />
        </List>
      </Modal.Content>
    </Modal>
  );
};
