import { SprinklerBase } from '@shared-types';
import { newUUID } from '../crypto-uuid';

export interface HardCodedBases {
  name: string;
  bases: SprinklerBase[];
}

const baseRainbird45 = {
  headModel: 'RD06-S-P-45-F',
  headSeries: 'Rainbird RD1800',
  inputPSI: 45,
  nozzleSeries: 'R-VAN Series',
  outputPSI: 45,
};

const baseRainbird5000SAM45 = {
  headModel: '5006+PC-SAM',
  headSeries: 'Rainbird 5000',
  inputPSI: 45,
  nozzleSeries: '5000 MPR',
  outputPSI: 45,
};

const baseRainbird30 = {
  headModel: 'RD06-S-P-30',
  headSeries: 'Rainbird RD1800',
  inputPSI: 30,
  nozzleSeries: 'R-VAN Series',
  outputPSI: 30,
};

export const hardCodedBaseOptions: HardCodedBases[] = [
  {
    name: 'Rainbird 5006 Plus Part Circle SAM (5006+PC-SAM)',
    bases: [
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-25 (Red) F',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-25 (Red) Q',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-25 (Red) H',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-25 (Red) T',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-30 (Green) F',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-30 (Green) Q',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-30 (Green) H',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-30 (Green) T',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-35 (Beige) F',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-35 (Beige) Q',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-35 (Beige) H',
      },
      {
        ...baseRainbird5000SAM45,
        uuid: newUUID(),
        nozzleModel: '5000-MPR-35 (Beige) T',
      },
    ],
  },
  {
    name: 'Rainbird RD1800 6" 45psi (RD06-S-P-45-F)',
    bases: [
      {
        ...baseRainbird45,
        uuid: newUUID(),
        nozzleModel: 'R-VAN14',
      },
      {
        ...baseRainbird45,
        uuid: newUUID(),
        nozzleModel: 'R-VAN14 (360)',
      },
      {
        ...baseRainbird45,
        uuid: newUUID(),
        nozzleModel: 'R-VAN18',
      },
      {
        ...baseRainbird45,
        uuid: newUUID(),
        nozzleModel: 'R-VAN18 (360)',
      },
      {
        ...baseRainbird45,
        uuid: newUUID(),
        nozzleModel: 'R-VAN24',
      },
      {
        ...baseRainbird45,
        uuid: newUUID(),
        nozzleModel: 'R-VAN24 (360)',
      },
      {
        ...baseRainbird45,
        uuid: newUUID(),
        nozzleModel: 'R-VAN-SST 5x30',
      },
      {
        ...baseRainbird45,
        uuid: newUUID(),
        nozzleModel: 'R-VAN-RCS 5x15',
      },
      {
        ...baseRainbird45,
        uuid: newUUID(),
        nozzleModel: 'R-VAN-LCS 5x15',
      },
    ],
  },
  {
    name: 'Rainbird RD1800 6" 30psi (RD06-S-P-30)',
    bases: [
      {
        ...baseRainbird30,
        uuid: newUUID(),
        nozzleModel: 'R-VAN14',
      },
      {
        ...baseRainbird30,
        uuid: newUUID(),
        nozzleModel: 'R-VAN14 (360)',
      },
      {
        ...baseRainbird30,
        uuid: newUUID(),
        nozzleModel: 'R-VAN18',
      },
      {
        ...baseRainbird30,
        uuid: newUUID(),
        nozzleModel: 'R-VAN18 (360)',
      },
      {
        ...baseRainbird30,
        uuid: newUUID(),
        nozzleModel: 'R-VAN24',
      },
      {
        ...baseRainbird30,
        uuid: newUUID(),
        nozzleModel: 'R-VAN24 (360)',
      },
    ],
  },
];
