import {
  Catalog,
  DesignElement,
  IDesignState,
  IPlant,
  IPoint,
  Sheet,
  TextChunk,
} from '@shared-types';
import { designDefault } from '../../../shared';
import { newUUID } from '../crypto-uuid';
import { defaultItem, isBasemap, isSheet, isSleeve } from '../helpers';
import {
  convertBedToPolyline,
  convertYardToPolyline,
} from '../polyline/polyline-helpers';
import { sheetService } from '../sheets/sheet.service';
import { getPipeSizes } from './getPipeSizes';

class DesignMigrator {
  private migrations: ((design: IDesignState) => IDesignState)[] = [];
  private _catalog: Catalog = {
    pipes: [],
    valves: [],
    backflows: [],
    prvs: [],
    brands: [],
    flowKits: [],
    pumps: [],
  };

  constructor() {
    this.migrations = [
      this.v1,
      this.v2,
      this.v3,
      this.v4,
      this.v5,
      this.v6,
      this.v7,
      this.v8,
      this.v9,
      this.v10,
      this.v11,
      this.v12,
      this.v13,
      this.v14,
      this.v15,
      this.v16,
      this.v17,
      this.v18,
      this.v19,
      this.v20,
      this.v21,
      this.v22,
      this.v23,
      this.v24,
      this.v25,
    ];
  }

  public migrate(design: IDesignState, catalog: Catalog): IDesignState {
    this._catalog = catalog;
    try {
      let currentVersion = design.version || 0;
      console.log(
        `This design is on version ${currentVersion} -- `,
        currentVersion === this.migrations.length
          ? 'no need to migrate'
          : 'migrating...',
      );
      while (this.migrations[currentVersion]) {
        console.log(`Migrating to version ${currentVersion + 1}`);

        design = this.migrations[currentVersion](design);
        currentVersion++;
      }
      design.version = currentVersion;

      const mainPipeSizes = design.mainPipeSizes?.length
        ? design.mainPipeSizes
        : getPipeSizes(design.mainPipe, catalog.pipes, design.mainPipeMinSize);
      const lateralPipeSizes = design.lateralPipeSizes?.length
        ? design.lateralPipeSizes
        : getPipeSizes(
            design.lateralPipe,
            catalog.pipes,
            design.lateralPipeMinSize,
          );
      const dripPipeSizes = design.dripPipeSizes?.length
        ? design.dripPipeSizes
        : getPipeSizes(design.dripPipe, catalog.pipes, design.dripPipeMinSize);
      design.pipeProducts = catalog.pipes;
      design.prvProducts = catalog.prvs;
      design.backflowProducts = catalog.backflows;
      design.valveProducts = catalog.valves;
      design.flowKits = catalog.flowKits;
      design.lateralValve = design.lateralValve || catalog.valves[0];
      design.dripValve = design.dripValve || catalog.valves[0];
      design.mainPipeSizes = mainPipeSizes;
      design.lateralPipeSizes = lateralPipeSizes;
      design.dripPipeSizes = dripPipeSizes;
    } catch (error) {
      console.error(error);
    }
    return design;
  }
  private v1 = (design: IDesignState): IDesignState => {
    return {
      ...design,
    };
  };
  private v2 = (data: IDesignState): IDesignState => {
    const newEdges = data.edges.map((edge) => ({
      ...edge,
      uuid: newUUID(),
      pipe:
        typeof edge.pipe === 'string'
          ? edge.pipe
          : edge.pipe
            ? `pipe:${(edge.pipe as any).id}`
            : '',
    }));
    const mainPipeSizes = data.mainPipeSizes?.length
      ? data.mainPipeSizes
      : getPipeSizes(data.mainPipe, this._catalog.pipes, data.mainPipeMinSize);
    const lateralPipeSizes = data.lateralPipeSizes?.length
      ? data.lateralPipeSizes
      : getPipeSizes(
          data.lateralPipe,
          this._catalog.pipes,
          data.lateralPipeMinSize,
        );
    const dripPipeSizes = data.dripPipeSizes?.length
      ? data.dripPipeSizes
      : getPipeSizes(data.dripPipe, this._catalog.pipes, data.dripPipeMinSize);
    const newData: IDesignState = {
      ...data,
      pipeProducts: this._catalog.pipes,
      prvProducts: this._catalog.prvs,
      backflowProducts: this._catalog.backflows,
      valveProducts: this._catalog.valves,
      flowKits: this._catalog.flowKits,
      elements: data.elements.map((element) => ({
        ...element,
        uuid: element.uuid || newUUID(),
      })),
      basemaps: data.basemaps || [],
      edges: newEdges,
      yards: data.yards.map((yard) => ({
        ...yard,
        uuid: yard.uuid || newUUID(),
      })),
      beds: (data.beds || []).map((bed) => ({
        ...bed,
        uuid: bed.uuid || newUUID(),
      })),
      sheets: (data.sheets || []).map((sheet) => ({
        ...sheet,
        uuid: sheet.uuid || newUUID(),
      })),
      activity: data.activity || [],
      printPreview: false,
      showArcs: false,
      sleeves: data.sleeves || [],
      showGPM: false,
      showCoverage: false,
      showAllCoverage: false,
      texts: data.texts || [],
      notification: null,
      measure: 0,
      ortho: data.ortho || true,
      center: data.center || { x: 0, y: 0 },
      lassoMode: 'All',
      loading: false,
      lateralValve: data.lateralValve || this._catalog.valves[0],
      dripValve: data.dripValve || this._catalog.valves[0],
      elementCache: {},
      currentElevation: data.currentElevation || 0,
      pipeAlgoVersion: data.pipeAlgoVersion || '',
      groups: data.groups || [],
      mainPipeSizes: mainPipeSizes,
      lateralPipeSizes: lateralPipeSizes,
      dripPipeSizes: dripPipeSizes,
      version: data.version || 1,
    };
    return newData;
  };
  private v3 = (data: IDesignState): IDesignState => {
    // adds itemTypes to BaseItems
    const newData: IDesignState = {
      ...data,
      elements: data.elements.map((element) => ({
        ...element,
        itemType: element.itemType || 'element',
      })),
      sleeves: data.sleeves.map((sleeve) => ({
        ...sleeve,
        itemType: sleeve.itemType || 'sleeve',
      })),
      edges: data.edges.map((edge) => ({
        ...edge,
        itemType: edge.itemType || 'edge',
      })),
      sheets: data.sheets.map((sheet) => ({
        ...sheet,
        itemType: sheet.itemType || 'sheet',
      })),
      texts: data.texts.map((text) => ({
        ...text,
        itemType: text.itemType || 'text-item',
      })),
      yards: data.yards.map((yard) => ({
        ...yard,
        itemType: yard.itemType || 'yard',
      })),
      beds: data.beds.map((bed) => ({
        ...bed,
        itemType: bed.itemType || 'bed',
      })),
      valveBoxes: data.valveBoxes.map((valveBox) => ({
        ...valveBox,
        itemType: valveBox.itemType || 'valveBox',
      })),
      basemaps: data.basemaps.map((basemap) => ({
        ...basemap,
        itemType: basemap.itemType || 'basemap',
      })),
      plants: data.plants.map((plant) => ({
        ...plant,
        itemType: plant.itemType || 'plant',
      })),
    };
    return newData;
  };
  private v4 = (data: IDesignState): IDesignState => {
    // add sheets to items
    return {
      ...data,
      items: data.items || [...data.sheets],
    };
  };
  private v5 = (data: IDesignState): IDesignState => {
    return {
      ...data,
      sheets: [],
    };
  };
  private v6 = (data: IDesignState): IDesignState => {
    // add sleeves to items
    return {
      ...data,
      items: [...data.items, ...data.sleeves],
      sleeves: [],
    };
  };
  private v7 = (data: IDesignState): IDesignState => {
    // mapping old elements to new elements with all the props
    const convertedElements = data.elements.map(
      (element: any): DesignElement => ({
        ...defaultItem({ x: element.x, y: element.y }),
        ...element,
        itemType: element.itemType || 'element',
      }),
    );
    const convertedValveBoxes = data.valveBoxes.map((valveBox: any) => {
      const oldPosition = valveBox.position
        ? valveBox.position
        : valveBox.point
          ? valveBox.point
          : { x: 0, y: 0 };
      return {
        ...defaultItem({ x: oldPosition.x, y: oldPosition.y }),
        ...valveBox,
        itemType: 'valveBox',
      };
    });
    const convertedEdges = data.edges.map((edge: any) => {
      let position: IPoint = { x: 0, y: 0 };
      const sourceItem = convertedElements.find(
        (element: any) => element.uuid === edge.source,
      );
      if (sourceItem) {
        position = sourceItem.position;
      }
      return {
        ...defaultItem(position),
        ...edge,
        itemType: 'edge',
      };
    });
    const convertedPlants = data.plants.map(
      (plant: any): IPlant => ({
        ...defaultItem({ x: plant.x, y: plant.y }),
        ...plant,
        itemType: 'plant',
        name:
          plant.name === 'Perennial Shrub (1 gph)'
            ? 'Perennial (1 gph)'
            : plant.name,
      }),
    );
    const convertedTexts = data.texts.map(
      (text: TextChunk): TextChunk => ({
        ...defaultItem({ x: text.point.x, y: text.point.y }),
        ...text,
        itemType: 'text-item',
      }),
    );

    return {
      ...data,
      elements: convertedElements,
      valveBoxes: convertedValveBoxes,
      edges: convertedEdges,
      plants: convertedPlants,
      texts: convertedTexts,
      selectedIDs: [],
      items: data.items.map((item) => {
        if (isSheet(item)) {
          const s: Sheet = {
            ...sheetService.createItem(
              item.start || item.position || { x: 0, y: 0 },
            ),
            ...item,
          };
          if (s.start) {
            delete s.start;
          }
          return s;
        } else {
          return item;
        }
      }),
    };
  };
  private v8 = (data: IDesignState): IDesignState => {
    // add index to sheets
    const sheets = data.items.filter(isSheet);
    return {
      ...data,
      items: data.items.map((item) => {
        if (isSheet(item)) {
          return {
            ...item,
            index: sheets.findIndex((s) => s.uuid === item.uuid),
          };
        } else {
          return item;
        }
      }),
    };
  };
  private v9 = (data: IDesignState): IDesignState => {
    // move plants to items
    return {
      ...data,
      items: [...data.items, ...data.plants],
      plants: [],
    };
  };
  private v10 = (data: IDesignState): IDesignState => {
    // move texts to items
    return {
      ...data,
      items: [...data.items, ...data.texts],
      texts: [],
    };
  };
  private v11 = (data: IDesignState): IDesignState => {
    // sleeves that were moved to items do not have a 'position' prop
    return {
      ...data,
      items: data.items.map((item) => {
        if (isSleeve(item) && !item.position) {
          item.position = item.lines[0].start;
          return item;
        } else {
          return item;
        }
      }),
    };
  };
  private v12 = (data: IDesignState): IDesignState => {
    // add knownPoints to basemaps
    return {
      ...data,
      items: data.items.map((item) => {
        if (isBasemap(item)) {
          return {
            ...item,
            knownPoints: {
              a: { x: item.position.x, y: item.position.y },
              b: {
                x: item.position.x + item.width / item.scale,
                y: item.position.y,
              },
            },
          };
        } else {
          return item;
        }
      }),
    };
  };
  private v13 = (data: IDesignState): IDesignState => {
    // add designMode to design
    return {
      ...data,
      designMode: 'basemap',
    };
  };
  private v14 = (data: IDesignState): IDesignState => {
    // add isMouse property
    return {
      ...data,
      isMouse: true,
    };
  };
  private v15 = (data: IDesignState): IDesignState => {
    // rename 'element' to 'design-element'
    return {
      ...data,
      elements: data.elements.map((element) => {
        return { ...element, itemType: 'design-element' };
      }),
    };
  };
  private v16 = (data: IDesignState): IDesignState => {
    // add the svgScale property (use scale)
    return {
      ...data,
      svgScale: data.svgScale || data.scale,
    };
  };
  private v17 = (data: IDesignState): IDesignState => {
    // add legend to items from legendPosition
    return {
      ...data,
      items: [
        ...data.items,
        {
          ...defaultItem(data.legendPosition),
          itemType: 'legend',
        },
      ],
    };
  };
  private v18 = (data: IDesignState): IDesignState => {
    // change the "visible" property to "printable" for all items
    return {
      ...data,
      items: data.items.map((item) => {
        return { ...item, printable: (item as any).visible };
      }),
    };
  };
  private v19 = (data: IDesignState): IDesignState => {
    // add strokeWidth and showPlantOutlines
    return {
      ...data,
      strokeWidth: 1,
      showPlantOutlines: false,
    };
  };
  private v20 = (data: IDesignState): IDesignState => {
    // set custom symbol type
    return {
      ...data,
      activeCustomSymbol: 'rock',
    };
  };
  private v21 = (data: IDesignState): IDesignState => {
    // any existing rocks need to be converted to custom-symbols
    return {
      ...data,
      items: data.items.map((item) => {
        if ((item.itemType as any) === 'rock') {
          return {
            ...item,
            itemType: 'custom-symbol',
            symbolType: 'rock',
          };
        } else {
          return item;
        }
      }),
    };
  };
  private v22 = (data: IDesignState): IDesignState => {
    // add visibleLayers and lockedLayers arrays to design
    return {
      ...data,
      visibleLayers: designDefault.visibleLayers,
      lockedLayers: [],
    };
  };
  private v23 = (data: IDesignState): IDesignState => {
    // convert yards and beds to polylines
    const polylineYards = data.yards.map((yard) => {
      const n = convertYardToPolyline(yard);
      n.uuid = `conv-${yard.uuid}`;
      return n;
    });
    const polylineBeds = data.beds.map((bed) => {
      const n = convertBedToPolyline(bed);
      n.uuid = `conv-${bed.uuid}`;
      return n;
    });
    return {
      ...data,
      items: [...data.items, ...polylineYards, ...polylineBeds],
      // yards: [], not deleting them yet
      // beds: [], not deleting them yet
    };
  };
  private v24 = (data: IDesignState): IDesignState => {
    // change printable back to visible
    return {
      ...data,
      elements: data.elements.map((element) => {
        return { ...element, visible: (element as any).printable };
      }),
      edges: data.edges.map((edge) => {
        return { ...edge, visible: (edge as any).printable };
      }),
      items: data.items.map((item) => {
        return { ...item, visible: (item as any).printable };
      }),
    };
  };
  private v25 = (data: IDesignState): IDesignState => {
    // make sure visible is not undefined
    return {
      ...data,
      elements: data.elements.map((element) => {
        return {
          ...element,
          visible: element.visible === undefined ? true : element.visible,
        };
      }),
      edges: data.edges.map((edge) => {
        return {
          ...edge,
          visible: edge.visible === undefined ? true : edge.visible,
        };
      }),
      items: data.items.map((item) => {
        return {
          ...item,
          visible: item.visible === undefined ? true : item.visible,
        };
      }),
    };
  };
}
export const designMigrator = new DesignMigrator();

export const migrateDesign = (
  data: IDesignState,
  catalog: Catalog,
): IDesignState => {
  // this is a stopgap for making sure old designs can work in the new system.
  // should probably be a migration of data at some point
  const migrated = designMigrator.migrate(data, catalog);
  console.log(migrated);
  return migrated;
};
