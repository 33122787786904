import { Checkbox } from 'semantic-ui-react';

import { DeleteAllPipesType } from 'src/pages/Workbench/features/pipes/DeleteAllPipes';
import { DeleteOrphansType } from 'src/pages/Workbench/features/pipes/DeleteOrphans';
import {
  cleanupEdges,
  connectEdgeHeads,
  doAutoSize,
  doFixPipeDirections,
  findLines,
} from 'src/pages/Workbench/features/pipes/Pipes';
import {
  setOrtho,
  setPipeSnap,
  useDesignStore,
} from 'src/pages/Workbench/state';
import { openModal } from 'src/pages/Workbench/state/modal.store';
import styled from 'styled-components';
import { sizeDripTransitions } from '../plants/plants';
import { PanelButton, PanelDivider, PanelWrap } from './PanelButton';

export const PipeToolProperties = () => {
  const ortho = useDesignStore((state) => state.ortho);
  const pipeSnap = useDesignStore((state) => state.pipeSnap);
  return (
    <Wrap>
      <h5>Pipe Tool</h5>
      <Checkbox
        label="Ortho (o)"
        toggle
        checked={ortho}
        onChange={() => setOrtho(!ortho)}
      />
      <Checkbox
        label="Snap (s)"
        toggle
        checked={pipeSnap}
        onChange={() => setPipeSnap(!pipeSnap)}
      />
      <PanelDivider />
      <PanelButton onClick={doFixPipeDirections}>
        Fix Pipe Directions
      </PanelButton>
      <PanelButton onClick={doAutoSize}>Autosize Pipes</PanelButton>
      <PanelButton onClick={connectEdgeHeads}>Connect edge heads</PanelButton>
      <PanelButton onClick={cleanupEdges}>Clean up</PanelButton>
      <PanelButton onClick={findLines}>Colinear(ish) Pipes</PanelButton>
      <PanelButton onClick={() => openModal(DeleteAllPipesType)}>
        Delete All Pipes
      </PanelButton>
      <PanelButton onClick={() => openModal(DeleteOrphansType)}>
        Delete All Orphans
      </PanelButton>
      <PanelButton onClick={sizeDripTransitions}>
        Size Drip Transitions
      </PanelButton>
    </Wrap>
  );
};

const Wrap = styled(PanelWrap)``;
