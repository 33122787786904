import { WaterGroup } from '@shared-types';
import styled from 'styled-components';
import { reduceHeadsGPMbyId } from '../../helpers/directedGraph';
import { useDesignStore } from '../../state';
import { GroupMenu } from './GroupMenu';

export const GroupDetails = ({ group }: { group: WaterGroup }) => {
  const elementCache = useDesignStore((state) => state.elementCache);

  return (
    <Wrap>
      {group.headIds.length > 0 ? (
        <div>
          {reduceHeadsGPMbyId(group.headIds, elementCache).toFixed(1)} gpm
          across {group.headIds.length} heads{' '}
        </div>
      ) : (
        ''
      )}
      <GroupMenu group={group} />
    </Wrap>
  );
};

const Wrap = styled.div`
  padding: 8px;
  background: #5e5e5e;
`;
