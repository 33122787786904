import { useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { contains, uniq } from 'underscore';
import {
  getEdgePoints,
  hasSameRadiusDiffHead,
  reduceHeadsGPMbyId,
  reducePlantsGPHbyId,
} from '../../helpers/directedGraph';
import {
  getDistanceFromEdge,
  isInsidePoly,
} from '../../helpers/geometry.helpers';

import {
  DesignElement,
  IEdge,
  IMiscItem,
  IPlant,
  IPoC,
  IPoint,
  IValveProduct,
  Zone,
} from '@shared-types';
import {
  Polyline,
  ZoneLossTable,
} from '../../../../../../shared-types/workbench-types';
import { LinkWithConfirm } from '../../../../components/LinkWithConfirm';
import { isSleeve } from '../../helpers';
import { localPaper } from '../../localPaper';
import { isPlant } from '../../plants/plants';
import {
  getPolylineBeds,
  getPolylineYards,
} from '../../polyline/polyline-helpers';
import { getPointsFromPolyline } from '../../polyline/polyline.service';
import { getState, pocGraphByNode } from '../../state';
import { deleteEdge } from '../../state/deleteEdge';
import { isSprinkler } from '../../tools/paper-items/paper-sprinkler';

export const Good = (props) => (
  <div>
    <Icon name="check" color="green" />
    {props.children}
  </div>
);
export const Bad = (props) => (
  <div>
    <Icon name="x" color="red" />
    {props.children}
  </div>
);
export const Warn = (props) => (
  <div>
    <Icon name="warning" color="yellow" />
    {props.children}
  </div>
);

export const AlertList = () => {
  const {
    elements,
    edges,
    valveBoxes,
    zones,
    pipeProducts,
    valveProducts,
    backflowProducts,
    pocGraphs,
    elementCache,
  } = getState();
  const plants = getState().items.filter(isPlant);
  const sleeves = getState().items.filter(isSleeve);
  const yards = getPolylineYards();
  const beds = getPolylineBeds();

  const [trunksNearPipes, setTrunksNearPipes] = useState(false);

  const [worstLossByZone, setWorstLosses] = useState<{
    [id: string]: ZoneLossTable;
  }>({});

  const checkTrunks = (
    plants: IPlant[],
    edges: IEdge[],
    yards: Polyline<'yard'>[],
  ) => {
    setTrunksNearPipes(false);

    const edgePoints = edges.reduce(
      (points: IPoint[][], edge: IEdge) => [
        ...points,
        getEdgePoints(edge, elementCache),
      ],
      [],
    );
    const plantsInsideYards = plants.filter(
      (plant) =>
        plant &&
        yards.some((yard) => {
          const points = getPointsFromPolyline(yard);
          return isInsidePoly(plant.position, points);
        }),
    );
    plantLoop: for (const plant of plantsInsideYards) {
      for (const edgePoint of edgePoints) {
        if (getDistanceFromEdge(edgePoint, plant.position) < 3) {
          setTrunksNearPipes(true);
          break plantLoop;
        }
      }
    }
  };

  const plantsAreTooBig = () => {
    const bigPlants = plants.filter((plant) => plant.width > 45);
    return bigPlants.length > 0;
  };
  const showBigPlants = () => {
    const bigPlants = plants.filter((plant) => plant.width > 45);
    if (bigPlants.length) {
      selectAndCenterItem(bigPlants[0].uuid);
    }
  };
  const _findUnzonedHead = () => {
    const headIDs = zones.reduce(
      (acc: string[], zone) => [...acc, ...zone.headIds],
      [],
    );
    return elements.find((el) => isSprinkler(el) && !headIDs.includes(el.uuid));
  };
  const hasUnzonedHeads = () => {
    return !!_findUnzonedHead();
  };
  const showUnzonedHeads = () => {
    const head = _findUnzonedHead();
    if (head) {
      selectAndCenterItem(head.uuid);
    }
  };

  useEffect(() => {
    const yards = getPolylineYards();
    checkTrunks(plants, edges, yards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plants, edges]);

  useEffect(() => {
    let worstLosses: { [id: string]: ZoneLossTable } = {};
    zones.forEach((zone) => {
      const dg = pocGraphByNode(zone.valve, pocGraphs);
      if (dg) {
        const losses = dg.lossesAtZone(
          zone,
          pipeProducts,
          valveProducts,
          backflowProducts,
          edges,
        );
        const worstLoss = losses.reduce(
          (acc, loss) => (loss.totalLoss > acc.totalLoss ? loss : acc),
          losses[0],
        );
        worstLosses[zone.uuid] = worstLoss;
      }
    });
    setWorstLosses(worstLosses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const edgesWithNoElements = (
    edges: IEdge[],
    elements: DesignElement[],
  ): IEdge[] => {
    const elementIDs = elements.map((el) => el.uuid);
    return edges.filter(
      (edge) =>
        !contains(elementIDs, edge.source) ||
        !contains(elementIDs, edge.target),
    );
  };

  const allSourcesConnectedToPOCs = (elements: DesignElement[]): boolean => {
    const sources = elements.filter(
      (el) => el.type === 'meter' || el.type === 'pump',
    );
    const pocs = elements.filter((el) => el.type === 'poc');
    if (sources.length) {
      return pocs.every((el) =>
        contains(
          sources.map((el) => el.uuid),
          (el.props as IPoC).sourceID,
        ),
      );
    }
    return true;
  };

  const allPOCsWithMainsHaveSource = (elements: DesignElement[]): boolean => {
    const pocs = elements.filter((el) => el.type === 'poc');
    return pocs
      .filter((poc) => (poc.props as IPoC).mainsToSource.length)
      .every((el) => !!(el.props as IPoC).sourceID);
  };

  const bedPlantsZoned = (
    plants: IPlant[],
    zones: Zone[],
    beds: Polyline<'bed'>[],
  ): boolean => {
    // TODO: check if fittings are inside, but somehow ignore fittings that are closely related to valve boxes/water sources?

    const beddedPlants = plants.filter((plant) =>
      beds.some((bed) => {
        const points = getPointsFromPolyline(bed);
        return isInsidePoly(plant.position, points);
      }),
    );
    const drippedPlants = zones
      .filter((z) => z.plantIds.length > 0)
      .reduce(
        (plantIDs: string[], zone: Zone) => [...plantIDs, ...zone.plantIds],
        [],
      );
    return beddedPlants.every((plant) => drippedPlants.includes(plant.uuid));
  };

  const allHeadsPipedGraph = (zones: Zone[]): boolean => {
    const pocGraphs = getState().pocGraphs;
    const headIDs = zones.reduce(
      (acc: string[], zone) => [...acc, ...zone.headIds],
      [],
    );
    return headIDs.every((id) => pocGraphs.some((dg) => dg.hasNode(id)));
  };
  const showUnconnectedHeads = () => {
    const headIDs = zones.reduce(
      (acc: string[], zone) => [...acc, ...zone.headIds],
      [],
    );

    const head = headIDs.find((id) => !pocGraphs.some((dg) => dg.hasNode(id)));
    if (head) {
      selectAndCenterItem(head);
    }
  };
  const someEdgesNull = (edges: IEdge[]): boolean => {
    return edges.some((edge) => !edge.pipe);
  };

  const badHeads = (elements: DesignElement[]): boolean =>
    elements.some((el) => isSprinkler(el) && el.props.precipAverage === 0);

  const duplicateEls = (elements: DesignElement[]): boolean => {
    const coordMap = new Set();
    for (const el of elements) {
      const key = `${el.position.x}:${el.position.y}`;
      if (coordMap.has(key)) {
        return true;
      }
      coordMap.add(key);
    }
    return false;
  };

  const showFirstDuplicate = () => {
    let firstDupe = '';
    const coordMap = new Set();
    for (const el of elements) {
      const key = `${el.position.x}:${el.position.y}`;
      if (coordMap.has(key)) {
        firstDupe = el.uuid;
        break;
      }
      coordMap.add(key);
    }
    if (firstDupe) {
      selectAndCenterItem(firstDupe);
    }
  };

  const selectAndCenterItem = (id: string) => {
    const item = localPaper.project.getItem({ data: { uuid: id } });
    item.selected = true;
    localPaper.view.center = item.position;
  };

  const hasNoResidualPSI = (zone: Zone) => {
    return (
      worstLossByZone[zone.uuid] && worstLossByZone[zone.uuid].residualPSI < 0
    );
  };
  const hasNoValveLoss = (zone: Zone) => {
    return (
      worstLossByZone[zone.uuid] && worstLossByZone[zone.uuid].valveLoss === 0
    );
  };

  const show4WayFittings = () => {
    const edges = getState().edges;
    const elements = getState().elements;
    const fittings = elements.filter((el) => el.type === 'fitting');
    const badFittings = fittings.filter(
      (fitting) =>
        edges.filter(
          (edge) =>
            edge.source === fitting.uuid || edge.target === fitting.uuid,
        ).length > 3,
    );
    if (badFittings.length) {
      selectAndCenterItem(badFittings[0].uuid);
    }
  };

  const hasBadGPM = (zone: Zone) => {
    let gpm = 0;
    if (zone.isDrip || zone.plantIds.length) {
      gpm = reducePlantsGPHbyId(plants, zone.plantIds) / 60;
    } else {
      gpm = reduceHeadsGPMbyId(zone.headIds, elementCache);
    }
    const dg = pocGraphByNode(zone.valve, pocGraphs);
    const rootGPM = dg ? dg.rootGPM(pipeProducts) : 0;
    return gpm > rootGPM;
  };

  return (
    <div>
      {zones
        .filter((zone) => {
          const heads = zone.headIds
            .map((id) => elementCache[id])
            .filter(isSprinkler);
          const psis = uniq(heads.map((h) => h.props.base.outputPSI));
          return psis.length > 1;
        })
        .map((z, i) => (
          <Bad key={i}>zone {z.orderNumber + 1} has mismatching PSIs</Bad>
        ))}
      {zones
        .filter((z) => hasBadGPM(z))
        .map((z, i) => (
          <Bad key={i}>Zone {z.orderNumber + 1} has too much GPM</Bad>
        ))}
      {elements.find(
        (el) =>
          el.type === 'miscItem' &&
          (el.props as IMiscItem).name === 'Master Valve',
      ) ? (
        <Good>Has Master Valve</Good>
      ) : (
        <Bad>Has no master valve</Bad>
      )}
      {zones.filter(hasNoResidualPSI).map((z) => (
        <Bad key={z.uuid}>Zone {z.orderNumber + 1} has negative pressure</Bad>
      ))}
      {zones.filter(hasNoValveLoss).map((z) => (
        <Bad key={z.uuid}>
          Zone {z.orderNumber + 1} has no valve loss listed
        </Bad>
      ))}
      {elements.filter(
        (el) =>
          el.type === 'miscItem' &&
          (el.props as IMiscItem).name === 'Gate Valve',
      ).length === valveBoxes.length ? (
        <Good>Has same # of gate valves as valve boxes</Good>
      ) : (
        <Warn># of gate valves does not equal # of valve boxes</Warn>
      )}
      {elements.filter(
        (el) =>
          el.type === 'miscItem' &&
          (el.props as IMiscItem).name === 'Quick Coupler',
      ).length === valveBoxes.length ? (
        <Good>Has same # of quick couplers as valve boxe</Good>
      ) : (
        <Warn># of quick couplers does not equal # of valve boxes</Warn>
      )}
      {hasUnzonedHeads() ? (
        <Bad>
          Some heads are not zoned{' '}
          <Button onClick={showUnzonedHeads}>Show</Button>
        </Bad>
      ) : (
        <Good>All heads are zoned</Good>
      )}
      {elements.find(
        (el) =>
          el.type === 'miscItem' &&
          (el.props as IMiscItem).name === 'Controller',
      ) ? (
        <Good>Has a Controller</Good>
      ) : (
        <Bad>Has no Controller</Bad>
      )}
      {!allPOCsWithMainsHaveSource(elements) ? (
        <Bad>Some POCs have mains but no source</Bad>
      ) : (
        <Good>All POCs that have mains also have a source</Good>
      )}
      {!allSourcesConnectedToPOCs(elements) ? (
        <Bad>Not all POCs are connected to the sources</Bad>
      ) : (
        <Good>All POCs are connected to source (or no sources) </Good>
      )}
      {/* TODO: error: if root has no overridePSI */}
      {edgesWithNoElements(edges, elements).map((edge, i) => (
        <div key={i}>
          Edge w/no elements: {edge.source.substr(0, 5)} &gt;{' '}
          {edge.target.substr(0, 5)}{' '}
          <LinkWithConfirm onConfirm={() => deleteEdge(edge.uuid)}>
            Delete
          </LinkWithConfirm>
        </div>
      ))}
      {plantsAreTooBig() ? (
        <Bad>
          Some plants are too big <Button onClick={showBigPlants}>Show</Button>
        </Bad>
      ) : (
        <Good>All plants are within size limits</Good>
      )}
      {elements.find(
        (el) => el.type === 'valve' && el.props && !(el.props as any).uuid,
      ) ? (
        <Bad>A valve exists that has no product</Bad>
      ) : (
        <Good>All valves are connected to a product</Good>
      )}

      {hasSameRadiusDiffHead(elements) ? (
        <Bad>has the same radius as a different head</Bad>
      ) : (
        <Good>All same radius heads are same type</Good>
      )}
      {zones
        .filter((z) => z.plantIds.length > 0)
        .map((z) => z.plantIds)
        .reduce(
          (acc: string[], currPlants: string[]) => [...acc, ...currPlants],
          [],
        )
        .some((plantID) => {
          const plantEl = plants.find((p) => p.uuid === plantID);
          return (
            plantEl &&
            yards.some((yard) => {
              const points = getPointsFromPolyline(yard);
              return isInsidePoly(plantEl.position, points);
            })
          );
        }) ? (
        <Bad>Some plants are zoned in turf areas</Bad>
      ) : (
        <Good>No plants are zoned in turf areas</Good>
      )}
      {pocGraphs.map((dg, dgIndex) => (
        <div key={dgIndex}>
          <span>Graph {dgIndex + 1}</span>
          {dg.rootGPM(pipeProducts) > 0 ? (
            <Good>POC has a positive GPM</Good>
          ) : (
            <Bad>POC has a bad GPM</Bad>
          )}
          {dg.hasUnvalvedHeads() ? (
            <Bad>Some heads aren't connected to a valve</Bad>
          ) : (
            <Good>All heads are connected to a valve</Good>
          )}
          {dg.hasSplitHeads() ? (
            <Bad>Some heads have more than one child</Bad>
          ) : (
            <Good>All heads have less than 2 children</Good>
          )}
          {dg.headsWhoseUpstreamValveIsNotTheirs().map((head, i) => (
            <div key={i}>
              Head {i} is not connected to its valve{' '}
              <Button onClick={() => selectAndCenterItem(head.uuid)}>
                View
              </Button>
            </div>
          ))}
          {dg.has4Way() ? (
            <Bad>
              Some fitting(s) have more than 3 connections
              <Button onClick={show4WayFittings}>Show</Button>
            </Bad>
          ) : (
            <Good>All fittings have 3 or fewer connections</Good>
          )}
        </div>
      ))}
      {yards.length + beds.length > 1 && !sleeves.length ? (
        <Bad>There are multiple yards/beds but no sleeves...worth a check?</Bad>
      ) : (
        <Good>There are sleeves</Good>
      )}
      {trunksNearPipes ? (
        <Bad>Some plants are too close to pipes in turf</Bad>
      ) : (
        <Good>All plants are away from pipes in turf</Good>
      )}
      {elements.find(
        (el) => el.type === 'valve' && !el.props.hasOwnProperty('brand'),
      ) ? (
        <Warn>Some zones don't have a good valve</Warn>
      ) : (
        <Good>All zones have a good valve</Good>
      )}
      {uniq(
        elements
          .filter(
            (el) => el.type === 'valve' && el.props.hasOwnProperty('brand'),
          )
          .map(
            (el) =>
              `${(el.props as IValveProduct).brand} ${
                (el.props as IValveProduct).name
              }`,
          ),
      ).length > 1 ? (
        <Warn>More than 1 type of valve</Warn>
      ) : (
        <Good>Only one valve type</Good>
      )}
      {bedPlantsZoned(plants, zones, beds) ? (
        <Good>All plants inside beds are zoned</Good>
      ) : (
        <Bad>Some plants inside beds are not zoned</Bad>
      )}
      {allHeadsPipedGraph(zones) ? (
        <Good>All heads in zones are connected</Good>
      ) : (
        <Bad>
          Some heads are not connected{' '}
          <Button onClick={showUnconnectedHeads} size="mini">
            View First
          </Button>
        </Bad>
      )}
      {someEdgesNull(edges) ? (
        <Bad>Some edges have no pipe value</Bad>
      ) : (
        <Good>All edges have a pipe value</Good>
      )}
      {badHeads(elements) ? (
        <Bad>Some heads have no precip</Bad>
      ) : (
        <Good>All heads seem good</Good>
      )}
      {duplicateEls(elements) ? (
        <Bad>
          Some elements have the same coordinates{' '}
          <Button onClick={showFirstDuplicate} size="mini">
            View
          </Button>
        </Bad>
      ) : (
        <Good>No elements share coordinates</Good>
      )}
    </div>
  );
};
