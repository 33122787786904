import { IMiscItemBase, Tools } from '@shared-types';
import { Dropdown, DropdownItem, DropdownMenu, Icon } from 'semantic-ui-react';
import { itemOptions } from 'src/shared/constants';
import styled from 'styled-components';
import { irrigationBlue } from '../../../vars';
import { ReactComponent as CropFree } from '../images/crop_free.svg';
import { ReactComponent as Lasso } from '../images/lasso.svg';

import {
  getState,
  setActiveMiscItem,
  setActiveTool,
  toggleArcsOff,
  togglePipeLabels,
  useDesignStore,
} from '../state';

export const ToolList = () => {
  const activeTool = useDesignStore((state) => state.activeTool);
  const activeMiscItem = useDesignStore((state) => state.activeMiscItem);

  const handlePipeToolActivation = () => {
    const showPipeLabels = getState().showPipeLabels;
    toggleArcsOff();
    if (showPipeLabels) {
      togglePipeLabels();
    }
    setActiveTool('Pipes');
  };
  const handleMiscItemChange = (item: IMiscItemBase) => {
    if (item) {
      setActiveMiscItem(item);
      setActiveTool('Misc Item');
    }
  };
  return (
    <Wrap>
      <CustomButton
        className="pointer-button"
        $active={activeTool === 'Arrow'}
        onClick={setActiveTool.bind(null, 'Arrow')}
      >
        <Icon name="mouse pointer" />
      </CustomButton>
      <CustomButton
        $active={activeTool === 'Marquee'}
        onClick={setActiveTool.bind(null, 'Marquee')}
      >
        <CropFree />
      </CustomButton>
      <CustomButton
        $active={activeTool === 'Lasso'}
        onClick={setActiveTool.bind(null, 'Lasso')}
      >
        <Lasso />
      </CustomButton>
      <div className="divider"></div>
      <>
        <Dropdown
          pointing="left"
          className="tool-menu"
          icon={null}
          trigger={
            <CustomButton>
              <Icon name="plus" />
            </CustomButton>
          }
        >
          <DropdownMenu>
            <CustomDropdownButton
              active={activeTool === 'Head Placement'}
              onClick={setActiveTool.bind(null, 'Head Placement')}
            >
              Head
            </CustomDropdownButton>

            <CustomDropdownButton
              active={activeTool === 'Pipes'}
              onClick={handlePipeToolActivation}
            >
              Pipe
            </CustomDropdownButton>

            <CustomDropdownButton
              title="Valve Box"
              active={activeTool === 'Valve Location'}
              onClick={setActiveTool.bind(null, 'Valve Location')}
            >
              Valve Box
            </CustomDropdownButton>

            <CustomDropdownButton
              title="Sheet"
              active={activeTool === 'sheet'}
              onClick={setActiveTool.bind(null, 'sheet')}
            >
              Sheet
            </CustomDropdownButton>

            <CustomDropdownButton
              active={activeTool === 'Sleeve'}
              onClick={setActiveTool.bind(null, 'Sleeve')}
            >
              Sleeve
            </CustomDropdownButton>
            <CustomDropdownButton
              active={activeTool === 'grid'}
              onClick={setActiveTool.bind(null, 'grid')}
            >
              Grid
            </CustomDropdownButton>
            <CustomDropdownButton
              active={activeTool === 'ruler'}
              onClick={setActiveTool.bind(null, 'ruler')}
            >
              Ruler
            </CustomDropdownButton>
            <CustomDropdownButton
              active={activeTool === 'north-arrow'}
              onClick={setActiveTool.bind(null, 'north-arrow')}
            >
              North Arrow
            </CustomDropdownButton>
            <CustomDropdownButton
              active={activeTool === 'text-tool'}
              onClick={setActiveTool.bind(null, 'text-tool')}
            >
              Text
            </CustomDropdownButton>
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          className="tool-menu"
          pointing="left"
          icon={null}
          trigger={<CustomButton>Misc</CustomButton>}
        >
          <DropdownMenu className="misc-options">
            {itemOptions
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <CustomDropdownButton
                  key={item.name}
                  active={activeMiscItem.name === item.name}
                  onClick={() => {
                    handleMiscItemChange(item);
                  }}
                >
                  {item.name}
                </CustomDropdownButton>
              ))}
          </DropdownMenu>
        </Dropdown>
      </>

      <>
        {[
          { label: 'Plant', value: 'Plant placement' },
          { label: 'Circle', value: 'circle' },
          { label: 'Polyline', value: 'polyline' },
          { label: 'Rectangle', value: 'rectangle' },
          { label: 'Symbol', value: 'custom-symbol' },
        ].map((item, i) => (
          <CustomButton
            $active={activeTool === item.value}
            key={i}
            onClick={setActiveTool.bind(null, item.value as Tools)}
          >
            {item.label}
          </CustomButton>
        ))}
      </>
    </Wrap>
  );
};
export const Wrap = styled.div`
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .ui.vertical.buttons .button:first-child {
    border-radius: 0;
  }
  .ui.button.toggle.active,
  .ui.buttons .button.toggle.active,
  .ui.toggle.buttons .active.button {
    background: ${irrigationBlue} !important;
  }
  .divider {
    border-top: 1px solid #999;
    margin: 0;
  }
  .misc-options {
    /* left: 150% !important; */
    max-height: 200px;
    overflow: auto;
  }
  .tool-menu {
    display: block;
  }
`;

const CustomButton = styled.button<{ $active?: boolean }>`
  display: block;
  outline: 0;
  border: none;
  line-height: 1em;
  color: ${(props) => (props.$active ? '#005baa' : '#333')};
  background: transparent;
  height: 3em;
  min-width: 3em;
  width: 100%;
  text-align: center;
  cursor: pointer;
  svg {
    width: 1.2em;
  }
  i.icon {
    margin: 0;
  }
  &:hover {
    background: #efefef;
  }
`;

const CustomDropdownButton = styled(DropdownItem)`
  display: block;
  outline: 0;
  border: none;
  line-height: 1em;
  color: ${(props) => (props.$active ? '#005baa' : '#333')};
  background: transparent;
  height: 3em;
  min-width: 3em;
  width: 100%;
  text-align: center;
  cursor: pointer;
  svg {
    width: 1.2em;
    margin-right: 4px;
  }
  i.icon {
    margin: 0;
  }
  &:hover {
    background: #efefef;
  }
`;
